import { useEffect } from 'react';
import { getNewsroomConfig } from 'config';
import { useDispatch } from 'react-redux';
import { useWatch, FormProvider } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form';
import { fetchLiveEncoders } from 'store/live/actions';
import { Divider, Dialog } from '@schibsted-svp/react-ui';
import { VideoConfiguration } from 'components/video/live/form/VideoConfiguration';
import LiveCreationDialogSpinner from 'components/video/live/LiveCreationDialog/LiveCreationDialogSpinner';
import { AudioConfiguration } from 'components/video/live/form/AudioConfiguration';
import { LiveSchedule } from 'components/video/live/form/LiveSchedule';
import type { LiveScheduleFormData } from 'components/video/live/form/LiveSchedule';
import useNewsroom from 'hooks/useNewsroom';
import type { LiveEvent } from 'models/liveEvent';
import type { InitialValues } from 'components/video/live/LiveCreationDialog/hooks';
import { EncoderInputSource } from './EncoderInputSource';
import { EncoderInputFormFooter } from './EncoderInputFormFooter';
import { useEncoderInputForm, useInitialValues } from './hooks';
import type { EncoderInputFormControl } from './types';

import css from './EncoderInputForm.module.scss';
import { useScheduleError } from './hooks-schedule';
import { useDvrWarning } from './hooks-dvr';

interface EncoderInputFormProps {
    events: LiveEvent[];
    hasEncoder: boolean;
    isEditing: boolean;
    initialValues: InitialValues;
    formControl: EncoderInputFormControl;
}

export function EncoderInputForm(props: EncoderInputFormProps) {
    const { events, hasEncoder, isEditing, formControl } = props;

    const dispatch = useDispatch();
    const newsroom = useNewsroom();

    useEffect(() => {
        dispatch(fetchLiveEncoders(newsroom));
    }, [newsroom, dispatch]);

    const initialValues = useInitialValues({ newsroom, ...props });

    const { formApi } = useEncoderInputForm(initialValues);

    const {
        control,
        formState: { isSubmitting },
    } = formApi;

    /* RHF has problems with using generic form components so we need to use type assetion here */
    /* @see: https://github.com/react-hook-form/react-hook-form/issues/6726 */
    const liveScheduleFormApi = formApi as unknown as UseFormReturn<LiveScheduleFormData>;
    const scheduleError = useScheduleError({ formApi });
    const dvrWarning = useDvrWarning({ formApi });

    const access = useWatch({ name: 'access', control });
    const isEncrypted = Boolean(access?.paywall);
    const hasEvents = Boolean(events.length);

    const newsroomConfig = getNewsroomConfig(newsroom);
    return (
        <FormProvider {...formApi}>
            <Dialog.Section>
                <form className={css.container}>
                    <EncoderInputSource />
                    <VideoConfiguration
                        isEncrypted={isEncrypted}
                        enableLivePreview
                        enableLiveVertical={newsroomConfig.live.enableLiveVertical}
                        enableDeinterlace
                    />
                    {!hasEncoder ? (
                        <>
                            <Divider margin="10px 0" />
                            <LiveSchedule
                                newsroom={newsroom}
                                formApi={liveScheduleFormApi}
                                dvrWarning={dvrWarning}
                                scheduleError={scheduleError}
                            />
                        </>
                    ) : null}

                    <AudioConfiguration enableAudioNormalization={newsroomConfig.live.audioNormalization?.enabled} />
                </form>

                {isSubmitting && <LiveCreationDialogSpinner />}
            </Dialog.Section>
            <EncoderInputFormFooter
                isEditing={isEditing}
                hasEncoder={hasEncoder}
                hasEvents={hasEvents}
                formControl={formControl}
            />
        </FormProvider>
    );
}
