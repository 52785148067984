import classnames from 'classnames/bind';
import { memo } from 'react';
import { formatRemainingExactTime } from 'lib/time';
import css from './TimelineTooltip.module.scss';

const cln = classnames.bind(css);

type TimelineTooltipProps = {
    marker?: boolean;
    time: number;
    offset: number;
};

export const TimelineTooltip = memo<TimelineTooltipProps>(function TimelineTooltip({ marker = false, time, offset }) {
    return (
        <div
            className={cln(css.tooltip, { [css.marker]: marker })}
            style={{ transform: `translateX(calc(${offset}px - 50%))` }}
        >
            {formatRemainingExactTime(time)}
        </div>
    );
});
