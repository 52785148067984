import { useController } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import { LabeledContainer } from '@schibsted-svp/react-ui';
import Rating from 'components/video/Rating';
import type { FormValues } from '../../types';
import css from './NewsLifetime.module.scss';

const LIFETIME_OPTIONS = [
    { label: 'short', value: 1 },
    { label: 'medium', value: 2 },
    { label: 'long', value: 3 },
];

type NewsLifetimeProps = {
    control: Control<FormValues>;
};

export function NewsLifetime({ control }: NewsLifetimeProps) {
    const { field } = useController({ name: 'additional.metadata.newsLifetime', control });

    return (
        <LabeledContainer label="news lifetime">
            <Rating
                value={field.value && Number(field.value)}
                options={LIFETIME_OPTIONS}
                onChange={(value) => field.onChange(String(value))}
                containerClassName={css.levels}
                itemClassName={css.item}
            />
        </LabeledContainer>
    );
}
