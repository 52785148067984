import { FaSearch } from 'react-icons/fa';

import css from './EmptyResult.module.scss';

export default function EmptyResult() {
    return (
        <span className={css.container}>
            <FaSearch size={100} className={css.searchIcon} />
            <br />
            <br />
            We cannot find the item you are searching for.
            <br />
            Maybe a little spelling mistake?
        </span>
    );
}
