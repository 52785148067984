import { useState } from 'react';
import type { MouseEvent } from 'react';
import type { Asset } from '@schibsted-svp/svp-api-types';
import Tag from 'components/core/LoadableTagLabel';
import { TagsEditingDialog } from 'components/core/TagsEditingDialog';

import css from './TagsCell.module.scss';

type TagsCellProps = {
    asset: Asset;
    readOnly: boolean;
};

export function TagsCell({ asset, readOnly = false }: TagsCellProps) {
    const [isOpen, setIsOpen] = useState(false);

    const { tags = [] } = asset.additional;

    const onEditClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    return (
        <div role="presentation">
            {tags.length > 0 && (
                <div className={css.list}>
                    {tags.map((id) => (
                        <Tag key={id} id={id} provider={asset.provider} />
                    ))}
                </div>
            )}

            {!readOnly && (
                <button className={css.add} type="button" onClick={onEditClick}>
                    + <span className={css.addText}>{tags.length > 0 ? 'Add more tags' : 'Add tags'}</span>
                </button>
            )}

            {isOpen && (
                <TagsEditingDialog asset={asset} isOpen={isOpen} onSuccess={closeDialog} onClose={closeDialog} />
            )}
        </div>
    );
}
