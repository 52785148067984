import { get } from 'lodash';
import { getCategoriesSubTree } from 'lib/categories';

export const getCategoriesItems = (state, { provider }) => get(state, ['categories', provider, 'items'], {});
export const getCategories = (state, { provider }) => get(state, ['categories', provider]);
export const getCategory = (state, { provider, id }) => get(state, ['categories', provider, 'items', id]);
export const getSeriesFlag = (state, { provider, id }) => get(state, ['categories', provider, 'items', id, 'isSeries']);

export const getCategoryChildren = (state, provider, categoryId) =>
    getCategoriesSubTree(categoryId, getCategoriesItems(state, { provider }));

export const getCategoryPromoUrl = (state, { categoryId, provider }) =>
    state.categories?.[provider]?.items?.[categoryId]?.additional.metadata?.promoUrl?.value;
