import config from 'config';
import { validatorTypes } from 'services/asset-quality/validators';

function pluralize(word: string) {
    if (word.endsWith('s')) {
        return `${word}es`;
    }

    return `${word}s`;
}

function numberToWords(number: number) {
    switch (number) {
        case 0:
            return 'zero';
        case 1:
            return 'one';
        case 2:
            return 'two';
        case 3:
            return 'three';
        case 4:
            return 'four';
        case 5:
            return 'five';
        default:
            return 'a lot';
    }
}

function arrayLengthMessage(name: string, score: number, required: number) {
    if (score === 1) {
        return `has ${pluralize(name)}`;
    }

    if (score === 0) {
        return `no ${pluralize(name)}`;
    }

    const value = score * required;
    const valueAsWord = numberToWords(value);
    const requiredAsWord = numberToWords(required);

    return `${valueAsWord} ${value === 1 ? name : pluralize(name)}, try adding ${requiredAsWord}`;
}

function wordCountMessage(name: string, score: number, required: number) {
    if (score === 1) {
        return `has ${name}`;
    }

    if (score === 0) {
        return `no ${name}`;
    }

    const requiredAsWord = numberToWords(required);

    return `${name} too short, try adding at least ${requiredAsWord} words`;
}

export function getMessage(ruleName: string, score: number) {
    const { name, validator } = config.assetQuality.rules[ruleName] || {};

    if (!validator) {
        return '';
    }

    if (validator.type === validatorTypes.ARRAY_LENGTH) {
        return arrayLengthMessage(name, score, validator.required);
    }

    if (validator.type === validatorTypes.WORD_COUNT) {
        return wordCountMessage(name, score, validator.required);
    }

    if (score === 1) {
        return `has ${name}`;
    }

    return `no ${name}`;
}
