import { createSelector } from '@reduxjs/toolkit';
import config from 'config';
import { prepareUsersNewsrooms } from 'components/settings/UserAccessManager/helpers';
import { api } from '.';

export function makeUserNewsroomSelector(email: string) {
    return createSelector([api.endpoints.GetUsers.select({ query: email })], ({ data }) => {
        const roles = data?.getUsers.at(0)?.roles;
        if (!roles) {
            return undefined;
        }
        return prepareUsersNewsrooms(roles, Object.keys(config.newsrooms));
    });
}
