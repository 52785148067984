/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { Button, Dialog, InputError, Spinner } from '@schibsted-svp/react-ui';
import { useLazyGetLiveQuery } from 'services/admin-bff-sdk/generated';
import useToggle from 'hooks/useToggle';
import { RtmpStreamKey } from 'components/video/live/form/RtmpForm/RtmpStreamKey';
import css from './RtmpInfoDialog.module.scss';

interface RtmpInfoDialogProps {
    provider: string;
    assetId: number;
    triggerProps?: {
        disabled: Button['props']['disabled'];
        buttonSize: Button['props']['size'];
        buttonClassName: Button['props']['className'];
    };
}

export function RtmpInfoDialog({ provider: newsroom, assetId, triggerProps }: RtmpInfoDialogProps) {
    const { disabled, buttonSize, buttonClassName } = triggerProps || {};

    const [getLiveData, { data: liveData, isLoading, isError }] = useLazyGetLiveQuery();
    const [isOpened, toggleIsOpened] = useToggle();

    useEffect(() => {
        if (isOpened) {
            getLiveData({ newsroom, assetId });
        }
    }, [isOpened, newsroom, assetId, getLiveData]);

    const DialogTrigger = (
        <Button
            onClick={toggleIsOpened}
            variant="standard"
            type="button"
            size={buttonSize}
            disabled={disabled}
            className={buttonClassName}
        >
            Web camera info
        </Button>
    );

    // eslint-disable-next-line no-underscore-dangle
    const streamKey = liveData?.live.__typename === 'RtmpLive' ? liveData.live.streamKey : undefined;

    return (
        <Dialog heading="Web camera info" isOpen={isOpened} dialogTrigger={DialogTrigger} onClose={toggleIsOpened}>
            <Dialog.Section>
                {isLoading ? (
                    <Spinner containerClassName={css.spinner} size="small" />
                ) : isError || !streamKey ? (
                    <InputError className={css.error}>No data available</InputError>
                ) : (
                    <RtmpStreamKey value={streamKey} readOnly />
                )}
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={toggleIsOpened}>
                    Close
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}
