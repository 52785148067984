import { get } from 'lodash';
import * as graphql from './graphql/last-asset-ingest.graphql';

/**
 * @typedef {Object} LastAssetIngest
 * @property {String} provider
 * @property {Number} id
 * @property {String} status
 * @property {String} [type]
 * @property {Number} [totalProgress]
 * @property {Object[]} [errors]
 * @property {String} errors.message
 * @property {String} [errors.type]
 * @property {Number} [errors.time]
 */

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.id
 * @returns {Promise<LastAssetIngest>}
 */
export async function getLastAssetIngest({ provider, id }) {
    const response = await this.query(graphql.getLastAssetIngest, { assetCriteria: { provider, assetId: id } });
    return get(response, 'lastAssetIngest');
}

/**
 * @param {Object} params
 * @param {Object[]} params.assets
 * @returns {Promise<LastAssetIngest[]>}
 */
export async function getLastAssetsIngest({ assets }) {
    const response = await this.query(graphql.getLastAssetsIngest, { assetsCriteria: assets });
    return get(response, 'lastAssetsIngest', []);
}
