import { combineReducers } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { merge } from 'lib/object';
import * as AssetFormActions from 'store/ui/assetForm/actionTypes';
import * as listActions from 'store/asset-list/actionTypes';

import { PLAYLISTS_ASSETS_FETCH_SUCCESS, PLAYLISTS_GENERATOR_ASSETS_FETCH_SUCCESS } from 'store/playlists/items';
import { STATIC_ITEMS_FETCH_SUCCESS } from 'store/playlists/staticItems';
import * as LiveActions from 'store/live/actions';
import * as Actions from './actionTypes';
import { defaultItems, defaultAsset } from './state';

function initializeAsset(state) {
    return state ? { ...state } : defaultAsset;
}

function itemsReducer(state = defaultItems, action) {
    switch (action.type) {
        case AssetFormActions.RELATED_FETCH_SUCCESS:
        case AssetFormActions.NEXT_SEARCH_SUCCESS:
        case PLAYLISTS_ASSETS_FETCH_SUCCESS:
        case PLAYLISTS_GENERATOR_ASSETS_FETCH_SUCCESS:
        case STATIC_ITEMS_FETCH_SUCCESS:
        case listActions.SET_ASSETS:
        case listActions.ADD_ASSETS:
        case LiveActions.FETCH_LIVE_ASSETS_SUCCESS:
        case LiveActions.FETCH_WAS_LIVE_ASSETS_SUCCESS: {
            const assets = get(action, 'payload.assets', action.assets);
            // for each asset on the fetched list prepare the state changes for corresponding items in the cache
            const assetsState = assets.reduce((acc, asset) => {
                const { provider, id } = asset;
                // make sure every asset state is initialized
                const assetState = initializeAsset(get(state, [provider, id]));

                // prepare the changes with an updated asset definition
                if (!acc[provider]) {
                    acc[provider] = {};
                }
                acc[provider][id] = {
                    ...assetState,
                    asset: {
                        ...asset,
                        // keep displays and not override with empty values from search endpoint
                        ...(assetState.asset?.displays ? { displays: assetState.asset.displays } : {}),
                    },
                };

                return acc;
            }, {});

            return merge(state, assetsState);
        }

        case Actions.ASSET_FETCH:
        case Actions.ASSET_SAVE: {
            const { id, provider } = action;

            // return existing state if a new asset is added
            if (!id) {
                return state;
            }

            // make sure the asset state is initialized
            const assetState = initializeAsset(get(state, [provider, id]));
            return merge(state, {
                [provider]: {
                    [id]: {
                        ...assetState,
                        isFetching: action.type === Actions.ASSET_FETCH,
                        error: null,
                    },
                },
            });
        }

        case Actions.ASSET_FETCH_SUCCESS:
        case Actions.ASSET_SAVE_SUCCESS: {
            const { id, asset } = action;
            const { provider } = asset;

            // make sure the asset state is initialized
            const assetState = initializeAsset(get(state, [provider, id]));

            const newState = {
                ...state,
            };
            if (!newState[provider]) {
                newState[provider] = {};
            }
            newState[provider][id] = {
                ...assetState,
                isFetching: false,
                isPristine: false,
                error: null,
                asset,
            };
            return newState;
        }

        case Actions.ASSET_CREATE_SUCCESS: {
            const { asset } = action;
            const { id, provider } = asset;

            return merge(state, {
                [provider]: {
                    [id]: {
                        ...defaultAsset,
                        asset,
                    },
                },
            });
        }

        case Actions.ASSET_FETCH_ERROR:
        case Actions.ASSET_SAVE_ERROR: {
            const { id, provider } = action;

            // make sure the asset state is initialized
            const assetState = initializeAsset(get(state, [provider, id]));

            return merge(state, {
                [provider]: {
                    [id]: {
                        ...assetState,
                        isFetching: false,
                        error: action.error,
                    },
                },
            });
        }

        case Actions.ASSET_CHANGE: {
            const { asset } = action;
            const { id, provider } = asset;

            return {
                ...state,
                [provider]: {
                    ...state[provider],
                    [id]: {
                        ...state[provider][id],
                        ...initializeAsset(get(state, [provider, id])),
                        asset: {
                            ...state[provider][id].asset,
                            ...asset,
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
}

export default combineReducers({
    items: itemsReducer,
});
