import { connect } from 'react-redux';
import { getTime } from 'lib/time';
import { getAssetData } from 'store/assets/selectors';

import AssetPreview from './AssetPreview';

/**
 * Get preview mode
 * Assets without snapshots will use player as preview
 *
 * @param asset
 * @returns {string}
 */
const getPreviewMode = (asset) => {
    if (asset.streamType === 'wasLive' || asset.streamType === 'live') {
        return 'stream';
    }

    return 'snapshots';
};

/**
 * Frame is time based value
 * It has to be translated to (0-1) value
 *
 * @param frame
 * @param asset
 * @returns {*}
 */
const getFrame = (frame, asset) => {
    if (asset.duration > 0 && typeof frame === 'number') {
        const framePosition = getTime({ seconds: frame }, 'milliseconds');
        return framePosition / asset.duration;
    }

    return null;
};

const enhance = connect((state, props) => {
    let { asset } = props;

    if (!asset) {
        // FIXME handle a case when getAssetData returns undefined
        ({ asset } = getAssetData(state, props));
    }

    return {
        mode: getPreviewMode(asset),
        frame: getFrame(props.frame, asset),
        asset,
    };
});

export default enhance(AssetPreview);
