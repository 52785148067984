import config from 'config';
import { getQualityRulesForNewsroom, canRenderAssetQualityForNewsroom } from 'services/asset-quality';
import { Checkbox } from '@schibsted-svp/react-ui';
import useNewsroom from 'hooks/useNewsroom';
import css from './AssetChecklistSettings.module.scss';

const { rules, placements } = config.assetQuality;

export function AssetChecklistSettings() {
    const newsroom = useNewsroom();
    const qualityRules = getQualityRulesForNewsroom(newsroom);

    if (!qualityRules) {
        return null;
    }

    return (
        <section data-test="AssetChecklistSettings">
            <h1>Notification settings</h1>

            <div className={css.content}>
                <p className={css.lead}>
                    This newsroom has the following notifications settings (read-only)
                    <br />
                    Set the notifications for your newsroom here:
                </p>
                <ul className={css.list}>
                    {Object.entries(rules).map(([ruleName, { name }]) => (
                        <li key={ruleName}>
                            <Checkbox readOnly label={`No ${name}`} checked={!!qualityRules[ruleName]} />
                        </li>
                    ))}
                </ul>
            </div>

            <div className={css.content}>
                <p className={css.lead}>When would you like to be notified?</p>
                <ul className={css.list}>
                    <li>
                        <Checkbox
                            readOnly
                            label="Before publishing asset"
                            checked={canRenderAssetQualityForNewsroom(newsroom, placements.publishingAsset)}
                        />
                    </li>
                    <li>
                        <Checkbox
                            readOnly
                            label="In the list view"
                            checked={canRenderAssetQualityForNewsroom(newsroom, placements.assetList)}
                        />
                    </li>
                </ul>
            </div>
        </section>
    );
}
