import { arrayToTree } from 'performant-array-to-tree';

export function getBreadCrumbs(startId, categories) {
    const breadCrumbs = [];

    function findCrumbs(catId) {
        const foundCategory = categories[catId];

        if (foundCategory && foundCategory.parentId !== 0) {
            breadCrumbs.push(findCrumbs(foundCategory.parentId, categories));
        }

        return foundCategory;
    }

    const crumbs = findCrumbs(startId);

    if (crumbs) {
        breadCrumbs.push(crumbs);
    }

    return breadCrumbs;
}

function flatten(depth) {
    return (acc, { data, children }) => {
        const value = acc.concat({
            value: data.id,
            label: data.title,
            showCategory: data.showCategory,
            childrenCount: children.length,
            depth,
        });

        if (children.length) {
            return children.reduce(flatten(depth + 1), value);
        }

        return value;
    };
}

export function generateCategoryTree(categoriesObj) {
    const categories = Object.values(categoriesObj);

    return arrayToTree(
        categories.map(({ parentId, ...rest }) => ({
            ...rest,
            parentId: parentId === 0 ? null : parentId,
        }))
    );
}

export function getCategoriesSubTree(parentId, categoriesObj) {
    const tree = generateCategoryTree(categoriesObj);

    if (!parentId) {
        return {
            children: tree,
        };
    }

    const breadCrumbs = getBreadCrumbs(parentId, categoriesObj);

    return breadCrumbs.reduce((prevSubtree, { id }, index) => {
        const isLastCrumb = index === breadCrumbs.length - 1;

        const subTree = prevSubtree.find((node) => node.data.id === id);

        return isLastCrumb ? subTree : subTree.children;
    }, tree);
}

export function generateSelectOptionsWithDepth(categoriesObj) {
    const tree = generateCategoryTree(categoriesObj);
    return tree.reduce(flatten(1), []);
}
