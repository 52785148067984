/**
 * @typedef {Object} Story
 * @property {String} id
 * @property {String} title
 */

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.id
 * @return {Promise<Array<Story>>}
 */
export async function fetchStories({ provider, id }) {
    return this.get(`v1/plan3/{PROVIDER}/stories/${id}`, { provider });
}
