import { useWatch, type UseFormReturn } from 'react-hook-form';
import { LiveStreamCustomDVR } from 'services/admin-bff/contants';
import type { EncoderInputFormData } from './types';

export const useDvrWarning = ({ formApi: { control } }: { formApi: UseFormReturn<EncoderInputFormData> }): string => {
    const [encoderDuration, dvrLength = LiveStreamCustomDVR.STANDARD] = useWatch({
        name: ['encoderDuration', 'dvrLength'],
        control,
    });

    if (encoderDuration > dvrLength) {
        return 'Encoder duration is greater than DVR length';
    }
    return '';
};
