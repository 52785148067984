import { useSelector } from 'react-redux';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { getIsFetchingSchedulesForProvider } from 'store/schedules/selectors';
import { SCHEDULES_SCOPE } from 'store/schedules/constants';
import { relative as getRelativeDate } from 'lib/date';
import { getProgressLabel, isAudio } from 'models/asset';
import { useAssetSchedulePublishTime } from 'store/schedules/hooks';
import type { IngestState } from './VideoSummary';
import css from './Heading.module.scss';

const getStatusText = (asset: Asset, scheduleTime: number, isFetchingSchedules: boolean): string | undefined => {
    if (isFetchingSchedules) {
        return 'Loading...';
    }

    const { status, published } = asset;

    if (status === 'active') {
        return `Published  ${getRelativeDate(published * 1000)}`;
    }
    if (scheduleTime) {
        return `Scheduled on ${getRelativeDate(scheduleTime * 1000)}`;
    }
    if (status === 'inactive') {
        return 'Unpublished';
    }

    if (status === 'draft') {
        return 'Incoming';
    }

    return 'Ready to be published';
};

const getFlightTimesText = (asset: Asset, isFetchingSchedules: boolean): string | undefined => {
    if (isFetchingSchedules) {
        return undefined;
    }

    const { created, flightTimes } = asset;

    const flightTimesStart = created === flightTimes?.start || isAudio(asset) ? undefined : flightTimes?.start;
    const flightTimesEnd = isAudio(asset) ? undefined : flightTimes?.end;

    if (flightTimesStart && flightTimesEnd) {
        return `flight times from ${getRelativeDate(flightTimesStart * 1000)} to ${getRelativeDate(
            flightTimesEnd * 1000
        )}`;
    }
    if (flightTimesStart) {
        return `flight times from ${getRelativeDate(flightTimesStart * 1000)}`;
    }
    if (flightTimesEnd) {
        return `flight times to ${getRelativeDate(flightTimesEnd * 1000)}`;
    }

    return undefined;
};

const getDistributeAfterText = (
    asset: Asset,
    scheduleTime: number,
    isFetchingSchedules: boolean
): string | undefined => {
    if (isFetchingSchedules) {
        return undefined;
    }

    const distributeAfter = Number(asset?.additional?.metadata?.distributeAfter);
    const shouldBeShown = asset.status === 'active' || scheduleTime;

    if (distributeAfter && shouldBeShown) {
        return `external distribution on ${getRelativeDate(distributeAfter * 1000)}`;
    }

    return undefined;
};

type HeadingProps = {
    asset: Asset;
    ingestState: IngestState;
};

export function Heading({ asset, ingestState }: HeadingProps) {
    const scheduleTime = useAssetSchedulePublishTime({ provider: asset.provider, id: asset.id });
    const isFetchingSchedules = useSelector((state) =>
        getIsFetchingSchedulesForProvider(state, { provider: asset.provider, scope: SCHEDULES_SCOPE.ASSET })
    );

    const progressLabel = getProgressLabel({ ...ingestState, progress: Number(ingestState.progress).toFixed(0) });
    const assetStatus = `STATUS: ${getStatusText(asset, scheduleTime, isFetchingSchedules)}`;

    return (
        <div className={css.heading}>
            {[
                progressLabel || assetStatus,
                getFlightTimesText(asset, isFetchingSchedules),
                getDistributeAfterText(asset, scheduleTime, isFetchingSchedules),
            ]
                .filter(Boolean)
                .join(', ')}
        </div>
    );
}
