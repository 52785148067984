import TravoltaGif from 'gifs/so-really.gif';

import css from './AssetNotFound.module.scss';

export function AssetNotFound() {
    return (
        <div className={css.error}>
            Requested asset can&apos;t be found
            <img alt="404" src={TravoltaGif} className={css.travolta} />
        </div>
    );
}
