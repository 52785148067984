import config from 'config';

// eslint-disable import/no-cycle
import GraphQLClient from './graphql-client';
import preRequest from './api-client/pre-request';
import * as live from './admin-bff/live';
import * as streamConfiguration from './admin-bff/stream-configuration';
import * as origin from './admin-bff/origin';
import * as assetsHistory from './admin-bff/assets-history';
import * as streamConverter from './admin-bff/stream-converter';
import * as lastAssetIngest from './admin-bff/last-asset-ingest';
import * as playlists from './admin-bff/playlists';
import * as scheduler from './admin-bff/scheduler';
import * as yats from './admin-bff/yats';
import * as pushNotifications from './admin-bff/push-notifications';

const adminBffClient = new GraphQLClient({ baseUrl: config.adminBff.host });

Object.entries({
    preRequest,
    ...live,
    ...streamConfiguration,
    ...origin,
    ...assetsHistory,
    ...streamConverter,
    ...lastAssetIngest,
    ...playlists,
    ...scheduler,
    ...yats,
    ...pushNotifications,
}).forEach(([name, func]) => {
    if (typeof func === 'function') {
        adminBffClient[name] = func.bind(adminBffClient);
    }
});

export default adminBffClient;
