import deepmerge from 'deepmerge';
import { diff } from 'deep-diff';

export function toArray(object) {
    return Object.keys(object).map((key) => object[key]);
}

/**
 * Get list of paths to values that differ between two objects
 * It performs deep comparison but treats arrays as scalar values
 *
 * @param {Object} leftObj
 * @param {Object} rightObj
 * @return {Array}
 */
export function getGeneralDiffPaths(leftObj, rightObj) {
    const diffResults = diff(leftObj, rightObj);
    if (!diffResults) return [];

    const paths = diffResults.map((res) =>
        res.path
            .reduce((path, key, index) => (!Number.isInteger(key) && index === path.length ? [...path, key] : path), [])
            .join('.')
    );

    return [...new Set(paths)];
}

/**
 * Default array merging algorithm pre-version-2.0.0 of deepmerge package
 * @see https://github.com/TehShrike/deepmerge#arraymerge-example-combine-arrays
 * @param {Array} target
 * @param {Array} source
 * @param {Object} options
 * @returns {Array}
 */
function combineMerge(target, source, options) {
    const destination = target.slice();

    source.forEach((item, index) => {
        if (typeof destination[index] === 'undefined') {
            destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
        } else if (options.isMergeableObject(item)) {
            destination[index] = deepmerge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
            destination.push(item);
        }
    });
    return destination;
}

export function merge<T1, T2>(target: Partial<T1>, source: Partial<T2>, options = { arrayMerge: combineMerge }) {
    const { arrayMerge, ...otherOptions } = options;
    return deepmerge<T1, T2>(target, source, { arrayMerge, ...otherOptions });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getObjectPaths(obj: any, parentKey = ''): string[] {
    const paths: string[] = [];

    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            const currentPath = parentKey ? `${parentKey}.${key}` : key;

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                const nestedPaths = getObjectPaths(obj[key], currentPath);
                paths.push(...nestedPaths);
            } else {
                paths.push(currentPath);
            }
        }
    }

    return paths;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getObjectValue(obj: any, path: string | string[]) {
    const pathArray = !Array.isArray(path) ? path.split('.') : path;
    return pathArray.reduce((res, key) => res?.[key], obj);
}
