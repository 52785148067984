import z from 'zod';
import { reportMessageToSentry } from 'lib/error';
import type { SubtitlesFormValues } from '../types';

const TOTAL_STORED_SUBTITLES = 20;
const BASE_CACHE_KEY = 'subtitles-editor';

const storedValuesSchema = z
    .object({
        lastEditedAt: z.number(),
        cues: z.array(
            z.object({
                id: z.string(),
                startTime: z.number(),
                endTime: z.number(),
                text: z.string().nullable(),
            })
        ),
    })
    .passthrough();

export function useSubtitleCuesLocalStore({
    provider,
    assetId,
    language,
}: {
    provider: string;
    assetId: number;
    language: string;
}) {
    const subtitleKey = `${provider}:${assetId}:${language}`;
    const storageKey = `${BASE_CACHE_KEY}:${subtitleKey}`;

    const getStorage = () => window.localStorage;

    const getSubtitles = ():
        | { formValues: SubtitlesFormValues; lastEditedAt: number }
        | { formValues: undefined; lastEditedAt: undefined } => {
        const value = getStorage().getItem(storageKey);
        if (!value) {
            return { formValues: undefined, lastEditedAt: undefined };
        }

        try {
            const { lastEditedAt, ...formValues } = storedValuesSchema.parse(JSON.parse(value));
            return {
                // @TODO: remove casting when switching to strict TS
                // @see https://stackoverflow.com/questions/71185664/why-does-zod-make-all-my-schema-fields-optional
                formValues: formValues as SubtitlesFormValues,
                lastEditedAt,
            };
        } catch (error) {
            reportMessageToSentry({
                message: 'Unable to parse cached subtitles',
                extras: { error },
            });
            return { formValues: undefined, lastEditedAt: undefined };
        }
    };

    const clearSubtitles = () => {
        getStorage().removeItem(storageKey);

        try {
            const savedList = JSON.parse(getStorage().getItem(BASE_CACHE_KEY) || '[]') as string[];
            const list = savedList.filter((item) => item !== subtitleKey);

            getStorage().setItem(BASE_CACHE_KEY, JSON.stringify(list));
        } catch (error) {
            reportMessageToSentry({
                message: 'Unable to clear recently edited subtitles',
                extras: { error },
            });
        }
    };

    const setSubtitles = (formValues: SubtitlesFormValues) => {
        const cacheItem = JSON.stringify({
            lastEditedAt: Date.now(),
            ...formValues,
        });

        getStorage().setItem(storageKey, cacheItem);

        try {
            const savedList = JSON.parse(getStorage().getItem(BASE_CACHE_KEY) || '[]') as string[];
            const list = [...new Set([subtitleKey, ...savedList])];

            while (list.length > TOTAL_STORED_SUBTITLES) {
                const item = list.pop();
                getStorage().removeItem(`${BASE_CACHE_KEY}:${item}`);
            }

            getStorage().setItem(BASE_CACHE_KEY, JSON.stringify(list));
        } catch (error) {
            reportMessageToSentry({
                message: 'Unable to clean up recently edited subtitles',
                extras: { error },
            });
        }
    };

    return {
        clearSubtitles,
        getSubtitles,
        setSubtitles,
    };
}
