import { useCallback, useContext } from 'react';
// TODO: replace it with build in hook after migrating to React 18
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { type TimelineState, TimelineStateContext } from '../TimelineContext';

export function useTimelineState() {
    const context = useContext(TimelineStateContext);
    if (!context) {
        throw new Error('Timeline state not found');
    }
    return context;
}

export function useSubscribeTimelineState<ReturnValue>(selector: (state: TimelineState) => ReturnValue): ReturnValue {
    const { getState, subscribe } = useTimelineState();
    const getSnapshot = useCallback(() => selector(getState()), [getState, selector]);

    return useSyncExternalStore(subscribe, getSnapshot);
}
