import { put, takeEvery } from '@redux-saga/core/effects';
import {
    fetchStreamConversionProgress,
    fetchStreamConversionSuccess,
    fetchStreamConversionFailure,
} from 'store/video/stream-conversion/actions';
import { matchMqttTopic } from 'store/mqtt/listeners/utils';

function* conversionMqttSaga({ type, data }) {
    const [, , provider, id, , action] = type.split('/');
    const { progress, error } = data;

    switch (action) {
        case 'progress':
            yield put(fetchStreamConversionProgress(provider, id, progress.percent, data.url));
            break;
        case 'end':
            yield put(fetchStreamConversionSuccess(provider, id, data.url));
            break;
        case 'error':
            yield put(fetchStreamConversionFailure(provider, id, error));
            break;
        default:
    }
}

export default [takeEvery((action) => matchMqttTopic(action, 'convert', 4), conversionMqttSaga)];
