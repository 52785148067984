import { call, spawn, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import { adminBffClient } from 'services';
import { checkAssetState } from 'store/video/cutting/sagas';
import {
    ADD_NAVIGATION_TAB,
    ACTIVATE_NAVIGATION_TAB,
    REMOVE_NAVIGATION_TAB,
} from 'store/video/navigation-tabs/actions';
import SELECT_NEWSROOM from 'store/newsroom/actionTypes';

import { watchForMqttEvents, unsubscribe } from './client';

export function* addNavigationTabSaga({ provider, id }) {
    try {
        const state = yield call(adminBffClient.getLastAssetIngest, { provider, id });
        yield call(checkAssetState, state);

        yield spawn(watchForMqttEvents, { topic: `ingest/${provider}/${id}/+/+` });
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to activate navigation tab',
            extras: {
                error,
            },
        });
    }
}

let selectedNewsroom;

export function* selectNewsroomSaga({ newsroom }) {
    if (selectedNewsroom === newsroom) return;

    if (selectedNewsroom) {
        yield call(unsubscribe, { topic: `${selectedNewsroom}/asset/+` });
        yield call(unsubscribe, { topic: `live/+/${selectedNewsroom}/#` });
    }
    yield spawn(watchForMqttEvents, { topic: `${newsroom}/asset/+` });

    yield spawn(watchForMqttEvents, { topic: `live/+/${newsroom}/#` });

    selectedNewsroom = newsroom;
}

export function* removeNavigationTabSaga({ provider, id }) {
    try {
        yield call(unsubscribe, { topic: `ingest/${provider}/${id}/+/+` });
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to remove navigation tab',
            extras: {
                error,
            },
        });
    }
}

export default [
    takeLatest([ADD_NAVIGATION_TAB, ACTIVATE_NAVIGATION_TAB], addNavigationTabSaga),
    takeEvery(REMOVE_NAVIGATION_TAB, removeNavigationTabSaga),
    takeEvery(SELECT_NEWSROOM, selectNewsroomSaga),
];
