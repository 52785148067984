import omit from 'lodash/omit';
import { call, put, take, takeEvery, select, race } from '@redux-saga/core/effects';
import * as ReactGA from 'react-ga';
import { merge } from 'lib/object';
import { reportMessageToSentry } from 'lib/error';
import assetsParsePatchErrors from 'store/assets/helpers';
import { Notification } from '@schibsted-svp/react-ui';

import { getDefaultCategoryFor } from 'config';
import * as Asset from 'models/asset';
import { getAsset } from 'store/assets/selectors';
import { apiClient } from 'services';

import * as ActionType from './actionTypes';
import {
    createAssetSuccess,
    fetchAssetSuccess,
    fetchAssetError,
    saveAsset as saveAssetAction,
    saveAssetSuccess,
    saveAssetError,
    assignPlaylistSuccess,
    unassignPlaylistSuccess,
    assignPlaylistError,
    unassignPlaylistError,
} from './actions';

function* parseResponseError(error) {
    if (!error.response) {
        return undefined;
    }

    const body = yield error.response.json();
    const { headers, ok, status, statusText } = error.response;
    return {
        body,
        headers: Array.from(headers),
        ok,
        status,
        statusText,
    };
}

export function* fetchAsset({ id, provider }) {
    try {
        const asset = yield call(apiClient.fetchAsset, { provider, id });

        yield put(fetchAssetSuccess({ id, asset }));
    } catch (error) {
        const responseError = yield parseResponseError(error);
        yield put(fetchAssetError({ id, provider, error: responseError }));
    }
}

/**
 * @param {Number} assetId
 * @returns {IterableIterator<Object>}
 */
export function* waitForAssetFetch(assetId) {
    while (true) {
        const { error, id, asset } = yield take([ActionType.ASSET_FETCH_ERROR, ActionType.ASSET_FETCH_SUCCESS]);

        if (id === assetId) {
            if (error) throw error;
            return asset;
        }
    }
}

/**
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.provider
 * @param {Object} params.changes
 * @param {Function} params.resolve
 * @param {Object} params.reject
 */
export function* saveAsset({ id, provider, changes, resolve, reject }) {
    try {
        // prepare changes in the asset to match the api schema
        const parsedChanges = Asset.prepareChanges(changes);

        const response = yield call(apiClient.saveAsset, { provider, id, changes: parsedChanges });
        // dispatch the api response to the store
        yield put(saveAssetSuccess({ id: response.id, asset: response }));

        if (resolve) {
            // resolve the promise that spawned this saga
            yield call(resolve);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            // gather all validation errors from the response
            const responseError = yield assetsParsePatchErrors(error);
            reportMessageToSentry({
                message: `${error.response.status}: Failed to save asset - there are errors in validation`,
                extras: {
                    error: responseError,
                },
            });
            if (reject) {
                // reject the promise that spawned this saga
                yield call(reject, responseError);
            }
        } else {
            const saveErrorMessage = 'Failed to save asset';
            const parsedResponseError = yield parseResponseError(error);
            const responseError = parsedResponseError || saveErrorMessage;

            reportMessageToSentry({
                message: `${error?.response?.status || ''}: ${saveErrorMessage}`,
                extras: {
                    error: responseError,
                },
            });
            // log the errors to the timeline
            yield put(saveAssetError({ id, provider, error: responseError }));
            if (reject) {
                Notification.notify.error(responseError);

                // reject the promise that spawned this saga
                yield call(reject, responseError);
            }
        }
    }
}

export function* createAsset({ provider, asset: data }) {
    try {
        const changes = merge(
            {
                category: {
                    id: getDefaultCategoryFor(provider),
                },
            },
            data
        );
        const asset = yield call(apiClient.saveAsset, { provider, changes });

        yield put(createAssetSuccess(asset));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to create asset',
            extras: {
                error,
            },
        });
    }
}

export function* assignPlaylist({ provider, id, playlistId }) {
    try {
        const asset = yield select(getAsset, { provider, id });
        const metadata = Asset.getMetadata(asset);
        yield put(
            saveAssetAction({
                id,
                provider,
                changes: {
                    additional: {
                        nextAsset: null,
                        metadata: {
                            ...metadata,
                            playlistId,
                        },
                    },
                },
            })
        );
        const { success, error } = yield race({
            success: take(ActionType.ASSET_SAVE_SUCCESS),
            error: take(ActionType.ASSET_SAVE_ERROR),
        });
        if (success) {
            yield put(assignPlaylistSuccess({ provider, id }));
        }
        if (error) {
            yield put(assignPlaylistError({ provider, id }));
        }
    } catch (error) {
        yield put(assignPlaylistError({ provider, id }));
    } finally {
        yield call(ReactGA.event, {
            category: 'Video',
            action: 'Next video',
            label: 'Playlist',
        });
    }
}

export function* unassignPlaylist({ provider, id }) {
    try {
        const asset = yield select(getAsset, { provider, id });
        const metadata = Asset.getMetadata(asset);
        yield put(
            saveAssetAction({
                id,
                provider,
                changes: {
                    additional: {
                        metadata: omit(metadata, 'playlistId'),
                    },
                },
            })
        );
        const { success, error } = yield race({
            success: take(ActionType.ASSET_SAVE_SUCCESS),
            error: take(ActionType.ASSET_SAVE_ERROR),
        });
        if (success) {
            yield put(unassignPlaylistSuccess({ provider, id }));
        }
        if (error) {
            yield put(unassignPlaylistError({ provider, id }));
        }
    } catch (error) {
        yield put(unassignPlaylistError({ provider, id }));
    }
}

export default [
    takeEvery(ActionType.ASSET_FETCH, fetchAsset),
    takeEvery(ActionType.ASSET_SAVE, saveAsset),
    takeEvery(ActionType.ASSET_CREATE, createAsset),
    takeEvery(ActionType.ASSET_ASSIGN_PLAYLIST, assignPlaylist),
    takeEvery(ActionType.ASSET_UNASSIGN_PLAYLIST, unassignPlaylist),
];
