import { connect } from 'react-redux';
import config from 'config';
import { get } from 'lodash';
import { nextSearch } from 'store/ui/assetForm/actions';
import { activeOptions } from 'lib/array';
import { defaultSortType } from 'store/asset-list/reducers';
import { withUserPermissions } from 'components/AssetForm/misc/withUserPersmissions';

import VideoSearch from './VideoSearch';

const defaultStreamTypes = ['vod', 'wasLive'];
const defaultStatuses = ['active'];
const defaultAssetType = ['video'];

const defaultSelectedFilter = {
    types: activeOptions(get(config, 'asset.labels.streamType'), defaultStreamTypes),
    statuses: activeOptions(get(config, 'asset.labels.status'), defaultStatuses),
    assetTypes: activeOptions(get(config, 'asset.labels.assetType'), defaultAssetType),
};

export const getTypeSpecificFilters = ({ selectedFilters, assetType }) => {
    const audio = get(config, 'asset.labels.assetType.audio');
    const video = get(config, 'asset.labels.assetType.video');

    const audioDefaultFilters = { isPodcast: true, assetTypes: [{ label: audio, value: audio }] };
    const videoDefaultFilters = { assetTypes: [{ label: video, value: video }] };

    return {
        ...defaultSelectedFilter,
        ...selectedFilters,
        ...(assetType === audio ? audioDefaultFilters : videoDefaultFilters),
    };
};

const mapStateToProps = (state, ownProps) => {
    const { provider, id, assetType } = ownProps;
    const { sortType, selectedFilters } = get(state, `ui.assetForm.search.${provider}.${id}`, {});

    const filters = getTypeSpecificFilters({ selectedFilters, assetType });

    return {
        sortType: ownProps.sortType || sortType || defaultSortType,
        selectedFilters: ownProps.selectedFilters || filters || defaultSelectedFilter,
    };
};

const enhance = connect(mapStateToProps, {
    nextSearch,
});

export default withUserPermissions(enhance(VideoSearch));
