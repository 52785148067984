import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { isTargetExternal } from 'lib/url';

// eslint-disable-next-line react/prefer-stateless-function
export default class AssetTab extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        children: PropTypes.node,
    };

    static defaultProps = {
        children: undefined,
    };

    render() {
        const { label, target } = this.props;

        if (isTargetExternal(target)) {
            return (
                <ReactGA.OutboundLink eventLabel={label} to={target} target="_blank" rel="noopener noreferrer">
                    {label} <FaExternalLinkAlt />
                </ReactGA.OutboundLink>
            );
        }

        return <NavLink to={target}>{label}</NavLink>;
    }
}
