import { useSelector } from 'react-redux';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { getVideoUpload } from 'store/video/upload/selectors';
import { getCutting } from 'store/video/cutting/selectors';
import { getConversion } from 'store/video/stream-conversion/selectors';
import { getIngestState } from 'models/asset';
import { Heading } from './Heading';
import { Caption } from './Caption';
import { ProgressBar } from './ProgressBar';
import css from './VideoSummary.module.scss';

export type IngestState = {
    type: string;
    state: string;
    progress: number;
    secondsLeft: number;
};

const useIngestState = ({ provider, id }: { provider: Asset['provider']; id: Asset['id'] }): IngestState => {
    const ingestState = useSelector((state) =>
        getIngestState({
            upload: getVideoUpload(state, { provider, id }),
            trimming: getCutting(state, { provider, id }),
            conversion: getConversion(state, { provider, id }),
        })
    );
    return {
        type: ingestState.type || '',
        state: ingestState.state || 'done',
        progress: ingestState.progress || 0,
        secondsLeft: ingestState.secondsLeft || 0,
    };
};

type VideoSummaryProps = {
    asset: Asset;
};

export function VideoSummary({ asset }: VideoSummaryProps) {
    const ingestState = useIngestState({ provider: asset.provider, id: asset.id });
    return (
        <div className={css.container}>
            <Heading asset={asset} ingestState={ingestState} />
            <Caption id={asset.id} />
            <ProgressBar state={ingestState.state} progress={ingestState.progress} />
        </div>
    );
}
