import { reportMessageToSentry } from 'lib/error';

const PREFERRED_ASSET_LIST_TYPE_KEY = 'svpPreferredAssetListType';
const DEFAULT_ASSET_LIST_TYPE = 'video';

/**
 *
 * @param {Object} params
 * @param {String} params.assetsListType
 * @returns {void}
 */
export const setPreferredAssetListType = ({ assetsListType }) => {
    try {
        window.localStorage.setItem(PREFERRED_ASSET_LIST_TYPE_KEY, assetsListType);
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to set preferred asset list type',
            extras: {
                error,
            },
        });
    }
};

/**
 * @returns {String}
 */
export const getPreferredAssetListType = () => {
    try {
        return window.localStorage.getItem(PREFERRED_ASSET_LIST_TYPE_KEY) || DEFAULT_ASSET_LIST_TYPE;
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to get preferred asset list type',
            extras: {
                error,
            },
        });
        return DEFAULT_ASSET_LIST_TYPE;
    }
};
