/* eslint-disable no-restricted-globals */

const formatter = new Intl.NumberFormat('en-GB');

function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

function format(number) {
    return formatter.format(number).replace(/,/g, ' ');
}

// remaps a number to a percantage string, where 0-1 is 0-100%
function toPercentageStr(number, fixed = 2) {
    return `${(number * 100).toFixed(fixed)}%`;
}

export { format, toPercentageStr, isNumeric };
