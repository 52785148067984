import { Component } from 'react';
import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';

import { Spinner } from '@schibsted-svp/react-ui';
import NavigationTab from '../NavigationTab';
import ListIcon from '../icons/ListIcon';

const bem = new BEM('video-navigation');

export default class NewsroomTabContainer extends Component {
    static propTypes = {
        newsroom: PropTypes.string.isRequired,
        videoList: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            number: PropTypes.number.isRequired,
            hasMore: PropTypes.bool.isRequired,
        }).isRequired,
        searchTerm: PropTypes.string.isRequired,
    };

    get location() {
        const { newsroom, searchTerm } = this.props;

        const location = {
            pathname: `/${newsroom}/assets`,
        };

        if (searchTerm) {
            location.search = `?query=${searchTerm}`;
        }

        return location;
    }

    get videosCount() {
        const { videoList } = this.props;

        let numberOfVideos = '\u2026';

        if (!videoList.loading) {
            numberOfVideos = String(videoList.number);
            if (videoList.hasMore) {
                numberOfVideos += '+';
            }
        }

        return numberOfVideos;
    }

    render() {
        const { videoList, newsroom } = this.props;

        return (
            <NavigationTab locationPath={this.location}>
                {videoList.loading && videoList.number > 0 ? (
                    <Spinner containerClassName={bem.element('tab-spinner')} size="compact" />
                ) : (
                    <ListIcon />
                )}
                <div className={bem.element('tab-title')}>
                    {newsroom} ({this.videosCount})
                </div>
            </NavigationTab>
        );
    }
}
