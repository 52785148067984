import type { Control } from 'react-hook-form';
import type { CategoryData } from 'components/categories/Form/types';
import { useWatch } from 'react-hook-form';
import { PodcastMainCategory } from './PodcastMainCategory';
import { PodcastSubCategory } from './PodcastSubCategory';
import { getSubcategories } from './helpers';

import css from './PodcastCategoriesSelect.module.scss';

export function PodcastCategoriesSelect({ control }: { control: Control<CategoryData> }) {
    const selectedMainCategories = useWatch({ control, name: 'additional.metadata.podcast_mainCategory' }) || [];

    return (
        <div className={css.container}>
            <PodcastMainCategory control={control} />

            {selectedMainCategories.map((mainCategory) => {
                const subcategories = getSubcategories(mainCategory);

                if (!subcategories.length) {
                    return null;
                }

                return (
                    <PodcastSubCategory
                        key={mainCategory}
                        control={control}
                        mainCategory={mainCategory}
                        subcategories={subcategories}
                    />
                );
            })}
        </div>
    );
}
