import { srt2webvtt } from './subtitles';
import { removeDuplicatedNewLines } from './string';

export function readFileFromDisk(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = (event) => resolve(event.target.result);
        reader.onerror = () => reject(new TypeError('Unable to read the selected file'));
        reader.readAsDataURL(file);
    });
}

export function fromBlob(blob: Blob, filename: string) {
    return new File([blob], filename, { type: blob.type });
}

export function getMediaType(file: File) {
    if (file?.type.startsWith('audio/')) {
        return 'audio';
    }
    if (file?.type.startsWith('video/')) {
        return 'video';
    }
    return undefined;
}

export function isVideoType(file: File) {
    return /^video\//.test(file.type);
}

export function isAudioOrVideoType(file: File) {
    return /^(audio|video)\//.test(file.type);
}

export function isImageType(file: File) {
    return file.type.startsWith('image/');
}

export function isSubtitleType(file: File) {
    return file.name.search(/\.((web)?vtt)|srt$/) !== -1;
}

function srt2vtt(subtitleFileContent: string) {
    try {
        return srt2webvtt(subtitleFileContent);
    } catch (error) {
        throw new Error(error);
    }
}

const vttSignature = 'WEBVTT';

export async function getSubtitleFile(file: File) {
    const uploadedFileResponse = new Response(file);
    const uploadedFileContent = await uploadedFileResponse.text();
    const subtitleFileContent = removeDuplicatedNewLines(uploadedFileContent);
    const [firstLine] = subtitleFileContent.match(/^(.*)$/m);
    if (firstLine === vttSignature) {
        return new File([subtitleFileContent], file.name);
    }
    if (Number(firstLine) !== 1) {
        throw new Error('Invalid file format');
    }
    const vttContent = srt2vtt(subtitleFileContent);

    return new File([vttContent], file.name.replace(/.srt$/i, '.vtt'));
}
