import { ReactEventHandler } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import classnames from 'classnames/bind';

import { Spinner } from '@schibsted-svp/react-ui';
import css from './Dropzone.module.scss';

const cln = classnames.bind(css);

const handleClick = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
};

export type DropzoneChildren = (props: {
    accept: string;
    disabled: boolean;
    loading?: boolean;
    multiple: boolean;
    onClick: ReactEventHandler<HTMLInputElement>;
    onChange: ReactEventHandler<HTMLInputElement>;
}) => ReactNode;

interface DropzoneProps {
    accept?: string;
    children?: DropzoneChildren;
    className?: string;
    disabled?: boolean;
    fixed?: boolean;
    loading?: boolean;
    onUpload: (file: File[]) => void;
    multiple?: boolean;
}

export function Dropzone({ accept, children, className, disabled, fixed, loading, onUpload, multiple }: DropzoneProps) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: NativeTypes.FILE,
        drop: (item: { files: File[] }, monitor) => {
            const { files = [] } = item;
            if (monitor.didDrop()) return;

            onUpload(files);
        },

        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
        }),

        canDrop: () => !(disabled || loading),
    });
    const handleChange = (event) => {
        onUpload(Array.from(event.target.files));
    };

    return (
        <section
            ref={drop}
            className={cln(
                css.dropzone,
                {
                    hovered: canDrop && isOver,
                    disabled,
                    fixed,
                    visible: (fixed && canDrop) || (fixed && loading),
                    isOver,
                    loading,
                },
                className
            )}
        >
            {!fixed
                ? children?.({
                      accept,
                      disabled,
                      multiple,
                      onClick: handleClick,
                      onChange: handleChange,
                  }) || (
                      <div className={css.content}>
                          {loading ? (
                              <Spinner containerClassName={css.spinner} />
                          ) : (
                              <>
                                  <AiOutlineCloudUpload className={css.icon} size={64} />
                                  <div className={css.label}>
                                      {multiple ? 'Drop files to upload' : 'Drop only one file to upload'}
                                      <br />
                                      or{' '}
                                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
                                      <label className={css.link}>
                                          browse
                                          <input
                                              data-testid="uploadInput"
                                              type="file"
                                              accept={accept}
                                              onClick={handleClick}
                                              onChange={handleChange}
                                              disabled={disabled}
                                              multiple={multiple}
                                              hidden
                                          />
                                      </label>
                                  </div>
                              </>
                          )}
                      </div>
                  )
                : loading && <Spinner />}
        </section>
    );
}
