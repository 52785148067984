import { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';
import { toPercentageStr } from 'lib/number';

import './Sprite.scss';

const bem = {
    sprite: new BEM('sprite'),
};

const Rectangle = PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
});

const defaultRect = { width: 0, height: 0 };

class Sprite extends PureComponent {
    // the amount of generated thumbnails
    static count({ expected, dimensions, image }) {
        return Math.min(
            expected,
            // take into consideration badly generated snapshots that have less items then they should
            // such previews will not display correctly based on the position but it's better than displaying nothing
            (image.width / dimensions.width) * (image.height / dimensions.height)
        );
    }

    // calculate the snapshot's position in the sprite
    static backgroundPos({ snapshotIdx, columns, rect }) {
        const row = parseInt(snapshotIdx / columns, 10);

        return {
            x: (snapshotIdx % columns) * rect.width,
            y: row * rect.height,
        };
    }

    static propTypes = {
        // the image used as the source of the sprite
        image: PropTypes.object.isRequired,
        // the width and height of a single frame in the source image
        dimensions: Rectangle,
        // the bounding rectangle of the element that will be used to display the sequence
        rect: Rectangle,
        // the expected number of frames in the source image
        expected: PropTypes.number.isRequired,
        // the current position in the sequence defining which frame to display in the [0,1) range
        position: PropTypes.number.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        dimensions: defaultRect,
        rect: defaultRect,
        className: '',
    };

    render() {
        const { className, image, dimensions, rect, expected, position } = this.props;

        // calculate the number of columns per row
        const columns = parseInt(image.width / dimensions.width, 10);
        const rows = parseInt(image.height / dimensions.height, 10);

        // snapshots might have a different size than the display container, scaling is required
        // which is exactly 100% (scaled to the size of the container) times the number of shots in a row
        const scaleX = columns;
        // and some adjustments for the Y scaling in case of mismatched ratios of the `dimensions` and `rect`
        const scaleY = rows;

        // the amount of generated thumbnails
        const count = Sprite.count({ expected, dimensions, image });

        // calculate which snapshot to use
        const snapshotIdx = parseInt(position * count, 10);

        // calculate the snapshot's position in the sprite
        const bgPos = Sprite.backgroundPos({ snapshotIdx, columns, rect });

        return (
            <div
                className={[bem.sprite.block(), className].join(' ')}
                style={{
                    backgroundSize: `${toPercentageStr(scaleX)} ${toPercentageStr(scaleY)}`,
                    backgroundPosition: `${-bgPos.x}px ${-bgPos.y}px`,
                    backgroundImage: `url(${image.src})`,
                }}
            />
        );
    }
}

export default Sprite;
