import type { Maybe } from 'types';

export interface LiveEventSchedule {
    startTime: Maybe<number>;
    endTime: Maybe<number>;
}

export interface LiveEventEncoder {
    id: string;
    name: string;
}

export interface LiveEventInput {
    id: number;
    name: string;
}

export interface LiveEvent {
    id: number;
    createTime: number;
    schedule?: Maybe<LiveEventSchedule>;
    status: string;
    dvrLength?: Maybe<number>;
    encoder?: Maybe<LiveEventEncoder>;
    input?: Maybe<LiveEventInput>;
    asset?: Maybe<{
        id: number;
        provider: string;
    }>;
}

export function getInputName(event: LiveEvent): string | undefined {
    return event?.input?.name;
}

export function getEncoderName(event: LiveEvent): string | undefined {
    return event?.encoder?.name;
}

export function getStartTime(event: LiveEvent): Maybe<number> {
    return event?.schedule?.startTime || null;
}

export function getEndTime(event: LiveEvent): Maybe<number> {
    return event?.schedule?.endTime || null;
}

export function isRunning(event: LiveEvent): boolean {
    return event?.status === 'running';
}

export function isPending(event: LiveEvent): boolean {
    return event?.status === 'pending';
}

export function hasError(event: LiveEvent): boolean {
    return event?.status === 'error';
}
