import Client from './client';

import getAdminVersion from './version-client/version';

const versionClient = new Client({ baseUrl: window.location.href });

Object.entries({
    getAdminVersion,
}).forEach(([k, v]) => {
    if (typeof v === 'function') {
        versionClient[k] = v.bind(versionClient);
    }
});

export default versionClient;
