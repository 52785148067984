import { merge } from 'lib/object';
import * as Actions from './actionTypes';
import categoriesSlice from './slice';

const defaultState = {
    isFetching: false,
    error: false,
    items: {},
};

function categories(state = {}, action) {
    const { provider } = action;

    switch (action.type) {
        case Actions.CATEGORIES_FETCH: {
            return merge(state, {
                [provider]: { ...defaultState, isFetching: true, error: false },
            });
        }

        case Actions.CATEGORIES_FETCH_SUCCESS: {
            return merge(state, {
                [provider]: {
                    items: action.categories.reduce((acc, category) => {
                        acc[category.id] = category;
                        return acc;
                    }, {}),
                    isFetching: false,
                    error: false,
                },
            });
        }

        case Actions.CATEGORIES_FETCHED: {
            return merge(state, {
                [provider]: {
                    isFetching: false,
                    error: false,
                },
            });
        }
        case Actions.CATEGORIES_FETCH_ERROR: {
            return merge(state, {
                [provider]: {
                    isFetching: false,
                    error: true,
                },
            });
        }
        case Actions.CATEGORY_VISIBILITY_TOGGLE_SUCCESS:
        case Actions.CATEGORY_FETCH_SUCCESS:
        case Actions.CATEGORY_SUBMISSION_SUCCESS: {
            const {
                payload: { provider, category }, // eslint-disable-line no-shadow
            } = action;

            return {
                ...state,
                [provider]: {
                    ...state[provider],
                    items: {
                        ...(state[provider] && state[provider].items),
                        [category.id]: category,
                    },
                },
            };
        }
        default:
            return categoriesSlice.reducer(state, action);
    }
}

export default categories;
