import { useState } from 'react';
import z, { ZodError } from 'zod';
import { Button, LabeledContainer, InputError } from '@schibsted-svp/react-ui';
import { usePlayer } from 'hooks/usePlayer';
import { TimeInput } from 'components/ui/Form/TimeInput';
import type { Midroll } from './Midrolls';
import css from './MidrollsForm.module.scss';

function createValidator(duration: number, excluded: number[]) {
    return z
        .number()
        .min(0, 'Start time cannot be less than 0')
        .max(duration, 'Start time cannot be grater than video duration')
        .refine((value) => !excluded.includes(value), 'Provided start time is already added');
}

type MidrollsFormProps = {
    duration: number;
    excluded: number[];
    submitDisabled: boolean;
    onMidrollAdd: (item: Midroll) => void;
};

export function MidrollsForm({ duration, excluded, submitDisabled, onMidrollAdd }: MidrollsFormProps) {
    const [value, setValue] = useState(0);
    const [error, setError] = useState<string>();

    const player = usePlayer();

    const handleValueChange = (newValue: number) => {
        setValue(Math.floor(newValue));
        setError(undefined);
    };

    const handleTimelineClick = () => {
        handleValueChange(player.currentTime);
    };

    const handleSubmit = () => {
        try {
            createValidator(duration, excluded).parse(value);
            onMidrollAdd({ timeline: value });
            setValue(0);
            setError(undefined);
        } catch (e) {
            if (e instanceof ZodError) {
                const formatted = e.format();
                // eslint-disable-next-line no-underscore-dangle
                const message = formatted._errors?.[0] ?? formatted[0]?._errors;
                setError(message);
            }
        }
    };

    return (
        <div className={css.container}>
            <LabeledContainer strict label="Start time">
                <TimeInput
                    size="small"
                    value={value}
                    maxValue={duration}
                    disabled={!player}
                    onValueChange={handleValueChange}
                />
            </LabeledContainer>

            <LabeledContainer>
                <Button type="button" size="small" variant="standard" onClick={handleTimelineClick} disabled={!player}>
                    Set start time
                </Button>
            </LabeledContainer>

            <LabeledContainer>
                <Button type="button" size="small" onClick={handleSubmit} disabled={submitDisabled || !player}>
                    Insert ad break
                </Button>
            </LabeledContainer>

            {error && <InputError className={css.error}>{error}</InputError>}
        </div>
    );
}
