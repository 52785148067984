import { useRef, useEffect } from 'react';
import { useCategoryPodmeMetadataQuery, useAssetPodmeMetadataQuery } from 'services/admin-bff-sdk/generated';
import type { AssetPodmeMetadata } from 'services/admin-bff-sdk/generated';
import type { Optional } from 'types';

const POLLING_INTERVAL = 5000;

const isPodmeMetadataPolling = (metadata: Optional<AssetPodmeMetadata>, status: string) => {
    if (!metadata) {
        return false;
    }

    const { sendToPodme, podmeId } = metadata;

    if (status !== 'active') {
        return Boolean(podmeId);
    }

    return Boolean((sendToPodme && !podmeId) || (!sendToPodme && podmeId));
};

export const useCategoryPodmeMetadata = ({
    provider,
    categoryId,
    skip,
}: {
    provider: string;
    categoryId: number;
    skip?: boolean;
}) => {
    const { data } = useCategoryPodmeMetadataQuery({ provider, categoryId }, { skip });
    const { podmeId, sendRssToPodme } = data?.categoryPodmeMetadata || {};
    return { isCategoryInPodme: Boolean(podmeId), sendCategoryRssToPodme: sendRssToPodme || false };
};

export const useAssetPodmeMetadata = ({
    provider,
    assetId,
    status,
}: {
    provider: string;
    assetId: number;
    status: string;
}): { podmeMetadata: AssetPodmeMetadata; isPodmeMetadataPolling: boolean } => {
    const podmeMetadataRef = useRef<AssetPodmeMetadata>();
    const isPolling = isPodmeMetadataPolling(podmeMetadataRef.current, status);

    const { data } = useAssetPodmeMetadataQuery(
        { provider, assetId },
        { pollingInterval: isPolling ? POLLING_INTERVAL : undefined }
    );
    const podmeMetadata = data?.assetPodmeMetadata;

    useEffect(() => {
        podmeMetadataRef.current = podmeMetadata;
    }, [podmeMetadata]);

    return { podmeMetadata, isPodmeMetadataPolling: isPolling };
};
