import { difference } from 'lodash';

const additionalColsSpaces = {
    tags: '5fr',
    category: '2fr',
    creationDate: '3fr',
    author: '2fr',
    provider: '2fr',
};

export const thumbnailSizes = {
    big: 232,
    normal: 124,
    compact: 93,
};

export function areAdditionalColsHidden(hiddenColumns, variant) {
    return difference(Object.keys(additionalColsSpaces), [...hiddenColumns]).length === 0 || variant === 'simple';
}

export function getAdditionalStyles(hiddenColumns) {
    return {
        gridTemplateColumns: Object.entries(additionalColsSpaces)
            .filter(([colName]) => !hiddenColumns.includes(colName))
            .map(([, space]) => space)
            .join(' '),
    };
}

export function getContainerStyles(hiddenColumns, variant, size = 'normal') {
    const spaces = [
        ...(!hiddenColumns.includes('preview') ? [`${thumbnailSizes[size]}px`] : []),
        '1fr',
        ...(!areAdditionalColsHidden(hiddenColumns, variant) ? ['1.5fr'] : []),
    ];

    return {
        gridTemplateColumns: spaces.join(' '),
    };
}
