import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm, useFormContext } from 'react-hook-form';
import { getNewsroomConfig } from 'config';
import useNewsroom from 'hooks/useNewsroom';
import { MediaLiveInputType } from 'services/admin-bff/contants';
import { getMediaLiveChannel } from 'store/live/selectors';
import { AudioChannelsMixType, AudioChannelsMixValuesStereo } from 'components/video/live/form/AudioConfiguration';
import type { InitialValues } from 'components/video/live/LiveCreationDialog/hooks';
import type { MediaLiveInputFormData, MediaLiveInputFormControl } from './types';

export const useMediaLiveInputForm = (initialValues: MediaLiveInputFormData) => {
    const formApi = useForm<MediaLiveInputFormData>({
        mode: 'all',
        defaultValues: initialValues,
    });
    return { formApi };
};

export const useMediaLiveInputFormContext = () => {
    const formApi = useFormContext<MediaLiveInputFormData>();
    return { formApi };
};

export const useMediaLiveInputFormFormSubmit = ({ onSubmit }: MediaLiveInputFormControl) => {
    const {
        formApi: { handleSubmit },
    } = useMediaLiveInputFormContext();

    return useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
};

// tmp helper type until config is typed properly
type NewsroomLiveConfig = { profile?: string };

export const useInitialValues = ({ initialValues, assetId }: { assetId: number; initialValues: InitialValues }) => {
    const newsroom = useNewsroom();
    const channel = useSelector((state) => getMediaLiveChannel(state, { provider: newsroom, assetId }));

    const isBackupStream = channel?.input?.urls.length === 2;
    const previousInputType = channel?.input?.type;

    const { profile: hlsProfile } = (getNewsroomConfig(newsroom)?.live as NewsroomLiveConfig) || {};

    return {
        useBackupEncoder: isBackupStream,
        inputType: previousInputType || MediaLiveInputType.PUSH,
        inputId: '',
        streamQuality: 'standard' as const,
        profile: hlsProfile ? { [hlsProfile as 'HLS1080']: true } : undefined,
        audioChannelsMix: {
            type: AudioChannelsMixType.STEREO,
            values: AudioChannelsMixValuesStereo,
        },
        ...initialValues,
    };
};
