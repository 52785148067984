import { Fragment } from 'react';
import { Dialog, Button, Divider, CopyToClipboard } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';
import config from 'config';
import { makeStreamTypeLabel } from 'models/asset';
import { getDateString, getFormattedTime, DATE_TIME_FORMAT } from 'lib/time';
import { relative as getRelativeDate } from 'lib/date';
import { capitalize } from 'lib/string';
import { PurePlayer } from 'components/core/Player';
import { sources } from 'components/core/hookForm/SourceSelect';
import { TagIcon } from 'components/core/TagIcon';
import type { HistoryData } from '../helpers';
import { FieldContainer } from './FieldContainer';
import {
    getStatusText,
    createIsFieldChanged,
    getFieldsChangesText,
    getMetadataChanges,
    formatEditedDate,
    formatPublishedDate,
    formatLastEditedBy,
    formatAgeLimit,
} from './helpers';
import { useSeriesData, useStoriesData, useTagsData, useViewerAccessInfo, useMetadata } from './hooks';

import css from './HistoryPreview.module.scss';

const cln = classnames.bind(css);

type HistoryPreviewProps = {
    data: HistoryData;
    isDialogOpen: boolean;
    closeDialog: () => void;
};

export function HistoryPreview({ data, isDialogOpen, closeDialog }: HistoryPreviewProps) {
    const { date, allChanges, changes, assetData } = data;
    const { category } = assetData;

    const viewerAccess = useViewerAccessInfo(assetData);

    const tags = useTagsData(assetData);
    const stories = useStoriesData(assetData);
    const series = useSeriesData(assetData);
    const metadata = useMetadata(assetData);

    const isFieldChanged = createIsFieldChanged(changes);
    const isMetadataChanged = createIsFieldChanged(getMetadataChanges(allChanges));

    return (
        <Dialog
            className={css.dialog}
            isOpen={isDialogOpen}
            heading={
                <>
                    History preview
                    <span className={css.headingInfo}>
                        <span className={css.date}>{getRelativeDate(date * 1000)}</span> -{' '}
                        {getFieldsChangesText(changes)}
                    </span>
                </>
            }
            headingClassName={css.heading}
            onClose={closeDialog}
        >
            <Dialog.Section className={css.container}>
                <div className={css.main}>
                    <FieldContainer label="Title" highlighted={isFieldChanged('title')} copyable>
                        {assetData.title}
                    </FieldContainer>
                    <FieldContainer label="Alternative title" highlighted={isFieldChanged('titleFront')} copyable>
                        {assetData.titleFront}
                    </FieldContainer>
                    <FieldContainer
                        label="Description"
                        fieldClassName={css.description}
                        highlighted={isFieldChanged('description')}
                        copyable
                    >
                        {assetData.description}
                    </FieldContainer>
                    <FieldContainer
                        label="Alternative description"
                        fieldClassName={css.description}
                        highlighted={isFieldChanged('descriptionFront')}
                        copyable
                    >
                        {assetData.descriptionFront}
                    </FieldContainer>
                    <FieldContainer label="Comment" highlighted={isFieldChanged('additional.settings.notes')} copyable>
                        {assetData.additional.settings.notes}
                    </FieldContainer>

                    <Divider margin="0" />

                    <FieldContainer label="Category" highlighted={isFieldChanged('category')}>
                        {category && (
                            <>
                                {category?.title} (ID: {category?.id})
                            </>
                        )}
                    </FieldContainer>
                    <FieldContainer
                        label="Viewer access"
                        highlighted={isFieldChanged(['streamConfiguration', 'additional.access'])}
                    >
                        {capitalize(viewerAccess, true)}
                    </FieldContainer>

                    <Divider margin="0" />

                    <FieldContainer
                        label="Tags"
                        fieldClassName={css.tags}
                        highlighted={isFieldChanged(['tags', 'additional.tags'])}
                    >
                        {tags.length &&
                            tags.map((tag) => (
                                <div key={tag.id} className={css.item}>
                                    <span className={cln('tagItem', 'purpleLabel')}>
                                        {tag.type && <TagIcon inheritColor tagType={tag.type} />}
                                        {tag.tag}
                                    </span>
                                </div>
                            ))}
                    </FieldContainer>
                    <FieldContainer
                        label="Stories"
                        fieldClassName={css.stories}
                        highlighted={isFieldChanged('additional.stories')}
                    >
                        {stories.length &&
                            stories.map((story) => (
                                <div key={story} className={css.item}>
                                    <span className={css.purpleLabel}>{story}</span>
                                </div>
                            ))}
                    </FieldContainer>
                    <FieldContainer label="Series" highlighted={isFieldChanged('series')}>
                        {series && (
                            <>
                                <span className={css.inlineLabel}>Season:</span> {series.season}
                                <span className={css.inlineLabel}>Episode:</span> {series.episode}
                            </>
                        )}
                    </FieldContainer>
                    <FieldContainer
                        label="Subtitles"
                        fieldClassName={css.subtitles}
                        highlighted={isFieldChanged('additional.subtitles')}
                    >
                        {assetData.additional.subtitles?.length &&
                            assetData.additional.subtitles.map((subtitle) => (
                                <div key={subtitle.language}>
                                    <a href={subtitle.url} className={css.purpleLabel} target="_blank" rel="noreferrer">
                                        {subtitle.url.split('/').pop()}
                                    </a>{' '}
                                    (
                                    {[config.languages[subtitle.language], subtitle.default && 'default']
                                        .filter(Boolean)
                                        .join(', ')}
                                    )
                                </div>
                            ))}
                    </FieldContainer>

                    <Divider margin="0" />

                    <FieldContainer
                        label="Source/contains material from"
                        highlighted={isFieldChanged('additional.settings.source')}
                    >
                        {sources[assetData.additional.settings.source]}
                    </FieldContainer>
                    <FieldContainer label="Article URL" highlighted={isFieldChanged('articleUrl')} copyable>
                        {assetData.articleUrl}
                    </FieldContainer>
                    <FieldContainer label="Age limit" highlighted={isFieldChanged('additional.settings.ageLimit')}>
                        {formatAgeLimit(assetData.additional.settings.ageLimit)}
                    </FieldContainer>
                    <FieldContainer
                        label="Hide from list"
                        highlighted={isFieldChanged('additional.settings.showInNewest')}
                    >
                        {!assetData.additional.settings.showInNewest && 'Yes'}
                    </FieldContainer>
                    <FieldContainer label="Disable ads" highlighted={isFieldChanged('additional.settings.showAds')}>
                        {!assetData.additional.settings.showAds && 'Yes'}
                    </FieldContainer>

                    <Divider margin="0" />

                    <FieldContainer
                        label="Metadata"
                        fieldClassName={css.metadata}
                        highlighted={isFieldChanged('additional.metadata')}
                    >
                        {metadata &&
                            metadata.map(({ key, value }) => (
                                <Fragment key={key}>
                                    <div
                                        className={cln('key', {
                                            highlighted: isMetadataChanged(`additional.metadata.${key}`),
                                        })}
                                    >
                                        {key}:
                                    </div>
                                    <div className={css.value}>
                                        <CopyToClipboard text={value} className={css.copy} />
                                    </div>
                                </Fragment>
                            ))}
                    </FieldContainer>
                </div>
                <div className={css.additional}>
                    <PurePlayer asset={assetData} />
                    <FieldContainer label="Image" highlighted={isFieldChanged('images')}>
                        <img src={assetData.images.main} alt="Poster preview" className={css.image} />
                    </FieldContainer>

                    <FieldContainer label="Status" highlighted={isFieldChanged('status')}>
                        {getStatusText(assetData.status)}
                    </FieldContainer>
                    <FieldContainer label="Flight times" highlighted={isFieldChanged('flightTimes')}>
                        <>
                            <span className={css.inlineLabel}>Start:</span>{' '}
                            {assetData.flightTimes.start > 0
                                ? getDateString(assetData.flightTimes.start, DATE_TIME_FORMAT)
                                : '-'}
                            <span className={css.inlineLabel}>End:</span>{' '}
                            {assetData.flightTimes.end > 0
                                ? getDateString(assetData.flightTimes.end, DATE_TIME_FORMAT)
                                : '-'}
                        </>
                    </FieldContainer>

                    <Divider margin="0" />

                    <FieldContainer label="Stream type" highlighted={isFieldChanged('streamType')}>
                        {makeStreamTypeLabel(assetData, config.asset.labels)}
                    </FieldContainer>
                    <FieldContainer label="External ID" highlighted={isFieldChanged('additional.externalId')}>
                        {assetData.additional.externalId}
                    </FieldContainer>
                    <FieldContainer label="Views" highlighted={isFieldChanged('displays')}>
                        {assetData.displays}
                    </FieldContainer>
                    <FieldContainer label="Duration" highlighted={isFieldChanged('duration')}>
                        {assetData.duration > 0 && getFormattedTime(assetData.duration)}
                    </FieldContainer>
                    <FieldContainer
                        label="Stream URL"
                        fieldClassName={css.streamUrl}
                        highlighted={isFieldChanged('streamUrls')}
                        copyable
                    >
                        {assetData.streamUrls.hls}
                    </FieldContainer>

                    <Divider margin="0" />

                    <FieldContainer
                        label="Modified"
                        highlighted={isFieldChanged(['updated', 'additional.settings.lastEditedBy'])}
                    >
                        {formatEditedDate(assetData.updated) || '-'}{' '}
                        {assetData.additional.settings?.lastEditedBy &&
                            `(${formatLastEditedBy(assetData.additional.settings?.lastEditedBy)})`}
                    </FieldContainer>
                    <FieldContainer label="Published" highlighted={isFieldChanged('published')}>
                        {formatPublishedDate(assetData.published)}
                    </FieldContainer>

                    <Divider margin="0" />

                    <FieldContainer label="Next video" highlighted={isFieldChanged('additional.nextAsset')}>
                        {assetData.additional.nextAsset?.id}
                    </FieldContainer>
                    <FieldContainer
                        label="Highlights"
                        fieldClassName={css.chapters}
                        highlighted={isFieldChanged('additional.chapters')}
                    >
                        {assetData.additional.chapters?.length &&
                            assetData.additional.chapters.map((chapter) => (
                                <div key={`chapter-${chapter.timeline}`} className={css.item}>
                                    <span className={css.purpleLabel}>{chapter.title}</span>
                                    {getFormattedTime({ seconds: chapter.timeline })}
                                </div>
                            ))}
                    </FieldContainer>
                    <FieldContainer
                        label="Ad breaks"
                        fieldClassName={css.cuePoints}
                        highlighted={isFieldChanged('additional.cuePoints')}
                    >
                        {assetData.additional.cuePoints?.length &&
                            assetData.additional.cuePoints.map((cuePoint) => (
                                <div key={`cuepoint-${cuePoint.timeline}`} className={css.item}>
                                    {getFormattedTime({ seconds: cuePoint.timeline })}
                                </div>
                            ))}
                    </FieldContainer>
                </div>
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={closeDialog}>
                    Cancel
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}
