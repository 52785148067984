import { Button, Dialog } from '@schibsted-svp/react-ui';
import { memo, useEffect, useRef } from 'react';
import * as ReactGA from 'react-ga';

import config from 'config';
import type { Maybe } from 'types';
import useToggle from 'hooks/useToggle';
import Tooltip from 'components/ui/Tooltip';

import { useGetAsset } from './hooks/useAsset';
import { SubtitlesEditorProvider } from './SubtitlesEditorProvider';
import type { SubtitlesFormRef } from './SubtitlesForm';
import css from './SubtitlesEditorDialog.module.scss';

interface SubtitlesEditorProps {
    assetId: number;
    provider: string;
    language: string;
    disabled?: boolean;
}

export const SubtitlesEditorDialog = memo<SubtitlesEditorProps>(({ assetId, provider, language, disabled = false }) => {
    const subtitlesFormRef = useRef<Maybe<SubtitlesFormRef>>(null);
    const [isOpen, toggleDialog] = useToggle();
    const asset = useGetAsset({ assetId, provider });
    const languageName = config.languages[language];
    const { subtitles } = asset?.additional || {};
    const isNotSavedSubtitle = !subtitles?.some((sub) => sub.language === language);

    useEffect(() => {
        if (isOpen) {
            ReactGA.modalview(`subtitles-editor`);
        }
    }, [isOpen]);

    const closeDialog = () => {
        if (subtitlesFormRef.current?.isSubmitting) {
            return;
        }
        subtitlesFormRef.current?.saveFormValues();
        toggleDialog();
    };

    return (
        <Dialog
            heading={`Subtitles Editor${languageName ? ` for ${languageName} language` : ''} `}
            isOpen={isOpen}
            className={css.dialog}
            onClose={closeDialog}
            dialogTrigger={
                isNotSavedSubtitle ? (
                    <Tooltip theme="light" content="Save asset changes first to edit subtitles" placement="top">
                        <div>
                            <Button disabled type="button" variant="standard" size="compact">
                                Edit
                            </Button>
                        </div>
                    </Tooltip>
                ) : (
                    <Button disabled={disabled} type="button" variant="standard" size="compact" onClick={toggleDialog}>
                        Edit
                    </Button>
                )
            }
        >
            <SubtitlesEditorProvider
                provider={provider}
                assetId={assetId}
                language={language}
                subtitles={subtitles}
                closeDialog={closeDialog}
                formRef={subtitlesFormRef}
            />
        </Dialog>
    );
});

SubtitlesEditorDialog.displayName = 'SubtitlesEditorDialog';
