import { PureComponent } from 'react';
import PropTypes from 'prop-types';
function stringToHSLColor(string, saturation, lightness) {
  if (string.length === 0) {
    return null;
  }
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line
  }
  const hue = hash % 360;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

/**
 * This component allows to calculate an HSL color based on a text and an optional saturation or lightness value.
 * It follows the render prop pattern so you can provide a render function as a child. The render function will be invoked
 * with HSL color string as a first argument.
 */
class StringToHSL extends PureComponent {
  render() {
    const {
      children,
      string,
      saturation,
      lightness
    } = this.props;
    return children(stringToHSLColor(string, saturation, lightness));
  }
}
StringToHSL.propTypes = {
  /** a render function which will receive an HSL color string as a first argument */
  children: PropTypes.func.isRequired,
  /** a string used to calculate the hue parameter of the returned color */
  string: PropTypes.string.isRequired,
  /** a number between 0 and 100 */
  saturation: PropTypes.number,
  /** a number between 0 and 100 */
  lightness: PropTypes.number
};
StringToHSL.defaultProps = {
  saturation: 40,
  lightness: 80
};
export default StringToHSL;