import * as PropTypes from 'prop-types';
import Suggest from 'react-autosuggest';
import { Input } from '@schibsted-svp/react-ui';
import suggestionFactory from './Helpers/suggestionFactory';
import './Autosuggest.scss';

/**
 * This is a wrapper component on top of [react-autosuggest](https://github.com/moroshko/react-autosuggest) library.
 * This wrapper forces input component to be always Stream Platform specific without requiring you to specify your own. This wrapper
 * also has a predefined [suggestion renderer](https://github.com/moroshko/react-autosuggest#renderSuggestionProp) so you don't need
 * to specify it for each use-case. You can use every prop from react-autosuggest docs (they are passed through to the core component)
 * except renderInputComponent and renderSuggestion functions which are predefined by this wrapper.
 */
function Autosuggest({ getSuggestionLabel, ...rest }) {
    const renderSuggestion = suggestionFactory(getSuggestionLabel);

    return (
        <Suggest
            renderSuggestion={renderSuggestion}
            renderInputComponent={(properties) => <Input {...properties} />}
            {...rest}
        />
    );
}

Autosuggest.propTypes = {
    /** These are the suggestions that will be displayed. Items can take an arbitrary shape. */
    suggestions: PropTypes.array.isRequired, // eslint-disable-line
    /** Will be called every time you need to recalculate suggestions. */
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    /** Will be called every time you need to set suggestions to [] */
    onSuggestionsClearRequested: PropTypes.func.isRequired,
    /** Pass through arbitrary props to the input. It must contain at least value and onChange and all required props for Stream Input component */
    inputProps: PropTypes.object.isRequired,
    /** Implement it to teach Autosuggest what should be the input value when suggestion is clicked. If your
     * suggestion array contains objects like { name: 'John', lastname: 'Doe' } and you want to inject
     * only name to the input after click then specify this function like ({name}) => name
     */
    getSuggestionValue: PropTypes.func.isRequired,
    /** This function should return label which should be displayed in a suggested result. If your
     * suggestion array contains objects like { name: 'John', lastname: 'Doe' } and you want your
     * suggestions to have only name then specify this function like ({name}) => name
     */
    getSuggestionLabel: PropTypes.func,
};

Autosuggest.defaultProps = {
    getSuggestionLabel: (suggestion) => suggestion,
};

export default Autosuggest;
