import { all, call, put, take, takeEvery } from '@redux-saga/core/effects';

import { VIDEO_DURATION_UPDATE, VIDEO_THUMBNAILS_CHANGE } from 'store/video/actions';
import { TRANSCODE_VIDEO } from 'store/video/upload/actions';

import { playerApiClient } from 'services';
import {
    FETCH_THUMBNAILS,
    fetchThumbnails as fetchThumbnailsAction,
    fetchThumbnailsSuccess,
    fetchThumbnailsError,
} from './actions';

export function* fetchThumbnails({ provider, id }) {
    try {
        const thumbnails = yield call(playerApiClient.fetchThumbnails, { provider, id });
        yield put(fetchThumbnailsSuccess(provider, id, thumbnails));
    } catch (error) {
        yield put(fetchThumbnailsError(provider, id, error.message));
    }
}

export function* waitForThumbnailsAndDuration() {
    const { streamUrl } = yield all({
        streamUrl: take(VIDEO_DURATION_UPDATE),
        thumbnails: take(VIDEO_THUMBNAILS_CHANGE),
    });

    const { provider, id } = streamUrl;

    yield put(fetchThumbnailsAction(provider, id));
}

export default [takeEvery(TRANSCODE_VIDEO, waitForThumbnailsAndDuration), takeEvery(FETCH_THUMBNAILS, fetchThumbnails)];
