import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import css from './NextVideoNavigation.module.scss';

const cln = classnames.bind(css);

const NAVIGATION_TABS = ['Single element', 'Playlist'];

export default function NextVideoNavigation({ activeTabIndex, onTabChange }) {
    return (
        <div className={css.container}>
            {NAVIGATION_TABS.map((tab, index) => (
                <button
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    type="button"
                    className={cln('navigation-button', {
                        'navigation-button--active': activeTabIndex === index,
                    })}
                    onClick={() => onTabChange(index)}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
}

NextVideoNavigation.propTypes = {
    activeTabIndex: PropTypes.oneOf([...NAVIGATION_TABS.keys()]).isRequired,
    onTabChange: PropTypes.func.isRequired,
};
