import { createAction } from '@reduxjs/toolkit';

export * from './actions-rest';

const OPEN_CUSTOM_PREVIEW_UPLOAD_DIALOG = 'Ui/AssetForm/OPEN_CUSTOM_PREVIEW_UPLOAD_DIALOG';
const CLOSE_CUSTOM_PREVIEW_UPLOAD_DIALOG = 'Ui/AssetForm/CLOSE_CUSTOM_PREVIEW_UPLOAD_DIALOG';

interface CustomPreviewUploadDialogPayload {
    provider: string;
    assetId: number;
}

export const openCustomPreviewUploadDialog = createAction<
    CustomPreviewUploadDialogPayload,
    typeof OPEN_CUSTOM_PREVIEW_UPLOAD_DIALOG
>(OPEN_CUSTOM_PREVIEW_UPLOAD_DIALOG);

export const closeCustomPreviewUploadDialog = createAction<
    CustomPreviewUploadDialogPayload,
    typeof CLOSE_CUSTOM_PREVIEW_UPLOAD_DIALOG
>(CLOSE_CUSTOM_PREVIEW_UPLOAD_DIALOG);
