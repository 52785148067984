import { api as generatedApi } from './generated';

const LIVE_STREAM_TAG = 'LiveStream';
const USERS_TAG = 'Users';
const TAGS_TAG = 'Tags';
const STORIES_TAG = 'Stories';

export const SUGGESTED_TAGS_LIST = 'SuggestedTags';

const createLiveStreamTag = (newsroom: string, assetId: number) =>
    ({ type: LIVE_STREAM_TAG, id: `${newsroom}:${assetId}` }) as const;

const createUsersTag = (id: string) => ({ type: USERS_TAG, id }) as const;

export function createTagsTag(id: string) {
    return { type: TAGS_TAG, id } as const;
}

function createStoryTag(id: string) {
    return { type: STORIES_TAG, id } as const;
}

export const api = generatedApi.enhanceEndpoints({
    addTagTypes: [LIVE_STREAM_TAG, USERS_TAG, STORIES_TAG, TAGS_TAG],
    endpoints: {
        getLive: {
            providesTags(result, _, { newsroom, assetId }) {
                return result?.live ? [createLiveStreamTag(newsroom, assetId)] : [];
            },
        },
        prepareLiveStream: {
            invalidatesTags(result, _, { newsroom, assetId }) {
                return result ? [createLiveStreamTag(newsroom, assetId)] : [];
            },
        },
        GetUsers: {
            providesTags(result, _, { query }) {
                const data = result?.getUsers || [];
                return [createUsersTag(query), ...data.map(({ email }) => createUsersTag(email))];
            },
        },
        UpdateUser: {
            invalidatesTags(result, _, { email }) {
                return result?.updateUser ? [createUsersTag(email)] : [];
            },
        },
        CreateUser: {
            invalidatesTags(result) {
                return result?.createUser ? [USERS_TAG] : [];
            },
        },
        ModifyAssetPodmeMetadata: {
            async onQueryStarted({ provider, assetId }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(
                    api.util.updateQueryData('AssetPodmeMetadata', { provider, assetId }, (draft) => {
                        Object.assign(draft, { assetPodmeMetadata: data.modifyAssetPodmeMetadata });
                    })
                );
            },
        },
        ModifyCategoryPodmeMetadata: {
            async onQueryStarted({ provider, categoryId }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(
                    api.util.updateQueryData('CategoryPodmeMetadata', { provider, categoryId }, (draft) => {
                        Object.assign(draft, { categoryPodmeMetadata: data.modifyCategoryPodmeMetadata });
                    })
                );
            },
        },
        listTags: {
            providesTags(result) {
                if (!result) {
                    return [];
                }
                return result.listTags.map(({ id }) => createTagsTag(id));
            },
        },
        searchTags: {
            providesTags(result) {
                if (!result) {
                    return [];
                }
                return result.searchTags.map(({ id }) => createTagsTag(id));
            },
        },
        suggestTags: {
            providesTags(result) {
                if (!result) {
                    return [createTagsTag(SUGGESTED_TAGS_LIST)];
                }
                return [...result.suggestTags.map(({ id }) => createTagsTag(id)), createTagsTag(SUGGESTED_TAGS_LIST)];
            },
        },
        getStories: {
            providesTags(result) {
                if (!result) {
                    return [];
                }
                return result.getStories.map(({ id }) => createStoryTag(id));
            },
        },
        searchStories: {
            providesTags(result) {
                if (!result) {
                    return [];
                }
                return result.searchStories.map(({ id }) => createStoryTag(id));
            },
        },
    },
});

export const adminBffSdk = api;

export const { useGetStoriesQuery, useListTagsQuery, useSearchStoriesQuery, useSearchTagsQuery, useSuggestTagsQuery } =
    api;
