import config from 'config';
import { isAudioOrVideoType, isImageType, isSubtitleType } from 'lib/file';

const { languages } = config;
const languagesCounter = Object.keys(languages).length;

/**
 * @param {Array<File>} files
 * @param {Object} attributes
 * @param {Number} attributes.subtitlesLimit
 * @returns {{ isValid: Boolean, message?: String, videoFile?: File, imageFile?: File, subtitleFiles?: Array<File> }}
 */
export default function validator(files, { subtitlesLimit }) {
    const limit = Math.max(languagesCounter - subtitlesLimit, 0);
    const videoFile = files.find(isAudioOrVideoType);
    const imageFile = files.find(isImageType);
    const subtitleFiles = files.filter(isSubtitleType).filter((subtitle, index) => index < limit);

    if (!videoFile && !imageFile && !subtitleFiles.length) {
        return {
            isValid: false,
            message: `No one of uploaded files is valid.`,
        };
    }

    const omittedFileNames = files
        .filter((file) => file !== videoFile && file !== imageFile && !subtitleFiles.includes(file))
        .map(({ name }) => name);

    return {
        isValid: true,
        message: omittedFileNames.length ? `Omitted files: ${omittedFileNames.join(', ')}` : undefined,
        imageFile,
        videoFile,
        subtitleFiles,
    };
}
