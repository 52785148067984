import { get } from 'lodash';
import config from 'config';

/**
 * @typedef {Object} Tag
 * @property {String} id
 * @property {String} tag
 */

/**
 * @param {Object} params
 * @param {Object} params.filter
 * @param {String} params.provider
 * @return {Promise<Array<Tag>>}
 */
export async function fetchTags({ filter, provider }) {
    const query = {
        limit: config.tags.limit,
    };

    if (filter) {
        query.filter = this.constructor.prepareFilter(filter);
    }

    const result = await this.get(`v1/svp/{PROVIDER}/tags`, { provider, query });
    return get(result, '_embedded.tags', []);
}
