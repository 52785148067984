import { useState } from 'react';
import { LabeledContainer, Input, Button } from '@schibsted-svp/react-ui';
import { getEmailFromLocalStorage, validateEmail } from 'store/auth/helpers';
import { ChangeInputEvent, LoginFormProps } from './types';
import { SupportContactInfo } from './SupportContactInfo';

import css from './LoginForm.module.scss';

export function LoginForm({
    handleLogin,
    email,
    setEmail,
    enablePasswordLogin,
    setPassword,
    loginAsSomebodyElse,
    showContactInfo,
}: LoginFormProps) {
    const defaultText = 'Enter your e-mail connected with a Stream account';
    const rememberedEmail = getEmailFromLocalStorage();
    const showEmailInput = (!rememberedEmail && !enablePasswordLogin) || (loginAsSomebodyElse && !enablePasswordLogin);
    const [emailError, setEmailError] = useState('');

    const onEmailChange = (event: ChangeInputEvent) => {
        setEmail(event.target.value);

        // reset error when user starts typing
        if (emailError) {
            setEmailError('');
        }
    };

    const onFormSubmit = (event) => {
        event.preventDefault();
        const validationResult = validateEmail(email);

        setEmailError(validationResult);

        if (!validationResult) {
            handleLogin(event);
        }
    };

    const passwordRequired = (
        <>
            <p className={css.error}>We are sorry!</p>{' '}
            <p>
                Your SSO provider is <b>down</b> and a <b>password</b> is required to log in.
            </p>
        </>
    );

    return (
        <span>
            <p className={css.instruction}>{enablePasswordLogin ? passwordRequired : defaultText} </p>
            <form onSubmit={onFormSubmit} noValidate>
                {showEmailInput ? (
                    <LabeledContainer label="E-mail">
                        <Input onChange={onEmailChange} type="email" name="email" error={emailError} noValidate />
                    </LabeledContainer>
                ) : null}
                {enablePasswordLogin ? (
                    <LabeledContainer label="Password">
                        <Input
                            onChange={(event: ChangeInputEvent) => setPassword(event.target.value)}
                            type="password"
                            name="password"
                            className={css.password}
                        />
                    </LabeledContainer>
                ) : null}
                <Button type="submit" className={css.logIn} disabled={Boolean(emailError)}>
                    Log in
                </Button>
            </form>
            {enablePasswordLogin || showContactInfo ? <SupportContactInfo /> : null}
        </span>
    );
}
