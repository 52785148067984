import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'hooks/useDebounce';
import { useSuggestTagsQuery } from 'services/admin-bff-sdk';
import { resetTagSuggestions } from 'services/admin-bff-sdk/utils';
import type { QuerySuggestTagsArgs } from 'services/admin-bff-sdk/generated';
import type { FormValues } from '../types';

export type FormData = Pick<FormValues, 'title' | 'description'> & Pick<FormValues['additional'], 'subtitles' | 'tags'>;

const TAG_SUGGESTIONS_LIMIT = 15;

const prepareFormData = ({ subtitles, ...formData }: FormData): Omit<QuerySuggestTagsArgs, 'provider'> => ({
    ...formData,
    subtitlesUrls: subtitles.map(({ url }) => url),
});

const shouldSkip = ({ title, description, tags, subtitlesUrls }: Omit<QuerySuggestTagsArgs, 'provider'>) =>
    !title && !description && !tags.length && !subtitlesUrls.length;

type TagsSuggestionsProps = {
    provider: string;
    formData: FormData;
    limit?: number;
};

export function useTagsSuggestions({ provider, formData, limit = TAG_SUGGESTIONS_LIMIT }: TagsSuggestionsProps) {
    const dispatch = useDispatch();
    const debouncedFormData = useDebounce(prepareFormData(formData), 500);

    const { isFetching, isLoading, data } = useSuggestTagsQuery(
        { provider, ...debouncedFormData, limit },
        { skip: shouldSkip(debouncedFormData) }
    );

    useEffect(() => {
        dispatch(resetTagSuggestions());
    }, [dispatch]);

    const tagsSuggestions = useMemo(
        () => (data?.suggestTags || []).map(({ id, title, type }) => ({ label: title, value: id, type })),
        [data?.suggestTags]
    );

    const suggestions = debouncedFormData?.tags
        ? tagsSuggestions.filter((suggestion) => !debouncedFormData.tags.find((tag) => tag === suggestion.value))
        : tagsSuggestions;

    return {
        isFetching,
        isLoading,
        suggestions,
    };
}
