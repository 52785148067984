import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { Button } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';
import { selectNewsroom } from 'store/newsroom/selectors';
import removeDocumentSelections from 'lib/dom';
import { getNewsroomConfig } from 'config';

import css from './Menu.module.scss';

const cln = classnames.bind(css);

function Menu({ setAssetsListType, assetsListType }) {
    const location = useLocation();
    const [isHidden, setIsHidden] = useState(true);
    const newsroom = useSelector(selectNewsroom);

    const { podcastsOnly } = getNewsroomConfig(newsroom);

    const toggleMenu = () => {
        setIsHidden((prevState) => !prevState);
    };

    const hideMenu = () => {
        setIsHidden(true);
        removeDocumentSelections();
    };

    /**
     * @param {String} assetsListType
     * @returns {Boolean}
     */
    const isAssetLinkActive = (listType) => {
        const { pathname } = location;
        const regex = /.+\/assets/g;
        return pathname.match(regex) && listType === assetsListType;
    };

    const onAssetsListClick = (type) => () => {
        hideMenu();
        setAssetsListType(type, getNewsroomConfig(newsroom)?.podcastsOnly);
    };

    const activeClassName = cln({ 'link--active': true });

    return (
        <div className={css.container}>
            <nav className={cln('links', { 'links--visible': !isHidden })}>
                <NavLink
                    to={`/${newsroom}/assets`}
                    onClick={onAssetsListClick('video')}
                    activeClassName={activeClassName}
                    isActive={() => isAssetLinkActive('video')}
                >
                    Video
                </NavLink>
                <NavLink
                    to={`/${newsroom}/assets`}
                    onClick={onAssetsListClick('audio')}
                    activeClassName={activeClassName}
                    isActive={() => isAssetLinkActive('audio')}
                >
                    {podcastsOnly ? 'Podcasts' : 'Audio'}
                </NavLink>
                <NavLink to={`/${newsroom}/live`} onClick={hideMenu} activeClassName={activeClassName}>
                    Live
                </NavLink>
                <NavLink to={`/${newsroom}/settings`} onClick={hideMenu} activeClassName={activeClassName}>
                    Settings
                </NavLink>
                <NavLink
                    to={`/${newsroom}/categories`}
                    onClick={hideMenu}
                    activeClassName={activeClassName}
                    isActive={(_, { pathname }) => {
                        const pattern = new RegExp(`^/${newsroom}/categor(y|ies)(/[^/])*`);
                        return pattern.test(pathname);
                    }}
                >
                    Categories
                </NavLink>
                <NavLink to={`/${newsroom}/playlists`} onClick={hideMenu} activeClassName={activeClassName}>
                    Playlists
                </NavLink>
            </nav>
            <Button type="button" className={css.burger} variant="standard" onClick={toggleMenu}>
                <MdMenu />
            </Button>
        </div>
    );
}

Menu.propTypes = {
    setAssetsListType: PropTypes.func.isRequired,
    assetsListType: PropTypes.oneOf(['audio', 'video']).isRequired,
};

export default Menu;
