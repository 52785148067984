import type { Middleware } from '@reduxjs/toolkit';

export const DEFERRED = Symbol('DEFERRED');

export const deferredMiddleware: Middleware = () => {
    return (next) => (action) => {
        if (!action[DEFERRED]) {
            return next(action);
        }

        return new Promise((resolve, reject) => {
            next({
                ...action,
                [DEFERRED]: (result) => {
                    if (result instanceof Error) {
                        reject(result);
                    }

                    resolve(result);
                },
            });
        });
    };
};
