import { Button, Dialog, Notification } from '@schibsted-svp/react-ui';
import { FormEventHandler, forwardRef, MouseEventHandler, useImperativeHandle } from 'react';
import config from 'config';
import { reportMessageToSentry } from 'lib/error';
import { SECOND } from 'lib/time';
import useInterval from 'hooks/useInterval';

import type { SubtitlesUploadedData } from 'components/AssetForm/fields/Subtitles/types';
import type { SubtitleCue } from './types';
import { useSubtitleCuesForm } from './hooks/useSubtitleCuesForm';
import { SubtitlesCacheNotification } from './SubtitlesCacheNotification';
import { SubtitlesEditor } from './SubtitlesEditor';

const SAVE_INTERVAL = SECOND * 30;

type SubtitlesFormProps = {
    provider: string;
    assetId: number;
    language: string;
    cues: SubtitleCue[];
    closeDialog: () => void;
    generateSubtitles: (uploadedSubtitles: SubtitlesUploadedData) => SubtitlesUploadedData[];
};

export type SubtitlesFormRef = {
    saveFormValues: () => void;
    isSubmitting: boolean;
};

export const SubtitlesForm = forwardRef<SubtitlesFormRef, SubtitlesFormProps>(function SubtitlesForm(
    { provider, assetId, language, cues, closeDialog, generateSubtitles },
    ref
) {
    const { formApi, loadSavedFormValues, saveFormValues, submitValues, lastEditedAt } = useSubtitleCuesForm({
        provider,
        assetId,
        language,
        initialCues: cues,
        generateSubtitles,
    });
    const { isDirty, isSubmitting } = formApi.formState;
    const saveFormValuesInterval = isDirty && !isSubmitting ? SAVE_INTERVAL : null;

    const handleCancelClick: MouseEventHandler<HTMLButtonElement> = () => {
        saveFormValues();
        closeDialog();
    };

    useInterval(() => {
        saveFormValues();
    }, saveFormValuesInterval);

    useImperativeHandle(ref, () => ({
        saveFormValues,
        isSubmitting,
    }));

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        const languageName = config.languages[language];
        event.stopPropagation();

        try {
            await submitValues(event);
            Notification.notify.success(`${languageName} subtitles have been updated successfully`);
            closeDialog();
        } catch (error) {
            reportMessageToSentry({
                message: 'Unable to save edited subtitles',
                extras: { provider, assetId, language, error },
            });
            Notification.notify.error(`An error occurred while trying to edit "${languageName}" subtitles`);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <SubtitlesCacheNotification lastSavedAt={lastEditedAt} loadSavedFormValues={loadSavedFormValues} />
            <SubtitlesEditor provider={provider} assetId={assetId} formApi={formApi} />

            <Dialog.Section mode="flexRight" variant="darker">
                <Button disabled={isSubmitting} type="button" variant="standard" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button loading={isSubmitting} type="submit">
                    Save
                </Button>
            </Dialog.Section>
        </form>
    );
});
