import { combineReducers } from '@reduxjs/toolkit';
import { toggle } from 'lib/array';

import { defaultPreferences } from './state';
import * as Actions from './actionTypes';

const preferences = (state = defaultPreferences, action) => {
    switch (action.type) {
        case Actions.OPTIONS_TOGGLE: {
            return {
                ...state,
                assetProperties: toggle(state.assetProperties, action.payload.option),
            };
        }

        default:
            return state;
    }
};

export default combineReducers({
    preferences,
});
