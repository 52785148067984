import { ChangeEvent } from 'react';
import { CollapsibleContent, LabeledContainer, Input, Select, Checkbox, Label, Divider } from '@schibsted-svp/react-ui';
import { useFormContext, Controller } from 'react-hook-form';
import { isPodmeIntegrationEnabled } from 'lib/flags';
import { PodcastCategoriesSelect } from 'components/categories/Form/PodcastCategories/PodcastCategoriesSelect';
import { findSingleOption, getValueFromOption } from 'components/ui/Form/Select';
import { PodcastAdsLimits } from './PodcastAdsLimits';
import { PodmeIntegration } from './PodmeIntegration';
import type { CategoryData } from './types';

import css from './PodcastProperties.module.scss';

const podcastTypesOptions = [
    {
        value: 'episodic',
        label: 'episodic',
    },
    {
        value: 'serial',
        label: 'serial',
    },
];

type PodcastPropertiesProps = {
    provider: string;
    categoryId: number;
    isCreationMode: boolean;
};

export function PodcastProperties({ provider, categoryId, isCreationMode }: PodcastPropertiesProps) {
    const { register, formState, control, setValue } = useFormContext<CategoryData>();
    const { errors } = formState;

    const normalizeExplicit = (event: ChangeEvent<HTMLInputElement>) => {
        setValue('additional.metadata.podcast_explicit.value', event.target.checked ? 'yes' : null);
    };

    return (
        <CollapsibleContent label="podcast properties">
            <section className={css.content}>
                <PodcastCategoriesSelect control={control} />
                <LabeledContainer label="AUTHOR" strict>
                    <Input
                        {...register('additional.metadata.podcast_author.value')}
                        size="small"
                        error={errors?.additional?.metadata?.podcast_author?.value.message}
                    />
                </LabeledContainer>
                <LabeledContainer label="LINK" strict>
                    <Input
                        {...register('additional.metadata.podcast_link.value')}
                        size="small"
                        error={errors?.additional?.metadata?.podcast_link?.value.message}
                    />
                </LabeledContainer>
                <LabeledContainer label="EMAIL" strict>
                    <Input
                        {...register('additional.metadata.podcast_ownerEmail.value')}
                        size="small"
                        error={errors?.additional?.metadata?.podcast_ownerEmail?.value.message}
                    />
                </LabeledContainer>
                <LabeledContainer label="ACAST SHOW ID" strict>
                    <Input {...register('additional.metadata.podcast_acast_showId.value')} size="small" disabled />
                </LabeledContainer>
                <LabeledContainer label="LANGUAGE" strict>
                    <Input
                        {...register('additional.metadata.podcast_language.value')}
                        size="small"
                        error={errors?.additional?.metadata?.podcast_language?.value.message}
                    />
                </LabeledContainer>
                <LabeledContainer label="OWNER NAME" strict>
                    <Input {...register('additional.metadata.podcast_ownerName.value')} size="small" />
                </LabeledContainer>
                <LabeledContainer label="COPYRIGHT" strict>
                    <Input
                        {...register('additional.metadata.podcast_copyright.value')}
                        placeholder={`Copyright: ${provider.toUpperCase()}`}
                        size="small"
                    />
                </LabeledContainer>
                <LabeledContainer label="PREPEND URL" strict>
                    <Input
                        {...register('additional.metadata.podcast_acast_prependUrl.value')}
                        size="small"
                        error={errors?.additional?.metadata?.podcast_acast_prependUrl?.value.message}
                    />
                </LabeledContainer>
                <LabeledContainer label="TYPE" strict className={css.type}>
                    <Controller
                        name="additional.metadata.podcast_type.value"
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                name="additional.metadata.podcast_type.value"
                                size="small"
                                options={podcastTypesOptions}
                                value={findSingleOption(podcastTypesOptions)(field.value)}
                                onChange={(values) => field.onChange(getValueFromOption(values))}
                                splitButton={false}
                            />
                        )}
                    />
                </LabeledContainer>
                <Checkbox
                    {...register('additional.metadata.podcast_explicit.value')}
                    label="Explicit"
                    containerClassName={css.explicit}
                    onChange={normalizeExplicit}
                />

                <Label className={css.adPlacement}>AD PLACEMENT</Label>
                <Divider className={css.adPlacementDivider} />
                <LabeledContainer label="SPONSORSHIP" className={css.spons} strict>
                    <Controller
                        control={control}
                        name="additional.metadata.podcast_sponsAdsLimits.value"
                        render={({ field: { onChange, value } }) => (
                            <PodcastAdsLimits onChange={onChange} value={value} />
                        )}
                    />
                </LabeledContainer>
                <LabeledContainer label="AIRTIME" className={css.airtime} strict>
                    <Controller
                        control={control}
                        name="additional.metadata.podcast_airtimeAdsLimits.value"
                        render={({ field: { onChange, value } }) => (
                            <PodcastAdsLimits onChange={onChange} value={value} />
                        )}
                    />
                </LabeledContainer>
                {!isCreationMode && isPodmeIntegrationEnabled && (
                    <PodmeIntegration
                        provider={provider}
                        categoryId={categoryId}
                        register={register}
                        control={control}
                    />
                )}
            </section>
        </CollapsibleContent>
    );
}
