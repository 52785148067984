import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button, Dialog, Radio } from '@schibsted-svp/react-ui';
import { useDispatch } from 'react-redux';
import { stopLiveStream, unpublishLiveAsset } from 'store/live/actions';
import css from './LiveUnpublishDialog.module.scss';

function LiveUnpublishDialog({ onSubmit, isOpen, onClose }) {
    const { id, newsroom: provider } = useParams();
    const assetId = Number(id);
    const dispatch = useDispatch();
    const [shouldStopEncoder, setShouldStopEncoder] = useState(true);

    const handleSubmit = useCallback(() => {
        if (shouldStopEncoder) {
            dispatch(stopLiveStream(provider, assetId));
        } else {
            dispatch(unpublishLiveAsset(provider, assetId));
        }
        onSubmit();
    }, [assetId, provider, shouldStopEncoder, onSubmit, dispatch]);

    const handleChange = (event) => setShouldStopEncoder(event.target.value === 'stop');

    return (
        <Dialog isOpen={isOpen} onClose={onClose} heading="Unpublish live video">
            <Dialog.Section className={css.container}>
                <p>Encoder is still running. What would you like to do?</p>
                <ul>
                    <li>
                        <Radio
                            name="unpublish"
                            checked={shouldStopEncoder}
                            onChange={handleChange}
                            label="Unpublish and stop encoder"
                            value="stop"
                        />
                    </li>
                    <li>
                        <Radio
                            name="unpublish"
                            checked={!shouldStopEncoder}
                            onChange={handleChange}
                            label="Unpublish and leave encoder running"
                            value="leave"
                        />
                    </li>
                </ul>
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={onClose}>
                    Cancel
                </Button>
                <Button type="button" onClick={handleSubmit}>
                    Confirm
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}

LiveUnpublishDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default LiveUnpublishDialog;
