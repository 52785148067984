import { useSelector } from 'react-redux';
import { Checkbox, Divider, LabeledContainer } from '@schibsted-svp/react-ui';
import {
    isGeoblocked,
    isEncrypted,
    getAccess,
    isLive,
    wasLive,
    isAudio,
    isStreamConfigurationEmpty,
} from 'models/asset';
import { useGetAccessDefinitionNameQueryState } from 'services/svp-api-client/access-definitions';
import { getVideoUpload } from 'store/video/upload/selectors';
import { UPLOAD_STATE } from 'store/video/upload/reducers';
import { isAssetStreamConfigurationAvailable } from 'store/stream-configurations/utils';
import { AccessDefinitionSelectRHFControl } from 'components/video/live/form/AccessDefinitionSelect';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { UseFormReturn } from 'react-hook-form';
import type { FormValues } from '../types';
import css from './ViewerAccess.module.scss';

export function shouldHideViewerAccessField(asset: Asset) {
    return (isLive(asset) || wasLive(asset)) && isStreamConfigurationEmpty(asset);
}

export function isDisabled(asset: Asset, isAssetUploading: boolean, disabled: boolean) {
    return (
        disabled || isAssetUploading || wasLive(asset) || !isAssetStreamConfigurationAvailable(asset) || isAudio(asset)
    );
}

type ViewerAccessProps = {
    asset: Asset;
    formApi: UseFormReturn<FormValues>;
};
export function ViewerAccess({ asset, formApi }: ViewerAccessProps) {
    const accessKey = getAccess(asset)[0];
    const accessName = useGetAccessDefinitionNameQueryState({ provider: asset.provider, accessKey });

    const { state: uploadState } = useSelector((state) =>
        getVideoUpload(state, { provider: asset.provider, id: asset.id })
    );
    const isUploading = [UPLOAD_STATE.UPLOAD, UPLOAD_STATE.TRANSCODE].includes(uploadState);
    const isViewerAccessHidden = shouldHideViewerAccessField(asset);

    if (isViewerAccessHidden) {
        return null;
    }

    const isLiveOrWasLive = isLive(asset) || wasLive(asset);
    const areActionsDisabled = isDisabled(asset, isUploading, formApi.formState.isSubmitting);

    if (isLiveOrWasLive) {
        return (
            <>
                <LabeledContainer strict label="Viewer access">
                    <>
                        {isGeoblocked(asset) && <Checkbox label="Geoblocked" checked disabled />}
                        {isEncrypted(asset) && (
                            <Checkbox label={`Behind paywall${accessName ? `: ${accessName}` : ''}`} checked disabled />
                        )}
                    </>
                </LabeledContainer>
                <Divider margin="10px 0 10px" />
            </>
        );
    }
    return (
        <>
            <LabeledContainer label="Viewer access">
                <Checkbox
                    {...formApi.register('protections.geoRestriction')}
                    disabled={areActionsDisabled}
                    label="Geoblock"
                    containerClassName={css.geoRestrictionCheckbox}
                />
                <AccessDefinitionSelectRHFControl
                    name="protections.accessLevel"
                    disabled={areActionsDisabled}
                    size="small"
                    canChooseFreeAccess
                    control={formApi.control}
                />
            </LabeledContainer>
            <Divider margin="10px 0 10px" />
        </>
    );
}
