import { CollapsibleContent } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import useToggle from 'hooks/useToggle';
import { HistoryList } from './HistoryList';
import css from './History.module.scss';
import { useGetAssetHistoryData, useHistoryPreview } from './hooks';
import { HistoryPreview } from './HistoryPreview';

type HistoryProps = {
    asset: Asset;
};

export function History({ asset }: HistoryProps) {
    const [isExpanded, toggleIsExpanded] = useToggle(false);
    const { historyData, isLoading, isError } = useGetAssetHistoryData(asset, isExpanded);
    const { previewData, isPreviewOpen, openPreview, closePreview } = useHistoryPreview();

    return (
        <CollapsibleContent label="History" expanded={isExpanded} onToggle={toggleIsExpanded}>
            <section className={css.content}>
                <HistoryList data={historyData} isLoading={isLoading} isError={isError} openPreview={openPreview} />
                {previewData && (
                    <HistoryPreview data={previewData} isDialogOpen={isPreviewOpen} closeDialog={closePreview} />
                )}
            </section>
        </CollapsibleContent>
    );
}
