import { Controller, Control } from 'react-hook-form';
import { Input } from '@schibsted-svp/react-ui';
import type { FormValues } from '../types';

type ArticleUrlInputProps = {
    control: Control<FormValues>;
    error: string | undefined;
};
export function ArticleUrlInput({ control, error }: ArticleUrlInputProps) {
    return (
        <Controller
            name="articleUrl"
            control={control}
            render={({ field }) => (
                <Input
                    {...field}
                    error={error}
                    value={field.value || ''}
                    size="small"
                    onChange={(value) => {
                        field.onChange(value.currentTarget.value || null);
                    }}
                />
            )}
        />
    );
}
