import { Button, Dialog } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { memo, useState, useMemo } from 'react';
import { isVod } from 'models/asset';

import { Tab, Tabs } from 'components/ui/Tabs';

import { CustomPreviewDialogContext } from './CustomPreviewDialogContext';
import { UploadCustomPreview } from './UploadCustomPreview';
import { VideoPreviewEditor } from './VideoPreviewEditor';
import css from './CustomPreviewDialog.module.scss';

interface CustomPreviewDialogProps {
    asset: Asset;
    loading: boolean;
    disabled?: boolean;
}

export const CustomPreviewDialog = memo<CustomPreviewDialogProps>(({ asset, loading, disabled = false }) => {
    const [tab, setTab] = useState<'editor' | 'upload' | undefined>(undefined);
    const [preventDialogClose, setPreventDialogClose] = useState(false);
    const [disableCreateButton, setDisableCreateButton] = useState(false);

    const openDialog = (tabName: 'editor' | 'upload' = 'editor') => {
        const selectedTab = isVod(asset) ? tabName : 'upload';

        return () => setTab(selectedTab);
    };

    const closeDialog = () => {
        if (!preventDialogClose) {
            setTab(() => undefined);
        }
    };

    const tooltipContent = <span>Creating preview from live video is unavailable</span>;

    const contextValue = useMemo(
        () => ({
            preventDialogClose,
            setPreventDialogClose,
            disableCreateButton,
            setDisableCreateButton,
        }),
        [preventDialogClose, setPreventDialogClose, disableCreateButton, setDisableCreateButton]
    );
    return (
        <CustomPreviewDialogContext.Provider value={contextValue}>
            <Dialog
                heading="Create custom preview"
                isOpen={Boolean(tab)}
                className={css.dialog}
                onClose={closeDialog}
                dialogTrigger={
                    <Button
                        type="button"
                        size="small"
                        onClick={openDialog()}
                        disabled={disabled || loading}
                        className={css.editButton}
                    >
                        Edit
                    </Button>
                }
            >
                <Dialog.Section className={css.tabsContainer}>
                    <Tabs>
                        <Tab
                            disabled={!isVod(asset)}
                            onClick={openDialog('editor')}
                            label="From main video"
                            isActive={tab === 'editor'}
                            showTooltip={!isVod(asset)}
                            tooltipContent={tooltipContent}
                        />

                        <Tab onClick={openDialog('upload')} label="Upload custom preview" isActive={tab === 'upload'} />
                    </Tabs>
                </Dialog.Section>

                {tab === 'editor' ? <VideoPreviewEditor asset={asset} closeDialog={closeDialog} /> : null}

                {tab === 'upload' ? (
                    <UploadCustomPreview provider={asset.provider} assetId={asset.id} closeDialog={closeDialog} />
                ) : null}
            </Dialog>
        </CustomPreviewDialogContext.Provider>
    );
});

CustomPreviewDialog.displayName = 'CustomPreviewDialog';
