import { Button, Input } from '@schibsted-svp/react-ui';
import { useFormContext } from 'react-hook-form';
import { FieldAdder } from 'components/core/hookForm/FieldAdder';
import type { FieldPath } from 'react-hook-form';
import { PreviewImage } from './PreviewImage';
import { trimValue } from './helpers';
import type { CategoryData } from './types';

import css from './Images.module.scss';

export function Images() {
    const { control, register, setValue, formState } = useFormContext<CategoryData>();
    const { errors } = formState;

    const handleOnChange: (event: React.FormEvent<HTMLInputElement>, key: FieldPath<CategoryData>) => void = (
        event,
        key
    ) => {
        setValue(key, trimValue((event.target as HTMLInputElement).value));
    };

    return (
        <FieldAdder
            name="additional.images"
            control={control}
            label="ADD IMAGES"
            mode="prepend"
            addButtonTestId="categoryImageAddButton"
        >
            {({ fields, remove }) =>
                fields.map((image, index) => {
                    const error = errors.additional?.images?.[index]?.key?.message;

                    return (
                        <div key={image.id} className={css['inputs-row']}>
                            <Input
                                {...register(`additional.images.${index}.key`)}
                                onChange={(event) => handleOnChange(event, `additional.images.${index}.key`)}
                                autoComplete="off"
                                size="small"
                                error={error}
                            />
                            <Button size="small" variant="standard" type="button" onClick={() => remove(index)}>
                                Delete
                            </Button>

                            <PreviewImage name={`additional.images.${index}.url`} control={control} />
                        </div>
                    );
                })
            }
        </FieldAdder>
    );
}
