import { print } from 'graphql/language/printer';
import { reportMessageToSentry } from 'lib/error';
import { checkForTokenRefresh } from 'services/auth-api-client';
import Client from './client';

class GraphQLClient {
    /**
     *
     * @param {String} baseUrl
     * @param {Function} [fetchFn=undefined]
     */
    constructor({ baseUrl, fetchFn = undefined }) {
        this.client = new Client({
            baseUrl,
            fetchFn,
        });
    }

    /**
     *
     * @param {String} provider
     */
    setProvider(provider) {
        this.provider = provider;
    }

    /**
     *
     * @returns {String}
     */
    getProvider() {
        return this.provider;
    }

    /**
     *
     * @param {Function} preRequest
     */
    set preRequest(preRequest) {
        this.client.preRequest = preRequest;
    }

    /**
     *
     * @param {Function} postRequest
     */
    set postRequest(postRequest) {
        this.client.postRequest = postRequest;
    }

    /**
     *
     * @param {AST} ast
     * @param {Object<String, Any>} [variables=undefined]
     * @param {Boolean} [shouldReportError=true]
     * @throws {Error}
     * @return {Promise<void>}
     */

    async query(ast, variables = undefined, shouldReportError = true) {
        try {
            const query = print(ast);
            const json = { query, variables };
            const response = await this.client.post('/', { json });

            await checkForTokenRefresh(ast.definitions);

            if (response.errors) {
                const { message, location } = response.errors[0] || {};
                const error = new Error(message);

                this.client.dispatchEvent(new CustomEvent('error', { detail: { path: location, error } }));
                throw error;
            }

            return response.data;
        } catch (error) {
            if (shouldReportError) {
                reportMessageToSentry({
                    message: 'Graphql-client query error',
                    extras: {
                        error,
                    },
                });
            }
            throw error;
        }
    }
}

export default GraphQLClient;
