import { connect } from 'react-redux';
import { getAsset } from 'store/assets/selectors';
import { findLiveEncoderEvents, getMediaLiveChannel, hasLiveAssetFailed } from 'store/live/selectors';
import { isElementalEncoder, isLive, isMediaLiveEncoder } from 'models/asset';
import VideoCard from './VideoCard';
import { dataMapper, liveEventMapper, mediaLiveChannelMapper } from './dataMapper';

function mapStateToProps(state, { id, provider, asset = undefined }) {
    const assetData = asset || getAsset(state, { id, provider });

    let hasLiveVideoFailed;
    let mappedAssetData;
    let additionalInputData;

    if (assetData) {
        mappedAssetData = dataMapper(assetData);
        hasLiveVideoFailed =
            isLive(assetData) && hasLiveAssetFailed(state, { newsroom: assetData.provider, assetId: assetData.id });

        if (isMediaLiveEncoder(asset)) {
            const channel = getMediaLiveChannel(state, { assetId: assetData.id, provider: assetData.provider });
            additionalInputData = mediaLiveChannelMapper(asset, channel);
        }
        if (isElementalEncoder(asset)) {
            const events = findLiveEncoderEvents(state, { assetId: assetData.id, provider: assetData.provider });
            additionalInputData = liveEventMapper(asset, events);
        }
    }
    return { mappedAssetData: { ...mappedAssetData, ...additionalInputData }, asset: assetData, hasLiveVideoFailed };
}

export default connect(mapStateToProps)(VideoCard);
