import { useEffect, memo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { omit } from 'lodash';
import BEM from 'lib/bem';
// import { ListPropTypes, FiltersPropTypes } from 'store/asset-list/validation';
// import { PreferencesPropTypes } from 'store/user/validation';
import InlineStatusFilters from 'components/AssetsList/InlineStatusFilters';
import AppliedFilters from 'components/AssetsList/AppliedFilters';
import OrderDropdown from 'components/core/OrderDropdown';
import AnnounceKit from 'announcekit-react';
import { useCategoryCustomFieldsPerType } from 'components/core/FiltersDialog/hooks';
import { AssetsTable } from './AssetsTable';
import FiltersButton from './FiltersButton';
import OptionsButton from './OptionsButton';

import './AssetsList.scss';

const bem = {
    list: new BEM('AssetsList'),
};

export const AssetsList = memo(function AssetsList({
    newsroom,
    fetchMore,
    setFilters,
    setSortType,
    sortType,
    removeFilters,
    list,
    items,
    type,
    preferences,
    optionsToggle,
    clearSearch,
    clearFilters,
    fetchList,
    isUserProvider,
    ...props
}) {
    const location = useLocation();
    const history = useHistory();

    const searchFor = (listType, searchTerm, provider) => {
        if (searchTerm) {
            props.searchFor(searchTerm, provider);
        } else {
            fetchList({ list: listType });
        }
    };

    const categoryCustomDateFields = useCategoryCustomFieldsPerType({
        provider: newsroom,
        type: 'date',
        filters: props.filters,
    });
    const categoryCustomBooleanFields = useCategoryCustomFieldsPerType({
        provider: newsroom,
        type: 'checkbox',
        filters: props.filters,
    });

    useEffect(() => {
        if (items.length) return;
        searchFor(type, new URLSearchParams(location.search, newsroom).get('query'), newsroom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsroom]);

    const removeQuery = () => {
        clearSearch(props.assetsListType);
        history.push(`/${newsroom}/assets`);
        searchFor(type, '', newsroom);
    };

    const getInlineFilters = () => {
        const { statuses } = props.filters;
        if (!isUserProvider) {
            return null;
        }
        return (
            type !== 'linked' && (
                <InlineStatusFilters activeFilters={Array.isArray(statuses) ? statuses : []} onChange={setFilters} />
            )
        );
    };

    const handleFiltersClear = () => {
        removeQuery();
        clearFilters(props.assetsListType);
    };

    const getPreparedFilters = (filters = {}, assetsListType) => {
        const customFilters = omit(filters, ['statuses', 'customFields']);
        const isVideoTheOnlySelectedFilter =
            customFilters.assetTypes?.length === 1 && customFilters.assetTypes[0]?.value === assetsListType;

        const flatFilters = {
            ...customFilters,
            ...filters.customFields,
        };

        if (isVideoTheOnlySelectedFilter) {
            // if the "video" filter is the only selected asset type filter then
            // remove it from selected filters list so that it doesn't appear on selected filters list
            return omit(flatFilters, 'assetTypes');
        }

        return flatFilters;
    };

    const query = new URLSearchParams(location.search).get('query');
    const preparedFilters = getPreparedFilters(props.filters, props.assetsListType);
    const emptyCustomFilters = !query && Object.values(preparedFilters || {}).filter(Boolean).length === 0;

    return (
        <section className={bem.list.block({ clearing: list.isClearing })}>
            <header className={bem.list.element('header', { blank: emptyCustomFilters })}>
                {emptyCustomFilters ? (
                    getInlineFilters()
                ) : (
                    <AppliedFilters
                        filters={type === 'main' ? preparedFilters : {}}
                        onFilterDeletion={removeFilters}
                        onFiltersClear={handleFiltersClear}
                        query={query}
                        onQueryDeletion={removeQuery}
                        categoryCustomDateFields={categoryCustomDateFields}
                        categoryCustomBooleanFields={categoryCustomBooleanFields}
                    />
                )}
                {type !== 'linked' && (
                    <>
                        {query && (
                            <OrderDropdown
                                selectedSort={sortType}
                                onChange={setSortType}
                                menuGap={35}
                                menuArrowPosition={105}
                            />
                        )}
                        <FiltersButton
                            provider={newsroom}
                            initialValues={props.filters}
                            setFilters={setFilters}
                            hideStatusField={!isUserProvider}
                        />
                    </>
                )}
                <OptionsButton preferences={preferences} toggle={optionsToggle} />
            </header>
            {!emptyCustomFilters && getInlineFilters()}
            <AssetsTable
                newsroom={newsroom}
                fetchMore={fetchMore}
                list={list}
                items={items}
                preferences={preferences}
                type={type}
            />
            <AnnounceKit floatWidget labels={['stream']} widget="https://announcekit.app/widgets/v2/1fpOG4" />
        </section>
    );
});
