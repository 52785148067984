/* eslint-disable camelcase */

import { isEqual } from 'lodash';
import { slug } from 'lib/string';
import type { Category } from '@schibsted-svp/svp-api-types';
import { nullifyEmptyString, DYNAMIC_FIELD_METADATA_PREFIX } from './index';
import type {
    CategoryData,
    CategoryMetadata as CategoryFormMetadata,
    PodmeMetadata,
    SimpleMetadataField,
    DynamicFieldMetadata,
} from '../types';

type MetadataToSend = {
    [key: string]: {
        value: string;
    };
};

export function preparePodcastCategories(
    podcastCategories: Pick<CategoryFormMetadata, 'podcast_mainCategory' | 'podcast_category'>
) {
    const { podcast_mainCategory, podcast_category } = podcastCategories;

    if (!podcast_mainCategory?.length) return null;

    const isSingleMainCategory =
        podcast_mainCategory.length === 1 && !podcast_category?.[podcast_mainCategory[0]]?.length;

    if (isSingleMainCategory) {
        return { podcast_category: { value: podcast_mainCategory[0] } };
    }

    const categoryTree = podcast_mainCategory.reduce((allCategories, currentCategory) => {
        if (!podcast_category?.[currentCategory]) {
            return { ...allCategories, [currentCategory]: [] };
        }
        return { ...allCategories, [currentCategory]: podcast_category[currentCategory] };
    }, {});

    return { podcast_categoryTree: { value: JSON.stringify(categoryTree) } };
}

export function getSimpleMetadataObject(simpleMetadata: SimpleMetadataField[]) {
    return simpleMetadata.reduce((prev, { key, value }) => ({ ...prev, [key]: { value } }), {});
}

export function getDynamicFieldsMetadataObject(dynamicMetadata: DynamicFieldMetadata[]) {
    return dynamicMetadata.reduce((prev, field) => {
        const key = `${DYNAMIC_FIELD_METADATA_PREFIX}_${field.key}`;
        return { ...prev, [key]: { value: field.value } };
    }, {});
}

export function filterAndFormatMetadata(metadataToSend: MetadataToSend, category: Category) {
    const inheritedMetadata = Object.fromEntries(
        Object.entries(category?.additional?.metadata || {}).filter(([, { inherited }]) => inherited)
    );

    return Object.entries(metadataToSend).reduce((prev, [key, field]) => {
        const value = field?.value && String(field?.value);
        const shouldStillBeInherited = inheritedMetadata[key]?.value === value;

        if (value && !shouldStillBeInherited) {
            return { ...prev, [key]: { value } };
        }

        return prev;
    }, {});
}

function getCastMetadataObject(metadata: Pick<CategoryFormMetadata, 'series_cast'>) {
    if (metadata.series_cast?.length) {
        return {
            series_cast: {
                value: JSON.stringify(metadata.series_cast),
            },
        };
    }

    return undefined;
}

export function prepareMetadata(values: CategoryData, category: Category) {
    const customMetadataKeys = [
        'podcast_mainCategory',
        'podcast_category',
        'podcast_categoryTree',
        'series_cast',
    ] as const;

    type CustomMetadata = (typeof customMetadataKeys)[number];
    type FixedMetadata = Omit<CategoryFormMetadata, CustomMetadata>;

    const fixedMetadata: FixedMetadata = Object.fromEntries(
        Object.entries(values.additional.metadata).filter(
            ([key]) => !customMetadataKeys.includes(key as CustomMetadata)
        )
    );

    const mergedMetadata = {
        ...getSimpleMetadataObject(values.simpleMetadata),
        ...getDynamicFieldsMetadataObject(values.dynamicFieldsMetadata),
        ...fixedMetadata,
        ...preparePodcastCategories(values.additional.metadata),
        ...getCastMetadataObject(values.additional.metadata),
    };

    if (mergedMetadata.podcast_explicit?.value) {
        mergedMetadata.podcast_explicit.value = 'yes';
    }

    return filterAndFormatMetadata(mergedMetadata, category);
}

export function prepareCategoryData(values: CategoryData, category: Category) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { simpleMetadata: _, dynamicFieldsMetadata: __, podmeMetadata: ___, ...restValues } = values;

    return {
        ...restValues,
        parentId: values.parentId || 0,
        additional: {
            ...values.additional,
            description: nullifyEmptyString(values?.additional.description || null),
            shortPath: slug(values?.additional?.shortPath || values?.title),
            metadata: prepareMetadata(values, category),
        },
    };
}

export const preparePodmeMetadata = (values: CategoryData, initialValues: CategoryData) => {
    const fieldsToUpdate = ['sendToPodme', 'sendRssToPodme', 'podmeCategories'];

    const metadataToUpdate: Partial<PodmeMetadata> = fieldsToUpdate.reduce((acc, field) => {
        const fieldValue = values?.podmeMetadata?.[field];
        if (!isEqual(fieldValue, initialValues?.podmeMetadata?.[field])) {
            return { ...acc, [field]: fieldValue };
        }
        return acc;
    }, {});

    if (metadataToUpdate?.sendToPodme === false) {
        if (values?.podmeMetadata?.sendRssToPodme) {
            metadataToUpdate.sendRssToPodme = false;
        }
        if (values?.podmeMetadata?.podmeCategories?.length) {
            metadataToUpdate.podmeCategories = [];
        }
    }

    return Object.keys(metadataToUpdate).length ? metadataToUpdate : undefined;
};
