import { get } from 'lodash';
import { getAsset } from 'store/assets/selectors';

/**
 * Returns a clip data
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.id
 * @param {Object} props.newsroom
 * @return {Object}
 */
export const getClipList = (state, { id, provider }) => {
    if (!id || !provider) return undefined;

    const clips = get(state, `clips.${provider}.${id}`);

    if (!clips) {
        return {};
    }

    if (clips.items) {
        clips.items = [...clips.items].sort().reverse();
    }

    return {
        assetList: clips.items || [],
        nextUrl: clips.nextUrl || '',
        loading: clips.listLoading || false,
        hasMore: !!clips.nextUrl,
    };
};

export const isCreateClipPending = (state, { id, provider }) => {
    return get(state, ['clips', provider, id, 'createClipPending'], false);
};

export const isTrimPending = (state, { id, provider }) => {
    return get(state, ['clips', provider, id, 'trimPending'], false);
};

export const isResetTrimPending = (state, { id, provider }) => {
    return state.clips?.[provider]?.[id]?.resetPending;
};

export const getUpdatedHighlights = (state, { assetId, provider, playback }) => {
    const asset = getAsset(state, { id: assetId, provider });

    if (!asset) {
        return null;
    }

    const { chapters } = asset.additional;

    if (!chapters.length) {
        return null;
    }

    const { begin: startTime, end: endTime } = playback;

    return chapters
        .filter(({ timeline }) => timeline >= startTime && timeline <= endTime)
        .map((highlight) => ({
            ...highlight,
            timeline: highlight.timeline - startTime,
        }));
};
