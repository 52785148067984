export const CHANGE_NEXT_VIDEOS = 'Video/CHANGE_NEXT_VIDEOS';
export const CHANGE_NEXT_VIDEOS_ERROR = 'Video/CHANGE_NEXT_VIDEOS_ERROR';
export const CHANGE_NEXT_VIDEOS_SUCCESS = 'Video/CHANGE_NEXT_VIDEOS_SUCCESS';
export const GENERATE_NEXT_VIDEOS = 'Video/GENERATE_NEXT_VIDEOS';
export const GENERATE_NEXT_VIDEOS_ERROR = 'Video/GENERATE_NEXT_VIDEOS_ERROR';
export const GENERATE_NEXT_VIDEOS_SUCCESS = 'Video/GENERATE_NEXT_VIDEOS_SUCCESS';
export const SET_NEXT_VIDEO = 'Video/SET_NEXT_VIDEO';
export const SET_NEXT_VIDEO_ERROR = 'Video/SET_NEXT_VIDEO_ERROR';
export const SET_NEXT_VIDEO_SUCCESS = 'Video/SET_NEXT_VIDEO_SUCCESS';
export const LOAD_MORE_NEXT_VIDEOS = 'Video/LOAD_MORE_NEXT_VIDEOS';

/**
 * @param {Number} id
 * @param {String} provider
 */
export const generateNextVideos = (id, provider) => ({
    type: GENERATE_NEXT_VIDEOS,
    id,
    provider,
});

/**
 * @param {Number} id
 * @param {String} provider
 * @param {String} error
 */
export const generateNextVideosError = (id, provider, error) => ({
    type: GENERATE_NEXT_VIDEOS_ERROR,
    id,
    provider,
    error,
});

/**
 * @param {Number} id
 * @param {String} provider
 * @param {Array<Number>} items
 * @param {Number|Null} loopsTo
 */
export const generateNextVideosSuccess = (id, provider, items, loopsTo) => ({
    type: GENERATE_NEXT_VIDEOS_SUCCESS,
    id,
    provider,
    items,
    loopsTo,
});

/**
 * @param {Number} id
 * @param {String} provider
 * @param {Object.<Number, Number|Null>} changes
 */
export const changeNextVideos = (id, provider, changes) => ({
    type: CHANGE_NEXT_VIDEOS,
    id,
    provider,
    changes,
});

/**
 * @param {Number} id
 * @param {String} provider
 * @param {String} error
 */
export const changeNextVideosError = (id, provider, error) => ({
    type: CHANGE_NEXT_VIDEOS_ERROR,
    id,
    provider,
    error,
});

/**
 * @param {Number} id
 * @param {String} provider
 */
export const changeNextVideosSuccess = (id, provider) => ({
    type: CHANGE_NEXT_VIDEOS_SUCCESS,
    id,
    provider,
});

/**
 * @param {Number} id
 * @param {String} provider
 * @param {Number|Null} nextId
 */
export const setNextVideo = (id, provider, nextId, isCurrentAsset) => ({
    type: SET_NEXT_VIDEO,
    id,
    provider,
    nextId,
    isCurrentAsset,
});

/**
 * @param {Number} id
 * @param {String} provider
 * @param {String} error
 */
export const setNextVideoError = (id, provider, error) => ({
    type: SET_NEXT_VIDEO_ERROR,
    id,
    provider,
    error,
});

/**
 * @param {Number} id
 * @param {String} provider
 * @param {Object} asset
 */
export const setNextVideoSuccess = (id, provider, asset) => ({
    type: SET_NEXT_VIDEO_SUCCESS,
    id,
    provider,
    asset,
});

/**
 * @param {Number} id
 * @param {String} newsroom
 * @param {Number} page
 */
export const loadMoreNextVideos = (id, provider, page) => ({
    type: LOAD_MORE_NEXT_VIDEOS,
    id,
    provider,
    page,
});
