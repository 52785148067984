import {
    ASSET_ASSIGN_PLAYLIST,
    ASSET_ASSIGN_PLAYLIST_SUCCESS,
    ASSET_ASSIGN_PLAYLIST_ERROR,
    ASSET_UNASSIGN_PLAYLIST,
    ASSET_UNASSIGN_PLAYLIST_SUCCESS,
    ASSET_UNASSIGN_PLAYLIST_ERROR,
} from 'store/assets/actionTypes';
import {
    CHANGE_NEXT_VIDEOS,
    CHANGE_NEXT_VIDEOS_ERROR,
    GENERATE_NEXT_VIDEOS,
    GENERATE_NEXT_VIDEOS_ERROR,
    GENERATE_NEXT_VIDEOS_SUCCESS,
    LOAD_MORE_NEXT_VIDEOS,
} from './actions';

const defaultState = {
    error: null,
    loopsTo: null,
    loading: true,
    page: 1,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @property {Number} action.id
 * @property {String} action.provider
 */
const generateNextVideosReducer = (state, { id, provider }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...defaultState,
        ...state[`${provider}-${id}`],
        items: [],
        loading: true,
    },
});

/**
 * @param {Object} state
 * @param {Object} action
 * @property {Number} action.id
 * @property {String} action.provider
 * @property {String} action.error
 */
const generateNextVideosErrorReducer = (state, { id, provider, error }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...state[`${provider}-${id}`],
        error,
        loading: false,
    },
});

/**
 * @param {Object} state
 * @param {Object} action
 * @property {Number} action.id
 * @property {String} action.provider
 * @property {Array<Number>} action.items
 * @property {Number|Null} action.loopsTo
 */
const generateNextVideosSuccessReducer = (state, { id, provider, items, loopsTo }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...state[`${provider}-${id}`],
        items,
        loading: false,
        loopsTo,
    },
});

/**
 * @param {Object} state
 * @param {Object} action
 * @property {Number} action.id
 * @property {String} action.provider
 */
const changeNextVideosReducer = (state, { id, provider }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...state[`${provider}-${id}`],
        loading: true,
    },
});

const changePlaylistReducer = (state, { id, provider }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...state[`${provider}-${id}`],
        loading: true,
    },
});

/**
 * @param {Object} state
 * @param {Object} action
 * @property {Number} action.id
 * @property {String} action.provider
 * @property {String} action.error
 */
const changeNextVideosErrorReducer = (state, { id, provider, error }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...state[`${provider}-${id}`],
        error,
    },
});

const changePlaylistErrorReducer = (state, { id, provider }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...state[`${provider}-${id}`],
        loading: false,
    },
});

const changePlaylistSuccessReducer = (state, { id, provider }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...state[`${provider}-${id}`],
        loading: false,
    },
});

/**
 * @param {Object} state
 * @param {Object} action
 * @property {Number} action.id
 * @property {String} action.provider
 * @property {Number} action.page
 */
const loadMoreNextVideosReducer = (state, { id, provider, page }) => ({
    ...state,
    [`${provider}-${id}`]: {
        ...state[`${provider}-${id}`],
        loading: true,
        page,
    },
});

/**
 * @param {Object} state
 * @param {Object} action
 * @property {String} action.type
 */
export default (state = {}, action) => {
    switch (action.type) {
        case GENERATE_NEXT_VIDEOS:
            return generateNextVideosReducer(state, action);

        case GENERATE_NEXT_VIDEOS_ERROR:
            return generateNextVideosErrorReducer(state, action);

        case GENERATE_NEXT_VIDEOS_SUCCESS:
            return generateNextVideosSuccessReducer(state, action);

        case CHANGE_NEXT_VIDEOS:
            return changeNextVideosReducer(state, action);
        case ASSET_ASSIGN_PLAYLIST:
        case ASSET_UNASSIGN_PLAYLIST:
            return changePlaylistReducer(state, action);
        case ASSET_ASSIGN_PLAYLIST_SUCCESS:
        case ASSET_UNASSIGN_PLAYLIST_SUCCESS:
            return changePlaylistSuccessReducer(state, action);
        case ASSET_ASSIGN_PLAYLIST_ERROR:
        case ASSET_UNASSIGN_PLAYLIST_ERROR:
            return changePlaylistErrorReducer(state, action);
        case CHANGE_NEXT_VIDEOS_ERROR:
            return changeNextVideosErrorReducer(state, action);

        case LOAD_MORE_NEXT_VIDEOS:
            return loadMoreNextVideosReducer(state, action);

        default:
            return state;
    }
};
