export const defaultNextAssetsState = {
    nextVideo: {
        isUpdating: false,
        pages: 1,
    },
    related: {
        isFetching: false,
        error: null,
        items: [],
    },
    search: {
        isFetching: false,
        error: null,
        items: [],
        query: '',
        selectedFilters: undefined,
        sortType: undefined,
    },
};
