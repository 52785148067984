import * as PropTypes from 'prop-types';
import css from './PosterPlaceholder.module.scss';

function PosterPlaceholder({ className, label }) {
    return (
        <div className={`${css.wrapper} ${className}`}>
            <div className={css.content}>
                <span>{label}</span>
            </div>
        </div>
    );
}

PosterPlaceholder.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
};

PosterPlaceholder.defaultProps = {
    className: null,
    label: 'No thumbnail set',
};

export default PosterPlaceholder;
