import { useState, useCallback, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Connector, Input, Spinner, LabeledContainer } from '@schibsted-svp/react-ui';
import { FaSearch } from 'react-icons/fa';

import { MovablePlaylistCard } from 'components/video/NextVideo/MovablePlaylistCard';
import EmptyResult from 'components/Playlists/EmptyResult';
import ErrorResult from 'components/Playlists/ErrorResult';

import { regularPlaylistsFetchList } from 'store/playlists/list';
import { getPlaylistsList } from 'store/playlists/selectors';
import { selectNewsroom } from 'store/newsroom/selectors';
import { assignPlaylist as assignPlaylistAction } from 'store/assets/actions';
import { LABELS } from 'screens/NewsroomScreen/PlaylistsScreen/utils';

import css from './Playlists.module.scss';

function Playlists({ assetId, assignPlaylist, provider, fetchList, list: { isFetching, error, items, hasNext } }) {
    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = ({ target: { value } }) => setSearchValue(value);
    const handleSearchSubmit = () => {
        fetchList({ name: searchValue });
    };
    const handleSearchKeyPress = (event) => {
        if (event.key !== 'Enter') {
            return;
        }
        event.preventDefault();
        handleSearchSubmit();
    };

    const loadMore = () => {
        if (isFetching || !hasNext) {
            return;
        }
        fetchList({ name: searchValue, more: true });
    };
    const playlistsCount = items.length;
    const isFetchError = error && playlistsCount === 0;
    const isFetched = playlistsCount || (!isFetching && !isFetchError);

    return (
        <div className={css.container}>
            {isFetchError && <ErrorResult onRetry={fetchList} />}
            {isFetched && (
                <>
                    <LabeledContainer strict label="Find a playlist" className={css.search}>
                        <Connector className="">
                            <Input
                                placeholder="Search for playlist"
                                onChange={handleSearchChange}
                                onBlur={handleSearchSubmit}
                                onKeyPress={handleSearchKeyPress}
                                value={searchValue}
                                size="small"
                            />
                            <Button
                                iconOnly
                                variant="standard"
                                type="button"
                                className=""
                                size="small"
                                onClick={handleSearchSubmit}
                            >
                                <FaSearch />
                            </Button>
                        </Connector>
                    </LabeledContainer>
                    <div className={css.list}>
                        <InfiniteScroll
                            loadMore={loadMore}
                            threshold={200}
                            hasMore={hasNext}
                            initialLoad={false}
                            useWindow={false}
                        >
                            {items.map((playlist) => (
                                <MovablePlaylistCard
                                    key={playlist}
                                    id={playlist}
                                    provider={provider}
                                    assignPlaylist={assignPlaylist}
                                    assetId={assetId}
                                    className={css.playlist}
                                />
                            ))}
                            {isFetching && hasNext && (
                                <Spinner containerClassName={css.spinner} label="Loading..." size="small" />
                            )}
                        </InfiniteScroll>
                    </div>
                </>
            )}

            {isFetching && playlistsCount === 0 && <Spinner className={css.spinner} />}
            {!isFetchError && !isFetching && playlistsCount === 0 && <EmptyResult />}
        </div>
    );
}
Playlists.propTypes = {
    provider: PropTypes.string.isRequired,
    fetchList: PropTypes.func.isRequired,
    list: PropTypes.shape({
        isFetching: PropTypes.bool,
        error: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        hasNext: PropTypes.bool.isRequired,
    }).isRequired,
    assetId: PropTypes.string.isRequired,
    assignPlaylist: PropTypes.func.isRequired,
};

function PlaylistsContainer({ assetType }) {
    const dispatch = useDispatch();
    const { id: assetId } = useParams();
    const provider = useSelector(selectNewsroom);
    const list = useSelector(getPlaylistsList);
    const labels = assetType === 'audio' ? LABELS.AUDIO : LABELS.VIDEO;

    const fetchList = useCallback(
        ({ name, more } = {}) => dispatch(regularPlaylistsFetchList({ provider, name, more, labels: [labels] })),
        [dispatch, provider, labels]
    );

    const assignPlaylistDispatch = (...args) => dispatch(assignPlaylistAction(...args));
    useEffect(() => {
        fetchList();
    }, [fetchList]);

    return (
        <Playlists
            provider={provider}
            list={list}
            fetchList={fetchList}
            assetId={assetId}
            assignPlaylist={assignPlaylistDispatch}
        />
    );
}

PlaylistsContainer.propTypes = {
    assetType: PropTypes.string.isRequired,
};

export default memo(PlaylistsContainer);
