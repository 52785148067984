import { get } from 'lodash';
import config from 'config';
import authToken from 'models/authToken';
import Client from './client';

export const client = new Client({ baseUrl: config.auth.url });

type ClientType = typeof client;

interface AuthApiClient extends ClientType {
    fetchToken?: () => string;
    logout?: () => string;
    refreshToken?: () => void;
}

export const authApiClient: AuthApiClient = client;

const HOUR = 3600;

function fetchToken(): string {
    const result = this.get(`${config.auth.url}/auth/token`, {
        params: { credentials: 'include' },
    });
    return get(result, 'jwt') || result;
}

function refreshToken(): string {
    return this.get(`${config.auth.url}/auth/token/refresh`, {
        params: { credentials: 'include' },
    });
}

function logout() {
    return this.delete(`${config.auth.url}/auth/token/refresh`, {
        params: { credentials: 'include' },
    });
}

export function calculateTokenAge(): number {
    const refreshStart = Number(localStorage.getItem('refreshStart'));
    const currentDate = Math.round(Date.now() / 1000);

    return currentDate - refreshStart;
}

export async function setNewToken() {
    const newToken = await authApiClient.refreshToken();
    const parsedToken: { exp: string; iat: number; raw: string; sub: string } | null = authToken.parse(newToken);
    const exp = Number(parsedToken.exp);

    // set start time by substracting 24 hours from exp
    // because iat holds date when refresh token was requested for the first time
    localStorage.setItem('refreshStart', (exp - 24 * HOUR).toString());
}

export async function checkForTokenRefresh(requests) {
    const methods = requests.filter(({ name }) => name.value !== 'streamDuration');
    if (methods.length) {
        const tokenAge = calculateTokenAge();

        if (tokenAge > HOUR) {
            await setNewToken();
        }
    }
}

authApiClient.fetchToken = fetchToken.bind(authApiClient);
authApiClient.logout = logout.bind(authApiClient);
authApiClient.refreshToken = refreshToken.bind(authApiClient);
