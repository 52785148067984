import * as PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import VideoCard from 'components/core/VideoCard';
import { MdVideocamOff } from 'react-icons/md';
import { Button } from '@schibsted-svp/react-ui';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import VideoCardControls from 'components/LiveAssetsList/VideoCardControls';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { isReadyToPublish, isLive } from 'models/asset';

import css from './LiveAssetsList.module.scss';

const cln = classnames.bind(css);

function LiveList({ header, visibleIcons, assets, errorMessage, fetchAssets, newsroom, className }) {
    const userPermissions = useUserPermissions();
    if (assets.length && userPermissions) {
        return (
            <section>
                <header>
                    <h1>{header}</h1>
                </header>
                <TransitionGroup className={className}>
                    {assets
                        .filter(
                            (asset) =>
                                userPermissions.isUser || (userPermissions.isAssociate && asset.status === 'active')
                        )
                        .map((asset) => (
                            <CSSTransition key={asset.id} classNames={css} timeout={800}>
                                <VideoCard
                                    key={asset.id}
                                    asset={asset}
                                    readOnly
                                    variant="simple"
                                    size="big"
                                    controlsPosition="row"
                                    className={cln('live-video-card', {
                                        'live-video-card--to-publish': isReadyToPublish(asset),
                                    })}
                                    visibleIcons={visibleIcons}
                                    asLink={false}
                                >
                                    {isLive(asset) && <VideoCardControls asset={asset} />}
                                </VideoCard>
                            </CSSTransition>
                        ))}
                </TransitionGroup>
            </section>
        );
    }
    return (
        <section>
            <header>
                <h1>{header}</h1>
            </header>
            <div className={css['no-results']}>
                <MdVideocamOff size={60} className={css['camera-icon']} />
                {errorMessage ? (
                    <div className={css['error-info']}>
                        <span>{errorMessage}</span>
                        <Button
                            type="button"
                            variant="standard"
                            size="compact"
                            className={css['retry-button']}
                            onClick={() => fetchAssets(newsroom)}
                        >
                            Retry
                        </Button>
                    </div>
                ) : (
                    <div>No videos</div>
                )}
            </div>
        </section>
    );
}

LiveList.propTypes = {
    header: PropTypes.string,
    visibleIcons: PropTypes.arrayOf(PropTypes.oneOf(['ratio', 'duration', 'endTime', 'date', 'views', 'silent'])),
    assets: PropTypes.arrayOf(PropTypes.object),
    errorMessage: PropTypes.string,
    fetchAssets: PropTypes.func,
    newsroom: PropTypes.string,
    className: PropTypes.string,
};

LiveList.defaultProps = {
    header: '',
    visibleIcons: [],
    assets: [],
    errorMessage: undefined,
    fetchAssets: undefined,
    newsroom: '',
    className: '',
};

export default LiveList;
