import { useState } from 'react';
import { useSearchStoriesQuery } from 'services/admin-bff-sdk';
import { useDebounce } from 'hooks/useDebounce';

export function useStoriesSearch({ provider, delay }: { provider: string; delay: number }) {
    const [term, onStoriesSearch] = useState('');
    const debouncedTerm = useDebounce(term, delay);

    const { data, ...rest } = useSearchStoriesQuery(
        { provider, query: debouncedTerm },
        { skip: debouncedTerm.length < 2 }
    );

    return {
        ...rest,
        onStoriesSearch,
        searchResults: data?.searchStories,
        term,
    };
}
