import { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Divider } from '@schibsted-svp/react-ui';
import type { Category } from '@schibsted-svp/svp-api-types';
import { getBreadCrumbs } from 'lib/categories';
import css from './BreadCrumbs.module.scss';

type BreadCrumbsProps = {
    provider: string;
    categories: {
        isFetching: boolean;
        items: Category[];
    };
};
export const BreadCrumbs = memo(function BreadCrumbs({ provider, categories }: BreadCrumbsProps) {
    const { category: categoryId } = useParams<{ category: string }>();
    const categoryIdNumber = Number(categoryId);
    const breadCrumbs =
        categories.isFetching || !categoryIdNumber ? [] : getBreadCrumbs(categoryIdNumber, categories.items);

    return (
        <>
            <div className={css.container}>
                <Link to={`/${provider}/categories`}>Categories</Link>
                {breadCrumbs.map(({ title, id }) => (
                    <span key={id}>
                        <MdKeyboardArrowRight className={css.icon} size="25" />
                        <Link to={`/${provider}/categories/${id}`}>{title}</Link>
                    </span>
                ))}
            </div>
            <Divider margin="18px -32px 32px" />
        </>
    );
});
