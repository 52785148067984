import { put, takeEvery, select, call, delay } from '@redux-saga/core/effects';
import * as ReactGA from 'react-ga';

import { Notification } from '@schibsted-svp/react-ui';
import { changeVideoThumbnails, updateVideoDuration } from 'store/video/actions';
import { changeAsset, fetchAssetSuccess } from 'store/assets/actions';
import { searchFor } from 'store/ui/actions';
import { scheduleDeleteSuccess } from 'store/schedules/actions';

import { getCurrentUser } from 'store/user/selectors';
import { getAsset } from 'store/assets/selectors';
import { getSearchTerm, getActiveNavigationTab } from 'store/video/navigation-tabs/selectors';

import { matchMqttTopic } from 'store/mqtt/listeners/utils';

export function* notifyUser({ data, asset } = {}) {
    const currentUser = yield select(getCurrentUser);
    const activeAsset = yield select(getActiveNavigationTab);

    if (!currentUser || !activeAsset) return;
    const { lastEditedBy } = data.additional.settings;

    if (
        activeAsset.id === asset.id &&
        lastEditedBy &&
        lastEditedBy !== currentUser &&
        lastEditedBy !== asset.additional.settings.lastEditedBy &&
        !lastEditedBy.startsWith('production') &&
        !lastEditedBy.startsWith('jw-publisher')
    ) {
        yield call(ReactGA.event, {
            category: 'Video',
            action: 'Notify on update',
        });
        Notification.notify.info(`Asset edited by ${lastEditedBy}`, {
            pauseOnFocusLoss: false,
        });
    }
}

const normalizeArray = (object, field) => {
    if (Array.isArray(object[field]) && object[field].length === 0) {
        // eslint-disable-next-line no-param-reassign
        object[field] = {};
    }
};

function* assetMqttSaga({ type, data }) {
    const [, provider, , action] = type.split('/');

    const { id } = data;
    const searchTerm = yield select(getSearchTerm);
    switch (action) {
        case 'updated': {
            const asset = yield select(getAsset, { id, provider });

            if (!asset) break;

            normalizeArray(data.category.additional, 'images');
            normalizeArray(data.category.additional, 'metadata');
            normalizeArray(data.additional, 'access');
            normalizeArray(data.additional, 'metadata');

            if (asset.duration !== data.duration) {
                yield put(updateVideoDuration(provider, id, data.duration));
            }

            if (asset.status !== data.status && data.status === 'active') {
                yield put(scheduleDeleteSuccess({ provider, assetId: id }));
            }

            if (asset.images.snapshots !== data.images.snapshots) {
                yield put(changeVideoThumbnails(id, provider, data.images.snapshots));
            }

            yield put(changeAsset(data));
            // delay to search in order to not override asset with stale values
            yield delay(1000);
            yield put(searchFor({ searchTerm }));
            yield call(notifyUser, { data, asset });
            break;
        }
        case 'created': {
            yield put(searchFor({ searchTerm }));
            yield put(fetchAssetSuccess({ id, asset: data }));
            break;
        }
        default:
    }
}

export default [takeEvery((action) => matchMqttTopic(action, 'asset', 2), assetMqttSaga)];
