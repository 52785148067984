import { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';

import { Button, Dialog } from '@schibsted-svp/react-ui';
import BEM from 'lib/bem';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getProgressInfo } from 'store/video/navigation-tabs/selectors';
import VideoIcon from '../../icons/VideoIcon';
import NavigationTab from '../../NavigationTab';
import NavigationTabProgress from '../../NavigationTabProgress';
import VideoTabHeader from '../VideoTabHeader';

const bem = new BEM('video-navigation');

function VideoTab({ type, title, provider, id, upload, onClose, cancelUpload }) {
    const [isCancelUploadConfirmOpen, setIsCancelUploadConfirmOpen] = useState(false);
    const { state: processingState, progress } = useSelector((state) => getProgressInfo(state, { provider, id }));
    const tabProgress = <NavigationTabProgress variant={processingState} progress={progress} />;

    const history = useHistory();
    const location = {
        pathname: `/${provider}/assets/${id}`,
    };
    const [isTabActive, setIsTabActive] = useState(history.location.pathname.startsWith(location.pathname));

    useEffect(() => {
        setIsTabActive(history.location.pathname.startsWith(location.pathname));
    }, [history.location.pathname, id, location.pathname]);

    const handleCancelUploadCancelClick = () => {
        setIsCancelUploadConfirmOpen(false);
    };

    const handleCancelUploadConfirmClick = () => {
        cancelUpload(provider, id, upload.filename);
        return onClose({ id, provider, active: isTabActive });
    };

    const renderCancelUploadConfirmation = () => {
        return (
            <Dialog
                isOpen={isCancelUploadConfirmOpen}
                onClose={handleCancelUploadCancelClick}
                heading="Closing tab will cancel file upload"
            >
                <Dialog.Section>
                    <h4>Are you sure you want to cancel upload?</h4>
                </Dialog.Section>
                <Dialog.Section mode="flexRight" variant="darker">
                    <Button type="button" variant="standard" onClick={handleCancelUploadCancelClick}>
                        Cancel
                    </Button>
                    <Button type="button" onClick={handleCancelUploadConfirmClick}>
                        Confirm
                    </Button>
                </Dialog.Section>
            </Dialog>
        );
    };

    const isUploading = () => upload.state === 'upload';

    const handleClose = ({ active }) => {
        if (!isUploading()) {
            return onClose({ id, provider, active });
        }

        setIsTabActive(active);
        return setIsCancelUploadConfirmOpen(true);
    };
    return (
        <NavigationTab
            locationPath={location}
            progress={tabProgress}
            error={processingState === 'error'}
            onClose={handleClose}
            assetId={id}
        >
            <VideoIcon />
            <div>
                <VideoTabHeader provider={provider} id={id} type={type} className={bem.element('tab-header')} />
                <div className={bem.element('tab-title')}>{title}</div>
            </div>
            {isUploading() && renderCancelUploadConfirmation()}
        </NavigationTab>
    );
}

VideoTab.propTypes = {
    id: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    upload: PropTypes.object.isRequired,
    cancelUpload: PropTypes.func.isRequired,
};

export default VideoTab;
