import { MdPhone, MdEmail } from 'react-icons/md';
import { RiSlackFill } from 'react-icons/ri';
import css from './SupportContactInfo.module.scss';

export function SupportContactInfo() {
    return (
        <div className={css.contactContainer}>
            <p>To set a password, please contact us:</p>
            <div className={css.contact}>
                <div>
                    <MdPhone size={20} className={css.icon} />{' '}
                    <a className={css.link} href="tel:+46 10-551 74 22">
                        +46 10-551 74 22
                    </a>
                </div>
                <div>
                    <RiSlackFill size={20} className={css.icon} /> <span>#svp-support</span>
                </div>
                <div>
                    <MdEmail size={20} className={css.icon} />{' '}
                    <a className={css.link} href="mailto:svp@schibsted.com">
                        svp@schibsted.com
                    </a>
                </div>
            </div>
        </div>
    );
}
