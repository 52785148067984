import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authEmailSelector } from 'store/auth/selectors';
import { useGetUsersQuery } from 'services/admin-bff-sdk/generated';

export interface UserRole {
    newsroom: string;
    roleType: string;
}

export const useUserRoles = (): UserRole[] => {
    const email = useSelector(authEmailSelector);
    const { data } = useGetUsersQuery({ query: email });
    return useMemo(
        () => data?.getUsers[0]?.roles?.map(({ newsroom, roleType }) => ({ newsroom, roleType })) || [],
        [data?.getUsers]
    );
};
