import { call, put, takeLatest } from '@redux-saga/core/effects';

import apiClient from 'services/api-client';
import relatedApiClient from 'services/related-api-client';

import generateSearchParameters from 'store/utils';

import * as ActionType from './actionTypes';
import * as Action from './actions';

export function* fetchRelated({ provider, id }) {
    try {
        const assets = yield call(relatedApiClient.getRelatedAssets, { provider, id });
        yield put(Action.relatedFetchSuccess({ provider, id, assets }));
    } catch (error) {
        yield put(Action.relatedFetchError({ provider, id, error: String(error) }));
    }
}

export function* searchNext({ provider, id, query, selectedFilters, sortType }) {
    try {
        const parameters = generateSearchParameters(selectedFilters, sortType);

        // TODO: extract next url too?
        const { assets } = yield call(apiClient.fetchFilteredAssets, { provider, query, ...parameters });
        yield put(Action.nextSearchSuccess({ provider, id, assets }));
    } catch (error) {
        yield put(Action.nextSearchError({ provider, id, query, error: String(error) }));
    }
}

export default [takeLatest(ActionType.RELATED_FETCH, fetchRelated), takeLatest(ActionType.NEXT_SEARCH, searchNext)];
