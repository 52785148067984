import { Button, LabeledContainer } from '@schibsted-svp/react-ui';
import { useEffect, useState } from 'react';
import z, { ZodError } from 'zod';

import { TimeInput } from 'components/ui/Form/TimeInput';
import type { CutRange } from 'store/uploads/previews/slice';

import { useVideoPreviewEditorContext } from './VideoPreviewEditorContext';
import css from './CustomPreviewForm.module.scss';
import { useCustomPreviewDialogContext } from '../CustomPreviewDialogContext';

const EMPTY_RANGE = { begin: 0, end: 0 };

const currentRangeSchema = z
    .object({
        begin: z.number(),
        end: z.number(),
    })
    .refine(({ begin, end }) => begin < end, {
        message: 'End time should be greater than start time',
        path: ['end'],
    });

interface CustomPreviewFormProps {
    cutRange?: CutRange;
    onChange: (range?: CutRange) => void;
}

export function CustomPreviewForm({ onChange, cutRange }: CustomPreviewFormProps) {
    const { duration, previewPlayer } = useVideoPreviewEditorContext();
    const { setDisableCreateButton } = useCustomPreviewDialogContext();
    const [currentRange, setCurrentRange] = useState<Partial<CutRange>>(cutRange ?? EMPTY_RANGE);
    const [error, setError] = useState<Partial<{ begin: string[]; end: string[] }> | undefined>(undefined);

    useEffect(() => {
        if (!cutRange) {
            setCurrentRange(EMPTY_RANGE);
            setError(undefined);
        }
    }, [cutRange]);

    useEffect(() => {
        setDisableCreateButton(Boolean(error));
        return () => setDisableCreateButton(false);
    }, [error, setDisableCreateButton]);

    const updateCurrentRange = (changes: Partial<CutRange>) => {
        const nextCutRange = { ...currentRange, ...changes };
        try {
            // as of lack of strict flag in tsconfig, zod returns object with optional properties
            const parsedValue = currentRangeSchema.parse(nextCutRange) as CutRange;
            setError(undefined);
            onChange(parsedValue);
        } catch (e) {
            if (e instanceof ZodError) {
                setError(e.flatten().fieldErrors);
            }
        } finally {
            setCurrentRange(nextCutRange);
        }
    };

    const handleBeginChange = (begin: number) => updateCurrentRange({ begin });

    const handleBeginSetFromPlayer = () => updateCurrentRange({ begin: previewPlayer?.currentTime });

    const handleEndChange = (end: number) => updateCurrentRange({ end });

    const handleEndSetFromPlayer = () => updateCurrentRange({ end: previewPlayer?.currentTime });

    return (
        <div className={css.container}>
            <LabeledContainer strict label="Start time" className={css.input}>
                <TimeInput
                    precise
                    size="small"
                    value={currentRange.begin}
                    maxValue={duration}
                    onValueChange={handleBeginChange}
                    error={error?.begin?.at(0)}
                />
            </LabeledContainer>
            <LabeledContainer>
                <Button type="button" size="small" variant="standard" onClick={handleBeginSetFromPlayer}>
                    Set start time
                </Button>
            </LabeledContainer>

            <LabeledContainer strict label="End time" className={css.input}>
                <TimeInput
                    precise
                    size="small"
                    value={currentRange.end}
                    maxValue={duration}
                    onValueChange={handleEndChange}
                    error={error?.end?.at(0)}
                />
            </LabeledContainer>
            <LabeledContainer>
                <Button type="button" size="small" variant="standard" onClick={handleEndSetFromPlayer}>
                    Set end time
                </Button>
            </LabeledContainer>
        </div>
    );
}
