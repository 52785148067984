import PropTypes from 'prop-types';
import { Button } from '@schibsted-svp/react-ui';

import css from './ErrorResult.module.scss';

export default function ErrorResult({ onRetry }) {
    return (
        <h1 className={css.container}>
            <span>Failed to fetch playlists</span>
            <Button type="button" size="small" onClick={onRetry}>
                Retry
            </Button>
        </h1>
    );
}

ErrorResult.propTypes = {
    onRetry: PropTypes.func.isRequired,
};
