import { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, LabeledContainer, Input, Dropdown } from '@schibsted-svp/react-ui';
import useToggle from 'hooks/useToggle';
import { isChannelRunning } from 'models/channel';
import classnames from 'classnames/bind';
import MediaLiveInputInfoDialogState from './MediaLiveInputInfoDialogState';
import { LiveCreationDialog } from '../LiveCreationDialog';

import css from './MediaLiveInputInfoDialog.module.scss';

const cln = classnames.bind(css);

export const getInputUrl = (url) => url.substring(0, url.lastIndexOf('/') + 1);

export const getVideoKey = (url) => url.substring(url.lastIndexOf('/') + 1);

const getInputLabel = (label, index) => `${index === 1 ? 'Backup ' : ''}${label}`;

const handleInputSelection = (event) => event.target.select();

function MediaLiveInputInfoDialog({ channel, assetId, provider, isStarting }) {
    const { type, urls } = channel.input || {};
    const [isOpened, toggleIsOpened] = useToggle();
    const isRunning = isChannelRunning(channel);

    const cloudEncoderButton = (
        <>
            <Button
                type="button"
                className={cln({ dialogTriggerButton: !isRunning })}
                onClick={toggleIsOpened}
                disabled={!urls}
                variant="standard"
                size="big"
            >
                Network Input - Cloud encoder
            </Button>
            <MediaLiveInputInfoDialogState
                state={channel.state}
                type={type}
                containerClassName={css.dialogTriggerStatus}
            />
        </>
    );

    const networkInputInfo = () => (
        <Dialog
            heading="Network input info"
            isOpen={isOpened}
            dialogTrigger={cloudEncoderButton}
            onClose={toggleIsOpened}
        >
            <Dialog.Section>
                {urls?.map((url, index) =>
                    type === 'PUSH' ? (
                        <div key={url} className={css.container}>
                            <LabeledContainer label={getInputLabel('Network Input URL', index)}>
                                <Input onFocus={handleInputSelection} value={getInputUrl(url)} readOnly />
                            </LabeledContainer>
                            <LabeledContainer label={getInputLabel('Video key', index)}>
                                <Input onFocus={handleInputSelection} value={getVideoKey(url)} readOnly />
                            </LabeledContainer>
                        </div>
                    ) : (
                        <LabeledContainer
                            key={url}
                            className={css.input}
                            label={getInputLabel('Network Input URL', index)}
                        >
                            <Input key={`url:${encodeURI(url)}`} onFocus={handleInputSelection} value={url} readOnly />
                        </LabeledContainer>
                    )
                ) || <div>Network input in cloud encoder not found</div>}
                <MediaLiveInputInfoDialogState state={channel.state} type={type} />
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={toggleIsOpened}>
                    Close
                </Button>
            </Dialog.Section>
        </Dialog>
    );

    if (isRunning) {
        return networkInputInfo();
    }

    return (
        <Dropdown
            variant="standard"
            hideOnOutsideClick
            connectGap={0}
            disabled={isStarting}
            leftButtonRenderer={networkInputInfo}
        >
            <LiveCreationDialog
                assetId={assetId}
                newsroom={provider}
                channelId={channel?.id}
                form="live"
                triggerType="dropdown"
            />
        </Dropdown>
    );
}

MediaLiveInputInfoDialog.propTypes = {
    assetId: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    channel: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.string,
        input: PropTypes.shape({
            type: PropTypes.oneOf(['PULL', 'PUSH']).isRequired,
            urls: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
    }),
    isStarting: PropTypes.bool,
};

MediaLiveInputInfoDialog.defaultProps = {
    channel: {},
    isStarting: false,
};

export default memo(MediaLiveInputInfoDialog);
