import PropTypes from 'prop-types';
import { Button } from '@schibsted-svp/react-ui';
import { bemgen } from 'lib/bem';
import { FiltersDialog } from 'components/core/FiltersDialog';
import './FiltersButton.scss';

function FiltersButton(props) {
    return (
        <FiltersDialog
            containerClassName={bemgen('FiltersButton')}
            hideStatusField={props.hideStatusField}
            onFilter={props.setFilters}
            dialogTrigger={(onClick) => (
                <Button onClick={onClick} type="button" variant="standard">
                    Filters
                </Button>
            )}
            {...props}
        />
    );
}

FiltersButton.propTypes = {
    hideStatusField: PropTypes.bool,
    setFilters: PropTypes.func,
};

FiltersButton.defaultProps = {
    hideStatusField: false,
    setFilters: () => {},
};

export default FiltersButton;
