import PropTypes from 'prop-types';
import ReactTooltip from '@tippyjs/react';
import { roundArrow } from 'tippy.js';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/themes/light.css';
import './Tooltip.scss';

/**
 * This is a wrapper component for https://github.com/tvkhoa/react-tippy.
 * Please check the docks for the available props
 */
function Tooltip({ children, ...rest }) {
    return (
        <ReactTooltip theme="light" arrow={roundArrow} animation="shift-away" offset={[25, 25]} {...rest}>
            {children}
        </ReactTooltip>
    );
}

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Tooltip;
