import omit from 'lodash/omit';
import { merge } from 'lib/object';
import { REMOVE_NAVIGATION_TAB } from 'store/video/navigation-tabs/actions';
import {
    FETCH_CONVERSION_URL,
    FETCH_STREAM_CONVERSION,
    FETCH_STREAM_CONVERSION_PROGRESS,
    FETCH_STREAM_CONVERSION_SUCCESS,
    FETCH_STREAM_CONVERSION_FAILURE,
    STREAM_CONVERSION_RESET,
} from './actions';

const initialState = {};

export default (state = initialState, action) => {
    const { provider, id, url } = action;

    const mergeState = (conversionData) => merge(state, { [provider]: { [id]: { video: conversionData } } });

    switch (action.type) {
        case FETCH_STREAM_CONVERSION: {
            const conversionData = {
                state: 'convert',
                progress: 0,
            };

            return mergeState(conversionData);
        }

        case FETCH_CONVERSION_URL: {
            const conversionData = {
                url,
            };

            return mergeState(conversionData);
        }

        case FETCH_STREAM_CONVERSION_PROGRESS: {
            const conversionData = {
                state: 'convert',
                progress: Math.round(action.progress),
                url,
            };

            return mergeState(conversionData);
        }

        case FETCH_STREAM_CONVERSION_SUCCESS: {
            const conversionData = {
                state: 'done',
                progress: 100,
                url,
            };

            return mergeState(conversionData);
        }

        case FETCH_STREAM_CONVERSION_FAILURE: {
            const conversionData = {
                state: 'error',
                url: undefined,
                error: action.error,
            };

            return mergeState(conversionData);
        }

        case STREAM_CONVERSION_RESET: {
            const conversionData = {
                state: '',
                progress: 0,
                url: undefined,
            };
            return mergeState(conversionData);
        }

        case REMOVE_NAVIGATION_TAB: {
            const assetState = state?.[provider]?.[id];
            if (assetState) {
                return {
                    ...state,
                    [provider]: omit(state[provider], id),
                };
            }
            return state;
        }

        default:
            return state;
    }
};
