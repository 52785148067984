import { Label, Spinner, InputError } from '@schibsted-svp/react-ui';
import { HistoryListItem } from './HistoryListItem';
import { HistoryData } from './helpers';
import css from './HistoryList.module.scss';

type HistoryListProps = {
    data: HistoryData[];
    isLoading: boolean;
    isError: boolean;
    openPreview: (data: HistoryData) => void;
};

export function HistoryList({ data, isLoading, isError, openPreview }: HistoryListProps) {
    if (isLoading) {
        return <Spinner containerClassName={css.spinner} size="small" />;
    }

    if (isError) {
        return <InputError className={css.error}>History fetching error</InputError>;
    }

    if (!data || !data.length) {
        return null;
    }

    return (
        <ul className={css.list}>
            <li className={css.header}>
                <Label>Date</Label>
                <Label>History</Label>
                <Label>User</Label>
            </li>
            {data.map((itemData) => (
                <HistoryListItem data={itemData} key={`history:item:${itemData.id}`} openPreview={openPreview} />
            ))}
        </ul>
    );
}
