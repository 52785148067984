import * as Actions from './actionTypes';

export const categoriesFetch = ({ provider }) => ({
    type: Actions.CATEGORIES_FETCH,
    provider,
});

export const categoriesFetchSuccess = ({ provider, categories }) => ({
    type: Actions.CATEGORIES_FETCH_SUCCESS,
    provider,
    categories,
});

export const categoriesFetchError = ({ provider }) => ({
    type: Actions.CATEGORIES_FETCH_ERROR,
    provider,
    error: true,
});

export const categoriesFetched = ({ provider }) => ({
    type: Actions.CATEGORIES_FETCHED,
    provider,
});

export const categorySubmission = (provider, data) => ({
    type: Actions.CATEGORY_SUBMISSION,
    payload: {
        provider,
        data,
    },
    meta: {
        thunk: true,
    },
});

export const categorySubmissionError = (meta) => ({
    type: Actions.CATEGORY_SUBMISSION_ERROR,
    error: true,
    meta,
});

export const categorySubmissionSuccess = (provider, category, meta) => ({
    type: Actions.CATEGORY_SUBMISSION_SUCCESS,
    payload: { category, provider },
    meta,
});

export const toggleCategoryVisibility = (provider, categoryId) => ({
    type: Actions.CATEGORY_VISIBILITY_TOGGLE,
    payload: { categoryId, provider },
    meta: {
        thunk: true,
    },
});

export const categoryVisibilityToggleSuccess = (provider, category, meta) => ({
    type: Actions.CATEGORY_VISIBILITY_TOGGLE_SUCCESS,
    payload: { provider, category },
    meta,
});

export const fetchCategory = (provider, categoryId) => ({
    type: Actions.CATEGORY_FETCH,
    payload: { categoryId, provider },
    meta: {
        thunk: true,
    },
});

export const fetchCategorySuccess = (provider, category, meta) => ({
    type: Actions.CATEGORY_FETCH_SUCCESS,
    payload: { provider, category },
    meta,
});

export const setSeriesFlag = (provider, categoryId, isSeries) => ({
    type: Actions.CATEGORY_SET_SERIES_FLAG,
    payload: { categoryId, provider, isSeries },
    meta: {
        thunk: true,
    },
});
