import { combineReducers } from '@reduxjs/toolkit';

import navigationTabs from './navigation-tabs/reducers';
import nextVideo from './next-video/reducers';
import upload from './upload/reducers';
import conversion from './stream-conversion/reducers';
import cutting from './cutting/reducers';

export default combineReducers({
    navigationTabs,
    nextVideo,
    upload,
    conversion,
    cutting,
});
