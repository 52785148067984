import * as PropTypes from 'prop-types';
import ReactCountdown from 'react-countdown';
import { Label } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';
import css from './Countdown.module.scss';

const cln = classnames.bind(css);

/**
 * This component is based on [react-countdown](https://github.com/ndresx/react-countdown) library.
 * Please check [the docs](https://github.com/ndresx/react-countdown) for the available props
 */

const formatTimeUnit = (timeUnits) => {
    return timeUnits.map((timeUnit) => (timeUnit < 10 ? `0${timeUnit}` : timeUnit));
};

// TODO if it can be reused move it to some common place
// eslint-disable-next-line react/prop-types
const countdownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    props: { hideOnComplete, label = 'Starts in:' },
}) => {
    // eslint-disable-line
    const dayCount = Number(days);
    let dayString = '';

    if (dayCount > 0) {
        dayString = `${days} ${days > 1 ? 'days ' : 'day '}`;
    }
    const [formattedHours, formattedMinutes, formattedSeconds] = formatTimeUnit([hours, minutes, seconds]);
    const countdown = dayCount > 0 ? dayString : `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    return completed && hideOnComplete ? null : (
        <span className={css.Countdown__inner}>
            <Label className={css.Countdown__label}>{label}</Label>
            <br />
            <span>{countdown}</span>
        </span>
    );
};

function Countdown({ className, ...props }) {
    return (
        <span className={cln('Countdown', className)}>
            <ReactCountdown {...props} />
        </span>
    );
}

Countdown.propTypes = {
    /** Custom class name for the countdown container */
    className: PropTypes.string,
    renderer: PropTypes.func,
};

Countdown.defaultProps = {
    className: undefined,
    renderer: countdownRenderer,
};

export default Countdown;
