import { LINKEDPROVIDERS_FETCH, LINKEDPROVIDERS_FETCH_SUCCESS, LINKEDPROVIDERS_FETCH_ERROR } from './actionTypes';

const defaultState = {
    isFetching: false,
    linked: undefined,
};

function linkedProviders(state = defaultState, action) {
    switch (action.type) {
        case LINKEDPROVIDERS_FETCH: {
            return {
                ...state,
                isFetching: true,
            };
        }

        case LINKEDPROVIDERS_FETCH_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                linked: action.payload.linked,
            };
        }

        case LINKEDPROVIDERS_FETCH_ERROR: {
            return {
                ...state,
                isFetching: false,
            };
        }

        default:
            return state;
    }
}

export default linkedProviders;
