import { FaCheckCircle } from 'react-icons/fa';
import Tooltip from 'components/ui/Tooltip';
import css from './PodmeCheckMark.module.scss';

type PodmeCheckMarkProps = {
    podmeId: number;
};

export function PodmeCheckMark({ podmeId }: PodmeCheckMarkProps) {
    return (
        <Tooltip theme="light" content={`ID: ${podmeId}`} offset={[15, 15]}>
            <span>
                <FaCheckCircle className={css.checkIcon} />
            </span>
        </Tooltip>
    );
}
