import { get } from 'lodash';

/**
 * @param {Object} params
 * @param {Number} params.id
 * @param {String} params.provider
 * @param {Object} params.body
 * @returns {Promise.<Object>}
 */
export async function transcodeIngest({ id, provider, body }) {
    const result = await this.put(`v1/ingest/${provider}/transcodings/${id}`, { json: body });
    return get(result, '_embedded');
}
