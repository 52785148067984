import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetData } from 'store/assets/selectors';
import { fetchAsset } from 'store/assets/actions';

export function useAssetStore() {
    const { newsroom: newsroomParam, id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAsset({ id, provider: newsroomParam }));
    }, [newsroomParam, id, dispatch]);

    const { asset, error, isFetching } =
        useSelector((state) => getAssetData(state, { id, provider: newsroomParam })) || {};

    return { asset, error, isFetching };
}
