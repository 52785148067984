import classnames from 'classnames/bind';
import config from 'config';
import { canRenderAssetQualityForNewsroom } from 'services/asset-quality';
import { getAsset, getAssetScore } from 'store/assets/selectors';
import Tooltip from 'components/ui/Tooltip';
import PoorMetadataCircleIcon from 'components/icons/PoorMetadataCircleIcon';
import { useSelector } from 'react-redux';
import { AssetChecklist } from '../AssetChecklist';
import css from './AssetQualityTooltip.module.scss';

const cln = classnames.bind(css);

const { assetList: assetListPlacement } = config.assetQuality.placements;

type AssetQualityTooltipProps = {
    assetId: number;
    provider: string;
    className?: string;
};

export function AssetQualityTooltip({ assetId, provider, className }: AssetQualityTooltipProps) {
    const asset = useSelector((state) => getAsset(state, { id: assetId, provider }));
    const assetScore = useSelector((state) => getAssetScore(state, assetId, provider));
    const canRender = assetScore < 1 && canRenderAssetQualityForNewsroom(provider, assetListPlacement, asset.assetType);

    if (!canRender) {
        return null;
    }

    const tooltipContent = (
        <div className={css.content}>
            <h4 className={css.heading}>
                <PoorMetadataCircleIcon className={css.headingIcon} />
                Poor Metadata
            </h4>
            <AssetChecklist assetId={assetId} provider={provider} />
        </div>
    );

    return (
        <Tooltip theme="light" className={cln('tooltip', className)} content={tooltipContent} placement="right">
            <div>
                <PoorMetadataCircleIcon />
            </div>
        </Tooltip>
    );
}
