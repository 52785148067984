import PropTypes from 'prop-types';

import { Button, Connector, Input, LabeledContainer, Select, Radio, Checkbox } from '@schibsted-svp/react-ui';
import { FaSearch } from 'react-icons/fa';

import config from 'config';

import css from './PlaylistsScreenForm.module.scss';
import { LABELS } from './utils';

const { playlists } = config;

function PlaylistsScreenForm({
    handleSearchSubmit,
    handleSearchChange,
    selectedLabels,
    onLabelChange,
    onTypeChange,
    selectedType,
    searchValue,
}) {
    const handleOnTypeChange = (event) => {
        const { value } = event.target;
        onTypeChange(value);
    };

    const onPodcastOnlyChange = (event) => {
        const { checked } = event.target;
        const newType = checked ? LABELS.PODCAST : LABELS.AUDIO;
        onTypeChange(newType);
    };

    return (
        <form onSubmit={handleSearchSubmit} className={css.form}>
            <Connector className={css.connector}>
                <Input placeholder="Search for playlist" onChange={handleSearchChange} value={searchValue} />
                <Button iconOnly type="submit" variant="primary">
                    <FaSearch color={css.white} />
                </Button>
            </Connector>
            <LabeledContainer variant="horizontal" label="Filtered by" className={css.filters}>
                <LabeledContainer variant="horizontal" label="Labels" className={css.labels}>
                    <Select
                        size="small"
                        isMulti
                        options={playlists.labels}
                        isClearable
                        value={selectedLabels}
                        onChange={onLabelChange}
                        className={css.select}
                    />
                </LabeledContainer>
                <LabeledContainer variant="horizontal" label="Type" className={css.type}>
                    <Radio
                        name="radio"
                        label="Video"
                        value={LABELS.VIDEO}
                        onChange={handleOnTypeChange}
                        checked={selectedType === LABELS.VIDEO}
                    />
                    <Radio
                        name="radio"
                        label="Audio"
                        value={LABELS.AUDIO}
                        onChange={handleOnTypeChange}
                        checked={selectedType === LABELS.AUDIO || selectedType === LABELS.PODCAST}
                    />
                    <Radio
                        name="radio"
                        label="Playlists"
                        value={LABELS.POP}
                        onChange={handleOnTypeChange}
                        checked={selectedType === LABELS.POP}
                    />
                    <Checkbox
                        name="isPodcastPlaylist"
                        label="Podcasts only"
                        checked={selectedType === LABELS.PODCAST}
                        onChange={onPodcastOnlyChange}
                    />
                </LabeledContainer>
            </LabeledContainer>
        </form>
    );
}
export default PlaylistsScreenForm;

PlaylistsScreenForm.propTypes = {
    handleSearchSubmit: PropTypes.func.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    selectedLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
    onLabelChange: PropTypes.func.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    selectedType: PropTypes.string.isRequired,
    searchValue: PropTypes.string.isRequired,
};
