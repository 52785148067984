import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as ReactGA from 'react-ga';
import { Dialog } from '@schibsted-svp/react-ui';
import useToggle from 'hooks/useToggle';
import { selectNewsroom } from 'store/newsroom/selectors';
import { getNewsroomConfig } from 'config';

import NewsroomsList from 'components/NewsroomsList';

import css from './Logo.module.scss';

function Logo() {
    const [isOpen, setIsOpen] = useToggle();
    const newsroom = useSelector(selectNewsroom);

    useEffect(() => {
        if (isOpen) {
            ReactGA.modalview('select-newsroom');
        }
    }, [isOpen]);

    const newsroomConfig = getNewsroomConfig(newsroom);
    const newsroomLogo = { backgroundImage: `url(${newsroomConfig.logo.url})` };
    const logo = (
        <button aria-label="Stream logo" type="button" className={css.logo} style={newsroomLogo} onClick={setIsOpen} />
    );

    return (
        <Dialog className={css.newsroomSelectDialog} dialogTrigger={logo} onClose={setIsOpen} isOpen={isOpen}>
            <Dialog.Section variant="darker" className={css.dialogHeading}>
                Select newsroom
            </Dialog.Section>
            <NewsroomsList onSelectNewsroom={setIsOpen} />
        </Dialog>
    );
}

export default Logo;
