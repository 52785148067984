import { useWatch } from 'react-hook-form';
import { Input, LabeledContainer } from '@schibsted-svp/react-ui';
import { MediaLiveInputType } from 'services/admin-bff/contants';
import * as validatorHelpers from 'components/video/live/form/validators';
import { useGetMediaLiveInputsQuery } from 'services/admin-bff-sdk/generated';
import useNewsroom from 'hooks/useNewsroom';
import { useMediaLiveInputFormContext } from './hooks';
import { MediaLivePushInfo } from './MediaLivePushInfo';
import { MediaLiveReservedInputsSelect } from './MediaLiveReservedInputsSelect';

export function MediaLiveSource() {
    const newsroom = useNewsroom();
    const { data: mediaLiveInputData } = useGetMediaLiveInputsQuery(
        { newsroom, reserved: true },
        { refetchOnMountOrArgChange: true }
    );
    const reservedInputs = mediaLiveInputData?.mediaLiveInputs || [];

    const { formApi } = useMediaLiveInputFormContext();
    const {
        register,
        control,
        formState: { errors },
    } = formApi;

    const inputType = useWatch({ name: 'inputType', control });

    const urlValidator = {
        required: validatorHelpers.required,
        url: validatorHelpers.url,
        hlsOrRtmpUrl: validatorHelpers.hlsOrRtmpUrl,
    };

    if (inputType === MediaLiveInputType.PULL) {
        return (
            <LabeledContainer label="Network Input URL">
                <Input
                    {...register('pull-source', { validate: urlValidator, shouldUnregister: true })}
                    error={errors?.['pull-source']?.message}
                />
            </LabeledContainer>
        );
    }

    if (inputType === MediaLiveInputType.PUSH && reservedInputs.length > 0) {
        return <MediaLiveReservedInputsSelect inputs={reservedInputs} />;
    }

    return <MediaLivePushInfo />;
}
