import classnames from 'classnames/bind';
import { CSSProperties, memo, MouseEventHandler, PropsWithChildren, useCallback, useMemo } from 'react';
import type { Item } from './types';
import { useSubscribeTimelineState, useTimelineState } from './hooks/useTimelineState';
import { TimelineState } from './TimelineContext';
import css from './TimelineItem.module.scss';

const cln = classnames.bind(css);

export type TimelineItemProps = Omit<Item, 'id'> &
    PropsWithChildren<{
        active?: boolean;
        className?: string;
        height?: number;
        index: number;
        max: number;
        min: number;
    }>;

export const TimelineItem = memo<TimelineItemProps>(function TimelineItem({
    children,
    className,
    height,
    index,
    startTime,
    endTime,
    max,
    min,
}) {
    const { setState } = useTimelineState();
    const pixelsPerSecond = useSubscribeTimelineState((state) => state.pixelsPerSecond);

    const handleMouseEnter = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
        const selection: TimelineState['selection'] = { index, startTime, endTime, max, min };
        setState({ selection });
    }, [index, startTime, endTime, max, min, setState]);

    const handleMouseLeave = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
        setState({ selection: undefined });
    }, [setState]);

    const style = useMemo<CSSProperties>(
        () => ({
            transform: `translateX(${startTime * pixelsPerSecond}px)`,
            width: `${(endTime - startTime) * pixelsPerSecond}px`,
            height,
        }),
        [endTime, height, pixelsPerSecond, startTime]
    );

    return (
        <div
            className={cln(css.item, className)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={style}
        >
            {children}
        </div>
    );
});
