import { forwardRef, ChangeEvent, FocusEvent } from 'react';
import config from 'config';
import { MdClear as ClearIcon } from 'react-icons/md';
import { CgUndo as UndoIcon } from 'react-icons/cg';
import { LabeledContainer, Input } from '@schibsted-svp/react-ui';

import css from './RtmpStreamKey.module.scss';

const adjustStreamKey = (streamKey: string): string => {
    // allow only URL safe characters
    return streamKey.replace(/[^a-zA-Z0-9_-]/g, '');
};

interface RtmpStreamKeyProps {
    value: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    error?: string;
    readOnly?: boolean;
}

export const RtmpStreamKey = forwardRef<Input & HTMLInputElement, RtmpStreamKeyProps>((props, ref) => {
    const { value, defaultValue, onChange, error, readOnly = false } = props;

    const handleKeyReset = () => onChange(value ? '' : defaultValue || '');
    const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => onChange(adjustStreamKey(event.target.value));
    const handleFocus = (event: FocusEvent<HTMLInputElement>) => event.target.select();

    return (
        <div className={css.container}>
            <LabeledContainer label="Network Input URL">
                <Input value={config.live.rtmp.url} onFocus={handleFocus} readOnly />
            </LabeledContainer>
            <LabeledContainer label="Stream Key">
                <Input
                    error={error}
                    value={value}
                    onFocus={handleFocus}
                    ref={ref}
                    {...(readOnly
                        ? { readOnly }
                        : {
                              icon: (
                                  <span className={css.streamKeyIcon} onClick={handleKeyReset}>
                                      {value ? <ClearIcon /> : <UndoIcon />}
                                  </span>
                              ),
                              iconPosition: 'right' as const,
                              onChange: handleKeyChange,
                          })}
                />
            </LabeledContainer>
        </div>
    );
});

RtmpStreamKey.displayName = 'RtmpStreamKey';
