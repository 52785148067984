import { useState } from 'react';
import * as PropTypes from 'prop-types';

import { CopyToClipboard, Dialog, Button } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';
import EmbedForm from '../EmbedForm';

import css from './EmbedDialog.module.scss';

const cln = classnames.bind(css);

function EmbedDialog({ asset, buttonClassName, paywallAccess, ...restProps }) {
    const [isOpened, setIsOpened] = useState(false);
    const [embedCode, setEmbedCode] = useState('');
    const [isEmbedCopied, setIsEmbedCopied] = useState(false);
    const { status } = asset;

    const { disabled } = restProps;

    if (status !== 'active' || paywallAccess) return null;

    let embedCopiedTimer = null;

    const toggleDialog = () => setIsOpened(!isOpened);

    const onEmbedCopied = () => {
        clearTimeout(embedCopiedTimer);
        setIsEmbedCopied(true);
        embedCopiedTimer = setTimeout(() => setIsEmbedCopied(false), 1000);
    };

    return (
        <Dialog
            heading="Embed"
            className={cln('EmbedDialog')}
            isOpen={isOpened}
            onClose={toggleDialog}
            dialogTrigger={
                <Button
                    variant="standard"
                    size="small"
                    type="button"
                    className={buttonClassName}
                    onClick={toggleDialog}
                    disabled={disabled}
                >
                    Embed
                </Button>
            }
        >
            <Dialog.Section>
                {isOpened && (
                    <EmbedForm onEmbedCodeUpdated={(code) => setEmbedCode(code)} {...{ asset, ...restProps }} />
                )}
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={toggleDialog}>
                    Close
                </Button>
                <CopyToClipboard text={embedCode} onCopy={onEmbedCopied}>
                    <Button
                        type="button"
                        variant="primary"
                        className={cln('EmbedDialog__copyButton', {
                            'EmbedDialog__copyButton--copied': isEmbedCopied,
                        })}
                    >
                        {isEmbedCopied ? 'Copied!' : 'Copy'}
                    </Button>
                </CopyToClipboard>
            </Dialog.Section>
        </Dialog>
    );
}

EmbedDialog.propTypes = {
    asset: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    provider: PropTypes.string.isRequired,
    paywallAccess: PropTypes.bool.isRequired,
    buttonClassName: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    startTime: PropTypes.number,
};

EmbedDialog.defaultProps = {
    buttonClassName: undefined,
    startTime: undefined,
};

export default EmbedDialog;
