import { useContext } from 'react';
import FormPlayer from './Player';
import FormPlayerContext from './PlayerContext';
import FormPlayerProvider from './PlayerProvider';

const withFormPlayer = (WrappedComponent) => {
    function WithFormPlayer(props) {
        const { player } = useContext(FormPlayerContext);
        return <WrappedComponent {...props} player={player} />;
    }
    WithFormPlayer.displayName = `withFormPlayer(${WrappedComponent.displayName || WrappedComponent.name})`;
    return WithFormPlayer;
};

export { FormPlayer, FormPlayerContext, FormPlayerProvider, withFormPlayer };
