import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getPlaylistDeletingState, selectPlaylist } from 'store/playlists/selectors';
import Playlist from './Playlist';

function PlaylistContainer({ id, provider, ...props }) {
    const playlist = useSelector((state) => selectPlaylist(state, provider, id));
    const isDeleting = useSelector(getPlaylistDeletingState);

    return playlist ? <Playlist playlist={playlist} isDeleting={isDeleting} {...props} /> : null;
}

PlaylistContainer.propTypes = {
    id: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
};

export default memo(PlaylistContainer);
