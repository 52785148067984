import type { Asset } from '@schibsted-svp/svp-api-types';
import { useAppSelector } from 'store/hooks';
import { getAsset } from 'store/assets/selectors';
import { getAudioFileFromMp4Url } from '../helpers';

export function useGetAsset({ assetId, provider }: { assetId: number; provider: string }) {
    return useAppSelector((state) => getAsset(state, { id: assetId, provider }) as Asset | undefined);
}

export function useGetAssetAudioUrl(props: { assetId: number; provider: string }) {
    const asset = useGetAsset(props);
    if (!asset?.streamUrls.mp4) {
        return undefined;
    }

    return getAudioFileFromMp4Url(asset.streamUrls.mp4);
}
