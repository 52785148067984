import { parseThumbnailsWebVTT } from 'lib/subtitles';

/**
 * @typedef {Object} Thumbnail
 * @property {Number} startTime
 * @property {String} url
 */

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.id
 * @return {Promise<Array<Thumbnail>>}
 */
export async function fetchThumbnails({ provider, id }) {
    const response = await this.get(`{PROVIDER}/assets/${id}`, { provider, query: { cb: Date.now() } });
    return parseThumbnailsWebVTT(response);
}
