import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWatch, type UseFormReturn } from 'react-hook-form';
import { MdCheck, MdDateRange, MdDeleteForever } from 'react-icons/md';
import { BiMessageCheck } from 'react-icons/bi';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { LabeledContainer, Spinner, Checkbox, Input } from '@schibsted-svp/react-ui';
import { relative as getRelativeDate } from 'lib/date';
import { getTimestamp, isFromFuture } from 'lib/time';
import { reportMessageToSentry } from 'lib/error';
import useInterval from 'hooks/useInterval';
import { getScheduleForAsset } from 'store/schedules/selectors';
import { SCHEDULES_SCOPE } from 'store/schedules/constants';
import { isActive, isAudio, getMetadata } from 'models/asset';
import { DateTimeMergedRHF } from 'components/video/edit/Form/DateTimeMerged';
import type { FormValues } from '../../types';
import css from './PushNotifications.module.scss';

const getNotificationSentData = (asset: Asset) => {
    try {
        const { notificationSent } = getMetadata(asset);
        return notificationSent ? JSON.parse(notificationSent) : undefined;
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to parse notification sent data',
            extras: {
                error,
            },
        });
        return undefined;
    }
};

const getIsAboutToPush = (schedule: { time: number }) => {
    return schedule?.time && !isFromFuture(schedule.time - 60);
};

type PushNotificationsProps = {
    asset: Asset;
    formApi: UseFormReturn<FormValues>;
};

export function PushNotifications({ asset, formApi }: PushNotificationsProps) {
    const { control, register, setValue } = formApi;

    const [title, pushNotificationsTime] = useWatch({
        control,
        name: ['title', 'pushNotifications.time'],
    });

    const assetSchedule = useSelector((state) =>
        getScheduleForAsset(state, { provider: asset.provider, id: asset.id, scope: SCHEDULES_SCOPE.ASSET })
    );
    const notificationSchedule = useSelector((state) =>
        getScheduleForAsset(state, { provider: asset.provider, id: asset.id, scope: SCHEDULES_SCOPE.NOTIFICATION })
    );

    const [isAboutToPush, setIsAboutToPush] = useState(false);
    const [isCustomDateExpanded, setIsCustomDateExpanded] = useState(false);

    useEffect(() => {
        setIsAboutToPush(getIsAboutToPush(notificationSchedule));
    }, [notificationSchedule]);

    useInterval(() => {
        setIsAboutToPush(getIsAboutToPush(notificationSchedule));
    }, 5000);

    useEffect(() => {
        setIsCustomDateExpanded(Boolean(pushNotificationsTime));
    }, [pushNotificationsTime]);

    const clearCustomDate = () => {
        setValue('pushNotifications.time', null, { shouldTouch: true, shouldDirty: true });
        setIsCustomDateExpanded(false);
    };

    const onCustomDateToggle = () => {
        if (!isCustomDateExpanded) {
            const time = assetSchedule?.publishTime || getTimestamp(new Date());
            setValue('pushNotifications.time', time, { shouldTouch: true, shouldDirty: true });
        }
        setIsCustomDateExpanded((expanded) => !expanded);
    };

    const getSendPushLabel = () => {
        if (isCustomDateExpanded) {
            return 'Send push at:';
        }
        const assetType = isAudio(asset) ? 'podcast' : 'video';
        return isActive(asset) ? 'Send push now' : `Send push when ${assetType} is published`;
    };

    const notificationSent = getNotificationSentData(asset);
    const defaultContent = title || asset.title;
    const minCustomDate = assetSchedule?.publishTime && new Date(assetSchedule.publishTime * 1000);

    return (
        <section className={css.container}>
            {notificationSent ? (
                <ul className={css.notificationsSentInfo}>
                    <li>
                        <MdCheck /> Push sent
                    </li>
                    <li>
                        <MdDateRange /> {getRelativeDate(notificationSent.time * 1000)}
                    </li>
                    <li>
                        <BiMessageCheck /> {notificationSent.numRecipients}
                    </li>
                </ul>
            ) : (
                <div className={css.form}>
                    {isAboutToPush ? (
                        <Spinner label="Push will be sent within less than 1 minute" size="compact" />
                    ) : (
                        <>
                            <Checkbox {...register('pushNotifications.send')} label={getSendPushLabel()} />
                            <div className={css.customDate}>
                                {isCustomDateExpanded ? (
                                    <>
                                        <DateTimeMergedRHF
                                            name="pushNotifications.time"
                                            control={control}
                                            className={css.dateTimeMerged}
                                            minDate={minCustomDate}
                                            isClearable={false}
                                        />
                                        <MdDeleteForever onClick={clearCustomDate} className={css.binIcon} />
                                    </>
                                ) : (
                                    <div className={css.customDateToggle} onClick={onCustomDateToggle}>
                                        +set custom date
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
            <LabeledContainer strict label="push content">
                <Input
                    {...register('pushNotifications.content')}
                    placeholder={notificationSent ? notificationSent.body : defaultContent}
                    disabled={Boolean(notificationSent) || isAboutToPush}
                    type="textarea"
                    size="small"
                />
            </LabeledContainer>
        </section>
    );
}
