import { useEffect, useState } from 'react';
import { reportMessageToSentry } from 'lib/error';
import { adminBffClient } from 'services';

export function useEncoderInputPreview(encoderId, inputId) {
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const { base64 } = await adminBffClient.fetchEncoderInputImage({ encoderId, inputId });
                setUrl(`data:image/jpeg;base64, ${base64}`);
                setIsLoading(false);
            } catch (error) {
                reportMessageToSentry({
                    message: 'Failed to preview encoder input',
                    extras: {
                        error,
                    },
                });
                setIsLoading(false);
                setUrl(null);
            }
        }
        if (encoderId && inputId) {
            fetchData();
        }

        return () => {
            setUrl(null);
            setIsLoading(false);
        };
    }, [encoderId, inputId]);

    return { isLoading, url };
}
