import type { Maybe, NonNullable } from 'types';
import type { CutRange } from 'store/uploads/previews/slice';

export interface TimeMarker {
    left: number;
    value: number;
}

export interface AnimatedElements {
    nextCutRange?: CutRange;
    nextCutRangeTooltips?: CutRange;
    nextTimeMarker?: TimeMarker;
    scrollLeftDelta?: number;
}

export interface CutRangeSelectorMouseMove {
    positionX: number;
    markerLeftTranslateX: number;
    markerRightTranslateX: number;
}

export type CutRangeSelectionEditMode = 'create' | 'move' | 'resize';

export interface CutRangeSelectorMouseDown {
    cutRange?: CutRange;
    minRatioValue: number;
    mode: CutRangeSelectionEditMode;
    positionX: number;
    scrollLeft: number;
    target: HTMLDivElement;
}

export interface CutRangeSelectorState {
    container: Maybe<HTMLDivElement>;
    selector: Maybe<HTMLDivElement>;
    slider: Maybe<HTMLDivElement>;
    mouseDown?: CutRangeSelectorMouseDown;
    mouseMove?: CutRangeSelectorMouseMove;
}

export type CutRangeSelectorStateReady = Pick<CutRangeSelectorState, 'mouseDown' | 'mouseMove'> & {
    container: NonNullable<CutRangeSelectorState['container']>;
    selector: NonNullable<CutRangeSelectorState['selector']>;
    slider: NonNullable<CutRangeSelectorState['slider']>;
};

export type CutRangeSelectorStateOnMouseDown = CutRangeSelectorStateReady & {
    mouseDown: NonNullable<CutRangeSelectorStateReady['mouseDown']>;
};

export type CutRangeSelectorStateOnMouseMove = CutRangeSelectorStateReady & {
    mouseMove: NonNullable<CutRangeSelectorStateReady['mouseMove']>;
};

export type CutRangeSelectorStateOnMouseDownAndMove = CutRangeSelectorStateOnMouseDown & {
    mouseMove: NonNullable<CutRangeSelectorStateReady['mouseMove']>;
};

export type CutRangeSelectorStateOnMouseDownAndMoveWithSelection = CutRangeSelectorStateOnMouseMove & {
    mouseDown: NonNullable<Required<CutRangeSelectorStateReady['mouseDown']>>;
};

export function isCutRangeSelectorStateReady(state: CutRangeSelectorState): state is CutRangeSelectorStateReady {
    return state.container !== null && state.selector !== null && state.slider !== null;
}

export function isCutRangeSelectorStateOnMouseDown(
    state: CutRangeSelectorState
): state is CutRangeSelectorStateOnMouseDown {
    return isCutRangeSelectorStateReady(state) && state.mouseDown !== undefined;
}

export function isCutRangeSelectorStateOnMouseMove(
    state: CutRangeSelectorState
): state is CutRangeSelectorStateOnMouseMove {
    return isCutRangeSelectorStateReady(state) && state.mouseMove !== undefined;
}

export function isCutRangeSelectorStateOnMouseDownAndMove(
    state: CutRangeSelectorState
): state is CutRangeSelectorStateOnMouseDownAndMove {
    return isCutRangeSelectorStateOnMouseDown(state) && state.mouseMove !== undefined;
}

export function isCutRangeSelectorStateOnMouseDownAndMoveWithSelection(
    state: CutRangeSelectorState
): state is CutRangeSelectorStateOnMouseDownAndMoveWithSelection {
    return isCutRangeSelectorStateOnMouseDownAndMove(state) && state.mouseDown.cutRange !== undefined;
}
