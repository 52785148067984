import { Select } from '@schibsted-svp/react-ui';
import { useSelector } from 'react-redux';
import { useWatch, Controller } from 'react-hook-form';
import type { Control, UseFormSetValue } from 'react-hook-form';
import config from 'config';
import { selectNewsrooms } from 'screens/NewsroomScreen/PlaylistsScreen/StaticItemsForm';
import { getNewsroomOptions } from 'store/newsroom/selectors';
import { getValuesFromOptionsArray, findMultipleOptions } from 'components/ui/Form/Select';
import type { FiltersDialogData } from './types';

type NewsroomsSelectProps = {
    control: Control<FiltersDialogData>;
    setValue: UseFormSetValue<FiltersDialogData>;
    setIsCategoryFilterDisabled: (value: boolean) => void;
};

export function NewsroomsSelect({ control, setValue, setIsCategoryFilterDisabled }: NewsroomsSelectProps) {
    const { currentNewsroomOption, allNewsroomsOptions } = useSelector(getNewsroomOptions);

    const selectedNewsrooms = useWatch({ name: 'newsrooms', control });

    const formattedSelectedNewsrooms = selectedNewsrooms?.map((value) => ({
        value,
        label: config.newsrooms[value],
    }));

    const handleOnChange =
        (onChange: (value: string[]) => void) => (inputNewsrooms: { value: string; label: string }[]) => {
            const changedNewsrooms = selectNewsrooms(inputNewsrooms, currentNewsroomOption, formattedSelectedNewsrooms);
            onChange(getValuesFromOptionsArray(changedNewsrooms));

            if (changedNewsrooms?.length === 1 && changedNewsrooms?.[0].value === currentNewsroomOption[0].value) {
                setIsCategoryFilterDisabled(false);
            } else {
                setIsCategoryFilterDisabled(true);
                setValue('categories', undefined);
                setValue('excludedCategory', undefined);
            }
        };

    return (
        <Controller
            name="newsrooms"
            control={control}
            render={({ field }) => (
                <Select
                    {...field}
                    value={findMultipleOptions(allNewsroomsOptions)(field.value)}
                    onChange={handleOnChange(field.onChange)}
                    options={allNewsroomsOptions}
                    splitButton={false}
                    isMulti
                />
            )}
        />
    );
}
