import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, UseFormReturn, type SubmitHandler } from 'react-hook-form';
import { Notification } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { saveAsset } from 'store/assets/actions';
import type { TagsEditingDialogData } from './types';

export const useTagsEditingForm = ({ asset }: { asset: Asset }) => {
    const formValues = { tags: asset.additional.tags };
    const formApi = useForm<TagsEditingDialogData>({
        defaultValues: formValues,
        values: formValues,
        resetOptions: {
            keepDirtyValues: true,
        },
    });
    return { formApi };
};

export const useTagsEditingFormSubmit = ({
    formApi,
    asset,
    onSuccess,
}: {
    formApi: UseFormReturn<TagsEditingDialogData>;
    asset: Asset;
    onSuccess: () => void;
}) => {
    const dispatch = useDispatch();
    const { handleSubmit } = formApi;

    return useMemo(() => {
        const submitHandler: SubmitHandler<TagsEditingDialogData> = async (data) => {
            try {
                const changes = { additional: { tags: data.tags } };
                await new Promise((resolve, reject) => {
                    dispatch(saveAsset({ id: asset.id, provider: asset.provider, changes, resolve, reject }));
                });
                onSuccess();
            } catch (error) {
                Notification.notify.error('Could not save tags');
            }
        };
        return handleSubmit(submitHandler);
    }, [handleSubmit, asset, onSuccess, dispatch]);
};
