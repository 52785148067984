import { get } from 'lodash';

/**
 * @typedef {Object} Series
 * @property {Number} seasonNumber
 * @property {String} title
 */

/**
 * @param {Object} params
 * @param {Number} params.categoryId
 * @param {String} params.provider
 * @return {Promise<Array<Series>>}
 */
export async function fetchSeries({ categoryId, provider }) {
    const result = await this.get(`v1/svp/{PROVIDER}/categories/${categoryId}/seasons`, { provider });
    return get(result, '_embedded.seasons', []);
}

/**
 * @param {Object} params
 * @param {Number} params.categoryId
 * @param {String} params.newsroom
 * @param {String} params.title
 * @param {String} params.seasonNumber
 * @return {Promise}
 */
export async function addSeries({ categoryId, provider, title, seasonNumber }) {
    return this.post(`v1/svp/{PROVIDER}/categories/${categoryId}/seasons`, { provider, json: { title, seasonNumber } });
}

/**
 * @param {Object} params
 * @param {Number} params.categoryId
 * @param {String} params.newsroom
 * @param {String} params.title
 * @param {String} params.seasonNumber
 * @return {Promise}
 */
export async function updateSeries({ categoryId, provider, title, seasonNumber }) {
    return this.patch(`v1/svp/{PROVIDER}/categories/${categoryId}/seasons/${seasonNumber}`, {
        provider,
        json: { title },
    });
}

export async function deleteSeries({ categoryId, provider, seasonNumber }) {
    return this.delete(`v1/svp/{PROVIDER}/categories/${categoryId}/seasons/${seasonNumber}`, { provider });
}

export async function getSeriesAssets({ categoryId, provider, seasonNumber }) {
    const result = await this.get(`v1/svp/{PROVIDER}/categories/${categoryId}/seasons/${seasonNumber}/assets`, {
        provider,
    });
    return get(result, 'assets', []);
}

export async function getSeriesNext({ provider, categoryId, series, seasonNumber }) {
    if (!series?.length) {
        throw new Error('No series available');
    }

    const nextSeasonNumber = seasonNumber || Math.max(...series.map((item) => item.seasonNumber));

    const seriesAssets = await this.getSeriesAssets({
        provider,
        categoryId,
        seasonNumber: nextSeasonNumber,
    });

    if (!seriesAssets) {
        throw new Error('Failed to fetch series next');
    }

    const episodeNumbers = seriesAssets.length ? seriesAssets.map((asset) => asset.series?.episodeNumber) : [0];
    const nextEpisodeNumber = Math.max(...episodeNumbers) + 1;

    return { nextSeasonNumber, nextEpisodeNumber };
}
