import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { withNewsroom } from 'components/NewsroomsList/with-newsroom';
import { getSearchList, getSearchTerm } from 'store/video/navigation-tabs/selectors';

import SearchTabContainer from './SearchTabContainer';

const enhance = compose(
    withNewsroom,
    connect((state) => ({
        searchList: getSearchList(state),
        searchTerm: getSearchTerm(state),
    }))
);

export default enhance(SearchTabContainer);
