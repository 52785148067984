import { PLAYLISTS_DELETE, PLAYLISTS_DELETE_SUCCESS, PLAYLISTS_DELETE_ERROR } from 'store/playlists/items';

import { PLAYLISTS_FETCH_LIST_SUCCESS } from 'store/playlists/shared';
import { STATIC_PLAYLISTS_FETCH_SUCCESS } from 'store/playlists/staticItems';

export const REGULAR_PLAYLISTS_FETCH_LIST = 'Playlists/REGULAR_FETCH_LIST';
export const PLAYLISTS_FETCH_LIST_ERROR = 'Playlists/FETCH_LIST_ERROR';

export const PLAYLIST_OF_PLAYLISTS_FETCH_LIST = 'Playlists/PLAYLIST_OF_PLAYLISTS_FETCH_LIST';

export const regularPlaylistsFetchList = ({ provider, name, labels, more }) => ({
    type: REGULAR_PLAYLISTS_FETCH_LIST,
    payload: {
        provider,
        name,
        labels,
        more,
    },
});

export const playlistOfPlaylistsFetchList = ({ provider, name, labels, more }) => ({
    type: PLAYLIST_OF_PLAYLISTS_FETCH_LIST,
    payload: {
        provider,
        name,
        labels,
        more,
    },
});

export const playlistsFetchListSuccess = ({ provider, playlists, page, hasNext, more }) => ({
    type: PLAYLISTS_FETCH_LIST_SUCCESS,
    payload: {
        provider,
        playlists,
        page,
        hasNext,
        more,
    },
});

export const playlistsFetchListError = () => ({
    type: PLAYLISTS_FETCH_LIST_ERROR,
    error: true,
});

export default function reducer(
    state = { isFetching: false, page: 1, hasNext: true, error: false, items: [] },
    action
) {
    switch (action.type) {
        case REGULAR_PLAYLISTS_FETCH_LIST:
        case PLAYLIST_OF_PLAYLISTS_FETCH_LIST: {
            return { ...state, items: state.items, isFetching: true, error: false };
        }

        case PLAYLISTS_FETCH_LIST_SUCCESS:
        case STATIC_PLAYLISTS_FETCH_SUCCESS: {
            return {
                ...state,
                items: [
                    ...new Set([
                        ...(action.payload.more ? state.items : []),
                        ...action.payload.playlists.map(({ id }) => id),
                    ]),
                ],
                isFetching: false,
                error: false,
                page: action.payload.page,
                hasNext: action.payload.hasNext,
            };
        }

        case PLAYLISTS_FETCH_LIST_ERROR: {
            return { ...state, isFetching: false, error: true };
        }

        case PLAYLISTS_DELETE: {
            return {
                ...state,
                isDeleting: true,
            };
        }

        case PLAYLISTS_DELETE_SUCCESS: {
            return {
                ...state,
                items: state.items.filter((item) => item !== action.payload.id),
                isDeleting: false,
            };
        }

        case PLAYLISTS_DELETE_ERROR: {
            return {
                ...state,
                isDeleting: false,
                error: true,
            };
        }

        default:
            return state;
    }
}
