import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import { Notification } from '@schibsted-svp/react-ui';
import { reportMessageToSentry } from 'lib/error';

export const accessDefinitionsApi = createApi({
    reducerPath: 'accessDefinitionsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${config.svpApi.host}${config.svpApi.path}`,
    }),
    endpoints: (builder) => ({
        getAccessDefinition: builder.query({
            query: ({ provider }) => `v1/${provider}/assets/access-definitions?appName=${config.svpApi.query.appName}`,
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    const errorMessage = 'Unable to fetch access definitions.';
                    Notification.notify.error(errorMessage);
                    reportMessageToSentry({
                        message: errorMessage,
                        extras: {
                            error,
                        },
                    });
                }
            },

            // eslint-disable-next-line no-underscore-dangle
            transformResponse: (response) => response?._embedded?.accessDefinitions || {},
        }),
    }),
});

export const { useGetAccessDefinitionQuery } = accessDefinitionsApi;

/**
 * @param {Object} params
 * @param {String} params.provider
 * @return {Object[]|null}
 */
export const useGetAccessDefinitionOptionsQueryState = ({ provider }) => {
    return accessDefinitionsApi.endpoints.getAccessDefinition.useQueryState(
        { provider },
        {
            selectFromResult: ({ data, isSuccess }) => {
                if (!isSuccess) {
                    return [];
                }
                const values = Object.values(data);

                return values.map(({ key: value, name: label }) => ({ label, value }));
            },
        }
    );
};

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {String} params.accessKey
 * @return {String|null}
 */
export const useGetAccessDefinitionNameQueryState = ({ provider, accessKey }) => {
    return accessDefinitionsApi.endpoints.getAccessDefinition.useQueryState(
        { provider },
        {
            selectFromResult: ({ data, isSuccess }) => {
                if (!isSuccess) {
                    return null;
                }
                return data?.find(({ key }) => key === accessKey)?.name;
            },
        }
    );
};

export const getAccessDefinitionOptionsQueryState = (provider) => (state) => {
    const { data, isSuccess } = accessDefinitionsApi.endpoints.getAccessDefinition.select({ provider })(state);
    if (!isSuccess) {
        return [];
    }
    const values = Object.values(data);

    return values.map(({ key: value, name: label }) => ({ label, value }));
};
