import { LabeledContainer, Checkbox } from '@schibsted-svp/react-ui';
import type { UseFormRegister } from 'react-hook-form';
import type { FormValues } from '../types';
import css from './LivingThumbnail.module.scss';

type LivingThumbnailProps = {
    wasLive: boolean;
    register: UseFormRegister<FormValues>;
};

export function LivingThumbnail({ wasLive, register }: LivingThumbnailProps) {
    return (
        <LabeledContainer label="Living Thumbnail">
            <Checkbox
                {...register('additional.metadata.livingThumbnail')}
                label="Living thumbnail"
                containerClassName={css.checkbox}
                disabled={wasLive}
            />
        </LabeledContainer>
    );
}
