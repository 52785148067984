import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getScheduledLiveDuration } from 'models/asset';
import {
    allEventsHaveError,
    atLeastOneEventIsPending,
    atLeastOneEventIsRunning,
    getStartTime,
    isMultiEventPending,
    isMultiEventRunning,
    isMultiLiveEventIdle,
} from 'models/multiLiveEvent';
import { areLiveEncodersLoading, areLiveEncodersReady, findLiveEncoderEvents } from 'store/live/selectors';
import LiveControlsTemplate from './LiveControlsTemplate';
import ElementalAdControls from './ElementalAdControls';
import LiveStopButton from './LiveRuntimeButtons/LiveStopButton';
import LiveStartButton from './LiveRuntimeButtons/LiveStartButton';
import { LiveScheduleDialog } from './LiveScheduleDialog';
import ElementalSourceButton from './ElementalSourceButton';

function ElementalEncoderControls({ asset, isStarting, isStopping }) {
    const events = useSelector((state) =>
        findLiveEncoderEvents(state, { assetId: asset.id, provider: asset.provider })
    );
    const isEncoderReady = useSelector((state) => areLiveEncodersReady(state));
    const isFetching = useSelector((state) => areLiveEncodersLoading(state, { newsroom: asset.provider }));
    const disabled = (!isEncoderReady && isFetching) || (isFetching && events.length === 0);
    const liveEventStartTime = getStartTime(events);
    const liveEventDuration = getScheduledLiveDuration(asset);

    const isIdle = isMultiLiveEventIdle(events);
    const isScheduled = !isIdle && atLeastOneEventIsPending(events) && Boolean(liveEventStartTime);
    const isRunning = atLeastOneEventIsRunning(events);
    const hasError = allEventsHaveError(events);

    const sourceButton = (
        <ElementalSourceButton loading={disabled} assetId={asset.id} provider={asset.provider} events={events} />
    );

    return (
        <>
            {isRunning && <ElementalAdControls assetId={asset.id} newsroom={asset.provider} />}

            <LiveControlsTemplate
                newsroom={asset.provider}
                assetId={asset.id}
                startTime={asset.flightTimes.start}
                liveDuration={liveEventDuration}
                sourceButton={sourceButton}
                isIdle={isIdle}
                isScheduled={isScheduled}
                isStarting={isStarting}
                isRunning={isRunning}
                isStopping={isStopping}
                hasError={hasError}
            >
                {events.length > 0 && !allEventsHaveError(events) ? (
                    <LiveScheduleDialog
                        newsroom={asset.provider}
                        assetId={asset.id}
                        startTime={asset.flightTimes.start}
                        encoderDuration={liveEventDuration}
                        encoderStartTime={liveEventStartTime}
                        isRunning={isStarting || isRunning || isStopping}
                        disabled={disabled || isStarting || isStopping}
                    >
                        {!isStarting && (isRunning || isStopping) ? (
                            <LiveStopButton
                                assetId={asset.id}
                                provider={asset.provider}
                                disabled={disabled}
                                loading={isStopping || !isMultiEventRunning(events)}
                                dropdownPrimaryOption
                                connected
                            >
                                Stop encoder
                            </LiveStopButton>
                        ) : null}

                        {isIdle || isScheduled || isStarting || isMultiEventPending(events) ? (
                            <LiveStartButton
                                assetId={asset.id}
                                provider={asset.provider}
                                disabled={disabled}
                                loading={isStarting}
                            >
                                Start encoder
                            </LiveStartButton>
                        ) : null}
                    </LiveScheduleDialog>
                ) : null}
            </LiveControlsTemplate>
        </>
    );
}

ElementalEncoderControls.propTypes = {
    asset: PropTypes.object.isRequired,
    isStarting: PropTypes.bool.isRequired,
    isStopping: PropTypes.bool.isRequired,
};

export default ElementalEncoderControls;
