import { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from '@schibsted-svp/react-ui';
import { startLiveStream } from 'store/live/actions';

function LiveStartButton({ children, assetId, provider, disabled, loading, size }) {
    const dispatch = useDispatch();
    const handleStartClick = useCallback(() => {
        dispatch(startLiveStream(provider, assetId));
    }, [assetId, provider, dispatch]);

    return (
        <Button
            type="button"
            loading={loading}
            disabled={disabled}
            size={size}
            variant="standard"
            onClick={handleStartClick}
        >
            {children}
        </Button>
    );
}

LiveStartButton.propTypes = {
    assetId: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    size: PropTypes.oneOf(['big', 'small', 'compact']),
    children: PropTypes.string.isRequired,
};

LiveStartButton.defaultProps = {
    disabled: false,
    loading: false,
    size: undefined,
};

export default LiveStartButton;
