import config from 'config';
import VideoCard from 'components/core/VideoCard';
import { Button } from '@schibsted-svp/react-ui';
import { MdAdd, MdLock } from 'react-icons/md';
import Tooltip from 'components/ui/Tooltip';
import type { UseFieldArrayRemove } from 'react-hook-form';
import type { PlaylistItemAsset } from './types';

import css from './RegularPlaylistItem.module.scss';

interface RegularPlaylistItemProps {
    provider: string;
    index: number;
    field: PlaylistItemAsset;
    openAddStaticVideoModal: (index: number) => void;
    remove: UseFieldArrayRemove;
    isLastItem: boolean;
}

export function RegularPlaylistItem({
    provider,
    index,
    field,
    openAddStaticVideoModal,
    remove,
    isLastItem,
}: RegularPlaylistItemProps) {
    const { asset, isStatic } = field;

    const tooltipContent = (
        <span>
            This item is locked and will <b className={css.bold}>stay</b> in this position on the playlist until it is
            removed.
        </span>
    );

    const tooltip = (
        <Tooltip theme="light" className={css.tooltip} content={tooltipContent} placement="top">
            <span>
                <MdLock size={15} className={css.lock} />
            </span>
        </Tooltip>
    );

    const additionalInfo = (
        <>
            <span className={css.newsroom}>
                &nbsp;-&nbsp;&nbsp;
                {config.newsrooms[asset.provider]}
            </span>
            <span className={css.category}>{asset.category}</span>
            {isStatic && tooltip}
        </>
    );
    return (
        <VideoCard
            id={asset.id}
            provider={asset.provider}
            visibleIcons={['duration', 'date']}
            variant="simple"
            readOnly
            asLink={false}
            className={css.videoCard}
            noFollow={asset.provider !== provider}
            additionalInfo={additionalInfo}
        >
            <Button
                size="small"
                className={css.addButtonTop}
                iconOnly
                type="button"
                onClick={() => {
                    openAddStaticVideoModal(index);
                }}
            >
                <MdAdd size={25} />
            </Button>
            {isLastItem && (
                <Button
                    size="small"
                    className={css.addButtonBottom}
                    iconOnly
                    type="button"
                    onClick={() => {
                        openAddStaticVideoModal(index + 1);
                    }}
                >
                    <MdAdd size={25} />
                </Button>
            )}
            {isStatic && (
                <Button
                    type="button"
                    variant="standard"
                    onClick={() => {
                        remove(index);
                    }}
                    className={css.removeButton}
                >
                    Remove
                </Button>
            )}
        </VideoCard>
    );
}
