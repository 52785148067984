import { useCallback } from 'react';
import type { EditMethod } from '../types';
import { getSelectorEdgeSize } from '../helpers';
import type { TimelineState } from '../TimelineContext';
import { useTimelineState } from './useTimelineState';

function getDragMethod({
    pixelsPerSecond,
    selection,
    time,
}: Required<Pick<TimelineState, 'pixelsPerSecond' | 'selection'>> & { time: number }): EditMethod | undefined {
    const edgeSize = getSelectorEdgeSize(pixelsPerSecond);

    if (time >= selection.startTime && time <= selection.startTime + edgeSize) {
        return 'resizeBegin' as const;
    }

    if (time >= selection.endTime - edgeSize && time <= selection.endTime) {
        return 'resizeEnd' as const;
    }

    if (time >= selection.startTime && time <= selection.endTime) {
        return 'move' as const;
    }

    return undefined;
}

export function useDragHandlers() {
    const { getState, setState } = useTimelineState();

    const startDragging = useCallback(() => {
        const { offset, scroll, selection, pixelsPerSecond } = getState();
        if (!offset || !selection) {
            return;
        }

        const time = (offset + scroll) / pixelsPerSecond;
        const method = getDragMethod({
            pixelsPerSecond,
            selection,
            time,
        });

        setState({ method });
    }, [getState, setState]);

    const finishDragging = useCallback(() => {
        setState({ method: undefined });
    }, [setState]);

    return { startDragging, finishDragging };
}
