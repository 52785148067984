import React from 'react';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withUserPermissions(WrappedComponent) {
    function WithUserPermissions(props) {
        const userPermissions = useUserPermissions();
        return (
            <WrappedComponent
                {...props}
                isUserProvider={userPermissions?.isUser}
                isAssociateProvider={userPermissions?.isAssociate}
            />
        );
    }

    WithUserPermissions.displayName = `withUserPermissions(${getDisplayName(WrappedComponent)})`;
    return WithUserPermissions;
}
