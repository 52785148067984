import {
    GetAssetSchedules,
    CreateAssetSchedule,
    UpdateAssetSchedule,
    DeleteAssetSchedule,
    GetNotificationSchedules,
    CreateNotificationSchedule,
    UpdateNotificationSchedule,
    DeleteNotificationSchedule,
} from './graphql/scheduler.graphql';

/**
 * @typedef {"PUBLISH" | "UNPUBLISH"} AssetScheduleActions
 */

/**
 * @typedef {"PUSH"} NotificationScheduleActions
 */

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {AssetScheduleActions | undefined} [params.action]
 * @returns {Promise<{provider: String, assetId: Number, time: Number, action: String}[]>}
 */
export async function fetchAssetSchedules({ provider, assetId, action }) {
    const response = await this.query(GetAssetSchedules, {
        provider,
        assetId,
        action,
    });
    return response?.assetSchedules || [];
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {Number} params.time
 * @param {AssetScheduleActions} params.action
 * @returns {Promise<{provider: String, assetId: Number, time: Number, action: String}>}
 */
export async function createAssetSchedule({ provider, assetId, time, action }) {
    const response = await this.query(CreateAssetSchedule, {
        provider,
        assetId,
        time,
        action,
    });
    return response?.createAssetSchedule;
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {Number} params.time
 * @param {AssetScheduleActions} params.action
 * @returns {Promise<{provider: String, assetId: Number, time: Number, action: String}>}
 */
export async function updateAssetSchedule({ provider, assetId, time, action }) {
    const response = await this.query(UpdateAssetSchedule, {
        provider,
        assetId,
        time,
        action,
    });
    return response?.updateAssetSchedule;
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {AssetScheduleActions} params.action
 * @returns {Promise<Boolean>}
 */
export async function deleteAssetSchedule({ provider, assetId, action }) {
    const response = await this.query(DeleteAssetSchedule, {
        provider,
        assetId,
        action,
    });
    return response?.deleteAssetSchedule;
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {AssetScheduleActions | undefined} [params.action]
 * @returns {Promise<{provider: String, assetId: Number, time: Number, action: String}[]>}
 */
export async function fetchNotificationSchedules({ provider, assetId, action }) {
    const response = await this.query(GetNotificationSchedules, {
        provider,
        assetId,
        action,
    });
    return response?.notificationSchedules || [];
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {AssetScheduleActions} params.action
 * @param {Number} params.time
 * @param {String} params.notificationBody
 * @returns {Promise<{provider: String, assetId: Number, time: Number, action: String}>}
 */
export async function createNotificationSchedule({ provider, assetId, action, time, notificationBody }) {
    const response = await this.query(CreateNotificationSchedule, {
        provider,
        assetId,
        action,
        time,
        notificationBody,
    });
    return response?.createNotificationSchedule;
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {AssetScheduleActions} params.action
 * @param {Number} params.time
 * @param {String} params.notificationBody
 * @returns {Promise<{provider: String, assetId: Number, time: Number, action: String}>}
 */
export async function updateNotificationSchedule({ provider, assetId, action, time, notificationBody }) {
    const response = await this.query(UpdateNotificationSchedule, {
        provider,
        assetId,
        action,
        time,
        notificationBody,
    });
    return response?.updateNotificationSchedule;
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {AssetScheduleActions} params.action
 * @returns {Promise<Boolean>}
 */
export async function deleteNotificationSchedule({ provider, assetId, action }) {
    const response = await this.query(DeleteNotificationSchedule, {
        provider,
        assetId,
        action,
    });
    return response?.deleteNotificationSchedule;
}
