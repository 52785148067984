import * as Actions from './actionTypes';

/**
 * @deprecated Prefer to use `useGetSeriesQuery` hook from adminBffSdk
 */
export const fetchStories = ({ ids, provider }) => ({ type: Actions.STORIES_FETCH, ids, provider });

/**
 * @deprecated Prefer to use `useGetSeriesQuery` hook from adminBffSdk
 */
export const fetchStoriesSuccess = ({ ids, stories, provider }) => ({
    type: Actions.STORIES_FETCH_SUCCESS,
    ids,
    provider,
    stories,
});

/**
 * @deprecated Prefer to use `useGetSeriesQuery` hook from adminBffSdk
 */
export const fetchStoriesError = ({ ids, provider, error }) => ({
    type: Actions.STORIES_FETCH_ERROR,
    ids,
    provider,
    error,
});
