import { merge } from 'lib/object';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

import { SCHEDULES_SCOPE, SCHEDULES_ACTION } from './constants';
import {
    SCHEDULES_FETCH,
    SCHEDULES_FETCH_SUCCESS,
    SCHEDULES_FETCH_ERROR,
    SCHEDULE_CREATE_SUCCESS,
    SCHEDULE_UPDATE_SUCCESS,
    SCHEDULE_DELETE_SUCCESS,
} from './actions';

const mapScheduleItem =
    (scope) =>
    ({ provider, assetId, action, time, notificationBody, notificationData }) => {
        switch (scope) {
            case SCHEDULES_SCOPE.NOTIFICATION:
                return { provider, assetId, time, notificationBody, notificationData };
            case SCHEDULES_SCOPE.ASSET: {
                const timeField = action === SCHEDULES_ACTION.PUBLISH ? 'publishTime' : 'unpublishTime';
                return { provider, assetId, [timeField]: time };
            }
            default:
                return { provider, assetId, time };
        }
    };

const defaultState = {
    isFetching: false,
    error: false,
    items: {},
};

function schedulesReducer(state = {}, { type, payload }) {
    const { provider, scope } = payload || {};
    const scopeKey = String(scope).toLowerCase();

    switch (type) {
        case SCHEDULES_FETCH: {
            return merge(state, {
                [scopeKey]: {
                    [provider]: { ...defaultState, isFetching: true, error: false },
                },
            });
        }

        case SCHEDULES_FETCH_SUCCESS: {
            return merge(state, {
                [scopeKey]: {
                    [provider]: {
                        items: keyBy(payload.schedules.map(mapScheduleItem(scope)), 'assetId'),
                        isFetching: false,
                        error: false,
                    },
                },
            });
        }

        case SCHEDULES_FETCH_ERROR: {
            return merge(state, {
                [scopeKey]: {
                    [provider]: {
                        isFetching: false,
                        error: true,
                    },
                },
            });
        }

        case SCHEDULE_CREATE_SUCCESS:
        case SCHEDULE_UPDATE_SUCCESS: {
            const { assetId, schedule } = payload;
            return merge(state, {
                [scopeKey]: {
                    [provider]: {
                        items: {
                            ...(state[scopeKey]?.[provider]?.items || {}),
                            [assetId]: mapScheduleItem(scope)(schedule),
                        },
                    },
                },
            });
        }

        case SCHEDULE_DELETE_SUCCESS: {
            return {
                ...state,
                [scopeKey]: {
                    [provider]: {
                        ...(state[scopeKey]?.[provider] || defaultState),
                        items: omit(state[scopeKey]?.[provider]?.items || {}, [payload.assetId]),
                    },
                },
            };
        }
        default:
            return state;
    }
}

export default schedulesReducer;
