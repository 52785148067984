import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { getNextList, getRelatedList, getSearchList } from 'store/video/next-video/selectors';
import { getAsset } from 'store/assets/selectors';
import { createAssetFormName } from 'store/assets/helpers';
import { getPlaylistId } from 'models/asset';
import { relatedFetch as fetchRelated } from 'store/ui/assetForm/actions';
import { playlistsFetch } from 'store/playlists/items';
import { unassignPlaylist } from 'store/assets/actions';
import { changeNextVideos, generateNextVideos, loadMoreNextVideos } from 'store/video/next-video/actions';

import NextVideo from './NextVideo';

const enhance = compose(
    connect(
        (state, { id, provider, disableNextVideo }) => {
            const formName = createAssetFormName(id);
            const asset = getAsset(state, { id, provider });
            const { assetType } = asset;
            return {
                next: getNextList(state, { id, provider }),
                related: getRelatedList(state),
                search: getSearchList(state, provider, id),
                disableNextVideo:
                    disableNextVideo ?? get(state.form[formName], 'values.additional.metadata.disableNextVideo', false),
                playlistId: getPlaylistId(asset),
                assetType,
            };
        },
        {
            changeNextVideos,
            fetchRelated,
            generateNextVideos,
            loadMoreNextVideos,
            playlistsFetch,
            unassignPlaylist,
        }
    )
);

export default enhance(NextVideo);
