export { LoginForm } from './LoginForm';
export { ProviderSelector } from './ProviderSelector';
export { Loader } from './Loader';
export { EntryPage } from './EntryPage';

export const authErrorMessage = (
    <div>
        Authentication error
        <br />
        The application needs to be reloaded.
        <br />
        Please copy all unsaved form data to some external place
        <br />
        than close this message to apply reload.
    </div>
);
