import * as PropTypes from 'prop-types';
import { Button, Connector, LabeledContainer } from '@schibsted-svp/react-ui';
import { relative } from 'lib/date';
import { getFormattedTimeWithDays } from 'lib/time';
import css from './LiveControlsTemplate.module.scss';
import LiveDuration from './LiveDuration';

function getLabel({ isScheduled, isStarting, isRunning, isStopping, hasError }) {
    if (isScheduled) {
        return 'Countdown to live';
    }
    if (isStarting || isRunning || isStopping) {
        return 'Ongoing live video';
    }
    if (hasError) {
        return 'Error occurred while starting live video';
    }
    return 'Start live video';
}

function getStartTimeLabel({ isScheduled, isStarting, isRunning, isStopping }) {
    if (isScheduled) {
        return 'Starts in';
    }
    if (isStarting || isRunning || isStopping) {
        return 'Started at';
    }
    return 'Starts at';
}

function LiveControlsTemplate({
    assetId,
    newsroom,
    children,
    startTime,
    liveDuration,
    sourceButton,
    isIdle,
    isScheduled,
    isStarting,
    isRunning,
    isStopping,
    hasError,
}) {
    const stateFlags = { isIdle, isScheduled, isStarting, isRunning, isStopping, hasError };
    const endTime = liveDuration ? startTime + liveDuration : undefined;
    return (
        <div className={css.container}>
            <h1>{getLabel(stateFlags)}</h1>

            <div className={css.controls}>
                {sourceButton ? <LabeledContainer label="Source">{sourceButton}</LabeledContainer> : null}

                <Connector gap={0} className={css.runtimeControls}>
                    {isScheduled || isStarting || isRunning || isStopping ? (
                        <LabeledContainer label={getStartTimeLabel(stateFlags)}>
                            <Button type="button" variant="standard" disabled>
                                <LiveDuration
                                    assetId={assetId}
                                    newsroom={newsroom}
                                    startTime={startTime}
                                    endTime={endTime}
                                    isIdle={isIdle}
                                    isScheduled={isScheduled}
                                    isRunning={isRunning}
                                    isStarting={isStarting}
                                    isStopping={isStopping}
                                />
                            </Button>
                        </LabeledContainer>
                    ) : null}

                    {endTime && (isStarting || isRunning || isStopping) ? (
                        <LabeledContainer label="Ends at" className={css.endTimeContainer}>
                            <Button type="button" variant="standard" disabled>
                                {relative(endTime * 1000)}
                            </Button>
                        </LabeledContainer>
                    ) : null}
                    {liveDuration && (isIdle || isScheduled) && !isStarting && !isRunning ? (
                        <LabeledContainer label="Ends after" className={css.endTimeContainer}>
                            <Button type="button" variant="standard" disabled>
                                {getFormattedTimeWithDays(liveDuration * 1000)}
                            </Button>
                        </LabeledContainer>
                    ) : null}

                    {children && <LabeledContainer>{children}</LabeledContainer>}
                </Connector>
            </div>
        </div>
    );
}

LiveControlsTemplate.propTypes = {
    assetId: PropTypes.number.isRequired,
    newsroom: PropTypes.string.isRequired,
    children: PropTypes.node,
    startTime: PropTypes.number.isRequired,
    liveDuration: PropTypes.number,
    sourceButton: PropTypes.node.isRequired,
    isIdle: PropTypes.bool,
    isScheduled: PropTypes.bool,
    isStarting: PropTypes.bool,
    isRunning: PropTypes.bool,
    isStopping: PropTypes.bool,
    hasError: PropTypes.bool,
};

LiveControlsTemplate.defaultProps = {
    children: undefined,
    liveDuration: undefined,
    isIdle: false,
    isScheduled: false,
    isStarting: false,
    isRunning: false,
    isStopping: false,
    hasError: false,
};

export default LiveControlsTemplate;
