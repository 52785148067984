import { ReactNode, useState } from 'react';
import {
    useFieldArray,
    type Control,
    type FieldValues,
    type UseFieldArrayReturn,
    type FieldArray,
    type FieldArrayPath,
    type FieldArrayWithId,
} from 'react-hook-form';
import { Label, Button, Spinner, CollapsibleContent } from '@schibsted-svp/react-ui';
import { MdAdd } from 'react-icons/md';
import css from './FieldAdder.module.scss';

const MODE = { APPEND: 'append', PREPEND: 'prepend' } as const;

type Mode = (typeof MODE)[keyof typeof MODE];
type Field = Record<string, string>;

type AddButtonProps = {
    onClick: () => void;
    disabled?: boolean;
    testId?: string;
};

function AddButton({ onClick, disabled, testId }: AddButtonProps) {
    return (
        <Button
            size="small"
            className={css.addButton}
            disabled={disabled}
            iconOnly
            type="button"
            onClick={onClick}
            data-testid={testId}
        >
            <MdAdd size={30} />
        </Button>
    );
}

type HeaderProps = {
    label: string | ReactNode;
    isLoading: boolean;
    addButton?: ReactNode;
};

function Header({ label, isLoading, addButton }: HeaderProps) {
    return (
        <div className={css.header}>
            <Label>{label}</Label>
            {isLoading ? <Spinner containerClassName={css.spinner} size="compact" /> : addButton}
        </div>
    );
}

type FieldAdderProps<TFieldValues extends FieldValues, TFieldArrayName extends FieldArrayPath<TFieldValues>> = {
    name: TFieldArrayName;
    control: Control<TFieldValues>;
    label: string | ReactNode;
    mode: Mode;
    children: (fields: UseFieldArrayReturn<TFieldValues, TFieldArrayName>) => ReactNode;
    collapsible?: boolean;
    onNewField?: (
        fields: FieldArrayWithId<TFieldValues, TFieldArrayName, 'id'>[]
    ) => FieldArray<TFieldValues, TFieldArrayName>;
    isLoading?: boolean;
    disabled?: boolean | ((fields: Field[]) => boolean);
    addButtonTestId?: string;
};

export function FieldAdder<TFieldValues extends FieldValues, TFieldArrayName extends FieldArrayPath<TFieldValues>>({
    name,
    label,
    isLoading,
    children,
    mode,
    onNewField,
    disabled,
    addButtonTestId,
    control,
    collapsible = false,
}: FieldAdderProps<TFieldValues, TFieldArrayName>) {
    const [expanded, setExpanded] = useState(false);

    const methods = useFieldArray({ name, control });
    const { append, prepend, fields } = methods;

    const defaultField = { key: '', value: '' } as FieldArray<TFieldValues, TFieldArrayName>;
    const disableAddButton = typeof disabled === 'function' ? disabled(fields) : disabled;
    const hasFields = Boolean(fields.length);

    const appendField = () => {
        append(onNewField ? onNewField(fields) : defaultField, { shouldFocus: false });
    };

    const prependField = () => {
        prepend(onNewField ? onNewField(fields) : defaultField, { shouldFocus: false });
        if (collapsible && !expanded) {
            setExpanded(true);
        }
    };

    const toggleExpanded = () => {
        setExpanded((currentExpanded) => !currentExpanded);
    };

    const addButtonTop = (mode === MODE.PREPEND || !hasFields) && (
        <AddButton disabled={disableAddButton} onClick={prependField} testId={addButtonTestId} />
    );
    const addButtonBottom = mode === MODE.APPEND && hasFields && (
        <AddButton disabled={disableAddButton} onClick={appendField} testId={addButtonTestId} />
    );

    if (collapsible && fields.length) {
        return (
            <CollapsibleContent
                customHeader={<Header label={label} isLoading={isLoading} />}
                expanded={expanded}
                onToggle={toggleExpanded}
            >
                <div className={css.content}>
                    {addButtonTop}
                    {children(methods)}
                    {addButtonBottom}
                </div>
            </CollapsibleContent>
        );
    }

    return (
        <div>
            <Header label={label} isLoading={isLoading} addButton={addButtonTop} />
            {hasFields && (
                <div className={css.content}>
                    {children(methods)}
                    {addButtonBottom}
                </div>
            )}
        </div>
    );
}
