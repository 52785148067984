import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

const defaultFactory = (suggestion) => suggestion;

/* eslint-disable react/display-name */
const Suggestion = (suggestionLabelFactory = defaultFactory) =>
    // eslint-disable-next-line func-names
    function (suggestion, { query }) {
        const suggestionText = suggestionLabelFactory(suggestion);
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts = AutosuggestHighlightParse(suggestionText, matches);

        return (
            <span className="af_suggestion-row">
                {parts.map((part) => (
                    <span
                        className={(part.highlight && 'af_suggestion-row__highlight') || undefined}
                        key={`${part.text}`}
                    >
                        {part.text}
                    </span>
                ))}
            </span>
        );
    };

export default Suggestion;
