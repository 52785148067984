import config from 'config';
import type { CategoryPodmeMetadata } from 'services/admin-bff-sdk/generated';

export const DYNAMIC_FIELD_METADATA_PREFIX = 'custom_field';

type IgnoredMetadata = (typeof config.categories.ignoredMetadata)[number];

export function isIgnoredMetadata(key: string): key is IgnoredMetadata {
    return config.categories.ignoredMetadata.includes(key as IgnoredMetadata);
}

export function isDynamicFieldMetadata(key: string): boolean {
    return key.startsWith(DYNAMIC_FIELD_METADATA_PREFIX);
}

export function nullifyEmptyString(value: string | null) {
    return typeof value === 'string' && value.trim() === '' ? null : value;
}

export function trimValue(value: string | null) {
    return typeof value === 'string' ? value.replace(/\s/g, '') : value;
}

export function isPodmeMetadataPolling(metadata?: CategoryPodmeMetadata) {
    if (!metadata) {
        return false;
    }
    const { sendToPodme, podmeId } = metadata;
    return Boolean((sendToPodme && !podmeId) || (!sendToPodme && podmeId));
}
