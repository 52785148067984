import * as PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

import BEM from 'lib/bem';

const bem = new BEM('video-navigation');

function CloseIcon({ onClose }) {
    return <MdClose onClick={onClose} className={bem.element('tab-close')} />;
}

CloseIcon.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default CloseIcon;
