import { useSelector } from 'react-redux';
import { isLiveRuntimeUnpublishing, isLiveRuntimeStopping } from 'store/live/selectors';
import { getCategories } from 'store/categories/selectors';
import { useUrlChecker } from 'components/core/Player/UrlCheckerContext';

export function useSubmitState({
    provider,
    assetId,
    isSubmitting,
    streamType,
}: {
    provider: string;
    assetId: number;
    isSubmitting: boolean;
    streamType: string;
}): {
    isDisabled: boolean;
    isProcessing: boolean;
    isWarning: boolean;
} {
    const isLiveUnpublishing = useSelector((state) => isLiveRuntimeUnpublishing(state, { assetId, provider }));
    const isLiveStopping = useSelector((state) => isLiveRuntimeStopping(state, { assetId, provider }));

    const categories = useSelector((state) => getCategories(state, { provider }));
    const areCategoriesLoaded = !categories?.isFetching || Object.values(categories?.items).length > 0;

    const isDisabled = !areCategoriesLoaded || (isLiveStopping && streamType === 'live');
    const isProcessing = isSubmitting || isLiveUnpublishing;

    const { available, shouldDisplay } = useUrlChecker();
    const isWarning = !available && shouldDisplay;

    return { isDisabled, isProcessing, isWarning };
}
