import { useCallback } from 'react';
import { useTimelineState } from './useTimelineState';

export function useContainerHandlers() {
    const { getState, setState } = useTimelineState();

    const changeViewport = useCallback(
        (scroll: number, width: number) => {
            const { duration, pixelsPerSecond, viewport, totalDuration } = getState();

            if (
                !viewport ||
                scroll < viewport.startTime * pixelsPerSecond ||
                scroll + width > viewport.endTime * pixelsPerSecond
            ) {
                const startTime = Math.round((scroll - width) / pixelsPerSecond);
                const endTime = Math.round((scroll + width * 2) / pixelsPerSecond);
                const nextViewport = {
                    startTime: Math.max(startTime, 0),
                    endTime: Math.min(endTime, totalDuration || duration),
                };

                setState({ viewport: nextViewport });
            }
        },
        [getState, setState]
    );

    const changeScroll = useCallback((scroll: number) => setState({ scroll }), [setState]);

    const changeOffset = useCallback(
        (offset: number | undefined) => {
            if (offset !== getState().offset) {
                setState({ offset });
            }
        },
        [getState, setState]
    );

    return { changeViewport, changeScroll, changeOffset };
}
