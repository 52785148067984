import { Component } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject, isEmpty } from 'lodash';
import moment from 'moment';
import { isCurrentYear } from 'lib/date';
import { Button, Option } from '@schibsted-svp/react-ui';
import config from 'config';
import { MdClose } from 'react-icons/md';
import { getFormattedTime } from 'lib/time';
import { isCustomField, getCustomFieldLabel } from 'components/core/FiltersDialog/DynamicFilters/helpers';
import classnames from 'classnames/bind';

import css from './AppliedFilters.module.scss';

const cln = classnames.bind(css);

const getValue = (filter) => (isPlainObject(filter) ? filter.value : filter);

const formatLabel = (filterType, label, categoryCustomDateFields, categoryCustomBooleanFields) => {
    if (isCustomField(categoryCustomDateFields, filterType)) {
        const date = moment.unix(label);
        return `${getCustomFieldLabel(categoryCustomDateFields, filterType)} ${date.format(
            `Do MMM ${isCurrentYear(date) ? '' : 'YYYY'}`
        )}`;
    }
    if (isCustomField(categoryCustomBooleanFields, filterType)) {
        return getCustomFieldLabel(categoryCustomBooleanFields, filterType);
    }
    switch (filterType) {
        case 'date': {
            const date = moment.unix(label);
            return `${filterType} ${date.format(`Do MMM ${isCurrentYear(date) ? '' : 'YYYY'}`)}`;
        }
        case 'moods':
            return config.asset.mood.values[label].label || label;
        case 'isPodcast':
            return 'Podcast';
        case 'isGeoblocked':
            return 'Geoblocked';
        case 'min':
            return `duration >= ${getFormattedTime(label * 1000)}`;
        case 'max':
            return `duration <= ${getFormattedTime(label * 1000)}`;
        case 'showHidden':
            return 'Show hidden assets';
        default:
            return label;
    }
};

class AppliedFilters extends Component {
    static propTypes = {
        onFiltersClear: PropTypes.func.isRequired,
        query: PropTypes.string,
        filters: PropTypes.object,
        onQueryDeletion: PropTypes.func.isRequired,
        onFilterDeletion: PropTypes.func.isRequired,
        categoryCustomDateFields: PropTypes.arrayOf(PropTypes.string),
        categoryCustomBooleanFields: PropTypes.arrayOf(PropTypes.boolean),
    };

    static defaultProps = {
        query: undefined,
        filters: {},
        categoryCustomDateFields: [],
        categoryCustomBooleanFields: [],
    };

    handleFilterRemove = (type, filter) => () => {
        const { onFilterDeletion } = this.props;
        onFilterDeletion({ filter: type, value: getValue(filter) });
    };

    render() {
        const {
            onFiltersClear,
            query,
            filters,
            onQueryDeletion,
            categoryCustomDateFields,
            categoryCustomBooleanFields,
        } = this.props;

        if (isEmpty(filters) && !query) {
            return null;
        }

        const filtersValues = Object.entries(filters)
            .filter(([, filterValues]) => filterValues !== false)
            .map(([filterType, filterValues]) => [filterType, [].concat(filterValues)]);

        const strikeFilters = ['excludedCategory', 'excludedTags'];

        return (
            <div>
                <section className={css.container}>
                    <Button type="button" variant="standard" onClick={onFiltersClear}>
                        Clear
                    </Button>
                    {query && (
                        <div className={css.searchLabel}>
                            <div>Showing results for:</div>
                            <Option className={css.query} onClick={onQueryDeletion}>
                                <Option.Icon>
                                    <MdClose size={20} />
                                </Option.Icon>
                                <Option.Label>{query}</Option.Label>
                            </Option>
                        </div>
                    )}
                    {!isEmpty(filtersValues) && (
                        <div className={css.filtersContainer}>
                            <div>Filtered by:</div>
                            <div className={css.filters}>
                                {filtersValues.map(([filterType, filterValues]) =>
                                    filterValues.map((filter) => (
                                        <Option
                                            key={getValue(filter)}
                                            className={cln({
                                                strikethroughText: strikeFilters.includes(filterType),
                                            })}
                                            variant="option"
                                            onClick={this.handleFilterRemove(filterType, filter)}
                                        >
                                            <Option.Icon>
                                                <MdClose size={20} />
                                            </Option.Icon>
                                            <Option.Label>
                                                {formatLabel(
                                                    filterType,
                                                    isPlainObject(filter) ? filter.label : filter,
                                                    categoryCustomDateFields,
                                                    categoryCustomBooleanFields
                                                )}
                                            </Option.Label>
                                        </Option>
                                    ))
                                )}
                            </div>
                        </div>
                    )}
                </section>
            </div>
        );
    }
}

export default AppliedFilters;
