import { useState, ReactNode } from 'react';
import { LabeledContainer } from '@schibsted-svp/react-ui';
import { InlineCollapsible } from './InlineCollapsible';

import css from './HidableLabeledContainer.module.scss';

interface HidableLabeledContainerProps {
    children: ReactNode;
    label: string;
    fieldName: string;
    value: string;
}

function getOpenFields() {
    try {
        return JSON.parse(localStorage.getItem('openFields')) || {};
    } catch (error) {
        return {};
    }
}

function setOpenFields(fieldName: string, value: boolean) {
    const openFields = getOpenFields();

    openFields[fieldName] = value;
    localStorage.setItem('openFields', JSON.stringify(openFields));
}

export function HidableLabeledContainer({ children, label, fieldName, value }: HidableLabeledContainerProps) {
    const [expanded, setExpanded] = useState(Boolean(value) || getOpenFields()?.[fieldName]);

    const hideContainer = () => {
        setExpanded(false);
        setOpenFields(fieldName, false);
    };

    const openContainer = () => {
        setExpanded(true);
        setOpenFields(fieldName, true);
    };

    return (
        <InlineCollapsible label={`+add ${label}`} variant="cover" onToggle={openContainer} expanded={expanded}>
            <LabeledContainer strict label={label} className={css.container}>
                <p onClick={hideContainer} className={css.hideContainer}>{`- hide ${label}`}</p>
                <span className={css.children}>{children}</span>
            </LabeledContainer>
        </InlineCollapsible>
    );
}
