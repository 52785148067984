export const getSchedules = (state) => state.schedules;

export const getSchedulesForScope = (state, { scope }) => getSchedules(state)[String(scope).toLowerCase()];

export const getSchedulesForProvider = (state, { provider, scope }) =>
    getSchedulesForScope(state, { scope })?.[provider];

export const getScheduleForAsset = (state, { provider, scope, id }) =>
    getSchedulesForProvider(state, { provider, scope })?.items?.[id];

export const getIsFetchingSchedulesForProvider = (state, { provider, scope }) =>
    getSchedulesForProvider(state, { provider, scope })?.isFetching;
