import classnames from 'classnames/bind';
import { memo, useMemo } from 'react';
import { formatRemainingTime } from 'lib/time';
import { useSubscribeTimelineState } from './hooks/useTimelineState';
import css from './TimelineRuler.module.scss';

const cln = classnames.bind(css);

type TimelineRulerProps = {
    className?: string;
};

export const TimelineRuler = memo<TimelineRulerProps>(function TimelineRuler({ className }) {
    const pixelsPerSecond = useSubscribeTimelineState((state) => state.pixelsPerSecond);
    const viewport = useSubscribeTimelineState((state) => state.viewport);

    const seconds = useMemo(() => {
        if (!viewport) {
            return undefined;
        }

        const length = viewport.endTime - viewport.startTime + 1;
        return Array.from({ length }, (_, index) => viewport.startTime + index);
    }, [viewport]);

    if (!seconds) {
        return null;
    }

    return (
        <div className={cln(css.ruler, className)} style={{ backgroundSize: `${pixelsPerSecond}px 12px` }}>
            {seconds.map((second) => (
                <span
                    key={`timeline:ruler:${second}`}
                    className={css.unit}
                    style={{
                        transform: `translateX(calc(${second * pixelsPerSecond}px - ${second === 0 ? '8px' : '50%'}))`,
                    }}
                >
                    {formatRemainingTime(second)}
                </span>
            ))}
        </div>
    );
});
