import BEM from 'lib/bem';

const bem = new BEM('video-navigation');

function VideoIcon() {
    return (
        <svg className={bem.element('tab-icon')} xmlns="http://www.w3.org/2000/svg" width="25" height="24">
            <g fill="none">
                <rect width="22" height="22" x="1" y="1" strokeWidth="2" rx="2" />
                <path d="M10 7l6 3-6 3zM1 17h22v2H1z" />
            </g>
        </svg>
    );
}

export default VideoIcon;
