import classnames from 'classnames/bind';
import { Button } from '@schibsted-svp/react-ui';
import css from './AdvancedToggleButton.module.scss';

const cln = classnames.bind(css);

interface AdvancedToggleButtonProps {
    onClick: () => void;
    isExpanded: boolean;
}

export function AdvancedToggleButton({ onClick, isExpanded }: AdvancedToggleButtonProps) {
    return (
        <Button
            type="button"
            onClick={onClick}
            size="small"
            variant="standard"
            className={cln('advancedToggleButton', {
                'advancedToggleButton--expanded': isExpanded,
            })}
        >
            {`${isExpanded ? 'Close' : 'Open'} advanced settings`}
        </Button>
    );
}
