import { getSeasonsArray, getSeasonsMap, getErrorFlag, getFetchingFlag } from 'store/series/selectors';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';
import { getSeriesFlag } from 'store/categories/selectors';
import { object, string, array } from 'zod';
import type { Seasons } from './types';

const seasonsSchema = object({
    seasons: array(
        object({
            title: string().trim().min(3, { message: 'Season name should be at least 3 characters long' }),
        }).passthrough()
    ),
});

export const useFetchSeasonsData = ({ categoryId, provider }: { categoryId: number; provider: string }) => {
    const seasons = useSelector((state) => getSeasonsArray(state, { provider, categoryId }));
    const seasonsByNumbers = useSelector((state) => getSeasonsMap(state, { provider, categoryId }));
    const isFetchingSeasons = useSelector((state) => getFetchingFlag(state, { provider, categoryId }));
    const isFetchingError = useSelector((state) => getErrorFlag(state, { provider, categoryId }));
    const isSeries = useSelector((state) => getSeriesFlag(state, { provider, id: categoryId }));

    return { seasons, isSeries, seasonsByNumbers, isFetchingSeasons, isFetchingError };
};

export const useSeriesForm = ({ defaultValues }: { defaultValues: Seasons }) => {
    const formApi = useForm<Seasons>({ mode: 'onChange', defaultValues, resolver: zodResolver(seasonsSchema) });

    return { formApi };
};
