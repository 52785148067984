import { createContext } from 'react';

const FormPlayerContext = createContext({
    player: {
        isReady: false,
        getCurrentTime: () => -1,
        getDuration: () => -1,
        seek: () => null,
        videoElement: undefined,
        currentTime: 0,
        duration: 0,
    },
    setPlayer: () => {},
});

export default FormPlayerContext;
