import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const getSubtitlesUploads = (state: RootState, { newsroom, assetId }: { newsroom: string; assetId: number }) => {
    return state.uploads?.subtitles?.[newsroom]?.[assetId];
};

export const getPendingSubtitlesUploads = createSelector(getSubtitlesUploads, (subtitles) => {
    return Object.entries(subtitles || {}).map(([language, data]) => ({ language, ...(data as object) }));
});
