import { apply, call, delay, put, race, select, take, takeEvery } from '@redux-saga/core/effects';
import { Notification } from '@schibsted-svp/react-ui';
import { reportMessageToSentry } from 'lib/error';
import { generateS3BucketPath, getS3PublicUri } from 'lib/s3';
import { getMetadata } from 'models/asset';
import FileUploader from 'services/file-uploader';
import {
    api as adminBffSdk,
    type CreateAssetPreviewMutationVariables,
    type UploadAssetPreviewMutationVariables,
    type UploadCategoryPreviewMutationVariables,
} from 'services/admin-bff-sdk/generated';
import { getAsset } from 'store/assets/selectors';
import { authEmailSelector } from 'store/auth/selectors';
import { UnknownAction } from '@redux-saga/core';
import { findAssetChangeAction, getAssetArtifacts, getCategoryArtifacts, getMetadataKey } from './sagas-utils';
import {
    type AssetPayload,
    type PayloadWithPrefix,
    createAssetPreview,
    uploadAssetPreview,
    createAssetPreviewFailure,
    createAssetPreviewSuccess,
    uploadCategoryPreview,
    uploadCategoryPreviewFailure,
    uploadCategoryPreviewSuccess,
} from './slice';

const TIMEOUT = 600_000;

function* waitForAssetChange({
    provider,
    assetId,
    artifacts,
    prefix,
}: PayloadWithPrefix<AssetPayload> & { artifacts: string | string[] }) {
    const asset = yield select(getAsset, { provider, id: assetId });
    const metadataKeys = getMetadataKey(artifacts, prefix);
    const metadata = getMetadata(asset);
    const result = yield race({
        changed: take(findAssetChangeAction(provider, assetId, metadata, metadataKeys)),
        timeout: delay(TIMEOUT, true),
    });

    if (result.timeout) {
        throw new Error('Waiting for asset change timed out.');
    }
}

function* uploadPreviewFile(provider: string, file: File) {
    const name = generateS3BucketPath(provider, file, 'previews');
    const fileUploader = yield call(FileUploader.create);
    const path = yield apply(fileUploader, fileUploader.uploadFileWithoutProgress, [{ file, name }]);
    return getS3PublicUri(path);
}

function* handleCreateAssetPreview(action: ReturnType<typeof createAssetPreview>) {
    const { provider, assetId, cutRange, artifacts = getAssetArtifacts(provider) } = action.payload;

    try {
        const editedBy = yield select(authEmailSelector);
        const payload: CreateAssetPreviewMutationVariables = {
            provider,
            assetId,
            editedBy,
            cutRange,
            artifacts,
        };

        const pending = yield put(adminBffSdk.endpoints.createAssetPreview.initiate(payload, { track: false }));
        const result = yield race({
            fulfilled: take(
                (fulfilled: UnknownAction) =>
                    adminBffSdk.endpoints.createAssetPreview.matchFulfilled(fulfilled) &&
                    fulfilled.meta.requestId === pending.requestId
            ),
            rejected: take(
                (rejected: UnknownAction) =>
                    adminBffSdk.endpoints.createAssetPreview.matchRejected(rejected) &&
                    rejected.meta.requestId === pending.requestId
            ),
        });

        if (!result.fulfilled || 'message' in result.fulfilled.payload.createAssetPreview) {
            throw new Error(result.fulfilled?.payload.createAssetPreview.message || 'Unable to create asset preview');
        }

        yield waitForAssetChange({ provider, assetId, artifacts });
        yield put(createAssetPreviewSuccess({ provider, assetId }));
        Notification.notify.success(`Video preview has been added [ID: ${action.payload.assetId}].`);
    } catch (error) {
        const message = 'Creating video preview failed';
        reportMessageToSentry({
            message,
            extras: { error },
        });

        yield put(createAssetPreviewFailure({ provider, assetId, message }));
        Notification.notify.error(`${message} [ID: ${assetId}].`);
    }
}

function* handleUploadAssetPreview(action: ReturnType<typeof uploadAssetPreview>) {
    const { provider, assetId, file, prefix, artifacts = getAssetArtifacts(provider, prefix) } = action.payload;

    try {
        const source = yield uploadPreviewFile(provider, file);
        const editedBy = yield select(authEmailSelector);
        const payload: UploadAssetPreviewMutationVariables = {
            provider,
            assetId,
            editedBy,
            source,
            prefix,
            artifacts,
        };

        const pending = yield put(adminBffSdk.endpoints.uploadAssetPreview.initiate(payload, { track: false }));
        const result = yield race({
            fulfilled: take(
                (fulfilled: UnknownAction) =>
                    adminBffSdk.endpoints.uploadAssetPreview.matchFulfilled(fulfilled) &&
                    fulfilled.meta.requestId === pending.requestId
            ),
            rejected: take(
                (rejected: UnknownAction) =>
                    adminBffSdk.endpoints.uploadAssetPreview.matchRejected(rejected) &&
                    rejected.meta.requestId === pending.requestId
            ),
        });

        if (!result.fulfilled || 'message' in result.fulfilled.payload.uploadAssetPreview) {
            throw new Error(result.fulfilled?.payload.uploadAssetPreview.message || 'Unable to upload asset preview');
        }

        yield waitForAssetChange({ provider, assetId, artifacts, prefix });
        yield put(createAssetPreviewSuccess({ provider, assetId, prefix }));
        Notification.notify.success(`Video preview has been uploaded [ID: ${assetId}].`);
    } catch (error) {
        const message = 'Uploading video preview failed';
        reportMessageToSentry({
            message,
            extras: { error },
        });

        yield put(createAssetPreviewFailure({ provider, assetId, prefix, message }));
        Notification.notify.error(`${message} [ID: ${assetId}].`);
    }
}

function* handleUploadCategoryPreview(action: ReturnType<typeof uploadCategoryPreview>) {
    const { provider, categoryId, file, artifacts = getCategoryArtifacts(provider) } = action.payload;

    try {
        const source = yield uploadPreviewFile(provider, file);
        const editedBy = yield select(authEmailSelector);
        const payload: UploadCategoryPreviewMutationVariables = {
            provider,
            categoryId,
            editedBy,
            source,
            artifacts,
        };

        const pending = yield put(adminBffSdk.endpoints.uploadCategoryPreview.initiate(payload, { track: false }));
        const result = yield race({
            fulfilled: take(
                (fulfilled: UnknownAction) =>
                    adminBffSdk.endpoints.uploadCategoryPreview.matchFulfilled(fulfilled) &&
                    fulfilled.meta.requestId === pending.requestId
            ),
            rejected: take(
                (rejected: UnknownAction) =>
                    adminBffSdk.endpoints.uploadCategoryPreview.matchRejected(rejected) &&
                    rejected.meta.requestId === pending.requestId
            ),
        });

        if (!result.fulfilled || 'message' in result.fulfilled.payload.uploadCategoryPreview) {
            throw new Error(
                result.fulfilled?.payload.uploadCategoryPreview.message || 'Unable to upload category preview'
            );
        }
    } catch (error) {
        const message = 'Uploading category preview failed';
        reportMessageToSentry({
            message,
            extras: { error },
        });

        yield put(uploadCategoryPreviewFailure({ provider, categoryId, message }));
    }
}

function handleUploadCategoryPreviewFailure(action: ReturnType<typeof uploadCategoryPreviewFailure>) {
    const { categoryId, message } = action.payload;
    Notification.notify.error(`${message} [ID: ${categoryId}].`);
}

function handleUploadCategoryPreviewSuccess(action: ReturnType<typeof uploadCategoryPreviewSuccess>) {
    Notification.notify.success(`Category preview has been added [ID: ${action.payload.categoryId}].`);
}

export default [
    takeEvery(createAssetPreview.type, handleCreateAssetPreview),
    takeEvery(uploadAssetPreview.type, handleUploadAssetPreview),
    takeEvery(uploadCategoryPreview.type, handleUploadCategoryPreview),
    takeEvery(uploadCategoryPreviewFailure.type, handleUploadCategoryPreviewFailure),
    takeEvery(uploadCategoryPreviewSuccess.type, handleUploadCategoryPreviewSuccess),
];
