import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { type CategoryPayload, uploadCategoryPreviewSuccess } from 'store/uploads/previews/slice';

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {},
    reducers: {
        deleteCategoryPreview(state: RootState, action: PayloadAction<CategoryPayload>) {
            const { provider, categoryId } = action.payload;
            delete state[provider].items[categoryId].additional.metadata.promoUrl;
        },
    },
    extraReducers(builder) {
        builder.addCase(uploadCategoryPreviewSuccess, (state, action) => {
            const { provider, categoryId, url } = action.payload;
            state[provider].items[categoryId].additional.metadata.promoUrl = { value: url };
        });
    },
});

export const { deleteCategoryPreview } = categoriesSlice.actions;

export default categoriesSlice;
