import { useWatch, type Control } from 'react-hook-form';
import { RichDescription } from 'components/core/hookForm/RichDescription';
import type { FormValues } from '../types';

type DescriptionProps = {
    control: Control<FormValues>;
};

export function Description({ control }: DescriptionProps) {
    const isPodcast = useWatch({ control, name: 'additional.metadata.isPodcast' });
    return (
        <RichDescription
            name="description"
            richName="additional.metadata.richDescription"
            control={control}
            allowRich={isPodcast}
        />
    );
}
