import { connect } from 'react-redux';

import { hasLiveAssetFailed, isErrorCleared } from 'store/live/selectors';
import { getVideoUpload } from 'store/video/upload/selectors';
import { cancelUpload } from 'store/video/upload/actions';

import VideoTab from './VideoTab';

const mapStateToProps = (state, { provider, id }) => ({
    upload: getVideoUpload(state, { provider, id }),
    error:
        hasLiveAssetFailed(state, { newsroom: provider, assetId: id }) &&
        !isErrorCleared(state, { newsroom: provider, assetId: id }),
});
const enhance = connect(mapStateToProps, {
    cancelUpload,
});

export default enhance(VideoTab);
