import {
    HiOutlineOfficeBuilding,
    HiOutlineCalendar,
    HiOutlineLocationMarker,
    HiOutlineTag,
    HiOutlineUser,
    HiOutlineStar,
    HiOutlineQrcode,
} from 'react-icons/hi';

function getIconForTagType(type?: string) {
    switch (type) {
        case 'descriptor':
            return HiOutlineTag;
        case 'event':
            return HiOutlineCalendar;
        case 'location':
            return HiOutlineLocationMarker;
        case 'organization':
            return HiOutlineOfficeBuilding;
        case 'person':
            return HiOutlineUser;
        case 'interest':
            return HiOutlineStar;
        case 'machine':
            return HiOutlineQrcode;
        default:
            return HiOutlineTag;
    }
}

type TagIconProps = {
    className?: string;
    inheritColor?: boolean;
    tagType: string;
};

export function TagIcon({ className, inheritColor, tagType }: TagIconProps) {
    const IconComponent = getIconForTagType(tagType);
    return <IconComponent className={className} style={{ color: inheritColor ? 'inherit' : '#1E1E1C' }} />;
}
