import { useState } from 'react';
import PropTypes from 'prop-types';

import { Spinner, Button, Dialog } from '@schibsted-svp/react-ui';

import { playlistsDelete } from 'store/playlists/items';
import { useDispatch, useSelector } from 'react-redux';
import { getPlaylistDeletingState } from 'store/playlists/selectors';
import deleteButtonStyle from 'components/Playlists/Playlist.module.scss';
import css from './DeleteConfirmationDialog.module.scss';

export default function DeleteConfirmationDialog({ id, provider }) {
    const isDeleting = useSelector(getPlaylistDeletingState);
    const dispatch = useDispatch();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const deletePlaylist = () => {
        dispatch(playlistsDelete({ id, provider }));
    };

    const dialogTrigger = (
        <Button
            type="button"
            className={deleteButtonStyle.deleteButton}
            variant="standard"
            size="big"
            onClick={() => setIsDialogOpen(true)}
        >
            Delete
        </Button>
    );

    return (
        <Dialog
            isOpen={isDialogOpen}
            heading="Remove playlist"
            dialogTrigger={dialogTrigger}
            containerClassName={css.container}
        >
            {isDeleting ? (
                <Dialog.Section>
                    <Spinner containerClassName={css.spinner} />
                </Dialog.Section>
            ) : (
                <Dialog.Section className={css.message}>
                    You are about to <b>completely</b> remove this playlist. <br /> It will <b>not be available</b> in
                    videos that used it.
                </Dialog.Section>
            )}
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={() => setIsDialogOpen(false)}>
                    Cancel
                </Button>
                <Button type="button" onClick={deletePlaylist}>
                    Delete
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}

DeleteConfirmationDialog.propTypes = {
    id: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
};
