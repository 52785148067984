import { get, difference } from 'lodash';

export const getTags = (state, provider) => {
    if (provider) {
        return get(state, ['tags', provider]);
    }

    return get(state, 'tags');
};

export const getMissingTags = (state, provider, playlistTagsIds) => {
    const tags = state?.tags?.[provider] || {};
    const allTagsIds = Object.values(tags).map(({ tag }) => tag.id);

    return difference(playlistTagsIds, allTagsIds);
};
