import { useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import type { ExternalStreamFormData, ExternalStreamFormControl } from './types';

export const useExternalStreamForm = () => {
    const formApi = useForm<ExternalStreamFormData>({
        mode: 'all',
    });
    return { formApi };
};

export const useExternalStreamFormFormSubmit = (
    formApi: UseFormReturn<ExternalStreamFormData>,
    { onSubmit }: ExternalStreamFormControl
) => {
    const { handleSubmit } = formApi;
    return useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
};
