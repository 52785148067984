import { LabeledContainer, Notification, Spinner } from '@schibsted-svp/react-ui';
import { memo, useCallback, useMemo } from 'react';
import { reportMessageToSentry } from 'lib/error';
import { useAppSelector } from 'store/hooks';
import { getAssetPreviewPending } from 'store/uploads/previews/selectors';
import { MultiPreviewVariant } from './MultiPreviewVariant';
import { MultiPreviewPlaceholder } from './MultiPreviewPlaceholder';
import { MultiPreviewLabel } from './MultiPreviewLabel';
import { parseMetadataValue } from './utils';
import css from './MultiPreview.module.scss';

const VARIANTS_LIMIT = 4;

type MultiPreviewProps = {
    assetId: number;
    provider: string;
    onChange?: (newValue: string) => void;
    prefix: string;
    value: string | undefined;
};

export const MultiPreview = memo(function MultiPreview({
    assetId,
    provider,
    onChange,
    prefix,
    value,
}: MultiPreviewProps) {
    const variants = useMemo(() => parseMetadataValue(value), [value]);
    const pending = useAppSelector((state) => getAssetPreviewPending(state, { provider, assetId, prefix }));

    const handleRemoveClick = useCallback(
        (url: string) => {
            try {
                if (!onChange || !variants.length) {
                    return;
                }

                const nextVariants = variants.filter((variantItems) => {
                    return !variantItems.find((item) => item.url === url);
                });

                onChange(JSON.stringify(nextVariants));
            } catch (error) {
                const message = 'Removing variant from multi preview failed';

                reportMessageToSentry({
                    message,
                    extras: { error },
                });

                Notification.notify.error(message);
            }
        },
        [onChange, variants]
    );

    return (
        <LabeledContainer label={<MultiPreviewLabel prefix={prefix} provider={provider} />} className={css.container}>
            <div className={css.list}>
                {variants.map((variantItems) => {
                    const item = variantItems.at(0);
                    if (!item) {
                        return null;
                    }

                    return (
                        <MultiPreviewVariant
                            key={`preview:${item.url}`}
                            url={item.url}
                            onRemove={onChange ? handleRemoveClick : undefined}
                        />
                    );
                })}

                {Array.from({ length: pending }, (_, index) => (
                    <div className={css.loader} key={`multi-preview-variant-loader:${index}`}>
                        <Spinner size="small" />
                    </div>
                ))}

                {variants.length + pending < VARIANTS_LIMIT ? (
                    <MultiPreviewPlaceholder provider={provider} assetId={assetId} prefix={prefix} />
                ) : null}
            </div>
        </LabeledContainer>
    );
});
