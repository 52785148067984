import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';
import { Button } from '@schibsted-svp/react-ui';
import VideoCard from 'components/core/VideoCard';

import { itemType, itemSources } from 'components/video/NextVideo/constants';
import classnames from 'classnames/bind';
import css from './MovableVideoCard.module.scss';

const cln = classnames.bind(css);

type DragItem = {
    id: number;
    type: string;
    position: number;
};

interface MovableVideoCardProps {
    id: number;
    type: string;
    moveCard: (item?: DragItem, position?: number) => void;
    dropCard: (item: DragItem) => void;
    removeCard: (id: number) => void;
    provider: string;
    className?: string;
    obsolete?: boolean;
    preview?: boolean;
    position?: number;
}

export function MovableVideoCard({
    id,
    type,
    moveCard,
    dropCard,
    removeCard,
    provider,
    className = undefined,
    obsolete,
    preview,
    position,
}: MovableVideoCardProps) {
    const [{ dragged }, drag] = useDrag({
        type: itemType,
        item: { id, type, position },
        end: (item: DragItem, monitor: DragSourceMonitor) => {
            if (monitor.didDrop()) {
                dropCard(monitor.getItem());
            }

            moveCard();
        },
        collect: (monitor) => ({
            dragged: monitor.isDragging(),
        }),
    });

    const [{ hovered }, drop] = useDrop({
        accept: itemType,
        hover: (item: DragItem) => {
            if (type !== itemSources.nextVideos) {
                return;
            }
            moveCard(item, position);
        },
        collect: (monitor) => ({
            hovered: !!monitor.isOver(),
        }),
    });

    function attachRef(element) {
        drag(element);
        drop(element);
    }

    const handleRemoveCardClick = () => {
        removeCard(id);
    };

    const showRemoveButton = type === itemSources.nextVideos && !dragged && removeCard != null;

    return (
        <div
            ref={attachRef}
            className={cln('movableVideoCard', { dragged, hovered, obsolete, preview }, className)}
            data-testid="nextVideoCard"
        >
            <VideoCard asLink={false} readOnly variant="simple" key={`card:${type}:${id}`} id={id} provider={provider}>
                {showRemoveButton && (
                    <div className={css.overlay}>
                        <Button className={css.loadMore} type="button" size="small" onClick={handleRemoveCardClick}>
                            Remove
                        </Button>
                    </div>
                )}
            </VideoCard>
        </div>
    );
}
