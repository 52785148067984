import { generateS3BucketPath } from 'lib/s3';

function isValidFile(file) {
    return file instanceof File || file instanceof Blob;
}

export function generateS3bucketPath(provider, file) {
    if (!isValidFile(file)) {
        throw new Error('file-uploader.uploadCategoryImage expects file to be an instance of a File or Blob class');
    }

    if (!provider) {
        throw new Error('provider argument is required in file-uploader.uploadCategoryImage');
    }

    return generateS3BucketPath(provider, file, 'categories');
}

export const prepareImages = (getS3Uri, generateS3Path) => (provider, additionalImages, posterImage) => {
    const imagesToUpload = [];
    const additionalImagesToSave = {};

    for (const image of additionalImages) {
        const { key, url } = image;
        if (isValidFile(url)) {
            const path = generateS3Path(provider, url);
            imagesToUpload.push({ file: url, name: path });
            additionalImagesToSave[key] = getS3Uri(path);
        } else {
            additionalImagesToSave[key] = url;
        }
    }

    let mainImage = posterImage;
    if (isValidFile(mainImage)) {
        const name = generateS3Path(provider, mainImage);
        imagesToUpload.push({ file: mainImage, name });
        mainImage = getS3Uri(name);
    }

    return {
        mainImage,
        imagesToUpload,
        additionalImagesToSave,
    };
};
