import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { getDraftMetadataScores, getMetadataScores } from 'store/assets/selectors';
import { useUrlChecker } from 'components/core/Player/UrlCheckerContext';
import { getMessage } from './utils';
import css from './AssetChecklist.module.scss';

const cln = classnames.bind(css);

export type AssetChecklistProps = {
    assetId: number;
    provider: string;
    draftValues?: Record<string, unknown>;
    includeValid?: boolean;
    assetType?: string;
};

export function AssetChecklist({
    assetId,
    provider,
    draftValues,
    includeValid = false,
    assetType = 'video/audio',
}: AssetChecklistProps) {
    const { available, idleLive, shouldDisplay } = useUrlChecker();

    const rules = useSelector((state) =>
        draftValues
            ? getDraftMetadataScores(state, assetId, provider, draftValues)
            : getMetadataScores(state, assetId, provider)
    ) as Record<string, number>;

    if (!rules) return null;

    const ruleList = Object.entries(rules).filter(([, score]) => includeValid || score !== 1);

    return (
        <ol>
            {ruleList.map(([ruleName, score]) => (
                <li key={ruleName}>
                    <span className={cln('rule', { 'rule--valid': score === 1 })}>{getMessage(ruleName, score)}</span>
                </li>
            ))}
            {shouldDisplay && (
                <li key="audio/video">
                    <span className={cln('rule', `rule--${available ? 'valid' : 'critical'}`)}>
                        {!available && 'not '}working {assetType}
                        {!available && idleLive ? ', encoder not set' : ''}
                    </span>
                </li>
            )}
        </ol>
    );
}
