/**
 *
 * @param {Object} params
 * @param {String} params.provider
 * @returns {Promise<Array<String>>}
 */
export async function fetchLinkedProviders({ provider }) {
    const providers = await this.get('v1/{PROVIDER}', { provider });
    return providers.linkedProviders || [];
}
