import { useFormContext, Controller } from 'react-hook-form';
import { Select } from '@schibsted-svp/react-ui';
import { ExtendedProps as SelectProps } from '@schibsted-svp/react-ui/lib/es/Select';
import { getValueFromOption, findSingleOptionInOptionsGroup } from 'components/ui/Form/Select';
import type { Option } from 'components/ui/Form/Select/SelectUtils';
import { CuttingroomChannel } from '../../../../../services/admin-bff-sdk/generated';

type CuttingroomChannelSelectProps = {
    name: string;
    cuttingroomChannels: CuttingroomChannel[];
} & SelectProps;

export function CuttingroomChannelSelectRHF({ name, cuttingroomChannels, ...props }: CuttingroomChannelSelectProps) {
    const { control } = useFormContext();

    const cuttingroomChannelOptions: Option[] = cuttingroomChannels.map((cuttingroomChannel) => ({
        label: cuttingroomChannel.name,
        value: JSON.stringify({ server: cuttingroomChannel.server, streamKey: cuttingroomChannel.streamKey }),
    }));

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Select
                    {...field}
                    value={findSingleOptionInOptionsGroup(cuttingroomChannelOptions)(field.value)}
                    onChange={(value) => field.onChange(getValueFromOption(value))}
                    options={cuttingroomChannelOptions}
                    placeholder="No channel"
                    size="compact"
                    isClearable
                    {...props}
                />
            )}
        />
    );
}
