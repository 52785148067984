import { throttle } from 'lodash';
import { useState, useMemo } from 'react';
import { useLazyGetUsersQuery, User } from 'services/admin-bff-sdk/generated';

interface Suggestion {
    firstname?: string;
    lastname?: string;
    email: string;
    newsrooms: string[];
}

export const prepareSuggestions = (suggestions: User[]): Suggestion[] => {
    return suggestions
        .filter(({ roles }) => !roles.find(({ newsroom, roleType }) => newsroom === '*' || roleType === 'admin'))
        .map(({ firstname, lastname, email, roles }) => ({
            firstname,
            lastname,
            email,
            newsrooms: roles.map((role) => role.newsroom),
        }));
};

export const useUsersSuggestions = (): { getSuggestions: (searchTerm: string) => void; suggestions: Suggestion[] } => {
    const [getUsers] = useLazyGetUsersQuery();
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

    const getSuggestions = useMemo(
        () =>
            throttle(async (searchTerm: string) => {
                if (searchTerm.length >= 3) {
                    const { data } = await getUsers({ query: searchTerm });
                    setSuggestions(prepareSuggestions(data?.getUsers || []));
                } else {
                    setSuggestions([]);
                }
            }, 500),
        [getUsers]
    );

    return { getSuggestions, suggestions };
};
