import { isNumeric } from 'lib/number';
import type { Season } from './types';

export const prepareNewSeasonRow = (fields: Season[]) => {
    const seasonsNumbers = fields.map((season, index) => {
        const { seasonNumber } = fields[index];
        return isNumeric(seasonNumber) ? +seasonNumber + 1 : 1;
    });

    const nextSeasonNumber = seasonsNumbers.length ? Math.max(...seasonsNumbers) : 1;

    return { seasonNumber: nextSeasonNumber };
};
