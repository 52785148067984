import { get } from 'lodash';
import { getRelatedData, getSearchData } from '../../ui/assetForm/selectors';

export const getNextList = (state, { id, provider }) => {
    const { items = [], loading = false, page = 1, loopsTo } = get(state, `video.nextVideo.${provider}-${id}`) || {};
    const lastId = items[items.length - 1];
    const lastNextId = get(state, `assets.items.${provider}.${lastId}.asset.additional.nextAsset.id`);

    return {
        items: [...items],
        lastNextId,
        loading,
        loopsTo,
        page,
    };
};

export const getRelatedList = (state) => {
    const related = getRelatedData(state);

    return {
        items: related.items.slice(),
        loading: related.isFetching,
    };
};

/**
 *
 * @param {Object} state
 * @param {String} provider
 * @param {Number} id
 * @returns {Object}
 */
export const getSearchList = (state, provider, id) => {
    const search = getSearchData(state, provider, id);
    if (!search) {
        return {
            items: [],
            loading: false,
            performed: false,
        };
    }

    const performed =
        (search.query && search.query.length > 0) ||
        search.selectedFilters !== undefined ||
        search.sortType !== undefined;

    return {
        items: search.items.slice(),
        loading: search.isFetching,
        performed,
    };
};
