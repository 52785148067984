import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, UseFormReturn } from 'react-hook-form';
import { omit } from 'lodash';
import { updateLiveStream } from 'store/live/actions';
import { getAdjustedEncoderStartTime, getScheduleSettings } from 'components/video/live/LiveCreationDialog/utils';
import { getTimeInSeconds } from 'lib/time';
import type { LiveScheduleFormData } from '../../form/LiveSchedule';

export const useLiveScheduleForm = (initialValues: LiveScheduleFormData) => {
    const formApi = useForm<LiveScheduleFormData>({
        mode: 'all',
        defaultValues: initialValues,
    });
    return { formApi };
};

export const useLiveScheduleFormSubmit = ({
    formApi,
    newsroom,
    assetId,
    onSuccess = () => {},
    isRunning,
}: {
    formApi: UseFormReturn<LiveScheduleFormData>;
    newsroom: string;
    assetId: number;
    onSuccess?: () => void;
    isRunning: boolean;
}) => {
    const { handleSubmit } = formApi;
    const dispatch = useDispatch();

    return useMemo(
        () =>
            handleSubmit(async (data) => {
                const values = getScheduleSettings(newsroom, data);

                await dispatch(
                    updateLiveStream(newsroom, assetId, isRunning ? omit(values, 'encoderStartTime') : values)
                );
                onSuccess();
            }),
        [handleSubmit, dispatch, newsroom, assetId, onSuccess, isRunning]
    );
};

export const useInitialValues = ({
    newsroom,
    startTime,
    encoderStartTime,
    encoderDuration,
}: {
    newsroom: string;
    startTime?: number;
    encoderStartTime?: number;
    encoderDuration?: number;
}) => {
    return useMemo(
        () => ({
            startTime,
            encoderStartTime,
            encoderDuration,
            enableAssetSchedule: Boolean(startTime) && startTime > getTimeInSeconds(),
            enableEncoderSchedule:
                Boolean(encoderStartTime) && encoderStartTime !== getAdjustedEncoderStartTime(newsroom, startTime),
        }),
        [newsroom, startTime, encoderStartTime, encoderDuration]
    );
};
