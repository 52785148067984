import { formatRemainingExactTime } from 'lib/time';
import { generateUniqId } from 'lib/uniq';
import type { SubtitleCue } from './types';

export const CUE_ID_SIZE = 8;

function btoaUtf8(value: string) {
    return btoa(String.fromCharCode(...new TextEncoder().encode(value)));
}

const round = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

const cueMapper = (cue: VTTCue): SubtitleCue => ({
    id: cue.id || generateUniqId(CUE_ID_SIZE),
    startTime: round(cue.startTime),
    endTime: round(cue.endTime),
    text: cue.text,
});

export async function parseVttContent(data: string) {
    return new Promise<SubtitleCue[]>((resolve, reject) => {
        const videoElement = document.createElement('video');
        const trackElement = document.createElement('track');
        const textTrack = trackElement.track;
        videoElement.append(trackElement);
        trackElement.src = `data:text/plain;base64,${btoaUtf8(data)}`;
        trackElement.onerror = (event, source, lineno, colno, error) => reject(error);
        trackElement.onload = () => resolve(Array.from(textTrack.cues, cueMapper));
        textTrack.mode = 'hidden';
    });
}

export function convertSubtitleCuesToVtt(cues: SubtitleCue[]) {
    return `WEBVTT\n\n${cues
        .map(
            (cue, index) =>
                `${index + 1}\n${formatRemainingExactTime(cue.startTime)} --> ${formatRemainingExactTime(
                    cue.endTime
                )}\n${cue.text}`
        )
        .join('\n\n')}\n`;
}

export function generateVttFile(cues: SubtitleCue[], language: string) {
    const vttContent = convertSubtitleCuesToVtt(cues);
    const options = { type: 'text/plain' };
    const blob = new Blob([vttContent], options);
    return new File([blob], `${language}.vtt`, options);
}

export function createCue({ startTime, endTime }: Pick<SubtitleCue, 'startTime' | 'endTime'>): SubtitleCue {
    return {
        id: generateUniqId(CUE_ID_SIZE),
        startTime,
        endTime,
        text: '',
    };
}

export function getAudioFileFromMp4Url(url: string) {
    return `${url.substring(0, url.lastIndexOf('/'))}/128.mp3`;
}
