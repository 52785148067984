import omit from 'lodash/omit';
import { merge } from 'lib/object';
import { REMOVE_NAVIGATION_TAB } from 'store/video/navigation-tabs/actions';
import { CUTTING_START, CUTTING_PROGRESS, CUTTING_COMPLETE, CUTTING_FAILURE } from './actions';

export const CUTTING_STATE = Object.freeze({
    TRANSCODE: 'transcode',
    DONE: 'done',
    ERROR: 'error',
});

export default (state = {}, action) => {
    const { provider, id } = action;

    switch (action.type) {
        case CUTTING_START: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        state: CUTTING_STATE.TRANSCODE,
                        progress: 0,
                    },
                },
            });
        }

        case CUTTING_PROGRESS: {
            const { progress } = action;
            return merge(state, {
                [provider]: {
                    [id]: {
                        state: CUTTING_STATE.TRANSCODE,
                        progress,
                    },
                },
            });
        }

        case CUTTING_COMPLETE: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        state: CUTTING_STATE.DONE,
                        progress: 100,
                    },
                },
            });
        }

        case CUTTING_FAILURE: {
            const { error } = action;
            return merge(state, {
                [provider]: {
                    [id]: {
                        state: CUTTING_STATE.ERROR,
                        error,
                    },
                },
            });
        }

        case REMOVE_NAVIGATION_TAB: {
            const assetState = state?.[provider]?.[id];
            if (assetState) {
                return {
                    ...state,
                    [provider]: omit(state[provider], id),
                };
            }
            return state;
        }

        default:
            return state;
    }
};
