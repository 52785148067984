import { Notification } from '@schibsted-svp/react-ui';
import { useDispatch } from 'react-redux';
import z, { ZodError } from 'zod';

import config from 'config';
import { reportMessageToSentry } from 'lib/error';
import { isVideoType } from 'lib/file';
import { uploadAssetPreview } from 'store/uploads/previews/slice';
import { getAssetArtifacts } from 'store/uploads/previews/sagas-utils';
import { Dropzone, type DropzoneChildren } from 'components/video/Dropzone/Dropzone';

const { allowedVideoTypes } = config;

const filesValidator = z
    .instanceof(File)
    .refine((file) => isVideoType(file), 'Must be video file type')
    .array()
    .length(1, 'Must contain one video file exactly');

type PreviewDropzoneProps = {
    className?: string;
    assetId: number;
    provider: string;
    children?: DropzoneChildren;
    prefix?: string;
    onUpload?: () => void;
};

export function PreviewDropzone({ className, provider, assetId, prefix, children, onUpload }: PreviewDropzoneProps) {
    const dispatch = useDispatch();
    const artifacts = getAssetArtifacts(provider, prefix);

    const handleUpload = async (files: unknown) => {
        try {
            const [file] = filesValidator.parse(files);
            dispatch(uploadAssetPreview({ provider, assetId, file, prefix, artifacts }));
            onUpload?.();
        } catch (error) {
            if (error instanceof ZodError) {
                const formatted = error.format();
                // eslint-disable-next-line no-underscore-dangle
                const message = formatted._errors?.[0] ?? formatted[0]?._errors;
                Notification.notify.error(`An error occurred during upload: ${message}.`);
                return;
            }

            reportMessageToSentry({
                message: 'Custom preview upload failed',
                extras: {
                    error,
                },
            });

            Notification.notify.error('An unexpected error occurred during file upload.');
        }
    };

    return (
        <Dropzone accept={allowedVideoTypes} onUpload={handleUpload} className={className}>
            {children ? (props) => children(props) : undefined}
        </Dropzone>
    );
}
