import { getTimeInSeconds } from 'lib/time';
import { ASSET_CHANGE } from 'store/assets/actionTypes';
import { wasLive } from 'models/asset';
import * as Actions from '../actions';

const isCreatedInLast24hours = (asset) => {
    return asset.created > getTimeInSeconds() - 24 * 3600;
};

const defaultState = {
    isFetching: false,
    list: [],
    error: '',
};

export default function wasLiveAssetsReducer(state = defaultState, action) {
    switch (action.type) {
        case Actions.FETCH_WAS_LIVE_ASSETS: {
            return {
                ...state,
                isFetching: true,
                error: '',
            };
        }
        case Actions.FETCH_WAS_LIVE_ASSETS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                list: action.assetsIds,
                error: '',
            };
        }
        case Actions.FETCH_WAS_LIVE_ASSETS_ERROR: {
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        }

        case ASSET_CHANGE: {
            const { asset } = action;
            if (wasLive(asset) && isCreatedInLast24hours(asset)) {
                return { ...state, list: [...new Set([...state.list, asset.id])] };
            }
            return state;
        }

        default:
            return state;
    }
}
