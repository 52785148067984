import { Radio, LabeledContainer } from '@schibsted-svp/react-ui';
import { MediaLiveInputType } from 'services/admin-bff/contants';
import { useMediaLiveInputFormContext } from './hooks';

import css from './MediaLiveInput.module.scss';

export function MediaLiveInput() {
    const { formApi } = useMediaLiveInputFormContext();
    const { register } = formApi;

    return (
        <LabeledContainer label="Source type">
            <div className={css.radios}>
                <Radio {...register('inputType')} value={MediaLiveInputType.PUSH} label="Generate key (push)" />
                <Radio {...register('inputType')} value={MediaLiveInputType.PULL} label="External URL (pull)" />
            </div>
        </LabeledContainer>
    );
}
