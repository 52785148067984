import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import type { Control, FieldValues, FieldPath, ControllerRenderProps } from 'react-hook-form';
import { Select, Spinner } from '@schibsted-svp/react-ui';
import type { ExtendedProps as SelectProps, DefaultOptionType } from '@schibsted-svp/react-ui/lib/es/Select';
import { useGetStoriesQuery } from 'services/admin-bff-sdk';
import { toHashMap } from 'lib/array';
import { useStoriesSearch } from 'components/AssetForm/hooks/useStoriesSearch';
import { getValuesFromOptionsArray } from 'components/ui/Form/Select';
import css from './StoriesSelect.module.scss';

const SEARCH_STORIES_DEBOUNCE_DELAY_MS = 500;

function LoadingMessage() {
    return <Spinner containerClassName={css.optionsSpinner} size="compact" label="Loading stories" />;
}
function LoadingIndicator() {
    return null;
}

type StoriesSelectProps<TFieldValues extends FieldValues> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    provider: string;
    normalizeValues?: boolean;
} & SelectProps<DefaultOptionType, true>;

export function StoriesSelect<TFieldValues extends FieldValues>({
    name,
    control,
    provider,
    normalizeValues = false,
    ...rest
}: StoriesSelectProps<TFieldValues>) {
    const { field, fieldState } = useController({ name, control });
    const storiesIds = useMemo(
        () => (normalizeValues ? field.value : getValuesFromOptionsArray(field.value)) || [],
        [field.value, normalizeValues]
    );
    const searchQuery = useStoriesSearch({ provider, delay: SEARCH_STORIES_DEBOUNCE_DELAY_MS });
    const storiesQuery = useGetStoriesQuery({ provider, ids: storiesIds }, { skip: !storiesIds?.length });
    const isLoading =
        storiesQuery.isFetching || storiesQuery.isLoading || searchQuery.isFetching || searchQuery.isLoading;

    const storiesOptions = useMemo(() => {
        const stories = toHashMap(storiesQuery.data?.getStories || []);
        return storiesIds.map((storyId) => ({
            label: stories[storyId]?.title,
            value: storyId,
        }));
    }, [storiesIds, storiesQuery.data]);

    const searchOptions = useMemo(
        () => searchQuery.searchResults?.map(({ id, title }) => ({ value: id, label: title })) || [],
        [searchQuery.searchResults]
    );

    const formatOptionLabel = ({ label }: DefaultOptionType) => {
        return !label || storiesQuery.isLoading ? <Spinner containerClassName={css.selectSpinner} /> : label;
    };

    const normalizeValuesHandlers: Pick<ControllerRenderProps, 'value' | 'onChange'> = {
        value: storiesOptions,
        onChange: (value) => field.onChange(getValuesFromOptionsArray(value)),
    };

    return (
        <Select
            {...field}
            {...(normalizeValues && normalizeValuesHandlers)}
            error={fieldState.error?.message}
            placeholder="Search for story"
            isLoading={isLoading}
            onInputChange={searchQuery.onStoriesSearch}
            options={searchOptions}
            formatOptionLabel={formatOptionLabel}
            components={{ LoadingMessage, LoadingIndicator }}
            backspaceRemovesValue={false}
            splitButton={false}
            isMulti
            isClearable
            {...rest}
        />
    );
}
