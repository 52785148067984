import { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import image from 'lib/image';
import BEM from 'lib/bem';

import './Image.scss';

const bem = new BEM('image');

class Image extends PureComponent {
    static propTypes = {
        src: PropTypes.string.isRequired,
        // image variant from config
        type: PropTypes.string.isRequired,
        // container width
        width: PropTypes.number.isRequired,
        // alt text
        alt: PropTypes.string,
        // styling options
        className: PropTypes.string,
        // allow to display the origin image's size
        ratioless: PropTypes.bool,
    };

    static defaultProps = {
        alt: '',
        className: '',
        ratioless: false,
    };

    render() {
        const { src, type, width, alt, className, ratioless } = this.props;
        // Don't add query params if it's a base64 data
        const imgSrc = src.startsWith('data:image') ? src : image.getImageSrc(src, type, width);

        return (
            <span className={bem.block({ ratioless })}>
                <img className={[bem.element('item'), className].join(' ')} src={imgSrc} alt={alt} />
            </span>
        );
    }
}

export default Image;
