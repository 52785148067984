import { isEncrypted } from 'models/asset';
import { getAsset } from 'store/assets/selectors';
import { CUTTING_STATE } from './reducers';

export const getCutting = (state, { provider, id }) => state.video?.cutting?.[provider]?.[id] || {};

export const getSecuredCutting = (state, { provider, id }) => {
    const asset = getAsset(state, { provider, id });

    // handle clip creation of encrypted asset
    if (isEncrypted(asset)) {
        return state.video.upload?.[provider]?.[id]?.video || {};
    }

    return getCutting(state, { provider, id });
};

export const getCuttingState = (state, props) => getCutting(state, props).state || CUTTING_STATE.DONE;

export const getCuttingError = (state, props) => {
    if (getCuttingState(state, props) === CUTTING_STATE.ERROR) {
        return getCutting(state, props).error || 'Unknown error';
    }
    return undefined;
};

export const isCuttingInProgress = (state, props) => getCuttingState(state, props) === CUTTING_STATE.TRANSCODE;
