import type { RootState } from 'store';
import previewsSlice, { AssetPayload, CategoryPayload, getPreviewKey, PayloadWithPrefix } from './slice';

export function getAssetPreviewPending(state: RootState, args: PayloadWithPrefix<AssetPayload>) {
    return state.uploads?.[previewsSlice.name]?.[getPreviewKey(args)] ?? 0;
}

export function isAssetPreviewPending(state: RootState, args: PayloadWithPrefix<AssetPayload>) {
    return state.uploads?.[previewsSlice.name]?.[getPreviewKey(args)] > 0;
}

export function isCategoryPreviewPending(state: RootState, args: PayloadWithPrefix<CategoryPayload>) {
    return state.uploads?.[previewsSlice.name]?.[getPreviewKey(args)] > 0;
}
