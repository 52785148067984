import { put, call, takeLatest } from '@redux-saga/core/effects';

import config from 'config';
import { apiClient } from 'services';
import { aclFetchSuccess, aclFetchError } from './actions';
import { ACL_FETCH } from './actionTypes';

export function* fetchAcl() {
    try {
        const response = yield call(apiClient.fetchAclList);

        const newsrooms = Object.keys(response.newsrooms)
            .sort()
            .map((newsroom) => ({
                id: newsroom,
                name: config.newsrooms[newsroom],
            }));

        const core = response.core ? Object.keys(response.core) : [];

        yield put(aclFetchSuccess({ newsrooms, core }));
    } catch ({ message, response = {} }) {
        yield put(aclFetchError(message));
    }
}

export default [takeLatest(ACL_FETCH, fetchAcl)];
