import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { atLeastOneEvent } from 'models/multiLiveEvent';
import { isLive, isRtmpEncoder } from 'models/asset';
import { fetchAsset } from 'store/assets/actions';
import { createClip, fetchClips, trimVideo, resetTrim } from 'store/clips/actions';
import { getAsset, getAssetData } from 'store/assets/selectors';
import { getClipList, isCreateClipPending, isTrimPending, isResetTrimPending } from 'store/clips/selectors';
import { isReady, getOriginalStreamUrls } from 'store/video/selectors';
import { findLiveEncoderEvents } from 'store/live/selectors';
import { isConversionInProgress } from 'store/video/stream-conversion/selectors';
import { isCuttingInProgress } from 'store/video/cutting/selectors';
import Clips from './Clips';

/**
 * Checking live asset activity based on source and protocol metadata.
 *
 * Should be also active when the source is set to rtmp (or software).
 * @see https://github.schibsted.io/svp/admin-front/issues/611
 * @see https://github.schibsted.io/svp/admin-front/pull/612#discussion_r748505
 *
 * @param {Object} state
 * @param {Object} asset
 * @returns {boolean}
 */
export const isLiveActive = (state, asset) => {
    if (!isLive(asset)) {
        return false;
    }

    const { source } = asset.additional.metadata;

    const events = findLiveEncoderEvents(state, { assetId: asset.id, provider: asset.provider });

    return atLeastOneEvent(events) || isRtmpEncoder(asset) || source === 'medialive';
};

export const getOriginAsset = (state, { id, provider }) => {
    const { asset = null, isFetching = false } = getAssetData(state, { id, provider }) || {};
    return {
        asset,
        loading: isFetching,
    };
};

export const mapStateToProps = (state, { id, provider }) => {
    const asset = getAsset(state, { id, provider });
    const { originAssetId } = asset.additional;
    const activeLive = isLiveActive(state, asset);

    const originAssetProps = { id: originAssetId || id, provider };
    return {
        asset,
        provider,
        activeLive,
        originAsset: getOriginAsset(state, { id: originAssetId, provider }),
        clipList: getClipList(state, originAssetProps),
        ready: isReady(state, { id, provider }),
        isCreateClipPending: isCreateClipPending(state, originAssetProps),
        isTrimPending: isTrimPending(state, originAssetProps),
        isTrimInProgress: isCuttingInProgress(state, originAssetProps),
        isConversionInProgress: isConversionInProgress(state, originAssetProps),
        isResetInProgress: isResetTrimPending(state, { id, provider }),
        originalStreamUrls: getOriginalStreamUrls(state, { id, provider }),
    };
};

const enhance = compose(
    connect(mapStateToProps, {
        fetchAsset,
        fetchClips,
        createClip,
        trimVideo,
        resetTrim,
    })
);

export default enhance(Clips);
