import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWatch, type Control, type ControllerRenderProps } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { fetchThumbnails } from 'store/thumbnails/actions';
import { revokeVideoPosterPreview } from 'store/video/upload/actions';
import { getCategory } from 'store/categories/selectors';
import { getSuggestedThumbnails, getAreThumbnailsLoading } from 'store/thumbnails/selectors';
import { getUploadedVideoPosterUri } from 'store/video/upload/selectors';
import { isAudio, isLive } from 'models/asset';
import type { Thumbnail } from 'services/player-api-client/thumbnails';
import { usePrevious } from 'hooks/usePrevious';
import type { FormValues } from '../../types';

export const useFetchThumbnails = ({
    asset,
}: {
    asset: Asset;
}): { thumbnails: Thumbnail[]; areThumbnailsLoading: boolean } => {
    const dispatch = useDispatch();

    const { provider, id } = asset;

    const thumbnails = useSelector((state) => getSuggestedThumbnails(state, { provider, id }));
    const areThumbnailsLoading = useSelector((state) => getAreThumbnailsLoading(state, { provider, id }));

    useEffect(() => {
        if (!thumbnails.length && !isAudio(asset) && !isLive(asset)) {
            dispatch(fetchThumbnails(provider, id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { thumbnails, areThumbnailsLoading };
};

export const useUploadedPosterChange = ({
    provider,
    id,
    onChange,
}: {
    provider: Asset['provider'];
    id: Asset['id'];
    onChange: ControllerRenderProps['onChange'];
}) => {
    const uploadedPoster = useSelector((state) => getUploadedVideoPosterUri(state, { id, provider }));
    const previousUploadedPoster = usePrevious(uploadedPoster);

    useEffect(() => {
        if (uploadedPoster && uploadedPoster !== previousUploadedPoster) {
            onChange(uploadedPoster);
        }
    }, [uploadedPoster, previousUploadedPoster, onChange]);
};

export const useCategoryChange = ({
    asset,
    control,
    onChange,
}: {
    asset: Asset;
    control: Control<FormValues>;
    onChange: ControllerRenderProps['onChange'];
}) => {
    const dispatch = useDispatch();

    const categoryId = useWatch({ control, name: 'category.id' });
    const prevCategoryId = usePrevious(categoryId);
    const category = useSelector((state) => getCategory(state, { provider: asset.provider, id: categoryId }));

    useEffect(() => {
        if (categoryId !== prevCategoryId && categoryId && prevCategoryId !== undefined && isAudio(asset)) {
            const categoryImage = category?.additional?.image;
            if (categoryImage) {
                dispatch(revokeVideoPosterPreview(asset.provider, asset.id));
                onChange(categoryImage);
            }
        }
    }, [categoryId, prevCategoryId, category, asset, dispatch, onChange]);
};
