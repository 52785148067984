import type { ReactNode } from 'react';
import classnames from 'classnames/bind';
import { LabeledContainer, CopyToClipboard } from '@schibsted-svp/react-ui';
import css from './FieldContainer.module.scss';

const cln = classnames.bind(css);

type FieldContainerProps = {
    label: string;
    highlighted?: boolean;
    copyable?: boolean;
    fieldClassName?: string;
    children: ReactNode;
};

export function FieldContainer({ label, highlighted, copyable, fieldClassName, children }: FieldContainerProps) {
    return (
        <LabeledContainer label={label} className={cln({ highlighted })}>
            {children ? (
                <div className={fieldClassName}>
                    {copyable && typeof children === 'string' ? (
                        <CopyToClipboard text={children} className={css.copy} />
                    ) : (
                        children
                    )}
                </div>
            ) : (
                '-'
            )}
        </LabeledContainer>
    );
}
