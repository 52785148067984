import { useState, useEffect } from 'react';
import * as ReactGA from 'react-ga';
import { Button, Dialog, LabeledContainer, Input, InputError } from '@schibsted-svp/react-ui';
import config from 'config';
import { Dropzone } from 'components/video/Dropzone/Dropzone';
import { LogoOverlaySelectRHF, hasLogoOverlayOptions } from 'components/video/live/form/LogoOverlaySelect';
import useNewsroom from 'hooks/useNewsroom';
import { Tab, Tabs } from 'components/ui/Tabs';
import { UploadDialogTrigger } from './UploadDialogTrigger';
import { useUploadDialogForm } from './hooks';
import css from './UploadDialog.module.scss';

const { allowedVideoTypes } = config;

const TABS = {
    LOCAL: 'local',
    URL: 'url',
} as const;

type Tab = (typeof TABS)[keyof typeof TABS];

const DEFAULT_TAB = TABS.LOCAL;

type UploadDialogProps = {
    triggerType: 'button' | 'dropdown';
};

export function UploadDialog({ triggerType = 'button' }: UploadDialogProps) {
    const provider = useNewsroom();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tab, setTab] = useState<Tab>(DEFAULT_TAB);

    const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

    const { formApi, handleSubmit } = useUploadDialogForm({ provider, toggleDialog });
    const { control, register, reset, setValue, formState } = formApi;
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (isDialogOpen) {
            reset();
            setTab(DEFAULT_TAB);
            ReactGA.modalview('advanced-filters');
        }
    }, [isDialogOpen, reset]);

    const onTabChange = (newTab: Tab) => {
        reset();
        setTab(newTab);
    };

    const onFileUpload = (files: File[]) => {
        setValue('files', files);
        handleSubmit();
    };

    return (
        <Dialog
            heading="Upload"
            dialogTrigger={<UploadDialogTrigger triggerType={triggerType} toggleDialog={toggleDialog} />}
            isOpen={isDialogOpen}
            onClose={toggleDialog}
        >
            <form onSubmit={handleSubmit}>
                <Dialog.Section className={css.tabsContainer}>
                    <Tabs>
                        <Tab label="Local file" isActive={tab === TABS.LOCAL} onClick={() => onTabChange(TABS.LOCAL)} />
                        <Tab label="From URL" isActive={tab === TABS.URL} onClick={() => onTabChange(TABS.URL)} />
                    </Tabs>
                </Dialog.Section>
                <Dialog.Section>
                    {tab === TABS.LOCAL && (
                        <>
                            <Dropzone
                                accept={allowedVideoTypes}
                                loading={isSubmitting}
                                onUpload={onFileUpload}
                                className={css.dropzone}
                            />
                            <InputError>{errors?.files?.message}</InputError>
                        </>
                    )}
                    {tab === TABS.URL && (
                        <LabeledContainer label="File URL">
                            <Input {...register('fileUrl')} error={errors?.fileUrl?.message} />
                        </LabeledContainer>
                    )}
                    {hasLogoOverlayOptions(provider) ? (
                        <LabeledContainer label="Burn-in logo" className={css.burnInLogo}>
                            <LogoOverlaySelectRHF name="overlay" control={control} newsroom={provider} size="compact" />
                        </LabeledContainer>
                    ) : null}
                </Dialog.Section>
                <Dialog.Section mode="flexRight" variant="darker">
                    <Button type="button" variant="standard" onClick={toggleDialog} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    {tab === 'url' && (
                        <Button type="submit" variant="primary" disabled={isSubmitting} loading={isSubmitting}>
                            Upload
                        </Button>
                    )}
                </Dialog.Section>
            </form>
        </Dialog>
    );
}
