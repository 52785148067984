import { Component } from 'react';
import svpPlayerLoader from 'lib/svpPlayerSdk';

const withSvpPlayer = (WrappedComponent) => {
    class WithSvpPlayer extends Component {
        state = {
            SvpPlayer: null,
            isReady: false,
        };

        static getDerivedStateFromProps(props, state) {
            if (state.SvpPlayer && state.isReady === false) {
                return {
                    isReady: true,
                };
            }

            return null;
        }

        async componentDidMount() {
            const SvpPlayer = await svpPlayerLoader();
            this.setState({
                SvpPlayer,
            });
        }

        render() {
            // do not pass decorator's specific props further
            // eslint-disable-next-line react/prop-types
            const { SvpPlayer, isReady } = this.state;
            if (isReady === false) {
                return null;
            }

            return <WrappedComponent SvpPlayer={SvpPlayer} {...this.props} />;
        }
    }

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithSvpPlayer.displayName = `withSvpPlayer(${wrappedComponentName})`;

    return WithSvpPlayer;
};

export default withSvpPlayer;
