import * as ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import config from 'config';

import PageViewTracker from './PageViewTracker';

if (config.ga) {
    // should be outside the component to be called only once
    ReactGA.initialize(config.ga.trackingCode, config.ga.options);
}

function GoogleAnalytics() {
    const location = useLocation();

    if (!config.ga) {
        return null;
    }

    return <PageViewTracker path={location.pathname} query={location.search} />;
}

export default GoogleAnalytics;
