import type { Optional } from 'types';

export function getFullUsernameFromEmail(email: Optional<string>) {
    if (!email) {
        return '';
    }
    return email
        .split('@')[0]
        .split('.')
        .map((word) => word.charAt(0).toUpperCase() + word.substr(1))
        .join(' ');
}

export function getFirstUsernameFromEmail(email: Optional<string>) {
    return email ? email.split('@')[0].split('.')[0] : '';
}

export function isEmail(string: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(string);
}
