import type { Category } from '@schibsted-svp/svp-api-types';

export const getCustomMetadataFields = (category: Category) => {
    const metadata = category?.additional?.metadata;

    const customFields = Object.entries(metadata || {})
        .filter(([key]) => key.startsWith('custom_field'))
        .map((field) => [field[0].replace('custom_field_', ''), JSON.parse(field[1]?.value)]);

    return customFields;
};

export const getCustomFieldsPerType = ({ category, type }: { category: Category; type: string }) => {
    const customFields = getCustomMetadataFields(category);

    const fieldsOfType = customFields
        .filter(([, value]) => value.type === type)
        .map(([key, value]) => ({ key, label: value.label }));

    return fieldsOfType;
};

export const getCustomFieldsTypeKeys = (customFieldsPerType) => customFieldsPerType.map(({ key }) => key);

export const isCustomField = (customFieldsPerType, filterType) =>
    customFieldsPerType.length && getCustomFieldsTypeKeys(customFieldsPerType).includes(filterType);

export const getCustomFieldLabel = (customFieldsPerType, filterType) =>
    customFieldsPerType.find((field) => field.key === filterType).label;
