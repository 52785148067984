import { createContext, memo, type PropsWithChildren, useEffect } from 'react';
import type { EditMethod, Selection, Item, Viewport } from './types';
import { useContextState, type UseContextStateReturn } from './hooks/useContextState';

export type TimelineState = {
    duration: number;
    items: Item[];
    method?: EditMethod;
    offset?: number;
    scroll: number;
    pixelsPerSecond: number;
    selection?: Selection;
    viewport?: Viewport;
    totalDuration?: number;
};

export const TimelineStateContext = createContext<UseContextStateReturn<TimelineState> | null>(null);

type TimelineStateProviderProps = PropsWithChildren<Pick<TimelineState, 'duration' | 'pixelsPerSecond'>>;

export const TimelineStateProvider = memo<TimelineStateProviderProps>(function TimelineStateProvider({
    children,
    duration,
    pixelsPerSecond,
}) {
    const contextState = useContextState<TimelineState>({
        duration,
        items: [],
        pixelsPerSecond,
        scroll: 0,
    });
    const { setState } = contextState;

    useEffect(() => setState({ duration }), [duration, setState]);
    useEffect(() => setState({ pixelsPerSecond }), [pixelsPerSecond, setState]);

    return <TimelineStateContext.Provider value={contextState}>{children}</TimelineStateContext.Provider>;
});
