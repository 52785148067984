import { combineReducers } from '@reduxjs/toolkit';
import config from 'config';
import { isBoolean, omit } from 'lodash';
import { defaultList, defaultFilters } from './state';
import * as Actions from './actionTypes';

export function removeEmptyValues(data) {
    return Object.entries(data).reduce((accumulator, [key, value]) => {
        if (!value && value !== 0) {
            return accumulator;
        }

        if (typeof value === 'object') {
            if (!Object.keys(value).length) {
                return accumulator;
            }
            if (!Array.isArray(value)) {
                return { ...accumulator, [key]: removeEmptyValues(value) };
            }
        }

        return { ...accumulator, [key]: value };
    }, {});
}

const listReducer =
    (list) =>
    (state = defaultList, action) => {
        switch (action.type) {
            case Actions.SORT_TYPE_SET:
            case Actions.FILTERS_SET:
            case Actions.FILTERS_REMOVE:
            case Actions.FILTERS_CLEAR:
            case Actions.FETCH_LIST: {
                if (action.list === list) {
                    return {
                        ...state,
                        isClearing: true,
                        isFetching: true,
                    };
                }
                return state;
            }

            case Actions.FETCH_MORE: {
                if (action.list === list) {
                    return {
                        ...state,
                        isClearing: false,
                        isFetching: true,
                    };
                }
                return state;
            }

            case Actions.FETCH_ERROR: {
                if (action.list === list) {
                    return {
                        ...state,
                        isClearing: false,
                        isFetching: false,
                        items: [],
                    };
                }
                return state;
            }

            case Actions.SET_HIGHLIGHT: {
                const { assetId, value } = action;
                const { highlights } = state;

                return {
                    ...state,
                    highlights: {
                        ...highlights,
                        [assetId]: value,
                    },
                };
            }

            case Actions.SET_ASSETS:
            case Actions.ADD_ASSETS: {
                if (action.list === list) {
                    const { assets, nextUrl } = action;
                    const assetIds = assets.map((asset) => asset.id);
                    const providers = [...new Set(assets.map((asset) => asset.provider))];

                    return {
                        ...state,
                        isClearing: false,
                        isFetching: false,
                        items:
                            action.type === Actions.SET_ASSETS ? assetIds : [...new Set([...state.items, ...assetIds])],
                        providers,
                        nextUrl,
                        lastResultsCount: assets.length,
                    };
                }
                return state;
            }

            default:
                return state;
        }
    };

export function filterReducer(state = defaultFilters(), action) {
    switch (action.type) {
        case Actions.FILTERS_SET: {
            const { filters } = action;
            const newState = { ...state };

            if (!filters.assetTypes || (filters.assetTypes && filters.assetTypes.length === 0)) {
                const { assetTypes: defaultAssetTypes } = defaultFilters();
                filters.assetTypes = state.assetTypes || defaultAssetTypes;
            }

            return removeEmptyValues({ ...newState, ...filters });
        }

        case Actions.FILTERS_REMOVE: {
            const { filter, value } = action;

            const newState = { ...state };

            if (newState?.customFields && Object.keys(newState.customFields).includes(filter)) {
                return {
                    ...newState,
                    customFields: omit(newState.customFields, filter),
                };
            }

            if (isBoolean(value)) {
                return {
                    ...state,
                    [filter]: false,
                };
            }

            if (filter === 'categories' && newState?.customFields) {
                delete newState.customFields;
            }

            let newFilter = ['after', 'before', 'min', 'max', 'categories', 'createdBy'].includes(filter)
                ? null
                : newState[filter].filter((item) => (typeof item === 'object' ? item.value !== value : item !== value));

            if (!newFilter || newFilter.length === 0) {
                if (filter === 'assetTypes') {
                    const { assetTypes: defaultAssetTypes } = defaultFilters();
                    newFilter = defaultAssetTypes;
                } else {
                    delete newState[filter];
                    return newState;
                }
            }

            newState[filter] = newFilter;

            return newState;
        }

        case Actions.FILTERS_CLEAR: {
            const { assetsListType } = action;
            const { assetType } = config.asset.labels;

            return {
                ...defaultFilters(assetsListType),
                // this action is invoking when we change asset list type or
                // when clearing filters via clear button
                // in second case we want to set podcast to false
                isPodcast: assetsListType === assetType.audio && action.isPodcastOnly,
                ...(!action.isChangingAssetListType && { isPodcast: false }),
            };
        }

        default:
            return state;
    }
}

export const defaultSortType = config.sortTypes.find(({ value }) => value === 'created');

function sortTypeReducer(state = defaultSortType, action) {
    switch (action.type) {
        case Actions.SORT_TYPE_SET: {
            const { sortType } = action;
            return sortType;
        }

        default:
            return state;
    }
}

export default combineReducers({
    main: listReducer('main'),
    linked: listReducer('linked'),
    filters: filterReducer,
    sortType: sortTypeReducer,
});
