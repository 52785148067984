import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { get } from 'lodash';

import {
    fetchMore as fetchMoreAction,
    filtersSet as filtersSetAction,
    sortTypeSet as setSortType,
    filtersRemove as filtersRemoveAction,
    fetchList,
    filtersClear,
} from 'store/asset-list/actions';
import { optionsToggle as optionsToggleAction } from 'store/user/actions';
import { clearSearch, searchFor } from 'store/ui/actions';
import { getAssetsListTypes } from 'store/ui/selectors';
import { getMainAssetsListItems, getLinkedAssetsListItems } from 'store/asset-list/selectors';

import { AssetsList } from './AssetsList';

const mapStateToProps = (
    { newsroom, assetList: { main, linked, filters, sortType }, user: { preferences }, assets: { items } },
    { type }
) => {
    return {
        newsroom,
        type,
        list: type === 'linked' ? linked : main,
        items:
            type === 'linked'
                ? getLinkedAssetsListItems({ items, assetList: linked })
                : getMainAssetsListItems({ items: items[newsroom], itemIds: main.items }),
        filters,
        sortType,
        preferences,
        assetsListType: get(getAssetsListTypes({ assetList: { filters } }), 0),
    };
};

/**
 * @param {Object} filters
 * @return {Object}
 */
const normalizeMultiSelectFilters = (filters) =>
    ['aspects', 'sources', 'types', 'tags', 'stories', 'accessLevels'].reduce(
        (acc, cur) => ({
            ...acc,
            ...(filters[cur] === '' && { [cur]: undefined }),
        }),
        filters
    );

const enhance = compose(
    connect(mapStateToProps, (dispatch, { type }) => ({
        fetchMore: () => dispatch(fetchMoreAction({ list: type })),
        optionsToggle: (option) => dispatch(optionsToggleAction({ option })),
        setFilters: (filters) =>
            dispatch(filtersSetAction({ list: type, filters: normalizeMultiSelectFilters(filters) })),
        setSortType: (sortType) => dispatch(setSortType({ list: type, sortType })),
        removeFilters: (info) => dispatch(filtersRemoveAction({ list: type, ...info })),
        clearFilters: (assetsListType) => dispatch(filtersClear({ list: type, assetsListType })),
        clearSearch: () => dispatch(clearSearch()),
        searchFor: (searchTerm, provider) => dispatch(searchFor({ searchTerm, provider })),
        fetchList: (payload) => dispatch(fetchList(payload)),
    }))
);

export default enhance(AssetsList);
