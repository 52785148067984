import type { UseFormReturn, FieldArrayWithId } from 'react-hook-form';
import { Input, Button, InputError, Spinner } from '@schibsted-svp/react-ui';
import config from 'config';
import { FieldAdder } from 'components/core/hookForm/FieldAdder';
import QuestionMark from 'components/ui/QuestionMark';
import { trimValue } from './helpers';
import type { CategoryData, DynamicFieldMetadata } from './types';

import css from './DynamicFields.module.scss';

function isFieldDisabled(
    inheritedDynamicFields: DynamicFieldMetadata[],
    field: FieldArrayWithId<CategoryData, 'dynamicFieldsMetadata', 'id'>
): boolean {
    return inheritedDynamicFields.some(({ key, value }) => key === field.key && field.value === value);
}

function HelperTooltip() {
    return (
        <QuestionMark
            content={
                <>
                    Dynamic fields will be added to asset form and filters.
                    <span className={css.tooltipClickInfo}>(Click on the helper icon for more information)</span>
                </>
            }
            onClick={(event) => {
                event.stopPropagation();
                window.open(`${config.schibstedDocsUrl}/editorial-tooling/stream/admin/#dynamic-form`, '_blank');
            }}
        />
    );
}

type DynamicFieldsProps = {
    inheritedDynamicFields: DynamicFieldMetadata[];
    isFetching: boolean;
    error: boolean;
    formApi: UseFormReturn<CategoryData>;
};

export function DynamicFields({ inheritedDynamicFields, isFetching, error, formApi }: DynamicFieldsProps) {
    if (isFetching) {
        return <Spinner size="compact" label="Fetching metadata" />;
    }

    if (error) {
        return <InputError>Failed to fetch metadata</InputError>;
    }

    const { control, register, formState } = formApi;

    return (
        <FieldAdder
            name="dynamicFieldsMetadata"
            control={control}
            label={
                <>
                    Dynamic fields
                    <HelperTooltip />
                </>
            }
            mode="append"
            collapsible
        >
            {({ fields, remove }) =>
                fields.map((field, index) => {
                    const disabled = isFieldDisabled(inheritedDynamicFields, field);
                    const keyError = formState.errors?.dynamicFieldsMetadata?.[index]?.key?.message;
                    const valueError = formState.errors?.dynamicFieldsMetadata?.[index]?.value?.message;

                    return (
                        <div key={field.id} className={css.field}>
                            <Input
                                {...register(`dynamicFieldsMetadata.${index}.key`, {
                                    setValueAs: (value) => trimValue(value),
                                })}
                                containerClassName={css.input}
                                autoComplete="off"
                                size="small"
                                placeholder="Key"
                                error={keyError}
                            />
                            <Input
                                {...register(`dynamicFieldsMetadata.${index}.value`)}
                                containerClassName={css.input}
                                type="textarea"
                                size="small"
                                rows={4}
                                placeholder="JSON config"
                                error={valueError}
                            />
                            <Button
                                className={css.removeButton}
                                disabled={disabled}
                                size="small"
                                variant="standard"
                                type="button"
                                onClick={() => remove(index)}
                            >
                                Delete
                            </Button>
                        </div>
                    );
                })
            }
        </FieldAdder>
    );
}
