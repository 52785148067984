import config from 'config';
import Client from './client';

import getResolvedPlaylist from './playlists-client/playlists';

const playlistsClient = new Client({ baseUrl: config.playlistsApi.host });

Object.entries({
    getResolvedPlaylist,
}).forEach(([k, v]) => {
    if (typeof v === 'function') {
        playlistsClient[k] = v.bind(playlistsClient);
    }
});

export default playlistsClient;
