export const ADD_NAVIGATION_TAB = 'Video/ADD_NAVIGATION_TAB';
export const REMOVE_NAVIGATION_TAB = 'Video/REMOVE_NAVIGATION_TAB';
export const ACTIVATE_NAVIGATION_TAB = 'Video/ACTIVATE_NAVIGATION_TAB';
export const DEACTIVATE_NAVIGATION_TAB = 'Video/DEACTIVATE_NAVIGATION_TAB';

/**
 * @param {Number} id
 * @param {String} provider
 */
export const addNavigationTab = (id, provider) => ({
    type: ADD_NAVIGATION_TAB,
    id,
    provider,
});

/**
 * @param {Number} id
 * @param {String} provider
 */
export const removeNavigationTab = (id, provider) => ({
    type: REMOVE_NAVIGATION_TAB,
    id,
    provider,
});

/**
 * @param {Number} id
 * @param {String} provider
 */
export const activateNavigationTab = (id, provider) => ({
    type: ACTIVATE_NAVIGATION_TAB,
    id,
    provider,
});

/**
 * @param {Number} id
 * @param {String} provider
 */
export const deactivateNavigationTab = (id, provider) => ({
    type: DEACTIVATE_NAVIGATION_TAB,
    id,
    provider,
});
