import { get } from 'lodash';
import * as graphql from './graphql/stream-configuration.graphql';

/**
 * @param {String} url
 * @return {Promise<Playlist[]>}
 */
export async function getDurationFromUrl(url) {
    const response = await this.query(graphql.getDurationFromUrl, {
        url,
    });
    return get(response, 'playlists');
}
