import { all, put, throttle } from '@redux-saga/core/effects';
import assetFormSagas from './assetForm/sagas';
import { SEARCH_FOR } from './actionTypes';

import { fetchList } from '../asset-list/actions';

const SEARCH_FOR_THRESHOLD = 5000; // ms

export function* search() {
    yield all([put(fetchList({ list: 'main' })), put(fetchList({ list: 'linked' }))]);
}

export default [...assetFormSagas, throttle(SEARCH_FOR_THRESHOLD, SEARCH_FOR, search)];
