import { Select } from '@schibsted-svp/react-ui';
import { useMemo } from 'react';
import { type Control, Controller, type FieldPath, type FieldValues } from 'react-hook-form';

import { findSingleOption, getValueFromOption } from 'components/ui/Form/Select';
import type { ExtendedProps as SelectProps } from '@schibsted-svp/react-ui/lib/es/Select';
import style from './LogoOverlaySelector.module.scss';
import { getLogoOverlayOptions, type Option } from './helpers';
import { LogoPreviewRHF } from './LogoPreview';

type LogoOverlaySelectRHFProps<TFieldValues extends FieldValues> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    newsroom: string;
} & SelectProps<Option>;

export function LogoOverlaySelectRHF<TFieldValues extends FieldValues>({
    name,
    control,
    newsroom,
    ...props
}: LogoOverlaySelectRHFProps<TFieldValues>) {
    const options = useMemo(() => getLogoOverlayOptions(newsroom), [newsroom]);

    return (
        <div className={style.wrapper}>
            <LogoPreviewRHF name={name} control={control} className={style.logo} />
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState }) => (
                    <div className={style.select}>
                        <Select
                            {...field}
                            value={findSingleOption(options)(field.value)}
                            onChange={(value) => field.onChange(getValueFromOption(value))}
                            options={options}
                            error={fieldState.error?.message}
                            {...props}
                        />
                    </div>
                )}
            />
        </div>
    );
}
