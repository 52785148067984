import { ACCESS_TOKEN_LOADED, AUTHORIZATION_FAILED } from './actions';

const defaultState = {
    token: null,
    verifying: true,
};

function authReducer(state = defaultState, action) {
    switch (action.type) {
        case ACCESS_TOKEN_LOADED: {
            return {
                ...state,
                token: action.token,
                verifying: false,
            };
        }
        case AUTHORIZATION_FAILED: {
            return {
                ...state,
                token: null,
                verifying: false,
            };
        }
        default:
            return state;
    }
}

export default authReducer;
