import classnames from 'classnames/bind';
import { Controller } from 'react-hook-form';
import type { Validate, Control, FieldValues, FieldPath } from 'react-hook-form';
import { FaRegClock } from 'react-icons/fa';
import { mergeDateAndTime } from 'lib/time';
import { DateTimeInput, LabeledContainer } from '@schibsted-svp/react-ui';
import { normalizeValidator } from 'components/video/live/form/validators';
import css from './DateTimeMerged.module.scss';

const cln = classnames.bind(css);

interface DateTimeMergedProps {
    input: { name: string; value: number; onChange: (value: unknown) => void };
    meta: { error?: string; warning?: string };
    minDate?: Date;
    className?: string;
    size?: 'compact' | 'small' | 'big';
    disabled?: boolean;
    readOnly?: boolean;
    labeled?: boolean;
    isClearable?: boolean;
    clearToNull?: boolean;
}

type DateTimeMergedUncontrolledProps = Omit<DateTimeMergedProps, 'input' | 'meta'>;

export function DateTimeMerged({
    minDate,
    className = '',
    input: { name, value, onChange },
    meta: { error, warning },
    size = 'small',
    disabled = false,
    readOnly = false,
    labeled = false,
    isClearable = true,
    clearToNull = true,
}: DateTimeMergedProps) {
    const dateTime = value ? new Date(value * 1000) : null;
    const clearable = disabled || readOnly ? false : isClearable;

    const onDateChange = (changedDate: Date) => {
        return onChange(changedDate || !clearToNull ? mergeDateAndTime(changedDate, dateTime) : null);
    };
    const onTimeChange = (changedTime: Date) => {
        return onChange(changedTime || !clearToNull ? mergeDateAndTime(dateTime, changedTime) : null);
    };

    const dateInput = (
        <DateTimeInput
            key={`${name} ${value} date`}
            value={dateTime}
            onChange={onDateChange}
            size={size}
            isClearable={clearable}
            placeholder={clearToNull ? 'Date' : 'Today'}
            datePickerProps={{
                todayButton: 'Today',
                minDate: minDate || new Date(),
                dateFormat: 'dd.MM.yyyy',
                disabled,
                readOnly,
            }}
        />
    );

    const timeInput = (
        <DateTimeInput
            key={`${name} ${value} time`}
            value={dateTime}
            onChange={onTimeChange}
            size={size}
            isClearable={clearable}
            placeholder={clearToNull ? 'Time' : 'Now'}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selectValueOnClick
            icon={<FaRegClock />}
            mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
            createAutoCorrectedDatePipe="HH:MM"
            datePickerProps={{
                minDate: new Date(),
                timeFormat: 'HH:mm',
                dateFormat: 'HH:mm',
                showTimeSelect: true,
                disabledKeyboardNavigation: true,
                showTimeSelectOnly: true,
                timeIntervals: 5,
                disabled,
                readOnly,
            }}
        />
    );

    return (
        <div
            className={cln(
                css.container,
                `container--${size}`,
                {
                    'container--labeled': labeled,
                },
                className
            )}
        >
            {labeled ? (
                <>
                    <LabeledContainer label="Date" className={css.dateContainer}>
                        {dateInput}
                    </LabeledContainer>
                    <LabeledContainer label="Time" className={css.timeContainer}>
                        {timeInput}
                    </LabeledContainer>
                </>
            ) : (
                <>
                    <div className={css.dateInput}>{dateInput}</div>
                    <div className={css.timeInput}>{timeInput}</div>
                </>
            )}
            {(error || warning) && (
                <div
                    className={cln(css.notification, {
                        'notification--error': Boolean(error),
                        'notification--warning': Boolean(warning && !error),
                    })}
                >
                    {error || warning}
                </div>
            )}
        </div>
    );
}

const toNumber = (value: unknown) => (value ? Number(value) : null);
const toString = (value: unknown) => (value ? String(value) : null);

interface DateTimeMergedRHFProps<TFieldValues extends FieldValues> extends DateTimeMergedUncontrolledProps {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    onChange?: (value: unknown) => void;
    normalizeToString?: boolean;
    validate?: Validate<unknown, TFieldValues> | Record<string, Validate<unknown, TFieldValues>>;
}

export function DateTimeMergedRHF<TFieldValues extends FieldValues>({
    name,
    control,
    onChange = () => {},
    normalizeToString,
    validate,
    ...rest
}: DateTimeMergedRHFProps<TFieldValues>) {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ validate: normalizeValidator(validate) }}
            render={({ field, fieldState }) => (
                <DateTimeMerged
                    input={{
                        ...field,
                        value: toNumber(field.value),
                        onChange: (value) => {
                            const newValue = normalizeToString ? toString(value) : value;
                            field.onChange(newValue);
                            onChange(newValue);
                        },
                    }}
                    meta={{ error: fieldState.error?.message }}
                    {...rest}
                />
            )}
        />
    );
}
