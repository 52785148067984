import * as PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import GoogleAnalytics from 'components/GoogleAnalytics';

import { LoginScreen } from 'screens/LoginScreen/LoginScreen';

import './styles/Application.scss';

import { AssociateNewsroomScreen } from 'screens/NewsroomScreen';
import { NewsroomSelectScreen } from 'screens/NewsroomSelectScreen';
import ReactIconsDefaultsProvider from 'components/core/ReactIconsDefaultsProvider';

function Application({ store }) {
    return (
        <ReactIconsDefaultsProvider className="redux-icon">
            <Provider store={store}>
                <Router>
                    <LoginScreen>
                        <Route exact path="/">
                            <NewsroomSelectScreen />
                        </Route>
                        <Route path="/:newsroom">
                            <DndProvider backend={HTML5Backend}>
                                <AssociateNewsroomScreen />
                            </DndProvider>
                        </Route>
                        <GoogleAnalytics />
                    </LoginScreen>
                </Router>
            </Provider>
        </ReactIconsDefaultsProvider>
    );
}

Application.propTypes = {
    store: PropTypes.object.isRequired,
};

export default Application;
