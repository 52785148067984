import { memo } from 'react';
import PropTypes from 'prop-types';
import { LabeledContainer } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';
import useToggle from 'hooks/useToggle';
import LoadableContent from '../../../core/LoadableContent';
import Thumbnail from './Thumbnail';

import css from './SuggestedThumbnails.module.scss';

const cln = classnames.bind(css);

/**
 * Prepares a unique key per each thumbnail item based on asset id combined
 * with coordinates fetched from the thumbnail url's hash, like:
 * `#xywh=0,0,96,54` => `009654`
 * @param {Number|String} id
 * @param {URL} url
 * @return {string}
 */
const thumbnailKey = (id, url) => String(id) + url.replace(/\D+/g, '');

function SuggestedThumbnails({ id, className, thumbnails, loading, player = null }) {
    const [expanded, toggleExpanded] = useToggle(false);

    const handleToggleClick = () => {
        toggleExpanded();
    };

    /**
     * @param {Object} arguments
     * @param {Number} arguments.startTime
     */
    const handleThumbnailClick = ({ startTime }) => {
        if (player) {
            player.pause();
            player.seek(startTime);
        }
    };

    const thumbnailRenderer = ({ startTime, url }) => {
        return (
            <li key={thumbnailKey(id, url)} className={css.thumbnail}>
                <Thumbnail startTime={startTime} url={String(url)} onClick={handleThumbnailClick} />
            </li>
        );
    };

    const renderLinks = () => {
        if (!thumbnails.length) {
            return null;
        }

        return (
            <span className={css.links} role="button" tabIndex="0" onClick={handleToggleClick}>
                {expanded ? 'close' : 'see more'}
            </span>
        );
    };

    const renderThumbnailList = () => {
        if (!thumbnails.length) {
            return <div className={css.noImages}>No suggested images</div>;
        }
        return <ul className={cln('thumbnails', { expanded })}>{thumbnails.map(thumbnailRenderer)}</ul>;
    };

    return (
        <LabeledContainer label="Suggested images" className={cln('container', className)}>
            <LoadableContent isLoading={loading} size="compact" className={css.list}>
                {renderThumbnailList()}
            </LoadableContent>
            {renderLinks()}
        </LabeledContainer>
    );
}

SuggestedThumbnails.propTypes = {
    id: PropTypes.number.isRequired,
    className: PropTypes.string,
    thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
            startTime: PropTypes.number.isRequired,
            url: PropTypes.string.isRequired,
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    player: PropTypes.object,
};

SuggestedThumbnails.defaultProps = {
    className: undefined,
    player: null,
};

export default memo(SuggestedThumbnails);
