function createModifier(modifier, value, baseClass) {
    if (!value) {
        return '';
    }

    const suffix = value !== true ? `-${value}` : '';

    return `${baseClass}--${modifier}${suffix}`;
}

function modifiersFromObj(modifiers = {}, baseClass) {
    return Object.keys(modifiers)
        .map((modifier) => createModifier(modifier, modifiers[modifier], baseClass))
        .filter((modifier) => modifier);
}

class BEM {
    static prefix = 'af';

    props = {
        block: '',
    };

    /**
     * Creates an instance of BEM.
     *
     * @param {String} block the name of the block this instance will handle
     */
    constructor(block) {
        this.props.block = `${BEM.prefix}_${block}`;
    }

    /**
     * Generates a list of css class names for a given block name and it's modifiers.
     *
     * @param {Object.<String, Boolean|String>} [modifiers={}] an object describing modifier names and their values
     * @param {String|Array.<String>} [mixin=[]] an array of additional classes
     * @returns {String} string made of concatenated class names, separated by a single space
     *
     * @example
     * // assuming:
     * //   BEM.prefix = 'p'
     * //   new BEM({block: 'block-name'})
     * // returns 'p_block-name p_block-name--modifier p_block-name--mod-value'
     * block({modifier: true, mod: 'value'})
     */
    block(modifiers, mixin = []) {
        let modifierClasses = [];

        if (modifiers != null) {
            modifierClasses = modifiersFromObj(modifiers, this.props.block);
        }

        return [this.props.block, ...modifierClasses].concat(mixin).join(' ');
    }

    /**
     * Generates a list of css class names for a given element name and it's modifiers.
     *
     * @param {String} element the element's name
     * @param {Object.<String, Boolean|String>} [modifiers={}] an object describing modifier names and their values
     * @param {String|Array.<String>} [mixin=[]] an array of additional classes
     * @returns {String} string made of concatenated class names, separated by a single space
     *
     * @example
     * // assuming:
     * //   BEM.prefix = 'p'
     * //   new BEM({block: 'block'})
     * // returns 'p_block__element p_block__element--modifier p_block__element--mod-value'
     * element('element', {modifier: true, mod: 'value'})
     */
    element(element, modifiers, mixin = []) {
        const elementClass = `${this.props.block}__${element}`;
        let modifierClasses = [];

        if (modifiers != null) {
            modifierClasses = modifiersFromObj(modifiers, elementClass);
        }

        return [elementClass, ...modifierClasses].concat(mixin).join(' ');
    }
}

/** Helper function to connect all classes provided to a component */
export const bemjoin = (...args) => args.filter((arg) => typeof arg === 'string' && arg).join(' ');

/**
 * Helper function to get rid of constant object initialization
 * @param {String} name Block element name
 * @param {Object} [variants] Object of element variants
 * @param {[String]} [mixin] Array of additional classes
 */
export const bemgen = (name, variants, mixin) => new BEM(name).block(variants, mixin);

export default BEM;
