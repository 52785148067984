import type { Asset } from '@schibsted-svp/svp-api-types';
import { Button, Input, LabeledContainer, Spinner, Toggle } from '@schibsted-svp/react-ui';
import { useMemo, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { MdAccessTime } from 'react-icons/md';

import { formatRemainingExactTime } from 'lib/time';
import type { CutRange } from 'store/uploads/previews/slice';
import { useFetchThumbnails } from 'components/AssetForm/containers/hooks/poster';

import { useVideoPreviewEditorContext } from './VideoPreviewEditorContext';
import { CustomPreviewForm } from './CustomPreviewForm';
import { CutRangeSelector } from './CutRangeSelector';
import { ScaleSlider } from './ScaleSlider';
import css from './VideoPreviewTimeline.module.scss';
import { PreviewPlayer } from './PreviewPlayer';

interface VideoPreviewTimelineProps {
    asset: Asset;
    cutRange?: CutRange;
    onCutRangeChange: (cutRange?: CutRange) => void;
}

export function VideoPreviewTimeline({ asset, cutRange, onCutRangeChange }: VideoPreviewTimelineProps) {
    const { duration, previewPlayer } = useVideoPreviewEditorContext();
    const [displayTimeline, setDisplayTimeline] = useState(true);
    const [scale, setScale] = useState(1);
    const selectedDuration = cutRange ? cutRange.end - cutRange.begin : undefined;
    const { areThumbnailsLoading, thumbnails } = useFetchThumbnails({ asset });

    // Limit a thumbnails number to the video length if YATS generates more thumbnails than the video duration
    const scaledThumbnails = useMemo(
        () => thumbnails.filter(({ startTime }, index) => startTime <= duration && index % scale === 0),
        [duration, scale, thumbnails]
    );

    const handleClearSelectionClick = () => {
        onCutRangeChange(undefined);
        previewPlayer?.videoElement?.pause();
    };

    if (areThumbnailsLoading) {
        return (
            <div className={css.noImages}>
                <Spinner />
            </div>
        );
    }

    if (!thumbnails.length) {
        return <div className={css.noImages}>Thumbnail images not available</div>;
    }

    return (
        <>
            <div className={css.center}>
                <PreviewPlayer
                    asset={asset}
                    loop={displayTimeline}
                    startTime={cutRange?.begin}
                    endTime={cutRange?.end}
                />
            </div>
            <div className={css.toolbar}>
                <LabeledContainer label="Duration" className={css.duration}>
                    <Input
                        size="compact"
                        className={css.durationInput}
                        icon={<MdAccessTime />}
                        readOnly
                        value={selectedDuration ? formatRemainingExactTime(selectedDuration) : ''}
                        placeholder="00:00:00.000"
                    />
                </LabeledContainer>
                <LabeledContainer className={css.clear}>
                    <Button
                        type="button"
                        size="compact"
                        variant="standard"
                        disabled={!cutRange}
                        className={css.clearButton}
                        onClick={handleClearSelectionClick}
                    >
                        <BiTrash /> Clear
                    </Button>
                </LabeledContainer>
                <LabeledContainer label="Timeline" className={css.toggle}>
                    <Toggle
                        labels={{ on: 'ON', off: 'OFF' }}
                        checked={displayTimeline}
                        onChange={() => setDisplayTimeline(!displayTimeline)}
                    />
                </LabeledContainer>
                {displayTimeline ? (
                    <LabeledContainer className={css.scale}>
                        <ScaleSlider onChange={setScale} />
                    </LabeledContainer>
                ) : null}
            </div>

            <div className={css.controls}>
                {displayTimeline ? (
                    <CutRangeSelector
                        cutRange={cutRange}
                        onCutRangeChange={onCutRangeChange}
                        thumbnails={scaledThumbnails}
                    />
                ) : (
                    <CustomPreviewForm cutRange={cutRange} onChange={onCutRangeChange} />
                )}
            </div>
        </>
    );
}
