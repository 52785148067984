import { Dialog } from '@schibsted-svp/react-ui';
import { useCallback, useRef, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import type { Optional } from 'types';
import { SECOND } from 'lib/time';
import type { PlayerApi } from 'components/core/Player/playerApi';
import type { SubtitleCue, SubtitlesFormValues } from './types';
import { useGetAssetAudioUrl } from './hooks/useAsset';
import { SubtitlesPlayer } from './SubtitlesPlayer';
import { SubtitleCuesField } from './SubtitleCuesField';
import { SubtitlesTimeline } from './SubtitlesTimeline';
import css from './SubtitlesEditor.module.scss';

type SubtitlesEditorProps = {
    provider: string;
    assetId: number;
    formApi: UseFormReturn<SubtitlesFormValues>;
};

export function SubtitlesEditor({ provider, assetId, formApi }: SubtitlesEditorProps) {
    const audioUrl = useGetAssetAudioUrl({ assetId, provider });
    const [duration, setDuration] = useState(0);
    const [displayedIndex, setDisplayedIndex] = useState<Optional<number>>();
    const playerRef = useRef<Optional<PlayerApi>>();
    const { control, getValues } = formApi;

    const handleDurationChange = useCallback((nextDuration: number) => {
        setDuration(() => nextDuration);
    }, []);

    const handlePlayerReady = useCallback((player: PlayerApi) => {
        playerRef.current = player;
    }, []);

    const handleCueChange = useCallback(
        (cue: Optional<SubtitleCue>) => {
            if (!cue) {
                setDisplayedIndex(() => undefined);
                return;
            }

            const index = getValues('cues').findIndex((field) => field.id === cue.id);
            if (index === undefined) {
                return;
            }

            setDisplayedIndex(() => index);
        },
        [getValues]
    );

    const seekPlayer = useCallback((time: number) => {
        const { current: player } = playerRef;
        if (player?.videoElement) {
            // time is increased by 1ms to avoid displaying 2 cues
            // when the previous one ends at the exact time when the next one starts
            player.videoElement.currentTime = (time * SECOND + 1) / SECOND;
        }
    }, []);

    return (
        <Dialog.Section className={css.layout}>
            <SubtitlesPlayer
                assetId={assetId}
                provider={provider}
                className={css.player}
                control={control}
                onCueChange={handleCueChange}
                onDurationChange={handleDurationChange}
                onPlayerReady={handlePlayerReady}
            />
            <SubtitleCuesField
                className={css.form}
                displayedIndex={displayedIndex}
                formApi={formApi}
                seekPlayer={seekPlayer}
            />
            {duration ? (
                <SubtitlesTimeline
                    className={css.timeline}
                    duration={duration}
                    displayedIndex={displayedIndex}
                    formApi={formApi}
                    seekPlayer={seekPlayer}
                    url={audioUrl}
                />
            ) : null}
        </Dialog.Section>
    );
}
