import { RiErrorWarningLine } from 'react-icons/ri';
import { Label } from '@schibsted-svp/react-ui';

import css from './MediaLivePushInfo.module.scss';

export function MediaLivePushInfo() {
    return (
        <div className={css.push}>
            <Label>Network Input URL</Label>
            <Label>Video Key</Label>
            <div className={css.pushInfo}>
                <RiErrorWarningLine size={20} />
                <span>Accessible when live creation will be completed</span>
            </div>
        </div>
    );
}
