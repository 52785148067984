import { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';
import LiveUnpublishDialog from 'components/video/edit/LiveUnpublishDialog/LiveUnpublishDialog';
import { Button, Dropdown } from '@schibsted-svp/react-ui';
import { isLive, wasLive, isMediaLiveEncoder, isElementalEncoder } from 'models/asset';
import useToggle from 'hooks/useToggle';
import { useWatch } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { UseFormReturn } from 'react-hook-form';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import { duplicateLiveStream } from 'store/live/actions';
import type { FormValues } from '../types';
import { ScheduleDialog } from './ScheduleDialog';
import { PublishConfirmationDialog } from '../fields/PublishConfirmationDialog';
import { useAssetForm } from '../hooks/useAssetForm';
import { useSubmitState } from '../hooks/useSubmitState';
import { useScheduleState } from '../hooks/useScheduleState';
import { useConfirmationState } from '../hooks/useConfirmationState';
import { usePushSubmitLabel } from '../hooks/usePushSubmitLabel';

import css from './Submit.module.scss';

const cln = classnames.bind(css);

export const statusMap = {
    incoming: 'draft',
    readyToPublish: 'readyToPublish',
    published: 'active',
    unpublished: 'inactive',
} as const;

export function generateButtons() {
    const readyToPublish = (label = 'Save') => ({ label, action: statusMap.readyToPublish });
    const publish = (label = 'Publish') => ({ label, action: statusMap.published });
    const unpublish = (label = 'Unpublish') => ({ label, action: statusMap.unpublished });

    return {
        [statusMap.incoming]: {
            primary: readyToPublish(),
            secondary: {
                [statusMap.published]: publish(),
                [statusMap.unpublished]: unpublish(),
            },
        },
        [statusMap.readyToPublish]: {
            primary: publish(),
            secondary: {
                [statusMap.published]: readyToPublish(),
                [statusMap.unpublished]: unpublish(),
            },
        },
        [statusMap.published]: {
            primary: publish('Update'),
            secondary: {
                [statusMap.unpublished]: unpublish(),
                [statusMap.readyToPublish]: readyToPublish('Set as ready to publish'),
            },
        },
        [statusMap.unpublished]: {
            primary: publish(),
            secondary: {
                [statusMap.unpublished]: unpublish('Save'),
                [statusMap.readyToPublish]: readyToPublish('Set as ready to publish'),
            },
        },
    };
}

const buttons = generateButtons();

const shouldBackToLive = { key: 'shouldBackToLive', label: 'Back to live' };

export const Submit = memo(function Submit({
    asset,
    onSubmit,
    variant,
    formApi,
}: {
    asset: Asset;
    onSubmit: ReturnType<typeof useAssetForm>['onSubmit'];
    variant: 'standard' | 'primary';
    formApi: UseFormReturn<FormValues>;
}) {
    const { id: assetId, provider, status } = asset;
    const userPermissions = useUserPermissions();
    const dispatch = useDispatch();
    const history = useHistory();

    const [expanded, setExpanded] = useState(false);
    const [isLiveUnpublishDialogOpen, toggleIsLiveUnpublishDialogOpen] = useToggle();
    const [showConfirmation, toggleShowConfirmation] = useToggle();
    const [isScheduleDialogOpen, toggleIsScheduleDialogOpen] = useToggle();

    const formValues = useWatch({ control: formApi.control }) as FormValues;
    const { isPublishConfirmationRequired } = useConfirmationState({ asset, formValues });
    const shouldDisplayUnpublishDialog = isLive(asset) && (isMediaLiveEncoder(asset) || isElementalEncoder(asset));

    function changeStatus(newStatus: Asset['status']): void {
        if (isPublishConfirmationRequired && newStatus === statusMap.published) {
            toggleShowConfirmation();
            return;
        }

        if (shouldDisplayUnpublishDialog && newStatus === statusMap.unpublished && newStatus !== status) {
            toggleIsLiveUnpublishDialogOpen();
            return;
        }

        onSubmit(newStatus)();
    }

    const handleScheduleSubmit = ({ changeStatusToActive }: { changeStatusToActive: boolean }) => {
        if (changeStatusToActive) {
            changeStatus('active');
        } else {
            onSubmit()();
        }
        setExpanded(false);
        toggleIsScheduleDialogOpen();
    };

    const duplicateLive = () => {
        dispatch(duplicateLiveStream(provider, assetId));
        history.push(`/${provider}/live`);
    };

    const bringBackToLive = () => {
        onSubmit(buttons[status].primary.action, { shouldBackToLive: true })();
    };

    const { shouldRenderScheduleInterface } = useScheduleState({ asset, control: formApi.control });
    const { isDisabled, isProcessing, isWarning } = useSubmitState({
        provider,
        assetId,
        isSubmitting: formApi.formState.isSubmitting,
        streamType: asset.streamType,
    });
    const { addPushLabel } = usePushSubmitLabel({ asset, control: formApi.control });

    const primaryButton = buttons[status].primary;
    const secondaryButtons = Object.entries({
        ...buttons[status].secondary,
        ...(shouldRenderScheduleInterface
            ? { [primaryButton.label]: primaryButton }
            : { schedule: { label: 'Schedule' } }),
        ...(isLive(asset) && { duplicate: { label: 'Duplicate' } }),
        ...(wasLive(asset) && { [shouldBackToLive.key]: { label: shouldBackToLive.label } }),
    });

    return (
        <>
            <Dropdown
                size="big"
                type="button"
                variant={variant}
                expanded={expanded}
                onClick={() => setExpanded((prev) => !prev)}
                disabled={!userPermissions?.isUser || isProcessing || isDisabled}
                className={cln({
                    warningStandard: isWarning && variant === 'standard',
                    warningPrimary: isWarning && variant === 'primary',
                })}
                leftButtonRenderer={() => (
                    <Button
                        type="button"
                        size="big"
                        variant={variant}
                        loading={isProcessing}
                        disabled={!userPermissions?.isUser || isDisabled}
                        onClick={() => {
                            if (shouldRenderScheduleInterface) {
                                toggleIsScheduleDialogOpen();
                            } else {
                                changeStatus(primaryButton.action);
                            }
                            setExpanded(false);
                        }}
                    >
                        {shouldRenderScheduleInterface ? 'Reschedule' : addPushLabel(primaryButton.label)}
                    </Button>
                )}
            >
                {secondaryButtons.map(([key, button]) => (
                    <Dropdown.Item
                        itemKey={status}
                        key={key}
                        onClick={() => {
                            if (key === 'schedule') {
                                toggleIsScheduleDialogOpen();
                            } else if (key === 'duplicate') {
                                duplicateLive();
                            } else if (key === shouldBackToLive.key) {
                                bringBackToLive();
                            } else {
                                changeStatus(button.action);
                            }
                            setExpanded(false);
                        }}
                    >
                        {button.label}
                    </Dropdown.Item>
                ))}
            </Dropdown>
            {isPublishConfirmationRequired ? (
                <PublishConfirmationDialog
                    asset={asset}
                    shouldBeShown={showConfirmation}
                    toggle={toggleShowConfirmation}
                    confirm={() => {
                        onSubmit(statusMap.published)();
                        setExpanded(false);
                        toggleShowConfirmation();
                    }}
                    isWarningVariant={isWarning}
                    confirmationButtonText={status === statusMap.published ? 'Update' : 'Publish'}
                    formValues={formValues}
                />
            ) : null}

            <LiveUnpublishDialog
                isOpen={isLiveUnpublishDialogOpen}
                onClose={toggleIsLiveUnpublishDialogOpen}
                onSubmit={toggleIsLiveUnpublishDialogOpen}
            />
            <ScheduleDialog
                isOpen={isScheduleDialogOpen}
                onClose={toggleIsScheduleDialogOpen}
                onSubmit={handleScheduleSubmit}
                asset={asset}
                control={formApi.control}
                setValue={formApi.setValue}
            />
        </>
    );
});
