import * as PropTypes from 'prop-types';

import { Button } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';

import css from './AdminUpdateNotification.module.scss';

const cln = classnames.bind(css);

function AdminUpdateNotification({ displaySeparateWindow, onClose }) {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div
            className={displaySeparateWindow ? css['update-notification-window'] : css['update-notification']}
            onClick={refreshPage}
            data-testid="adminUpdateNotification"
        >
            <div className={css.message}>New admin version is available now!</div>
            {displaySeparateWindow && (
                <div className={css.button}>
                    <Button type="button" variant="primary" size="small" onClick={onClose}>
                        Close
                    </Button>
                </div>
            )}
            <div className={cln('message', 'message--violet')}>Click here to get the update</div>
        </div>
    );
}

AdminUpdateNotification.propTypes = {
    displaySeparateWindow: PropTypes.bool,
    onClose: PropTypes.func,
};

AdminUpdateNotification.defaultProps = {
    displaySeparateWindow: false,
    onClose: undefined,
};

export default AdminUpdateNotification;
