import moment from 'moment';

const TIME_FORMAT = 'HH:mm';
/**
 *
 * @param {Moment} dateInfo
 * @return {String}
 */
function relative(dateInfo, timeFormat = TIME_FORMAT, formats = {}) {
    const date = moment(dateInfo);

    return date.calendar(null, {
        sameDay: `[Today at] ${timeFormat}`,
        sameElse(now) {
            return `${this.isSame(moment(now), 'year') ? '' : 'YYYY '}MMM D ${timeFormat}`;
        },
        ...formats,
    });
}

/**
 * @param {Moment} dateInfo
 * @return {Boolean}
 */
function isCurrentYear(dateInfo) {
    return dateInfo.isSame(moment(), 'year');
}

export { relative, isCurrentYear };
