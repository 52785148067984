import * as Actions from './actionTypes';

export const createAsset = (provider, asset) => ({
    type: Actions.ASSET_CREATE,
    provider,
    asset,
});

export const createAssetSuccess = (asset) => ({
    type: Actions.ASSET_CREATE_SUCCESS,
    asset,
});

export const fetchAsset = ({ id, provider }) => ({
    type: Actions.ASSET_FETCH,
    id,
    provider,
});

export const fetchAssetSuccess = ({ id, asset }) => ({
    type: Actions.ASSET_FETCH_SUCCESS,
    id,
    asset,
});

export const fetchAssetError = ({ id, provider, error }) => ({
    type: Actions.ASSET_FETCH_ERROR,
    id,
    provider,
    error,
});

// asset - might be only partial changes
export const saveAsset = ({ id, provider, changes, resolve, reject }) => ({
    type: Actions.ASSET_SAVE,
    id,
    provider,
    changes,
    resolve,
    reject,
});

export const saveAssetSuccess = ({ id, provider, asset }) => ({
    type: Actions.ASSET_SAVE_SUCCESS,
    id,
    provider,
    asset,
});

export const saveAssetError = ({ id, provider, error }) => ({
    type: Actions.ASSET_SAVE_ERROR,
    id,
    provider,
    error,
});

export const changeAsset = (asset) => ({
    type: Actions.ASSET_CHANGE,
    asset,
});

export const assignPlaylist = ({ provider, id, playlistId }) => ({
    type: Actions.ASSET_ASSIGN_PLAYLIST,
    provider,
    id,
    playlistId,
});

export const assignPlaylistSuccess = ({ provider, id }) => ({
    type: Actions.ASSET_ASSIGN_PLAYLIST_SUCCESS,
    provider,
    id,
});

export const assignPlaylistError = ({ provider, id }) => ({
    type: Actions.ASSET_ASSIGN_PLAYLIST_ERROR,
    provider,
    id,
});

export const unassignPlaylist = ({ provider, id }) => ({
    type: Actions.ASSET_UNASSIGN_PLAYLIST,
    provider,
    id,
});

export const unassignPlaylistSuccess = ({ provider, id }) => ({
    type: Actions.ASSET_UNASSIGN_PLAYLIST_SUCCESS,
    provider,
    id,
});

export const unassignPlaylistError = ({ provider, id }) => ({
    type: Actions.ASSET_UNASSIGN_PLAYLIST_SUCCESS,
    provider,
    id,
});
