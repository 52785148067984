import { Label, LabeledContainer, Checkbox, Select, Divider, Spinner } from '@schibsted-svp/react-ui';
import { Controller, useWatch } from 'react-hook-form';
import type { Control, UseFormRegister } from 'react-hook-form';
import { usePodmeCategoriesQuery } from 'services/admin-bff-sdk/generated';
import { findMultipleOptions, getValuesFromOptionsArray } from 'components/ui/Form/Select';
import QuestionMark from 'components/ui/QuestionMark/QuestionMark';
import { PodmeCheckMark } from 'components/ui/PodmeCheckMark';
import { usePodmeMetadataWithPolling } from './hooks';
import type { CategoryData } from './types';
import css from './PodmeIntegration.module.scss';

type PodmeIntegrationProps = {
    provider: string;
    categoryId: number;
    control: Control<CategoryData>;
    register: UseFormRegister<CategoryData>;
};

export function PodmeIntegration({ provider, categoryId, control, register }: PodmeIntegrationProps) {
    const { data: podmeCategoriesData } = usePodmeCategoriesQuery();
    const podmeCategories = podmeCategoriesData?.podmeCategories || [];
    const categoriesOptions = podmeCategories.map(({ id, name }) => ({ value: id, label: name }));

    const { podmeMetadata, isPolling } = usePodmeMetadataWithPolling({ provider, categoryId });
    const sendToPodmeField = useWatch({ control, name: 'podmeMetadata.sendToPodme' });

    return (
        <div className={css.container}>
            <div className={css.header}>
                <Label>PODME INTEGRATION</Label>
                <QuestionMark
                    className={css.questionMark}
                    content="The integration (also via RSS) cannot be reversed once the changes have been saved. If you would like to undo it, please contact us on Slack at #svp-support."
                />
            </div>
            <Divider className={css.divider} />
            <div className={css.form}>
                <div className={css.mainCheckboxContainer}>
                    <Checkbox
                        {...register('podmeMetadata.sendToPodme')}
                        label="Send to Podme"
                        disabled={podmeMetadata?.sendToPodme}
                    />
                    {isPolling ? (
                        <Spinner size="compact" className={css.spinner} />
                    ) : (
                        podmeMetadata?.podmeId && <PodmeCheckMark podmeId={podmeMetadata.podmeId} />
                    )}
                </div>

                {sendToPodmeField && (
                    <>
                        <Checkbox
                            {...register('podmeMetadata.sendRssToPodme')}
                            label="Integrate via RSS"
                            disabled={podmeMetadata?.sendRssToPodme}
                            className={css.rssCheckbox}
                        />
                        <LabeledContainer label="PODME CATEGORY" strict className={css.category}>
                            <Controller
                                name="podmeMetadata.podmeCategories"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Select
                                        {...field}
                                        value={findMultipleOptions(categoriesOptions)(field.value)}
                                        onChange={(value) => field.onChange(getValuesFromOptionsArray(value))}
                                        options={categoriesOptions}
                                        error={fieldState.error?.message}
                                        isClearable={false}
                                        splitButton={false}
                                        size="small"
                                        isMulti
                                    />
                                )}
                            />
                        </LabeledContainer>
                    </>
                )}
            </div>
        </div>
    );
}
