import { MouseEventHandler, useEffect, useState } from 'react';
import * as ReactGA from 'react-ga';

import config from 'config';
import { Button, Dialog } from '@schibsted-svp/react-ui';
import { usePrevious } from 'hooks/usePrevious';
import Select, { findSingleOption } from 'components/ui/Form/Select/index';
import { TranscodingOptions } from '../TranscodingOptions/TranscodingOptions';

import css from './UploadConfirmationDialog.module.scss';

const { languages } = config;
const languageList = Object.keys(languages);
const EMPTY_LANGUAGE_MAP = {};

const PRESERVE_PREVIEW_DEFAULT_STATE = true;
const OVERLAY_DEFAULT_STATE = '';

type Subtitle = {
    default: boolean;
    language: string;
    url: string;
};

type SubtitleMap = Pick<Subtitle, 'language' | 'url'> | typeof EMPTY_LANGUAGE_MAP;

type UploadConfirmationDialogProps = {
    videoFile?: File;
    subtitleFiles?: File[];
    onConfirm: (subtitles: SubtitleMap, preservePreview: boolean) => void;
    onCancel: () => void;
    hasPlayback: boolean;
    provider: string;
    opened?: boolean;
};

export function UploadConfirmationDialog({
    videoFile,
    subtitleFiles = [],
    onConfirm,
    onCancel,
    hasPlayback,
    provider,
    opened = false,
}: UploadConfirmationDialogProps) {
    const [languageMap, setLanguageMap] = useState<SubtitleMap>(EMPTY_LANGUAGE_MAP);
    const [preservePreview, setPreservePreview] = useState(PRESERVE_PREVIEW_DEFAULT_STATE);
    const prevOpened = usePrevious(opened);
    const [overlay, setOverlay] = useState();

    useEffect(() => {
        if (opened && opened !== prevOpened) {
            ReactGA.modalview('confirm/file-upload');
        }
    }, [opened, prevOpened]);

    const handleLanguageSelect = (filename) => (option) => {
        setLanguageMap((value) => ({
            ...value,
            [filename]: option ? option.value : undefined,
        }));
    };

    const handleCancelClick: MouseEventHandler<HTMLElement> = () => {
        setLanguageMap(EMPTY_LANGUAGE_MAP);
        onCancel();
    };

    const handleConfirmClick: MouseEventHandler<HTMLElement> = () => {
        const value = { ...languageMap };
        setLanguageMap(EMPTY_LANGUAGE_MAP);
        onConfirm(value, preservePreview, overlay);
        setPreservePreview(PRESERVE_PREVIEW_DEFAULT_STATE);
        setOverlay(OVERLAY_DEFAULT_STATE);
    };

    const mapSubtitles = ({ filename }) => {
        const selectedLanguage = languageMap[filename];

        return languageList
            .filter((language) => language === selectedLanguage || !Object.values(languageMap).includes(language))
            .map((value) => ({
                value,
                label: languages[value],
            }));
    };

    const canConfirm = subtitleFiles.length
        ? Object.values(languageMap).filter(Boolean).length === subtitleFiles.length
        : true;

    const title = [videoFile && 'Replace', subtitleFiles.length && 'add subtitles'].filter(Boolean).join(' and ');

    return (
        <Dialog isOpen={opened} onClose={handleCancelClick} heading={title}>
            <Dialog.Section>
                {videoFile && (
                    <>
                        <h4 className={css.videoConfirmation}>
                            Are you sure you want to replace file{hasPlayback && ' and reset playback'}?
                        </h4>
                        <TranscodingOptions
                            provider={provider}
                            overlayProps={{ overlay, setOverlay }}
                            previewProps={{ preservePreview, setPreservePreview }}
                        />
                    </>
                )}

                {subtitleFiles.length > 0 && (
                    <>
                        <p>Select languages for uploaded subtitles</p>
                        <ul className={css.languageSelectors}>
                            {subtitleFiles.map(({ name: filename }) => {
                                const selectedLanguage = languageMap[filename];
                                const options = mapSubtitles({ filename });

                                return (
                                    <li key={`${filename}:${selectedLanguage}`} className={css.subtitle}>
                                        <span>{filename}</span>
                                        <Select
                                            size="small"
                                            placeholder="Language"
                                            value={findSingleOption(options)(selectedLanguage)}
                                            onChange={handleLanguageSelect(filename)}
                                            options={options}
                                            splitButton={false}
                                            isDisabled={!languageList.length}
                                            isSearchable={false}
                                            isClearable
                                            required
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
            </Dialog.Section>

            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button type="button" disabled={!canConfirm} onClick={handleConfirmClick}>
                    Confirm
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}
