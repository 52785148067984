import { Controller, type Control, type FieldValues, type FieldPath } from 'react-hook-form';
import { Select } from '@schibsted-svp/react-ui';
import { findSingleOption } from 'components/ui/Form/Select';

const ageLimits = {
    0: 'All',
    6: '6 years',
    9: '9 years',
    12: '12 years',
    15: '15 years',
    18: '18 years',
};

export const options = Object.entries(ageLimits).map(([value, label]) => ({
    value: Number(value),
    label,
}));

type AgeLimitSelectProps<TFieldValues extends FieldValues> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
};

export function AgeLimitSelect<TFieldValues extends FieldValues>({ name, control }: AgeLimitSelectProps<TFieldValues>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Select
                    {...field}
                    value={findSingleOption(options)(field.value)}
                    onChange={(value) => field.onChange(value ? value.value : null)}
                    options={options}
                    isClearable
                    placeholder="Not applicable"
                    splitButton={false}
                    size="small"
                />
            )}
        />
    );
}
