import classnames from 'classnames/bind';
import { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Spinner } from '@schibsted-svp/react-ui';
import css from './EmbedPreviewIframe.module.scss';

const cln = classnames.bind(css);

function EmbedPreviewIframe(props, ref) {
    const [isLoading, setIsLoading] = useState(false);
    const iframeRef = useRef();
    const timeoutRef = useRef();

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current);
    }, []);

    useImperativeHandle(ref, () => ({
        refresh: (src) => {
            setIsLoading(true);
            iframeRef.current.src = src;
        },
    }));

    const handleIframeLoad = () => {
        timeoutRef.current = setTimeout(() => setIsLoading(false), 150);
    };

    return (
        <div className={cln('EmbedPreviewIframe')} {...props}>
            <iframe
                title="previewIframe"
                className={cln('EmbedPreviewIframe__iframe', {
                    'EmbedPreviewIframe__iframe--hidden': isLoading,
                })}
                ref={iframeRef}
                onLoad={handleIframeLoad}
                allowFullScreen
            />
            {isLoading && <Spinner />}
        </div>
    );
}

export default forwardRef(EmbedPreviewIframe);
