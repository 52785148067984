import moment from 'moment';

// https://github.com/silviapfeiffer/silviapfeiffer.github.io/blob/master/index.html
export function convertSrtCue(srtCue = '') {
    const srtCueSplit = srtCue.split(/\n/);

    if (!srtCueSplit[0].match(/^\d+$/)) {
        throw new Error('Cue has not a number');
    }

    // concatenate muilt-line string separated in array into one
    while (srtCueSplit.length > 3) {
        for (let i = 3; i < srtCueSplit.length; i += 1) {
            srtCueSplit[2] += `\n${srtCueSplit[i]}`;
        }
        srtCueSplit.splice(3, srtCueSplit.length - 3);
    }

    let vttCue = `${srtCueSplit[0]}\n`;
    const timestamp = srtCueSplit[1].match(/(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/);
    if (!timestamp) {
        throw new Error('Cue has unrecognized timestring');
    }

    vttCue += `${timestamp[1]}:${timestamp[2]}:${timestamp[3]}.${timestamp[4]} --> ${timestamp[5]}:${timestamp[6]}:${timestamp[7]}.${timestamp[8]}\n`;
    vttCue += srtCueSplit[2];

    return vttCue;
}

export function srt2webvtt(data = '') {
    if (!data) {
        throw new Error('Empty file');
    }
    // remove dos newlines & trim white space start and end
    const srt = data.replace(/\r+/g, '').trim();
    const cueList = srt.split('\n\n');
    return `WEBVTT\n\n${cueList.map(convertSrtCue).join('\n\n')}\n`;
}

export function parseThumbnailsWebVTT(data = '') {
    // remove dos newlines & trim white space start and end
    const vtt = data.replace(/\r+/g, '').trim();
    // split entries and omit WebVTT header
    const cueList = vtt.split('\n\n').slice(1);

    return cueList
        .map((vttCue) => {
            try {
                const vttCueSplit = vttCue.split(/\n/);
                const vttTimecodeSplit = vttCueSplit[0].match(
                    /(?:(\d+):)?(\d+):(\d+)(?:.(\d+))\s*--?>\s*(?:(\d+):)?(\d+):(\d+)(?:.(\d+))/
                );
                return {
                    url: vttCueSplit[1].trim(),
                    startTime: moment
                        .duration({
                            milliseconds: vttTimecodeSplit[4],
                            seconds: vttTimecodeSplit[3],
                            minutes: vttTimecodeSplit[2],
                            hours: vttTimecodeSplit[1],
                        })
                        .asSeconds(),
                };
            } catch {
                return null;
            }
        })
        .filter(Boolean);
}
