import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { getFormattedTime } from 'lib/time';
import BEM from 'lib/bem';
import './FormattedTime.scss';

const bem = new BEM('FormattedTime');

export default class FormattedTime extends Component {
    static propTypes = {
        seconds: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
        onClick: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        seconds: null,
        onClick: () => null,
        className: '',
    };

    handleClick = (event) => {
        event.preventDefault();
        const { onClick, seconds } = this.props;
        onClick(seconds);
    };

    render() {
        const { seconds, onClick, className, ...rest } = this.props;
        const props = {
            className: [bem.block(), className].join(' '),
            ...rest,
        };

        if (onClick) {
            props.role = 'button';
            props.tabIndex = 0;
            props.onClick = this.handleClick;
        }

        return <span {...props}>{getFormattedTime({ seconds })}</span>;
    }
}
