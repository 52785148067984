import { get } from 'lodash';

/**
 * @param {Object} asset
 * @return {Boolean}
 */
export function isAssetStreamConfigurationAvailable(asset) {
    const url = get(asset, 'streamUrls.hls', '');
    return url !== '' && url !== 'https://admin.svp.schibsted.io/placeholder.m3u8';
}
