export const FETCH_THUMBNAILS = 'Model/FETCH_THUMBNAILS';
export const FETCH_THUMBNAILS_SUCCESS = 'Model/FETCH_THUMBNAILS_SUCCESS';
export const FETCH_THUMBNAILS_ERROR = 'Model/FETCH_THUMBNAILS_ERROR';

/**
 * @param {String} provider
 * @param {Number} id
 */
export const fetchThumbnails = (provider, id) => ({
    type: FETCH_THUMBNAILS,
    provider,
    id,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {Array<Thumbnail>} thumbnails
 */
export const fetchThumbnailsSuccess = (provider, id, thumbnails) => ({
    type: FETCH_THUMBNAILS_SUCCESS,
    provider,
    id,
    thumbnails,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {String} error
 */
export const fetchThumbnailsError = (provider, id, error) => ({
    type: FETCH_THUMBNAILS_ERROR,
    provider,
    id,
    error,
});
