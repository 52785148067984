import BEM from 'lib/bem';

import * as PropTypes from 'prop-types';

import { selectNewsroomLinkedProviders } from 'services/svp-api-rtk/providers';
import { useSelector } from 'react-redux';
import NewsroomTab from './NewsroomTab';
import { VideoTabs } from './VideoTabs';
import SearchTab from './SearchTab';

import './VideoNavigation.scss';

const videoNavigationContainer = new BEM('video-navigation-container');
const videoNavigation = new BEM('video-navigation');

function VideoNavigation({ newsroom }) {
    const linkedProviders = useSelector((state) => selectNewsroomLinkedProviders(state, newsroom));

    return (
        <div className={videoNavigationContainer.block()}>
            <div className={videoNavigation.block()}>
                <NewsroomTab newsroom={newsroom} />
                {linkedProviders?.length ? <SearchTab /> : null}
            </div>
            <VideoTabs />
            <div className={videoNavigation.block({ fill: true })} />
        </div>
    );
}

VideoNavigation.propTypes = {
    newsroom: PropTypes.string.isRequired,
};

export default VideoNavigation;
