import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSortedAndLimitedLiveNotifications, hasLiveAssetFailed } from 'store/live/selectors';
import { CollapsibleContent } from '@schibsted-svp/react-ui';
import PoorMetadataCircleIcon from 'components/icons/PoorMetadataCircleIcon';
import config from 'config';
import SingleNotification from './SingleNotification';

import css from './LiveNotifications.module.scss';

const LiveNotificationsHeader = (
    <div className={css.headerContainer}>
        <PoorMetadataCircleIcon className={css.icon} />
        <span className={css.header}>NOTIFICATIONS</span>
    </div>
);

function LiveNotifications({ newsroom, assetId }) {
    const liveNotifications = useSelector((state) =>
        getSortedAndLimitedLiveNotifications(state, {
            newsroom,
            assetId,
            limit: config.live.notifications.ASSET_VIEW_DISPLAY_LIMIT,
        })
    );
    const shouldBeExpanded = useSelector((state) => hasLiveAssetFailed(state, { newsroom, assetId }));

    if (!liveNotifications) {
        return <div>Live notifications not found</div>;
    }

    return (
        <CollapsibleContent
            customHeader={LiveNotificationsHeader}
            className={css.container}
            startExpanded={shouldBeExpanded}
        >
            <section className={css.notifications}>
                {liveNotifications.map(({ timestamp, level, code, message }) => (
                    <SingleNotification
                        key={`notification:${code}:${timestamp}:${message}`}
                        timestamp={timestamp}
                        level={level}
                        code={code}
                        message={message}
                    />
                ))}
            </section>
        </CollapsibleContent>
    );
}

LiveNotifications.propTypes = {
    newsroom: PropTypes.string.isRequired,
    assetId: PropTypes.number.isRequired,
};

export default memo(LiveNotifications);
