import { AudioChannelsMixType } from 'components/video/live/form/AudioConfiguration';
import { get, pick } from 'lodash';
import { LiveStreamProtocol, LiveStreamSource, MediaLiveInputType } from './contants';
import {
    CancelAd,
    CreateLiveStream,
    EncoderInputPreview,
    GetLiveEncoders,
    InsertAd,
    StartLiveStream,
    StopLiveStream,
    UnpublishLiveAsset,
    UpdateLiveStream,
} from './graphql/live.graphql';

/**
 * @param {Object<string, *>} values
 * @returns {'medialive'|'external'|'network'|'sdi'}
 */
export const getSetupSourceType = (values) => {
    const { backupEncoderId, backupNetworkUrl, encoderId, externalUrl, inputId, networkUrl, inputType } = values;
    if (inputType) {
        return 'medialive';
    }
    if (externalUrl) {
        return 'external';
    }
    if ((encoderId && networkUrl) || (backupEncoderId && backupNetworkUrl && !(encoderId && inputId))) {
        return 'network';
    }
    return 'sdi';
};

/**
 * @param {String} url
 * @return {Object}
 */
const prepareExternalSourceSetup = (url) => ({
    type: LiveStreamSource.EXTERNAL,
    external: { url },
});

/**
 * @param {String} encoderId
 * @param {Number} inputId
 * @return {Object}
 */
const prepareSDISourceSetup = (encoderId, inputId) => ({
    type: LiveStreamSource.SDI,
    sdi: { encoderId, inputId },
});

/**
 * @param {String} encoderId
 * @param {String} url
 * @return {Object}
 */
const prepareNetworkSourceSetup = (encoderId, url) => ({
    type: LiveStreamSource.NETWORK,
    network: { encoderId, url },
});

const mapValuesToIdGainTuples = (values, requiredChannels) => {
    return values.slice(0, requiredChannels + 1).map((_, index) => ({
        channelId: index,
        gain: values[index] ? 0 : -60,
    }));
};

export function calculateMinChannelsRequired(leftChannelValues, rightChannelValues) {
    return Math.max(leftChannelValues.lastIndexOf(true), rightChannelValues.lastIndexOf(true));
}

export function prepareAudioChannelsMixSetup({
    type,
    values: { leftChannel: leftChannelValues, rightChannel: rightChannelValues },
}) {
    if (type === AudioChannelsMixType.STEREO) {
        return undefined;
    }
    const requiredChannelsCount = calculateMinChannelsRequired(leftChannelValues, rightChannelValues);
    return {
        audioChannelsMix: {
            stereoMix: {
                leftChannel: mapValuesToIdGainTuples(leftChannelValues, requiredChannelsCount),
                rightChannel: mapValuesToIdGainTuples(rightChannelValues, requiredChannelsCount),
            },
        },
    };
}

/**
 * @param {Object} [options]
 * @param {Number} [options.dvrLength]
 * @param {String[]} [options.protections]
 * @param {String} [options.profile]
 * @return {Object}
 */
const prepareHlsProtocolSetup = ({ dvrLength, protections, profile, audioChannelsMix } = {}) => {
    return {
        type: LiveStreamProtocol.HLS,
        ...((dvrLength != null || protections || profile || audioChannelsMix) && {
            hls: {
                ...(dvrLength != null && { dvrLength }),
                ...(protections && { protections }),
                ...(profile && { profile }),
                ...(audioChannelsMix && prepareAudioChannelsMixSetup(audioChannelsMix)),
            },
        }),
    };
};

/**
 * @param {Object} data
 * @param {String} data.externalUrl
 * @return {Object}
 */
const prepareExternalSetup = ({ externalUrl }) => ({
    source: prepareExternalSourceSetup(externalUrl),
});

const prepareMediaLiveSetup = (data) => {
    const inputType = data.inputType.toUpperCase();

    return {
        source: {
            type: 'MEDIALIVE',
            medialive: {
                type: data.inputId ? MediaLiveInputType.RESERVED : inputType,
                url: data['pull-source'],
                ...(data.inputId && {
                    reserved: {
                        inputId: data.inputId,
                    },
                }),
            },
        },
        protocol: prepareHlsProtocolSetup(data),
        ...prepareCuttingroomDestination(data),
    };
};

const prepareCuttingroomDestination = (data) => {
    return data.cuttingroomChannel ? { destination: JSON.parse(data.cuttingroomChannel) } : {};
};

/**
 * @param {Object} data
 * @param {String} [data.encoderId]
 * @param {Number} [data.inputId]
 * @param {String} [data.backupEncoderId]
 * @param {Number} [data.backupInputId]
 * @return {Object}
 */
const prepareSDISetup = ({ encoderId, inputId, backupEncoderId, backupInputId, ...rest }) => ({
    protocol: prepareHlsProtocolSetup(rest),
    ...(encoderId &&
        inputId && {
            source: prepareSDISourceSetup(encoderId, inputId),
        }),
    ...(backupEncoderId &&
        backupInputId && {
            backup: prepareSDISourceSetup(backupEncoderId, backupInputId),
        }),
    ...prepareCuttingroomDestination(rest),
});

/**
 * @param {Object} data
 * @param {String} [data.encoderId]
 * @param {String} [data.networkUrl]
 * @param {String} [data.backupEncoderId]
 * @param {String} [data.backupNetworkUrl]
 * @return {Object}
 */
const prepareNetworkSetup = ({ encoderId, networkUrl, backupEncoderId, backupNetworkUrl, ...rest }) => ({
    protocol: prepareHlsProtocolSetup(rest),
    ...(encoderId &&
        networkUrl && {
            source: prepareNetworkSourceSetup(encoderId, networkUrl),
        }),
    ...(backupEncoderId &&
        backupNetworkUrl && {
            backup: prepareNetworkSourceSetup(backupEncoderId, backupNetworkUrl),
        }),
    ...prepareCuttingroomDestination(rest),
});

/**
 * @param {Object} data
 * @param {String} data.title
 * @param {String} [data.description]
 * @param {Number} data.categoryId
 * @param {String} [data.createdBy]
 * @param {String} [data.access]
 * @return {Object}
 */
export const prepareMetadataInput = (data) => pick(data, 'title', 'description', 'categoryId', 'createdBy', 'access');

/**
 * @param {Object} data
 * @param {String} [data.editedBy]
 * @param {String} [data.access]
 * @return {Object}
 */
export const prepareMetadataInputForUpdate = (data) => pick(data, 'editedBy', 'access');

/**
 * External encoder doesn't handle schedule
 *
 * @param {Object} data
 * @param {Number} [data.startTime]
 * @param {Number} [data.encoderStartTime]
 * @param {Number} [data.encoderDuration]
 * @return {Object | undefined}
 */
export const prepareScheduleInput = ({ startTime, encoderDuration, encoderStartTime, ...data }) => {
    if (getSetupSourceType(data) === 'external' || (!startTime && !encoderStartTime && !encoderDuration)) {
        return undefined;
    }
    return {
        ...(startTime && {
            asset: { startTime },
        }),
        ...((encoderStartTime || encoderDuration) && {
            encoder: {
                ...(encoderStartTime && { startTime: encoderStartTime }),
                ...(encoderDuration && { duration: encoderDuration }),
            },
        }),
    };
};

/**
 * @param {Object} data
 * @return {Object}
 */
export const prepareSetupInput = (data) => {
    switch (getSetupSourceType(data)) {
        case 'medialive': {
            return prepareMediaLiveSetup(data);
        }
        case 'external': {
            return prepareExternalSetup(data);
        }
        case 'network': {
            return prepareNetworkSetup(data);
        }
        case 'sdi': {
            return prepareSDISetup(data);
        }
        default:
            throw new Error('Sth went wrong');
    }
};

/**
 * @param {Object} data
 * @return {Object}
 */
export function prepareLogoOverlayInput(data) {
    if (!data.overlay) {
        return undefined;
    }
    return { url: data.overlay };
}

/**
 * @param {String} newsroom
 * @param {Object} values
 * @returns {Promise<Object>}
 */
export async function createLiveStream({ newsroom, values }) {
    const response = await this.query(CreateLiveStream, {
        newsroom,
        metadata: prepareMetadataInput(values),
        schedule: prepareScheduleInput(values),
        livePreview: get(values, 'livePreview', false),
        vertical: get(values, 'vertical', false),
        setup: prepareSetupInput(values),
        overlay: prepareLogoOverlayInput(values),
        deinterlace: get(values, 'deinterlace', false),
        audioNormalization: get(values, 'audioNormalization', false),
    });
    return get(response, 'createLiveStream', {});
}

/**
 * @param {Object} params
 * @param {String} params.newsroom
 * @returns {Promise<Object>}
 */
export async function fetchLiveEncoders({ newsroom }) {
    return this.query(GetLiveEncoders, {
        newsroom,
    });
}

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} editedBy
 * @returns {Promise<Boolean>}
 */
export async function startLiveStream({ newsroom, assetId, editedBy }) {
    const response = await this.query(StartLiveStream, {
        newsroom,
        assetId,
        editedBy,
    });
    return get(response, 'startLiveStream');
}

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} editedBy
 * @returns {Promise<Boolean>}
 */
export async function stopLiveStream({ newsroom, assetId, editedBy }) {
    const response = await this.query(StopLiveStream, {
        newsroom,
        assetId,
        editedBy,
    });
    return get(response, 'stopLiveStream');
}

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {Object} values
 * @param {String} editedBy
 * @returns {Promise<Boolean>}
 */
export async function updateLiveStream({ newsroom, assetId, values, editedBy }) {
    const response = await this.query(UpdateLiveStream, {
        newsroom,
        assetId,
        schedule: prepareScheduleInput(values),
        metadata: {
            editedBy,
        },
        overlay: prepareLogoOverlayInput(values),
    });
    return get(response, 'updateLiveStream');
}

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {Number} duration
 * @param {Number} offset
 * @returns {Promise<Boolean>}
 */
export async function insertAd({ newsroom, assetId, duration, offset }) {
    const response = await this.query(InsertAd, {
        newsroom,
        assetId,
        duration,
        offset,
    });
    return get(response, 'insertAd');
}

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Promise<Boolean>}
 */
export async function cancelAd({ newsroom, assetId }) {
    const response = await this.query(CancelAd, {
        newsroom,
        assetId,
    });
    return get(response, 'cancelAd');
}

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} editedBy
 * @returns {Promise<Boolean>}
 */
export async function unpublishLiveAsset({ newsroom, assetId, editedBy }) {
    const response = await this.query(UnpublishLiveAsset, {
        newsroom,
        assetId,
        editedBy,
    });
    return get(response, 'unpublishLiveAsset');
}

export async function fetchEncoderInputImage({ encoderId, inputId, width = 640, height = 360 }) {
    const response = await this.query(EncoderInputPreview, {
        encoderId,
        inputId,
        width,
        height,
    });
    return get(response, 'encoderInputPreview');
}
