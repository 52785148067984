import { forwardRef, PropsWithChildren } from 'react';
import type { TimelineRef } from './hooks/useTimelineRef';
import { TimelineContainer } from './TimelineContainer';
import { TimelineStateProvider } from './TimelineContext';

export type TimelineProps = PropsWithChildren<{
    className?: string;
    /** Asset duration in seconds */
    duration: number;
    /** A number of pixels that represents a second on the timeline */
    pixelsPerSecond: number;
}>;

export const Timeline = forwardRef<TimelineRef, TimelineProps>(function Timeline(
    { children, className, duration, pixelsPerSecond },
    ref
) {
    return (
        <TimelineStateProvider duration={duration} pixelsPerSecond={pixelsPerSecond}>
            <TimelineContainer className={className} ref={ref}>
                {children}
            </TimelineContainer>
        </TimelineStateProvider>
    );
});
