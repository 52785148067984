import { Button, Dialog } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { AssetChecklist } from 'components/asset-checklist/AssetChecklist';
import type { FormValues } from '../types';
import css from './PublishConfirmationDialog.module.scss';

const cln = classnames.bind(css);

type PublishConfirmationDialogProps = {
    asset: Asset;
    shouldBeShown: boolean;
    toggle: () => void;
    confirm: () => void;
    isWarningVariant: boolean;
    confirmationButtonText: 'Update' | 'Publish';
    formValues: FormValues;
};

export function PublishConfirmationDialog({
    asset,
    shouldBeShown,
    toggle,
    confirm,
    isWarningVariant,
    confirmationButtonText,
    formValues,
}: PublishConfirmationDialogProps) {
    return (
        <Dialog isOpen={shouldBeShown} onClose={toggle} heading={`${asset.assetType} checklist`}>
            <Dialog.Section>
                <p>The quality of metadata is quite poor, you can improve this by filling in the following metadata:</p>
                <AssetChecklist
                    assetId={asset.id}
                    provider={asset.provider}
                    draftValues={formValues}
                    assetType={asset.assetType}
                />
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button data-test="CancelButton" type="button" variant="standard" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    data-testid="ConfirmButton"
                    type="button"
                    onClick={confirm}
                    className={cln({ warning: isWarningVariant })}
                >
                    {confirmationButtonText} anyway
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}
