import { ReactNode } from 'react';
import { Controller, Validate } from 'react-hook-form';
import { Select, LabeledContainer } from '@schibsted-svp/react-ui';
import { EncoderPreview } from './EncoderPreview';
import { normalizeValidator } from '../validators';
import { useEncoderInputFormContext } from './hooks';
import type { EncoderInputOption, EncoderInputFormData } from './types';

import css from './EncoderInputForm.module.scss';

const customOptionStyles = (provided: Record<string, string>, { data }: { data: EncoderInputOption }) => {
    if (data.running || data.pending) {
        return {
            ...provided,
            color: '#dfc552 !important',
            fontStyle: 'italic',
        };
    }
    return provided;
};

interface EncoderInputSelectorProps {
    name: 'source' | 'backup';
    label: string;
    options: EncoderInputOption[];
    encoderId: string;
    inputId?: number;
    validate?:
        | Record<string, Validate<EncoderInputOption, EncoderInputFormData>>
        | Validate<EncoderInputOption, EncoderInputFormData>;
    children?: ReactNode;
}

export function EncoderInputSelector(props: EncoderInputSelectorProps) {
    const { name, label, options, encoderId, inputId, validate, children } = props;

    const { formApi } = useEncoderInputFormContext();
    const { control } = formApi;

    return (
        <>
            <div className={css.inputPreview}>
                {encoderId ? <EncoderPreview encoderId={encoderId} inputId={inputId} /> : null}
            </div>

            <LabeledContainer label={label}>
                <Controller
                    name={name}
                    control={control}
                    rules={{ validate: normalizeValidator(validate) }}
                    shouldUnregister
                    render={({ field, fieldState }) => (
                        <Select
                            {...field}
                            options={options}
                            error={fieldState.error?.message}
                            placeholder={label}
                            isLoading={!options}
                            styles={{ option: customOptionStyles }}
                            isClearable
                        />
                    )}
                />
                {children}
            </LabeledContainer>
        </>
    );
}
