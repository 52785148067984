import { useState, memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroller';
import { Button, Spinner, Dropdown } from '@schibsted-svp/react-ui';

import ScreenContainer from 'components/ui/ScreenContainer';
import Playlist from 'components/Playlists';
import EmptyResult from 'components/Playlists/EmptyResult';
import ErrorResult from 'components/Playlists/ErrorResult';
import PlaylistsScreenForm from './PlaylistsScreenForm';
import { mergePlaylistLabelsIds, LABELS, setPreferredPlaylistType, getPreferredPlaylistType } from './utils';

import css from './PlaylistsScreen.module.scss';

function PlaylistsScreen({
    provider,
    fetchRegularPlaylistsList,
    fetchPlaylistOfPlaylistsList,
    list: { isFetching, error, items, hasNext },
    isUserProvider,
}) {
    const [searchValue, setSearchValue] = useState('');
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [selectedType, setSelectedType] = useState(getPreferredPlaylistType());

    const fetchPlaylistsList = useCallback(
        (more = false) => {
            const mergedLabelsIds = mergePlaylistLabelsIds(selectedLabels, selectedType);

            if (selectedType !== LABELS.POP) {
                fetchRegularPlaylistsList({ name: searchValue, labels: mergedLabelsIds, more });
            } else {
                fetchPlaylistOfPlaylistsList({ name: searchValue, labels: mergedLabelsIds, more });
            }
        },
        [fetchPlaylistOfPlaylistsList, fetchRegularPlaylistsList, searchValue, selectedLabels, selectedType]
    );

    const handleSearchChange = ({ target: { value } }) => setSearchValue(value);
    const handleSearchSubmit = (event) => {
        event.preventDefault();

        fetchPlaylistsList();
    };

    const onLabelChange = (newLabel) => {
        setSelectedLabels(newLabel);
    };

    const onTypeChange = (value) => {
        setSelectedType(value);
        setPreferredPlaylistType(value);
    };

    useEffect(() => {
        fetchPlaylistsList();
    }, [fetchPlaylistsList, selectedLabels, selectedType]);

    const loadMore = () => {
        if (isFetching || !hasNext) {
            return;
        }
        fetchPlaylistsList(true);
    };

    const onRetry = () => {
        fetchPlaylistsList();
    };

    const playlistsCount = items.length;
    const isFetchError = error && playlistsCount === 0;
    const isFetched = playlistsCount || (!isFetching && !isFetchError);
    return (
        <ScreenContainer className={css.container}>
            <>
                <header className={css.header}>
                    <h1 className={css.heading}>Playlists</h1>
                    <PlaylistsScreenForm
                        handleSearchSubmit={handleSearchSubmit}
                        handleSearchChange={handleSearchChange}
                        selectedLabels={selectedLabels}
                        onLabelChange={onLabelChange}
                        onTypeChange={onTypeChange}
                        selectedType={selectedType}
                        searchValue={searchValue}
                    />

                    <Dropdown
                        hideOnOutsideClick
                        mainButtonRenderer={(onClick) => (
                            <Button type="button" disabled={!isUserProvider} onClick={onClick} size="big">
                                Create playlist of
                            </Button>
                        )}
                    >
                        <Link to={`/${provider}/playlists/add`}>
                            <Dropdown.Item itemKey="videos" onClick={() => setPreferredPlaylistType(LABELS.VIDEO)}>
                                videos
                            </Dropdown.Item>
                            <Dropdown.Item itemKey="audio" onClick={() => setPreferredPlaylistType(LABELS.AUDIO)}>
                                audio
                            </Dropdown.Item>
                            <Dropdown.Item itemKey="playlists" onClick={() => setPreferredPlaylistType(LABELS.POP)}>
                                playlists
                            </Dropdown.Item>
                        </Link>
                    </Dropdown>
                </header>
                {isFetched && (
                    <InfiniteScroll loadMore={loadMore} threshold={200} hasMore={hasNext} initialLoad={false}>
                        {items.map((playlist) => (
                            <Playlist
                                key={playlist}
                                id={playlist}
                                provider={provider}
                                hideDeleteButton={!isUserProvider}
                            />
                        ))}
                        {isFetching && hasNext && (
                            <Spinner containerClassName={css.spinner} label="Loading..." size="small" />
                        )}
                    </InfiniteScroll>
                )}
            </>
            {isFetchError && <ErrorResult onRetry={onRetry} />}
            {isFetching && playlistsCount === 0 && <Spinner className={css.spinner} />}
            {!isFetchError && !isFetching && playlistsCount === 0 && <EmptyResult />}
        </ScreenContainer>
    );
}
export default memo(PlaylistsScreen);

PlaylistsScreen.propTypes = {
    provider: PropTypes.string.isRequired,
    fetchRegularPlaylistsList: PropTypes.func.isRequired,
    fetchPlaylistOfPlaylistsList: PropTypes.func.isRequired,
    list: PropTypes.shape({
        isFetching: PropTypes.bool,
        error: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        hasNext: PropTypes.bool.isRequired,
    }).isRequired,
    isUserProvider: PropTypes.bool.isRequired,
};
