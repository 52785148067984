import { useWatch } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import type { Asset } from '@schibsted-svp/svp-api-types';
import config from 'config';
import { isLive } from 'models/asset';
import { canRenderAssetQualityForNewsroom } from 'services/asset-quality';
import { CollapsibleContent, Divider } from '@schibsted-svp/react-ui';
import { AssetChecklist } from 'components/asset-checklist/AssetChecklist';
import type { FormValues } from '../types';

const { placements } = config.assetQuality;

type AssetChecklistFieldProps = {
    asset: Asset;
    control: Control<FormValues>;
};

export function AssetChecklistField({ asset, control }: AssetChecklistFieldProps) {
    const formValues = useWatch({ control });

    const { newsroom: newsroomParam } = useParams();
    const assetQualityPlacement = isLive(asset) ? placements.publishingLiveAsset : placements.publishingAsset;
    const shouldRender = canRenderAssetQualityForNewsroom(newsroomParam, assetQualityPlacement, asset.assetType);

    if (!shouldRender) {
        return null;
    }

    return (
        <>
            <CollapsibleContent label="Quality Checklist" startExpanded>
                <AssetChecklist
                    assetId={asset.id}
                    provider={asset.provider}
                    draftValues={formValues}
                    includeValid
                    assetType={asset.assetType}
                />
            </CollapsibleContent>
            <Divider margin="-15px 0 24px" />
        </>
    );
}
