import { uniqBy } from 'lodash';

export const STATIC_VIDEOS_SEARCH = 'Playlists/STATIC_VIDEOS_SEARCH';
export const STATIC_VIDEOS_SEARCH_MORE = 'Playlists/STATIC_VIDEOS_SEARCH_MORE';
export const STATIC_ITEMS_FETCH_SUCCESS = 'Playlists/STATIC_ITEMS_FETCH_SUCCESS';
export const STATIC_ITEMS_FETCH_ERROR = 'Playlists/STATIC_ITEMS_FETCH_ERROR';
export const STATIC_ITEMS_CLEAR = 'Playlists/STATIC_ITEMS_CLEAR';
export const STATIC_PLAYLISTS_FETCH_SUCCESS = 'Playlists/STATIC_PLAYLISTS_FETCH_SUCCESS';
export const PLAYLISTS_SEARCH = 'Playlists/PLAYLISTS_SEARCH';

export const playlistsSearch = ({ searchTerm, providers, originProvider }) => ({
    type: PLAYLISTS_SEARCH,
    payload: {
        searchTerm,
        providers,
        originProvider,
    },
});

export const staticVideosSearch = ({ searchTerm, provider, searchQueryProviders, parameters }) => ({
    type: STATIC_VIDEOS_SEARCH,
    payload: {
        searchTerm,
        provider,
        searchQueryProviders,
        parameters,
    },
});

export const staticVideosSearchMore = () => ({
    type: STATIC_VIDEOS_SEARCH_MORE,
});

export const staticItemsFetchSuccess = ({ assets, nextUrl, more }) => ({
    type: STATIC_ITEMS_FETCH_SUCCESS,
    payload: {
        assets,
        items: assets.map((asset) => ({
            id: asset.id,
            provider: asset.provider,
            category: asset.category.title,
            duration: asset.duration,
        })),
        nextUrl,
        more,
    },
});

export const staticItemsFetchError = () => ({
    type: STATIC_ITEMS_FETCH_ERROR,
    error: true,
});

export const staticItemsClear = () => ({
    type: STATIC_ITEMS_CLEAR,
});

export const staticPlaylistsFetchSuccess = ({ playlists, provider, providers }) => ({
    type: STATIC_PLAYLISTS_FETCH_SUCCESS,
    payload: {
        playlists,
        items: playlists.map((playlist) => ({
            id: playlist.id,
            provider: playlist.provider,
            hasNext: playlist.hasNext,
            page: playlist.page || 1,
        })),
        provider,
        more: true,
        providers,
    },
});

export default function reducer(state = { query: '', isFetching: false, items: [], error: false }, action) {
    switch (action.type) {
        case STATIC_VIDEOS_SEARCH: {
            return {
                ...state,
                items: [],
                query: action.payload.searchTerm,
                isFetching: true,
                error: false,
            };
        }
        case PLAYLISTS_SEARCH: {
            const { searchTerm, providers } = action.payload;
            const shouldClearStore =
                searchTerm !== state.query ||
                (providers?.length === 1 && state.providers?.length === 1 && providers[0] !== state.providers[0]) ||
                providers?.length !== state.providers?.length;

            return {
                ...state,
                items: shouldClearStore ? [] : state?.items,

                query: searchTerm,
                isFetching: true,
                error: false,
                providers,
            };
        }
        case STATIC_ITEMS_FETCH_SUCCESS:
        case STATIC_PLAYLISTS_FETCH_SUCCESS: {
            const items = uniqBy([...(state?.items || []), ...action.payload.items], 'id');
            const { nextUrl } = action.payload;

            return {
                ...state,
                items,
                isFetching: false,
                nextUrl,
                ...(!nextUrl && { hasNext: items.some((item) => item.hasNext) }),
            };
        }

        case STATIC_VIDEOS_SEARCH_MORE: {
            return {
                ...state,
                isFetching: true,
            };
        }

        case STATIC_ITEMS_FETCH_ERROR: {
            return {
                ...state,
                isFetching: false,
                error: true,
            };
        }

        case STATIC_ITEMS_CLEAR: {
            return {
                ...state,
                query: '',
                items: [],
                error: false,
                nextUrl: undefined,
            };
        }
        default:
            return state;
    }
}
