import type { ExtendedInputProps as InputProps } from '@schibsted-svp/react-ui/lib/es/Input';
import { Input } from '@schibsted-svp/react-ui';
import type { KeyboardEventHandler } from 'react';
import type { NumberFormatValues, OnValueChange } from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import { formatRemainingExactTime, getTime, SECOND } from 'lib/time';
import { shortcuts } from './helpers';

const MAX_DEFAULT_VALUE = 359999; // equivalent of the 99:59:59 time format;
const INPUT_VALUE_REGEX = /^[0-9_]{2}:[0-5_][0-9_]:[0-5_][0-9_](?:\.[0-9_]{3})?$/;

type TimeInputProps = {
    /** Indicates whether display milliseconds or not */
    precise?: boolean;
    /** Input value */
    value?: number;
    /** Maximum value that a field can handle, by default it is nr of seconds that represents `99:59:59` */
    maxValue?: number;
    /** Handles a callback on onChange event */
    onValueChange?: (value: number) => void;
} & InputProps;

/**
 * It's a helper component which handles time interaction based on the Input Component. It uses the
 * [`react-number-format`](https://www.npmjs.com/package/react-number-format) as a dependency to mask the value.
 * It also contains some shortcuts (which are similar to Adobe products) to increase or decrease values, like:
 *
 * - `ArrowUp` - increases by 1 second,
 * - `ArrowDown` - decreases by 1 second,
 * - `k` - increases by 10 seconds,
 * - `l` - decreases by 10 seconds.
 *
 * You can also use every prop type that is accepted by the [Input component](#input)
 */
export function TimeInput({
    precise = false,
    value = 0,
    maxValue = MAX_DEFAULT_VALUE,
    onValueChange,
    ...props
}: TimeInputProps) {
    function change(newValue: number) {
        if (newValue < 0) {
            return onValueChange(0);
        }

        if (maxValue && newValue > maxValue) {
            return onValueChange(maxValue);
        }

        return onValueChange(newValue);
    }

    const handleKeyDown: KeyboardEventHandler<HTMLElement> = ({ key, shiftKey }) => {
        if (Number.isInteger(Number(key))) {
            return;
        }

        const shortcutModifier = shortcuts(shiftKey, precise)[key];
        if (!shortcutModifier) {
            return;
        }

        change(shortcutModifier(value));
    };

    const handleValueChange: OnValueChange = ({ formattedValue }) => {
        const parsedValue = formattedValue.replace(/_/g, '0');
        change(getTime(parsedValue, 'seconds', true));
    };

    const handleIsAllowed = ({ floatValue, formattedValue }: NumberFormatValues) => {
        if (floatValue !== undefined && (floatValue < 0 || floatValue > maxValue * SECOND)) {
            return false;
        }
        return formattedValue.match(INPUT_VALUE_REGEX) !== null;
    };

    const formattedValue = value != null ? formatRemainingExactTime(value).replace(/[.:]/g, '') : undefined;

    return (
        // NOTE: ignore some props type incompatibilities between react-ui's Input and NumberFormat
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <PatternFormat
            {...props}
            value={formattedValue}
            valueIsNumericString
            isAllowed={handleIsAllowed}
            customInput={Input}
            format={`##:##:##${precise ? '.###' : ''}`}
            placeholder={`00:00:00${precise ? '.000' : ''}`}
            mask="_"
            onKeyDown={handleKeyDown}
            onValueChange={handleValueChange}
        />
    );
}
