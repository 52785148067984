import PropTypes from 'prop-types';
import { relative } from 'lib/date';
import classnames from 'classnames/bind';
import { MdError } from 'react-icons/md';

import css from './SingleNotification.module.scss';

const cln = classnames.bind(css);

function SingleNotification({ timestamp, level, code, message }) {
    return (
        <div className={css.container}>
            <MdError className={cln('icon', `icon--${level}`)} />
            <div className={cln('level', `level--${level}`)}>
                {level} {code || ''}
            </div>
            <div className={css.message}>{message}</div>
            <div className={css.date}> {relative(timestamp * 1000, 'HH:mm:ss')}</div>
        </div>
    );
}

SingleNotification.propTypes = {
    timestamp: PropTypes.number.isRequired,
    level: PropTypes.oneOf(['info', 'alert', 'error']).isRequired,
    code: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
};

export default SingleNotification;
