import { connect } from 'react-redux';

import { saveAsset } from 'store/assets/actions';

import TitleCell from './TitleCell';

const enhance = connect(null, {
    saveAsset,
});

export default enhance(TitleCell);
