import * as liveEventModel from 'models/liveEvent';

/**
 * @typedef {LiveEvent[]} MultiLiveEvent
 */

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function noEvents(events = []) {
    return events.length === 0;
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function atLeastOneEvent(events = []) {
    return events.length > 0;
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isMultiEvent(events = []) {
    return events.length > 1;
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function allEventsAreRunning(events = []) {
    return atLeastOneEvent(events) && events.every(liveEventModel.isRunning);
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isMultiEventRunning(events = []) {
    return (
        atLeastOneEvent(events) &&
        (events.every(liveEventModel.isRunning) ||
            (events.some(liveEventModel.isRunning) && events.some(liveEventModel.hasError)))
    );
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isMultiEventPending(events = []) {
    return (
        atLeastOneEvent(events) &&
        (events.every(liveEventModel.isPending) ||
            (events.some(liveEventModel.isPending) && events.some(liveEventModel.hasError)))
    );
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function atLeastOneEventIsRunning(events = []) {
    return events.some(liveEventModel.isRunning);
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function allEventsHaveError(events = []) {
    return atLeastOneEvent(events) && events.every(liveEventModel.hasError);
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function allEventsArePending(events = []) {
    return atLeastOneEvent(events) && events.every(liveEventModel.isPending);
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function atLeastOneEventIsPending(events = []) {
    return !atLeastOneEventIsRunning(events) && events.some(liveEventModel.isPending);
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Number|null}
 */
export function getStartTime(events = []) {
    return (
        events
            .map(liveEventModel.getStartTime)
            .filter(Boolean)
            .sort((a, b) => a - b)
            .shift() || null
    );
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Number|null}
 */
export function getEndTime(events = []) {
    return events
        .map(liveEventModel.getEndTime)
        .filter(Boolean)
        .sort((a, b) => a - b)
        .shift();
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Number|null}
 */
export function getDvrLength(events = []) {
    return events
        .map(({ dvrLength }) => dvrLength)
        .filter(Boolean)
        .shift();
}

/**
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isMultiLiveEventIdle(events = []) {
    const startTime = getStartTime(events);
    return allEventsArePending(events) && (!startTime || startTime < Date.now() / 1000);
}
