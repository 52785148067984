import { useContext } from 'react';
import PropTypes from 'prop-types';

import BEM from 'lib/bem';
import Player from 'components/core/Player';

import FormPlayerContext from './PlayerContext';
import './Player.scss';

const bem = new BEM('player');

function FormPlayer({ asset }) {
    const { setPlayer } = useContext(FormPlayerContext);

    const { title, images, assetType } = asset;
    const src = `${images.main}?t[]=x452q80`;

    return (
        <div className={bem.block()} data-testid="assetFormPlayer">
            <img className={[bem.element('poster'), bem.element(assetType)].join(' ')} src={src} alt={title} />
            <div className={bem.element('wrapper')}>
                <Player asset={asset} pauseOnSeek onReady={(player) => setPlayer(player)} />
            </div>
        </div>
    );
}
FormPlayer.propTypes = {
    asset: PropTypes.object.isRequired,
};

export default FormPlayer;
