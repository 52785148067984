import { Validate } from 'react-hook-form';

export const required = (value: unknown): string | undefined => {
    if (value !== undefined && value !== null) {
        return undefined;
    }

    const str = String(value == null ? '' : value).replace(/\s/g, '');

    if (str.length > 0) {
        return undefined;
    }

    return 'Field is required';
};

export const numeric = (value: unknown): string | undefined => {
    const numValue = Number(value);

    if (typeof numValue === 'number' && !Number.isNaN(numValue) && typeof value !== 'boolean') {
        return undefined;
    }

    return 'Must be a number';
};

interface BackupEncoderValue {
    encoderId: string;
    inputId?: number;
}

export const backupEncoderInput = (
    value: BackupEncoderValue,
    allValues: { source: BackupEncoderValue; useBackupEncoder: boolean }
): string | undefined => {
    const { source, useBackupEncoder } = allValues;
    const { encoderId, inputId } = source || {};
    const { encoderId: backupEncoderId, inputId: backupInputId } = value || {};

    if (useBackupEncoder && encoderId === backupEncoderId && inputId === backupInputId && inputId) {
        return 'Backup input cannot be the same as the primary one';
    }

    if (useBackupEncoder && ((!inputId && backupInputId) || (inputId && !backupInputId))) {
        return 'Backup input should be the same type as the primary one';
    }

    return undefined;
};

export const backupEncoderInputFactory =
    (source: BackupEncoderValue, useBackupEncoder: boolean) =>
    (value: BackupEncoderValue): string | undefined => {
        return backupEncoderInput(value, { source, useBackupEncoder });
    };

export const url = (value: string): string | undefined => {
    // eslint-disable-next-line no-useless-escape
    if (/^[a-z0-9]*:\/\/[-a-zA-Z0-9@:%_+.~#?&\/=]*/i.test(value)) {
        return undefined;
    }

    return 'Must be a valid URL';
};

export const rtmpUrl = (value: string): string | undefined => {
    const message = 'Must be RTMP url without search params';
    try {
        const urlValue = new URL(value);
        if (!urlValue.href.startsWith('rtmp') || ['?', '#', '&'].some((char) => urlValue.href.includes(char))) {
            return message;
        }
        return undefined;
    } catch {
        return message;
    }
};

export const hlsUrl = (value: string): string | undefined => {
    const message = 'Must be HLS url without hash and with [.m3u8, .m3u, .M3U8, .M3U] extension';
    try {
        // http url with query params
        if (!/^http.*.(?:m3u8?|M3U8?)($|[?])/.test(value)) {
            return message;
        }
        return undefined;
    } catch {
        return message;
    }
};

export const hlsOrRtmpUrl = (value: string): string | undefined => {
    if (value.startsWith('http')) {
        return hlsUrl(value);
    }
    if (value.startsWith('rtmp')) {
        return rtmpUrl(value);
    }
    return 'Must be a valid HLS or RTMP url';
};

export const normalizeValidator = <T extends Validate<unknown, unknown>>(validator: T | Record<string, T>) => {
    if (typeof validator === 'function') {
        return { validator };
    }
    if (typeof validator === 'object') {
        return validator;
    }
    return {};
};
