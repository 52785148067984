import config from 'config';

export const durationOptions = config.live.adsDuration.map((value) => ({
    label: `${value} sec`,
    value,
}));

export function calculateSecondsLeft(endTime) {
    const currentTime = Math.floor(Date.now() / 1000);
    const diff = endTime - currentTime;
    return Math.max(Math.round(diff), 0);
}

export function calculateProgressWidth(duration, secondsLeft) {
    const progressRatio = (duration - secondsLeft) / duration;
    return Math.max(0, Math.min(1, progressRatio)) * 100;
}
