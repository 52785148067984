import { Component } from 'react';
import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';

import NavigationTab from '../NavigationTab';
import ListIcon from '../icons/ListIcon';

const bem = new BEM('video-navigation');

export default class SearchTabContainer extends Component {
    static propTypes = {
        newsroom: PropTypes.string.isRequired,
        searchTerm: PropTypes.string.isRequired,
        searchList: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            number: PropTypes.number.isRequired,
            hasMore: PropTypes.bool.isRequired,
        }).isRequired,
    };

    get location() {
        const { newsroom, searchTerm } = this.props;

        const search = new URLSearchParams();
        search.append('query', searchTerm);

        return {
            pathname: `/${newsroom}/assets/linked`,
            search: `?${search.toString()}`,
        };
    }

    get videosCount() {
        const { searchList } = this.props;

        let numberOfVideos = '\u2026';

        if (!searchList.loading) {
            numberOfVideos = String(searchList.number);
            if (searchList.hasMore) {
                numberOfVideos += '+';
            }
        }

        return numberOfVideos;
    }

    render() {
        const { searchTerm } = this.props;
        if (!searchTerm) {
            return null;
        }

        return (
            <NavigationTab locationPath={this.location}>
                <ListIcon />
                <div className={bem.element('tab-title')}>Other newsrooms ({this.videosCount})</div>
            </NavigationTab>
        );
    }
}
