import type { Action } from '@reduxjs/toolkit';
import type { Asset, AssetMetadata } from '@schibsted-svp/svp-api-types';
import { getNewsroomConfig } from 'config';
import { getMetadata } from 'models/asset';
import { ASSET_CHANGE } from 'store/assets/actionTypes';

export function getAssetArtifacts(provider: string, prefix?: string): string[] {
    const { artifactsMap, defaultArtifacts } = getNewsroomConfig(provider).previews;
    if (!prefix || !(prefix in artifactsMap)) {
        return defaultArtifacts.split(',');
    }
    return artifactsMap[prefix].split(',');
}

export function getCategoryArtifacts(provider: string): string[] {
    const { categoryMetadataKey } = getNewsroomConfig(provider).previews;
    return [categoryMetadataKey];
}

function artifactMapper(artifact: string) {
    switch (artifact) {
        case '1080_6000_preview_hq':
            return 'preview_hq';
        default:
            return artifact;
    }
}

export function getPrefixMetadataKey(prefix: string) {
    return `preview_${prefix}`;
}

export function getMetadataKey(artifacts: string | string[], prefix?: string): string[] {
    if (prefix) {
        return [getPrefixMetadataKey(prefix)];
    }
    return [artifacts].flatMap(artifactMapper);
}

function isAssetChangeAction(action: Action): action is Action & { asset: Asset } {
    return action.type === ASSET_CHANGE;
}

export function findAssetChangeAction(
    provider: string,
    assetId: number,
    metadata: AssetMetadata,
    metadataKeys: string[]
) {
    return (action: Action): boolean => {
        if (!isAssetChangeAction(action) || action.asset.provider !== provider || action.asset.id !== assetId) {
            return false;
        }

        const updatedMetadata = getMetadata(action.asset);
        if (!updatedMetadata) {
            return false;
        }

        return metadataKeys.some((key) => Boolean(updatedMetadata[key]) && updatedMetadata[key] !== metadata[key]);
    };
}
