export const VIDEO_THUMBNAILS_CHANGE = 'Video/THUMBNAILS_CHANGE';
export const VIDEO_DURATION_UPDATE = 'Video/DURATION_UPDATE';

/**
 * @param {String} provider
 * @param {Number} id
 * @param {Number} duration
 */
export const updateVideoDuration = (provider, id, duration) => ({
    type: VIDEO_DURATION_UPDATE,
    provider,
    id,
    duration,
});

/**
 * @param {Number} id
 * @param {String} provider
 * @param {String} thumbnails
 */
export const changeVideoThumbnails = (id, provider, thumbnails) => ({
    type: VIDEO_THUMBNAILS_CHANGE,
    provider,
    id,
    thumbnails,
});
