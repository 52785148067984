import { SET_VERSION } from './actions';

export default function versionReducer(state = {}, action) {
    switch (action.type) {
        case SET_VERSION:
            return {
                id: action.id,
            };
        default:
            return state;
    }
}
