import { useController } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import { useUsersSuggestions } from 'hooks/usersManagement/useUsersSuggestions';
import { Autosuggest } from 'components/ui/Form';
import type { FiltersDialogData } from './types';
import css from './UserSearchInput.module.scss';

type UserSearchInputProps = {
    control: Control<FiltersDialogData>;
};

export function UserSearchInput({ control }: UserSearchInputProps) {
    const { getSuggestions, suggestions } = useUsersSuggestions();

    const { field } = useController({ name: 'createdBy', control });
    const { value, onChange } = field;

    const handleChange = (event, { newValue }) => {
        onChange(newValue);
    };

    const getSuggestionValue = ({ email }) => email;

    const handleShowUserEditForm = (event, { suggestion }) => {
        onChange(suggestion.email);
    };

    const handleBlur = (event, { highlightedSuggestion }) => {
        if (highlightedSuggestion) {
            onChange(highlightedSuggestion.email);
        }
    };

    const handleSuggestionsFetch = (data: { value: string }) => {
        getSuggestions(data.value);
    };

    return (
        <div className={css.container}>
            <Autosuggest
                suggestions={suggestions}
                focusInputOnSuggestionClick={false}
                onSuggestionsFetchRequested={handleSuggestionsFetch}
                onSuggestionsClearRequested={() => {}}
                getSuggestionLabel={getSuggestionValue}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={handleShowUserEditForm}
                inputProps={{
                    onBlur: handleBlur,
                    placeholder: 'Search by e-mail',
                    value: value || '',
                    onChange: handleChange,
                    className: css.input,
                }}
            />
        </div>
    );
}
