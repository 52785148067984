/* eslint-disable no-nested-ternary */
import { Component } from 'react';
import { isEqual } from 'lodash';
import * as PropTypes from 'prop-types';

import * as ArrayLib from 'lib/array';
import { Spinner } from '@schibsted-svp/react-ui';
import Playlists from 'components/video/NextVideo/Playlists';
import { itemSources } from 'components/video/NextVideo/constants';

import AvailableVideoList from 'components/video/NextVideo/AvailableVideoList';
import SelectedPlaylist from 'components/video/NextVideo/SelectedPlaylist';
import VideoSearch from 'components/video/NextVideo/VideoSearch';
import NextVideoNavigation from 'components/video/NextVideo/NextVideoNavigation';
import { MovableVideoCard } from './MovableVideoCard';
import { NextVideoList } from './NextVideoList';

import css from './NextVideo.module.scss';

/**
 * Returns an object of modified video ids mapped to next videos ids
 *
 * @param {Number} dragId            - A currently dropped or removed video id
 * @param {Array<Number>} propsItems - A next video chain taken from props
 * @param {Array<Number>} stateItems - A modified next video chain taken from state
 * @param {Number} firstId           - An identifier of the currently edited video
 * @param {Number} [lastId=null]     - An identifier from one of loopsTo or nextVideoId
 * @returns {Object.<Number, Number>}
 */
export const getChanges = (dragId, propsItems, stateItems, firstId, lastId = null) => {
    const propsItemsDragIndex = propsItems.indexOf(dragId);
    const stateItemsDragIndex = stateItems.indexOf(dragId);
    const propsItemsPrevId = propsItems[propsItemsDragIndex - 1] || firstId;
    const propsItemsNextId = propsItems[propsItemsDragIndex + 1] || lastId;
    const stateItemsPrevId = stateItems[stateItemsDragIndex - 1] || firstId;
    const stateItemsNextId = stateItems[stateItemsDragIndex + 1] || lastId;

    // checks if a dragged element is a new one and returns a addition change object
    if (propsItemsDragIndex === -1 && stateItemsDragIndex !== -1) {
        return {
            [stateItemsPrevId]: dragId,
        };
    }

    // checks if a dragged video is removed and returns a remove change object
    if (propsItemsDragIndex !== -1 && stateItemsDragIndex === -1) {
        return {
            [propsItemsPrevId]: null,
        };
    }

    // checks if a dragged video was dropped on the same position and returns an empty change object
    if (propsItemsDragIndex === stateItemsDragIndex) {
        return {};
    }

    // returns a move change object
    return {
        [propsItemsPrevId]: propsItemsNextId,
        [stateItemsPrevId]: dragId,
        [dragId]: stateItemsNextId,
    };
};

export default class NextVideo extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        provider: PropTypes.string.isRequired,
        assetType: PropTypes.string.isRequired,
        next: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.number).isRequired,
            lastNextId: PropTypes.number,
            loading: PropTypes.bool.isRequired,
            loopsTo: PropTypes.number,
            page: PropTypes.number.isRequired,
        }).isRequired,
        related: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.number).isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
        search: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.number).isRequired,
            loading: PropTypes.bool.isRequired,
            performed: PropTypes.bool.isRequired,
        }).isRequired,
        changeNextVideos: PropTypes.func.isRequired,
        fetchRelated: PropTypes.func.isRequired,
        generateNextVideos: PropTypes.func.isRequired,
        loadMoreNextVideos: PropTypes.func.isRequired,
        playlistsFetch: PropTypes.func.isRequired,
        disableNextVideo: PropTypes.bool.isRequired,
        playlistId: PropTypes.string.isRequired,
        unassignPlaylist: PropTypes.func.isRequired,
        disableNextVideoRhfCheckbox: PropTypes.node,
        enableNextVideoRhfCheckbox: PropTypes.node,
    };

    static defaultProps = {
        disableNextVideoRhfCheckbox: undefined,
        enableNextVideoRhfCheckbox: undefined,
    };

    /**
     * @type {Object}
     * @property {Object} dragItem - An object representation of the dragged video card
     * @property {Number} dragItem.id - An identifier of the dragged video card
     * @property {String} dragItem.type - A list name from which the dragged video card was taken
     * @property {Number} dropPosition - A position index of a target list
     * @property {Array<Number>} items - A list of modified next videos list
     * @property {Number} activeNavigationTabIndex
     */
    state = {
        dragItem: null,
        dropPosition: null,
        items: [],
        activeNavigationTabIndex: 0,
    };

    componentDidMount() {
        const { id, provider, fetchRelated, generateNextVideos, playlistId, playlistsFetch } = this.props;

        fetchRelated({ provider, id });
        generateNextVideos(id, provider);
        if (playlistId) {
            playlistsFetch({ provider, id: playlistId });
        }
    }

    onLoadMoreClick = () => {
        const { id, provider, next, loadMoreNextVideos } = this.props;
        const nextPage = next.page + 1;

        loadMoreNextVideos(id, provider, nextPage);
    };

    onVideoCardDrop = (dragItem) => {
        this.changeNextVideos(dragItem.id);
        this.onVideoCardMove();
    };

    onVideoCardMove = (dragItem, dropPosition) => {
        const {
            next: { items: nextItems },
        } = this.props;
        let items = [...nextItems];

        if (dragItem != null && dragItem.type !== itemSources.nextVideos) {
            items = [...items, dragItem.id];
        }

        if (dragItem != null && dropPosition != null) {
            items = ArrayLib.move(items, items.indexOf(dragItem.id), dropPosition);
        }

        this.setState(() => ({ dragItem, dropPosition, items }));
    };

    onVideoCardRemove = (dragId) => {
        this.setState(
            (state) => {
                const items = [...state.items];
                items.splice(items.indexOf(dragId), items.length);

                return { items };
            },
            () => this.changeNextVideos(dragId)
        );
    };

    static getDerivedStateFromProps(props, state) {
        const { items } = props.next;

        if (!isEqual(items, state.defaultItems)) {
            return {
                items: [...items],
                defaultItems: [...items],
            };
        }

        return null;
    }

    isVideoCardDragged = (id) => {
        const { dragItem } = this.state;

        return dragItem != null && id === dragItem.id && Object.values(itemSources).includes(dragItem.type);
    };

    isVideoCardVisible = (type, id, position) => {
        const { dragItem, dropPosition } = this.state;

        if (!dragItem || type !== itemSources.nextVideos || dragItem.type === itemSources.nextVideos) {
            return false;
        }

        return dropPosition != null && position > dropPosition;
    };

    handleUnassignPlaylist = () => {
        const { provider, id, unassignPlaylist } = this.props;

        this.setState({ items: [] });
        unassignPlaylist({ provider, id });
    };

    handleNavigationTabChange = (index) => {
        this.setState({ activeNavigationTabIndex: index });
    };

    changeNextVideos(dragId) {
        const { id, provider, changeNextVideos, next, playlistId } = this.props;
        const { items } = this.state;

        const lastId = next.loopsTo || next.lastNextId;
        const changes = getChanges(dragId, next.items, items, id, lastId);

        changeNextVideos(id, provider, playlistId ? { [id]: dragId } : changes);
    }

    renderAvailableVideos() {
        const { next, provider, id, related, search, assetType, disableNextVideoRhfCheckbox } = this.props;
        const items = (search.performed ? search.items : related.items).filter(
            (itemId) => !next.items.includes(itemId)
        );
        const type = search.performed ? itemSources.searchVideos : itemSources.relatedVideos;

        return (
            <div className={css['search-container']}>
                <VideoSearch
                    id={id}
                    provider={provider}
                    assetType={assetType}
                    disableNextVideoRhfCheckbox={disableNextVideoRhfCheckbox}
                />
                <AvailableVideoList loading={search.performed && search.loading} className={css['assets-container']}>
                    {this.renderVideoCardList(items, type)}
                </AvailableVideoList>
            </div>
        );
    }

    renderNextVideos() {
        const {
            disableNextVideo,
            playlistId,
            provider,
            next: { lastNextId, loading, loopsTo },
            enableNextVideoRhfCheckbox,
        } = this.props;
        const { items } = this.state;

        return (
            <div className={css['selected-videos-container']}>
                {loading && <Spinner containerClassName={css.spinner} />}
                <NextVideoList
                    moveCard={this.onVideoCardMove}
                    className={css['assets-container']}
                    loopsTo={loopsTo}
                    loading={loading}
                    loadMore={lastNextId && this.onLoadMoreClick}
                    disableNextVideo={disableNextVideo}
                    hasPlaylist={Boolean(playlistId)}
                    enableNextVideoRhfCheckbox={enableNextVideoRhfCheckbox}
                >
                    {playlistId ? (
                        <SelectedPlaylist
                            provider={provider}
                            playlistId={playlistId}
                            onUnassignPlaylist={this.handleUnassignPlaylist}
                            loading={loading}
                        />
                    ) : (
                        this.renderVideoCardList(items, itemSources.nextVideos)
                    )}
                </NextVideoList>
            </div>
        );
    }

    renderVideoCardList(items, type) {
        const { dragItem } = this.state;
        const { provider } = this.props;
        const { id: dragId } = dragItem || {};

        return items.map((id, position) => (
            <MovableVideoCard
                key={`card:${type}:${id}`}
                id={id}
                provider={provider}
                type={type}
                position={position}
                dragged={this.isVideoCardDragged(id)}
                preview={dragId === id}
                obsolete={this.isVideoCardVisible(type, id, position)}
                moveCard={this.onVideoCardMove}
                dropCard={this.onVideoCardDrop}
                removeCard={this.onVideoCardRemove}
            />
        ));
    }

    render() {
        const { related, disableNextVideo, assetType } = this.props;
        const { activeNavigationTabIndex } = this.state;

        const shouldRenderAvailableVideos = !disableNextVideo;
        return (
            <section className={css['next-video-container']}>
                {related.loading ? (
                    <Spinner className={css.spinner} />
                ) : (
                    <>
                        {this.renderNextVideos()}
                        <NextVideoNavigation
                            activeTabIndex={activeNavigationTabIndex}
                            onTabChange={this.handleNavigationTabChange}
                        />
                        {activeNavigationTabIndex === 0 && shouldRenderAvailableVideos && this.renderAvailableVideos()}
                        {activeNavigationTabIndex === 1 && <Playlists assetType={assetType} />}
                    </>
                )}
            </section>
        );
    }
}
