/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Notification, InputError, Spinner } from '@schibsted-svp/react-ui';
import { FieldAdder } from 'components/core/hookForm/FieldAdder';
import { seriesDelete, seriesAdd, seriesFetch, seriesUpdate } from 'store/series/actions';
import { setSeriesFlag } from 'store/categories/actions';
import { FormProvider } from 'react-hook-form';
import { SeasonRow } from './SeasonRow';
import { prepareNewSeasonRow } from './helpers';
import { useFetchSeasonsData, useSeriesForm } from './hooks';
import css from './SeasonsForm.module.scss';

interface Props {
    provider: string;
    categoryId: number;
}

export function SeasonsForm({ provider, categoryId }: Props) {
    const [savingSeasonRow, setSavingSeasonRow] = useState(false);
    const dispatch = useDispatch();
    const {
        seasons: seasonsInStore,
        isSeries,
        seasonsByNumbers,
        isFetchingSeasons,
        isFetchingError,
    } = useFetchSeasonsData({ provider, categoryId });

    const seasonsCount = seasonsInStore.length;
    const seriesLoaded = seasonsCount !== 0;

    const { formApi } = useSeriesForm({ defaultValues: { seasons: seasonsInStore } });
    const { reset, watch, control } = formApi;

    useEffect(() => {
        if (!seasonsInStore.length && isSeries && isFetchingSeasons) {
            return;
        }

        reset({ seasons: seasonsInStore });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seasonsInStore.length, isSeries, reset, isFetchingSeasons]);

    const { seasons } = watch();

    useEffect(() => {
        if (!seriesLoaded && isSeries) {
            dispatch(seriesFetch(provider, categoryId));
        }
    }, [categoryId, isSeries, provider, seriesLoaded, dispatch]);

    function isAddButtonDisabled(fields) {
        return fields.length > seasonsCount;
    }

    async function changeSeriesFlag(isSeriesFlag) {
        try {
            await dispatch(setSeriesFlag(provider, categoryId, isSeriesFlag));
            return true;
        } catch (e) {
            Notification.notify.error(
                `Failed to convert "${categoryId}" ${isSeriesFlag ? 'category to a series' : 'series to a category'}`
            );
            return false;
        }
    }

    async function handleSave(index) {
        const shouldChangeCategoryToASeries = seasonsCount === 0 && !isSeries;
        const { title, seasonNumber } = seasons[index];

        setSavingSeasonRow(true);
        if (shouldChangeCategoryToASeries) {
            const success = await changeSeriesFlag(true);

            if (!success) {
                setSavingSeasonRow(false);
                return;
            }
        }

        const shouldUpdate = seasonsByNumbers[seasonNumber];

        try {
            const action = shouldUpdate ? seriesUpdate : seriesAdd;

            await dispatch(action(provider, categoryId, +seasonNumber, title));
            Notification.notify.success(
                `Season "${title}" has been ${shouldUpdate ? 'updated' : 'created'} successfully`
            );
        } catch (e) {
            Notification.notify.error(`An error occurred while trying to add "${title}" season`);
        }
        setSavingSeasonRow(false);
    }

    async function handleDelete(deleteField, index) {
        const { title, seasonNumber } = seasons[index];

        const savedInAPI = seasonsByNumbers[seasonNumber];

        if (!savedInAPI) {
            deleteField(index);
            return;
        }

        setSavingSeasonRow(true);
        try {
            const shouldChangeSeriesToACategory = seasonsCount === 1 && isSeries;

            await dispatch(seriesDelete(provider, categoryId, +seasonNumber));

            if (shouldChangeSeriesToACategory) {
                const success = await changeSeriesFlag(false);
                if (!success) {
                    setSavingSeasonRow(false);
                    return;
                }
            }

            Notification.notify.success(`Season "${title}" has been deleted successfully`);
        } catch (e) {
            if (e.status === 400) {
                Notification.notify.error(e.message);
            } else {
                Notification.notify.error(`An error occurred while trying to delete "${title}" season`);
            }
        }
        setSavingSeasonRow(false);
    }

    const label = seasonsCount > 0 ? 'ADD SEASONS' : 'CREATE SERIES';

    if (isFetchingSeasons && !savingSeasonRow) {
        return <Spinner size="compact" label="Fetching seasons" containerClassName={css['metadata-spinner']} />;
    }

    if (!isFetchingSeasons && isFetchingError) {
        return <InputError className={css['metadata-error']}>Failed to fetch seasons</InputError>;
    }
    return (
        <FormProvider {...formApi}>
            <FieldAdder
                name="seasons"
                control={control}
                label={label}
                mode="append"
                isLoading={savingSeasonRow}
                onNewField={prepareNewSeasonRow}
                disabled={isAddButtonDisabled}
                addButtonTestId="seriesAddButton"
            >
                {(methods) => {
                    const { fields, remove: deleteField } = methods;
                    const allRowsValues = fields;

                    const props = {
                        seasonsByNumbers,
                        fields,
                        seasonsCount,
                        allRowsValues,
                        handleDelete: (index) => handleDelete(deleteField, index),
                        handleSave,
                    };

                    return fields.map((field, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <SeasonRow key={field.id} index={index} {...props} />
                    ));
                }}
            </FieldAdder>
        </FormProvider>
    );
}
