/* eslint-disable react/jsx-no-bind */
import { useFormContext } from 'react-hook-form';
import type { UseFieldArrayReturn } from 'react-hook-form';
import { Button, Input } from '@schibsted-svp/react-ui';
import css from './SeasonsForm.module.scss';
import { isSeasonNumberValid, findDuplicatedSeasonNumberIndex } from './validators';
import type { Season } from './types';

interface Props {
    seasonsByNumbers: Record<number, Season>;
    index: number;
    seasonsCount: number;
    handleDelete: (deleteField: Pick<UseFieldArrayReturn, 'remove'>) => void;
    handleSave: (index: number) => void;
}
export function SeasonRow({ seasonsByNumbers, index, seasonsCount, handleDelete, handleSave }: Props) {
    const { register, getValues, formState } = useFormContext();

    const seasons = getValues('seasons');
    const { seasonNumber, title } = seasons[index] || {};
    const { errors } = formState;
    const notTouched = seasonsByNumbers[seasonNumber] && seasonsByNumbers[seasonNumber].title === title;

    const invalidSeasonNumberError = isSeasonNumberValid(seasonNumber);

    const duplicatedSeasonIndex = findDuplicatedSeasonNumberIndex(seasonNumber, seasons);
    const duplicationError = duplicatedSeasonIndex === index ? 'This season number is duplicated' : undefined;
    const isAnyError = invalidSeasonNumberError || duplicationError || !title;
    const isSaveDisabled = Boolean(isAnyError) || notTouched;

    const isSeasonNumberDisabled = !!seasonsByNumbers[seasonNumber] && index < seasonsCount;

    return (
        <div className={css['inputs-row']}>
            <Input
                {...register(`seasons.${index}.title`)}
                placeholder="Title"
                autoComplete="off"
                size="small"
                type="text"
                error={errors?.seasons?.[index]?.title?.message}
            />
            <Input
                {...register(`seasons.${index}.seasonNumber`)}
                placeholder="Season number"
                disabled={isSeasonNumberDisabled}
                size="small"
                type="text"
                error={invalidSeasonNumberError || duplicationError}
            />
            <Button size="small" variant="standard" type="button" onClick={handleDelete.bind(null, index)}>
                Delete
            </Button>
            <Button size="small" disabled={isSaveDisabled} type="button" onClick={handleSave.bind(null, index)}>
                Save
            </Button>
        </div>
    );
}
