import authToken from 'models/authToken';

/**
 *
 * @param {Object} options
 * @returns {Object}
 */
export default function preRequest(options) {
    const token = authToken.get();

    return (
        token &&
        token.raw && {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token.raw}`,
            },
        }
    );
}
