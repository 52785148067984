import { useSelector } from 'react-redux';
import type { Category } from '@schibsted-svp/svp-api-types';
import { addTransformToImage } from 'lib/image';
import { slug, tryJSON } from 'lib/string';
import { getCategory } from 'store/categories/selectors';
import { isIgnoredMetadata, isDynamicFieldMetadata, DYNAMIC_FIELD_METADATA_PREFIX } from '../helpers/index';
import { usePodmeMetadata } from './index';

export function getPodcastCategories(metadata: Category['additional']['metadata']) {
    const podcastCategories = JSON.parse(metadata.podcast_categoryTree?.value || '""');
    const podcastSingleCategory = metadata.podcast_category?.value;

    if (!podcastCategories && !podcastSingleCategory) return null;

    if (podcastSingleCategory) {
        return {
            podcast_mainCategory: [podcastSingleCategory],
        };
    }

    const podcastMainCategories = Object.keys(podcastCategories);
    const podcastSubcategories = Object.fromEntries(
        Object.entries(podcastCategories).filter(([, value]) => value && Array.isArray(value) && value.length)
    );

    return {
        podcast_mainCategory: podcastMainCategories,
        podcast_category: podcastSubcategories,
    };
}

export function getMetadata(metadata: Category['additional']['metadata']) {
    const customMetadataKeys = ['podcast_categoryTree', 'podcast_category', 'podcast_richDescription', 'series_cast'];

    const fixedMetadata = Object.fromEntries(
        Object.entries(metadata).filter(([key]) => isIgnoredMetadata(key) && !customMetadataKeys.includes(key))
    );

    return {
        ...fixedMetadata,
        ...getPodcastCategories(metadata),
        podcast_richDescription: metadata?.podcast_richDescription || { value: null },
        series_cast: tryJSON(metadata?.series_cast?.value) || [],
    };
}

export function getSimpleMetadata(metadata: Category['additional']['metadata']) {
    return Object.entries(metadata)
        .filter(([key]) => !isIgnoredMetadata(key) && !isDynamicFieldMetadata(key))
        .map(([key, { value, inherited }]) => ({
            key,
            value,
            inherited,
        }));
}

export function getDynamicFieldsMetadata(metadata: Category['additional']['metadata']) {
    return Object.entries(metadata)
        .filter(([key]) => !isIgnoredMetadata(key) && isDynamicFieldMetadata(key))
        .map(([key, { value, inherited }]) => ({
            key: key.replace(`${DYNAMIC_FIELD_METADATA_PREFIX}_`, ''),
            value,
            inherited,
        }));
}

export function useInitialValues({
    provider,
    categoryId,
    isCreationMode,
}: {
    provider: string;
    categoryId: number;
    isCreationMode: boolean;
}) {
    const category = useSelector((state) => getCategory(state, { provider, id: categoryId }));
    const podmeMetadata = usePodmeMetadata({ provider, categoryId, skip: isCreationMode });

    if (isCreationMode) {
        return {
            parentId: categoryId,
            showCategory: true,
            simpleMetadata: [],
            dynamicFieldsMetadata: [],
            podmeMetadata: {
                sendToPodme: false,
                sendRssToPodme: false,
                podmeCategories: [],
            },
        };
    }

    return {
        ...category,
        additional: {
            ...category.additional,
            image: addTransformToImage(category.additional.image),
            images: Object.entries(category.additional.images).map(([key, value]) => ({
                key,
                url: addTransformToImage(value),
            })),
            shortPath: category.additional.shortPath || slug(category.title),
            metadata: getMetadata(category.additional.metadata),
        },
        simpleMetadata: getSimpleMetadata(category.additional.metadata),
        dynamicFieldsMetadata: getDynamicFieldsMetadata(category.additional.metadata),
        podmeMetadata: {
            sendToPodme: podmeMetadata?.sendToPodme || false,
            sendRssToPodme: podmeMetadata?.sendRssToPodme || false,
            podmeCategories: podmeMetadata?.podmeCategories || [],
        },
    };
}
