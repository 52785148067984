import type { Asset } from '@schibsted-svp/svp-api-types';
import { useSelector } from 'react-redux';
import { getScheduleForAsset } from './selectors';
import { SCHEDULES_SCOPE } from './constants';
import type { NotificationSchedule } from './types';

export const useAssetSchedulePublishTime = ({
    provider,
    id,
}: {
    provider: Asset['provider'];
    id: Asset['id'];
}): number | undefined => {
    const schedule = useSelector((state) => getScheduleForAsset(state, { provider, id, scope: SCHEDULES_SCOPE.ASSET }));
    return schedule?.publishTime;
};

export const useNotificationSchedule = ({
    provider,
    id,
}: {
    provider: Asset['provider'];
    id: Asset['id'];
}): NotificationSchedule => {
    return useSelector((state) => getScheduleForAsset(state, { provider, id, scope: SCHEDULES_SCOPE.NOTIFICATION }));
};
