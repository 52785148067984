import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';

import './Thumbnail.scss';

const bem = new BEM('Thumbnail');

function Thumbnail({ className, onClick, startTime, url }) {
    if (!url) {
        return null;
    }

    const [src, hash] = url.split('#');

    const [, x, y, width, height] = hash.match(/xywh=(\d+),(\d+),(\d+),(\d+)/);

    function handleClick(event) {
        onClick?.({ event, startTime });
    }

    return (
        <span
            role="button"
            tabIndex="0"
            className={bem.block(undefined, className)}
            onClick={handleClick}
            aria-label="Thumbnail"
            style={{
                width: `${width}px`,
                height: `${height}px`,
                backgroundImage: `url(${src})`,
                backgroundPositionX: `-${x}px`,
                backgroundPositionY: `-${y}px`,
            }}
        />
    );
}

Thumbnail.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    startTime: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
};

Thumbnail.defaultProps = {
    className: undefined,
    onClick: undefined,
};

export default Thumbnail;
