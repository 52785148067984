// TODO: add some comments
export function toggle(array, element) {
    const result = [...array];
    const position = result.indexOf(element);

    // eslint-disable-next-line
    if (~position) {
        result.splice(position, 1);
    } else {
        result.push(element);
    }

    return result;
}

export function isIndexBounded(array, idx) {
    return idx > -1 && idx < array.length;
}

export function move(array, from, to) {
    const indiciesInBounds = isIndexBounded(array, from) && isIndexBounded(array, to);

    if (!indiciesInBounds || from === to) {
        return array;
    }

    const result = [...array];
    const [element] = result.splice(from, 1);

    result.splice(to, 0, element);

    return result;
}

/**
 * Returns a unique array based on given ones
 * @param {Array} array
 * @return {Array}
 */
export function unique(array) {
    return Array.from(new Set(array));
}

/**
 * Convert array of objects to hashmap
 * @param array
 * @param key
 */
export function toHashMap(array, key = 'id') {
    return array.reduce((acc, item) => {
        acc[item[key]] = item;
        return acc;
    }, {});
}
/**
 * Extract values from options objects array.
 *
 * Each option should be in {value, label} format
 * @param {Array} options
 * @returns {Array} Array of values
 */
export function getValues(options) {
    return options.map(({ value }) => value);
}

/**
 * Map hashmap of options fitlered with array of selected elements
 * to array of options
 *
 * @param {Object} array
 * @param {Array} activeElements
 * @returns {{label: *, value: string}[]}
 */
export function activeOptions(hashmap, selectedElements) {
    return Object.keys(hashmap)
        .filter((key) => selectedElements.includes(key))
        .map((value) => ({
            label: hashmap[value],
            value,
        }));
}
