import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm, useFormContext } from 'react-hook-form';
import { getNewsroomConfig } from 'config';
import { getLiveEncoderOptions } from 'store/live/selectors';
import { AudioChannelsMixType, AudioChannelsMixValuesStereo } from 'components/video/live/form/AudioConfiguration';
import { LiveEvent } from 'models/liveEvent';
import type { InitialValues } from 'components/video/live/LiveCreationDialog/hooks';
import { getInitialSources } from './helpers';
import type { EncoderInputFormData, EncoderInputFormControl } from './types';

export const useEncoderInputForm = (initialValues: EncoderInputFormData) => {
    const formApi = useForm<EncoderInputFormData>({
        mode: 'all',
        defaultValues: initialValues,
    });
    return { formApi };
};

export const useEncoderInputFormContext = () => {
    const formApi = useFormContext<EncoderInputFormData>();
    return { formApi };
};

export const useEncoderInputFormFormSubmit = ({ onSubmit }: EncoderInputFormControl) => {
    const {
        formApi: { handleSubmit },
    } = useEncoderInputFormContext();

    return useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
};

// tmp helper type until config is typed properly
type NewsroomLiveConfig = { profile?: string; livePreviewDefault?: boolean; audioNormalization?: { default: boolean } };

export const useInitialValues = ({
    newsroom,
    initialValues,
    events,
}: {
    newsroom: string;
    initialValues: InitialValues;
    events: LiveEvent[];
}) => {
    const encoderOptions = useSelector(getLiveEncoderOptions);
    const { initialSource, initialBackup } = getInitialSources(events, encoderOptions);
    const {
        profile: hlsProfile,
        livePreviewDefault,
        audioNormalization,
    } = (getNewsroomConfig(newsroom)?.live as NewsroomLiveConfig) || {};

    return {
        source: initialSource,
        useBackupEncoder: initialBackup,
        ...(initialBackup && { backup: initialBackup }),
        streamQuality: 'standard' as const,
        profile: hlsProfile ? { [hlsProfile as 'HLS1080']: true } : undefined,
        audioChannelsMix: {
            type: AudioChannelsMixType.STEREO,
            values: AudioChannelsMixValuesStereo,
        },
        livePreview: livePreviewDefault || false,
        vertical: false,
        overlay: '',
        deinterlace: false,
        audioNormalization: audioNormalization?.default || false,
        ...initialValues,
    };
};
