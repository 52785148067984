import podcastCategories from 'podcastCategories';

export const getSubcategories = (categoryName: string) => {
    if (!podcastCategories[categoryName]) return [];

    const { subcategories } = podcastCategories[categoryName];
    if (!subcategories) return [];

    const subcategoriesOptions = Object.entries(subcategories).map(([value, label]) => ({ value, label }));
    return subcategoriesOptions;
};
