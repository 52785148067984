import { CollapsibleContent, Divider, LabeledContainer, Input, Select } from '@schibsted-svp/react-ui';
import { findSingleOptionWithDefaultValue, getValueFromOption } from 'components/ui/Form/Select';
import config from 'config';
import { Controller, useWatch, type Control, type UseFormRegister } from 'react-hook-form';
import type { FormValues } from '../types';
import css from './PodcastProperties.module.scss';

const options = config?.podcasts?.episodeTypes || [];
const defaultEpisodeType = options.find((opt) => opt.default)?.value;

type PodcastPropertiesProps = {
    control: Control<FormValues>;
    register: UseFormRegister<FormValues>;
};
export function PodcastProperties({ control, register }: PodcastPropertiesProps) {
    const isPodcast = useWatch({ control, name: 'additional.metadata.isPodcast' });

    if (!isPodcast) return null;
    return (
        <>
            <CollapsibleContent label="Additional podcast properties">
                <div className={css.container}>
                    <LabeledContainer label="Podcast GUID">
                        <Input
                            {...register('additional.metadata.podcast_guid')}
                            size="small"
                            placeholder="None"
                            readOnly
                        />
                    </LabeledContainer>
                    <LabeledContainer label="Episode Type">
                        <Controller
                            name="additional.metadata.podcast_episodeType"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    value={findSingleOptionWithDefaultValue(
                                        options,
                                        defaultEpisodeType
                                    )(field.value as typeof defaultEpisodeType)}
                                    onChange={(value) => field.onChange(value ? value.value : null)}
                                    size="small"
                                    placeholder="None"
                                    normalize={getValueFromOption}
                                    options={options}
                                    splitButton={false}
                                    isClearable
                                />
                            )}
                        />
                    </LabeledContainer>
                </div>
            </CollapsibleContent>
            <Divider margin="0" />
        </>
    );
}
