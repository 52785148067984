import { useDispatch } from 'react-redux';
import type { UseFormReturn, FieldArrayWithId } from 'react-hook-form';
import { Input, Button, InputError, Spinner } from '@schibsted-svp/react-ui';
import { FieldAdder } from 'components/core/hookForm/FieldAdder';
import { deleteCategoryPreview } from 'store/categories/slice';
import { trimValue } from './helpers';
import type { CategoryData, MetadataField } from './types';

import css from './MetadataInputs.module.scss';

function isFieldDisabled(
    metadataFromAPI: MetadataField[],
    field: FieldArrayWithId<CategoryData, 'simpleMetadata', 'id'>
): boolean {
    return metadataFromAPI
        .filter(({ inherited }) => inherited)
        .some(({ key, value }) => key === field.key && field.value === value);
}

type MetadataInputsProps = {
    provider: string;
    categoryId: number;
    metadataFromAPI: MetadataField[];
    isFetching: boolean;
    error: boolean;
    formApi: UseFormReturn<CategoryData>;
};

export function MetadataInputs({
    provider,
    categoryId,
    metadataFromAPI,
    isFetching,
    error,
    formApi,
}: MetadataInputsProps) {
    const dispatch = useDispatch();

    if (isFetching) {
        return <Spinner size="compact" label="Fetching metadata" />;
    }

    if (error) {
        return <InputError>Failed to fetch metadata</InputError>;
    }

    const { control, register, formState } = formApi;

    const removePreview = (key: string) => {
        if (key === 'promoUrl') {
            dispatch(deleteCategoryPreview({ provider, categoryId }));
        }
    };

    return (
        <FieldAdder name="simpleMetadata" label="Add new properties" mode="append" control={control}>
            {({ fields, remove }) =>
                fields.map((field, index) => {
                    const disabled = isFieldDisabled(metadataFromAPI, field);
                    const keyError = formState.errors?.simpleMetadata?.[index]?.key?.message;
                    const valueError = formState.errors?.simpleMetadata?.[index]?.value?.message;

                    return (
                        <div key={field.id} className={css.row}>
                            <Input
                                {...register(`simpleMetadata.${index}.key`, {
                                    setValueAs: (value) => trimValue(value),
                                })}
                                containerClassName={css.input}
                                autoComplete="off"
                                size="small"
                                error={keyError}
                            />
                            <Input
                                {...register(`simpleMetadata.${index}.value`)}
                                containerClassName={css.input}
                                size="small"
                                error={valueError}
                            />
                            <Button
                                className={css.button}
                                disabled={disabled}
                                size="small"
                                variant="standard"
                                type="button"
                                onClick={() => {
                                    remove(index);
                                    removePreview(field.key);
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    );
                })
            }
        </FieldAdder>
    );
}
