import { useEffect, useCallback, type ReactNode, type ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import {
    Controller,
    useWatch,
    useFormState,
    UseFieldArrayReturn,
    type UseFormReturn,
    type SetValueConfig,
} from 'react-hook-form';
import { Checkbox, Spinner } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { getTimestamp } from 'lib/time';
import { isPodmeIntegrationEnabled } from 'lib/flags';
import { getCategory } from 'store/categories/selectors';
import { isPodcastPushEnabled } from 'models/asset';
import { PodmeCheckMark } from 'components/ui/PodmeCheckMark';
import { usePrevious } from 'hooks/usePrevious';
import { useAssetPodmeMetadata, useCategoryPodmeMetadata } from './hooks/podmeMetadata';
import { PushNotifications } from '../fields/PushNotifications';
import { PodcastExperimentsSettings } from '../fields/PodcastExperimentsSettings';

import type { FormValues } from '../types';
import css from './PodcastControls.module.scss';

const VALUE_SET_OPTIONS: SetValueConfig = { shouldDirty: true, shouldTouch: true };

type PodcastControlsProps = {
    asset: Asset;
    formApi: UseFormReturn<FormValues>;
    children: ReactNode;
    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;
};

export function PodcastControls({ asset, formApi, children, simpleMetadataControls }: PodcastControlsProps) {
    const { control, register, setValue, getValues } = formApi;

    const [categoryId, isPodcast, sendToPodme, distributeAfter] = useWatch({
        control,
        name: ['category.id', 'additional.metadata.isPodcast', 'sendToPodme', 'additional.metadata.distributeAfter'],
    });

    const { touchedFields } = useFormState({ control });

    const { provider, id: assetId, status } = asset;

    const { podmeMetadata, isPodmeMetadataPolling } = useAssetPodmeMetadata({ provider, assetId, status });
    const { isCategoryInPodme, sendCategoryRssToPodme } = useCategoryPodmeMetadata({
        provider,
        categoryId,
        skip: !categoryId,
    });

    const category = useSelector((state) => getCategory(state, { provider, id: categoryId }));

    const prevCategoryId = usePrevious(categoryId);
    const prevSendCategoryRssToPodme = usePrevious(sendCategoryRssToPodme);

    const getDistributeAfterDefault = useCallback(() => {
        return String(asset.published || getTimestamp(new Date()));
    }, [asset.published]);

    useEffect(() => {
        if (categoryId !== prevCategoryId && prevCategoryId && category) {
            const { metadata: categoryMetadata } = category.additional || {};
            const isCategoryPodcast = Boolean(
                categoryMetadata?.podcast_category || categoryMetadata?.podcast_categoryTree
            );
            if (isCategoryPodcast) {
                setValue('additional.metadata.isPodcast', true, VALUE_SET_OPTIONS);
                if (!distributeAfter) {
                    setValue('additional.metadata.distributeAfter', getDistributeAfterDefault(), VALUE_SET_OPTIONS);
                }
            }
            setValue('sendToPodme', false, VALUE_SET_OPTIONS);
        }
    }, [categoryId, prevCategoryId, category, distributeAfter, touchedFields, getDistributeAfterDefault, setValue]);

    useEffect(() => {
        // prevent disabling both distributeAfter and sendToPodme fields when changing the category
        if (sendCategoryRssToPodme !== prevSendCategoryRssToPodme && distributeAfter && sendToPodme) {
            setValue('sendToPodme', false, VALUE_SET_OPTIONS);
        }
    }, [sendCategoryRssToPodme, prevSendCategoryRssToPodme, distributeAfter, sendToPodme, setValue]);

    const onPodcastChange = ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
        if (checked && !distributeAfter) {
            setValue('additional.metadata.distributeAfter', getDistributeAfterDefault(), VALUE_SET_OPTIONS);
        } else if (!checked) {
            setValue('additional.metadata.distributeAfter', null, VALUE_SET_OPTIONS);
            setValue('sendToPodme', false, VALUE_SET_OPTIONS);
        }
    };

    const onDistributeExternallyChange = ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
        if (checked && !isPodcast) {
            setValue('additional.metadata.isPodcast', true, VALUE_SET_OPTIONS);
        }
        if (checked && sendCategoryRssToPodme && sendToPodme) {
            setValue('sendToPodme', false, VALUE_SET_OPTIONS);
        }
    };

    const onPodmeChange = ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
        if (checked && !isPodcast) {
            setValue('additional.metadata.isPodcast', true, VALUE_SET_OPTIONS);
        }
        if (checked && sendCategoryRssToPodme && distributeAfter) {
            setValue('additional.metadata.distributeAfter', null, VALUE_SET_OPTIONS);
        }
    };

    return (
        <section className={css.container}>
            <div className={css.podcastForm}>
                <h1>Podcast</h1>
                <div className={css.podcastControls}>
                    <div className={css.checkboxContainer}>
                        <Checkbox
                            {...register('additional.metadata.isPodcast', { onChange: onPodcastChange })}
                            label="Podcast"
                            containerClassName={css.checkboxInput}
                        />
                        <p>Will be distributed in internal apps and websites</p>
                    </div>
                    <div className={css.checkboxContainer}>
                        <Controller
                            name="additional.metadata.distributeAfter"
                            control={control}
                            render={({ field: { value, ...rest } }) => (
                                <Checkbox
                                    {...rest}
                                    checked={Boolean(value)}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        rest.onChange(event.currentTarget.checked ? getDistributeAfterDefault() : null);
                                        onDistributeExternallyChange(event);
                                    }}
                                    label="Distribute externally"
                                    containerClassName={css.checkboxInput}
                                />
                            )}
                        />
                        <p>
                            {['Apple', 'Spotify', 'Google', sendCategoryRssToPodme && 'Podme (free)']
                                .filter(Boolean)
                                .join(', ')}
                        </p>
                    </div>
                    {isPodmeIntegrationEnabled && isCategoryInPodme && (
                        <div className={css.podmeContainer}>
                            <Checkbox
                                {...register('sendToPodme', { onChange: onPodmeChange })}
                                label="Podme premium"
                                containerClassName={css.checkboxInput}
                            />
                            {isPodmeMetadataPolling ? (
                                <Spinner size="compact" className={css.spinner} />
                            ) : (
                                podmeMetadata?.podmeId && <PodmeCheckMark podmeId={podmeMetadata.podmeId} />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {isPodcastPushEnabled(asset) && (
                <div>
                    <h1>Push notifications</h1>
                    <PushNotifications asset={asset} formApi={formApi} />
                </div>
            )}

            <div className={css.playerSettings}>
                <h1>Player settings</h1>
                <PodcastExperimentsSettings
                    assetId={asset.id}
                    simpleMetadataControls={simpleMetadataControls}
                    getValues={getValues}
                />
                <div className={css.playerSettingsInfo}>Fine tune in &quot;add new properties&quot;</div>
            </div>

            <div className={css.submitButton}>{children}</div>
        </section>
    );
}
