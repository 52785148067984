import { clamp, isEmpty } from 'lodash';

/**
 * @type {Object<String, Symbol>}
 */
export const validatorTypes = {
    ARRAY_LENGTH: Symbol('ARRAY_LENGTH'),
    VALUE_CHECK: Symbol('VALUE_CHECK'),
    WORD_COUNT: Symbol('WORD_COUNT'),
};

/**
 * @param {Number} required
 * @returns {Function}
 */
export function arrayLengthValidator(required) {
    if (typeof required !== 'number' || required <= 0) {
        throw new Error('The `required` parameter has to be a number grater than 0');
    }

    const arrayLength = (value) => {
        if (value == null || !Array.isArray(value)) {
            return 0;
        }

        return clamp(value.length / required, 0, 1);
    };

    arrayLength.required = required;
    arrayLength.type = validatorTypes.ARRAY_LENGTH;

    return arrayLength;
}

/**
 * @returns {Function}
 */
export function valueCheckValidator() {
    const valueCheck = (value) => {
        if (value == null) {
            return 0;
        }

        if (typeof value === 'boolean') {
            return value ? 1 : 0;
        }

        if (typeof value === 'number') {
            return 1;
        }

        if (typeof value === 'string') {
            return /\S/.test(value) ? 1 : 0;
        }

        return isEmpty(value) ? 0 : 1;
    };

    valueCheck.type = validatorTypes.VALUE_CHECK;

    return valueCheck;
}

/**
 * @param {Number} required
 * @returns {Function}
 */
export function wordCountValidator(required) {
    if (typeof required !== 'number' || required <= 0) {
        throw new Error('The `required` parameter has to be a number grater than 0');
    }

    const wordCount = (value) => {
        if (value == null || typeof value !== 'string') {
            return 0;
        }

        const wordsMatch = value.match(/\S+/g);
        const words = wordsMatch != null ? wordsMatch.length : 0;

        return clamp(words / required, 0, 1);
    };

    wordCount.required = required;
    wordCount.type = validatorTypes.WORD_COUNT;

    return wordCount;
}
