import { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import type { Validate, FieldValues, FieldPath, PathValue, Control, UseFormSetValue, Path } from 'react-hook-form';
import { typedMemo } from 'lib/react';
import { Select } from '@schibsted-svp/react-ui';
import type { ExtendedProps as SelectProps } from '@schibsted-svp/react-ui/lib/es/Select';
import { findSingleOption, getValueFromOption } from 'components/ui/Form/Select';
import { normalizeValidator } from '../../validators';
import { parseCustomOption, getDefaultOptions, appendOption, createCustomOption, validate, Option } from './helpers';

interface TimeLengthSelectProps<TFieldValues extends FieldValues> extends SelectProps<Option, false> {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    setValue: UseFormSetValue<TFieldValues>;
    defaultLabel: string;
    currentValue?: number;
    placeholder?: string;
    validate?: Validate<number, TFieldValues> | Record<string, Validate<number, TFieldValues>>;
    disabled?: boolean;
}

export const TimeLengthSelect = typedMemo(
    <TFieldValues extends FieldValues>({
        name,
        control,
        setValue,
        defaultLabel,
        currentValue,
        validate: additionalValidate,
        disabled = false,
        ...props
    }: TimeLengthSelectProps<TFieldValues>) => {
        const [options, setOptions] = useState(getDefaultOptions(defaultLabel));

        if (currentValue && !options.find(({ value }) => value === currentValue)) {
            setOptions((opts) => appendOption(opts, createCustomOption(currentValue)));
        }

        const validateCustomTimeLength = useCallback(
            (value: string): boolean => {
                const newOption = parseCustomOption(value);
                if (!newOption || newOption.value === 0) {
                    return false;
                }

                return !options.find((option) => option.value === newOption.value);
            },
            [options]
        );

        const createCustomTimeLength = useCallback(
            (value: string) => {
                const option = parseCustomOption(value);

                setOptions((opts) => appendOption(opts, option));

                /* RHF has problems with using generic form components so we need to use type assetion here */
                /* @see: https://github.com/react-hook-form/react-hook-form/discussions/7246 */
                setValue(name, option.value as PathValue<TFieldValues, Path<TFieldValues>>);
            },
            [name, setOptions, setValue]
        );

        return (
            <Controller
                name={name}
                control={control}
                rules={{ validate: { value: validate, ...normalizeValidator(additionalValidate) } }}
                render={({ field, fieldState }) => (
                    <Select
                        {...field}
                        value={findSingleOption(options)(field.value)}
                        onChange={(value) => field.onChange(getValueFromOption(value))}
                        options={options}
                        error={fieldState.error?.message}
                        onCreateOption={createCustomTimeLength}
                        isValidNewOption={validateCustomTimeLength}
                        isDisabled={disabled}
                        creatable
                        {...props}
                    />
                )}
            />
        );
    }
);
