import { Input, LabeledContainer, Dialog } from '@schibsted-svp/react-ui';
import LiveCreationDialogFooter from 'components/video/live/LiveCreationDialog/LiveCreationDialogFooter';
import LiveCreationDialogSpinner from 'components/video/live/LiveCreationDialog/LiveCreationDialogSpinner';
import * as validatorHelpers from '../validators';
import { useExternalStreamForm, useExternalStreamFormFormSubmit } from './hooks';
import type { ExternalStreamFormControl } from './types';

interface ExternalStreamFormProps {
    formControl: ExternalStreamFormControl;
}

export function ExternalStreamForm({ formControl }: ExternalStreamFormProps) {
    const { formApi } = useExternalStreamForm();

    const {
        register,
        formState: { isSubmitting, isValid, errors },
    } = formApi;

    const externalUrlValidator = { required: validatorHelpers.required, url: validatorHelpers.url };

    const handleSubmit = useExternalStreamFormFormSubmit(formApi, formControl);

    return (
        <>
            <Dialog.Section>
                <form>
                    <LabeledContainer label="Input url">
                        <Input
                            {...register('externalUrl', { validate: externalUrlValidator })}
                            error={errors?.externalUrl?.message}
                        />
                    </LabeledContainer>
                </form>
                {isSubmitting && <LiveCreationDialogSpinner />}
            </Dialog.Section>

            <LiveCreationDialogFooter
                onSubmit={handleSubmit}
                onCancel={formControl.onCancel}
                disabled={isSubmitting || !isValid}
            />
        </>
    );
}
