import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import config from 'config';

const baseQuery = fetchBaseQuery({
    baseUrl: `${config.svpApi.host}${config.svpApi.path}`,
});

export const svpApiBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
    args,
    api,
    extraOptions
) => {
    return baseQuery(
        {
            ...(typeof args !== 'string' ? args : undefined),
            url: typeof args === 'string' ? args : args.url,
            params: {
                ...(typeof args !== 'string' ? args.params : undefined),
                appName: config.svpApi.query.appName,
            },
        },
        api,
        extraOptions
    );
};
