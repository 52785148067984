import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { setAssetsListType } from 'store/ui/actions';
import { getAssetsListTypes } from 'store/ui/selectors';
import { get } from 'lodash';

import Menu from './Menu';

const mapStateToProps = (state) => ({
    assetsListType: get(getAssetsListTypes(state), 0),
});

const enhance = compose(
    connect(mapStateToProps, {
        setAssetsListType,
    })
);

export default enhance(Menu);
