import { get } from 'lodash';
import config from 'config';

const {
    assetQuality: { rules },
    defaultNewsroomConfig,
    newsroomsConfig,
} = config;

/**
 * @param {String[]|Boolean} setting
 * @param {String} searchFor
 * @returns {Boolean}
 */
export const containsEntry = (setting, searchFor) =>
    setting === true || (Array.isArray(setting) && setting.includes(searchFor));

/**
 * @param {Object} object
 * @param {String} key
 * @param {*} value
 * @returns {Object<String, *>}
 */
const asObject = (object = {}, [key, value]) => ({ ...object, [key]: value });

/**
 * @param {String} newsroom
 * @param {String} [assetType]
 * @param {Boolean} [isPodcast]
 * @returns {Object|null}
 */
function getNewsroomSettings(newsroom, assetType, isPodcast) {
    let fieldName = 'videoQuality';

    if (assetType === 'audio') {
        if (isPodcast) {
            fieldName = 'audioQuality';
        } else {
            fieldName = 'audioNotPodcastQuality';
        }
    }
    return get(newsroomsConfig, [newsroom, fieldName], defaultNewsroomConfig[fieldName]) || null;
}

/**
 * @param {String} newsroom
 * @param {String} [assetType]
 * @param {Boolean} [isPodcast]
 * @returns {Object|null}
 */
export function getQualityRulesForNewsroom(newsroom, assetType, isPodcast) {
    const settings = getNewsroomSettings(newsroom, assetType, isPodcast);

    if (!settings) {
        return null;
    }

    return Object.entries(rules)
        .filter(([ruleName]) => containsEntry(settings.rules, ruleName))
        .reduce(asObject, {});
}

/**
 * @param {String} newsroom
 * @param {String} placement
 * @param {String} [assetType]
 * @returns {Boolean}
 */
export function canRenderAssetQualityForNewsroom(newsroom, placement, assetType) {
    const settings = getNewsroomSettings(newsroom, assetType);

    if (!settings) {
        return false;
    }

    return containsEntry(settings.placements, placement);
}

/**
 * @param {String} newsroom
 * @param {Object} asset
 * @property {String} asset.streamType
 * @param {Object} category
 * @property {Boolean} category.isSeries
 * @param {Object} [draftValues={}]
 * @returns {Object|null}
 */
export function calculateMetadataScores(newsroom, asset, category = {}, draftValues = {}) {
    const { assetType } = asset;
    const isPodcast = draftValues?.additional?.metadata?.isPodcast;

    const newsroomRules = getQualityRulesForNewsroom(newsroom, assetType, isPodcast);
    if (!newsroomRules || !asset) {
        return null;
    }

    return (
        Object.entries(newsroomRules)
            // disable next video rule for non VoD type
            .filter(([, { field }]) => !field.includes('nextAsset') || asset.streamType === 'vod')
            // remove series rules if category isn't a series
            .filter(([, { name }]) => !(name === 'season' || name === 'episode') || category.isSeries)
            .map(([ruleName, { field, validator }]) => {
                const value = get(draftValues, field, get(asset, field));
                const score = validator(value);

                return [ruleName, score];
            })
            .reduce(asObject, {})
    );
}
