import { createSelector } from '@reduxjs/toolkit';
import { get, omit } from 'lodash';
import { isPlaylistOfPlaylists, LABELS, getPreferredPlaylistType } from 'screens/NewsroomScreen/PlaylistsScreen/utils';

const wasFetched = (playlist) => playlist?.id;

const arePlaylistElementsValid = (elements) => elements === null || Boolean(elements);

export const getPlaylistsList = (state) => get(state, ['playlists', 'list']);

export const getPlaylist = (state, provider, playlistId) =>
    get(state, ['playlists', 'items', provider, playlistId], {});

export const getRegularPlaylist = createSelector(getPlaylist, (playlist) => {
    if (!wasFetched(playlist)) {
        return undefined;
    }
    const duration = playlist.assets?.reduce((prev, current) => prev + current.asset.duration, 0) || 0;
    const length = playlist.assets?.length || 0;

    return {
        ...playlist,
        length,
        duration,
        assets: playlist?.assets || [],
        isRegularPlaylist: true,
    };
});

export const getStaticItemsData = (state) => {
    const staticItemsData = get(state, 'playlists.staticItemsSearch');
    return omit(staticItemsData, 'error');
};

export const getPlaylistFetchingState = (state, { newsroom: provider, playlistId }) => {
    const playlist = getPlaylist(state, provider, playlistId);
    return get(playlist, 'isFetching');
};

export const getPlaylistDeletingState = (state) => {
    const playlists = getPlaylistsList(state);
    return get(playlists, 'isDeleting', false);
};

export const isRegularPlaylist = (state, provider, playlistId) => {
    const playlist = getPlaylist(state, provider, playlistId);

    if (!playlistId || !wasFetched(playlist)) {
        return getPreferredPlaylistType() !== LABELS.POP;
    }

    return !isPlaylistOfPlaylists(playlist);
};

export const getListOfPlaylists = createSelector(
    getPlaylist,
    (state) => state,
    (playlist, state) => {
        if (!wasFetched(playlist)) {
            return undefined;
        }

        const totalDuration = playlist.items.reduce((prev, curr) => {
            const {
                playlist: { id, provider },
            } = curr;
            const singlePlaylist = getRegularPlaylist(state, provider, id);

            if (!singlePlaylist) {
                return prev;
            }
            return prev + singlePlaylist.duration;
        }, 0);

        const playlists = playlist.items.reduce((existingItems, item) => {
            const singlePlaylist = getRegularPlaylist(state, item.playlist.provider, item.playlist.id);

            if (!singlePlaylist) {
                return existingItems;
            }
            const mappedItem = {
                position: item.position,
                isStatic: true,
                playlist: {
                    id: item.playlist.id,
                    name: singlePlaylist?.name,
                    provider: item.playlist.provider,
                },
            };
            return [...existingItems, mappedItem];
        }, []);

        const { length } = playlists;

        return {
            ...playlist,
            playlists,
            length,
            duration: totalDuration,
            isRegularPlaylist: false,
        };
    }
);

/**
 * @param {Object} state
 * @param {String} provider
 * @param {String} id
 * @return {Object}
 */

export const selectPlaylist = createSelector(
    isRegularPlaylist,
    (state, provider, id) => ({
        state,
        provider,
        id,
    }),
    (isRegular, { state, provider, id }) => {
        if (isRegular) {
            return getRegularPlaylist(state, provider, id);
        }

        return getListOfPlaylists(state, provider, id);
    }
);

export const isPlaylistExisting = (state, provider, playlistId) =>
    Boolean(state.playlists?.items?.[provider]?.[playlistId]?.items);

export const isPlaylistLoaded = (state, provider, playlistId) => {
    const playlist = state.playlists?.items?.[provider]?.[playlistId];
    const items = playlist?.items;
    const elements = arePlaylistElementsValid(items);

    if (elements) {
        return elements;
    }
    return arePlaylistElementsValid(playlist?.assets);
};

export const getPlaylistFilters = (state) => get(state, ['playlists', 'filters']);
