import { DEFERRED } from '../deferred';

export const LIVE_ENCODERS_REQUEST = 'Live/LIVE_ENCODERS_REQUEST';
export const LIVE_ENCODERS_SUCCESS = 'Live/LIVE_ENCODERS_SUCCESS';
export const LIVE_ENCODERS_FAILURE = 'Live/LIVE_ENCODERS_FAILURE';

export const LIVE_STREAM_CREATION_REQUEST = 'Live/LIVE_STREAM_CREATION_REQUEST';
export const LIVE_STREAM_CREATION_SUCCESS = 'Live/LIVE_STREAM_CREATION_SUCCESS';
export const LIVE_STREAM_CREATION_FAILURE = 'Live/LIVE_STREAM_CREATION_FAILURE';

export const LIVE_STREAM_PREPARATION_REQUEST = 'Live/LIVE_STREAM_PREPARATION_REQUEST';
export const LIVE_STREAM_PREPARATION_SUCCESS = 'Live/LIVE_STREAM_PREPARATION_SUCCESS';
export const LIVE_STREAM_PREPARATION_FAILURE = 'Live/LIVE_STREAM_PREPARATION_FAILURE';

export const LIVE_STREAM_UPDATE_REQUEST = 'Live/LIVE_STREAM_UPDATE_REQUEST';
export const LIVE_STREAM_UPDATE_SUCCESS = 'Live/LIVE_STREAM_UPDATE_SUCCESS';
export const LIVE_STREAM_UPDATE_FAILURE = 'Live/LIVE_STREAM_UPDATE_FAILURE';

export const START_LIVE_STREAM_REQUEST = 'Live/START_LIVE_STREAM_REQUEST';
export const START_LIVE_STREAM_SUCCESS = 'Live/START_LIVE_STREAM_SUCCESS';
export const START_LIVE_STREAM_FAILURE = 'Live/START_LIVE_STREAM_FAILURE';

export const STOP_LIVE_STREAM_REQUEST = 'Live/STOP_LIVE_STREAM_REQUEST';
export const STOP_LIVE_STREAM_SUCCESS = 'Live/STOP_LIVE_STREAM_SUCCESS';
export const STOP_LIVE_STREAM_FAILURE = 'Live/STOP_LIVE_STREAM_FAILURE';

export const AFTER_LIVE_STREAM_START_TIME = 'live/AFTER_LIVE_STREAM_START_TIME';
export const AFTER_LIVE_STREAM_END_TIME = 'live/AFTER_LIVE_STREAM_END_TIME';

export const EDIT_LIVE_STREAM_SUCCESS = 'Live/EDIT_LIVE_STREAM_SUCCESS';
export const EDIT_LIVE_STREAM_REQUEST = 'Live/EDIT_LIVE_STREAM_REQUEST';
export const EDIT_LIVE_STREAM_FAILURE = 'Live/EDIT_LIVE_STREAM_FAILURE';

export const CANCEL_LIVE_AD_REQUEST = 'Live/CANCEL_LIVE_AD_REQUEST';
export const CANCEL_LIVE_AD_SUCCESS = 'Live/CANCEL_LIVE_AD_SUCCESS';
export const CANCEL_LIVE_AD_FAILURE = 'Live/CANCEL_LIVE_AD_FAILURE';

export const INSERT_LIVE_AD_REQUEST = 'Live/INSERT_LIVE_AD_REQUEST';
export const INSERT_LIVE_AD_SUCCESS = 'Live/INSERT_LIVE_AD_SUCCESS';
export const INSERT_LIVE_AD_FAILURE = 'Live/INSERT_LIVE_AD_FAILURE';
export const INSERT_LIVE_AD_COMPLETE = 'Live/INSERT_LIVE_AD_COMPLETE';

export const FETCH_WAS_LIVE_ASSETS = 'Live/FETCH_WAS_LIVE_ASSETS';
export const FETCH_WAS_LIVE_ASSETS_SUCCESS = 'Live/FETCH_WAS_LIVE_ASSETS_SUCCESS';
export const FETCH_WAS_LIVE_ASSETS_ERROR = 'Live/FETCH_WAS_LIVE_ASSETS_ERROR';

export const FETCH_LIVE_ASSETS = 'Live/FETCH_LIVE_ASSETS';
export const FETCH_LIVE_ASSETS_SUCCESS = 'Live/FETCH_LIVE_ASSETS_SUCCESS';
export const FETCH_LIVE_ASSETS_ERROR = 'Live/FETCH_LIVE_ASSETS_ERROR';

export const LIVE_NOTIFICATION_ADD = 'Live/LIVE_NOTIFICATION_ADD';
export const MEDIALIVE_CHANNEL_STATE_CHANGE = 'Live/MEDIALIVE_CHANNEL_STATE_CHANGE';
export const ELEMENTAL_EVENT_STATE_CHANGE = 'Live/ELEMENTAL_EVENT_STATE_CHANGE';

export const UNPUBLISH_LIVE_ASSET_REQUEST = 'Live/UNPUBLISH_LIVE_ASSET_REQUEST';
export const UNPUBLISH_LIVE_ASSET_SUCCESS = 'Live/UNPUBLISH_LIVE_ASSET_SUCCESS';
export const UNPUBLISH_LIVE_ASSET_FAILURE = 'Live/UNPUBLISH_LIVE_ASSET_FAILURE';

export const DUPLICATE_LIVE_STREAM_REQUEST = 'Live/DUPLICATE_LIVE_STREAM_REQUEST';
export const DUPLICATE_LIVE_STREAM_SUCCESS = 'Live/DUPLICATE_LIVE_STREAM_SUCCESS';
export const DUPLICATE_LIVE_STREAM_FAILURE = 'Live/DUPLICATE_LIVE_STREAM_FAILURE';
export const REMOVE_DUPLICATE_LIVE_STREAM_INFO = 'Live/REMOVE_DUPLICATE_LIVE_STREAM_INFO';

/**
 * @param {String} newsroom
 * @return {Object}
 */
export const fetchLiveEncoders = (newsroom) => ({
    type: LIVE_ENCODERS_REQUEST,
    newsroom,
});

/**
 * @param {String} newsroom
 * @param {Object} encoders
 * @param {Object[]} channels
 * @returns {Object}
 */
export const fetchingLiveEncodersSuccess = (newsroom, encoders, channels) => ({
    type: LIVE_ENCODERS_SUCCESS,
    newsroom,
    encoders,
    channels,
});

/**
 * @param {String} newsroom
 * @param {String} error
 * @returns {Object}
 */
export const fetchingLiveEncodersFailure = (newsroom, error) => ({
    type: LIVE_ENCODERS_FAILURE,
    newsroom,
    error,
});

/**
 * @param {String} newsroom
 * @param {Object<String, Any>} values
 * @return {Object}
 */
export const createLiveStream = (newsroom, values) => ({
    type: LIVE_STREAM_CREATION_REQUEST,
    newsroom,
    values,
    [DEFERRED]: true,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} source
 * @param {Number} [startTime]
 * @param {Number} [duration]
 * @returns {Object}
 */
export const creatingLiveStreamSuccess = (newsroom, assetId, source, startTime, duration) => ({
    type: LIVE_STREAM_CREATION_SUCCESS,
    newsroom,
    assetId,
    source,
    startTime,
    duration,
});

/**
 * @param {String} newsroom
 * @param {String} error
 * @returns {Object}
 */
export const creatingLiveStreamFailure = (newsroom, error) => ({
    type: LIVE_STREAM_CREATION_FAILURE,
    newsroom,
    error,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {Object} values
 * @returns {Object}
 */
export const prepareLiveStream = (newsroom, assetId, values) => ({
    type: LIVE_STREAM_PREPARATION_REQUEST,
    assetId,
    newsroom,
    values,
    [DEFERRED]: true,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const preparingLiveStreamSuccess = (newsroom, assetId) => ({
    type: LIVE_STREAM_PREPARATION_SUCCESS,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} error
 * @returns {Object}
 */
export const preparingLiveStreamFailure = (newsroom, assetId, error) => ({
    type: LIVE_STREAM_PREPARATION_FAILURE,
    assetId,
    newsroom,
    error,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {Object} values
 * @returns {Object}
 */
export const updateLiveStream = (newsroom, assetId, values) => ({
    type: LIVE_STREAM_UPDATE_REQUEST,
    newsroom,
    assetId,
    values,
    [DEFERRED]: true,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const updatingLiveStreamSuccess = (newsroom, assetId) => ({
    type: LIVE_STREAM_UPDATE_SUCCESS,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} error
 * @returns {Object}
 */
export const updatingLiveStreamFailure = (newsroom, assetId, error) => ({
    type: LIVE_STREAM_UPDATE_FAILURE,
    newsroom,
    assetId,
    error,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const editLiveStreamRequest = (newsroom, assetId) => ({
    type: EDIT_LIVE_STREAM_REQUEST,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const editLiveStreamSuccess = (newsroom, assetId) => ({
    type: EDIT_LIVE_STREAM_SUCCESS,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const editLiveStreamFailure = (newsroom, assetId) => ({
    type: EDIT_LIVE_STREAM_FAILURE,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const startLiveStream = (newsroom, assetId) => ({
    type: START_LIVE_STREAM_REQUEST,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const startingLiveStreamSuccess = (newsroom, assetId) => ({
    type: START_LIVE_STREAM_SUCCESS,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} error
 * @returns {Object}
 */
export const startingLiveStreamFailure = (newsroom, assetId, error) => ({
    type: START_LIVE_STREAM_FAILURE,
    newsroom,
    assetId,
    error,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const stopLiveStream = (newsroom, assetId) => ({
    type: STOP_LIVE_STREAM_REQUEST,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const stoppingLiveStreamSuccess = (newsroom, assetId) => ({
    type: STOP_LIVE_STREAM_SUCCESS,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} error
 * @returns {Object}
 */
export const stoppingLiveStreamFailure = (newsroom, assetId, error) => ({
    type: STOP_LIVE_STREAM_FAILURE,
    newsroom,
    assetId,
    error,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {Number} duration
 * @return {Object}
 */
export const insertLiveAd = (newsroom, assetId, duration) => ({
    type: INSERT_LIVE_AD_REQUEST,
    newsroom,
    assetId,
    duration,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @return {Object}
 */
export const insertingLiveAdSuccess = (newsroom, assetId) => ({
    type: INSERT_LIVE_AD_SUCCESS,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} error
 * @return {Object}
 */
export const insertingLiveAdFailure = (newsroom, assetId, error) => ({
    type: INSERT_LIVE_AD_FAILURE,
    newsroom,
    assetId,
    error,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const afterLiveStreamStartTime = (newsroom, assetId) => ({
    type: AFTER_LIVE_STREAM_START_TIME,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const afterLiveStreamEndTime = (newsroom, assetId) => ({
    type: AFTER_LIVE_STREAM_END_TIME,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @return {Object}
 */
export const completeLiveAdInserting = (newsroom, assetId) => ({
    type: INSERT_LIVE_AD_COMPLETE,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @return {Object}
 */
export const cancelLiveAd = (newsroom, assetId) => ({
    type: CANCEL_LIVE_AD_REQUEST,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @return {Object}
 */
export const cancellingLiveAdSuccess = (newsroom, assetId) => ({
    type: CANCEL_LIVE_AD_SUCCESS,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} error
 * @return {Object}
 */
export const cancellingLiveAdFailure = (newsroom, assetId, error) => ({
    type: CANCEL_LIVE_AD_FAILURE,
    newsroom,
    assetId,
    error,
});

export const fetchWasLiveAssets = ({ provider }) => ({
    type: FETCH_WAS_LIVE_ASSETS,
    provider,
});

export const fetchWasLiveAssetsSuccess = (assets) => ({
    type: FETCH_WAS_LIVE_ASSETS_SUCCESS,
    assetsIds: assets.map((asset) => asset.id),
    assets,
});

export const fetchWasLiveAssetsError = (error) => ({
    type: FETCH_WAS_LIVE_ASSETS_ERROR,
    error,
});

export const fetchLiveAssets = ({ provider }) => ({
    type: FETCH_LIVE_ASSETS,
    provider,
});

export const fetchLiveAssetsSuccess = (assets) => ({
    type: FETCH_LIVE_ASSETS_SUCCESS,
    assetsIds: assets.map((asset) => asset.id),
    assets,
});

export const fetchLiveAssetsError = (error) => ({
    type: FETCH_LIVE_ASSETS_ERROR,
    error,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {LiveEventNotification} data
 * @returns {Object}
 */
export const addLiveNotification = (newsroom, assetId, data) => ({
    type: LIVE_NOTIFICATION_ADD,
    newsroom,
    assetId,
    data,
});

export const changeMediaLiveState = (newsroom, assetId, data) => ({
    type: MEDIALIVE_CHANNEL_STATE_CHANGE,
    newsroom,
    assetId,
    data,
});

export const changeLiveEventState = (newsroom, assetId, data) => ({
    type: ELEMENTAL_EVENT_STATE_CHANGE,
    newsroom,
    assetId,
    data,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const unpublishLiveAsset = (newsroom, assetId) => ({
    type: UNPUBLISH_LIVE_ASSET_REQUEST,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const unpublishLiveAssetSuccess = (newsroom, assetId) => ({
    type: UNPUBLISH_LIVE_ASSET_SUCCESS,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const unpublishLiveAssetFailure = (newsroom, assetId) => ({
    type: UNPUBLISH_LIVE_ASSET_FAILURE,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @returns {Object}
 */
export const duplicateLiveStream = (newsroom, assetId) => ({
    type: DUPLICATE_LIVE_STREAM_REQUEST,
    newsroom,
    assetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {Number} newAssetId
 * @returns {Object}
 */
export const duplicateLiveStreamSuccess = (newsroom, assetId, newAssetId) => ({
    type: DUPLICATE_LIVE_STREAM_SUCCESS,
    newsroom,
    assetId,
    newAssetId,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} error
 * @returns {Object}
 */
export const duplicateLiveStreamFailure = (newsroom, assetId, error) => ({
    type: DUPLICATE_LIVE_STREAM_FAILURE,
    newsroom,
    assetId,
    error,
});

/**
 * @param {String} newsroom
 * @param {Number} assetId
 * @param {String} error
 * @returns {Object}
 */
export const removeDuplicateLiveStreamInfo = () => ({
    type: REMOVE_DUPLICATE_LIVE_STREAM_INFO,
});
