import config from 'config';
import { useSelector } from 'react-redux';
import { isLive } from 'models/asset';
import { getDraftAssetScore } from 'store/assets/selectors';
import { canRenderAssetQualityForNewsroom } from 'services/asset-quality';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { FormValues } from '../types';

const { publishingAsset: publishingAssetPlacement, publishingLiveAsset: publishingLiveAssetPlacement } =
    config.assetQuality.placements;

export function useConfirmationState({ asset, formValues }: { asset: Asset; formValues: FormValues }): {
    isPublishConfirmationRequired: boolean;
} {
    const assetQualityPlacement = isLive(asset) ? publishingLiveAssetPlacement : publishingAssetPlacement;

    const shouldRenderAssetQuality = canRenderAssetQualityForNewsroom(
        asset.provider,
        assetQualityPlacement,
        asset.assetType
    );

    const draftAssetScore = useSelector((state) => getDraftAssetScore(state, asset, formValues));

    const isPublishConfirmationRequired = shouldRenderAssetQuality && draftAssetScore < 1;
    return { isPublishConfirmationRequired };
}
