import { put, takeEvery, select, call } from '@redux-saga/core/effects';
import { unsubscribe } from 'store/mqtt/client';
import {
    videoTranscodingProgress,
    transcodeVideo,
    completeVideoTranscoding,
    videoTranscodingFailure,
} from 'store/video/upload/actions';
import { cuttingStart, cuttingProgress, cuttingComplete, cuttingFailure } from 'store/video/cutting/actions';
import { matchMqttTopic } from 'store/mqtt/listeners/utils';
import { getAsset } from 'store/assets/selectors';

function* transcodeMqttSaga({ type, data }) {
    const [, , provider, id, , action] = type.split('/');
    const { progress, error } = data;

    switch (action) {
        case 'start':
            yield put(transcodeVideo(provider, id));
            break;
        case 'progress':
            yield put(videoTranscodingProgress(provider, id, progress));
            break;
        case 'end':
            yield put(completeVideoTranscoding(provider, id));
            break;
        case 'error':
            yield put(videoTranscodingFailure(provider, id, error));
            break;
        default:
    }
}

function* cutMqttSaga({ type, data }) {
    const [, , provider, id, , action] = type.split('/');
    const { progress, error } = data;

    switch (action) {
        case 'start':
            yield put(cuttingStart(provider, id));
            break;
        case 'progress':
            yield put(cuttingProgress(provider, id, progress));
            break;
        case 'end': {
            const asset = yield select(getAsset, { provider, id });
            const originAssetId = asset?.additional?.originAssetId;
            yield put(cuttingComplete(provider, id, originAssetId));

            const topic = [`ingest/${provider}/${id}/cut/+`, `ingest/${provider}/${id}/transcode/+`];
            yield call(unsubscribe, { topic });

            break;
        }
        case 'error':
            yield put(cuttingFailure(provider, id, error));
            break;
        default:
    }
}

export default [
    takeEvery((action) => matchMqttTopic(action, 'transcode', 4), transcodeMqttSaga),
    takeEvery((action) => matchMqttTopic(action, 'cut', 4), cutMqttSaga),
];
