import config from 'config';
import { isEqual } from 'lodash';
import { Select } from '@schibsted-svp/react-ui';
import type { UseFormGetValues, UseFieldArrayReturn } from 'react-hook-form';
import type { FormValues } from '../../types';

const SETTINGS_KEY_REGEX = /experimentId|variants|(\d+)variant(\w+)/;

const { podcastExperimentsSettings: settings } = config;

type SimpleMetadata = { key: string; value: string }[];

const getSettingMetadata = (settingKey: string, assetId: number) => {
    if (!settings[settingKey]) {
        return [];
    }

    const { metadata }: { metadata: Record<string, string> } = settings[settingKey];

    return Object.entries(metadata).map(([key, rawValue]) => {
        const value = key === 'experimentId' ? String(assetId) : rawValue;
        return { key, value };
    });
};

const getValue = (simpleMetadata: SimpleMetadata, assetId: number) => {
    const settingMetadata = simpleMetadata
        .filter(({ key }) => key?.match(SETTINGS_KEY_REGEX))
        .map(({ key, value }) => ({
            key,
            value,
        }));

    if (!settingMetadata.length) {
        return { value: 'open', label: 'Open' };
    }
    const item = Object.entries(settings).find(([key]) => {
        return isEqual(settingMetadata, getSettingMetadata(key, assetId));
    });

    if (!item) {
        return { value: 'custom', label: 'Custom' };
    }

    const [value, { name: label }] = item;
    return { value, label };
};

export function PodcastExperimentsSettings({
    assetId,
    simpleMetadataControls,
    getValues,
}: {
    assetId: number;
    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;

    getValues: UseFormGetValues<FormValues>;
}) {
    const { fields, replace } = simpleMetadataControls;

    const onChange = ({ value: newSettingKey }) => {
        const simpleMetadata = getValues('additional.metadata.simpleMetadata');
        const metadata = simpleMetadata.filter(({ key }) => !key?.match(SETTINGS_KEY_REGEX));

        const settingMetadata = getSettingMetadata(newSettingKey, assetId);
        replace([...metadata, ...settingMetadata]);
    };
    const options = Object.entries(settings).map(([value, { name: label }]) => ({ value, label }));
    const value = getValue(fields, assetId);

    return <Select size="small" splitButton={false} options={options} value={value} onChange={onChange} />;
}
