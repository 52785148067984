import { CopyToClipboard } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import css from './Caption.module.scss';

type CaptionProps = {
    id: Asset['id'];
};

export function Caption({ id }: CaptionProps) {
    return (
        <div className={css.caption}>
            <span className={css.label}>ID</span>
            <CopyToClipboard text={id} title="Click to copy ID" />
        </div>
    );
}
