import { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import { useGetAccessDefinitionQuery } from 'services/svp-api-client/access-definitions';
import selectNewsroom from 'store/newsroom/actions';
import Header from 'components/header/header';
import { PlaylistsScreenAdd } from 'screens/NewsroomScreen/PlaylistsScreen/PlaylistsScreenAdd';
import { PlaylistsScreenEdit } from 'screens/NewsroomScreen/PlaylistsScreen/PlaylistsScreenEdit';
import LiveScreen from './LiveScreen';
import SettingsScreen from './SettingsScreen';
import AssetsListScreen from './AssetsListScreen';
import { AssetScreen } from './AssetScreen';
import CategoriesScreen from './CategoriesScreen';
import PlaylistsScreen from './PlaylistsScreen';

export const NewsroomScreen = memo(function NewsroomScreen() {
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const { newsroom: newsroomParam } = useParams();
    const { newsroom } = useSelector((state) => ({
        newsroom: state.newsroom,
    }));

    useEffect(() => {
        dispatch(selectNewsroom(newsroomParam));
    }, [dispatch, newsroomParam]);

    useGetAccessDefinitionQuery({ provider: newsroomParam });

    if (!newsroom) {
        return null;
    }

    return (
        <>
            <Header />
            <main>
                <Route path={`${path}/assets/:listType(linked)?`} exact>
                    <AssetsListScreen />
                </Route>
                <Route path={`${path}/assets/:id(\\d+)/:tab?`}>
                    <AssetScreen />
                </Route>
                <Route path={`${path}/live`}>
                    <LiveScreen />
                </Route>
                <Route path={`${path}/settings`}>
                    <SettingsScreen />
                </Route>
                <Route path={`${path}/(categories|category)`}>
                    <CategoriesScreen provider={newsroomParam} />
                </Route>
                <Route exact strict path={`${path}/playlists`}>
                    <PlaylistsScreen />
                </Route>

                <Route exact strict path={`${path}/playlists/add`}>
                    <PlaylistsScreenAdd />
                </Route>
                <Route exact path={`${path}/playlists/edit/:playlistId`}>
                    <PlaylistsScreenEdit />
                </Route>
            </main>
        </>
    );
});
