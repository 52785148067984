/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Notification } from '@schibsted-svp/react-ui';
import config from 'config';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useAppSelector } from 'store/hooks';
import { hasToken, isVerifying } from 'store/auth/selectors';
import { accessTokenLoaded } from 'store/auth/actions';
import { getEmailFromLocalStorage, setEmailInLocalStorage } from 'store/auth/helpers';
import authToken from 'models/authToken';
import { EntryPage, Loader, LoginForm, ProviderSelector } from 'components/Auth';
import type { LoginScreenProps, SubmitEvent } from 'components/Auth/types';
import css from './LoginScreen.module.scss';

export function LoginScreen({ children }: LoginScreenProps) {
    const [nextStep, setNextStep] = useState(false);
    const [statusCode, setStatusCode] = useState(0);
    const previousEmail = getEmailFromLocalStorage();
    const [email, setEmail] = useState(previousEmail || null);
    const [password, setPassword] = useState('');
    const [showForm, setShowForm] = useState(!email);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [enablePasswordLogin, setEnablePasswordLogin] = useState(false);
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const tokenExists = useAppSelector(hasToken);
    const isVerifyingUser = useAppSelector(isVerifying);
    const isFetchingNewsrooms = useAppSelector((state) => state.acl.isFetching);
    const dispatch = useDispatch();

    const resetStatusCode = () => {
        setStatusCode(0);
    };

    const handleAssignProvider = ({ location }) => {
        const idp: string = new URL(`${window.location.origin}${location}`).searchParams.get('idp') || '';
        setSelectedProvider(idp);
    };

    const handleAssignToken = useCallback(
        ({ token }) => {
            resetStatusCode();

            const accessToken = {
                raw: token,
                ...(authToken.parse(token) || {}),
            };
            authToken.store(accessToken);
            dispatch(accessTokenLoaded(accessToken));
        },
        [dispatch]
    );

    const handleRedirect = useCallback(
        async ({ headers }) => {
            setNextStep(true);
            const { location } = headers;

            if (location.startsWith('/sso/saml/login')) {
                handleAssignProvider({ location: headers.location });
            }

            const data = await fetch(`${window.location.origin}${headers.location}`, {
                method: 'GET',
                redirect: 'manual',
                mode: 'cors',
                credentials: 'include',
                headers,
            });
            const result = await data.text();

            if (headers.location === '/auth/token') {
                handleAssignToken({ token: result });
                return;
            }

            window.location.href = result;
        },
        [handleAssignToken]
    );

    const handleUserWithoutPassword = ({ message }) => {
        setEnablePasswordLogin(true);

        throw new Error(`${message}. Please contact us.`);
    };

    const handleWrongCredentials = ({ message }) => {
        setShowContactInfo(true);
        throw new Error(`${message}. Please contact us.`);
    };

    const handlePasswordLogin = () => {
        setShowForm(false);
        setEnablePasswordLogin(true);
    };

    const redirectToProviderSelection = () => {
        setNextStep(true);
    };

    const handleLogin = useCallback(
        async (event: SubmitEvent) => {
            event.preventDefault();

            try {
                const response = await fetch('auth/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                });

                const loginData = await response.json();
                const { status } = response;
                const { headers, message } = loginData;

                setStatusCode(status);
                setEmailInLocalStorage(email);

                switch (status) {
                    case 302:
                        handleRedirect({ headers });
                        break;
                    case 500:
                        handleUserWithoutPassword({ message });
                        break;
                    case 400:
                        handleWrongCredentials({ message });
                        break;
                    case 401:
                        handlePasswordLogin();
                        break;
                    case 404:
                        if (!enablePasswordLogin) {
                            redirectToProviderSelection();
                        }
                        break;
                    default:
                        if (!enablePasswordLogin) {
                            throw new Error("Couldn't log in");
                        }
                }
            } catch (error) {
                let errorMessage = "Couldn't log in";
                if (error instanceof Error) {
                    errorMessage = error.message;
                }
                Notification.notify.error(errorMessage);
            }
        },
        [email, enablePasswordLogin, handleRedirect, password]
    );

    useEffect(() => {
        setIsLoading(statusCode === 302 || isVerifyingUser || isFetchingNewsrooms);
    }, [isFetchingNewsrooms, isVerifyingUser, statusCode]);

    const isFirstStep = !nextStep && !isLoading;
    const isKnownUser = Boolean(email) && previousEmail !== 'undefined' && !showForm;

    if (tokenExists && !isLoading) {
        return children;
    }
    return (
        <div className={css.container}>
            <img alt="streamLogo" src={config.defaultNewsroomConfig.logo.url} className={css.streamLogo} />
            <p className={css.caption}>Stream Admin Tool</p>
            <SwitchTransition>
                <CSSTransition key={isFirstStep ? 'first' : 'next'} timeout={400} classNames={css}>
                    {isFirstStep ? (
                        <SwitchTransition>
                            <CSSTransition
                                key={isKnownUser || enablePasswordLogin ? 'known' : 'unknown'}
                                timeout={400}
                                classNames={css}
                            >
                                {isKnownUser && !enablePasswordLogin ? (
                                    <EntryPage handleLogin={handleLogin} email={email} setShowForm={setShowForm} />
                                ) : (
                                    <LoginForm
                                        handleLogin={handleLogin}
                                        email={email}
                                        setEmail={setEmail}
                                        enablePasswordLogin={enablePasswordLogin}
                                        setPassword={setPassword}
                                        loginAsSomebodyElse={showForm}
                                        showContactInfo={showContactInfo}
                                    />
                                )}
                            </CSSTransition>
                        </SwitchTransition>
                    ) : (
                        <SwitchTransition>
                            <CSSTransition key={isLoading ? 'loader' : 'ssoProviders'} timeout={400} classNames={css}>
                                {isLoading ? <Loader selectedProvider={selectedProvider} /> : <ProviderSelector />}
                            </CSSTransition>
                        </SwitchTransition>
                    )}
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}
