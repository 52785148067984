export const hlsProfile = {
    HLS1080: 'HLS1080',
} as const;

export const MediaType = {
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
} as const;

export const LiveStreamSource = {
    SDI: 'SDI',
    NETWORK: 'NETWORK',
    EXTERNAL: 'EXTERNAL',
} as const;

export const LiveStreamCustomDVR = {
    UNLIMITED: -1,
    NODVR: 0,
    STANDARD: 43200, // 12h
    ENCRYPTED_STANDARD: 21600, // 6h
} as const;

export const LiveStreamProtocol = {
    RTMP: 'RTMP',
    HLS: 'HLS',
} as const;

export const LiveStreamProtection = {
    GEOBLOCKED: 'GEOBLOCKED',
    ENCRYPTED: 'ENCRYPTED',
    TOKEN_SECURED: 'TOKEN_SECURED',
} as const;

export const StreamingProtocol = {
    HLS: 'HLS',
    RTMP: 'RTMP',
} as const;

export const StreamProtection = {
    GEOBLOCKED: 'GEOBLOCKED',
    TOKEN_SECURED: 'TOKEN_SECURED',
    ENCRYPTED: 'ENCRYPTED',
    PLAYER_VERIFIED: 'PLAYER_VERIFIED',
} as const;

export const StreamType = {
    LIVE: 'LIVE',
    VOD: 'VOD',
} as const;

export const MediaLiveSourceType = {
    SOURCE: 'SOURCE',
    BACKUP: 'BACKUP',
} as const;

export const MediaLiveInputType = {
    PULL: 'PULL',
    PUSH: 'PUSH',
    RESERVED: 'RESERVED',
} as const;
