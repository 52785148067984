import config from 'config';
import { get, mean, pick, throttle } from 'lodash';
import { api as adminBffApi } from 'services/admin-bff-sdk/generated';
import { calculateMetadataScores } from 'services/asset-quality';
import { getCategory } from 'store/categories/selectors';
import { getScheduleForAsset } from 'store/schedules/selectors';
import { SCHEDULES_SCOPE } from 'store/schedules/constants';
import { isGeoblocked, getAccess, hasFreeAccess } from 'models/asset';
import { SCHEDULE_RADIO_VALUES } from 'components/AssetForm/fields/ScheduleDialogFormVideo';

const getAssetsData = (state) => state.assets;

const getItems = (assets) => assets.items;

const getAssets = (state) => getItems(getAssetsData(state));

const getAssetId = (state, { id }) => id;

const getAssetData = (state, { id, provider }) => get(getAssets(state), [provider, id]);

const getAsset = (state, props) => get(getAssetData(state, props), 'asset');

export const getMetadata = (metadata) =>
    Object.fromEntries(
        Object.entries(metadata).reduce(
            (accumulator, [key, value]) =>
                config.asset.ignoredMeta.includes(key) ? [...accumulator, [key, value]] : accumulator,
            []
        )
    );

export const getSimpleMetadata = (metadata) =>
    Object.entries(metadata).reduce(
        (accumulator, [key, value]) =>
            !config.asset.ignoredMeta.includes(key) ? [...accumulator, { key, value }] : accumulator,
        []
    );

const prepareTagsOptions = (tagsIDs = [], tags) => tagsIDs.map((id) => ({ value: id, label: tags[id]?.tag?.tag }));
const prepareStoriesOptions = (storiesIDs = [], stories) => storiesIDs.map((id) => ({ value: id, label: stories[id] }));
const sortByTimelineAscending = (objA, objB) => objA.timeline - objB.timeline;

/**
 *
 * @param {String} provider
 * @param {Number} categoryId
 */
export const filterDraftCategory = (provider, categoryId) =>
    (categoryId !== config.asset.hiddenCategory[provider] && categoryId) || null;

const getPushNotificationsTime = (notificationSchedule, assetSchedule) => {
    if (notificationSchedule?.time && assetSchedule?.publishTime) {
        const diff = Math.abs(notificationSchedule.time - assetSchedule.publishTime);
        // if push time equals scheduled publish time (+/- 60s delay and 10s safety guard)
        // set time to null which means that push will be sent at publish time
        if (diff <= 70) {
            return null;
        }
    }
    return notificationSchedule?.time;
};

const prepareAssetFormData = (state, props) => {
    const { asset } = props;

    const { provider } = asset;
    const tags = get(state, ['tags', provider], {});
    const stories = get(state, ['stories', provider], {});

    const simpleMetadata = getSimpleMetadata(asset.additional.metadata);

    const startFlightTimes = get(asset, 'flightTimes.start');
    const endFlightTimes = get(asset, 'flightTimes.end');
    const distributeAfter = get(asset, 'additional.metadata.distributeAfter');
    const assetSchedule = getScheduleForAsset(state, { provider, id: asset.id, scope: SCHEDULES_SCOPE.ASSET });
    const notificationSchedule = getScheduleForAsset(state, {
        provider,
        id: asset.id,
        scope: SCHEDULES_SCOPE.NOTIFICATION,
    });

    asset.additional.chapters.sort(sortByTimelineAscending);
    asset.additional.cuePoints.sort(sortByTimelineAscending);

    const subtitlesDefault = get(
        get(asset, 'additional.subtitles', []).find((subtitle) => subtitle.default === true),
        'language'
    );

    const { data: { assetPodmeMetadata } = {} } = adminBffApi.endpoints.AssetPodmeMetadata.select({
        provider,
        assetId: asset.id,
    })(state);

    return {
        assetId: asset.id,
        provider,
        title: asset.title,
        titleFront: asset.titleFront,
        description: asset.description,
        descriptionFront: asset.descriptionFront,
        status: asset.status,
        playback: asset.playback,
        flightTimesStartDateTime: startFlightTimes,
        flightTimesEndDateTime: endFlightTimes,
        category: {
            id: filterDraftCategory(provider, asset.category.id),
        },
        articleUrl: asset.articleUrl,
        additional: {
            originAssetId: asset.additional.originAssetId,
            chapters: asset.additional.chapters.map(({ title, timeline }) => ({
                title,
                timeline,
            })),
            cuePoints: asset.additional.cuePoints,
            tags: prepareTagsOptions(asset.additional.tags, tags),
            stories: prepareStoriesOptions(asset.additional.stories, stories),
            subtitles: asset.additional.subtitles,
            settings: {
                showAds: asset.additional.settings.showAds,
                source: asset.additional.settings.source,
                ageLimit: asset.additional.settings.ageLimit,
                notes: asset.additional.settings.notes,
                showInNewest: asset.additional.settings.showInNewest,
            },
            metadata: {
                contentType: asset.additional.metadata.contentType,
                mood: asset.additional.metadata.mood,
                newsValue: asset.additional.metadata.newsValue,
                newsLifetime: asset.additional.metadata.newsLifetime,
                silentplay: asset.additional.metadata.silentplay === 'true',
                disableNextVideo: !!asset.additional.metadata.disableNextVideo,
                isPodcast: !!asset.additional.metadata.isPodcast,
                livingThumbnail: !!asset.additional.metadata.livingThumbnail,
                titleShortPath: asset.additional.metadata.titleShortPath,
                podcast_guid: get(asset, 'additional.metadata.podcast_guid'),
                podcast_episodeType: get(asset, 'additional.metadata.podcast_episodeType'),
                distributeAfter,
                richDescription: get(asset, 'additional.metadata.richDescription', null),
                ...pick(
                    asset.additional.metadata,
                    'liveDuration',
                    'eventStartTime',
                    'studioStartTime',
                    'notificationSent',
                    'chunkDuration',
                    'seoTitle',
                    'seoDescription',
                    'bylineName',
                    'bylineEmail',
                    'hasSubtitlesInHls',
                    'tts_disabled'
                ),
            },
        },
        simpleMetadata,
        protections: {
            geoRestriction: isGeoblocked(asset),
            accessLevel: hasFreeAccess(asset) ? 'free' : getAccess(asset)?.[0],
        },
        series: {
            seasonNumber: asset.series && asset.series.seasonNumber,
            episodeNumber: asset.series && asset.series.episodeNumber,
        },
        streamUrls: { hls: asset.streamUrls.hls },
        images: {
            main: asset.images.main,
        },
        subtitlesDefault,
        scheduleDateTime: assetSchedule?.publishTime || null,
        // radio for regular assets
        scheduleRadioValue: assetSchedule ? SCHEDULE_RADIO_VALUES.PUBLISHER : SCHEDULE_RADIO_VALUES.FLIGHT_TIMES,
        // used when asset is wasLive and user want to change status to live
        shouldBackToLive: false,
        pushNotifications: {
            send: Boolean(notificationSchedule),
            content: notificationSchedule?.notificationBody || notificationSchedule?.notificationData?.push?.body,
            time: getPushNotificationsTime(notificationSchedule, assetSchedule),
        },
        sendToPodme: assetPodmeMetadata?.sendToPodme || false,
    };
};

const selectFormInitialData = (state, props) => {
    const asset = getAsset(state, props);

    if (!asset) {
        return null;
    }

    return prepareAssetFormData(state, { ...props, asset });
};

/**
 * @param {Object} state
 * @param {Number} assetId
 * @param {String} provider
 * @returns {Object|null}
 */
const getMetadataScores = (state, assetId, provider) => {
    const asset = getAsset(state, { id: assetId, provider });
    const category = getCategory(state, { id: asset.category.id, provider });

    return asset && provider ? calculateMetadataScores(provider, asset, category) : undefined;
};

/**
 * @param {Object} state
 * @param {Number} assetId
 * @param {String} provider
 * @returns {Number|null}
 */
const getAssetScore = (state, assetId, provider) => {
    const metadataScore = getMetadataScores(state, assetId, provider);

    return metadataScore ? mean(Object.values(metadataScore)) : null;
};

/**
 * @param {Object} state
 * @param {Number} assetId
 * @param {String} newsroom
 * @param {Object} draftValues
 * @returns {Object|null}
 */
const getDraftMetadataScores = throttle((state, assetId, provider, draftValues) => {
    const asset = getAsset(state, { id: assetId, provider });
    const category = getCategory(state, { id: asset.category.id, provider });
    return calculateMetadataScores(provider, asset, category, draftValues);
}, 100);

/**
 * @param {Object} state
 * @param {Object} asset
 * @param {Object} draftValues
 * @returns {Number|null}
 */
const getDraftAssetScore = (state, asset, draftValues) => {
    const metadataScore = getDraftMetadataScores(state, asset.id, asset.provider, draftValues);
    return metadataScore ? mean(Object.values(metadataScore)) : null;
};

export {
    selectFormInitialData,
    getAssetsData,
    getItems,
    getAssets,
    getAssetId,
    getAssetData,
    getAsset,
    getMetadataScores,
    getAssetScore,
    getDraftAssetScore,
    getDraftMetadataScores,
    prepareAssetFormData,
};
