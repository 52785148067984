import classnames from 'classnames/bind';
import { debounce } from 'lodash';
import type { MouseEventHandler, PropsWithChildren, UIEventHandler } from 'react';
import { forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { getOffset, getScrollDelta } from './helpers';
import { useSubscribeTimelineState } from './hooks/useTimelineState';
import { useContainerHandlers } from './hooks/useContainerHandlers';
import { useTimelineRef, type TimelineRef } from './hooks/useTimelineRef';
import { round } from './utils';
import { TimelineMarker } from './TimelineMarker';
import { TimelineTooltips } from './TimelineTooltips';
import css from './TimelineContainer.module.scss';

const cln = classnames.bind(css);

type TimelineContainerProps = PropsWithChildren<{
    className?: string;
}>;

export const TimelineContainer = forwardRef<TimelineRef, TimelineContainerProps>(function TimelineContainer(
    { children, className },
    ref
) {
    const containerRef = useRef<HTMLDivElement>(null);
    const method = useSubscribeTimelineState((state) => state.method);
    const totalDuration = useSubscribeTimelineState((state) => state.totalDuration || state.duration);
    const pixelsPerSecond = useSubscribeTimelineState((state) => state.pixelsPerSecond);
    const { changeOffset, changeScroll, changeViewport } = useContainerHandlers();

    useTimelineRef(ref, containerRef);

    const handleMouseLeave = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
        changeOffset(undefined);
    }, [changeOffset]);

    const handleMouseMove = useCallback<MouseEventHandler<HTMLDivElement>>(
        (event) => changeOffset(getOffset(event)),
        [changeOffset]
    );

    const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>(
        (event) => {
            const { scrollLeft, clientWidth } = event.currentTarget;
            changeScroll(scrollLeft);
            changeViewport(scrollLeft, clientWidth);
        },
        [changeScroll, changeViewport]
    );

    useEffect(() => {
        const { current: container } = containerRef;

        const handleContainerWheel = (event: WheelEvent) => {
            if (container) {
                event.preventDefault();
                container.scrollLeft += getScrollDelta(event);
            }
        };

        container?.addEventListener('wheel', handleContainerWheel, { passive: false, capture: true });
        return () => container?.removeEventListener('wheel', handleContainerWheel, { capture: true });
    }, []);

    useEffect(() => {
        const handleWindowResize = debounce(() => {
            const { current: container } = containerRef;
            if (container) {
                changeViewport(container.scrollLeft, container.clientWidth);
            }
        }, 200);

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, [changeViewport]);

    useLayoutEffect(() => {
        const { current: container } = containerRef;
        if (container) {
            changeViewport(container.scrollLeft, container.clientWidth);
        }
    }, [changeViewport]);

    const style = useMemo(
        () => ({ width: `max(100%, ${round(totalDuration * pixelsPerSecond)}px)` }),
        [totalDuration, pixelsPerSecond]
    );

    return (
        <div
            className={cln(css.wrapper, className, { [`${css[`wrapper-${method}`]}`]: method !== undefined })}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
        >
            <div className={css.container} onScroll={handleScroll} ref={containerRef}>
                <div className={css.content} style={style}>
                    {children}
                </div>
            </div>

            {method ? <TimelineTooltips containerRef={containerRef} /> : <TimelineMarker />}
        </div>
    );
});
