import {
    SERIES_FETCH,
    SERIES_FETCH_SUCCESS,
    SERIES_FETCH_ERROR,
    SERIES_ADD,
    SERIES_ADD_SUCCESS,
    SERIES_UPDATE,
    SERIES_UPDATE_SUCCESS,
    SERIES_DELETE,
    SERIES_DELETE_SUCCESS,
} from './actionTypes';

/**
 * @param {String} provider
 * @param {Number} categoryId
 */
export const seriesFetch = (provider, categoryId) => ({
    type: SERIES_FETCH,
    provider,
    categoryId,
});

/**
 * @param {String} provider
 * @param {Number} categoryId
 * @param {Array<Series>} series
 */
export const seriesFetchSuccess = (provider, categoryId, series) => ({
    type: SERIES_FETCH_SUCCESS,
    provider,
    categoryId,
    series,
});

/**
 * @param {String} provider
 * @param {Number} categoryId
 * @param {String} error
 */
export const seriesFetchError = (provider, categoryId, error) => ({
    type: SERIES_FETCH_ERROR,
    provider,
    categoryId,
    error,
});

/**
 * @param {String} provider
 * @param {Number} categoryId
 * @param {Number} seasonNumber
 * @param {String} seasonTitle
 */
export const seriesAdd = (provider, categoryId, seasonNumber, seasonTitle) => ({
    type: SERIES_ADD,
    provider,
    categoryId,
    seasonNumber,
    seasonTitle,
    meta: {
        thunk: true,
    },
});

/**
 * @param {String} provider
 * @param {Number} categoryId
 * @param {Array<Series>} series
 * @param {Object} meta
 *
 */
export const seriesAddSuccess = (provider, categoryId, series, meta) => ({
    type: SERIES_ADD_SUCCESS,
    provider,
    categoryId,
    series,
    meta,
});

export const seriesUpdate = (provider, categoryId, seasonNumber, seasonTitle) => ({
    type: SERIES_UPDATE,
    provider,
    categoryId,
    seasonNumber,
    seasonTitle,
    meta: {
        thunk: true,
    },
});

export const seriesUpdateSuccess = (provider, categoryId, series, meta) => ({
    type: SERIES_UPDATE_SUCCESS,
    provider,
    categoryId,
    series,
    meta,
});

export const seriesDelete = (provider, categoryId, seasonNumber) => ({
    type: SERIES_DELETE,
    provider,
    categoryId,
    seasonNumber,
    meta: {
        thunk: true,
    },
});

export const seriesDeleteSuccess = (provider, categoryId, seasonNumber, meta) => ({
    type: SERIES_DELETE_SUCCESS,
    provider,
    categoryId,
    seasonNumber,
    meta,
});
