import { useFieldArray, type Control } from 'react-hook-form';
import type { MouseEvent } from 'react';
import classnames from 'classnames/bind';
import { MdAccessTime, MdDelete } from 'react-icons/md';
import { Button } from '@schibsted-svp/react-ui';
import AssetPreview from 'components/video/AssetPreview';
import EmbedDialog from 'components/video/embed/EmbedDialog';
import FormattedTime from 'components/core/FormattedTime';
import type { FormValues } from 'components/AssetForm/types';
import { usePlayer } from 'hooks/usePlayer';
import HighlightForm from './HighlightForm';
import css from './Highlights.module.scss';

const cln = classnames.bind(css);

type HighlightsProps = {
    id: number;
    provider: string;
    duration: number;
    control: Control<FormValues>;
    isSubmitting: boolean;
};

export function Highlights({ id, provider, duration, control, isSubmitting }: HighlightsProps) {
    const { fields, insert, remove } = useFieldArray({
        control,
        name: 'additional.chapters',
    });

    const player = usePlayer();

    const addHighlight = ({ title, startTime: timeline }: { title: string; startTime: number }) => {
        const index = fields.filter((chapter) => chapter.timeline < timeline).length;
        insert(index, { title, timeline });
    };

    const handleHighlightClick = (event) => {
        if (event.currentTarget.dataset.time) {
            player?.seek(event.currentTarget.dataset.time);
        }
    };

    const handleRemoveClick = (index: number) => (event: MouseEvent) => {
        event.stopPropagation();
        remove(index);
    };

    const excluded = fields
        .map((item) => item.timeline)
        .filter((timeline, index, array) => array.indexOf(timeline) === index);

    return (
        <section className={css.highlights}>
            <HighlightForm
                onSubmit={addHighlight}
                submitDisabled={isSubmitting}
                excluded={excluded}
                className={css.greybox}
                duration={duration}
            />
            {fields.length > 0 && (
                <div className={cln('list')} data-testid="higlightsList">
                    {fields.map((_, index) => {
                        const highlight = fields[index];

                        return (
                            highlight && (
                                <div
                                    data-testid="highlightContainer"
                                    data-time={highlight.timeline}
                                    key={`highlight${highlight.timeline}`}
                                    className={css.highlight}
                                    onClick={handleHighlightClick}
                                >
                                    <div className={css['preview-container']}>
                                        <AssetPreview
                                            id={id}
                                            provider={provider}
                                            width={124}
                                            aspectRatio={124 / 70}
                                            frame={highlight.timeline}
                                            className={css.preview}
                                        />
                                        <div className={css['preview-hover']}>
                                            <EmbedDialog
                                                id={id}
                                                provider={provider}
                                                buttonClassName={css['embed-button']}
                                                startTime={highlight.timeline}
                                            />
                                            <Button
                                                size="small"
                                                iconOnly
                                                type="button"
                                                className={css['delete-button']}
                                                onClick={handleRemoveClick(index)}
                                            >
                                                <MdDelete size={19} className={css.deleteIcon} />
                                            </Button>
                                        </div>
                                    </div>
                                    <span className={css.title}>{highlight.title}</span>
                                    <span className={css.time}>
                                        <MdAccessTime size={18} />
                                        <FormattedTime seconds={highlight.timeline} />
                                    </span>
                                </div>
                            )
                        );
                    })}
                </div>
            )}
        </section>
    );
}
