import { DEFERRED } from '../deferred';

export const SCHEDULES_FETCH = 'Schedules/FETCH';
export const SCHEDULES_FETCH_SUCCESS = 'Schedules/FETCH_SUCCESS';
export const SCHEDULES_FETCH_ERROR = 'Schedules/FETCH_ERROR';

export const SCHEDULE_CREATE = 'Schedules/CREATE';
export const SCHEDULE_CREATE_SUCCESS = 'Schedules/CREATE_SUCCESS';

export const SCHEDULE_UPDATE = 'Schedules/UPDATE';
export const SCHEDULE_UPDATE_SUCCESS = 'Schedules/UPDATE_SUCCESS';

export const SCHEDULE_DELETE = 'Schedules/DELETE';
export const SCHEDULE_DELETE_SUCCESS = 'Schedules/DELETE_SUCCESS';

export const schedulesFetch = ({ provider, assetId, scope, action }) => ({
    type: SCHEDULES_FETCH,
    payload: { provider, assetId, scope, action },
});

export const schedulesFetchSuccess = ({ provider, scope, schedules }) => ({
    type: SCHEDULES_FETCH_SUCCESS,
    payload: { provider, scope, schedules },
});

export const schedulesFetchError = ({ provider, scope }) => ({
    type: SCHEDULES_FETCH_ERROR,
    payload: { provider, scope },
    error: true,
});

export const scheduleCreate = ({ provider, assetId, scope, action, time, ...rest }) => ({
    type: SCHEDULE_CREATE,
    payload: { provider, assetId, scope, action, time, ...rest },
    [DEFERRED]: true,
});

export const scheduleCreateSuccess = ({ provider, assetId, scope, schedule }) => ({
    type: SCHEDULE_CREATE_SUCCESS,
    payload: { provider, assetId, scope, schedule },
});

export const scheduleUpdate = ({ provider, assetId, scope, action, time, ...rest }) => ({
    type: SCHEDULE_UPDATE,
    payload: { provider, assetId, scope, action, time, ...rest },
    [DEFERRED]: true,
});

export const scheduleUpdateSuccess = ({ provider, assetId, scope, schedule }) => ({
    type: SCHEDULE_UPDATE_SUCCESS,
    payload: { provider, assetId, scope, schedule },
});

export const scheduleDelete = ({ provider, assetId, scope, action }) => ({
    type: SCHEDULE_DELETE,
    payload: { provider, assetId, scope, action },
    [DEFERRED]: true,
});

export const scheduleDeleteSuccess = ({ provider, assetId, scope, action }) => ({
    type: SCHEDULE_DELETE_SUCCESS,
    payload: { provider, assetId, scope, action },
});
