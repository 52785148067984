import { difference } from 'lodash';

export const getStories = (state) => state.stories;

export const getMissingStories = (state, provider, playlistStoriesIds) => {
    const stories = state?.stories?.[provider] || {};
    const fetchedStories = Object.entries(stories?.[provider] || {}).filter(([, item]) => item.story !== null);
    const fetchedStoriesIds = fetchedStories.flatMap(([id]) => id);

    return difference(playlistStoriesIds, fetchedStoriesIds);
};
