import { useSelector } from 'react-redux';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { useGetAccessDefinitionNameQueryState } from 'services/svp-api-client/access-definitions';
import { getTags } from 'store/tags/selectors';
import { getSeries } from 'store/series/selectors';
import { getAccess, hasFreeAccess, isGeoblocked } from 'models/asset';
import { useGetAuthorsQuery } from 'services/admin-bff-sdk/generated';
import { useGetStoriesQuery } from 'services/admin-bff-sdk';

export function useTagsData(assetData: Asset) {
    const allTags = useSelector((state) => getTags(state, assetData.provider));
    return assetData.additional.tags?.map((id) => allTags[id]?.tag).filter(Boolean);
}

export function useStoriesData({ provider, additional: { stories } }: Asset) {
    const { data } = useGetStoriesQuery({ provider, ids: stories }, { skip: !stories.length });
    return data?.getStories.map((story) => story.title) || [];
}

export function useSeriesData(assetData: Asset) {
    const allSeries = useSelector((state) =>
        getSeries({ state, provider: assetData.provider, categoryId: assetData.category?.id })
    );
    return (
        assetData.series && {
            season: allSeries?.find(({ value }) => value === assetData.series.seasonNumber)?.label,
            episode: assetData.series.episodeNumber,
        }
    );
}

export function useViewerAccessInfo(assetData: Asset) {
    const accessName = useGetAccessDefinitionNameQueryState({
        provider: assetData.provider,
        accessKey: getAccess(assetData)?.[0],
    });
    return [hasFreeAccess(assetData) ? 'open encryption' : accessName, isGeoblocked(assetData) && 'geoblocked']
        .filter(Boolean)
        .join(', ');
}

export function useAuthors(provider: string, authorsString: string) {
    let authors = [];
    try {
        authors = JSON.parse(authorsString);
    } catch (error) {} // eslint-disable-line no-empty

    const { data: authorsData } = useGetAuthorsQuery({ provider, query: authors });
    return authorsData?.getAuthors?.map(({ title }) => title);
}

export function useMetadata(assetData: Asset) {
    const {
        provider,
        additional: { metadata },
    } = assetData;

    const authors = useAuthors(provider, metadata?.authors);

    if (!metadata || !Object.entries(metadata).length) {
        return undefined;
    }

    return Object.entries(metadata).map(([key, value]) => {
        if (key === 'authors' && authors?.length) {
            return { key, value: authors.join(', ') };
        }

        return { key, value };
    });
}
