/* global __RELEASE__ */
import { versionClient } from 'services';
import { put, call, select, delay } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import config from 'config';
import { setVersion } from './actions';
import getAdminVersion from './selectors';

export function* watchVersionUpdate() {
    yield put(setVersion(__RELEASE__));

    while (true) {
        try {
            yield delay(config.adminUpdateChecksThreshold);

            const prevVersion = yield select(getAdminVersion);
            let newVersion = yield call(versionClient.getAdminVersion);
            newVersion = newVersion.trim();

            if (newVersion !== prevVersion) {
                yield put(setVersion(newVersion));
            }
        } catch (error) {
            reportMessageToSentry({
                message: 'Failed to set admin version',
                extras: {
                    error,
                },
            });
        }
    }
}

export default watchVersionUpdate();
