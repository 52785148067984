import { connect } from 'react-redux';
import { getCategories } from 'store/categories/selectors';
import { categoriesFetch } from 'store/categories/actions';
import CategoriesScreen from './CategoriesScreen';

export default connect(
    (state, { provider }) => {
        const categories = getCategories(state, { provider });

        return {
            provider,
            categories: categories || { items: [] },
            shouldFetchCategories: categories === undefined,
        };
    },
    { categoriesFetch }
)(CategoriesScreen);
