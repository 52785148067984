import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { toHashMap } from 'lib/array';

export const mapSeriesItems = (i) => ({
    value: i.seasonNumber,
    label: i.title,
});

export const getSeries = createSelector(
    (data) => data.state.series,
    (data) => data.provider,
    (data) => data.categoryId,
    (series, provider, categoryId) => get(series, `${provider}[${categoryId}].items`, []).map(mapSeriesItems)
);

export const getSeasonsData = (state, { provider, categoryId }) => get(state, ['series', provider, categoryId]);
export const getSeasonsArray = (...args) => get(getSeasonsData(...args), 'items', []);
export const getSeasonsMap = (...args) => toHashMap(getSeasonsArray(...args), 'seasonNumber');
export const getFetchingFlag = (...args) => get(getSeasonsData(...args), 'status') === 'loading';
export const getErrorFlag = (...args) => get(getSeasonsData(...args), 'status') === 'error';
export const getNextData = (state, { provider, categoryId }) => get(state, ['series', provider, categoryId, 'next']);
export const getIsFetchNextLoading = (...args) => get(getNextData(...args), 'status') === 'loading';
