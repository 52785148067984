import { useController } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import { LabeledContainer } from '@schibsted-svp/react-ui';
import Rating from 'components/video/Rating';
import type { FormValues } from '../../types';
import css from './NewsValue.module.scss';

const VALUE_OPTIONS = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

type NewsValueProps = {
    control: Control<FormValues>;
};

export function NewsValue({ control }: NewsValueProps) {
    const { field } = useController({ name: 'additional.metadata.newsValue', control });

    return (
        <LabeledContainer label="news value">
            <Rating
                options={VALUE_OPTIONS}
                value={field.value && Number(field.value)}
                onChange={(value) => field.onChange(String(value))}
                containerClassName={css.levels}
            />
        </LabeledContainer>
    );
}
