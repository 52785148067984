import { get } from 'lodash';
import { unique } from 'lib/array';
import { merge } from 'lib/object';
import {
    FETCH_CLIPS,
    FETCH_CLIPS_SUCCESS,
    FETCH_CLIPS_ERROR,
    CREATE_CLIP,
    CREATE_CLIP_START,
    CREATE_CLIP_ERROR,
    TRIM_VIDEO,
    TRIM_VIDEO_START,
    TRIM_VIDEO_ERROR,
    RESET_TRIM,
    RESET_TRIM_SUCCESS,
    RESET_TRIM_FAILURE,
} from './actions';

/**
 * @param {Object} state
 * @param {String} provider
 * @param {Number} assetId
 * @param {Array<Number>|Number} [items=[]]
 * @returns {Array<Number>}
 */
export function itemsReducer(state, provider, assetId, items = []) {
    const currentItems = get(state, `${provider}.${assetId}.items`, []);
    return unique([].concat(currentItems, items));
}

/**
 * @param {Object} state
 * @param {Object} action
 * @param {Number} action.assetId
 * @param {String} action.provider
 * @param {Object} data
 * @returns {Object}
 */
export function dataReducer(state, action, data) {
    const { assetId, provider } = action;
    return merge(state, { [provider]: { [assetId]: data } });
}

/**
 * Clips reducer
 * @param {Object} [state={}]
 * @param {Object} action
 * @returns {Object}
 */
export default function clipsReducer(state = {}, action) {
    const { assetId, provider } = action;

    switch (action.type) {
        case FETCH_CLIPS: {
            const { nextUrl } = action;
            return dataReducer(state, action, {
                listLoading: true,
                nextUrl,
            });
        }

        case FETCH_CLIPS_SUCCESS: {
            const { nextUrl, assets } = action;
            const newAssetsIds = assets.map((asset) => asset.id);
            return dataReducer(state, action, {
                listLoading: false,
                items: itemsReducer(state, provider, assetId, newAssetsIds),
                nextUrl,
            });
        }

        case FETCH_CLIPS_ERROR: {
            const { error } = action;
            return dataReducer(state, action, {
                listLoading: false,
                error,
            });
        }

        case CREATE_CLIP: {
            return dataReducer(state, action, {
                createClipPending: true,
            });
        }

        case CREATE_CLIP_START: {
            const { newAssetId } = action;
            return dataReducer(state, action, {
                createClipPending: false,
                items: itemsReducer(state, provider, assetId, newAssetId),
            });
        }

        case CREATE_CLIP_ERROR: {
            const { error } = action;
            return dataReducer(state, action, {
                createClipPending: false,
                error,
            });
        }

        case TRIM_VIDEO: {
            return dataReducer(state, action, {
                trimPending: true,
            });
        }

        case TRIM_VIDEO_START: {
            return dataReducer(state, action, {
                trimPending: false,
            });
        }

        case TRIM_VIDEO_ERROR: {
            const { error } = action;
            return dataReducer(state, action, {
                trimPending: false,
                error,
            });
        }

        case RESET_TRIM: {
            return dataReducer(state, action, {
                resetPending: true,
            });
        }

        case RESET_TRIM_SUCCESS: {
            return dataReducer(state, action, {
                resetPending: false,
            });
        }

        case RESET_TRIM_FAILURE: {
            const { error } = action;

            return dataReducer(state, action, {
                resetPending: false,
                error,
            });
        }

        default:
            return state;
    }
}
