import { useCallback, useEffect, useState } from 'react';
import type { Optional } from 'types';
import type { SubtitlesUploadedData } from 'components/AssetForm/fields/Subtitles/types';
import { parseVttContent } from '../helpers';
import type { SubtitleCue } from '../types';

export function useSubtitleCuesFetch(subtitle: SubtitlesUploadedData) {
    const [isLoading, setIsLoading] = useState(false);
    const [subtitleCues, setSubtitleCues] = useState<Optional<SubtitleCue[]>>(undefined);

    const fetchSubtitleCues = useCallback(async (subtitleUrl: string) => {
        try {
            setIsLoading(() => true);
            const response = await fetch(subtitleUrl);
            const data = await response.text();
            const cues = await parseVttContent(data);
            setIsLoading(() => false);
            setSubtitleCues(cues);
        } catch (e) {
            setIsLoading(() => false);
        }
    }, []);

    useEffect(() => {
        fetchSubtitleCues(subtitle.url);
    }, [fetchSubtitleCues, subtitle.url]);

    return {
        subtitleCues,
        isLoading,
    };
}
