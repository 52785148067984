import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useGetAuthorsQuery } from 'services/admin-bff-sdk/generated';

export function useAuthorsGet({ provider, delay }: { provider: string; delay: number }) {
    const [ids, setIds] = useState([]);
    const onAuthorsGet = useMemo<typeof setIds>(() => debounce(setIds, delay), [delay, setIds]);
    const { data, ...rest } = useGetAuthorsQuery({ provider, query: ids });

    return {
        ...rest,
        onAuthorsGet,
        data,
        ids,
    };
}
