import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { createVideo } from 'store/video/upload/actions';
import { filtersClear } from 'store/asset-list/actions';
import { withNewsroom } from 'components/NewsroomsList/with-newsroom';
import AssetListScreen from './AssetListScreen';

const enhance = compose(
    withNewsroom,
    connect(null, {
        createVideo,
        filtersClear,
    })
);

export default enhance(AssetListScreen);
