export const CATEGORIES_FETCH = 'Categories/CATEGORIES_FETCH';
export const CATEGORIES_FETCH_SUCCESS = 'Categories/CATEGORIES_FETCH_SUCCESS';
export const CATEGORIES_FETCH_ERROR = 'Categories/CATEGORIES_FETCH_ERROR';
export const CATEGORY_FETCH = 'Categories/CATEGORY_FETCH';
export const CATEGORY_FETCH_SUCCESS = 'Categories/CATEGORY_FETCH_SUCCESS';
// to indicate that categories have already been fetched and cached so no SUCCESS/ERROR case will be dispatched
export const CATEGORIES_FETCHED = 'Categories/CATEGORIES_FETCHED';
export const CATEGORY_SUBMISSION = 'Categories/SUBMISSION';
export const CATEGORY_SUBMISSION_SUCCESS = 'Categories/SUBMISSION_SUCCESS';
export const CATEGORY_SUBMISSION_ERROR = 'Categories/SUBMISSION_ERROR';
export const CATEGORY_VISIBILITY_TOGGLE = 'Categories/CATEGORY_VISIBILITY_TOGGLE';
export const CATEGORY_VISIBILITY_TOGGLE_SUCCESS = 'Categories/CATEGORY_VISIBILITY_TOGGLE_SUCCESS';
export const CATEGORY_SET_SERIES_FLAG = 'Categories/CATEGORY_SET_SERIES_FLAG';
