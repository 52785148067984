import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { playlistsFiltersClear } from 'store/playlists/filters';
import { selectNewsroom } from 'store/newsroom/selectors';
import { PlaylistsScreenGenerator } from 'screens/NewsroomScreen/PlaylistsScreen/PlaylistsGenerator';

export function PlaylistsScreenAdd() {
    const provider = useSelector(selectNewsroom);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(playlistsFiltersClear(provider));
    }, [dispatch, provider]);

    return <PlaylistsScreenGenerator mode="new" provider={provider} />;
}
