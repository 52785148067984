import { FocusEventHandler, ReactElement } from 'react';
import { Control, useController } from 'react-hook-form';
import { TimeInput } from 'components/ui/Form/TimeInput';
import type { SubtitlesFormValues } from './types';

type SubtitleCueTimeInputProps = {
    className?: string;
    control: Control<SubtitlesFormValues>;
    icon?: ReactElement;
    name: `cues.${number}.startTime` | `cues.${number}.endTime`;
    onFocus?: FocusEventHandler<HTMLInputElement>;
};

export function SubtitleCueTimeInput({
    className,
    control,
    icon = undefined,
    name,
    onFocus,
}: SubtitleCueTimeInputProps) {
    const { field } = useController({ name, control });
    return (
        <TimeInput
            value={field.value}
            onValueChange={(v) => field.onChange(v)}
            size="compact"
            className={className}
            icon={icon}
            onBlur={() => field.onBlur()}
            onFocus={onFocus}
            precise
        />
    );
}
