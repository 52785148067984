import { ChangeEvent, useCallback } from 'react';
import { Checkbox } from '@schibsted-svp/react-ui';
import css from './PodcastAdsLimits.module.scss';

const getAdsLimits = (limitsString: string) => {
    const [pre, mid, post] = limitsString?.split(',') || [];

    return {
        pre: pre !== '0',
        mid: mid !== '0',
        post: post !== '0',
    };
};

type AdPosition = 'pre' | 'mid' | 'post';

function AdCheckbox({
    position,
    checked,
    onChange,
}: {
    position: AdPosition;
    checked: boolean;
    onChange: (adPosition: AdPosition) => (event: ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Checkbox
            name={position}
            label={position}
            checked={checked}
            onChange={onChange(position)}
            containerClassName={css.checkbox}
        />
    );
}

export function PodcastAdsLimits({ value, onChange }: { value: string; onChange: (val: string) => void }) {
    const { pre, mid, post } = getAdsLimits(value);

    const handleCheckboxChange = useCallback(
        (position: AdPosition) => {
            return (event: ChangeEvent<HTMLInputElement>) => {
                const isChecked = event.target.checked;

                const newValuesArray = [
                    position === 'pre' ? isChecked : pre,
                    position === 'mid' ? isChecked : mid,
                    position === 'post' ? isChecked : post,
                ];
                const newValue = newValuesArray.map((element) => (element ? '1' : '0')).join(',');
                onChange(newValue);
            };
        },
        [onChange, pre, mid, post]
    );

    return (
        <div className={css.container}>
            <AdCheckbox position="pre" checked={pre} onChange={handleCheckboxChange} />
            <AdCheckbox position="mid" checked={mid} onChange={handleCheckboxChange} />
            <AdCheckbox position="post" checked={post} onChange={handleCheckboxChange} />
        </div>
    );
}
