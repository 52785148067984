import { Checkbox, Button } from '@schibsted-svp/react-ui';
import { getTimestamp } from 'lib/time';
import { DateTimeMergedRHF } from 'components/video/edit/Form/DateTimeMerged';
import { type Control, useWatch, useController, type UseFormSetValue } from 'react-hook-form';
import type { FormValues } from '../types';

import css from './ScheduleDialogFormAudio.module.scss';

type ScheduleDialogFormAudioProps = {
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;
};

export function ScheduleDialogFormAudio({ control, setValue }: ScheduleDialogFormAudioProps) {
    const [schedulePublishTime, distributeAfter] = useWatch({
        control,
        name: ['schedulePublishTime', 'additional.metadata.distributeAfter'],
    });
    const { field: schedulePublishTimeField } = useController({ name: 'schedulePublishTime', control });
    const { field: distributeAfterField } = useController({ name: 'additional.metadata.distributeAfter', control });

    const synchronizeDate = () => {
        setValue('additional.metadata.distributeAfter', schedulePublishTime.toString());
    };
    return (
        <>
            <div className={css.checkbox}>
                <Checkbox
                    label="SCHEDULE PODCAST FOR LATER"
                    containerClassName={css.checkboxInput}
                    checked={Boolean(schedulePublishTime)}
                    onChange={(event) => {
                        if (event.target.checked) {
                            schedulePublishTimeField.onChange(getTimestamp(new Date()));
                        } else {
                            schedulePublishTimeField.onChange(null);
                        }
                    }}
                />
                <p>Do not publish podcast until this time is met</p>
                {schedulePublishTime ? (
                    <DateTimeMergedRHF control={control} name="schedulePublishTime" className={css.dateTime} />
                ) : null}
            </div>
            <div className={css.checkbox}>
                <Checkbox
                    label="DISTRIBUTE EXTERNALLY (APPLE, SPOTIFY, ACAST)"
                    containerClassName={css.checkboxInput}
                    checked={Boolean(distributeAfter)}
                    onChange={(event) => {
                        if (event.target.checked) {
                            distributeAfterField.onChange(String(getTimestamp(new Date())));
                        } else {
                            distributeAfterField.onChange(null);
                        }
                    }}
                />
                <p>Must be after publication date</p>
                {distributeAfter ? (
                    <div className={css.distributeAfter}>
                        <DateTimeMergedRHF
                            control={control}
                            name="additional.metadata.distributeAfter"
                            minDate={schedulePublishTime && new Date(schedulePublishTime * 1000)}
                            normalizeToString
                            className={css.dateTime}
                        />
                        {schedulePublishTime ? (
                            <Button
                                type="button"
                                size="compact"
                                variant="standard"
                                className={css.synchronize}
                                onClick={synchronizeDate}
                            >
                                Set to publication time
                            </Button>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </>
    );
}
