import type { Control, UseFormRegister, UseFieldArrayReturn } from 'react-hook-form';
import { MdAdd } from 'react-icons/md';
import { LabeledContainer, Input, Button } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { FormValues } from '../types';
import { useDynamicFields } from '../hooks/useDynamicFields';
import css from './AssetMetadataInputs.module.scss';

// TODO: ADD VALIDATE AND TRIM BELLOW
// const validateKey = (value) => (value && value.trim() ? undefined : 'Metadata key is required');
// const trimKey = (value) => value.trim();

type AssetMetadataInputsProps = {
    asset: Asset;
    control: Control<FormValues>;
    register: UseFormRegister<FormValues>;
    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;
};
export function AssetMetadataInputs({ asset, control, register, simpleMetadataControls }: AssetMetadataInputsProps) {
    const { fields, remove, append } = simpleMetadataControls;
    const { isDynamicField } = useDynamicFields({ asset, control });

    return (
        <LabeledContainer label="Add new properties">
            <div className={css.container}>
                {fields.map(
                    (field, index) =>
                        !isDynamicField(field.key) && (
                            <div key={field.id} className={css.inputsRow}>
                                <Input size="small" {...register(`additional.metadata.simpleMetadata.${index}.key`)} />
                                <Input
                                    size="small"
                                    {...register(`additional.metadata.simpleMetadata.${index}.value`)}
                                />
                                <Button size="small" variant="standard" type="button" onClick={() => remove(index)}>
                                    Delete
                                </Button>
                            </div>
                        )
                )}
                <Button
                    type="button"
                    size="small"
                    className={css.addButton}
                    iconOnly
                    onClick={() => append({ key: '', value: '' })}
                >
                    <MdAdd />
                </Button>
            </div>
        </LabeledContainer>
    );
}
