import { memo } from 'react';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import ScreenContainer from 'components/ui/ScreenContainer';
import { AssetChecklistSettings } from 'components/asset-checklist/AssetChecklistSettings';
import { UserAccessManager } from 'components/settings/UserAccessManager';

function SettingsScreen() {
    const userPermissions = useUserPermissions();

    return (
        <>
            {userPermissions?.isUser ? (
                <ScreenContainer>
                    <UserAccessManager userNewsrooms={userPermissions?.userNewsrooms ?? []} />
                </ScreenContainer>
            ) : null}

            <ScreenContainer>
                <AssetChecklistSettings />
            </ScreenContainer>
        </>
    );
}

export default memo(SettingsScreen);
