import { FormEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import type { DeepPartial } from 'types';
import { useDeferredDispatch } from 'hooks/useDeferredDispatch';
import { saveAsset } from 'store/assets/actions';
import { uploadSubtitles } from 'store/uploads/subtitles/actions';
import type { FormValues } from 'components/AssetForm/types';
import type { SubtitlesUploadedData } from 'components/AssetForm/fields/Subtitles/types';

import { generateVttFile } from '../helpers';
import type { SubtitleCue, SubtitlesFormValues } from '../types';
import { useSubtitleCuesLocalStore } from './useSubtitleCuesLocalStore';

export const useSubtitleCuesForm = ({
    provider,
    assetId,
    language,
    initialCues,
    generateSubtitles,
}: {
    provider: string;
    assetId: number;
    language: string;
    initialCues: SubtitleCue[];
    generateSubtitles: (uploadedSubtitles: SubtitlesUploadedData) => SubtitlesUploadedData[];
}) => {
    const dispatch = useDispatch();
    const deferredDispatch = useDeferredDispatch();

    const formApi = useForm<SubtitlesFormValues>({
        defaultValues: { cues: initialCues },
    });
    const { formState, getValues, handleSubmit, reset, setValue } = formApi;
    const { isDirty, isSubmitting } = formState;

    const { getSubtitles, clearSubtitles, setSubtitles } = useSubtitleCuesLocalStore({ provider, assetId, language });
    const { lastEditedAt, formValues: { cues: savedCues } = {} } = getSubtitles();

    const saveFormValues = useCallback(() => {
        if (isDirty && !isSubmitting) {
            setSubtitles(getValues());
        }
    }, [getValues, isDirty, isSubmitting, setSubtitles]);

    const loadSavedFormValues = useCallback(() => {
        savedCues?.forEach((cue, index) =>
            setValue(`cues.${index}`, cue, {
                shouldTouch: true,
                shouldDirty: true,
            })
        );
    }, [savedCues, setValue]);

    const submitValues: FormEventHandler<HTMLFormElement> = async (event) => {
        saveFormValues();

        await handleSubmit(async ({ cues }) => {
            const file = generateVttFile(cues, language);
            const uploadedSubtitle = await deferredDispatch<SubtitlesUploadedData>(
                uploadSubtitles(provider, assetId, language, file)
            );

            await new Promise((resolve, reject) => {
                const changes: DeepPartial<FormValues> = {
                    additional: {
                        subtitles: generateSubtitles(uploadedSubtitle),
                    },
                };
                dispatch(saveAsset({ id: assetId, provider, changes, resolve, reject }));
            });

            // reset isDirty after successful save to avoid further local storage updates
            reset({}, { keepValues: true });
            clearSubtitles();
        })(event);
    };

    return { formApi, loadSavedFormValues, saveFormValues, submitValues, lastEditedAt };
};
