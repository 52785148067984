import config from 'config';
import type { UserRole } from 'hooks/usersManagement/useUserRoles';
import type { NewsroomOption, UserData } from './types';

const ALLOWED_ROLES = ['admin', 'user'];

export const prepareUsersNewsrooms = (roles: UserRole[], newsrooms: string[]): string[] => {
    const isAdmin = roles.find(({ newsroom }) => newsroom === '*');
    if (isAdmin) {
        return newsrooms;
    }
    return newsrooms.filter((newsroom) =>
        roles.find((role) => role.newsroom === newsroom && ALLOWED_ROLES.includes(role.roleType))
    );
};

export const generateNewsroomsList = (roles: UserRole[], selectedNewsrooms: string[]): NewsroomOption[] => {
    const usersNewsrooms = prepareUsersNewsrooms(roles, Object.keys(config.newsrooms));
    const allowedNewsrooms = new Set([...selectedNewsrooms, ...usersNewsrooms]);

    return [...allowedNewsrooms].reduce<NewsroomOption[]>((acc, value) => {
        acc.push({
            value,
            label: config.newsrooms[value],
        });
        return acc;
    }, []);
};

export const getEmptyUser = (): UserData => ({
    firstname: '',
    lastname: '',
    email: '',
    newsrooms: [],
});
