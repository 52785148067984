import * as PropTypes from 'prop-types';
import { Button } from '@schibsted-svp/react-ui';
import { isRtmpEncoder } from 'models/asset';
import { RtmpInfoDialog } from '../RtmpInfoDialog';
import LiveStopButton from './LiveRuntimeButtons/LiveStopButton';
import LiveControlsTemplate from './LiveControlsTemplate';
import { RtmpMigrateButton } from './RtmpMigrateButton';

function ExternalOrRtmpControls({ asset, isStopping }) {
    const sourceButton = isRtmpEncoder(asset) ? (
        <RtmpInfoDialog assetId={asset.id} provider={asset.provider} />
    ) : (
        <Button type="button" variant="standard" disabled>
            External encoder
        </Button>
    );

    return (
        <>
            <RtmpMigrateButton assetId={asset.id} provider={asset.provider} />
            <LiveControlsTemplate
                newsroom={asset.provider}
                assetId={asset.id}
                startTime={asset.flightTimes.start}
                sourceButton={sourceButton}
                isStopping={isStopping}
                isRunning
            >
                <LiveStopButton assetId={asset.id} provider={asset.provider} loading={isStopping} connected>
                    Stop encoder
                </LiveStopButton>
            </LiveControlsTemplate>
        </>
    );
}

ExternalOrRtmpControls.propTypes = {
    asset: PropTypes.object.isRequired,
    isStopping: PropTypes.bool.isRequired,
};

export default ExternalOrRtmpControls;
