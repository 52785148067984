const getPixelRatio = () => {
    let devicePixelRatio = window.devicePixelRatio || 1;

    if (devicePixelRatio > 1) {
        devicePixelRatio = 2;
    }

    return devicePixelRatio;
};

export default { getPixelRatio };
