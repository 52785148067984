export const contentTypes = {
    story: 'Story',
    interview: 'Interview',
    summary: 'Summary',
    explainer: 'Explainer',
    viral: 'Viral',
    pressConference: 'Press Conference',
    debate: 'Debate',
    program: 'Program',
    documentary: 'Documentary',
    additionalVideo: 'Additional Video',
    highlight: 'Highlight',
    contentMarketing: 'Content Marketing',
    liveSports: 'Live Sports',
    newsBulletin: 'News Bulletin',
};
