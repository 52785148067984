import { get } from 'lodash';

/**
 * @typedef {Object} Acl
 * @property {Object} core
 * @property {Object} newsrooms
 */

/**
 * @return {Promise<Acl>}
 */
export async function fetchAclList() {
    const result = await this.get('v1/acl');
    return get(result, '_embedded');
}
