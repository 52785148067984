import * as DateUtils from 'lib/date';
import * as NumberUtils from 'lib/number';
import { getDateString } from 'lib/time';
import { get, maxBy } from 'lodash';
import config from 'config';
import {
    isLive,
    wasLive,
    makeStreamTypeLabel,
    isRtmpEncoder,
    isExternalEncoder,
    isEmptyEncoder,
    isNetworkEncoder,
    isExternalOrRtmpEncoder,
    isGeoblocked,
    isEncrypted,
    getAccess,
} from 'models/asset';
import { getChannelEndTime, isChannelRunning, isChannelScheduled } from 'models/channel';
import { getEncoderName, getInputName, hasError } from 'models/liveEvent';
import { getEndTime, atLeastOneEvent, atLeastOneEventIsRunning, atLeastOneEventIsPending } from 'models/multiLiveEvent';

const {
    aspectRatios,
    asset: { labels },
    newsrooms,
} = config;

/**
 * @param {Asset} asset
 * @param {LiveEvent} [event]
 * @returns {String}
 */
export const getInput = (asset, event) => {
    if (hasError(event)) {
        return '';
    }

    if (!event) {
        return isEmptyEncoder(asset) || wasLive(asset)
            ? undefined
            : (isRtmpEncoder(asset) && 'Web camera') || (isExternalEncoder(asset) && 'External encoder');
    }

    const inputName = getInputName(event);

    const encoderName = getEncoderName(event);

    return isNetworkEncoder(asset) ? `Network Input - ${encoderName}` : `${inputName} - ${encoderName}`;
};

/**
 * @param {Asset} asset
 * @returns {String}
 */
const calculateRatio = (asset) => {
    const aspectRatio = get(asset, 'additional.metadata.aspectRatio');
    const sourceFiles = get(asset, 'additional.sourceFiles', []);

    const { width, height } = maxBy(sourceFiles, (data) => data.height) || {};

    let { label: ratio } = aspectRatios.find(({ value }) => value === aspectRatio) || {};

    if (!ratio && width && height) {
        ratio = width / height;
        ratio = ratio % 1 ? ratio.toFixed(2) : ratio;

        const { label: labelFromConfig } = aspectRatios.find(({ value }) => value === String(ratio)) || {};
        ratio = labelFromConfig || `${ratio}/1`;
    }

    return ratio;
};

/**
 * @param {Asset} asset
 * @returns {Number|undefined}
 */
export const calculateDuration = (asset) => {
    const {
        duration,
        playback,
        created,
        published,
        flightTimes: { start },
    } = asset;

    if (isLive(asset)) {
        if (isEmptyEncoder(asset)) {
            return undefined;
        }
        if (start && created !== start) {
            return Date.now() - start * 1000;
        }
        if (published) {
            return Date.now() - published * 1000;
        }
        return undefined;
    }
    if (playback) {
        return duration - playback.begin * 1000;
    }
    return duration;
};

/**
 * @param {Asset} asset
 * @returns {Object}
 */
export function dataMapper(asset) {
    const { id, title, category, displays, published, flightTimes, provider } = asset;

    const createdBy = get(asset, 'additional.settings.createdBy');
    const isSilent = get(asset, 'additional.metadata.silentplay', false) === 'true';
    const notes = get(asset, 'additional.settings.notes');

    const ratio = calculateRatio(asset);
    const calculatedDuration = !isLive(asset) || isExternalOrRtmpEncoder(asset) ? calculateDuration(asset) : undefined;
    const access = getAccess(asset)?.[0];

    return {
        id,
        type: makeStreamTypeLabel(asset, labels),
        title,
        notes,
        ratio: ratio || '-',
        assetFormLink: `/${provider}/assets/${id}`,
        duration: calculatedDuration,
        category: category.title,
        views: displays ? NumberUtils.format(displays) : 0,
        fullFormatDate: published ? DateUtils.relative(published * 1000) : '-',
        shortFormatDate: published ? getDateString(published) : '-',
        startFlightTimes: flightTimes.start ? flightTimes.start * 1000 : undefined,
        author: createdBy || '-',
        provider: { label: newsrooms[provider], id: provider },
        isSilent,
        isGeoblocked: isGeoblocked(asset),
        isEncrypted: isEncrypted(asset),
        isFreeAccess: isEncrypted(asset) && !access,
        access,
    };
}

function getRelativeEndTime(endTime) {
    return endTime ? DateUtils.relative(endTime * 1000) : undefined;
}

/**
 * @param {Asset} asset
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Object}
 */
export function liveEventMapper(asset, events = []) {
    const endTime = getEndTime(events);
    return {
        duration:
            atLeastOneEventIsRunning(events) || atLeastOneEventIsPending(events) ? calculateDuration(asset) : undefined,
        endTime: getRelativeEndTime(endTime),
        inputInfos: atLeastOneEvent(events)
            ? events.map((event) => getInput(asset, event))
            : [getInput(asset)].filter(Boolean),
    };
}

/**
 * @param {Asset} asset
 * @param {MediaLiveChannel} [channel]
 * @returns {{endTime: Number|undefined, inputInfos: [String]|undefined}}
 */
export function mediaLiveChannelMapper(asset, channel) {
    const endTime = getChannelEndTime(channel);
    return {
        duration: isChannelRunning(channel) || isChannelScheduled(channel) ? calculateDuration(asset) : undefined,
        endTime: getRelativeEndTime(endTime),
        inputInfos: channel ? ['Cloud encoder'] : [],
    };
}
