/* eslint no-underscore-dangle: "off" */
import * as AssetList from 'store/asset-list/actionTypes';
import * as Assets from 'store/assets/actionTypes';
import { FETCH_LIVE_ASSETS_SUCCESS, FETCH_WAS_LIVE_ASSETS_SUCCESS } from 'store/live/actions';
import { get, set } from 'lodash';
import { merge } from 'lib/object';
import { api as adminBffSdk } from 'services/admin-bff-sdk';

import { defaultState, defaultTag } from './state';
import * as Actions from './actionTypes';

function mergeTags(state, provider, incomingTags) {
    const changes = {
        [provider]: {},
    };

    incomingTags.forEach((tag) => {
        changes[provider][tag.id] = {
            ...state?.[provider][tag.id],
            isFetching: false,
            error: null,
            tag: { id: tag.id, tag: tag.title, type: tag.type },
        };
    });

    return merge(state, changes);
}

function prepareTags({ state, asset, result = {} }) {
    const { tags } = asset.additional || {};

    // when asset come form sse events the tags are in asset.tags
    const { tags: embedded } = asset._embedded || asset || {};
    const { provider } = asset;

    if (!result[provider]) {
        set(result, provider, {});
    }

    // prepare a list of all the tags assigned to the asset
    // those left without the `tag` property filled will trigger a separate fetch
    if (tags && Array.isArray(tags)) {
        // exclude those that already have their definition in the store so that it doesn't get overwritten by defaults
        tags.filter((id) => get(state, [provider, id]) === undefined).forEach((id) => {
            // eslint-disable-next-line no-param-reassign
            result[provider][id] = defaultTag();
        });
    }

    // overwrite empty tag definitions with those that have already been fetched through `_embedded`
    if (embedded && Array.isArray(embedded)) {
        embedded.forEach((tag) => {
            // eslint-disable-next-line no-param-reassign
            result[provider][tag.id] = defaultTag(tag);
        });
    }

    return result;
}

function tagsReducer(state = defaultState, action) {
    switch (action.type) {
        case Assets.ASSET_FETCH_SUCCESS:
        case Assets.ASSET_SAVE_SUCCESS:
        case Assets.ASSET_CHANGE: {
            const { asset } = action;

            // for each tag present in the fetched asset, update those cached in the store
            const updates = prepareTags({ state, asset });

            return merge(state, updates);
        }
        case FETCH_LIVE_ASSETS_SUCCESS:
        case FETCH_WAS_LIVE_ASSETS_SUCCESS:
        case AssetList.SET_ASSETS:
        case AssetList.ADD_ASSETS: {
            const { assets } = action;
            const updates = {};

            // for each tag present in the list of fethed assets, update those cached in the store
            assets.forEach((asset) => {
                prepareTags({ state, asset, result: updates });
            });

            return merge(state, updates);
        }

        case Actions.TAGS_FETCH: {
            const { ids, provider } = action;
            const changes = {
                [provider]: {},
            };

            ids.forEach((id) => {
                changes[provider][id] = {
                    ...get(state, [provider, id]),
                    isFetching: true,
                    error: null,
                };
            });

            return merge(state, changes);
        }

        case Actions.TAGS_FETCH_SUCCESS: {
            const { tags, provider } = action;
            const changes = {
                [provider]: {},
            };

            tags.forEach((tag) => {
                changes[provider][tag.id] = {
                    ...get(state, [provider, tag.id]),
                    isFetching: false,
                    error: null,
                    tag,
                };
            });

            return merge(state, changes);
        }

        case Actions.TAGS_FETCH_ERROR: {
            const { ids, provider, error } = action;
            const changes = {
                [provider]: {},
            };

            ids.forEach((id) => {
                changes[provider][id] = {
                    ...get(state, [provider, id]),
                    isFetching: false,
                    error,
                };
            });

            return merge(state, changes);
        }
        default:
            if (adminBffSdk.endpoints.searchTags.matchFulfilled(action)) {
                return mergeTags(state, action.meta.arg.originalArgs.provider, action.payload.searchTags);
            }
            if (adminBffSdk.endpoints.suggestTags.matchFulfilled(action)) {
                return mergeTags(state, action.meta.arg.originalArgs.provider, action.payload.suggestTags);
            }
            if (adminBffSdk.endpoints.listTags.matchFulfilled(action)) {
                return mergeTags(state, action.meta.arg.originalArgs.provider, action.payload.listTags);
            }
            return state;
    }
}

export default tagsReducer;
