import { Notification } from '@schibsted-svp/react-ui';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import { authEmailSelector } from 'store/auth/selectors';
import { useGetUsersQuery } from 'services/admin-bff-sdk/generated';
import { useGetProvidersQuery } from 'services/svp-api-rtk/providers';
import { Loader } from 'components/Auth';
import { NewsroomScreen } from './NewsroomScreen';

export const AssociateNewsroomScreen = memo(function LimitedNewsroomScreen() {
    const history = useHistory();
    const email = useSelector(authEmailSelector);
    const usersQuery = useGetUsersQuery({ query: email });
    const providersQuery = useGetProvidersQuery();
    const userPermissions = useUserPermissions();

    useEffect(() => {
        if (!userPermissions) {
            return;
        }
        if (!userPermissions.isUser && !userPermissions.isAssociate) {
            Notification.notify.error(`You don't have permission to view this resource.`);
            history.push('/');
        }
    }, [history, userPermissions]);

    if (!userPermissions || usersQuery.isLoading || providersQuery.isLoading) {
        return <Loader />;
    }

    if (!userPermissions.isUser && !userPermissions.isAssociate) {
        return null;
    }

    return <NewsroomScreen />;
});
