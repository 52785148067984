import PropTypes from 'prop-types';
import { Button, Dialog } from '@schibsted-svp/react-ui';
import { AssetChecklist } from 'components/asset-checklist/AssetChecklist';
import classnames from 'classnames/bind';
import css from './PublishVideoConfirmationDialog.module.scss';

const cln = classnames.bind(css);

function PublishVideoConfirmationDialog({
    assetId,
    provider,
    shouldBeShown,
    toggle,
    confirm,
    isRequired,
    isWarningVariant,
    isPublished,
    assetType,
}) {
    if (!isRequired) {
        return null;
    }
    return (
        <Dialog isOpen={shouldBeShown} onClose={toggle} heading={`${assetType} checklist`}>
            <Dialog.Section>
                <p>The quality of metadata is quite poor, you can improve this by filling in the following metadata:</p>
                <AssetChecklist assetId={assetId} provider={provider} assetType={assetType} />
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button data-test="CancelButton" type="button" variant="standard" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    data-testid="ConfirmButton"
                    type="button"
                    onClick={confirm}
                    className={cln({ warning: isWarningVariant })}
                >
                    {isPublished ? 'Update' : 'Publish'} anyway
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}

PublishVideoConfirmationDialog.propTypes = {
    assetId: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    shouldBeShown: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isWarningVariant: PropTypes.bool,
    isPublished: PropTypes.bool,
    assetType: PropTypes.string,
};

PublishVideoConfirmationDialog.defaultProps = {
    shouldBeShown: false,
    isWarningVariant: false,
    isPublished: false,
    assetType: 'video/audio',
};

export default PublishVideoConfirmationDialog;
