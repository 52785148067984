import { useState } from 'react';
import { getTimestamp } from 'lib/time';
import { MdDeleteForever } from 'react-icons/md';
import { LabeledContainer, CollapsibleContent } from '@schibsted-svp/react-ui';
import { DateTimeMergedRHF } from 'components/video/edit/Form/DateTimeMerged';
import Countdown from 'components/ui/Countdown';
import { useWatch, useController, type Control } from 'react-hook-form';
import { RadioOption } from 'components/video/live/form/RadioOption/RadioOption';
import type { FormValues } from '../types';

import css from './ScheduleDialogFormVideo.module.scss';

type ScheduleRadioValuesKeys = keyof typeof SCHEDULE_RADIO_VALUES;

export const SCHEDULE_RADIO_VALUES = {
    PUBLISHER: 'PUBLISHER',
    FLIGHT_TIMES: 'FLIGHT_TIMES',
} as const;

type ScheduleDialogFormVideoProps = {
    control: Control<FormValues>;
};

export function ScheduleDialogFormVideo({ control }: ScheduleDialogFormVideoProps) {
    const [flightTimes, schedulePublishTime] = useWatch({
        control,
        name: ['flightTimes', 'schedulePublishTime'],
    });

    const { field: flightTimesEndField } = useController({ name: 'flightTimes.end', control });
    const { field: schedulePublishTimeField } = useController({ name: 'schedulePublishTime', control });

    const [isFlightTimesEndExpanded, setIsFlightTimesEndExpanded] = useState(Boolean(flightTimes.end));
    const [scheduleRadioValue, setScheduleRadioValue] = useState(
        schedulePublishTime ? SCHEDULE_RADIO_VALUES.PUBLISHER : SCHEDULE_RADIO_VALUES.FLIGHT_TIMES
    );

    const clearFlightTimesEnd = () => {
        flightTimesEndField.onChange(null);

        setIsFlightTimesEndExpanded(false);
    };

    const onFlightTimesEndToggle = () => {
        if (!isFlightTimesEndExpanded) {
            flightTimesEndField.onChange(getTimestamp(new Date()));
        }
        setIsFlightTimesEndExpanded((expanded) => !expanded);
    };

    return (
        <>
            <RadioOption
                name="scheduleRadioValue"
                value={SCHEDULE_RADIO_VALUES.PUBLISHER}
                label="SCHEDULE VIDEO FOR LATER"
                checked={scheduleRadioValue === SCHEDULE_RADIO_VALUES.PUBLISHER}
                onChange={(event) => {
                    setScheduleRadioValue(event.target.value as ScheduleRadioValuesKeys);
                    schedulePublishTimeField.onChange(getTimestamp(new Date()));
                }}
                className={css.radio}
            >
                Do not publish video until this time is met
                {scheduleRadioValue === SCHEDULE_RADIO_VALUES.PUBLISHER ? (
                    <DateTimeMergedRHF control={control} name="schedulePublishTime" className={css.dateTime} />
                ) : null}
            </RadioOption>
            <RadioOption
                name="scheduleRadioValue"
                value={SCHEDULE_RADIO_VALUES.FLIGHT_TIMES}
                checked={scheduleRadioValue === SCHEDULE_RADIO_VALUES.FLIGHT_TIMES}
                onChange={(event) => {
                    setScheduleRadioValue(event.target.value as ScheduleRadioValuesKeys);
                    if (schedulePublishTime) {
                        schedulePublishTimeField.onChange(null);
                    }
                }}
                label="SET FLIGHT TIMES"
                className={css.radio}
            >
                Publish right away, but set video as not playable
                <br /> until time is met
                {scheduleRadioValue === SCHEDULE_RADIO_VALUES.FLIGHT_TIMES ? (
                    <>
                        <LabeledContainer label="FROM" className={css.dateTime}>
                            <DateTimeMergedRHF control={control} name="flightTimes.start" />
                        </LabeledContainer>
                        <Countdown
                            className={css.countdownToStart}
                            key={flightTimes.start}
                            date={flightTimes.start * 1000}
                        />
                        <CollapsibleContent
                            variant="cover"
                            label="+add end time"
                            className={css.dateTimeFlightTimesEnd}
                            headerClassName={css.flightTimesEndHeader}
                            expanded={isFlightTimesEndExpanded}
                            onToggle={onFlightTimesEndToggle}
                        >
                            <MdDeleteForever onClick={clearFlightTimesEnd} className={css.binIcon} />

                            <LabeledContainer label="TO">
                                <DateTimeMergedRHF control={control} name="flightTimes.end" />
                            </LabeledContainer>
                            <Countdown
                                className={css.countdownToEnd}
                                key={flightTimes.end}
                                date={flightTimes.end * 1000}
                                label="Ends in"
                            />
                        </CollapsibleContent>
                    </>
                ) : null}
            </RadioOption>
        </>
    );
}
