import type { Asset } from '@schibsted-svp/svp-api-types';
import { Notification } from '@schibsted-svp/react-ui';
import { isEqual } from 'lodash';
import { scheduleCreate, scheduleUpdate, scheduleDelete } from 'store/schedules/actions';
import { SCHEDULES_SCOPE, SCHEDULES_ACTION } from 'store/schedules/constants';
import { useSendPushNotificationMutation } from 'services/admin-bff-sdk/generated';
import { useDeferredDispatch } from 'hooks/useDeferredDispatch';
import { isAudio, isPushNotificationSent } from 'models/asset';
import { getTimeInSeconds } from 'lib/time';
import type { FormValues } from '../../types';

export const SCHEDULE_METHOD = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
} as const;

type ScheduleMethod = (typeof SCHEDULE_METHOD)[keyof typeof SCHEDULE_METHOD];

export function getScheduleMethod({
    initStatus,
    status,
    initPushNotifications,
    pushNotifications,
    originalOrPublishTime,
    shouldSendPushNow,
    isPublishScheduleRemoved,
    isChanged,
    isCategoryChanged,
}: {
    initStatus: Asset['status'];
    status: Asset['status'];
    initPushNotifications: FormValues['pushNotifications'];
    pushNotifications: FormValues['pushNotifications'];
    originalOrPublishTime: number;
    shouldSendPushNow: boolean;
    isPublishScheduleRemoved: boolean;
    isChanged: boolean;
    isCategoryChanged: boolean;
}): ScheduleMethod {
    // do not set any schedule (or delete existing one) if sending push now
    if (shouldSendPushNow) {
        return initPushNotifications?.send ? SCHEDULE_METHOD.DELETE : null;
    }

    // remove push when custom date removed and push set on publish but not publishing yet
    if (initPushNotifications?.time && !originalOrPublishTime && pushNotifications?.send && status !== 'active') {
        return SCHEDULE_METHOD.DELETE;
    }

    // remove push when publish schedule removed and push set on publish
    if (isPublishScheduleRemoved && !originalOrPublishTime && initPushNotifications?.send) {
        return SCHEDULE_METHOD.DELETE;
    }

    // update push when category changes
    if (initPushNotifications?.send && pushNotifications?.send && isCategoryChanged) {
        return SCHEDULE_METHOD.UPDATE;
    }

    // do not set any schedule when asset unpublished
    if (initStatus === 'active' && status === 'inactive' && pushNotifications?.send) {
        return null;
    }

    if (pushNotifications?.send) {
        if (!initPushNotifications?.send) {
            return SCHEDULE_METHOD.CREATE;
        }
        if (isChanged) {
            return SCHEDULE_METHOD.UPDATE;
        }
    } else if (initPushNotifications?.send) {
        return SCHEDULE_METHOD.DELETE;
    }

    return null;
}

export function usePushNotifications() {
    const deferredDispatch = useDeferredDispatch();
    const [sendPushNotification] = useSendPushNotificationMutation();

    return async (initValues: FormValues, values: FormValues, newStatus: Asset['status'], asset: Asset) => {
        if (isPushNotificationSent(asset) || !isAudio(asset)) {
            return;
        }

        const initStatus = asset.status;
        const status = newStatus || initStatus;

        const publishScheduleTime = initStatus !== 'active' && status === 'active' ? null : values.schedulePublishTime;
        const publishScheduleTimeDelayed = publishScheduleTime && publishScheduleTime + 60;

        const currentTime = getTimeInSeconds();
        const originalOrPublishTime = values.pushNotifications?.time || publishScheduleTimeDelayed;
        const time = originalOrPublishTime || currentTime;

        const shouldSendPushNow = values.pushNotifications?.send && time <= currentTime;
        const isPublishScheduleRemoved = initValues.schedulePublishTime && !publishScheduleTime;
        const isChanged = !isEqual(initValues.pushNotifications, { ...values.pushNotifications, time });
        const isCategoryChanged = initValues.category?.id !== values.category?.id;

        const scheduleMethod = getScheduleMethod({
            initStatus,
            status,
            initPushNotifications: initValues.pushNotifications,
            pushNotifications: values.pushNotifications,
            originalOrPublishTime,
            shouldSendPushNow,
            isPublishScheduleRemoved,
            isChanged,
            isCategoryChanged,
        });

        const commonScheduleProps = {
            provider: asset.provider,
            assetId: asset.id,
            scope: SCHEDULES_SCOPE.NOTIFICATION,
            action: SCHEDULES_ACTION.PUSH,
        };

        const notificationBody = values.pushNotifications?.content;

        switch (scheduleMethod) {
            case SCHEDULE_METHOD.CREATE:
                await deferredDispatch(scheduleCreate({ ...commonScheduleProps, time, notificationBody }));
                break;
            case SCHEDULE_METHOD.UPDATE:
                await deferredDispatch(scheduleUpdate({ ...commonScheduleProps, time, notificationBody }));
                break;
            case SCHEDULE_METHOD.DELETE:
                await deferredDispatch(scheduleDelete(commonScheduleProps));
                break;
            default:
                break;
        }

        if (shouldSendPushNow && status === 'active') {
            try {
                await sendPushNotification({ provider: asset.provider, assetId: asset.id, notificationBody }).unwrap();
            } catch (error) {
                Notification.notify.error('Failed sending push notification');
            }
        }
    };
}
