import { useMemo } from 'react';

import { useController, Control, FieldValues, FieldPath } from 'react-hook-form';
import { Select, Spinner } from '@schibsted-svp/react-ui';
import type { ExtendedProps as SelectProps, DefaultOptionType } from '@schibsted-svp/react-ui/lib/es/Select';

import { useListTagsQuery } from '../../../services/admin-bff-sdk';
import { useTagsSearch } from '../../AssetForm/hooks/useTagsSearch';
import { findSingleOption } from '../../ui/Form/Select';

import css from './TagsSelect.module.scss';

function LoadingMessage() {
    return <Spinner containerClassName={css.optionsSpinner} size="compact" label="Loading director" />;
}
function LoadingIndicator() {
    return null;
}

type DirectorSelectProps<TFieldValues extends FieldValues> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    provider: string;
} & SelectProps<DefaultOptionType, true>;

export function DirectorSelect<TFieldValues extends FieldValues>({
    name,
    control,
    provider,
}: DirectorSelectProps<TFieldValues>) {
    const { field, fieldState } = useController({ name, control });
    const {
        data,
        isFetching: isListTagsFetching,
        isLoading: isListTagsLoading,
    } = useListTagsQuery({ provider, ids: field.value ? [field.value] : [] }, { skip: !field.value });
    const { isFetching, isLoading, onTagsSearch, searchResults } = useTagsSearch({ provider, delay: 200 });

    const selectedOptions = useMemo(() => {
        return data?.listTags.map((tag) => ({ value: tag.id, label: tag.title })) || [];
    }, [data]);

    const options =
        searchResults?.filter((tag) => tag.type === 'person').map(({ id, title }) => ({ value: id, label: title })) ??
        [];

    const formatOptionLabel = ({ label }: DefaultOptionType) => {
        if (isListTagsLoading || !label) {
            return <Spinner containerClassName={css.selectSpinner} />;
        }

        return label;
    };

    return (
        <Select
            value={findSingleOption(selectedOptions)(field.value)}
            onChange={(newValue) => field.onChange(newValue ? newValue.value : null)}
            error={fieldState.error?.message}
            placeholder="Search for director"
            isLoading={isListTagsFetching || isListTagsLoading || isFetching || isLoading}
            onInputChange={onTagsSearch}
            options={options}
            formatOptionLabel={formatOptionLabel}
            components={{ LoadingMessage, LoadingIndicator }}
            backspaceRemovesValue={false}
            splitButton={false}
            isClearable
            size="small"
        />
    );
}
