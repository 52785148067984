import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useSearchAuthorsQuery } from 'services/admin-bff-sdk/generated';

export function useAuthorsSearch({ provider, delay }: { provider: string; delay: number }) {
    const [term, setQuery] = useState('');
    const onAuthorsSearch = useMemo<typeof setQuery>(() => debounce(setQuery, delay), [delay, setQuery]);
    const { data, ...rest } = useSearchAuthorsQuery({ provider, query: term }, { skip: term.length < 2 });

    return {
        ...rest,
        onAuthorsSearch,
        searchResults: data,
        term,
    };
}
