import { useSelector } from 'react-redux';

import { MdAccessTime, MdVideoLibrary } from 'react-icons/md';
import AssetPreview from 'components/video/AssetPreview';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { thumbnailSizes } from 'components/core/VideoCard/helpers';

import { getFormattedTime } from 'lib/time';

import PropTypes from 'prop-types';
import { getRegularPlaylist } from 'store/playlists/selectors';

import css from './PlaylistTile.module.scss';

const cln = classnames.bind(css);

function PlaylistTile({ id, name, provider }) {
    const playlist = useSelector((state) => getRegularPlaylist(state, provider, id));

    return (
        <Link to={`/${provider}/playlists/edit/${id}`} className={css.container}>
            <div
                className={cln('videoPreviews', {
                    'videoPreviews--big': playlist?.length < 2,
                    'videoPreviews--medium': playlist?.length === 2,
                })}
            >
                {playlist?.assets?.slice(0, 6)?.map(({ asset }) => (
                    <AssetPreview
                        width={thumbnailSizes.compact}
                        key={`${id}-${provider}-${asset.id}`}
                        id={asset.id}
                        provider={asset.provider}
                    />
                ))}
            </div>
            <div className={css.meta}>
                <div
                    className={cln('name', {
                        'name--centered': playlist?.length === 1,
                    })}
                >
                    {name}
                </div>
                <div className={css.info}>
                    <div className={css.infoLength}>
                        <MdVideoLibrary color={css.silver2} />
                        <span>{playlist?.length}</span>
                    </div>
                    <div className={css.infoDuration}>
                        <MdAccessTime color={css.silver2} />
                        <span>{getFormattedTime(playlist?.duration)}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
}

PlaylistTile.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
};

export default PlaylistTile;
