import classnames from 'classnames/bind';
import { memo } from 'react';
import { TimelineItem, type TimelineItemProps } from 'components/core/Timeline';
import { Control, useWatch } from 'react-hook-form';
import { SubtitlesFormValues } from './types';
import css from './SubtitlesTimelineItem.module.scss';

const cln = classnames.bind(css);

type SubtitlesTimelineItemProps = TimelineItemProps & {
    control: Control<SubtitlesFormValues>;
    highlighted: boolean;
};

export const SubtitlesTimelineItem = memo<SubtitlesTimelineItemProps>(function SubtitlesTimelineItem({
    active,
    control,
    highlighted,
    index,
    ...props
}) {
    const text = useWatch({ control, name: `cues.${index}.text` });

    return (
        <TimelineItem
            active={active}
            className={cln(css.item, {
                [css['item-active']]: active,
                [css['item-highlighted']]: highlighted,
            })}
            index={index}
            {...props}
        >
            {text}
        </TimelineItem>
    );
});
