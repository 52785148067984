import {
    ADD_NAVIGATION_TAB,
    REMOVE_NAVIGATION_TAB,
    ACTIVATE_NAVIGATION_TAB,
    DEACTIVATE_NAVIGATION_TAB,
} from './actions';
import { navigationTabExists } from './selectors';
import defaultState from './state';
import { ASSET_CREATE_SUCCESS } from '../../assets/actionTypes';

const addNavigationTabReducer = (state, { id, provider }) => {
    if (navigationTabExists({ id, provider }, state)) {
        return state;
    }

    const opened = state.slice();
    opened.push({ id: Number(id), provider, active: true });

    return opened;
};

const removeNavigationTabReducer = (state, { id, provider }) =>
    state.filter((entry) => !(id === entry.id && provider === entry.provider));

const changeNavigationTabFocusReducer = ({ state, action: { id }, active } = {}) =>
    state.map((tab) => {
        if (tab.id === id) {
            return { ...tab, active };
        }
        return tab;
    });

export default (state = defaultState, action) => {
    if (action.type === ADD_NAVIGATION_TAB) {
        return addNavigationTabReducer(state, action);
    }

    if (action.type === REMOVE_NAVIGATION_TAB) {
        return removeNavigationTabReducer(state, action);
    }

    if (action.type === ASSET_CREATE_SUCCESS) {
        const {
            asset: { id, provider },
        } = action;
        return addNavigationTabReducer(state, { id: String(id), provider });
    }
    if (action.type === ACTIVATE_NAVIGATION_TAB) {
        return changeNavigationTabFocusReducer({ state, action, active: true });
    }
    if (action.type === DEACTIVATE_NAVIGATION_TAB) {
        return changeNavigationTabFocusReducer({ state, action, active: false });
    }

    return state;
};
