import { createSelector } from '@reduxjs/toolkit';
import { Notification } from '@schibsted-svp/react-ui';
import z from 'zod';

import { reportMessageToSentry } from 'lib/error';
import type { RootState } from 'store';
import { svpApi } from './base-api';

const providerSchema = z.object({
    id: z.string(),
    label: z.string(),
    linkedProviders: z.array(z.string()),
    language: z.string(),
});

const providersSchema = z
    .object({
        _embedded: z.object({
            providers: z.array(providerSchema),
        }),
    })
    // eslint-disable-next-line no-underscore-dangle
    .transform((val) => val._embedded.providers);

export type Provider = z.infer<typeof providerSchema>;

export const providersApi = svpApi.injectEndpoints({
    endpoints: (builder) => ({
        getProviders: builder.query<Provider[], void>({
            query: () => 'v1/',
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    const errorMessage = 'Unable to fetch providers';
                    Notification.notify.error(errorMessage);
                    reportMessageToSentry({
                        message: errorMessage,
                        extras: {
                            error,
                        },
                    });
                }
            },
            transformResponse(data) {
                return providersSchema.parse(data);
            },
        }),
    }),
    overrideExisting: false,
});

export const selectUserLinkedProviders = createSelector(
    [providersApi.endpoints.getProviders.select(), (state: RootState, userNewsrooms: string[]) => userNewsrooms],
    ({ data }, userNewsrooms) => {
        if (!data || !userNewsrooms) {
            return undefined;
        }
        return userNewsrooms
            .map((newsroom) => data.find((provider) => provider.id === newsroom)?.linkedProviders)
            .flat()
            .filter((newsroom) => Boolean(newsroom) && !userNewsrooms.includes(newsroom));
    }
);

export const selectNewsroomLinkedProviders = createSelector(
    [providersApi.endpoints.getProviders.select(), (state: RootState, newsroom: string) => newsroom],
    ({ data }, newsroom) => {
        return data?.find((provider) => provider.id === newsroom)?.linkedProviders || [];
    }
);

export const selectQueryProviders = createSelector(
    [
        providersApi.endpoints.getProviders.select(),
        (state: RootState, newsroom: string) => newsroom,
        (state: RootState, newsroom: string, searchQueryProviders: string) => searchQueryProviders,
    ],
    ({ data }, newsroom, searchQueryProviders) => {
        if (!searchQueryProviders?.includes('all')) {
            return searchQueryProviders;
        }
        return data.find((provider) => provider.id === newsroom)?.linkedProviders?.join(',');
    }
);

export const { useGetProvidersQuery } = providersApi;
