import { combineReducers } from '@reduxjs/toolkit';

import assetForm from './assetForm/reducers';
import { SEARCH_CLEAR, SEARCH_FOR } from './actionTypes';
import notifications from './notifications/reducers';

const defaultSearchState = {
    query: '',
};

function searchReducer(state = defaultSearchState, { type, searchTerm }) {
    if (type === SEARCH_FOR) {
        return {
            ...defaultSearchState,
            query: searchTerm,
        };
    }

    if (type === SEARCH_CLEAR) {
        return {
            ...defaultSearchState,
        };
    }

    return state;
}

export default combineReducers({
    search: searchReducer,
    assetForm,
    notifications,
});
