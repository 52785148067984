import { defaultNextAssetsState } from './state';
import * as Actions from './actionTypes';

export function relatedReducer(state = defaultNextAssetsState.related, action) {
    switch (action.type) {
        case Actions.RELATED_FETCH: {
            return {
                ...state,
                isFetching: true,
                error: null,
                items: [], // in this particular case it is easier to just clear the list of results
            };
        }

        case Actions.RELATED_FETCH_SUCCESS: {
            const { assets } = action;

            return {
                ...state,
                isFetching: false,
                error: null,
                items: assets.map((asset) => asset.id),
            };
        }

        case Actions.RELATED_FETCH_ERROR: {
            const { provider, id } = action;

            return {
                ...state,
                isFetching: false,
                error: {
                    provider,
                    id,
                    errorMessage: action.error,
                },
                items: [],
            };
        }

        default:
            return state;
    }
}

export function searchReducer(state = {}, action) {
    const { provider, id, query, selectedFilters, sortType, assets, type } = action;
    const newState = { ...state };
    if (provider && !newState[provider]) {
        newState[provider] = {};
    }

    switch (type) {
        case Actions.NEXT_SEARCH: {
            newState[provider][id] = {
                ...defaultNextAssetsState.search,
                isFetching: true,
                query,
                selectedFilters,
                sortType,
            };
            return newState;
        }

        case Actions.NEXT_SEARCH_SUCCESS: {
            newState[provider][id] = {
                ...newState[provider][id],
                isFetching: false,
                error: null,
                items: assets.map((asset) => asset.id),
            };
            return newState;
        }

        case Actions.NEXT_SEARCH_ERROR: {
            newState[provider][id] = {
                ...newState[provider][id],
                isFetching: false,
                error: {
                    provider,
                    id,
                    errorMessage: action.error,
                },
                items: [],
            };
            return newState;
        }

        default:
            return newState;
    }
}
