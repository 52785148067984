import { memo } from 'react';
import { getNewsroomConfig } from 'config';
import Tooltip from 'components/ui/Tooltip';
import css from './MultiPreviewLabel.module.scss';

function artifactMapper(artifact: string) {
    switch (artifact) {
        case 'preview_360':
        case 'preview_vivi_category':
            return '360p';
        case 'preview_540p':
            return '540p';
        case 'preview_640p':
            return '640p';
        case '1080_6000_preview_hq':
        case 'preview_1080p':
        case 'preview_hq':
            return '1080p';
        default:
            return '';
    }
}

type MultiPreviewLabelProps = {
    prefix: string;
    provider: string;
};

export const MultiPreviewLabel = memo<MultiPreviewLabelProps>(function MultiPreviewLabel({ prefix, provider }) {
    const { artifactsMap } = getNewsroomConfig(provider).previews;
    const resolutions = artifactsMap[prefix]?.split(',').map(artifactMapper).filter(Boolean);
    const maxResolution = resolutions.length > 0 && Math.max(...resolutions.map((res) => parseInt(res, 10)));

    return (
        <span>
            {prefix}{' '}
            {resolutions ? (
                <Tooltip theme="light" content={`Video must be in ${maxResolution}p resolution`} offset={[0, 12]}>
                    <small className={css.details}>({resolutions.join(', ')})</small>
                </Tooltip>
            ) : null}
        </span>
    );
});
