import { MdAdd } from 'react-icons/md';
import Playlist from 'components/Playlists';
import type { UseFieldArrayRemove } from 'react-hook-form';
import { Button } from '@schibsted-svp/react-ui';
import type { PlaylistItemPlaylist } from './types';

import css from './PlaylistOfPlaylistItem.module.scss';

interface PlaylistOfPlaylistItemProps {
    index: number;
    field: PlaylistItemPlaylist;
    openAddItemModal: (index: number) => void;
    remove: UseFieldArrayRemove;
    isLastItem: boolean;
}

export function PlaylistOfPlaylistItem({
    index,
    field,
    openAddItemModal,
    remove,
    isLastItem,
}: PlaylistOfPlaylistItemProps) {
    const { playlist, isStatic } = field;

    return (
        <div className={css.playlistCard}>
            {isStatic && (
                <Button
                    type="button"
                    variant="standard"
                    onClick={() => {
                        remove(index);
                    }}
                    className={css.removeButton}
                >
                    Remove
                </Button>
            )}
            <Playlist
                provider={playlist.provider}
                id={playlist.id}
                hideDeleteButton
                className={css.playlist}
                size="compact"
                linkOnlyOnName
            />
            <Button
                size="small"
                className={css.addButtonTop}
                iconOnly
                type="button"
                onClick={() => {
                    openAddItemModal(index);
                }}
            >
                <MdAdd size={25} />
            </Button>
            {isLastItem && (
                <Button
                    size="small"
                    className={css.addButtonBottom}
                    iconOnly
                    type="button"
                    onClick={() => {
                        openAddItemModal(index + 1);
                    }}
                >
                    <MdAdd size={25} />
                </Button>
            )}
        </div>
    );
}
