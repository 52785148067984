import { useSelector } from 'react-redux';
import { type Control, useWatch } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { getScheduleForAsset } from 'store/schedules/selectors';
import { SCHEDULES_SCOPE } from 'store/schedules/constants';
import { isAudio, isPushNotificationSent } from 'models/asset';
import type { FormValues } from '../types';

export function usePushSubmitLabel({ asset, control }: { asset: Asset; control: Control<FormValues> }): {
    addPushLabel: (originalLabel: string) => string;
} {
    const [pushNotifications] = useWatch({ control, name: ['pushNotifications'] });
    const notificationSchedule = useSelector((state) =>
        getScheduleForAsset(state, { provider: asset.provider, id: asset.id, scope: SCHEDULES_SCOPE.NOTIFICATION })
    );

    let pushLabel: string | undefined;
    if (isAudio(asset) && !isPushNotificationSent(asset) && !notificationSchedule && pushNotifications.send) {
        pushLabel = pushNotifications.time ? 'Set push' : 'Push';
    }

    const addPushLabel = (originalLabel: string) => {
        if (pushLabel && asset.status !== 'draft') {
            return `${originalLabel} & ${pushLabel}`;
        }
        return originalLabel;
    };

    return { addPushLabel };
}
