import { useMemo } from 'react';
import { useForm, UseFormReturn, type SubmitHandler } from 'react-hook-form';
import type { RtmpFormData, RtmpFormControl } from './types';

export const useRtmpForm = () => {
    const formApi = useForm<RtmpFormData>({
        mode: 'all',
    });
    return { formApi };
};

export const useRtmpFormFormSubmit = (formApi: UseFormReturn<RtmpFormData>, { onSubmit }: RtmpFormControl) => {
    const { handleSubmit } = formApi;
    return useMemo(() => {
        const submitHandler: SubmitHandler<RtmpFormData> = async (data) => {
            const { streamKey, audioStream } = data;
            const streamType = audioStream ? 'AUDIO' : 'VIDEO';
            return onSubmit({ type: 'RTMP', streamKey, streamType });
        };
        return handleSubmit(submitHandler);
    }, [handleSubmit, onSubmit]);
};
