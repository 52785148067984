/* eslint no-underscore-dangle: "off" */
import * as AssetList from 'store/asset-list/actionTypes';
import * as Assets from 'store/assets/actionTypes';
import { get, set } from 'lodash';
import { merge } from 'lib/object';
import { adminBffSdk } from 'services/admin-bff-sdk';

import { defaultState, defaultStory } from './state';
import * as Actions from './actionTypes';

function prepareStories({ state, asset, result = {} }) {
    const { stories } = asset.additional || {};
    const { stories: embedded } = asset._embedded || {};
    const { provider } = asset;

    if (!result[provider]) {
        set(result, provider, {});
    }

    // prepare a list of all the stories assigned to the asset
    // those left without the `story` property filled will trigger a separate fetch
    if (stories && Array.isArray(stories)) {
        // exclude those that already have their definition in the store so that it doesn't get overwritten by defaults
        stories
            .filter((id) => get(state, [provider, id]) === undefined)
            .forEach((id) => {
                // eslint-disable-next-line no-param-reassign
                result[provider][id] = defaultStory();
            });
    }

    // overwrite empty story definitions with those that have already been fetched through `_embedded`
    if (embedded && Array.isArray(embedded)) {
        embedded.forEach((story) => {
            // eslint-disable-next-line no-param-reassign
            result[provider][story.id] = defaultStory(story);
        });
    }

    return result;
}

function getStoriesPendingReducer(state, { provider, ids }) {
    const changes = {
        [provider]: ids.reduce(
            (acc, id) => ({
                ...acc,
                [id]: {
                    isFetching: true,
                    error: null,
                },
            }),
            get(state, provider)
        ),
    };
    return merge(state, changes);
}

function getStoriesFulfilledReducer(state, { provider, stories }) {
    const changes = {
        [provider]: stories.reduce(
            (acc, story) => ({
                ...acc,
                [story.id]: {
                    ...get(state, [provider, story.id]),
                    isFetching: false,
                    error: null,
                    story,
                },
            }),
            get(state, provider)
        ),
    };
    return merge(state, changes);
}

function getStoriesFulfilledRejected(state, { provider, ids, error }) {
    const changes = {
        [provider]: ids.reduce(
            (acc, id) => ({
                ...acc,
                [id]: {
                    ...get(state, [provider, id]),
                    isFetching: false,
                    error,
                },
            }),
            {}
        ),
    };
    return merge(state, changes);
}

function storiesReducer(state = defaultState, action) {
    switch (action.type) {
        case Assets.ASSET_FETCH_SUCCESS:
        case Assets.ASSET_SAVE_SUCCESS:
        case Assets.ASSET_CHANGE: {
            const { asset } = action;

            // for each story present in the fetched asset, update those cached in the store
            const updates = prepareStories({ state, asset });

            return merge(state, updates);
        }

        case AssetList.SET_ASSETS:
        case AssetList.ADD_ASSETS: {
            const { assets } = action;
            const updates = {};

            // for each story present in the list of fethed assets, update those cached in the store
            assets.forEach((asset) => {
                prepareStories({ state, asset, result: updates });
            });

            return merge(state, updates);
        }

        case Actions.STORIES_FETCH: {
            return getStoriesPendingReducer(state, action);
        }

        case Actions.STORIES_FETCH_SUCCESS: {
            return getStoriesFulfilledReducer(state, action);
        }

        case Actions.STORIES_FETCH_ERROR: {
            return getStoriesFulfilledRejected(state, action);
        }

        default:
            if (adminBffSdk.endpoints.getStories.matchPending(action)) {
                const { provider, ids } = action.meta.arg.originalArgs;
                return getStoriesPendingReducer(state, { provider, ids });
            }

            if (adminBffSdk.endpoints.getStories.matchFulfilled(action)) {
                const { provider } = action.meta.arg.originalArgs;
                return getStoriesFulfilledReducer(state, {
                    provider,
                    stories: action.payload.getStories,
                });
            }

            if (adminBffSdk.endpoints.getStories.matchRejected(action)) {
                const { provider, ids } = action.meta.arg.originalArgs;
                return getStoriesFulfilledRejected(state, {
                    provider,
                    ids,
                    error: action.error.message,
                });
            }

            if (adminBffSdk.endpoints.searchStories.matchFulfilled(action)) {
                const { provider } = action.meta.arg.originalArgs;

                const changes = {
                    [provider]: action.payload.searchStories.reduce(
                        (acc, story) => ({
                            ...acc,
                            [story.id]: {
                                ...get(state, [provider, story.id]),
                                isFetching: false,
                                error: null,
                                story,
                            },
                        }),
                        get(state, provider)
                    ),
                };

                return merge(state, changes);
            }

            return state;
    }
}

export default storiesReducer;
