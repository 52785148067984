import {
    LIVE_ENCODERS_FAILURE,
    LIVE_ENCODERS_REQUEST,
    LIVE_ENCODERS_SUCCESS,
    LIVE_NOTIFICATION_ADD,
} from 'store/live/actions';
import config from 'config';

/**
 * @typedef LiveEventNotification
 * @property {Number} code
 * @property {LiveEventNotificationStatusEnumError|LiveEventNotificationStatusEnumAlert|LiveEventNotificationStatusEnumInfo} level
 * @property {String} message
 * @property {String|Number} timestamp
 */

/**
 * @typedef {"ERROR"} LiveEventNotificationStatusEnumError
 * @typedef {"ALERT"} LiveEventNotificationStatusEnumAlert
 * @typedef {"INFO"} LiveEventNotificationStatusEnumInfo
 */

/**
 * @param {Number} assetId
 * @param {LiveEventNotification} data
 * @returns {{eventId: Number, assetId: Number, code: Number, level: String, message: String, timestamp: Number}}
 */
export function notificationMapper(assetId, data) {
    return {
        assetId,
        code: data.code,
        level: data.level.toLowerCase(),
        message: data.message,
        timestamp: Number(data.timestamp) || Date.now(),
    };
}

/**
 * @param {Object} data
 * @param {Number} data.id
 * @param {Object} data.asset
 * @param {Number} data.asset.id
 * @param {LiveEventNotification[]} [data.notifications]
 * @returns {Array<{eventId: Number, assetId: Number, code: Number, level: String, message: String, timestamp: Number}> | undefined}
 */

export function eventMapper({ asset, notifications } = {}) {
    if (!notifications?.length || !asset) {
        return undefined;
    }

    return notifications.map((notification) => notificationMapper(asset.id, notification));
}

/**
 *
 * @param {Object[]} [encoders=[]]
 * @returns {Array<{eventId: Number, assetId: Number, code: Number, level: String, message: String, timestamp: Number}> | undefined}
 */

export function getIncomingNotifications(encoders = []) {
    return encoders
        .map(({ events }) => events)
        .flat()
        .map(eventMapper)
        .flat()
        .filter(Boolean);
}

/**
 *
 * @param {LiveEventNotification[]} [currentNotifications=[]]
 * @param {LiveEventNotification[]} [incomingNotifications=[]]
 * @returns {LiveEventNotification[]}
 */

function mergeNotifications(currentNotifications = [], incomingNotifications = []) {
    const skipIncomingNotification = ({ code, timestamp }) =>
        !currentNotifications.find((current) => current.code === code && current.timestamp === timestamp);

    return currentNotifications.concat(incomingNotifications.filter(skipIncomingNotification));
}

export function getSupportedNotifications(notifications) {
    const unsupportedNotificationCodes = Object.values(config.live.notifications.unsupportedCodes).flat();

    return notifications.filter(({ code }) => !unsupportedNotificationCodes.includes(code));
}

/**
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {String} action.newsroom
 * @param {Object[]} [action.encoders=[]]
 * @returns {Object}
 */
export function liveNotificationReducer(state = {}, { type, newsroom, ...rest }) {
    const { encoders = [] } = rest;
    const newsroomState = state[newsroom] || {};

    switch (type) {
        case LIVE_ENCODERS_REQUEST: {
            return {
                ...state,
                [newsroom]: {
                    ...newsroomState,
                    loading: true,
                    items: newsroomState.items || [],
                },
            };
        }

        case LIVE_ENCODERS_FAILURE: {
            return {
                ...state,
                [newsroom]: {
                    ...newsroomState,
                    loading: false,
                },
            };
        }

        case LIVE_ENCODERS_SUCCESS: {
            const incomingNotifications = getIncomingNotifications(encoders);
            const mergedNotifications = mergeNotifications(newsroomState.items, incomingNotifications);

            return {
                ...state,
                [newsroom]: {
                    ...newsroomState,
                    loading: false,
                    items: getSupportedNotifications(mergedNotifications),
                },
            };
        }

        case LIVE_NOTIFICATION_ADD: {
            const { assetId, data } = rest;
            const mergedNotifications = [...(newsroomState.items || []), notificationMapper(assetId, data)];

            return {
                ...state,
                [newsroom]: {
                    ...newsroomState,
                    loading: false,
                    items: getSupportedNotifications(mergedNotifications),
                },
            };
        }

        default:
            return state;
    }
}

export default liveNotificationReducer;
