import config from 'config';

import Client from './client';
import * as accessDefinitions from './svp-api-client/access-definitions';
import * as providers from './svp-api-client/providers';

const apiClient = new Client({ baseUrl: `${config.svpApi.host}${config.svpApi.path}`, query: config.svpApi.query });

Object.entries({
    ...accessDefinitions,
    ...providers,
}).forEach(([k, v]) => {
    if (typeof v === 'function') {
        apiClient[k] = v.bind(apiClient);
    }
});

export default apiClient;
