import Validator from 'validatorjs';

export const setEmailInLocalStorage = (email) => localStorage.setItem(EMAIL_KEY, email);
export const getEmailFromLocalStorage = () => localStorage.getItem(EMAIL_KEY);

const EMAIL_KEY = 'svpEmail';

export const validateEmail = (email) => {
    if (!email) return undefined;

    const rules = {
        email: 'required|email',
    };

    const validation = new Validator({ email }, rules);
    validation.passes();

    return validation.errors.first('email');
};
