import * as Actions from './actionTypes';

/**
 * @param {Object} data
 * @param {String} data.searchTerm
 * @param {String} data.provider
 */
export const searchFor = ({ searchTerm, provider }) => ({
    type: Actions.SEARCH_FOR,
    searchTerm,
    provider,
});

export const clearSearch = () => ({
    type: Actions.SEARCH_CLEAR,
});

/**
 * @param {String} assetsListType [audio|video]
 * @param {Boolean} isPodcastOnly
 * @returns {{assetsListType: *, type: string}}
 */
export const setAssetsListType = (assetsListType, isPodcastOnly) => ({
    type: Actions.SET_ASSETS_LIST_TYPE,
    assetsListType,
    isPodcastOnly,
});
