import { ReactNode } from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';

import { Button } from '@schibsted-svp/react-ui';
import { itemTypePlaylist, itemSources } from 'components/video/NextVideo/constants';

import classnames from 'classnames/bind';
import css from './NextVideoList.module.scss';

const cln = classnames.bind(css);

type DropItem = {
    type: string;
};

interface NextVideoListProps {
    children: ReactNode[];
    className: string;
    hovered: boolean;
    moveCard: (item: DropItem) => void;
    loadMore: () => void;
    loading: boolean;
    loopsTo: number;
    disableNextVideo: boolean;
    hasPlaylist: boolean;
    dndItemType: string;
    enableNextVideoRhfCheckbox: boolean;
}

export function NextVideoList({
    children,
    className,
    hovered,
    moveCard,
    loadMore,
    loading,
    loopsTo,
    disableNextVideo,
    hasPlaylist,
    dndItemType,
    enableNextVideoRhfCheckbox,
}: NextVideoListProps) {
    const empty = children.length === 0;
    const showEmptyInfo = empty && !loading;
    const showVideoList = !empty;
    const showLoopedInfo = !hovered && !loading && loopsTo != null;
    const showLoadMoreButton = !hovered && !loading && loadMore && loopsTo == null;
    const filled = children.length >= 3;

    const [, drop] = useDrop({
        accept: ['PLAYLIST', 'NEXT_VIDEO'],
        hover: (item: DropItem, monitor: DropTargetMonitor) => {
            if (item.type === itemTypePlaylist) {
                return;
            }
            const allowToHoverExternalCards = item.type !== itemSources.nextVideos;
            const hoverOnlyOnCurrentTarget = monitor.isOver({ shallow: true });

            if (allowToHoverExternalCards && hoverOnlyOnCurrentTarget) {
                moveCard(monitor.getItem());
            }
        },

        canDrop: () => true,
    });

    const handleLoadMoreClick = () => {
        if (loadMore) {
            loadMore();
        }
    };

    if (disableNextVideo) {
        return (
            <div className={cln({ hovered, disableNextVideo }, className)}>
                <div className={css.info}>
                    <h6 className={css.infoTitle}>Recommendations has been disabled</h6>
                    {enableNextVideoRhfCheckbox}
                </div>
            </div>
        );
    }

    return (
        <div
            ref={drop}
            className={cln({ hovered }, css.nextVideoList, className, {
                isPlaylist: Boolean((hovered && dndItemType === itemTypePlaylist) || (hasPlaylist && hovered)),
            })}
        >
            {showEmptyInfo && (
                <div className={css.info}>
                    <h6 className={css.infoTitle}>Drag and drop</h6>
                    <p className={css.infoContent}>
                        You can override standard recommendations by either single elements or a playlist
                    </p>
                </div>
            )}

            {hasPlaylist ? (
                children
            ) : (
                <>
                    {showVideoList && (
                        <ul className={cln('list', { 'list--filled': filled })} data-testid="nexVideoListContainer">
                            {children.map((child) => (
                                <li key={`next-videos:${child.key}`}>{child}</li>
                            ))}
                        </ul>
                    )}

                    {showLoopedInfo && (
                        <div className={css.loopedInfo}>
                            the list loops back to <strong>{loopsTo}</strong>
                        </div>
                    )}

                    {showLoadMoreButton && (
                        <Button
                            className={css.loadMore}
                            type="button"
                            size="small"
                            variant="standard"
                            onClick={handleLoadMoreClick}
                        >
                            Load more
                        </Button>
                    )}
                </>
            )}
        </div>
    );
}
