import { fromBlob } from './file';

const cache = {};

let config;
let pixelRatio;

/**
 * Returns transformation for given image key and width
 * Adjusts the best
 */
const getImageTransform = (key, width) => {
    const cacheKey = key + width + pixelRatio;

    // first check if there is cached object
    if (cache[cacheKey]) {
        return cache[cacheKey];
    }

    const id = key.split('.');
    let i = 0;
    let transformSizes = config.sizes;
    let transform;

    while (i < id.length) {
        transformSizes = transformSizes[id[i]];

        if (!transformSizes) {
            throw new Error(`Image transformation missing for key ${key}`);
        }

        i += 1;
    }

    const dimensions = transformSizes.reduce((prev, curr) =>
        Math.abs(curr[0] - width) < Math.abs(prev[0] - width) ? curr : prev
    );

    // we do not support px lower than 1 and bigger than 2
    pixelRatio = Math.max(1, Math.min(2, pixelRatio));

    // crop has 'c' as option
    if (dimensions[2] === 'c') {
        // skip pixel density for crop transform
        transform = (dimensions[0] ? dimensions[0] : '') + (dimensions[1] ? `x${dimensions[1]}` : '');

        transform = `${dimensions[2] + transform}q80`;
    } else {
        // multiply image size by aspect ratio
        transform =
            (dimensions[0] ? dimensions[0] * pixelRatio : '') + (dimensions[1] ? `x${dimensions[1] * pixelRatio}` : '');

        // quality for devices with bigger pixel density is lower
        transform += pixelRatio > 1 ? 'q50' : 'q80';
    }

    cache[cacheKey] = transform;

    return transform;
};

const image = {
    configure(options) {
        config = options;
        pixelRatio = config.getPixelRatio();
    },
    getImageSrc(imageId, key, width) {
        if (imageId) {
            const extension = imageId.indexOf('.jpg') === -1 && imageId.indexOf('.png') === -1 ? '.jpg' : '';
            return `${imageId + extension}?t[]=${getImageTransform(key, width)}`;
        }
        return null;
    },
};

/**
 * @param {String} src
 * @param {Object<String, String>} attributes
 * @returns {Promise<HTMLImageElement>}
 */
export function loadImage(src, attributes = {}) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        Object.entries(attributes).forEach(([name, value]) => img.setAttribute(name, value));
        img.onload = () => resolve(img);
        img.onerror = () => reject(new TypeError('Unable to load an image'));
        img.src = src;
    });
}

/**
 * @param {HTMLImageElement} img - Image File Object
 * @param {Number} scaleX
 * @param {Number} scaleY
 * @param {Object} crop - crop Object
 * @property {Number} crop.x
 * @property {Number} crop.y
 * @property {Number} crop.width
 * @property {Number} crop.height
 * @param {String} fileName - Name of the returned file in Promise
 */
export function getCroppedImage(img, scaleX, scaleY, crop, fileName) {
    const width = Math.round(crop.width * scaleX);
    const height = Math.round(crop.height * scaleY);
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, crop.x * scaleX, crop.y * scaleY, width, height, 0, 0, width, height);

    return new Promise((resolve) => {
        canvas.toBlob((blob) => resolve(fromBlob(blob, fileName)), 'image/jpeg', 1);
    });
}

export function addTransformToImage(url, quality = 'x452q50') {
    if (url && !url.match(/t\[\]=/)) {
        return `${url}?t[]=${quality}`;
    }
    return url;
}

export default image;
