import { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { regularPlaylistsFetchList, playlistOfPlaylistsFetchList } from 'store/playlists/list';
import { getPlaylistsList } from 'store/playlists/selectors';
import { selectNewsroom } from 'store/newsroom/selectors';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';

import PlaylistsScreen from './PlaylistsScreen';

function PlaylistsScreenContainer() {
    const dispatch = useDispatch();
    const provider = useSelector(selectNewsroom);
    const list = useSelector(getPlaylistsList);
    const userPermissions = useUserPermissions();
    const fetchRegularPlaylistsList = useCallback(
        ({ name, labels, more } = {}) => dispatch(regularPlaylistsFetchList({ provider, name, labels, more })),
        [dispatch, provider]
    );

    const fetchPlaylistOfPlaylistsList = useCallback(
        ({ name, labels, more } = {}) => dispatch(playlistOfPlaylistsFetchList({ provider, name, labels, more })),
        [dispatch, provider]
    );

    return (
        <PlaylistsScreen
            provider={provider}
            list={list}
            fetchRegularPlaylistsList={fetchRegularPlaylistsList}
            fetchPlaylistOfPlaylistsList={fetchPlaylistOfPlaylistsList}
            isUserProvider={Boolean(userPermissions?.isUser)}
        />
    );
}

export default memo(PlaylistsScreenContainer);
