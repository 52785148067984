import { memo, type ReactEventHandler, useState } from 'react';
import { MdAccessTime, MdDeleteForever, MdContentCopy } from 'react-icons/md';
import { formatRemainingTimeAsUnits } from 'lib/time';
import { HTML5Player } from 'components/core/Player/HTML5Player';
import { CopyToClipboard } from '@schibsted-svp/react-ui';
import css from './MultiPreviewVariant.module.scss';

type MultiPreviewVariantProps = {
    onRemove?: (url: string) => void;
    url: string;
};

export const MultiPreviewVariant = memo(function MultiPreviewVariant({ onRemove, url }: MultiPreviewVariantProps) {
    const [duration, setDuration] = useState<number>();

    const handleRemoveClick: ReactEventHandler<SVGElement> = (event) => {
        if (!onRemove) {
            return;
        }

        event.preventDefault();
        onRemove(url);
    };

    return (
        <div className={css.container}>
            <HTML5Player src={url} loading={false} onDurationChange={setDuration} className={css.player} />
            <div className={css.toolbar}>
                {duration && (
                    <div className={css.duration}>
                        <MdAccessTime className={css.durationIcon} /> {formatRemainingTimeAsUnits(duration)}
                    </div>
                )}

                <div className={css.actions}>
                    <CopyToClipboard text={url}>
                        <MdContentCopy className={css.actionIcon} />
                    </CopyToClipboard>
                    {onRemove ? <MdDeleteForever className={css.actionIcon} onClick={handleRemoveClick} /> : null}
                </div>
            </div>
        </div>
    );
});
