import classnames from 'classnames/bind';
import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form';
import { Checkbox, Divider, LabeledContainer } from '@schibsted-svp/react-ui';
import { getTimeInSeconds } from 'lib/time';
import { getAdjustedEncoderStartTime } from 'components/video/live/LiveCreationDialog/utils';
import { TimeLengthSelect } from 'components/video/live/form/EncoderInputForm/TimeLengthSelect';
import { DateTimeMergedRHF } from 'components/video/edit/Form/DateTimeMerged';
import css from './LiveSchedule.module.scss';

const cln = classnames.bind(css);

const validateEncoderStartTime =
    (startTime: number = getTimeInSeconds()) =>
    (value: number): string | undefined => {
        if (!value || startTime >= value) {
            return undefined;
        }
        return 'Must be a date prior to live video start time';
    };

export interface LiveScheduleFormData {
    encoderDuration?: number;
    enableAssetSchedule: boolean;
    startTime?: number;
    enableEncoderSchedule?: boolean;
    encoderStartTime?: number;
}

interface LiveScheduleProps {
    newsroom: string;
    formApi: UseFormReturn<LiveScheduleFormData>;
    isRunning?: boolean;
    dvrWarning?: string;
    scheduleError?: { message: string; assetId: number }[];
}

export function LiveSchedule({ newsroom, formApi, isRunning = false, dvrWarning, scheduleError }: LiveScheduleProps) {
    const { register, control, setValue, trigger, formState } = formApi;
    const { isSubmitting } = formState;

    const [enableAssetSchedule, enableEncoderSchedule, startTime, encoderDuration, encoderStartTime] = useWatch({
        name: ['enableAssetSchedule', 'enableEncoderSchedule', 'startTime', 'encoderDuration', 'encoderStartTime'],
        control,
    });

    const disabledEncoderSchedule = !enableAssetSchedule || !enableEncoderSchedule;

    const setEncoderStartTime = useCallback(
        (value) => {
            setValue('encoderStartTime', getAdjustedEncoderStartTime(newsroom, value));
        },
        [newsroom, setValue]
    );

    useEffect(() => {
        if (enableAssetSchedule && startTime && !(enableEncoderSchedule || encoderStartTime)) {
            setEncoderStartTime(startTime);
        }
    }, [enableAssetSchedule, enableEncoderSchedule, encoderStartTime, startTime, setEncoderStartTime]);

    useEffect(() => {
        trigger('encoderStartTime');
    }, [enableEncoderSchedule, trigger]);

    const handleStartTimeChange = (newValue) => {
        if (!enableEncoderSchedule) {
            setEncoderStartTime(newValue);
        }
    };

    return (
        <>
            <LabeledContainer label="Stop live video after" width={270}>
                <TimeLengthSelect
                    name="encoderDuration"
                    control={control}
                    setValue={setValue}
                    defaultLabel="Unlimited"
                    placeholder="Unlimited"
                    isClearable
                    currentValue={encoderDuration}
                />
                {dvrWarning && <div className={css.dvrWarning}>{dvrWarning}</div>}
            </LabeledContainer>

            {!isRunning && (
                <>
                    <Divider margin="10px 0" />
                    <div className={css.schedules}>
                        <LabeledContainer label="Schedule live video for later" className={css.assetSchedule}>
                            <Checkbox
                                {...register('enableAssetSchedule')}
                                label="Set later start time for live video"
                            />

                            <div className={cln(css.field, { 'field--collapsed': !enableAssetSchedule })}>
                                <DateTimeMergedRHF
                                    name="startTime"
                                    control={control}
                                    readOnly={isSubmitting}
                                    disabled={!enableAssetSchedule}
                                    size="big"
                                    labeled
                                    onChange={handleStartTimeChange}
                                />
                            </div>
                        </LabeledContainer>
                        <LabeledContainer
                            label="Encoder start time"
                            className={cln(css.encoderSchedule, {
                                'encoderSchedule--visible': enableAssetSchedule,
                            })}
                        >
                            <Checkbox
                                {...register('enableEncoderSchedule')}
                                label="Set custom start time for encoder"
                                disabled={!enableAssetSchedule}
                            />

                            <div className={css.field}>
                                <DateTimeMergedRHF
                                    name="encoderStartTime"
                                    control={control}
                                    readOnly={isSubmitting}
                                    disabled={disabledEncoderSchedule}
                                    validate={enableEncoderSchedule ? validateEncoderStartTime(startTime) : undefined}
                                    size="big"
                                    labeled
                                />
                            </div>
                        </LabeledContainer>
                        {scheduleError?.length > 0 && (
                            <div>
                                {scheduleError.map((errorData) => (
                                    <div className={css.scheduleError} key={errorData.assetId}>
                                        <span>{errorData.message} </span>
                                        <Link
                                            to={`/${newsroom}/assets/${errorData.assetId}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {errorData.assetId}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
