import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { omit } from 'lodash';
import BEM from 'lib/bem';
import { getDuration, isEncrypted } from 'models/asset';
import { Button, Spinner } from '@schibsted-svp/react-ui';
import VideoCard from 'components/core/VideoCard';
import { Playback as RhfPlayback } from 'components/AssetForm/containers/Playback';
import Playback from '../edit/Form/Playback';
import ReduxFormPlayback from '../edit/Form/Playback/ReduxFormPlayback';
import ClipCard from './ClipCard';
import './Clips.scss';

const bem = new BEM('Clips');

const getDetailedDuration = (asset) => {
    return getDuration(asset, 'ms') / 1000;
};
export default class Clips extends Component {
    static propTypes = {
        className: PropTypes.string,
        provider: PropTypes.string.isRequired,
        asset: PropTypes.object.isRequired,
        originAsset: PropTypes.shape({
            asset: PropTypes.object,
            loading: PropTypes.bool,
        }).isRequired,
        clipList: PropTypes.shape({
            assetList: PropTypes.arrayOf(PropTypes.number),
            loading: PropTypes.bool,
            hasMore: PropTypes.bool,
            nextUrl: PropTypes.string,
        }).isRequired,
        fetchAsset: PropTypes.func.isRequired,
        fetchClips: PropTypes.func.isRequired,
        createClip: PropTypes.func.isRequired,
        trimVideo: PropTypes.func.isRequired,
        resetTrim: PropTypes.func.isRequired,
        ready: PropTypes.bool.isRequired,
        activeLive: PropTypes.bool.isRequired,
        isCreateClipPending: PropTypes.bool.isRequired,
        isTrimPending: PropTypes.bool.isRequired,
        isTrimInProgress: PropTypes.bool.isRequired,
        isConversionInProgress: PropTypes.bool.isRequired,
        isResetInProgress: PropTypes.bool,
        originalStreamUrls: PropTypes.string,
        rhfControl: PropTypes.object,
        rhfIsSubmitting: PropTypes.bool,
    };

    static defaultProps = {
        className: undefined,
        isResetInProgress: false,
        originalStreamUrls: undefined,
        rhfControl: {},
        rhfIsSubmitting: false,
    };

    componentDidMount() {
        this.fetchOriginAsset();
        this.fetchAssetClips();
    }

    onLoadMoreClick = () => {
        this.fetchAssetClips();
    };

    /**
     * Returns the origin asset id
     * @return {Number|undefined}
     */
    get originAssetId() {
        const { asset } = this.props;

        return asset.additional.originAssetId;
    }

    /**
     * @return {Boolean}
     */
    get loading() {
        const { clipList, originAsset } = this.props;

        if (!this.originAssetId) {
            return clipList.loading;
        }

        return originAsset.loading || clipList.loading;
    }

    handleClipCreate = (playback) => {
        const { asset, provider, createClip } = this.props;
        createClip(asset.id, provider, playback);
    };

    handleVideoTrim = (playback) => {
        const { asset, provider, trimVideo } = this.props;

        trimVideo(asset.id, provider, playback);
    };

    handleResetTrim = () => {
        const { asset, provider, resetTrim, originalStreamUrls } = this.props;

        const parsedOriginalStreamUrls = JSON.parse(originalStreamUrls);

        const previousData = {
            streamUrls: parsedOriginalStreamUrls,
            additional: {
                ...asset.additional,
                metadata: omit(asset.additional?.metadata, ['originalStreamUrls', 'previousStreamUrls']),
            },
        };

        resetTrim({ assetId: asset.id, provider, previousData });
    };

    fetchOriginAsset() {
        const { provider, originAsset, fetchAsset } = this.props;

        if (this.originAssetId && !originAsset.asset && !originAsset.loading) {
            fetchAsset({
                id: this.originAssetId,
                provider,
            });
        }
    }

    fetchAssetClips() {
        const { asset, provider, clipList, fetchClips } = this.props;
        const { assetList, loading, nextUrl, hasMore } = clipList;

        if (!loading && (!assetList || (assetList.length && hasMore))) {
            fetchClips(this.originAssetId || asset.id, provider, nextUrl);
        }
    }

    renderPlaybackForm = () => {
        const {
            asset,
            ready,
            activeLive,
            isCreateClipPending,
            isTrimPending,
            isTrimInProgress,
            isConversionInProgress,
            isResetInProgress,
            originalStreamUrls,
            rhfControl,
            rhfIsSubmitting,
        } = this.props;
        const duration = asset.streamType !== 'live' ? getDetailedDuration(asset) : undefined;
        const disabled = this.loading || !ready || isResetInProgress;

        if (this.originAssetId) {
            return (
                <RhfPlayback
                    className={bem.element('form')}
                    component={ReduxFormPlayback}
                    disabled={disabled}
                    duration={duration}
                    control={rhfControl}
                    isSubmitting={rhfIsSubmitting}
                />
            );
        }

        // Cannot handle a clip creation from a non active live video
        const canCreateClip = asset.streamType !== 'live' || activeLive;
        const canVideoTrim = asset.streamType === 'wasLive';
        // Reset trim does not work for encrypted assets
        const canResetTrim = !isEncrypted(asset) && canVideoTrim && originalStreamUrls;
        if (!canCreateClip && !canVideoTrim) {
            return <div className={bem.element('info')}>Live video has to be running to allow clip creation.</div>;
        }

        return (
            <Playback
                className={bem.element('form')}
                disabled={disabled}
                duration={duration}
                onClipCreate={canCreateClip ? this.handleClipCreate : undefined}
                onVideoTrim={canVideoTrim ? this.handleVideoTrim : undefined}
                onResetTrim={canResetTrim ? this.handleResetTrim : undefined}
                createClipLoading={isCreateClipPending}
                trimLoading={isTrimPending}
                trimDisabled={isTrimInProgress || isConversionInProgress}
                resetTrimLoading={isResetInProgress}
                precise
            />
        );
    };

    renderClipList() {
        const { asset, originAsset, clipList, provider } = this.props;
        const { assetList, loading, hasMore } = clipList;

        const clips = assetList && assetList.filter((id) => id !== asset.id);

        const showClips = clips != null && clips.length > 0;
        const showMoreButton = !loading && clips != null && clips.length > 0 && hasMore;
        const showOriginAsset = this.originAssetId && originAsset.asset != null;

        if (this.loading) {
            return <Spinner containerClassName={bem.element('spinner')} />;
        }

        return (
            <>
                {showClips && (
                    <div className={bem.element('grid')}>
                        {clips.map((clipId) => (
                            <ClipCard
                                key={`${provider}-asset-${clipId}`}
                                provider={provider}
                                id={clipId}
                                data-testid="clipVideoCard"
                            />
                        ))}

                        {showMoreButton && (
                            <Button
                                className={bem.element('load-more-button')}
                                size="small"
                                type="button"
                                variant="standard"
                                onClick={this.onLoadMoreClick}
                            >
                                Load more
                            </Button>
                        )}
                    </div>
                )}
                {showOriginAsset && (
                    <div className={bem.element('original-container')}>
                        <span className={bem.element('heading')}>ORIGINAL VIDEO</span>
                        <VideoCard
                            readOnly
                            className={bem.element('original-video')}
                            variant="simple"
                            provider={provider}
                            id={originAsset.asset.id}
                            data-testid="clipVideoCard"
                        />
                    </div>
                )}
            </>
        );
    }

    render() {
        const { className } = this.props;

        return (
            <section className={bem.block(undefined, className)}>
                {this.renderPlaybackForm()}
                {this.renderClipList()}
            </section>
        );
    }
}
