import { memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { addTransformToImage } from 'lib/image';
import { MdFolder, MdImage } from 'react-icons/md';
import { getCategoryChildren } from 'store/categories/selectors';
import css from './CategoriesList.module.scss';

const classNames = classnames.bind(css);

type ThumbnailProps = { url: string };
function Thumbnail({ url }: ThumbnailProps) {
    return url ? (
        <img className={css.thumbnail} src={addTransformToImage(url, '180q80')} alt="" />
    ) : (
        <span className={css['no-image-icon']}>
            <MdImage className={css.icon} />
        </span>
    );
}

type CategoriesListProps = { provider: string };
export const CategoriesList = memo(function CategoriesList({ provider }: CategoriesListProps) {
    const { category: categoryId } = useParams<{ category: string }>();
    const tree = useSelector((state) => getCategoryChildren(state, provider, categoryId));
    const history = useHistory();
    const { data: parent, children } = tree;

    const handleRowClick = (url) => {
        history.push(url);
    };

    return (
        <table className={css.table}>
            <thead>
                <tr>
                    <th colSpan={2}>CATEGORY</th>
                </tr>
            </thead>
            <tbody>
                {parent && (
                    <tr
                        onClick={() => handleRowClick(`/${provider}/category/${parent.id}`)}
                        className={classNames({ disabled: !parent.showCategory })}
                    >
                        <td className={css['thumbnail-cell']} aria-label="parent category row">
                            <Thumbnail url={parent.additional.image} />
                        </td>
                        <td className={css['title-cell']}>{parent.title}</td>
                    </tr>
                )}
                {children.map((category) => {
                    const {
                        id,
                        title,
                        showCategory,
                        additional: { image },
                    } = category.data;
                    const hasChildren = category.children.length;

                    return (
                        <tr
                            key={id}
                            className={classNames({ disabled: !hasChildren && !showCategory })}
                            onClick={() =>
                                handleRowClick(
                                    hasChildren ? `/${provider}/categories/${id}` : `/${provider}/category/${id}`
                                )
                            }
                        >
                            <td className={classNames('thumbnail-cell', { 'child-row': parent })}>
                                {hasChildren ? <MdFolder className={css['dir-icon']} /> : <Thumbnail url={image} />}
                            </td>
                            <td
                                data-testid={hasChildren ? 'parentCategoryTitleCell' : 'categoryTitleCell'}
                                className={css['title-cell']}
                            >
                                {title}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
});
