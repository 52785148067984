import { get } from 'lodash';
import * as graphql from './graphql/origin.graphql';

/**
 * @param {Object} params
 * @param {String} params.newsroom
 * @param {Number} params.assetId
 * @param {String} params.languageCode
 * @param {String} params.source
 * @return {Promise<Object>}
 */
export async function uploadSubtitles({ newsroom, assetId, languageCode, source }) {
    const response = await this.query(graphql.uploadSubtitles, {
        provider: newsroom,
        assetId,
        languageCode,
        source,
    });

    return get(response, 'uploadSubtitles');
}

/**
 * @param {object} params
 * @param {string} params.provider
 * @param {number} params.assetId
 * @return {Promise<number | null>}
 */
export async function getStreamDuration({ provider, assetId }) {
    const response = await this.query(
        graphql.streamDuration,
        {
            provider,
            assetId,
        },
        false
    );
    return get(response, 'streamDuration');
}
