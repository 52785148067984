import { Component } from 'react';
import * as PropTypes from 'prop-types';

import Playback from './index';

export default class ReduxFormPlayback extends Component {
    static propTypes = {
        input: PropTypes.shape({
            value: PropTypes.shape({
                begin: PropTypes.number,
                end: PropTypes.number,
            }),
            onChange: PropTypes.func.isRequired,
        }).isRequired,
        meta: PropTypes.shape({
            submitting: PropTypes.bool.isRequired,
        }).isRequired,
        disabled: PropTypes.bool,
        duration: PropTypes.number,
    };

    static defaultProps = {
        disabled: false,
        duration: undefined,
    };

    onChange = (value) => {
        const {
            input: { onChange },
        } = this.props;

        onChange({
            begin: Math.floor(value.begin),
            end: Math.floor(value.end),
        });
    };

    render() {
        const { input, meta, disabled, duration, ...props } = this.props;

        return (
            <Playback
                {...props}
                disabled={disabled || meta.submitting}
                duration={duration}
                onChange={this.onChange}
                value={input.value}
            />
        );
    }
}
