import {
    isConversionInProgress,
    isVideoReadyForDownloading,
    canVideoBePreparedForDownloading,
} from 'store/video/stream-conversion/selectors';

import { getAssetData } from 'store/assets/selectors';

import { isVodDownloadable } from 'models/asset';

import { areTranscodingsDone } from 'store/video/selectors';
import { fetchStreamConversion } from 'store/video/stream-conversion/actions';
import { connect } from 'react-redux';

import StreamControls from './StreamControls';

const mapStateToProps = (state, { id, provider }) => {
    const { asset } = getAssetData(state, { id, provider });

    const isPreparingForDownloading = isConversionInProgress(state, { id, provider });
    const isReadyForDownloading = isVideoReadyForDownloading(state, { id, provider });
    const canBePreparedForDownloading = canVideoBePreparedForDownloading(state, { id, provider });
    const disabled = !areTranscodingsDone(state, { provider, id });

    return {
        isPreparingForDownloading,
        isReadyForDownloading: isReadyForDownloading || isVodDownloadable(asset),
        canBePreparedForDownloading,
        disabled,
    };
};

export default connect(mapStateToProps, { fetchStreamConversion })(StreamControls);
