import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const SECOND = 1000;
export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;

export function getTimeInSeconds() {
    return Math.trunc(Date.now() / SECOND);
}

/**
 * Returns a string representation of time.
 * If the time duration is less than 0 then representation
 * will print dash characters instead of digits.
 *
 * @param {Number|String|Object|undefined} value
 * @returns {String}
 */
export function getFormattedTime(value) {
    const duration = moment.duration(value);
    const milliseconds = duration.asMilliseconds();

    if (milliseconds < 0) {
        return '--:--:--';
    }
    const hours = `${Math.floor(duration.asHours())}`.padStart(2, '0');
    return moment.utc(milliseconds).format(`${hours}:mm:ss`);
}

export function getFormattedTimeWithDays(value) {
    const duration = moment.duration(value);
    const milliseconds = duration.asMilliseconds();

    const ONE_DAY = 60 * 60 * 24 * 1000;
    if (ONE_DAY < milliseconds) {
        const days = Math.floor(milliseconds / ONE_DAY);
        return `${days} ${days > 1 ? 'days ' : 'day '}`;
    }

    const hours = `${Math.floor(duration.asHours())}`.padStart(2, '0');
    return moment.utc(milliseconds).format(`${hours}:mm:ss`);
}

/**
 * Returns a time in a proper unit which is 'milliseconds' by default.
 *
 * @param {Number|String|Object|undefined} value
 * @param {String} [unit=ms] - Moment's time unit
 * @param {Boolean} [precise=false]
 * @returns {Number}
 */
export function getTime(value, unit = 'ms', precise = false) {
    const duration = moment.duration(value).as(unit);
    return precise ? duration : Math.floor(duration);
}

/**
 * Returns UNIX timestamp of a provided date and time
 *
 * @param {String|Date} date Date string e.g. (01.01.2018)
 * @param {String|Date} time Time string e.g. (10:53)
 * @param {String} dateFormat [DD.MM.YYYY]
 * @param {String} timeFormat [HH:mm]
 */
export function mergeDateAndTime(date, time, dateFormat = DATE_FORMAT, timeFormat = TIME_FORMAT) {
    const dateString = moment(date || new Date(), dateFormat).format(dateFormat);
    const timeString = moment(time || new Date(), timeFormat).format(timeFormat);
    return moment(`${dateString} ${timeString}`, 'DD-MM-YYYY HH:mm').unix();
}

/**
 * Returns true if a provided timestamp is greater then current time
 *
 * @param {Number} timestamp Unix timestamp (seconds)
 */
export function isFromFuture(timestamp) {
    return timestamp > moment().unix();
}

/**
 * Return time as string from a given unix timestamp
 *
 * @param {Number} timestamp Unix timestamp
 * @param {String} timeFormat [HH:mm]
 */
export const getTimeString = (timestamp, timeFormat = TIME_FORMAT) => moment.unix(timestamp).format(timeFormat);

/**
 * Return date as string from a given unix timestamp
 *
 * @param {Number} timestamp Unix timestamp
 * @param {String} dateFormat [DD.MM.YYYY]
 */
export const getDateString = (timestamp, dateFormat = DATE_FORMAT) => moment.unix(timestamp).format(dateFormat);

/**
 * @param {String|Date} date Date string e.g. (01.01.2018)
 * @param {String} dateFormat [DD.MM.YYYY]
 */
export const getTimestamp = (date, dateFormat = DATE_FORMAT) => moment(date, dateFormat).unix();

/**
 * Return date difference in seconds
 *
 * @param {Number} timestamp Unix timestamp
 * @return {Number}
 */
export const getDateDiff = (timestamp) => moment.duration(moment.unix(timestamp).diff()).asSeconds();

/**
 * Check if the first date is in the same day as the second one
 *
 * @param {Date} firstDate First date
 * @param {Date} secondDate Second date
 */
export const isSameDay = (firstDate, secondDate) => moment(firstDate).isSame(secondDate, 'day');

/**
 * Converts seconds to player time property
 *
 * @param {Number} secs Seconds to convert
 * @return {String}
 */
export const secondsToTime = (secs) => {
    const pad = (num) => `0${num}`.slice(-2);
    const minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);
    return `${pad(hours)}h${pad(minutes % 60)}m${pad(secs % 60)}s`;
};

/**
 * @param {Number} secondsLeft
 * @return {String}
 */
export const formatRemainingTime = (secondsLeft) => {
    const duration = moment.duration(secondsLeft, 'seconds');
    const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
    return moment.utc(duration.asMilliseconds()).format(`${hours}:mm:ss`);
};

/**
 * @param {Number} secondsLeft
 * @return {String}
 */
export const formatRemainingExactTime = (secondsLeft) => {
    const duration = moment.duration(secondsLeft, 'seconds');
    const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
    return moment.utc(duration.asMilliseconds()).format(`${hours}:mm:ss.SSS`);
};

/**
 * @param {Number} secondsLeft
 * @return {String}
 */
export const formatRemainingTimeAsUnits = (secondsLeft) => {
    const output = [];
    const duration = moment.duration(secondsLeft, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const milliseconds = duration.milliseconds();

    if (hours) {
        output.push(`${hours}h`);
    }
    if (minutes) {
        output.push(`${minutes}m`);
    }
    if (seconds) {
        output.push(`${seconds}s`);
    }
    if (milliseconds) {
        output.push(`${milliseconds}ms`);
    }

    return output.join(' ');
};
