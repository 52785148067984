import { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { compose } from '@reduxjs/toolkit';
import { get } from 'lodash';

import BEM from 'lib/bem';

import { Button, LabeledContainer, InputError, Input } from '@schibsted-svp/react-ui';
import { TimeInput } from 'components/ui/Form/TimeInput';

import { withFormPlayer } from 'components/video/edit/Form/Player';

import validator from './HighlightForm.validator';
import './HighlightForm.scss';

const bem = new BEM('HighlightForm');

const defaultValue = { startTime: 0, title: '' };

class HighlightForm extends PureComponent {
    static propTypes = {
        submitDisabled: PropTypes.bool,
        excluded: PropTypes.arrayOf(PropTypes.number),
        onSubmit: PropTypes.func.isRequired,
        className: PropTypes.string,
        player: PropTypes.object,
        duration: PropTypes.number,
    };

    static defaultProps = {
        submitDisabled: false,
        excluded: [],
        className: undefined,
        player: null,
        duration: 0,
    };

    state = {
        ...defaultValue,
    };

    setStartTime = (startTime) => {
        const { errors = {} } = this.state;
        delete errors.startTime;

        this.setState((prevState) => ({
            ...prevState,
            startTime: Math.round(startTime),
            errors,
        }));
    };

    handleStartTimePickFromPlayer = () => {
        const { player } = this.props;

        this.setStartTime(player.currentTime);
    };

    handleSubmit = () => {
        const { onSubmit, excluded, player, duration } = this.props;
        const { title, startTime } = this.state;
        const highlight = { title, startTime };
        const validation = validator(highlight, { duration: player.duration || duration, excluded });

        if (validation.passes()) {
            onSubmit(highlight);
            this.setState(() => ({
                ...defaultValue,
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                errors: validation.errors.all(),
            }));
        }
    };

    /**
     * Emulates submit when the `Enter` key is pressed
     * @param {Event} event
     */
    handleSubmitOnEnter = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit(event);
        }
    };

    handleTitleChange = (event) => {
        const title = event.target.value;
        const { errors = {} } = this.state;
        delete errors.title;

        this.setState((prevState) => ({
            ...prevState,
            title,
            errors,
        }));
    };

    render() {
        const { submitDisabled, className, player } = this.props;
        const { title, startTime, errors } = this.state;
        const formDisabled = !player;
        const startTimeError = get(errors, 'startTime[0]');
        const titleError = get(errors, 'title[0]');

        return (
            <div className={bem.block({ error: Boolean(startTimeError) || Boolean(titleError) }, className)}>
                <LabeledContainer strict label="Start time" className={bem.element('start-time-input')}>
                    <TimeInput
                        size="small"
                        value={startTime}
                        maxValue={get(player, 'duration')}
                        disabled={formDisabled}
                        onKeyPress={this.handleSubmitOnEnter}
                        onValueChange={this.setStartTime}
                    />
                </LabeledContainer>

                <LabeledContainer className={bem.element('start-time-button')}>
                    <Button
                        type="button"
                        size="small"
                        variant="standard"
                        disabled={formDisabled}
                        onClick={this.handleStartTimePickFromPlayer}
                    >
                        Set start time
                    </Button>
                </LabeledContainer>

                <LabeledContainer strict label="Title" className={bem.element('title-input')}>
                    <Input
                        size="small"
                        value={title}
                        disabled={formDisabled}
                        onChange={this.handleTitleChange}
                        onKeyPress={this.handleSubmitOnEnter}
                    />
                </LabeledContainer>

                <LabeledContainer className={bem.element('title-button')}>
                    <Button
                        type="button"
                        size="small"
                        onClick={this.handleSubmit}
                        disabled={submitDisabled || formDisabled}
                    >
                        Add highlight
                    </Button>
                </LabeledContainer>

                {startTimeError && <InputError>{startTimeError}</InputError>}
                {titleError && <InputError>{titleError}</InputError>}
            </div>
        );
    }
}

const enhance = compose(withFormPlayer);

export default enhance(HighlightForm);
