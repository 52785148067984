import { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import * as ReactGA from 'react-ga';

export default class PageViewTracker extends PureComponent {
    static propTypes = {
        path: PropTypes.string.isRequired,
        query: PropTypes.string.isRequired,
    };

    componentDidMount() {
        const { path, query } = this.props;

        ReactGA.pageview(path + query);
    }

    componentDidUpdate(prevProps) {
        const { path, query } = this.props;

        const currPage = path + query;
        const prevPage = prevProps.path + prevProps.query;

        if (currPage !== prevPage) {
            ReactGA.pageview(currPage);
        }
    }

    render() {
        return null;
    }
}
