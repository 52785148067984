import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Dialog, Checkbox } from '@schibsted-svp/react-ui';
import { RiErrorWarningLine } from 'react-icons/ri';
import { generateUniqId } from 'lib/uniq';
import LiveCreationDialogFooter from 'components/video/live/LiveCreationDialog/LiveCreationDialogFooter';
import LiveCreationDialogSpinner from 'components/video/live/LiveCreationDialog/LiveCreationDialogSpinner';
import { RtmpStreamKey } from './RtmpStreamKey';
import { useRtmpForm, useRtmpFormFormSubmit } from './hooks';
import type { RtmpFormControl } from './types';
import css from './RtmpForm.module.scss';

interface RtmpFormProps {
    formControl: RtmpFormControl;
    isEditing: boolean;
}

export function RtmpForm({ formControl, isEditing }: RtmpFormProps) {
    const defaultStreamKey = useRef(generateUniqId());

    const { formApi } = useRtmpForm();

    const {
        control,
        register,
        formState: { isSubmitting, isValid },
    } = formApi;

    const handleSubmit = useRtmpFormFormSubmit(formApi, formControl);

    return (
        <>
            <Dialog.Section>
                <form>
                    <Controller
                        name="streamKey"
                        defaultValue={defaultStreamKey.current}
                        control={control}
                        rules={{ required: 'Stream key is required' }}
                        render={({ field, fieldState }) => (
                            <RtmpStreamKey
                                {...field}
                                defaultValue={defaultStreamKey.current}
                                error={fieldState.error?.message}
                            />
                        )}
                    />
                    <Checkbox {...register('audioStream')} label="Audio only stream" className={css.audioCheckbox} />
                    <div className={css.dvrInfo}>
                        <RiErrorWarningLine size={20} />
                        DVR is not supported
                    </div>
                </form>
                {isSubmitting && <LiveCreationDialogSpinner />}
            </Dialog.Section>

            <LiveCreationDialogFooter
                onSubmit={handleSubmit}
                onCancel={formControl.onCancel}
                submitButtonText={isEditing ? 'Set source' : 'Create'}
                disabled={isSubmitting || !isValid}
            />
        </>
    );
}
