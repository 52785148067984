import qraphql from './graphql/yats.graphql';

/**
 * @param {Object} params
 * @param {String} params.assetId
 * @param {String} params.provider
 * @param {Array<{begin: Number, end?: Number}>} params.cutRanges
 * @returns {Promise<{assetId: Number, originAssetId: Number, errorMessage: String}>}
 */
export async function createClip({ assetId, provider, cutRanges }) {
    const { createClips } = await this.query(qraphql.CreateClips, {
        assetId,
        provider,
        cutRanges,
    });
    return {
        assetId: createClips.assetId,
        originAssetId: createClips.originAssetId,
        errorMessage: createClips.message,
    };
}

/**
 * @param {Object} params
 * @param {String} params.assetId
 * @param {String} params.provider
 * @param {Array<{begin: Number, end?: Number}>} params.cutRanges
 * @returns {Promise<Boolean>}
 */
export async function trimAsset({ assetId, provider, cutRanges }) {
    const { trimAsset: success } = await this.query(qraphql.TrimAsset, {
        assetId,
        provider,
        cutRanges,
    });

    return success;
}
