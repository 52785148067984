import { MdHelp } from 'react-icons/md';
import Tooltip from 'components/ui/Tooltip';
import css from './PlayerShortcuts.module.scss';

type PlayerShortcutsProps = {
    isAudio: boolean;
};

export function PlayerShortcuts({ isAudio }: PlayerShortcutsProps) {
    const moveUnit = isAudio ? 'second' : 'frame';

    const tooltipContent = (
        <div className={css.container}>
            <div className={css.header}>Player keyboard shortcuts</div>
            <ul className={css.list}>
                <li>
                    <span>J</span> move backward by 1 {moveUnit}
                </li>
                <li>
                    <span>K</span> pause/play
                </li>
                <li>
                    <span>L</span> move forward by 1 {moveUnit}
                </li>
            </ul>
        </div>
    );

    return (
        <Tooltip theme="light" content={tooltipContent} placement="left" offset={[0, 12]}>
            <span className={css.helpIcon}>
                <MdHelp />
            </span>
        </Tooltip>
    );
}
