import { useCallback, useMemo } from 'react';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { useFieldArray } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { LabeledContainer } from '@schibsted-svp/react-ui';
import { useAppSelector } from 'store/hooks';
import { getPendingSubtitlesUploads } from 'store/uploads/subtitles/selectors';
import { getProgressInfo } from 'store/video/navigation-tabs/selectors';
import type { FormValues } from '../../types';
import type { SubtitlesUploadedData, SubtitlesUploadingData } from './types';
import { UploadSubtitles } from './UploadSubtitles';
import { SubtitlesList } from './SubtitlesList';

type SubtitlesProps = {
    provider: string;
    assetId: number;
    streamType: Asset['streamType'];
    formApi: UseFormReturn<FormValues>;
};

export function mergeSubtitles(subtitles: SubtitlesUploadedData[], uploads: SubtitlesUploadingData[]) {
    return uploads.reduce((allSubtitles, uploadedSubtitle) => {
        const isReplacedSubtitle = ({ language }) => language === uploadedSubtitle.language;

        if (!allSubtitles.some(isReplacedSubtitle)) {
            return [...allSubtitles, uploadedSubtitle];
        }

        return allSubtitles.map((subtitle) => {
            return isReplacedSubtitle(subtitle) ? uploadedSubtitle : subtitle;
        });
    }, subtitles);
}

export function useUploadingProgress({ provider, assetId }: { provider: string; assetId: number }) {
    const { type } = useSelector((state) => getProgressInfo(state, { provider, id: assetId }));
    return {
        isUploading: Boolean(type),
    };
}

export function Subtitles({ provider, assetId, streamType, formApi }: SubtitlesProps) {
    const { control, getValues } = formApi;

    const { fields, append, remove, replace, update } = useFieldArray({
        control,
        name: 'additional.subtitles',
    });

    const { isUploading } = useUploadingProgress({ provider, assetId });
    const uploads = useAppSelector((state) => getPendingSubtitlesUploads(state, { newsroom: provider, assetId }));
    const allSubtitles = useMemo(() => mergeSubtitles(fields, uploads), [fields, uploads]);

    const setDefault = useCallback(
        (newIndex: number) => {
            replace(
                getValues('additional.subtitles').map((field, index) => {
                    return {
                        ...field,
                        default: newIndex === index && !field.default,
                    };
                })
            );
        },
        [getValues, replace]
    );

    const addSubtitlesToForm = useCallback(
        (subtitles: SubtitlesUploadedData) => {
            const index = getValues('additional.subtitles').findIndex(
                ({ language }) => language === subtitles.language
            );
            if (index !== -1) {
                return update(index, subtitles);
            }
            return append(subtitles);
        },
        [append, getValues, update]
    );

    return (
        <LabeledContainer label="Subtitles">
            <UploadSubtitles
                disabled={isUploading}
                provider={provider}
                assetId={assetId}
                addSubtitlesToForm={addSubtitlesToForm}
                streamType={streamType}
            />
            <SubtitlesList
                provider={provider}
                assetId={assetId}
                disabled={isUploading}
                subtitles={allSubtitles}
                onDelete={remove}
                onDefaultChange={setDefault}
            />
        </LabeledContainer>
    );
}
