import { Component } from 'react';
import * as PropTypes from 'prop-types';

import { toPercentageStr } from 'lib/number';

import classnames from 'classnames/bind';
import SnapshotsSource from './SnapshotsSource';
import StreamSource from './StreamSource';
import ImageComponent from '../../core/Image/Image';

import css from './AssetPreview.module.scss';

const cln = classnames.bind(css);

class AssetPreview extends Component {
    static propTypes = {
        // displays only frame at given sec instead of preview overlay with progress bar
        mode: PropTypes.oneOf(['stream', 'snapshots']).isRequired,
        asset: PropTypes.shape({
            id: PropTypes.number.isRequired,
            duration: PropTypes.number.isRequired,
            streamType: PropTypes.string.isRequired,
            images: PropTypes.shape({
                main: PropTypes.string.isRequired,
                snapshots: PropTypes.string,
            }).isRequired,
        }).isRequired,
        width: PropTypes.number.isRequired,
        frame: PropTypes.number,
        className: PropTypes.string,
        children: PropTypes.node,
        expandHeight: PropTypes.bool,
    };

    static defaultProps = {
        frame: null,
        className: '',
        children: undefined,
        expandHeight: false,
    };

    element = null;

    state = {
        position: -1,
        hovered: false,
    };

    setElement = (element) => {
        if (this.element === null) {
            this.element = element;
            this.forceUpdate();
        }
    };

    setFocus = (hovered) => () => {
        this.setState({
            hovered,
        });
    };

    onMouseMove = (event) => {
        const { left, width } = this.element.getBoundingClientRect();
        const x = window.scrollX + left;

        this.setState({
            position: (event.pageX - x) / width || 0,
        });
    };

    /**
     * When seeking through preview is available then position equal to current bar status
     * @returns {*}
     */
    getPosition() {
        if (this.isSeekable()) {
            return this.state.position;
        }

        return this.props.frame;
    }

    isSeekable() {
        const { asset, frame } = this.props;

        if (typeof frame === 'number') {
            return false;
        }

        if (!asset.images.snapshots) {
            // seekable is implemented with player for waslive assets
            return asset.streamType === 'wasLive';
        }

        return true;
    }

    renderPreview() {
        const { asset, mode, expandHeight, children } = this.props;
        const { hovered } = this.state;

        const position = this.getPosition();

        if (children || !hovered) {
            return null;
        }

        if (mode === 'stream') {
            return <StreamSource asset={asset} hovered={hovered} position={position} expandHeight={expandHeight} />;
        }

        const { snapshots } = asset.images;

        if (this.element === null || !snapshots) {
            return null;
        }

        return (
            <SnapshotsSource
                asset={asset}
                snapshots={snapshots}
                position={position}
                duration={asset.duration}
                hovered={hovered}
                element={this.element}
            />
        );
    }

    renderSeekbar() {
        const { hovered } = this.state;

        if (hovered && this.isSeekable()) {
            return (
                <div className={css.indicator}>
                    <span
                        className={css['indicator-progress']}
                        style={{ width: toPercentageStr(this.getPosition()) }}
                    />
                </div>
            );
        }
        return null;
    }

    render() {
        const { asset, width, className, children } = this.props;
        const { main: src } = asset.images;

        return (
            <div className={cln('AssetPreview', className)} data-testid="higlightPreviw">
                <div
                    ref={this.setElement}
                    className={css.progress}
                    onMouseMove={this.onMouseMove}
                    onMouseEnter={this.setFocus(true)}
                    onMouseLeave={this.setFocus(false)}
                >
                    {this.renderPreview()}
                    {this.renderSeekbar()}
                </div>
                <ImageComponent src={src} type="poster" width={width} className={css.image} />
                {children}
            </div>
        );
    }
}

export default AssetPreview;
