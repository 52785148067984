import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/en-gb';
import { hlsProfile } from 'services/admin-bff/contants';
import { MINUTE, DAY } from 'lib/time';
import * as assetQuality from './asset-quality';
import { contentTypes } from './content-types';
import { auth } from './auth';

moment.locale('en-gb');
const momentNorwegian = moment();
momentNorwegian.locale('nb');

declare const __RELEASE__: string;

const staticPath = '/static/assets';

const config = {
    api: {
        host: 'https://cms-api-stage.stream.schibsted.media',
        path: '/api',
    },
    adminBff: {
        host: 'https://cms-bff-stage.stream.schibsted.media',
    },
    svpApi: {
        host: 'https://svp-stage.vg.no',
        path: '/svp/api',
        query: {
            appName: 'svpAdmin',
        },
    },
    playlistsApi: {
        host: 'https://playlist-stage.stream.schibsted.media',
    },
    mqtt: {
        url: 'wss://mqtt-stage.stream.schibsted.media',
        options: {
            // https://github.com/mqttjs/MQTT.js#client
            keepalive: 30,
            clientId: `admin-front-${__RELEASE__}-${Math.random().toString(16).substr(2, 8)}`,
        },
    },
    podcastsApi: {
        url: 'https://podcast-stage.stream.schibsted.media',
    },
    relatedApi: {
        url: 'https://related-stage.stream.schibsted.media',
    },
    auth,
    videoCompletionApi: {
        url: 'https://connect.vgdata.studio/video_completion_api',
    },
    languages: {
        en_GB: 'English',
        nb_NO: 'Norwegian',
        sv_SE: 'Swedish',
    },
    /**
    some types must be stated explicitly in allowedVideoTypes
    @see https://github.com/react-dropzone/react-dropzone/issues/314
    @see https://github.com/react-dropzone/react-dropzone/issues/734
    */
    allowedVideoTypes: 'video/x-flv,video/mp4,video/x-m4v,video/*,audio/*',
    images: {
        sizes: {
            poster: [
                [124, 70],
                [235, 132],
                [452, 254],
                [613, 345],
                [740, 416],
                [900, 506],
            ],
        },

        snapshots: {
            transformation: 'q100',
            width: 96,
            height: 54,
            max: 800,
            duration: 1000,
        },

        totalThumbnailsSuggestion: 12,

        poster: {
            upload: {
                minimumSize: [300, 250],
            },
            generate: {
                mime: 'image/jpeg',
                quality: 1,
            },
        },
    },

    // mapping from newsroom ids to their respective displayable names
    newsrooms: {
        '100sport': '100sport',
        ab: 'Aftonbladet',
        adressa: 'Adressa',
        adressabrandstudio: 'Adressa Brandstudio',
        altaposten: 'Altaposten',
        ap: 'Aftenposten',
        bt: 'Bergens Tidende',
        brandstudio: 'BrandStudio',
        folkebladet: 'Folkebladet',
        fvn: 'FVN',
        harstad: 'Harstad Tidende',
        itromso: 'iTromso',
        podcast: 'Podcast',
        rbnett: 'rbnett',
        ridenews: 'RideNews',
        riks: 'riks',
        sa: 'Stavanger Aftenblad',
        schibsted: 'Schibsted',
        smp: 'Sunnmorsposten',
        svd: 'Svenska Dagbladet',
        tronderavisa: 'Tronder-Avisa',
        vgtv: 'VGTV',
        vgtvs: 'VGTV Sponset',
        vgtvexternal: 'VGTV external',
        steinkjer: 'Steinkjer24',
        avisast: 'Sør-Trøndelag',
        bladet: 'Bladet',
        banett: 'Brønnøysund Avis',
        innherred: 'Innherred',
        fosnafolket: 'Fosna-Folket',
        opp: 'Opp',
        tronderbladet: 'Trønderbladet',
        stjordalsnytt: 'Stjørdals-nytt',
        mn24: 'MN24',
        hitrafroya: 'Hitra-Frøya',
        framtidinord: 'Framtid i Nord',
        vaganavisa: 'Våganavisa',
        vol: 'VOL.no',
        /**
         * @see https://github.schibsted.io/svp/platform/issues/642
         */
        nyttiuka: 'Aesby',
        vikebladet: 'Vikebladet Vestposten',
        fjt: 'Fjordenes Tidende',
        vestlandsnytt: 'Vestlandsnytt',
        fjuken: 'Fjuken',
        vigga: 'Vigga',
        dolen: 'Dølen',
        fjordabladet: 'Fjordabladet',
        morenytt: 'Møre-Nytt',
        fjordingen: 'Fjordingen',
        driva: 'Driva',
        andalsnes: 'Åndalsnes Avis',
        nyss: 'Nyss',
        agderposten: 'Agderposten',
        lindesnes: 'Lindesnes',
        farsund: 'Lister',
        varden: 'Varden',
        lp: 'Lillesands-Posten',
        venneslatidende: 'Vennesla Tidende',
        gat: 'Grimstad Adressetidende',
        bomlonytt: 'Bømlo-nytt',
        sunnhordland: 'Sunnhordaland',
        tvnu: 'Tv.nu',
        e24: 'E24',
        klaebuposten: 'Klæbuposten',
        gauldalsposten: 'Gauldalsposten',
        froya: 'Frøya',
        bnytt: 'Bygdanytt',
        vnytt: 'Vestnytt',
        stri: 'Strilen',
        avavis: 'Askøyværingen',
        bareasane: 'Bare Åsane',
        tvh: 'TV Haugaland',
        aptts: 'Aftenposten TTS',
        marsteinen: 'Marsteinen',
        op: 'Øyposten',
        r24: 'Randaberg 24',
        stord: 'Stord24',
        wellobe: 'Wellobe',
        godare: 'Godare',
        nearadio: 'NeaRadio',
        podme: 'PodMe',
        fitjarposten: 'Fitjar-Posten',
        kulingen: 'Kulingen',
        kystogfjord: 'Kyst og Fjord',
        porten: 'Porten',
        polaris: 'Polaris Media',
        bttts: 'Bergens Tidende TTS',
        vgtts: 'VG TTS',
    },
    defaultNewsroomConfig: {
        live: {
            assetDefaults: {
                category: 1,
                title: 'Siste nytt',
                description: 'Siste nytt',
            },
            enableLiveVertical: false,
            audioNormalization: {
                enabled: false,
                default: false,
            },
        },
        videoQuality: {
            rules: ['title', 'description'],
            placements: true,
        },
        audioQuality: {
            rules: ['title', 'description', 'tags', 'cuePoints', 'seriesSeason', 'seriesEpisode'],
            placements: true,
        },
        audioNotPodcastQuality: {
            rules: ['title'],
            placements: true,
        },
        language: 'nb_NO',
        logo: {
            url: `${staticPath}/stream-logo.svg`,
        },
        features: {
            engagementChart: {
                enabled: false,
            },
            seriesProperties: {
                enabled: false,
            },
            movieProperties: {
                enabled: false,
            },
        },
        previews: {
            assetMetadataKey: 'preview_360p',
            categoryMetadataKey: '1080_6000_preview_hq',
            defaultArtifacts: 'preview_360p,1080_6000_preview_hq',
            // attribute map used for multi previews
            artifactsMap: undefined as Record<string, string> | undefined,
        },
        contentTypes,
        // a default time difference (in seconds) between encoder start and flight times start of live video
        encoderStartTimeAhead: 20 * MINUTE,
        podcastsOnly: true,
    },
    swedishNewsrooms: ['ab', 'svd', 'ridenews', 'tvnu'],
    newsroomsConfig: {
        // swedish newsrooms
        ab: {
            live: {
                assetDefaults: {
                    category: 4,
                    title: 'Senaste nytt',
                    description: 'Aftonbladet sänder live',
                },
                defaultPaywallAccess: 'plus',
                livePreviewDefault: true,
                enableLiveVertical: true,
            },
            videoQuality: {
                rules: ['title', 'description', 'tags', 'category', 'nextAsset', 'seoTitle'],
                placements: ['assetList', 'publishingAsset'],
            },
            previews: {
                // attribute map used for multi previews
                artifactsMap: {
                    portrait: 'preview_640p',
                    promo: 'preview_540p,preview_1080p',
                },
            },
            liveLogoOverlays: [
                { value: 'https://dd-ab.akamaized.net/ab/aft-1080p.png', label: 'Default AB overlay 1080p' },
                { value: 'https://dd-ab.akamaized.net/ab/aft-720p.png', label: 'Default AB overlay 720p' },
            ],
            language: 'sv_SE',
            logo: {
                url: `${staticPath}/ab-logo.svg`,
            },
            tvSiteAssetUrlPrefix: 'https://tv.aftonbladet.se/video/:assetId/',
        },
        svd: {
            live: {
                assetDefaults: {
                    category: 1,
                    title: 'LIVE: Senaste nytt',
                    description: 'SvD sänder live',
                },
                defaultPaywallAccess: 'plus',
            },
            videoQuality: {
                rules: ['title', 'description', 'tags'],
                placements: true,
            },
            language: 'sv_SE',
            logo: {
                url: `${staticPath}/svd-logo.svg`,
            },
        },
        tvnu: {
            language: 'sv_SE',
            logo: {
                url: `${staticPath}/tvnu-logo.svg`,
            },
        },
        // norwegian newsrooms
        schibsted: {
            live: {
                profile: hlsProfile.HLS1080,
            },
            logo: {
                url: `${staticPath}/schibsted-logo.svg`,
            },
        },
        vgtv: {
            live: {
                assetDefaults: {
                    title: `Siste nytt \u2014 ${momentNorwegian.format('LL')}`,
                },
                defaultPaywallAccess: 'plus',
                profile: hlsProfile.HLS1080,
                livePreviewDefault: true,
                enableLiveVertical: true,
                audioNormalization: {
                    enabled: true,
                    default: true,
                },
            },
            videoQuality: {
                rules: [
                    'title',
                    'description',
                    'tags',
                    'category',
                    'nextAsset',
                    'contentType',
                    'seoTitle',
                    'seoDescription',
                ],
                placements: true,
            },
            liveLogoOverlays: [
                {
                    value: 'https://dd-vgtv.akamaized.net/vgtv/right-horizontal.png',
                    label: 'VG logo - right side',
                },
                {
                    value: 'https://dd-vgtv.akamaized.net/vgtv/left-horizontal.png',
                    label: 'VG logo - left side',
                },
                {
                    value: 'https://dd-vgtv.akamaized.net/vgtv/vgtv.png',
                    label: 'VGTV logo - right side',
                },
                {
                    value: 'https://dd-vgtv.akamaized.net/vgtv/vgtv-left.png',
                    label: 'VGTV logo - left side',
                },
            ],
            logo: {
                url: `${staticPath}/vgtv-logo.svg`,
            },
            tvSiteAssetUrlPrefix: 'https://tv.vg.no/video/:assetId/',
            features: {
                engagementChart: {
                    enabled: true,
                },
                seriesProperties: {
                    enabled: true,
                },
                movieProperties: {
                    enabled: true,
                },
            },
            previews: {
                assetMetadataKey: 'preview_vivi_category',
                defaultArtifacts: 'preview_vivi_category,1080_6000_preview_hq',
                // attribute map used for multi previews
                artifactsMap: {
                    portrait: 'preview_640p',
                    landscape: 'preview_640p',
                    promo: 'preview_540p,preview_1080p',
                },
            },
            contentTypes: {
                ...contentTypes,
                episode: 'Episode',
                clip: 'Clip',
                trailer: 'Trailer',
            },
            encoderStartTimeAhead: MINUTE,
        },
        vgtts: {
            defaultAssetListType: 'audio',
            podcastsOnly: false,
        },
        vgtvexternal: {
            logo: {
                url: `${staticPath}/vgtvexternal-logo.svg`,
            },
        },
        adressa: {
            live: {
                assetDefaults: {
                    category: 27,
                },
            },
            logo: {
                url: `${staticPath}/adressa-logo.svg`,
            },
        },
        altaposten: {
            logo: {
                url: `${staticPath}/altaposten-logo.svg`,
            },
        },
        ap: {
            live: {
                assetDefaults: {
                    category: 10460,
                },
                defaultPaywallAccess: 'plus',
            },
            logo: {
                url: `${staticPath}/ap-logo.svg`,
            },
        },
        aptts: {
            defaultAssetListType: 'audio',
            podcastsOnly: false,
        },
        bt: {
            live: {
                assetDefaults: {
                    category: 22,
                },
            },
            logo: {
                url: `${staticPath}/bt-logo.svg`,
            },
        },
        bttts: {
            defaultAssetListType: 'audio',
            podcastsOnly: false,
        },
        fvn: {
            live: {
                assetDefaults: {
                    category: 22,
                },
                defaultPaywallAccess: 'plus',
            },
            logo: {
                url: `${staticPath}/fvn-logo.svg`,
            },
        },
        sa: {
            live: {
                assetDefaults: {
                    category: 1,
                },
                profile: hlsProfile.HLS1080,
                defaultPaywallAccess: 'plus',
            },
            liveLogoOverlays: [
                {
                    value: 'https://dd-abo.akamaized.net/sa/vod/logo/right-horizontal.png',
                    label: 'SA logo - right side',
                },
            ],
            logo: {
                url: `${staticPath}/sa-logo.svg`,
            },
        },
        adressabrandstudio: {
            logo: {
                url: `${staticPath}/adressabrandstudio-logo.svg`,
            },
        },
        folkebladet: {
            liveLogoOverlays: [
                {
                    value: 'https://dd-polaris.akamaized.net/folkebladet/folkebladet-right.png',
                    label: 'FolkebladetTV LIVE logo - right side - 720p input',
                },
                {
                    value: 'https://dd-polaris.akamaized.net/folkebladet/folkebladet-right-1080p.png',
                    label: 'FolkebladetTV LIVE logo - right side - 1080p input',
                },
                {
                    value: 'https://dd-polaris.akamaized.net/folkebladet/folkebladet-right-vod-1080p.png',
                    label: 'FolkebladetTV VOD logo - horizontal',
                },
                {
                    value: 'https://dd-polaris.akamaized.net/folkebladet/folkebladet-right-vod-vertical-1080p.png',
                    label: 'FolkebladetTV VOD logo - vertical',
                },
            ],
            logo: {
                url: `${staticPath}/folkebladet-logo.svg`,
            },
        },
        itromso: {
            logo: {
                url: `${staticPath}/itromso-logo.svg`,
            },
        },
        smp: {
            logo: {
                url: `${staticPath}/smp-logo.svg`,
            },
        },
        tronderavisa: {
            logo: {
                url: `${staticPath}/tronderavisa-logo.svg`,
            },
        },
        harstad: {
            logo: {
                url: `${staticPath}/harstad-logo.svg`,
            },
        },
        rbnett: {
            logo: {
                url: `${staticPath}/rbnett-logo.svg`,
            },
        },
        ridenews: {
            logo: {
                url: `${staticPath}/ridenews-logo.svg`,
            },
        },
        e24: {
            logo: {
                url: `${staticPath}/e24-logo.svg`,
            },
        },
    },
    asset: {
        additional: [
            'midroll', // this is not a field in SVP API
            'originAssetId',
            'settings',
            'externalId',
            'externalCategoryId',
            'barrels',
            'chapters',
            'featuredChapters',
            'url',
            'cuePoints',
            'imageCaption',
            'nextAsset',
            'subtitles',
            'sourceFiles',
            'tags',
            'stories',
            'access',
            'metadata',
        ],
        ignoredMeta: [
            'affiliateAccess',
            'newsValue',
            'newsLifetime',
            'contentType',
            'ageLimit',
            'mood',
            'silentplay',
            'disableNextVideo',
            'isPodcast',
            'enablePodcastExport',
            'distributeAfter',
            'podcast_episodeType',
            'podcast_guid',
            'chunkDuration',
            'richDescription',
            'liveDuration',
            'eventStartTime',
            'studioStartTime',
            'notificationSent',
            'livingThumbnail',
            'hasSubtitlesInHls',
            'seoTitle',
            'seoDescription',
            'bylineName',
            'bylineEmail',
            'titleShortPath',
            'tts_disabled',
            'authors',
            'cast',
            'director',
        ],
        registerRequiredFields: [
            'distributeAfter',
            'notificationSent',
            'liveDuration',
            'chunkDuration',
            'tts_disabled',
        ],
        defaultCategory: {
            ab: 1016,
            adressa: 27,
            ap: 10460,
            bt: 22,
            fvn: 181,
            vgtv: 100171,
        },
        hiddenCategory: {
            vgtv: 100171,
            ab: 1016,
            vgtvexternal: 1,
        },
        nextVideo: {
            pagination: 5,
        },
        // mapping from asset field values to their respective displayable names
        labels: {
            assetType: {
                video: 'video',
                audio: 'audio',
            },
            streamType: {
                live: 'live',
                wasLive: 'was live',
                vod: 'VoD',
            },
            status: {
                draft: 'incoming',
                readyToPublish: 'ready to publish',
                active: 'published',
                inactive: 'unpublished',
            },
        },
        mood: {
            iconSet: 'facebook',
            values: {
                neutral: {
                    label: 'Neutral',
                    icon: 'neutral_face',
                },
                funny: {
                    label: 'Haha',
                    icon: 'laughing',
                },
                love: {
                    label: 'Aww..',
                    icon: 'heart_eyes',
                },
                wow: {
                    label: 'Wow',
                    icon: 'hushed',
                },
                sad: {
                    label: 'Sad',
                    icon: 'disappointed_relieved',
                },
                angry: {
                    label: 'Angry',
                    icon: 'angry',
                },
            },
        },
    },

    assets: {
        pagination: 30,
        // mapping from asset property paths to their respective displayable names
        properties: {
            id: 'ID',
            assetType: 'asset type',
            streamType: 'type',
            'images.main': 'thumbnail',
            title: 'title',
            'additional.tags': 'tags',
            status: 'status',
            displays: 'views',
            'category.title': 'category',
            published: 'published',
            provider: 'newsroom',
        },
        // list of always visible columns, no matter the user settings
        columns: ['title'],
        // list of asset property paths that are configurable through options
        columnOptions: {
            preview: 'preview',
            tags: 'tags',
            category: 'category',
            creationDate: 'published',
            author: 'created by',
        },
    },

    tags: {
        limit: 100,
    },

    stories: {
        limit: 100,
    },

    live: {
        adsDuration: [15, 30, 60], // in seconds
        notifications: {
            unsupportedCodes: {
                error: [2999],
            },
            ASSET_VIEW_DISPLAY_LIMIT: 10,
            PERSONAL_PANEL_DISPLAY_LIMIT: 5,
        },
        rtmp: {
            url: 'rtmp://rtmp-stage.stream.schibsted.media/live',
        },
    },

    // period of time (in seconds) which determines that live video is running too long
    longRunningLiveThreshold: DAY,

    // period of time (in milliseconds) after which check for admin update is done
    adminUpdateChecksThreshold: 5 * MINUTE * 1000,

    categories: {
        ignoredMetadata: [
            'topic',
            'podcast_category',
            'podcast_categoryTree',
            'podcast_author',
            'podcast_link',
            'podcast_ownerEmail',
            'podcast_acast_showId',
            'podcast_acast_prependUrl',
            'podcast_type',
            'podcast_language',
            'podcast_ownerName',
            'podcast_explicit',
            'podcast_copyright',
            'podcast_sponsAdsLimits',
            'podcast_airtimeAdsLimits',
            'podcast_richDescription',
            'series_cast',
            'series_director',
            'series_releasedAt',
            'series_isSponsored',
        ],
    },

    player: {
        src: 'https://cdn.stream.schibsted.media/player/beta/player.next.min.js',
        options: {
            api: 'https://svp-stage.vg.no/svp/api/v1/',
            tokenUrl: 'https://svp-stage.vg.no/svp/token/v1/',
            thumbnailsUrl: 'https://svp-stage.vg.no/svp/thumbnails/v1/',
            locale: 'en',
            settings: {
                preview: true,
                na: true,
                jw: { preload: 'none' },
            },
            sharing: false,
            recommended: false,
            autoplay: false,
            ttsSkin: false,
        },
    },

    embed: {
        scriptUrl: 'https://stage-cdn.stream.schibsted.media/embed/latest/js/embed.min.js',
        iframeUrl: 'https://stage-cdn.stream.schibsted.media/embed/latest/iframe.html',
    },

    aws: {
        key: 'MMRF3C9C2LLNQI5P8TB6',
        region: 'no-east1',
        bucket: 'svp-ingest-stage',
    },

    evaporate: {
        cloudfront: true,
        logging: false,
        computeContentMd5: false,
        awsSignatureVersion: 2,
        maxConcurrentParts: 10,
        partSize: 20 * 1024 * 1024,
        progressIntervalMS: 1000,
    },
    jwDashboard: 'https://dashboard.jwplayer.com/#/content/detail',
    announcekitChangelog: 'https://announcekit.co/schibsted/spp-product-updates/label/53671',
    aspectRatios: [
        { value: '1.78', label: '16/9' },
        { value: '1', label: '1/1' },
        { value: '0.56', label: '9/16' },
    ],
    sortTypes: [
        { value: 'created', label: 'Creation date' },
        { value: 'published', label: 'Publication date' },
        { value: 'relevance', label: 'Most relevant' },
    ],
    ga: {
        trackingCode: 'UA-129902176-2',
    },
    assetQuality,
    filters: {
        min: {
            label: 'duration',
            operator: '>=',
        },
        max: {
            label: 'duration',
            operator: '<=',
        },
        excludedCategory: {
            label: 'categoryId',
            operator: '<>',
        },
        excludedTags: {
            label: 'additional.tags.id',
            operator: '<>',
        },
    },
    playlists: {
        order: 'created',
        labels: [
            {
                value: 'promoted-shows',
                label: 'Promoted shows',
                style: { color: '#646464', backgroundColor: '#dee7ee' },
            },
            {
                value: 'editorial-episodes',
                label: 'Editorial episodes',
                style: { color: '#fff', backgroundColor: '#a59bd0' },
            },
            {
                value: 'single-show',
                label: 'Single show',
                style: { color: '#fff', backgroundColor: '#c2c1df' },
            },
        ],
    },
    podcasts: {
        episodeTypes: [
            { value: 'full', label: 'Full (default)', default: true },
            { value: 'trailer', label: 'Trailer', default: false },
            { value: 'bonus', label: 'Bonus', default: false },
        ],
        categoryId: {
            e24: 100262,
        },
        pushEnabledFor: ['ab', 'ap', 'bt', 'e24', 'sa', 'svd', 'vgtv'], // newsrooms list
    },
    podcastExperimentsSettings: {
        open: {
            name: 'Open',
            metadata: {},
        },
        '100-behind-paywall': {
            name: '100% behind paywall',
            metadata: {
                experimentId: null,
                variants: '1',
                '1variantType': 'Subscription',
                '1variantPercentage': '100',
                '1variantOffer': 'False',
            },
        },
        '100-behind-loginwall': {
            name: '100% behind loginwall',
            metadata: {
                experimentId: null,
                variants: '1',
                '1variantType': 'Login',
                '1variantPercentage': '100',
                '1variantOffer': 'False',
            },
        },
        '50-paywall-50-loginwall': {
            name: '50% paywall, 50% loginwall',
            metadata: {
                experimentId: null,
                variants: '1,2',
                '1variantType': 'Subscription',
                '1variantPercentage': '50',
                '1variantOffer': 'False',
                '2variantType': 'Login',
                '2variantPercentage': '50',
                '2variantOffer': 'False',
            },
        },
        '50-paywall-50-open': {
            name: '50% paywall, 50% open',
            metadata: {
                experimentId: null,
                variants: '1',
                '1variantType': 'Subscription',
                '1variantPercentage': '50',
                '1variantOffer': 'False',
            },
        },
        '50-loginwall-50-open': {
            name: '50% loginwall, 50% open',
            metadata: {
                experimentId: null,
                variants: '1',
                '1variantType': 'Login',
                '1variantPercentage': '50',
                '1variantOffer': 'False',
            },
        },
    },
    sentry: {
        dsn: 'https://98f0eff534f241d4bc73067568c92a85@sentry.k8s.vgnett.no/9',
        environment: 'stage',
        beforeSend: (event) => {
            const errorMessagesFilter = ['status:401', 'status:403', 'status:404', 'Failed to fetch'];
            const extraErrorMessage = event.extra?.error?.message;

            if (errorMessagesFilter.some((errorMessageFilter) => extraErrorMessage?.includes(errorMessageFilter))) {
                return null;
            }
            return event;
        },
    },
    schibstedDocsUrl: 'https://docs.schibsted.media',
} as const;

export default config;
