import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';

export default function ReactIconsDefaultsProvider({ children, className }) {
    const value = useMemo(() => ({ className }), [className]);
    return <IconContext.Provider value={value}>{children}</IconContext.Provider>;
}

ReactIconsDefaultsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
};
