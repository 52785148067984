import { merge } from 'lib/object';
import {
    SERIES_FETCH,
    SERIES_FETCH_SUCCESS,
    SERIES_FETCH_ERROR,
    SERIES_ADD_SUCCESS,
    SERIES_DELETE_SUCCESS,
    SERIES_UPDATE_SUCCESS,
} from './actionTypes';

const status = {
    loading: 'loading',
    error: 'error',
    success: 'success',
};

/**
 *
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const seriesReducer = (state = {}, action) => {
    switch (action.type) {
        case SERIES_FETCH: {
            const { provider, categoryId } = action;
            return merge(state, {
                [provider]: {
                    [categoryId]: {
                        status: status.loading,
                    },
                },
            });
        }

        case SERIES_FETCH_SUCCESS: {
            const { provider, categoryId, series: items } = action;
            return merge(state, {
                [provider]: {
                    [categoryId]: {
                        status: status.success,
                        items,
                    },
                },
            });
        }

        case SERIES_FETCH_ERROR: {
            const { provider, categoryId, error } = action;
            return merge(state, {
                [provider]: {
                    [categoryId]: {
                        status: status.error,
                        error,
                    },
                },
            });
        }

        case SERIES_DELETE_SUCCESS: {
            const { provider, categoryId, seasonNumber } = action;
            return {
                ...state,
                [provider]: {
                    ...state[provider],
                    [categoryId]: {
                        items: state[provider][categoryId].items.filter(
                            (season) => season.seasonNumber !== seasonNumber
                        ),
                    },
                },
            };
        }

        case SERIES_ADD_SUCCESS: {
            const { provider, categoryId, series } = action;

            const items = [...state[provider][categoryId].items, series].sort(
                (a, b) => a.seasonNumber - b.seasonNumber
            );

            return merge(state, {
                [provider]: {
                    [categoryId]: {
                        items,
                    },
                },
            });
        }

        case SERIES_UPDATE_SUCCESS: {
            const { provider, categoryId, series } = action;

            const items = [
                ...state[provider][categoryId].items.filter((item) => item.seasonNumber !== series.seasonNumber),
                series,
            ].sort((a, b) => a.seasonNumber - b.seasonNumber);

            return merge(state, {
                [provider]: {
                    [categoryId]: {
                        items,
                    },
                },
            });
        }

        default:
            return state;
    }
};

export default seriesReducer;
