import { get } from 'lodash';

/**
 * @typedef {Object} Category
 * @property {Object} additional
 * @property {Number} ageLimit
 * @property {Number} id
 * @property {Boolean} isSeries
 * @property {Object} lastAsset
 * @property {Number} order
 * @property {Number} parentId
 * @property {Boolean} showCategory
 * @property {String} stats
 * @property {String} title
 */

/**
 * @param {Object} params
 * @param {String} params.provider
 * @return {Promise<Array<Category>>}
 */
export async function fetchCategories({ provider }) {
    const result = await this.get('v1/svp/{PROVIDER}/categories', { provider, query: { additional: true } });
    return get(result, '_embedded.categories', []);
}

export async function fetchCategory({ provider, categoryId }) {
    return this.get(`v1/svp/{PROVIDER}/categories/${categoryId}`, {
        provider,
        query: { additional: true },
    });
}

export async function saveCategory({ provider, payload }) {
    if (payload.id) {
        return this.put(`v1/svp/{PROVIDER}/categories/${payload.id}`, { provider, json: payload });
    }

    return this.post(`v1/svp/{PROVIDER}/categories`, { provider, json: payload });
}
