import * as Actions from './actionTypes';

export const fetchTags = ({ ids, provider }) => ({
    type: Actions.TAGS_FETCH,
    ids,
    provider,
});

export const fetchTagsSuccess = ({ ids, provider, tags }) => ({
    type: Actions.TAGS_FETCH_SUCCESS,
    ids,
    provider,
    tags,
});

export const fetchTagsError = ({ ids, provider, error }) => ({
    type: Actions.TAGS_FETCH_ERROR,
    ids,
    provider,
    error,
});
