import type { UseFormRegister } from 'react-hook-form';
import { Checkbox } from '@schibsted-svp/react-ui';
import type { ReactNode } from 'react';
import { FormValues } from '../types';
import css from './TTSControls.module.scss';

type TTSControlsProps = {
    register: UseFormRegister<FormValues>;
    children: ReactNode;
};

export function TTSControls({ register, children }: TTSControlsProps) {
    return (
        <section className={css.container}>
            <div className={css.form}>
                <h1>Text To Speech</h1>
                <Checkbox
                    {...register('additional.metadata.tts_disabled')}
                    label="Lock the current version from further article updates"
                />
            </div>
            <div className={css.submitButton}>{children}</div>
        </section>
    );
}
