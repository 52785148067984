import { useState, useMemo } from 'react';
import { FaSearch } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { Button, LabeledContainer, Spinner } from '@schibsted-svp/react-ui';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import type { UseFieldArrayReturn, UseFormGetValues, FormState } from 'react-hook-form';
import StaticItemsSearchDialog from './StaticItemsSearchDialog';
import { RegularPlaylistItem } from './RegularPlaylistItem';
import { FormValues, PlaylistItemAsset, PlaylistItemPlaylist } from './types';

import css from './PlaylistsFormItems.module.scss';
import { PlaylistOfPlaylistItem } from './PlaylistOfPlaylistItem';

interface PlaylistsFormItemsProps {
    fieldArrayApi: UseFieldArrayReturn<FormValues, 'items', 'id'>;
    provider: string;
    getValues: UseFormGetValues<FormValues>;
    isRegularPlaylist: boolean;
    isPristine: boolean;
    isPlaylistFetching: boolean;
    disabledSaving: boolean;
    formState: FormState<FormValues>;
    isSearching: boolean;
}

export function PlaylistsFormItems({
    fieldArrayApi,
    provider,
    getValues,
    isRegularPlaylist,
    isPristine,
    isPlaylistFetching,
    disabledSaving,
    formState,
    isSearching,
}: PlaylistsFormItemsProps) {
    const { fields, insert, remove } = fieldArrayApi;
    const [isFindModalShown, setIsFindModalShown] = useState(false);
    const { isSubmitting } = formState;

    const [itemPosition, setItemPosition] = useState<number>();

    const { searchQuery } = getValues();

    const shouldDisplayAssets = useMemo(
        () =>
            searchQuery
                ? fields.length !==
                  fields.filter((_, index) => {
                      const { isStatic } = fields[index];
                      return isStatic;
                  }).length
                : true,
        [fields, searchQuery]
    );

    const isNoResults = (!fields.length && searchQuery) || (!isSubmitting && !isPristine);

    const openAddItemModal = (index: number) => {
        setItemPosition(index);
        setIsFindModalShown(true);
    };

    if (isPlaylistFetching || isSearching) {
        return (
            <ul className={css.list}>
                <Spinner containerClassName={css.spinner} />
            </ul>
        );
    }

    if (isNoResults) {
        return (
            <ul className={css.list}>
                <div className={css.noResults}>
                    <FaSearch size={60} opacity={0.15} />
                    <br />
                    No results matching search criteria
                </div>
                <LabeledContainer className={css.saveButton}>
                    <Button type="submit" loading={isSubmitting} disabled={disabledSaving}>
                        Save
                    </Button>
                </LabeledContainer>
            </ul>
        );
    }

    if (!fields.length) {
        return (
            <ul className={css.list}>
                <div className={css.addVideo} data-testid="addNewVideoView">
                    <Button
                        size="small"
                        className={css.addButton}
                        iconOnly
                        type="button"
                        onClick={() => openAddItemModal(0)}
                    >
                        <MdAdd size={25} />
                    </Button>
                </div>
                <LabeledContainer className={css.saveButton}>
                    <Button type="submit" loading={isSubmitting} disabled={disabledSaving}>
                        Save
                    </Button>
                </LabeledContainer>
                <StaticItemsSearchDialog
                    provider={provider}
                    isOpen={isFindModalShown}
                    setIsOpen={setIsFindModalShown}
                    isRegularPlaylist={isRegularPlaylist}
                    staticItemPosition={itemPosition}
                    getValues={getValues}
                    insertItem={insert}
                />
            </ul>
        );
    }

    if (shouldDisplayAssets) {
        return (
            <ul className={css.list}>
                <TransitionGroup>
                    {fields.map((field, index) => {
                        const { position } = field;
                        const isLastItem = fields.length === index + 1;

                        return (
                            <CSSTransition key={`${provider}-${field.id}-${position}`} classNames={css} timeout={800}>
                                <li>
                                    {isRegularPlaylist ? (
                                        <RegularPlaylistItem
                                            provider={provider}
                                            index={index}
                                            field={field as PlaylistItemAsset}
                                            openAddStaticVideoModal={openAddItemModal}
                                            remove={remove}
                                            isLastItem={isLastItem}
                                        />
                                    ) : (
                                        <PlaylistOfPlaylistItem
                                            index={index}
                                            field={field as PlaylistItemPlaylist}
                                            openAddItemModal={openAddItemModal}
                                            remove={remove}
                                            isLastItem={isLastItem}
                                        />
                                    )}
                                </li>
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
                <LabeledContainer className={css.saveButton}>
                    <Button type="submit" loading={isSubmitting} disabled={disabledSaving}>
                        Save
                    </Button>
                </LabeledContainer>
                <StaticItemsSearchDialog
                    provider={provider}
                    isOpen={isFindModalShown}
                    setIsOpen={setIsFindModalShown}
                    staticItemPosition={itemPosition}
                    isRegularPlaylist={isRegularPlaylist}
                    getValues={getValues}
                    insertItem={insert}
                />
            </ul>
        );
    }
    return null;
}
