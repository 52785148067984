import classnames from 'classnames/bind';
import { Spinner } from '@schibsted-svp/react-ui';
import Tooltip from 'components/ui/Tooltip';
import { useEncoderInputPreview } from 'hooks/useEncoderInputPreview';

import css from './EncoderPreview.module.scss';

const cln = classnames.bind(css);

interface EncoderPreviewProps {
    className?: string;
    encoderId?: string;
    inputId?: number;
}

export function EncoderPreview({ encoderId, inputId, className }: EncoderPreviewProps) {
    const { isLoading, url } = useEncoderInputPreview(encoderId, inputId);
    return (
        <div className={cln('container', className)}>
            {isLoading && <Spinner size="small" containerClassName={css.spinner} />}

            {!isLoading && url && (
                <Tooltip
                    theme="dark"
                    placement="top"
                    content={<img alt="Encoder input preview tooltip" src={url} className={css.image} />}
                >
                    <img alt="Encoder input preview" src={url} className={css.image} />
                </Tooltip>
            )}
        </div>
    );
}
