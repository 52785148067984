import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import {
    getStreamConfiguration,
    isLive,
    isVod,
    isScheduled,
    isElementalEncoder,
    isRtmpEncoder,
    isMediaLiveEncoder,
    isEmptyEncoder,
} from 'models/asset';
import { isAssetStreamConfigurationAvailable } from 'store/stream-configurations/utils';
import { findLiveEncoderEvents } from 'store/live/selectors';
import { allEventsHaveError } from 'models/multiLiveEvent';
import Placeholder from 'components/core/Placeholder';
import { useUrlChecker } from 'components/core/Player/UrlCheckerContext';
import Player from './Player';
import css from './PlayerUrlChecker.module.scss';

const cln = classnames.bind(css);

function PlayerUrlChecker({ asset, highlighted, ...props }) {
    const isLiveAsset = isLive(asset);

    const isEncryptedAsset = Boolean(getStreamConfiguration(asset).filter((v) => v !== 'geoblocked').length);
    const isScheduledAsset = isScheduled(asset);
    const isSupportedEncoder = isElementalEncoder(asset) || isRtmpEncoder(asset) || isMediaLiveEncoder(asset);
    const liveEvents = useSelector((state) =>
        findLiveEncoderEvents(state, { provider: asset.provider, assetId: asset.id })
    );
    const isStartingLiveEventFailed = allEventsHaveError(liveEvents);

    const { available, idleLive } = useUrlChecker();

    if (isLiveAsset && idleLive && !available) {
        return <Placeholder label="Encoder not started" />;
    }

    if (isLiveAsset && isEmptyEncoder(asset)) {
        return <Placeholder label="Please set up the source" />;
    }

    if (isLiveAsset && isStartingLiveEventFailed) {
        return <Placeholder label="No preview due to error" />;
    }

    if (isLiveAsset && !available && isSupportedEncoder && !isEncryptedAsset && !isScheduledAsset) {
        return (
            <Placeholder
                label={
                    isRtmpEncoder(asset)
                        ? 'Waiting for signal from your web camera...'
                        : 'The live video is starting...'
                }
                spinner
            />
        );
    }

    if (isVod(asset) && !isAssetStreamConfigurationAvailable(asset)) {
        return (
            <img
                className={cln('poster', asset.assetType)}
                src={`${asset.images.main}?t[]=x452q80`}
                alt={asset.title}
            />
        );
    }

    const svpPlayerOptions = {
        disableCountdownPlugin: isLiveAsset,
    };

    return (
        <div className={cln({ highlighted })}>
            <Player
                asset={asset}
                {...props}
                play={isLiveAsset}
                mute={isLiveAsset}
                autoplay={isLiveAsset}
                options={svpPlayerOptions}
            />
        </div>
    );
}

PlayerUrlChecker.propTypes = {
    asset: PropTypes.object.isRequired,
    highlighted: PropTypes.bool,
};

PlayerUrlChecker.defaultProps = {
    highlighted: false,
};

export default memo(PlayerUrlChecker);
