import config from 'config';
import { makeStreamTypeLabel, getIngestState, isEncrypted } from 'models/asset';

import { getAsset } from 'store/assets/selectors';
import { getSecuredCutting } from 'store/video/cutting/selectors';

export const navigationTabExists = ({ id, provider }, navigationTabs) =>
    navigationTabs.some((entry) => Number(id) === entry.id && provider === entry.provider);

export const getNavigationTabs = (state) =>
    state.video.navigationTabs.map(({ id, provider }) => {
        const asset = getAsset(state, { provider, id });
        return (
            asset && {
                id,
                provider,
                title: asset.title || '',
                type: makeStreamTypeLabel(asset, config.asset.labels) || '',
            }
        );
    });

export const getActiveNavigationTab = (state) => state.video.navigationTabs.find((tab) => tab.active);
export const getSearchTerm = (state) => state.ui.search.query || '';

export const getVideoList = (state) => {
    const list = state.assetList.main;

    return {
        loading: list.isFetching,
        number: list.items.length,
        hasMore: !!list.nextUrl,
    };
};

export const getSearchList = (state) => {
    const list = state.assetList.linked;
    return {
        loading: list.isFetching,
        number: list.items.length,
        hasMore: !!list.nextUrl,
    };
};

export const getUpload = (state, { provider, id }) => {
    const asset = getAsset(state, { provider, id });

    // creating clip from encrypted asset is stored in the same key as uploading/transcoding, skipping it
    if (isEncrypted(asset)) {
        return {};
    }
    return state.video.upload?.[provider]?.[id]?.video || {};
};

export const getConversion = (state, { provider, id }) => state.video.conversion?.[provider]?.[id]?.video || {};

export const getProgressInfo = (state, { provider, id }) =>
    getIngestState({
        upload: getUpload(state, { provider, id }),
        trimming: getSecuredCutting(state, { provider, id }),
        conversion: getConversion(state, { provider, id }),
    });
