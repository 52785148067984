import { Controller, Control } from 'react-hook-form';
import { Select } from '@schibsted-svp/react-ui';
import { findSingleOption } from 'components/ui/Form/Select';
import { getContentTypeOptions } from '../helpers/ContentTypeHelpers';
import type { FormValues } from '../types';

type ContentTypeSelectProps = {
    provider: string;
    control: Control<FormValues>;
};

export function ContentTypeSelect({ provider, control }: ContentTypeSelectProps) {
    const options = getContentTypeOptions(provider);

    return (
        <Controller
            name="additional.metadata.contentType"
            control={control}
            render={({ field }) => (
                <Select
                    {...field}
                    value={findSingleOption(options)(field.value)}
                    onChange={(value) => field.onChange(value ? value.value : null)}
                    options={options}
                    isClearable
                    placeholder="Not specified"
                    splitButton={false}
                    size="small"
                />
            )}
        />
    );
}
