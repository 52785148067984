import * as z from 'zod';
import { useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Notification } from '@schibsted-svp/react-ui';
import {
    useCreateUserMutation,
    useUpdateUserMutation,
    CreateUserMutation,
    UpdateUserMutation,
} from 'services/admin-bff-sdk/generated';
import type { UserData, FormMode } from './types';

const userFormSchema = z
    .object({
        email: z.string().email(),
    })
    .passthrough();

export const useUserForm = () => {
    const formApi = useForm<UserData>({ resolver: zodResolver(userFormSchema) });
    return { formApi };
};

export const useUserFormContext = () => {
    const formApi = useFormContext<UserData>();
    return { formApi };
};

export const useUserFormSubmit = ({ mode, onSuccess = () => {} }: { mode: FormMode; onSuccess?: () => void }) => {
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();

    const {
        formApi: { handleSubmit },
    } = useUserFormContext();

    return useMemo(
        () =>
            handleSubmit(async (data) => {
                const { newsrooms, ...rest } = data;

                const roles = newsrooms?.map((newsroom) => ({ newsroom, roleType: 'user' })) || [];
                const created = new Date().toISOString();

                try {
                    let result: CreateUserMutation['createUser'] | UpdateUserMutation['updateUser'];

                    if (mode === 'edit') {
                        const response = await updateUser({ ...rest, roles }).unwrap();
                        result = response.updateUser;
                    } else {
                        const response = await createUser({ ...rest, roles, created }).unwrap();
                        result = response.createUser;
                    }

                    if ('message' in result) {
                        throw new Error(result.message);
                    }

                    Notification.notify.success(`User ${mode === 'edit' ? 'updated' : 'saved'} successfully`);
                    onSuccess();
                } catch (error) {
                    Notification.notify.error(
                        error.message || `Failed to ${mode === 'edit' ? 'update' : 'save'} new user`
                    );
                }
            }),
        [createUser, updateUser, handleSubmit, mode, onSuccess]
    );
};
