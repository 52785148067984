import type { Asset } from '@schibsted-svp/svp-api-types';
import { useEffect } from 'react';
import { getAccess } from 'models/asset';
import { useSignAssetStreamUrlsMutation } from 'services/admin-bff-sdk/generated';

export function useSignAssetStreamUrls(asset: Asset): { asset: Asset; isLoading: boolean } {
    const [signAssetStreamUrls, { data, isLoading, isUninitialized }] = useSignAssetStreamUrlsMutation();
    const hasAccess = getAccess(asset).length > 0;

    useEffect(() => {
        if (hasAccess) {
            signAssetStreamUrls({
                provider: asset.provider,
                assetId: asset.id,
                expirationTime: 3600 * 2,
            });
        }
    }, [asset.provider, asset.id, asset.streamUrls.hls, signAssetStreamUrls, hasAccess]);

    if (!hasAccess) {
        return {
            asset,
            isLoading: false,
        };
    }

    const signedAsset = data
        ? {
              ...asset,
              streamUrls: {
                  ...asset.streamUrls,
                  hls: data?.signAssetStreamUrls.find(({ format }) => format === 'HLS')?.url || asset.streamUrls.hls,
              },
          }
        : undefined;

    return {
        asset: signedAsset,
        isLoading: isLoading || isUninitialized,
    };
}
