import { isNumeric } from 'lib/number';

export const isTitleValid = (value) =>
    typeof value === 'string' && value.trim().length > 2
        ? undefined
        : 'Season name should be at least 3 characters long';

export const isSeasonNumberValid = (value) =>
    isNumeric(value) && value > 0 ? undefined : 'Season number is required and should be greater than 0';

export const isSeasonNumberUnique = (value, { seasons }) => {
    const isDuplicated = seasons.filter(({ seasonNumber }) => +value === +seasonNumber).length > 1;
    return isDuplicated ? 'This season number is duplicated' : undefined;
};

export const findDuplicatedSeasonNumberIndex = (value, seasons) => {
    const duplicates = seasons.filter(({ seasonNumber }) => Number(value) === Number(seasonNumber));

    if (duplicates.length > 1) {
        const lastDuplicate = duplicates.at(-1);
        return seasons.findIndex(({ title }) => lastDuplicate.title === title);
    }
    return -1;
};
