import type { Asset } from '@schibsted-svp/svp-api-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MdAccessTime } from 'react-icons/md';

import { formatRemainingTimeAsUnits } from 'lib/time';
import { getPreviewUrl } from 'models/asset';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import { isAssetPreviewPending } from 'store/uploads/previews/selectors';
import { HTML5Player } from 'components/core/Player/HTML5Player';

import { CustomPreviewDialog } from './CustomPreviewDialog';
import css from './CustomPreview.module.scss';

interface CustomPreviewProps {
    asset: Asset;
}

export function CustomPreview({ asset }: CustomPreviewProps) {
    const previewUrl = getPreviewUrl(asset);
    const [duration, setDuration] = useState<number>(0);
    const isGeneratingPreview = useSelector((state) =>
        isAssetPreviewPending(state, {
            assetId: asset.id,
            provider: asset.provider,
        })
    );
    const userPermissions = useUserPermissions();

    return (
        <div className={css.video}>
            <HTML5Player src={previewUrl} loading={isGeneratingPreview} onDurationChange={setDuration} width={250} />
            <div className={css.details}>
                <div className={css.caption}>Edit preview</div>
                <div className={css.description}>
                    {duration ? (
                        <span>
                            Current preview duration:
                            <span className={css.duration}>
                                <MdAccessTime className={css.durationIcon} /> {formatRemainingTimeAsUnits(duration)}
                            </span>
                        </span>
                    ) : (
                        <span> No preview uploaded yet </span>
                    )}
                </div>
                <div className={css.buttons}>
                    <CustomPreviewDialog
                        asset={asset}
                        loading={isGeneratingPreview}
                        disabled={!userPermissions?.isUser}
                    />
                </div>
            </div>
        </div>
    );
}
