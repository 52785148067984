import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { fetchWasLiveAssets, fetchLiveEncoders, fetchLiveAssets } from 'store/live/actions';
import { withNewsroom } from 'components/NewsroomsList/with-newsroom';
import { get } from 'lodash';
import {
    getSplittedAssetsByStatus,
    getWasLiveAssetsList,
    getUserRunningLiveAssets,
    getIsFetchingFlag,
    getErrors,
    getCurrentAndUpcomingLiveAssets,
} from 'store/live/selectors';

import LiveScreen from './LiveScreen';

export default compose(
    withNewsroom,
    connect(
        (state) => {
            const userEmail = get(state, 'auth.token.sub');

            return {
                isFetching: getIsFetchingFlag(state),
                ...getSplittedAssetsByStatus(state),
                ...getCurrentAndUpcomingLiveAssets(state),
                wasLiveAssets: getWasLiveAssetsList(state),
                personalLiveAssets: getUserRunningLiveAssets(state, userEmail),
                ...getErrors(state),
            };
        },

        {
            fetchLiveAssets,
            fetchWasLiveAssets,
            fetchLiveEncoders,
        }
    )
)(LiveScreen);
