import { useState } from 'react';
import { useSearchTagsQuery } from 'services/admin-bff-sdk';
import { useDebounce } from 'hooks/useDebounce';

export function useTagsSearch({ provider, delay }: { provider: string; delay: number }) {
    const [term, onTagsSearch] = useState('');
    const debouncedTerm = useDebounce(term, delay);

    const { data, ...rest } = useSearchTagsQuery(
        { provider, query: debouncedTerm },
        { skip: debouncedTerm.length < 2 }
    );

    return {
        ...rest,
        onTagsSearch,
        searchResults: data?.searchTags,
        term,
    };
}
