import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, type UseFormHandleSubmit } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Notification } from '@schibsted-svp/react-ui';
import { reportMessageToSentry } from 'lib/error';
import { useModifyCategoryPodmeMetadataMutation } from 'services/admin-bff-sdk/generated';
import { getCategory } from 'store/categories/selectors';
import { categorySubmission } from 'store/categories/actions';
import { prepareCategoryData, preparePodmeMetadata } from '../helpers/submit';
import { categoryFormSchema } from '../validators';
import type { CategoryData } from '../types';

export const useCategoryForm = (values: CategoryData) => {
    const formApi = useForm<CategoryData>({
        mode: 'all',
        values,
        resolver: zodResolver(categoryFormSchema),
    });

    return { formApi };
};

export const useCategoryFormSubmit = ({
    handleSubmit,
    initialValues,
    categoryId,
    provider,
    mode,
    isCreationMode,
}: {
    handleSubmit: UseFormHandleSubmit<CategoryData>;
    initialValues: CategoryData;
    categoryId: number;
    provider: string;
    mode: string;
    isCreationMode: boolean;
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const category = useSelector((state) => getCategory(state, { provider, id: categoryId }));
    const [modifyPodmeMetadata] = useModifyCategoryPodmeMetadataMutation();

    return useCallback(
        (event) =>
            handleSubmit(async (values) => {
                try {
                    // NOTE: modifying Podme metadata has to be done before category update
                    if (!isCreationMode && categoryId) {
                        const podmeMetadata = preparePodmeMetadata(values, initialValues);
                        if (podmeMetadata) {
                            await modifyPodmeMetadata({ provider, categoryId, metadata: podmeMetadata });
                        }
                    }

                    const preparedValues = prepareCategoryData(values, category);
                    const { category: responseCategory } = await dispatch(categorySubmission(provider, preparedValues));

                    if (mode === 'new') {
                        Notification.notify.success('Successfully saved new category');
                        history.push(`/${provider}/category/${responseCategory.id}`);
                    } else {
                        Notification.notify.success('Category updated successfully');
                    }
                } catch (error) {
                    const message = 'There was an error during category submission';
                    reportMessageToSentry({
                        message,
                        extras: {
                            error,
                        },
                    });
                    Notification.notify.error(message);
                }
            })(event),

        [
            handleSubmit,
            dispatch,
            history,
            mode,
            provider,
            initialValues,
            isCreationMode,
            categoryId,
            category,
            modifyPodmeMetadata,
        ]
    );
};
