import NewsroomsList from 'components/NewsroomsList';
import { bemgen } from 'lib/bem';

import './NewsroomSelectScreen.scss';

export function NewsroomSelectScreen() {
    return (
        <section className={bemgen('NewsroomSelectScreen')}>
            <header className={bemgen('NewsroomSelectScreen__header')}>Select newsroom</header>
            <NewsroomsList />
        </section>
    );
}
