import PropTypes from 'prop-types';
import { Button } from '@schibsted-svp/react-ui';

import * as ReactGA from 'react-ga';

import css from './DownloadControls.module.scss';

function DownloadControls({ id, provider, url, buttonOnly, disabled }) {
    const downloadVideo = () => {
        ReactGA.event({
            category: 'Video',
            action: 'Download',
            label: `${provider}.${id}`,
        });
        window.open(`${url}?download=true`);
    };

    if (buttonOnly) {
        return (
            <Button onClick={downloadVideo} variant="standard" size="small" type="button" disabled={disabled}>
                Download
            </Button>
        );
    }

    return (
        <div className={css.ready}>
            <h1 className={css.message}>This video is ready to download</h1>
            <Button onClick={downloadVideo} variant="standard" size="small" type="button" disabled={disabled}>
                Download
            </Button>
        </div>
    );
}

DownloadControls.propTypes = {
    id: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    url: PropTypes.string,
    buttonOnly: PropTypes.bool,
    disabled: PropTypes.bool,
};

DownloadControls.defaultProps = {
    url: undefined,
    buttonOnly: false,
    disabled: false,
};

export default DownloadControls;
