import { getLinkedProviders } from 'store/providers/selectors';
import { getPlaylistFilters } from 'store/playlists/selectors';
import config from 'config';

export const selectNewsroom = ({ newsroom }) => newsroom;

export const getNewsroomOptions = (state) => {
    const newsrooms = getLinkedProviders(state);
    const newsroomsOptions = newsrooms.map((value) => ({ value, label: config.newsrooms[value] }));
    const currentNewsroom = selectNewsroom(state);
    const currentNewsroomOption = [
        {
            value: currentNewsroom,
            label: config.newsrooms[currentNewsroom],
        },
    ];

    const allNewsroomsOptions = [
        // { value: 'all', label: 'All newsrooms' },
        ...currentNewsroomOption,
        ...newsroomsOptions,
    ];

    return { currentNewsroomOption, allNewsroomsOptions, linkedProviders: newsrooms, currentNewsroom };
};

export const isOnlyCurrentNewsroomSelected = (state) => {
    const currentNewsroom = selectNewsroom(state);
    const { newsrooms } = getPlaylistFilters(state);
    if (!newsrooms) {
        return true;
    }

    return newsrooms?.length === 1 && newsrooms[0] === currentNewsroom;
};
