import React from 'react';
import { Spinner } from '@schibsted-svp/react-ui';
import config from 'config';
import css from './Loader.module.scss';

interface LoaderProps {
    selectedProvider?: string;
}

export function Loader({ selectedProvider }: LoaderProps) {
    const ssoProvider = config.auth.providers.find((provider) => provider.id === selectedProvider);

    return (
        <span className={css.info}>
            <Spinner className={css.spinner} />
            {selectedProvider ? (
                <>
                    <p className={css.instruction}>Welcome to </p>
                    <img
                        style={{
                            maxWidth: `300px`,
                        }}
                        src={ssoProvider.logo}
                        alt={ssoProvider.title}
                    />
                </>
            ) : null}
        </span>
    );
}
