import { put, call, spawn, takeEvery, select } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import { adminBffClient } from 'services';
import { watchForMqttEvents } from 'store/mqtt/client';

import { FETCH_CLIPS_SUCCESS, createClipSuccess, trimVideoSuccess } from 'store/clips/actions';
import { videoTranscodingProgress } from 'store/video/upload/actions';
import { getAsset } from 'store/assets/selectors';
import { cuttingProgress, CUTTING_COMPLETE } from './actions';

const TRANSCODING_TYPE = Object.freeze({
    CUTTING: 'CUTTING',
    TRANSCODING: 'TRANSCODING',
});

export function* checkAssetState({ provider, assetId: id, type, __typename: status, totalProgress }) {
    if (type === TRANSCODING_TYPE.TRANSCODING) {
        if (status === 'AssetIngestInProgress') {
            yield put(videoTranscodingProgress(provider, id, totalProgress));
        }
    } else if (type === TRANSCODING_TYPE.CUTTING) {
        if (status === 'AssetIngestInProgress') {
            yield put(cuttingProgress(provider, id, totalProgress));
            yield spawn(watchForMqttEvents, { topic: `ingest/${provider}/${id}/cut/+` });
        }
    }
}

export function* fetchClipsSuccessSaga({ assets }) {
    try {
        const results = yield call(adminBffClient.getLastAssetsIngest, {
            assets: assets.map(({ provider, id }) => ({ provider, assetId: id })),
        });
        for (const state of results) {
            yield call(checkAssetState, state);
        }
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch clips',
            extras: {
                error,
            },
        });
    }
}
export function* cuttingCompleteSaga({ id, provider }) {
    const asset = yield select(getAsset, { provider, id });
    const originAssetId = asset?.additional?.originAssetId;
    if (originAssetId) {
        yield put(createClipSuccess(originAssetId, id, provider));
    } else {
        yield put(trimVideoSuccess(id, provider));
    }
}

export default [
    takeEvery(FETCH_CLIPS_SUCCESS, fetchClipsSuccessSaga),
    takeEvery(CUTTING_COMPLETE, cuttingCompleteSaga),
];
