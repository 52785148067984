const categories = {
    Arts: {
        label: 'Arts',
        subcategories: {
            Books: 'Books',
            Design: 'Design',
            'Fashion & Beauty': 'Fashion & Beauty',
            Food: 'Food',
            'Performing Arts': 'Performing Arts',
            'Visual Arts': 'Visual Arts',
        },
    },

    Business: {
        label: 'Business',
        subcategories: {
            Careers: 'Careers',
            Entrepreneurship: 'Entrepreneurship',
            Investing: 'Investing',
            Management: 'Management',
        },
    },

    Comedy: {
        label: 'Comedy',
        subcategories: {
            'Comedy Interviews': 'Comedy Interviews',
            Improv: 'Improv',
            'Stand-Up': 'Stand-Up',
        },
    },

    Education: {
        label: 'Education',
        subcategories: {
            Courses: 'Courses',
            'How To': 'How To',
            'Language Learning': 'Language Learning',
            'Self-Improvement': 'Self-Improvement',
        },
    },

    Fiction: {
        label: 'Fiction',
        subcategories: {
            'Comedy Fiction': 'Comedy Fiction',
            Drama: 'Drama',
            'Science Fiction': 'Science Fiction',
        },
    },

    Government: {
        label: 'Government (no subcategories)',
    },
    History: {
        label: 'History (no subcategories)',
    },

    'Health & Fitness': {
        label: 'Health & Fitness',
        subcategories: {
            'Alternative Health': 'Alternative Health',
            Fitness: 'Fitness',
            Medicine: 'Medicine',
            'Mental Health': 'Mental Health',
            Nutrition: 'Nutrition',
            Sexuality: 'Sexuality',
        },
    },

    'Kids & Family': {
        label: 'Kids & Family',
        subcategories: {
            'Education for Kids': 'Education for Kids',
            Parenting: 'Parenting',
            'Pets & Animals': 'Pets & Animals',
            'Stories for Kids': 'Stories for Kids',
        },
    },

    Leisure: {
        label: 'Leisure',
        subcategories: {
            'Animation & Manga': 'Animation & Manga',
            Automotive: 'Automotive',
            Aviation: 'Aviation',
            Crafts: 'Crafts',
            Games: 'Games',
            Hobbies: 'Hobbies',
            'Home & Garden': 'Home & Garden',
            'Video Games': 'Video Games',
        },
    },

    Music: {
        label: 'Music',
        subcategories: {
            'Music Commentary': 'Music Commentary',
            'Music History': 'Music History',
            'Music Interviews': 'Music Interviews',
        },
    },

    News: {
        label: 'News',
        subcategories: {
            'Business News': 'Business News',
            'Daily News': 'Daily News',
            'Entertainment News': 'Entertainment News',
            'News Commentary': 'News Commentary',
            Politics: 'Politics',
            'Sports News': 'Sports News',
            'Tech News': 'Tech News',
        },
    },

    'Religion & Spirituality': {
        label: 'Religion & Spirituality',
        subcategories: {
            Buddhism: 'Buddhism',
            Christianity: 'Christianity',
            Hinduism: 'Hinduism',
            Islam: 'Islam',
            Judaism: 'Judaism',
            Religion: 'Religion',
            Spirituality: 'Spirituality',
        },
    },

    Science: {
        label: 'Science',
        subcategories: {
            Astronomy: 'Astronomy',
            Chemistry: 'Chemistry',
            'Earth Sciences': 'Earth Sciences',
            'Life Sciences': 'Life Sciences',
            Mathematics: 'Mathematics',
            'Natural Sciences': 'Natural Sciences',
            Nature: 'Nature',
            Physics: 'Physics',
            'Social Sciences': 'Social Sciences',
        },
    },

    'Society & Culture': {
        label: 'Society & Culture',
        subcategories: {
            Documentary: 'Documentary',
            'Personal Journals': 'Personal Journals',
            Philosophy: 'Philosophy',
            'Places & Travel': 'Places & Travel',
            Relationships: 'Relationships',
        },
    },
    Sports: {
        label: 'Sports',
        subcategories: {
            Baseball: 'Baseball',
            Basketball: 'Basketball',
            Cricket: 'Cricket',
            'Fantasy Sports': 'Fantasy Sports',
            Football: 'Football',
            Golf: 'Golf',
            Hockey: 'Hockey',
            Rugby: 'Rugby',
            Running: 'Running',
            Soccer: 'Soccer',
            Swimming: 'Swimming',
            Tennis: 'Tennis',
            Volleyball: 'Volleyball',
            Wilderness: 'Wilderness',
            Wrestling: 'Wrestling',
        },
    },

    Technology: {
        label: 'Technology (no subcategories)',
    },

    'True Crime': {
        label: 'True Crime (no subcategories)',
    },

    'TV & Film': {
        'After Shows': 'After Shows',
        'Film History': 'Film History',
        'Film Interviews': 'Film Interviews',
        'Film Reviews': 'Film Reviews',
        'TV Reviews': 'TV Reviews',
    },
};

export default categories;
