import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import ScreenContainer from 'components/ui/ScreenContainer';
import { generateSelectOptionsWithDepth } from 'lib/categories';
import { LabeledContainer, Dialog, Button, Select, Divider, Spinner } from '@schibsted-svp/react-ui';
import { CategoryForm } from 'components/categories/Form';
import { BreadCrumbs } from 'components/categories/BreadCrumbs';
import { CategoriesList } from 'components/categories/CategoriesList';
import css from './CategoryScreen.module.scss';

function CategoriesSelect({ onChange, categories, value }) {
    const options = generateSelectOptionsWithDepth(categories.items);

    const customStyles = {
        option: (base, { data }) => ({
            ...base,
            paddingLeft: `${data.depth * 10}px !important`,
        }),
    };

    return (
        <Select
            styles={customStyles}
            isLoading={categories.isFetching}
            isClearable
            placeholder="Search for category"
            options={options}
            value={value}
            onChange={onChange}
        />
    );
}

CategoriesSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    categories: PropTypes.shape({
        isFetching: PropTypes.bool.isRequired,
        items: PropTypes.object.isRequired,
        error: PropTypes.bool,
    }),
    value: PropTypes.string,
};

CategoriesSelect.defaultProps = {
    categories: undefined,
    value: undefined,
};

function CategoriesScreen({ provider, categories, shouldFetchCategories, categoriesFetch }) {
    const [showDialog, setDialogVisibility] = useState(false);
    const [parentCategory, setParentCategory] = useState();
    const history = useHistory();
    const userPermissions = useUserPermissions();

    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        if (shouldFetchCategories) {
            categoriesFetch({ provider });
        }
    }, [categoriesFetch, provider, shouldFetchCategories]);

    const goToNewCategoryForm = () => {
        history.push(`/${provider}/category/${parentCategory ? parentCategory.value : 0}/new`);
        setParentCategory(null);
        setDialogVisibility(false);
    };

    const handleCategorySelection = (selectedCategory) => {
        if (selectedCategory) {
            const { childrenCount, value } = selectedCategory;
            if (childrenCount) {
                history.push(`/${provider}/categories/${value}`);
            } else {
                history.push(`/${provider}/category/${value}`);
            }
        }
    };

    const handleParentSelect = (selectedParent) => {
        setParentCategory(selectedParent);
    };

    const categoriesCount = Object.keys(categories.items).length;

    const isFetchError = categories.error && categoriesCount === 0;
    const { isFetching } = categories;
    const isFetched = categoriesCount || (!isFetching && !isFetchError);

    return (
        <ScreenContainer>
            {isFetchError && (
                <h1 className={css.error}>
                    <span>Failed to fetch categories</span>
                    <Button type="button" size="small" onClick={() => categoriesFetch({ provider })}>
                        Retry
                    </Button>
                </h1>
            )}
            {isFetching && <Spinner className={css.spinner} />}
            {isFetched && (
                <>
                    <header className={css.header}>
                        <h1 className={css.heading}>Categories</h1>
                        <LabeledContainer strict label="Search for category">
                            <CategoriesSelect categories={categories} value={null} onChange={handleCategorySelection} />
                        </LabeledContainer>
                        <LabeledContainer>
                            <Dialog
                                isOpen={showDialog}
                                onClose={() => setDialogVisibility(false)}
                                heading="Create new category"
                                dialogTrigger={
                                    <Button
                                        type="button"
                                        disabled={!userPermissions?.isUser}
                                        onClick={() => setDialogVisibility(true)}
                                    >
                                        Create new category
                                    </Button>
                                }
                            >
                                <Dialog.Section>
                                    <LabeledContainer label="Choose parent category" className={css['dialog-select']}>
                                        <CategoriesSelect
                                            categories={categories}
                                            value={parentCategory}
                                            onChange={handleParentSelect}
                                        />
                                    </LabeledContainer>
                                </Dialog.Section>
                                <Dialog.Section variant="darker" mode="flexRight">
                                    <Button onClick={() => setDialogVisibility(false)} type="button" variant="standard">
                                        Cancel
                                    </Button>
                                    <Button type="button" onClick={goToNewCategoryForm}>
                                        Create
                                    </Button>
                                </Dialog.Section>
                            </Dialog>
                        </LabeledContainer>
                    </header>
                    <Divider margin="32px -32px 18px" />
                    <Route path="/:newsroom/(categories|category)/:category(\d+)/:mode(new)?">
                        <BreadCrumbs provider={provider} categories={categories} />
                    </Route>
                    <Route exact path="/:newsroom/category/:category(\d+)?/:mode(new)?">
                        <CategoryForm key={pathname} />
                    </Route>
                    <Route exact path="/:newsroom/categories/:category(\d+)?">
                        <CategoriesList provider={provider} />
                    </Route>
                </>
            )}
        </ScreenContainer>
    );
}

CategoriesScreen.propTypes = {
    provider: PropTypes.string.isRequired,
    categories: PropTypes.shape({
        isFetching: PropTypes.bool.isRequired,
        items: PropTypes.object.isRequired,
        error: PropTypes.bool,
    }),
    shouldFetchCategories: PropTypes.bool.isRequired,
    categoriesFetch: PropTypes.func.isRequired,
};

CategoriesScreen.defaultProps = {
    categories: undefined,
};

export default CategoriesScreen;
