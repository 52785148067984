export const config = {
    api: {
        host: 'https://example.com/api',
    },
    auth: {
        url: 'https://example.com/auth',
    },
    player: {
        options: {
            api: 'https://example.com/api',
            tokenUrl: 'https://example.com/teken',
            thumbnailsUrl: 'https://example.com/thumbnails',
        },
    },
    aws: {
        key: 'aws_key',
        bucket: 'aws_bucket',
    },
    asset: {
        labels: {
            status: {
                draft: 'incoming',
                readyToPublish: 'ready to publish',
                active: 'published',
                inactive: 'unpublished',
            },
        },
    },
} as const;

export default config;
