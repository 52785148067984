import { useState, useCallback, memo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import { searchFor } from 'store/ui/actions';
import { getSearchTerm } from 'store/video/navigation-tabs/selectors';
import SearchForm from './SearchForm';

export const Search = memo(function Search() {
    const searchTermGlobal = useAppSelector(getSearchTerm);
    const [searchTerm, setSearchTerm] = useState(searchTermGlobal);
    const [prevSearchTerm, setPrevSearchTerm] = useState('');

    const { newsroom } = useParams();
    const history = useHistory();
    const dispatch = useAppDispatch();

    if (searchTermGlobal !== prevSearchTerm) {
        setSearchTerm(searchTermGlobal);
        setPrevSearchTerm(searchTermGlobal);
    }

    const onChange = useCallback(
        (term) => {
            setSearchTerm(term);
        },
        [setSearchTerm]
    );

    const onSearch = useCallback(() => {
        const location = {
            pathname: `/${newsroom}/assets`,
            search: '',
        };

        const search = new URLSearchParams();

        if (searchTerm) {
            search.append('query', searchTerm);
        }

        location.search = `?${search.toString()}`;

        if (searchTerm !== searchTermGlobal) {
            dispatch(searchFor({ searchTerm, provider: newsroom }));
            history.push(location);
        }
    }, [newsroom, searchTerm, dispatch, history, searchTermGlobal]);

    return <SearchForm value={searchTerm} onSubmit={onSearch} onChange={onChange} />;
});
