import { Component } from 'react';
import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';
import { Button, LabeledContainer } from '@schibsted-svp/react-ui';
import { TimeInput } from 'components/ui/Form/TimeInput';
import TrimConfirmationDialog from './TrimConfirmationDialog';

import './Playback.scss';

const bem = new BEM('Playback');

export default class Playback extends Component {
    static propTypes = {
        className: PropTypes.string,
        disabled: PropTypes.bool,
        duration: PropTypes.number,
        onChange: PropTypes.func,
        onClipCreate: PropTypes.func,
        onVideoTrim: PropTypes.func,
        onResetTrim: PropTypes.func,
        value: PropTypes.shape({
            begin: PropTypes.number,
            end: PropTypes.number,
        }),
        precise: PropTypes.bool,
        player: PropTypes.shape({
            currentTime: PropTypes.number,
        }),
        createClipLoading: PropTypes.bool,
        trimLoading: PropTypes.bool,
        trimDisabled: PropTypes.bool,
        resetTrimLoading: PropTypes.bool,
    };

    static defaultProps = {
        className: undefined,
        disabled: false,
        duration: 0,
        onChange: () => {},
        onClipCreate: undefined,
        onVideoTrim: undefined,
        onResetTrim: undefined,
        value: null,
        precise: false,
        player: null,
        createClipLoading: false,
        trimLoading: false,
        trimDisabled: false,
        resetTrimLoading: false,
    };

    /**
     * @type {Object}
     * @property {{begin: Number, end: Number}} playback
     */
    state = {
        playback: {
            begin: 0,
            end: null,
        },
    };

    componentDidMount() {
        this.resetPlaybackTimes();
    }

    componentDidUpdate(prevProps) {
        const { duration: oldDuration } = prevProps;
        const { duration: newDuration } = this.props;

        if (oldDuration !== newDuration) {
            this.resetPlaybackTimes();
        }
    }

    /**
     * @returns {{begin: Number, end: Number}}
     */
    get playback() {
        const { precise, duration } = this.props;
        const { playback } = this.state;

        if (precise) {
            if (playback.end === duration) {
                return { begin: playback.begin, end: null };
            }
            return playback;
        }

        if (playback.end === duration) {
            return { begin: Math.floor(playback.begin), end: null };
        }

        return {
            begin: Math.floor(playback.begin),
            end: playback.end ? Math.floor(playback.end) : null,
        };
    }

    handlePlaybackBeginChange = (begin) => this.changePlayback({ begin });

    handlePlaybackBeginSet = () => this.changePlayback({ begin: this.props.player.currentTime });

    handlePlaybackEndChange = (end) => this.changePlayback({ end });

    handlePlaybackEndSet = () => this.changePlayback({ end: this.props.player.currentTime });

    handleCreateClipClick = () => {
        const { onClipCreate } = this.props;
        onClipCreate(this.playback);
    };

    handleVideoTrimClick = () => {
        const { onVideoTrim } = this.props;
        onVideoTrim(this.playback);
    };

    handleResetTrim = () => {
        const { onResetTrim } = this.props;
        onResetTrim();
    };

    resetPlaybackTimes() {
        this.setState(() => ({
            playback: {
                begin: this.props.value?.begin || 0,
                end: this.props.value?.end || null,
            },
        }));
    }

    /**
     * @param {{begin?: Number, end?: Number}} changes
     */
    changePlayback(changes) {
        this.setState(
            ({ playback }) => ({
                playback: { ...playback, ...changes },
            }),
            () => {
                const { onChange, value, duration } = this.props;
                const { playback } = this.state;
                // do not update null value if playback has default values (prevent unsaved form changes)
                if (value === null && playback.begin === 0 && [0, duration].includes(playback.end)) {
                    return;
                }
                onChange(playback);
            }
        );
    }

    /**
     * Render component
     * @return {JSXElement}
     */
    render() {
        const {
            className,
            disabled,
            duration,
            onClipCreate,
            onVideoTrim,
            onResetTrim,
            player,
            createClipLoading,
            trimLoading,
            trimDisabled,
            resetTrimLoading,
        } = this.props;
        const { playback } = this.state;

        const formDisabled = disabled || !player;

        return (
            <div className={bem.block(undefined, className)}>
                <LabeledContainer strict label="Start time" className={bem.element('input')}>
                    <TimeInput
                        size="small"
                        value={playback.begin}
                        maxValue={duration}
                        disabled={formDisabled}
                        onValueChange={this.handlePlaybackBeginChange}
                    />
                </LabeledContainer>

                <LabeledContainer>
                    <Button
                        type="button"
                        size="small"
                        variant="standard"
                        disabled={formDisabled}
                        onClick={this.handlePlaybackBeginSet}
                    >
                        Set start time
                    </Button>
                </LabeledContainer>

                <LabeledContainer strict label="End time" className={bem.element('input')}>
                    <TimeInput
                        size="small"
                        value={playback.end}
                        maxValue={duration}
                        disabled={formDisabled}
                        onValueChange={this.handlePlaybackEndChange}
                    />
                </LabeledContainer>

                <LabeledContainer>
                    <Button
                        type="button"
                        size="small"
                        variant="standard"
                        disabled={formDisabled}
                        onClick={this.handlePlaybackEndSet}
                    >
                        Set end time
                    </Button>
                </LabeledContainer>

                {onClipCreate && (
                    <LabeledContainer>
                        <Button
                            type="button"
                            size="small"
                            disabled={formDisabled}
                            onClick={this.handleCreateClipClick}
                            loading={createClipLoading}
                        >
                            Create clip
                        </Button>
                    </LabeledContainer>
                )}

                {onVideoTrim && (
                    <TrimConfirmationDialog
                        heading="Trim video"
                        message="Are you sure to trim the video?"
                        onConfirmClick={this.handleVideoTrimClick}
                        loading={trimLoading}
                        disabled={trimDisabled || formDisabled}
                        gaTrackedValue="confirm/trim-video"
                    />
                )}
                {onResetTrim && (
                    <TrimConfirmationDialog
                        heading="Reset trim"
                        message="Are you sure to revert the trimmed video back?"
                        onConfirmClick={this.handleResetTrim}
                        loading={resetTrimLoading}
                        disabled={trimDisabled || formDisabled}
                        gaTrackedValue="confirm/reset-trim-video"
                    />
                )}
            </div>
        );
    }
}
