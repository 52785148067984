import classnames from 'classnames/bind';
import { ChangeEvent, memo, useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Checkbox, Divider, LabeledContainer } from '@schibsted-svp/react-ui';
import useToggle from 'hooks/useToggle';

import { AdvancedToggleButton } from 'components/video/live/form/AdvancedToggleButton';
import { RadioOptionRHF } from 'components/video/live/form/RadioOption';

import css from './AudioConfiguration.module.scss';

const cln = classnames.bind(css);

export const AudioChannelsMixType = {
    STEREO: 'STEREO',
    DUALMONO: 'DUALMONO',
    IN3N4: 'IN3N4',
    CUSTOM: 'CUSTOM',
} as const;

export const AudioChannelsMixValuesStereo = {
    leftChannel: [true, false, false, false, false],
    rightChannel: [false, true, false, false, false],
} as const;

export const AudioChannelsMixValuesDualMono = {
    leftChannel: [true, true, false, false, false],
    rightChannel: [true, true, false, false, false],
} as const;

export const AudioChannelsMixValuesIn3n4 = {
    leftChannel: [false, false, true, false, false],
    rightChannel: [false, false, false, true, false],
} as const;

export interface AudioConfigurationFormData {
    audioChannelsMix: {
        type: (typeof AudioChannelsMixType)[keyof typeof AudioChannelsMixType];
        values: {
            leftChannel: readonly boolean[];
            rightChannel: readonly boolean[];
        };
    };
    audioNormalization: boolean;
}

type AudioConfigurationProps = {
    enableAudioNormalization?: boolean;
};

export const AudioConfiguration = memo(({ enableAudioNormalization = false }: AudioConfigurationProps) => {
    const { register, setValue, control } = useFormContext<AudioConfigurationFormData>();

    const [isAdvancedExpanded, toggleIsAdvancedExpanded] = useToggle(false);
    const [isAdvancedFirstTimeExpanded, setIsAdvancedFirstTimeExpanded] = useState(false);

    const audioChannelsMix = useWatch({ name: 'audioChannelsMix', control });
    const { type } = audioChannelsMix;

    const toggleAdvancedSection = () => {
        toggleIsAdvancedExpanded();
        setIsAdvancedFirstTimeExpanded(true);
    };

    const handleTypeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const audioChannelsMixType = event.target.value;
            if (audioChannelsMixType === AudioChannelsMixType.STEREO) {
                setValue('audioChannelsMix.values', AudioChannelsMixValuesStereo);
            } else if (audioChannelsMixType === AudioChannelsMixType.DUALMONO) {
                setValue('audioChannelsMix.values', AudioChannelsMixValuesDualMono);
            } else if (audioChannelsMixType === AudioChannelsMixType.IN3N4) {
                setValue('audioChannelsMix.values', AudioChannelsMixValuesIn3n4);
            }
        },
        [setValue]
    );

    const areCheckboxesDisabled = type !== AudioChannelsMixType.CUSTOM;
    const radioButtonClassNames = (audioChannelsMixType) =>
        cln('type', { 'type--selected': type === audioChannelsMixType });

    return (
        <div
            className={cln('advancedContainer', {
                'advancedContainer--expanded': isAdvancedExpanded,
            })}
        >
            <Divider margin="10px 0" />

            <LabeledContainer
                label="Configure live audio"
                className={cln('advanced', {
                    'advanced--expanded': isAdvancedExpanded,
                    'advanced--touched': isAdvancedFirstTimeExpanded,
                })}
            >
                <RadioOptionRHF
                    name="audioChannelsMix.type"
                    value={AudioChannelsMixType.STEREO}
                    label="Default stereo"
                    onChange={handleTypeChange}
                    className={radioButtonClassNames(AudioChannelsMixType.STEREO)}
                />
                <RadioOptionRHF
                    name="audioChannelsMix.type"
                    value={AudioChannelsMixType.DUALMONO}
                    label="Dual mono"
                    onChange={handleTypeChange}
                    className={radioButtonClassNames(AudioChannelsMixType.DUALMONO)}
                />
                <RadioOptionRHF
                    name="audioChannelsMix.type"
                    value={AudioChannelsMixType.IN3N4}
                    label="3+4 stereo"
                    onChange={handleTypeChange}
                    className={radioButtonClassNames(AudioChannelsMixType.IN3N4)}
                />
                <RadioOptionRHF
                    name="audioChannelsMix.type"
                    value={AudioChannelsMixType.CUSTOM}
                    label="Custom mixing"
                    onChange={handleTypeChange}
                    className={radioButtonClassNames(AudioChannelsMixType.CUSTOM)}
                />
                <div className={css.grid}>
                    <table className={css.channelsMap}>
                        <tr>
                            <th aria-hidden="true" />
                            <th>Left speaker</th>
                            <th>Right speaker</th>
                        </tr>
                        {Array.from({ length: 5 }, (_, i) => (
                            <tr key={i}>
                                <td>Channel {i + 1}</td>
                                <td>
                                    <Checkbox
                                        {...register(`audioChannelsMix.values.leftChannel.${i}`)}
                                        disabled={areCheckboxesDisabled}
                                    />
                                </td>
                                <td>
                                    <Checkbox
                                        {...register(`audioChannelsMix.values.rightChannel.${i}`)}
                                        disabled={areCheckboxesDisabled}
                                    />
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
                {enableAudioNormalization && (
                    <LabeledContainer label="Audio settings" className={css.optionList}>
                        <Checkbox {...register('audioNormalization')} label="Normalize audio" />
                    </LabeledContainer>
                )}
            </LabeledContainer>
            <AdvancedToggleButton onClick={toggleAdvancedSection} isExpanded={isAdvancedExpanded} />
        </div>
    );
});

AudioConfiguration.displayName = 'AudioConfiguration';
