import { Button } from '@schibsted-svp/react-ui';
import { MdAdd } from 'react-icons/md';
import { PreviewDropzone } from './PreviewDropzone';
import css from './MultiPreviewPlaceholder.module.scss';

type MultiPreviewPlaceholderProps = {
    provider: string;
    assetId: number;
    prefix: string;
};

export function MultiPreviewPlaceholder({ provider, assetId, prefix }: MultiPreviewPlaceholderProps) {
    return (
        <PreviewDropzone assetId={assetId} provider={provider} prefix={prefix} className={css.dropzone}>
            {(inputProps) => (
                <Button type="label" size="small" iconOnly>
                    <input type="file" {...inputProps} hidden />
                    <MdAdd size={20} />
                </Button>
            )}
        </PreviewDropzone>
    );
}
