import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configureScope } from '@sentry/browser';
import { useCategoryPodmeMetadataQuery } from 'services/admin-bff-sdk/generated';
import type { CategoryPodmeMetadata } from 'services/admin-bff-sdk/generated';
import { getCategoryPromoUrl } from 'store/categories/selectors';
import { fetchCategory } from 'store/categories/actions';
import { isCategoryPreviewPending } from 'store/uploads/previews/selectors';
import { isPodmeMetadataPolling } from '../helpers';

export const useFetchCategory = ({ provider, categoryId }: { categoryId: number; provider: string }) => {
    const [isFetchingCategory, setIsFetchingCategory] = useState(false);
    const [isCategoryFetchError, setCategoryFetchError] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!categoryId) {
            return;
        }
        configureScope((scope) => {
            scope.setTags({ categoryId });
        });

        (async function fetch() {
            setIsFetchingCategory(true);
            try {
                await dispatch(fetchCategory(provider, categoryId));
                setCategoryFetchError(false);
            } catch (error) {
                setCategoryFetchError(true);
            }

            setIsFetchingCategory(false);
        })();

        // eslint-disable-next-line consistent-return
        return () =>
            configureScope((scope) => {
                scope.setTags({ categoryId: null });
            });
    }, [categoryId, provider, dispatch, setCategoryFetchError, setIsFetchingCategory]);

    return { isFetchingCategory, isCategoryFetchError };
};

export const usePodmeMetadata = ({
    provider,
    categoryId,
    skip,
}: {
    provider: string;
    categoryId: number;
    skip?: boolean;
}) => {
    const { data } = useCategoryPodmeMetadataQuery({ provider, categoryId }, { skip });
    return data?.categoryPodmeMetadata;
};

const PODME_METADATA_POLLING_INTERVAL = 5000;

export const usePodmeMetadataWithPolling = ({
    provider,
    categoryId,
}: {
    provider: string;
    categoryId: number;
}): { podmeMetadata: CategoryPodmeMetadata; isPolling: boolean } => {
    const podmeMetadataRef = useRef<CategoryPodmeMetadata>();
    const isPolling = isPodmeMetadataPolling(podmeMetadataRef.current);

    const { data } = useCategoryPodmeMetadataQuery(
        { provider, categoryId },
        { pollingInterval: isPolling ? PODME_METADATA_POLLING_INTERVAL : undefined }
    );
    const podmeMetadata = data?.categoryPodmeMetadata;

    useEffect(() => {
        podmeMetadataRef.current = podmeMetadata;
    }, [podmeMetadata]);

    return { podmeMetadata, isPolling };
};

export const useIsPreviewLoading = ({ categoryId, provider }) =>
    useSelector((state) => isCategoryPreviewPending(state, { categoryId, provider }));

export const useGetCategoryPromoUrl = ({ categoryId, provider }: { categoryId: number; provider: string }) =>
    useSelector((state) => getCategoryPromoUrl(state, { categoryId, provider })) || undefined;
