import { Button, Dialog } from '@schibsted-svp/react-ui';
import { useEffect } from 'react';
import * as ReactGA from 'react-ga';
import { closeCustomPreviewUploadDialog, openCustomPreviewUploadDialog } from 'store/ui/assetForm/actions';
import { PreviewDropzone } from './PreviewDropzone';
import css from './UploadCustomPreview.module.scss';

interface UploadCustomPreviewProps {
    assetId: number;
    provider: string;
    closeDialog: () => void;
}

export function UploadCustomPreview({ assetId, provider, closeDialog }: UploadCustomPreviewProps) {
    useEffect(() => {
        ReactGA.modalview(`custom-preview-upload`);
    }, []);

    useEffect(() => {
        openCustomPreviewUploadDialog({ provider, assetId });
        return () => {
            closeCustomPreviewUploadDialog({ provider, assetId });
        };
    }, [assetId, provider]);

    const handleUpload = () => {
        closeDialog();
    };

    return (
        <>
            <Dialog.Section>
                <PreviewDropzone
                    assetId={assetId}
                    provider={provider}
                    onUpload={handleUpload}
                    className={css.dropzone}
                />
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={closeDialog}>
                    Cancel
                </Button>
            </Dialog.Section>
        </>
    );
}
