import { customAlphabet } from 'nanoid';

/**
 * @param {Number} [size=21]
 * @return {String}
 */
export function generateUniqId(size = 21) {
    const generate = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', size);
    return generate();
}
