import { Spinner } from '@schibsted-svp/react-ui';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activateNavigationTab, deactivateNavigationTab } from 'store/video/navigation-tabs/actions';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import ScreenContainer from 'components/ui/ScreenContainer';
import VideoNavigation from 'components/video/navigation/VideoNavigation';
import { AssetForm } from 'components/AssetForm';

import { AssetNotFound } from './AssetNotFound';
import { useAssetStore } from './hooks';
import { DragAndDropUpload } from './DragAndDropUpload';

import css from './AssetScreen.module.scss';

export const AssetScreen = memo(function AssetScreen() {
    const { newsroom, id } = useParams();
    const { asset, error, isFetching } = useAssetStore();
    const dispatch = useDispatch();
    const userPermissions = useUserPermissions();
    const canRender = userPermissions?.isUser || asset?.status === 'active';

    useEffect(() => {
        dispatch(activateNavigationTab(Number(id), newsroom));

        return () => {
            dispatch(deactivateNavigationTab(Number(id), newsroom));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, newsroom]);

    if (!isFetching && (error?.status === 404 || !canRender)) {
        return <AssetNotFound />;
    }

    if (isFetching && !asset) {
        return <Spinner containerClassName={css.spinner} />;
    }

    if (!asset) {
        return null;
    }
    return (
        <ScreenContainer className={css.assetScreen}>
            <VideoNavigation newsroom={newsroom} />

            <AssetForm asset={asset} key={id} />
            <DragAndDropUpload provider={newsroom} asset={asset} />
        </ScreenContainer>
    );
});
