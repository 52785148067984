import { getNewsroomConfig } from 'config';

export function getContentTypeOptions(provider) {
    const newsroomConfig = getNewsroomConfig(provider);
    const { contentTypes } = newsroomConfig;

    return Object.entries(contentTypes).map(([value, label]) => ({
        value,
        label,
    }));
}
