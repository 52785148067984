import { useMemo, useCallback } from 'react';
import type { Control } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { getDynamicFields, groupDynamicFields, filterDynamicFieldsForAsset } from '../helpers/DynamicForm';
import type { FormValues } from '../types';
import { useCategoryWatch } from './useCategoryWatch';

export function useDynamicFields({ asset, control }: { asset: Asset; control: Control<FormValues> }) {
    const { category } = useCategoryWatch({ provider: asset.provider, control });

    const dynamicFields = useMemo(
        () => filterDynamicFieldsForAsset(getDynamicFields(category), asset),
        [asset, category]
    );

    const dynamicFieldsGrouped = useMemo(() => groupDynamicFields(dynamicFields), [dynamicFields]);

    const isDynamicField = useCallback(
        (key: string) => dynamicFields.some((field) => key === field.key),
        [dynamicFields]
    );

    return { dynamicFields, dynamicFieldsGrouped, isDynamicField };
}
