import { useSelector } from 'react-redux';
import type { FieldErrors, UseFormReturn } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { getVideoUploadErrors } from 'store/video/upload/selectors';
import { getAssetData } from 'store/assets/selectors';
import { SubmissionError } from 'components/ui/Form';
import type { FormValues } from '../types';
import css from './FormErrors.module.scss';

type FormErrorsProps = {
    provider: Asset['provider'];
    assetId: Asset['id'];
    formApi: UseFormReturn<FormValues>;
    formErrors: FieldErrors<FormValues>;
};

export function FormErrors({ provider, assetId, formApi, formErrors }: FormErrorsProps) {
    const formError = formErrors?.root?.serverError?.message;
    const uploadErrors = useSelector((state) => getVideoUploadErrors(state, { provider, id: assetId }));
    const assetError = useSelector((state) => getAssetData(state, { provider, id: assetId })?.error);

    if (formError) {
        return <SubmissionError className={css.error}>{formError}</SubmissionError>;
    }

    if (uploadErrors.length > 0 || assetError) {
        const clipboardText = JSON.stringify({
            assetId,
            assetError,
            uploadErrors,
            formState: formApi.getValues(),
        });

        return (
            <SubmissionError className={css.error} clipboardText={clipboardText}>
                Something went wrong. Please contact us on Slack at #svp-support or on mail:svp@schibsted.com and
                provide below error code:
            </SubmissionError>
        );
    }

    return null;
}
