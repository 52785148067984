import { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';
import { TagPropTypes } from 'store/tags/validation';

import { Spinner } from '@schibsted-svp/react-ui';

import './LoadableTagLabel.scss';

const bem = new BEM('tag');

class LoadableTagLabel extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        id: PropTypes.string.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        provider: PropTypes.string.isRequired,
        item: TagPropTypes,
        className: PropTypes.string,
    };

    static defaultProps = {
        item: {},
        className: '',
    };

    render() {
        const { item = {}, className } = this.props;
        const { error, isFetching, tag = {} } = item;

        // TODO try to figure out sth better than displaying 'error' in case of tag fetch failure
        const label = error ? 'error' : tag && (tag.tag || tag.id);

        return (
            <span className={bem.block({ error: !!error }, className)}>
                {label || ''}
                {isFetching && <Spinner size="compact" />}
            </span>
        );
    }
}

export default LoadableTagLabel;
