import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { getObjectPaths, getObjectValue } from 'lib/object';
import { REMOVE_NAVIGATION_TAB } from 'store/video/navigation-tabs/actions';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { UseFormReturn } from 'react-hook-form';
import type { FormValues } from '../types';

type PersistentState = Record<number, Partial<FormValues>>;
const initialState = {} as PersistentState;

export const rhfPersistentSlice = createSlice({
    name: 'rhfPersistent',
    initialState,
    reducers: {
        delete(state, action: PayloadAction<number>) {
            delete state[action.payload];
        },
        set(state, action: PayloadAction<{ id: number; data: Partial<FormValues> }>) {
            state[action.payload.id] = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(REMOVE_NAVIGATION_TAB, (state, action) => {
            delete state[(action as unknown as { id: number }).id];
        });
    },
});

export const useAssetFormPersistent = (
    assetId: number,
    formApi: UseFormReturn<FormValues>
): { onSubmitAction: () => void } => {
    const dispatch = useDispatch();

    const onSubmitAction = () => {
        dispatch(rhfPersistentSlice.actions.delete(assetId));
    };

    const persistent = useAppSelector<PersistentState>((state) => state.rhfPersistent[assetId]) || {};
    useEffect(() => {
        Object.entries(persistent).forEach(([key, value]) => {
            // TODO: Ignoring ATM - it was very hard to type it correctly.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            formApi.setValue(key, value, { shouldDirty: true, shouldTouch: true });
        });
        return () => {
            const changed = getObjectPaths(formApi.formState.dirtyFields)
                .filter((path) => getObjectValue(formApi.formState.dirtyFields, path))
                .map((path) => [path, formApi.getValues(path as keyof FormValues)]);

            if (changed.length) {
                dispatch(rhfPersistentSlice.actions.set({ id: assetId, data: Object.fromEntries(changed) }));
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        onSubmitAction,
    };
};
