import * as Actions from './actionTypes';

export const linkedProvidersFetch = () => ({
    type: Actions.LINKEDPROVIDERS_FETCH,
});

export const linkedProvidersFetchSuccess = (payload) => ({
    type: Actions.LINKEDPROVIDERS_FETCH_SUCCESS,
    payload,
});

export const linkedProvidersFetchError = () => ({
    type: Actions.LINKEDPROVIDERS_FETCH_ERROR,
});
