export function isTargetExternal(target: string) {
    return target.startsWith('http');
}

export function getFileNameFromUrl(url: string | undefined) {
    try {
        return new URL(url).pathname?.split('/')?.pop();
    } catch {
        return undefined;
    }
}
