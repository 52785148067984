import { connect } from 'react-redux';
import { getMainNotificationVisibility } from 'store/ui/notifications/selectors';
import {
    getUserTooLongRunningLiveAssets,
    getFailedLiveAssetsCreatedBySpecificUser,
    getUserIdleLiveAssets,
} from 'store/live/selectors';
import getNextVersion from 'store/version/selectors';
import { setMainNotificationVisibility } from 'store/ui/notifications/actions';
import { authEmailSelector } from 'store/auth/selectors';
import { selectNewsroom } from 'store/newsroom/selectors';
import config from 'config';

import Avatar from './Avatar';

const enhance = connect(
    (state) => {
        const newsroom = selectNewsroom(state);
        const userEmail = authEmailSelector(state);

        return {
            newsroom,
            email: userEmail,
            longLiveAssets: getUserTooLongRunningLiveAssets(state, userEmail),
            idleLiveAssets: getUserIdleLiveAssets(state, userEmail),
            failedLiveAssets: getFailedLiveAssetsCreatedBySpecificUser(
                state,
                newsroom,
                userEmail,
                config.live.notifications.PERSONAL_PANEL_DISPLAY_LIMIT
            ),
            isMainNotificationVisible: getMainNotificationVisibility(state),
            nextVersion: getNextVersion(state),
        };
    },
    {
        setMainNotificationVisibility,
    }
);

export default enhance(Avatar);
