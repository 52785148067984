import { merge } from 'lib/object';
import {
    AFTER_LIVE_STREAM_END_TIME,
    AFTER_LIVE_STREAM_START_TIME,
    ELEMENTAL_EVENT_STATE_CHANGE,
    LIVE_ENCODERS_SUCCESS,
    START_LIVE_STREAM_FAILURE,
    START_LIVE_STREAM_REQUEST,
    START_LIVE_STREAM_SUCCESS,
    STOP_LIVE_STREAM_FAILURE,
    STOP_LIVE_STREAM_REQUEST,
    STOP_LIVE_STREAM_SUCCESS,
    UNPUBLISH_LIVE_ASSET_FAILURE,
    UNPUBLISH_LIVE_ASSET_REQUEST,
    UNPUBLISH_LIVE_ASSET_SUCCESS,
    EDIT_LIVE_STREAM_REQUEST,
    EDIT_LIVE_STREAM_SUCCESS,
    EDIT_LIVE_STREAM_FAILURE,
} from 'store/live/actions';

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default function runtimeReducer(state = {}, { type, newsroom, assetId, ...rest }) {
    switch (type) {
        case UNPUBLISH_LIVE_ASSET_REQUEST:
        case UNPUBLISH_LIVE_ASSET_SUCCESS:
        case UNPUBLISH_LIVE_ASSET_FAILURE: {
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...state[newsroom]?.[assetId],
                        isUnpublishing: type === UNPUBLISH_LIVE_ASSET_REQUEST,
                    },
                },
            });
        }

        case START_LIVE_STREAM_REQUEST:
        case START_LIVE_STREAM_SUCCESS:
        case START_LIVE_STREAM_FAILURE:
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...state[newsroom]?.[assetId],
                        isStarting: type === START_LIVE_STREAM_REQUEST,
                    },
                },
            });

        case STOP_LIVE_STREAM_REQUEST:
        case STOP_LIVE_STREAM_SUCCESS:
        case STOP_LIVE_STREAM_FAILURE:
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...state[newsroom]?.[assetId],
                        isStopping: type === STOP_LIVE_STREAM_REQUEST,
                    },
                },
            });

        case EDIT_LIVE_STREAM_REQUEST:
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...(state[newsroom]?.[assetId] || {}),
                        isEditing: true,
                    },
                },
            });
        case EDIT_LIVE_STREAM_SUCCESS:
        case EDIT_LIVE_STREAM_FAILURE:
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...state[newsroom]?.[assetId],
                        isEditing: false,
                    },
                },
            });

        case AFTER_LIVE_STREAM_START_TIME: {
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...state[newsroom]?.[assetId],
                        isStarting: true,
                    },
                },
            });
        }

        case AFTER_LIVE_STREAM_END_TIME: {
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...state[newsroom]?.[assetId],
                        isStopping: true,
                    },
                },
            });
        }

        case ELEMENTAL_EVENT_STATE_CHANGE: {
            const { data } = rest;
            if (data.state.toLowerCase() !== 'starting') {
                return state;
            }
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...state[newsroom]?.[assetId],
                        isStarting: true,
                    },
                },
            });
        }

        case LIVE_ENCODERS_SUCCESS: {
            const { channels = [] } = rest;
            return channels.reduce((reducerState, channel) => {
                const channelState = channel.state.toLowerCase();
                if (!['starting', 'stopping'].includes(channelState)) {
                    return reducerState;
                }
                const { asset } = channel;
                return {
                    ...reducerState,
                    [asset.provider]: {
                        ...reducerState[asset.provider],
                        [asset.id]: {
                            ...reducerState[asset.provider]?.[asset.id],
                            ...(channelState === 'starting' && { isStarting: true }),
                            ...(channelState === 'running' && { isStarting: false, isStopping: false }),
                            ...(channelState === 'stopping' && { isStopping: true }),
                        },
                    },
                };
            }, state);
        }

        default:
            return state;
    }
}
