import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export const streamUrlSchema = z.string().url({ message: 'Stream URL must be a valid url' });

export const seriesSchema = z
    .object({
        seasonNumber: z.number({ invalid_type_error: 'Season number must be a number' }).optional().nullable(),
        episodeNumber: z.number({ invalid_type_error: 'Episode number must be a number' }).optional().nullable(),
    })
    .superRefine(({ seasonNumber, episodeNumber }, ctx) => {
        if (!seasonNumber && episodeNumber) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['seasonNumber'],
                message: 'Please choose season from the list or start typing to create a new one',
            });
        }
        if (seasonNumber && !episodeNumber) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['episodeNumber'],
                message: 'Please enter episode number',
            });
        }
    });

export const assetFormSchema = z
    .object({
        title: z.string().min(2, { message: 'The title must be at least 2 characters.' }),
        description: z.union([
            z.literal(''),
            z.null(),
            z.string().min(2, { message: 'The description must be at least 2 characters.' }),
        ]),
        descriptionFront: z.union([
            z.literal(''),
            z.null(),
            z.string().min(2, { message: 'The alternative description must be at least 2 characters.' }),
        ]),
        category: z.object({
            id: z.number({ invalid_type_error: 'Category must be selected' }),
        }),
        articleUrl: z.union([z.null(), z.string().url({ message: 'The article URL format is invalid' })]),
        series: seriesSchema,
        streamUrls: z.object({
            hls: streamUrlSchema,
        }),
        additional: z
            .object({
                metadata: z
                    .object({
                        richDescription: z
                            .union([
                                z.literal(''),
                                z
                                    .string()
                                    .min(2, { message: 'Rich description must be at least 2 characters long' })
                                    .max(2048, {
                                        message: 'Rich description must be at most 2048 characters long',
                                    }),
                            ])
                            .optional()
                            .nullable(),
                        bylineName: z.union([z.literal(''), z.string()]).optional(),
                        bylineEmail: z
                            .union([z.literal(''), z.string().email({ message: 'Must be an e-mail' })])
                            .optional(),
                    })
                    .catchall(z.any())
                    .refine(({ bylineName, bylineEmail }) => (bylineName ? bylineEmail : true), {
                        message: 'Must be provided',
                        path: ['bylineEmail'],
                    })
                    .refine(({ bylineName, bylineEmail }) => (bylineEmail ? bylineName : true), {
                        message: 'Must be provided',
                        path: ['bylineName'],
                    }),
            })
            .passthrough(),
    })
    .passthrough();

export const resolver = zodResolver(assetFormSchema);
