import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getScheduledLiveDuration } from 'models/asset';
import { getChannelStartTime, isChannelIdle, isChannelRunning, isChannelScheduled } from 'models/channel';
import { areLiveEncodersLoading, areMediaLiveChannelsReady, getMediaLiveChannel } from 'store/live/selectors';
import MediaLiveInputInfoDialog from '../MediaLiveInputInfoDialog/MediaLiveInputInfoDialog';
import LiveStopButton from './LiveRuntimeButtons/LiveStopButton';
import LiveStartButton from './LiveRuntimeButtons/LiveStartButton';
import LiveControlsTemplate from './LiveControlsTemplate';
import { LiveScheduleDialog } from './LiveScheduleDialog';
import css from './MediaLiveEncoderControls.module.scss';
import { LiveCreationDialog } from '../LiveCreationDialog';

function MediaLiveEncoderControls({ asset, isStarting, isStopping }) {
    const channel = useSelector((state) => getMediaLiveChannel(state, { assetId: asset.id, provider: asset.provider }));
    const isEncoderReady = useSelector((state) => areMediaLiveChannelsReady(state, { provider: asset.provider }));
    const isFetching = useSelector((state) => areLiveEncodersLoading(state, { newsroom: asset.provider }));
    const disabled = !isEncoderReady && isFetching;
    const liveEventStartTime = getChannelStartTime(channel);
    const liveEventDuration = getScheduledLiveDuration(asset);

    const isIdle = isChannelIdle(channel);
    const isScheduled = isChannelScheduled(channel);
    const isRunning = isChannelRunning(channel);

    const sourceButton = channel ? (
        <MediaLiveInputInfoDialog
            assetId={asset.id}
            provider={asset.provider}
            channel={channel}
            isStarting={isStarting}
        />
    ) : (
        <LiveCreationDialog
            form="live"
            assetId={asset.id}
            newsroom={asset.provider}
            triggerProps={{ buttonLoading: disabled }}
            from="sourceButton"
        />
    );

    return (
        <LiveControlsTemplate
            newsroom={asset.provider}
            assetId={asset.id}
            startTime={asset.flightTimes.start}
            liveDuration={liveEventDuration}
            sourceButton={sourceButton}
            isIdle={isIdle}
            isScheduled={isScheduled}
            isStarting={isStarting}
            isRunning={isRunning}
            isStopping={isStopping}
        >
            {channel ? (
                <>
                    <LiveScheduleDialog
                        newsroom={asset.provider}
                        assetId={asset.id}
                        startTime={asset.flightTimes.start}
                        encoderDuration={liveEventDuration}
                        encoderStartTime={liveEventStartTime}
                        isRunning={isStarting || isRunning || isStopping}
                        disabled={disabled || isStarting || isStopping}
                    >
                        {!isStarting && (isRunning || isStopping) ? (
                            <LiveStopButton
                                assetId={asset.id}
                                provider={asset.provider}
                                disabled={disabled}
                                loading={isStopping}
                                dropdownPrimaryOption
                                connected
                            >
                                Stop encoder
                            </LiveStopButton>
                        ) : null}

                        {isIdle || isScheduled || isStarting ? (
                            <LiveStartButton
                                assetId={asset.id}
                                provider={asset.provider}
                                disabled={disabled}
                                loading={isStarting}
                            >
                                Start encoder
                            </LiveStartButton>
                        ) : null}
                    </LiveScheduleDialog>
                    {isStopping ? (
                        <div className={css.stoppingInfo}>
                            <p>Video is stopping...</p>
                            <p>It takes several seconds</p>
                        </div>
                    ) : null}
                </>
            ) : null}
        </LiveControlsTemplate>
    );
}

MediaLiveEncoderControls.propTypes = {
    asset: PropTypes.object.isRequired,
    isStarting: PropTypes.bool.isRequired,
    isStopping: PropTypes.bool.isRequired,
};

export default MediaLiveEncoderControls;
