import { useFormContext, Controller, Control, FieldPath } from 'react-hook-form';
import { Select } from '@schibsted-svp/react-ui';
import { ExtendedProps as SelectProps } from '@schibsted-svp/react-ui/lib/es/Select';
import { useGetAccessDefinitionOptionsQueryState } from 'services/svp-api-client/access-definitions';
import { getValueFromOption, findSingleOptionInOptionsGroup } from 'components/ui/Form/Select';
import useNewsroom from 'hooks/useNewsroom';

type AccessDefinitionSelectProps = {
    name: string;
    disabled?: boolean;
    canChooseFreeAccess?: boolean;
} & SelectProps;

const useAccessDefinitionOptions = ({ canChooseFreeAccess = false }) => {
    const provider = useNewsroom();

    const rawOptions = useGetAccessDefinitionOptionsQueryState({ provider });
    let accessDefinitionOptions = rawOptions;

    if (canChooseFreeAccess) {
        const noPaywallOptions = [{ label: 'No paywall', options: [{ label: 'Open encryption', value: 'free' }] }];
        const paywallOptions = [{ label: 'Behind paywall', options: rawOptions }];
        accessDefinitionOptions = [...noPaywallOptions, ...paywallOptions];
    }

    return accessDefinitionOptions;
};

export function AccessDefinitionSelectRHF({
    name,
    disabled = false,
    canChooseFreeAccess,
    ...props
}: AccessDefinitionSelectProps) {
    const { control } = useFormContext();
    const accessDefinitionOptions = useAccessDefinitionOptions({ canChooseFreeAccess });
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Select
                    {...field}
                    value={findSingleOptionInOptionsGroup(accessDefinitionOptions)(field.value)}
                    onChange={(value) => field.onChange(getValueFromOption(value))}
                    options={accessDefinitionOptions}
                    placeholder="No encryption"
                    isDisabled={disabled}
                    size="compact"
                    isClearable
                    {...props}
                />
            )}
        />
    );
}

export function AccessDefinitionSelectRHFControl<T>({
    name,
    disabled = false,
    canChooseFreeAccess,
    control,
    ...props
}: Omit<AccessDefinitionSelectProps, 'name'> & { control: Control<T>; name: FieldPath<T> }) {
    const accessDefinitionOptions = useAccessDefinitionOptions({ canChooseFreeAccess });
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Select
                    {...field}
                    value={findSingleOptionInOptionsGroup(accessDefinitionOptions)(field.value)}
                    onChange={(value) => field.onChange(getValueFromOption(value))}
                    options={accessDefinitionOptions}
                    placeholder="No encryption"
                    isDisabled={disabled}
                    size="compact"
                    isClearable
                    {...props}
                />
            )}
        />
    );
}
