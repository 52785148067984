import { Component } from 'react';
import * as PropTypes from 'prop-types';
import BEM, { bemgen } from 'lib/bem';

import { Button, Connector, LabeledContainer, Input } from '@schibsted-svp/react-ui';
import { FaSearch } from 'react-icons/fa';
import './VideoSearch.scss';
import OrderDropdown from 'components/core/OrderDropdown';
import { FiltersDialog } from 'components/core/FiltersDialog';

const bem = new BEM('VideoSearch');

class VideoSearch extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        provider: PropTypes.string.isRequired,
        nextSearch: PropTypes.func.isRequired,
        sortType: PropTypes.object.isRequired,
        selectedFilters: PropTypes.object.isRequired,
        query: PropTypes.string,
        assetType: PropTypes.string,
        disableNextVideoRhfCheckbox: PropTypes.node,
        isUserProvider: PropTypes.bool,
        isAssociateProvider: PropTypes.bool,
    };

    static defaultProps = {
        query: '',
        assetType: 'video',
        disableNextVideoRhfCheckbox: undefined,
        isUserProvider: false,
        isAssociateProvider: false,
    };

    state = {
        query: this.props.query,
        sortType: this.props.sortType,
        selectedFilters: this.props.selectedFilters,
    };

    handleQueryChange = (event) => {
        const { value: query } = event.target;

        this.setState(() => ({ query }));
    };

    handleQueryKeyPress = (event) => {
        if (event.key !== 'Enter') {
            return;
        }

        event.preventDefault();

        this.search();
    };

    handleSearchClick = () => {
        this.search();
    };

    handleOrderChange = (sortType) => {
        this.setState({ sortType }, () => this.search());
    };

    handleFiltersChange = (selectedFilters) => {
        this.setState({ selectedFilters }, () => this.search());
    };

    search = () => {
        const { id, nextSearch, provider } = this.props;
        const { query, selectedFilters, sortType } = this.state;
        nextSearch({ provider, id, query, selectedFilters, sortType });
    };

    render() {
        // eslint-disable-next-line react/prop-types
        const {
            id,
            provider,
            nextSearch,
            selectedFilters,
            sortType,
            assetType,
            disableNextVideoRhfCheckbox,
            isUserProvider,
            isAssociateProvider,
            ...props
        } = this.props;
        const { query } = this.state;

        return (
            <LabeledContainer {...props} strict className={bem.block(undefined, props.className)} label="Find next">
                <Connector className={bem.element('container')}>
                    <Input
                        className={bem.element('input')}
                        onChange={this.handleQueryChange}
                        onKeyPress={this.handleQueryKeyPress}
                        placeholder="Search by title or ID"
                        size="small"
                        value={query}
                    />
                    <Button
                        data-testid="nextVideoSearchSubmitButton"
                        iconOnly
                        className={bem.element('button')}
                        onClick={this.handleSearchClick}
                        size="small"
                        type="button"
                        variant="standard"
                    >
                        <FaSearch />
                    </Button>
                    <OrderDropdown
                        size="small"
                        selectedSort={sortType}
                        onChange={this.handleOrderChange}
                        className={bem.element('order')}
                        menuGap={14}
                        menuArrowPosition={125}
                    />
                    <FiltersDialog
                        provider={provider}
                        containerClassName={bemgen('NextVideoFiltersButton')}
                        onFilter={this.handleFiltersChange}
                        stopFormPropagation
                        hideStatusField={!isUserProvider}
                        dialogTrigger={(onClick) => (
                            <Button onClick={onClick} type="button" size="small" variant="standard">
                                Filters
                            </Button>
                        )}
                        initialValues={selectedFilters}
                        {...props}
                    />
                    {disableNextVideoRhfCheckbox}
                </Connector>
            </LabeledContainer>
        );
    }
}

export default VideoSearch;
