import classnames from 'classnames/bind';
import { memo, useLayoutEffect, useRef, useState } from 'react';

import type { Maybe } from 'types';
import { formatRemainingExactTime, formatRemainingTime } from 'lib/time';
import type { Thumbnail } from 'services/player-api-client/thumbnails';

import { toCSSPercent } from './helpers';
import css from './Timeline.module.scss';

const cln = classnames.bind(css);

const INTERSECTION_OBSERVER_OPTIONS: IntersectionObserverInit = {
    threshold: 1,
};

interface TimelineProps {
    duration: number;
    thumbnails: Thumbnail[];
}

export const Timeline = memo(({ duration, thumbnails }: TimelineProps) => {
    const [intersecting, setIntersecting] = useState(false);
    const timelineRef = useRef<Maybe<HTMLDivElement>>(null);
    const secondsInFirstThumbnail = thumbnails.at(1)?.startTime ?? 0;
    const secondsInLastThumbnail = duration % secondsInFirstThumbnail;
    const lastThumbnailWidth = secondsInLastThumbnail / duration;

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(([{ isIntersecting }]) => {
            setIntersecting(isIntersecting);
        }, INTERSECTION_OBSERVER_OPTIONS);

        if (timelineRef.current) {
            observer.observe(timelineRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className={cln(css.container, { intersecting })} ref={timelineRef}>
            {thumbnails.map(({ startTime }, index, { length }) => (
                <span
                    key={`preview-editor:timeline:${startTime}`}
                    className={css.time}
                    style={index + 1 === length ? { maxWidth: toCSSPercent(lastThumbnailWidth) } : undefined}
                >
                    {Number.isInteger(secondsInFirstThumbnail)
                        ? formatRemainingTime(startTime)
                        : formatRemainingExactTime(startTime)}
                </span>
            ))}
        </div>
    );
});

Timeline.displayName = 'Timeline';
