import { FocusEvent } from 'react';
import { useController } from 'react-hook-form';
import { Input, LabeledContainer, Select } from '@schibsted-svp/react-ui';
import { getValueFromOption, findSingleOptionInOptionsGroup } from 'components/ui/Form/Select';
import type { GetMediaLiveInputsQuery } from 'services/admin-bff-sdk/generated';
import { getInputUrl, getVideoKey } from 'components/video/live/MediaLiveInputInfoDialog/MediaLiveInputInfoDialog';
import { MediaLivePushInfo } from './MediaLivePushInfo';
import { useMediaLiveInputFormContext } from './hooks';
import css from './MediaLiveReservedInputsSelect.module.scss';

type MediaLiveInput = GetMediaLiveInputsQuery['mediaLiveInputs'][number];

const handleFocus = (event: FocusEvent<HTMLInputElement>) => event.target.select();

const customOptionStyles = (
    provided: Record<string, string>,
    {
        data,
    }: {
        data: MediaLiveInputOption;
    }
) => {
    if (data.occupied) {
        return {
            ...provided,
            color: '#dfc552 !important',
            fontStyle: 'italic',
        };
    }
    return provided;
};

const isMediaLiveInputOccupied = (input: MediaLiveInput): boolean => {
    return Boolean(input.channel?.asset);
};

const getMediaLiveInputOptionLabel = (input: MediaLiveInput): string => {
    if (isMediaLiveInputOccupied(input)) {
        return `${input.name} (occupied: ${input.channel.asset.id})`;
    }
    return input.name;
};

type MediaLiveInputOption = {
    value: string;
    label: string;
    occupied: boolean;
};

const getMediaLiveInputOptions = (inputs: MediaLiveInput[]): MediaLiveInputOption[] => {
    return [
        ...inputs.map((input) => ({
            label: getMediaLiveInputOptionLabel(input),
            value: input.id,
            occupied: isMediaLiveInputOccupied(input),
        })),
        { label: 'Generate new key', value: '', occupied: false },
    ];
};

type MediaLiveReservedInputsSelectProps = {
    inputs: MediaLiveInput[];
};

export function MediaLiveReservedInputsSelect({ inputs }: MediaLiveReservedInputsSelectProps) {
    const { formApi } = useMediaLiveInputFormContext();
    const { control } = formApi;
    const { field } = useController({ name: 'inputId', control, shouldUnregister: true, defaultValue: '' });

    const options = getMediaLiveInputOptions(inputs);

    const valueToInputData = inputs.find((input) => input.id === field.value);

    return (
        <LabeledContainer label="inputs">
            <Select
                {...field}
                value={findSingleOptionInOptionsGroup(options)(field.value)}
                onChange={(value) => field.onChange(getValueFromOption(value))}
                options={options}
                styles={{ option: customOptionStyles }}
                placeholder="Choose input"
            />
            {!field.value ? (
                <MediaLivePushInfo />
            ) : (
                <div className={css.container}>
                    <LabeledContainer label="Network Input URL">
                        <Input value={getInputUrl(valueToInputData?.urls[0])} onFocus={handleFocus} readOnly />
                    </LabeledContainer>
                    <LabeledContainer label="Video key">
                        <Input value={getVideoKey(valueToInputData?.urls[0])} onFocus={handleFocus} readOnly />
                    </LabeledContainer>
                </div>
            )}
        </LabeledContainer>
    );
}
