import config from 'config';

import Client from './client';
import * as thumbnails from './player-api-client/thumbnails';

const playeraApiClient = new Client({ baseUrl: config.player.options.thumbnailsUrl });

Object.entries({ ...thumbnails }).forEach(([k, v]) => {
    if (typeof v === 'function') {
        playeraApiClient[k] = v.bind(playeraApiClient);
    }
});

export default playeraApiClient;
