import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { withNewsroom } from 'components/NewsroomsList/with-newsroom';
import { getVideoList, getSearchTerm } from 'store/video/navigation-tabs/selectors';

import NewsroomTabContainer from './NewsroomTabContainer';

const enhance = compose(
    withNewsroom,
    connect((state) => ({
        videoList: getVideoList(state),
        searchTerm: getSearchTerm(state),
    }))
);

export default enhance(NewsroomTabContainer);
