import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const getAssetFormUi = (state) => state.ui.assetForm;

const getTagSuggestionsData = (state) => getAssetFormUi(state).tagSuggestions;

const getTagSuggestions = (state) => getTagSuggestionsData(state).items;

const getFormTags = (state) => get(state.form, 'asset.values.additional.tags');

const getTagSuggestionsWithoutFormTags = createSelector(getTagSuggestions, getFormTags, (suggestions, tags = []) =>
    suggestions.filter(({ value }) => !tags.includes(value))
);

const getRelatedData = (state) => getAssetFormUi(state).related;

const getSearchData = (state, provider, id) => get(getAssetFormUi(state), `search.${provider}.${id}`);

export function isCustomPreviewUploadDialogOpen(state, { provider, assetId }) {
    return getAssetFormUi(state)?.customPreviewUploadDialog[provider]?.[assetId]?.isOpen ?? false;
}

export {
    getAssetFormUi,
    getTagSuggestionsData,
    getTagSuggestions,
    getFormTags,
    getTagSuggestionsWithoutFormTags,
    getRelatedData,
    getSearchData,
};
