import { call, put, takeEvery } from '@redux-saga/core/effects';
import { Notification } from '@schibsted-svp/react-ui';
import { reportMessageToSentry } from 'lib/error';
import { adminBffClient } from 'services';
import { DEFERRED } from '../deferred';
import { SCHEDULES_SCOPE } from './constants';
import {
    SCHEDULES_FETCH,
    SCHEDULE_CREATE,
    SCHEDULE_UPDATE,
    SCHEDULE_DELETE,
    schedulesFetchSuccess,
    schedulesFetchError,
    scheduleCreateSuccess,
    scheduleUpdateSuccess,
    scheduleDeleteSuccess,
} from './actions';

export function* schedulesFetch({ payload: { provider, assetId, scope, action } }) {
    try {
        let schedules = [];
        if (scope === SCHEDULES_SCOPE.NOTIFICATION) {
            schedules = yield call(adminBffClient.fetchNotificationSchedules, { provider, assetId, action });
        } else {
            schedules = yield call(adminBffClient.fetchAssetSchedules, { provider, assetId, action });
        }
        yield put(schedulesFetchSuccess({ provider, scope, schedules }));
    } catch (error) {
        const message = 'Failed to fetch schedules';
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        yield put(schedulesFetchError({ provider, scope }));
        Notification.notify.error(message);
    }
}

export function* scheduleCreate({
    payload: { provider, assetId, scope, action, time, notificationBody },
    [DEFERRED]: deferred,
}) {
    try {
        let schedule = null;
        if (scope === SCHEDULES_SCOPE.NOTIFICATION) {
            schedule = yield call(adminBffClient.createNotificationSchedule, {
                provider,
                assetId,
                action,
                time,
                notificationBody,
            });
        } else {
            schedule = yield call(adminBffClient.createAssetSchedule, { provider, assetId, action, time });
        }
        yield put(scheduleCreateSuccess({ provider, assetId, scope, schedule }));
        deferred(schedule);
    } catch (error) {
        const message = 'Failed to create schedule';
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        Notification.notify.error(message);
        deferred(error);
    }
}

export function* scheduleUpdate({
    payload: { provider, assetId, scope, action, time, notificationBody },
    [DEFERRED]: deferred,
}) {
    try {
        let schedule = null;
        if (scope === SCHEDULES_SCOPE.NOTIFICATION) {
            schedule = yield call(adminBffClient.updateNotificationSchedule, {
                provider,
                assetId,
                action,
                time,
                notificationBody,
            });
        } else {
            schedule = yield call(adminBffClient.updateAssetSchedule, { provider, assetId, action, time });
        }
        yield put(scheduleUpdateSuccess({ provider, assetId, scope, schedule }));
        deferred(schedule);
    } catch (error) {
        const message = 'Failed to update schedule';
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        Notification.notify.error(message);
        deferred(error);
    }
}

export function* scheduleDelete({ payload: { provider, assetId, scope, action }, [DEFERRED]: deferred }) {
    try {
        let success = false;
        if (scope === SCHEDULES_SCOPE.NOTIFICATION) {
            success = yield call(adminBffClient.deleteNotificationSchedule, { provider, assetId, action });
        } else {
            success = yield call(adminBffClient.deleteAssetSchedule, { provider, assetId, action });
        }
        if (success) {
            yield put(scheduleDeleteSuccess({ provider, assetId, scope, action }));
        }
        deferred();
    } catch (error) {
        const message = 'Failed to delete schedule';
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        Notification.notify.error(message);
        deferred(error);
    }
}

export default [
    takeEvery(SCHEDULES_FETCH, schedulesFetch),
    takeEvery(SCHEDULE_CREATE, scheduleCreate),
    takeEvery(SCHEDULE_UPDATE, scheduleUpdate),
    takeEvery(SCHEDULE_DELETE, scheduleDelete),
];
