import { createRef, Component } from 'react';
import * as PropTypes from 'prop-types';
import { debounce, mapValues } from 'lodash';

import { CopyToClipboard, LabeledContainer, Select, Checkbox, Input } from '@schibsted-svp/react-ui';
import { secondsToTime } from 'lib/time';
import classnames from 'classnames/bind';
import config from 'config';
import { TimeInput } from 'components/ui/Form/TimeInput';
import { isLive } from 'models/asset';
import EmbedPreviewIframe from '../EmbedPreviewIframe';

import css from './EmbedForm.module.scss';

const cln = classnames.bind(css);

const embedScriptId = 'embed-player';

const maxPreviewPlayerSize = { width: 768, height: 432 };

const defaultPlayerSizeOptions = [
    { value: { width: 960, height: 540 }, label: '960 x 540' },
    { value: { width: 640, height: 360 }, label: '640 x 360' },
    { value: { width: 480, height: 270 }, label: '480 x 270' },
    { value: { width: '100%', height: '100%' }, label: 'Responsive' },
];

export default class EmbedForm extends Component {
    static propTypes = {
        asset: PropTypes.object.isRequired,
        provider: PropTypes.string.isRequired,
        startTime: PropTypes.number,
        onEmbedCodeUpdated: PropTypes.func.isRequired,
    };

    static defaultProps = {
        startTime: undefined,
    };

    playerSizeOptions = {
        audio: [{ value: { width: '100%', height: 248 }, label: 'Audio' }, ...defaultPlayerSizeOptions],
        video: [...defaultPlayerSizeOptions],
    };

    embedOptions = {
        iframe: { label: 'Iframe', defaultValue: false, reloadPreview: false },
        disableAds: { label: 'Disable ads', defaultValue: false },
        playNext: { label: 'Play next', defaultValue: true },
        loop: { label: 'Loop', defaultValue: false },
        ...(isLive(this.props.asset) && { dvr: { label: 'Dvr', defaultValue: true } }),
        showPlayerControls: {
            label: 'Show player controls',
            defaultValue: true,
            isDisabled: () => this.isAudioSizeSelected,
        },
        sharing: { label: 'Sharing', defaultValue: true },
        autoplay: { label: 'Autoplay', defaultValue: false },
        videoPreview: { label: 'Video preview', defaultValue: false },
        startAt: { label: 'Start at', defaultValue: false },
    };

    state = {
        embedCode: '',
        selectedPlayerSize: this.playerSizeOptions[this.props.asset.assetType][0],
        startTime: this.props.startTime || 0,
        options: {
            ...mapValues(this.embedOptions, 'defaultValue'),
            ...(this.props.startTime !== undefined ? { startAt: true } : {}),
        },
        embedUrl: false,
    };

    previewIframeRef = createRef();

    refreshEmbedCodeDebounced = debounce(() => this.refreshEmbedCode(), 500);

    componentDidMount() {
        this.appendEmbedScript();
        this.refreshEmbedCode();
    }

    get isAudioSizeSelected() {
        return this.state.selectedPlayerSize === this.playerSizeOptions.audio[0];
    }

    makeChangeOption = (name) => () => {
        this.setState(
            ({ options }) => ({
                options: {
                    ...options,
                    [name]: !options[name],
                },
            }),
            () => this.refreshEmbedCode(this.embedOptions[name].reloadPreview !== false)
        );
    };

    setStartTime = (startTime) => {
        this.setState(
            ({ options }) => ({
                startTime,
                options: { ...options, startAt: true },
            }),
            this.refreshEmbedCodeDebounced
        );
    };

    changePlayerSize = (playerSize) => {
        this.setState({ selectedPlayerSize: playerSize }, () => this.refreshEmbedCode());
    };

    refreshPreviewIframe() {
        const { data } = this.generateEmbedConfig(true);
        this.previewIframeRef.current.refresh(`${config.embed.iframeUrl}?params=${data}`);
    }

    refreshEmbedCode(refreshPreviewIframe = true) {
        this.setState(
            () => ({
                embedCode: this.generateEmbedCode(),
                embedUrl: this.generateEmbedUrl(),
            }),
            () => {
                if (refreshPreviewIframe) this.refreshPreviewIframe();
                this.props.onEmbedCodeUpdated(this.state.embedCode);
            }
        );
    }

    appendEmbedScript() {
        const script = document.querySelector(`#${embedScriptId}`);
        if (script) return;

        this.playerScript = document.createElement('script');
        this.playerScript.id = embedScriptId;
        this.playerScript.src = config.embed.scriptUrl;
        this.playerScript.async = true;
        document.body.append(this.playerScript);
    }

    generateEmbedConfig(preview = false) {
        const {
            selectedPlayerSize,
            startTime,
            options: { disableAds, playNext, autoplay, showPlayerControls, sharing, startAt, loop, dvr, videoPreview },
        } = this.state;

        const { asset, provider } = this.props;
        let { width, height } = selectedPlayerSize.value;

        if (preview && (parseInt(width, 10) > maxPreviewPlayerSize.width || width.toString().includes('%'))) {
            ({ width } = maxPreviewPlayerSize);
        }
        if (preview && (parseInt(height, 10) > maxPreviewPlayerSize.height || height.toString().includes('%'))) {
            ({ height } = maxPreviewPlayerSize);
        }

        const data = {
            id: asset.id,
            provider,
            ...(preview ? { pluginTracking: false } : {}),
            ...(this.isAudioSizeSelected ? { height } : {}),
            ...(showPlayerControls || this.isAudioSizeSelected ? {} : { noui: true }),
            ...(sharing ? {} : { sharing: false }),
            ...(autoplay ? { autoplayViewable: true } : {}),
            ...(loop ? { repeat: true } : {}),
            ...(dvr || !isLive(asset) ? {} : { dvr: false }),
            ...(startAt ? { time: secondsToTime(startTime) } : {}),
            ...(disableAds || preview ? { settings: { na: true } } : {}),
            ...(playNext ? {} : { recommended: { next: false } }),
            ...(videoPreview ? { videoPreview: true } : {}),
        };

        const style = {
            width: Number.isInteger(width) ? `${width}px` : width,
            height: Number.isInteger(height) ? `${height}px` : height,
        };

        return { data: encodeURIComponent(JSON.stringify(data)), style };
    }

    generateEmbedCode() {
        const { iframe } = this.state.options;
        const { data, style } = this.generateEmbedConfig();
        const styleString = Object.entries(style)
            .map(([key, value]) => `${key}:${value}`)
            .join(';');

        if (iframe) {
            return `<iframe src="${this.state.embedUrl}" style="${styleString};border:0" frameborder="0" allowfullscreen></iframe>`;
        }

        return `<div data-svp-asset="${data}" style="${styleString}"></div><script src="${config.embed.scriptUrl}"></script>`;
    }

    generateEmbedUrl() {
        const {
            asset: { id },
            provider,
        } = this.props;
        const {
            startTime,
            options: { disableAds, playNext, autoplay, showPlayerControls, sharing, startAt, loop, videoPreview },
        } = this.state;

        const searchParams = new URLSearchParams();
        searchParams.append('id', id);
        searchParams.append('provider', provider);
        if (disableAds) {
            searchParams.append('noads', true);
        }
        if (loop) {
            searchParams.append('loop', true);
        }
        if (!(showPlayerControls || this.isAudioSizeSelected)) {
            searchParams.append('noui', true);
        }
        if (!sharing) {
            searchParams.append('sharing', false);
        }
        if (autoplay) {
            searchParams.append('play', true);
        }
        if (startAt) {
            searchParams.append('start', startTime);
        }
        if (!playNext) {
            searchParams.append('next', false);
        }
        if (videoPreview) {
            searchParams.append('videoPreview', true);
        }
        return `${config.embed.iframeUrl}?${searchParams.toString()}`;
    }

    render() {
        const {
            asset: { assetType },
        } = this.props;
        const { options, startTime, selectedPlayerSize, embedCode, embedUrl } = this.state;

        const embedConfig = this.generateEmbedConfig(true);

        const embedOptions = Object.entries(this.embedOptions).map(([key, option]) => {
            const disabled = option.isDisabled?.();
            return (
                <Checkbox
                    key={key}
                    name={key}
                    label={option.label}
                    checked={disabled ? option.defaultValue : options[key]}
                    onChange={this.makeChangeOption(key)}
                    disabled={disabled}
                />
            );
        });

        return (
            <div className={cln('EmbedForm')}>
                <EmbedPreviewIframe ref={this.previewIframeRef} style={embedConfig.style} />
                <LabeledContainer label="Player size" className={cln('EmbedForm__playerSize')}>
                    <Select
                        name="playerSize"
                        value={selectedPlayerSize}
                        onChange={this.changePlayerSize}
                        options={this.playerSizeOptions[assetType]}
                    />
                    <div className={cln('EmbedForm__embedCode')}>
                        <Input
                            type="textarea"
                            name="embedCode"
                            value={embedCode}
                            onChange={() => {}}
                            onFocus={(event) => event.target.select()}
                            readOnly
                        />
                    </div>
                </LabeledContainer>
                <LabeledContainer label="Embed options" className={cln('EmbedForm__options')}>
                    <div className={cln('EmbedForm__optionsWrapper')}>
                        {embedOptions}
                        <TimeInput
                            name="startTime"
                            disabled={!options.startAt}
                            value={startTime}
                            className={cln('EmbedForm__startTime')}
                            onValueChange={this.setStartTime}
                        />
                    </div>
                </LabeledContainer>
                <LabeledContainer label="Embed url" className={cln('EmbedForm__embedUrl')}>
                    {embedUrl && (
                        <CopyToClipboard
                            text={embedUrl}
                            title="Click to copy embed url"
                            onCopyMessage="Embed url copied to clipboard"
                        />
                    )}
                </LabeledContainer>
            </div>
        );
    }
}
