export type Option = {
    value: string;
    label: string;
};

interface GroupOption<OptionType extends Option> {
    label: string;
    options: OptionType[];
}

export const findSingleOption =
    <OptionType extends Option>(options: readonly OptionType[]) =>
    (value: OptionType['value']) => {
        return options?.find((option) => option.value === value);
    };

export const findSingleOptionWithDefaultValue =
    <OptionType extends Option>(options: readonly OptionType[], defaultValue: OptionType['value']) =>
    (value: OptionType['value']) =>
        findSingleOption(options)(value || defaultValue);

export const findMultipleOptions =
    <OptionType extends Option>(options: OptionType[]) =>
    (values: OptionType['value'][]) =>
        values?.map(findSingleOption(options));

export const getValueFromOption = <OptionType extends Option>(option: OptionType): OptionType['value'] => {
    return option && option.value;
};

export const getValuesFromOptionsArray = <OptionType extends Option>(
    options: readonly OptionType[]
): OptionType['value'][] => options && options.map(getValueFromOption);

export const objectToOptions = (object: Record<string, string>, swap = false) =>
    Object.entries(object).map(([label, value]) => (swap ? { label: value, value: label } : { label, value }));

export const findSingleOptionInOptionsGroup =
    <OptionType extends Option>(availableOptions: OptionType[] | GroupOption<OptionType>[]) =>
    (value: OptionType['value']) => {
        if ((availableOptions[0] as GroupOption<OptionType>)?.options) {
            const allOptions = (availableOptions as GroupOption<OptionType>[]).flatMap(({ options }) => options);

            return findSingleOption(allOptions)(value);
        }

        return findSingleOption(availableOptions as OptionType[])(value);
    };
