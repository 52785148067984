import { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import './InlineStatusFilters.scss';
import { bemgen } from 'lib/bem';
import config from 'config';
import { objectToOptions, findSingleOption } from 'components/ui/Form/Select';

const filters = objectToOptions(config.asset.labels.status, true);

class InlineStatusFilters extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        activeFilters: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        activeFilters: [],
    };

    onClick = (filter) => {
        const { onChange } = this.props;
        const filterVal = filter.currentTarget.dataset.value;
        const selected = findSingleOption(filters)(filterVal);

        onChange({ statuses: selected ? [selected] : filters });
    };

    getActiveFilters = () => {
        const { activeFilters } = this.props;
        return activeFilters.reduce((acc, { value }) => {
            const active = filters.find((filter) => filter.value === value);
            if (active) {
                acc.push(value);
            }
            return acc;
        }, []);
    };

    render() {
        const activeFilters = this.getActiveFilters();
        const allActive = activeFilters.length === 0 || activeFilters.length === filters.length;

        const items = filters.map(({ value, label }) => (
            <li
                key={value}
                data-value={value}
                className={bemgen('InlineStatusFilters__element', {
                    active: !allActive && activeFilters.includes(value),
                })}
                onClick={this.onClick}
                role="presentation"
            >
                {label}
            </li>
        ));

        return (
            <ul className={bemgen('InlineStatusFilters')} data-testid="inlineStatusFilters">
                <li
                    data-value="all"
                    className={bemgen('InlineStatusFilters__element', {
                        active: allActive,
                    })}
                    onClick={this.onClick}
                    role="presentation"
                >
                    all
                </li>
                {items}
            </ul>
        );
    }
}

export default InlineStatusFilters;
