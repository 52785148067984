import get from 'lodash/get';
import omit from 'lodash/omit';
import { merge } from 'lib/object';
import { REMOVE_NAVIGATION_TAB } from 'store/video/navigation-tabs/actions';
import {
    TRANSCODE_VIDEO,
    TRANSCODE_VIDEO_COMPLETE,
    TRANSCODE_VIDEO_FAILURE,
    TRANSCODE_VIDEO_PROGRESS,
    UPLOAD_VIDEO,
    UPLOAD_VIDEO_ERROR,
    UPLOAD_VIDEO_PROGRESS,
    UPLOAD_VIDEO_POSTER,
    UPLOAD_VIDEO_POSTER_COMPLETE,
    UPLOAD_VIDEO_POSTER_ERROR,
    REVOKE_VIDEO_POSTER_PREVIEW,
    UPLOAD_CANCEL,
} from './actions';

/**
 * @enum {String}
 */
export const UPLOAD_STATE = Object.freeze({
    TRANSCODE: 'transcode',
    UPLOAD: 'upload',
    DONE: 'done',
    ERROR: 'error',
    UPLOAD_CANCELED: 'upload_canceled',
});

const initialState = {
    errors: {},
};

function revokeObjectUrl(state, { provider, id }) {
    const objectUrl = get(state, `${provider}.${id}.poster.objectUrl`);

    return objectUrl && URL.revokeObjectURL(objectUrl);
}

function createObjectUrl(state, action) {
    revokeObjectUrl(state, action);

    return URL.createObjectURL(action.file);
}

export default (state = initialState, action) => {
    const id = action.id || (action.payload || {}).id;
    const provider = action.provider || (action.payload || {}).provider;

    switch (action.type) {
        case UPLOAD_VIDEO_POSTER: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        poster: {
                            state: UPLOAD_STATE.UPLOAD,
                            fileName: null,
                            objectUrl: createObjectUrl(state, action),
                            error: null,
                        },
                    },
                },
            });
        }
        case UPLOAD_VIDEO_POSTER_ERROR: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        poster: {
                            state: UPLOAD_STATE.ERROR,
                            fileName: action.file ? action.file.name : null,
                            error: action.error,
                        },
                    },
                },
            });
        }
        case UPLOAD_VIDEO_POSTER_COMPLETE: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        poster: {
                            state: UPLOAD_STATE.DONE,
                            uri: action.uri,
                        },
                    },
                },
            });
        }
        case REVOKE_VIDEO_POSTER_PREVIEW: {
            if (!state?.[provider]?.[id]?.poster) {
                return state;
            }

            revokeObjectUrl(state, action);
            return merge(state, {
                [provider]: {
                    [id]: {
                        poster: {
                            uri: undefined,
                            objectUrl: undefined,
                        },
                    },
                },
            });
        }
        case UPLOAD_VIDEO: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        video: {
                            state: UPLOAD_STATE.UPLOAD,
                            progress: 0,
                        },
                    },
                },
            });
        }
        case UPLOAD_VIDEO_ERROR: {
            return merge(state, {
                errors: {
                    [provider]: {
                        [id]: [
                            {
                                fileName: action.file.name,
                                error: action.error,
                                state: UPLOAD_STATE.UPLOAD,
                            },
                        ],
                    },
                },
            });
        }
        case UPLOAD_VIDEO_PROGRESS: {
            const { progress, secondsLeft, filename } = action;

            if (state?.[provider]?.[id]?.video?.state === UPLOAD_STATE.UPLOAD_CANCELED) {
                return state;
            }

            return merge(state, {
                [provider]: {
                    [id]: {
                        video: {
                            state: UPLOAD_STATE.UPLOAD,
                            progress,
                            secondsLeft,
                            filename,
                        },
                    },
                },
            });
        }
        case UPLOAD_CANCEL: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        video: {
                            state: UPLOAD_STATE.UPLOAD_CANCELED,
                        },
                    },
                },
            });
        }
        case TRANSCODE_VIDEO_PROGRESS: {
            const { progress } = action;

            return merge(state, {
                [provider]: {
                    [id]: {
                        video: {
                            state: UPLOAD_STATE.TRANSCODE,
                            progress,
                        },
                    },
                },
            });
        }
        case TRANSCODE_VIDEO: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        video: {
                            state: UPLOAD_STATE.TRANSCODE,
                            progress: 0,
                        },
                    },
                },
            });
        }
        case TRANSCODE_VIDEO_COMPLETE: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        video: {
                            state: UPLOAD_STATE.DONE,
                        },
                    },
                },
            });
        }
        case TRANSCODE_VIDEO_FAILURE: {
            return merge(state, {
                [provider]: {
                    [id]: {
                        video: {
                            state: UPLOAD_STATE.ERROR,
                        },
                    },
                },
                errors: {
                    [provider]: {
                        [id]: [
                            {
                                error: action.error,
                                state: 'transcoding',
                            },
                        ],
                    },
                },
            });
        }

        case REMOVE_NAVIGATION_TAB: {
            const assetState = state?.[provider]?.[id];
            if (assetState) {
                return {
                    ...state,
                    [provider]: omit(state[provider], id),
                    errors: {
                        [provider]: omit(state.errors[provider], id),
                    },
                };
            }
            return state;
        }

        default:
            return state;
    }
};
