import classnames from 'classnames/bind';
import type { PropsWithChildren } from 'react';
import css from './Tabs.module.scss';

const cln = classnames.bind(css);

interface TabsProps {
    className?: string;
}

export function Tabs({ children, className }: PropsWithChildren<TabsProps>) {
    return <div className={cln(css.container, className)}>{children}</div>;
}
