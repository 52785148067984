import { Initials, Divider } from '@schibsted-svp/react-ui';
import { getFullUsernameFromEmail } from 'lib/email';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { EntryPageProps } from './types';
import css from './EntryPage.module.scss';

export function EntryPage({ handleLogin, email, setShowForm }: EntryPageProps) {
    return (
        <div>
            <p className={css.instruction}>Choose an account to log in </p>
            <div className={css.userCard}>
                <span className={css.knownUser} onClick={handleLogin}>
                    <Initials as="button" size="big" title={email} className={css.initials}>
                        {email}
                    </Initials>

                    <span className={css.username}>{getFullUsernameFromEmail(email)}</span>
                    <span className={css.email}>{email}</span>
                </span>
                <Divider margin="0 30px" />
                <span className={css.anotherAccount} onClick={() => setShowForm(true)}>
                    <HiOutlineUserCircle className={css.defaultAvatar} size={52} />
                    <div className={css.text}>Log in as somebody else</div>
                </span>
            </div>
        </div>
    );
}
