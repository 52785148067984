import type { Asset } from '@schibsted-svp/svp-api-types';
import { useAssetPodmeMetadataQuery } from 'services/admin-bff-sdk/generated';

export function useSendToPodme({
    provider,
    assetId,
}: {
    provider: Asset['provider'];
    assetId: Asset['id'];
}): boolean | undefined {
    const { data: { assetPodmeMetadata } = {} } = useAssetPodmeMetadataQuery({ provider, assetId });
    return assetPodmeMetadata?.sendToPodme;
}
