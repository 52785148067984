import Validator from 'validatorjs';

export default function validator(data, attributes) {
    const { duration, excluded = [] } = attributes;

    const rules = {
        title: 'required|min:3',
        startTime: `between:0,${duration}|not_in:${excluded.join(',')}`,
    };

    const messages = {
        'required.title': 'Title is required',
        'between.startTime': "The start time can't be grater than the video duration",
        'not_in.startTime': 'The provided start time point is already added',
    };

    return new Validator(data, rules, messages);
}
