import { merge } from 'lib/object';
import {
    CANCEL_LIVE_AD_FAILURE,
    CANCEL_LIVE_AD_REQUEST,
    CANCEL_LIVE_AD_SUCCESS,
    INSERT_LIVE_AD_COMPLETE,
    INSERT_LIVE_AD_FAILURE,
    INSERT_LIVE_AD_REQUEST,
    INSERT_LIVE_AD_SUCCESS,
} from '../actions';

export const defaultEntityState = {
    emittedAt: undefined,
    processing: false,
    progress: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {String} action.newsroom
 * @param {Number} action.assetId
 * @returns {Object}
 */
export default function adsReducer(state = {}, { type, newsroom, assetId }) {
    switch (type) {
        case INSERT_LIVE_AD_REQUEST:
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...defaultEntityState,
                        processing: true,
                    },
                },
            });

        case INSERT_LIVE_AD_SUCCESS:
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        emittedAt: Math.round(Date.now() / 1000),
                        processing: false,
                        progress: true,
                    },
                },
            });

        case CANCEL_LIVE_AD_REQUEST:
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        processing: true,
                    },
                },
            });

        case INSERT_LIVE_AD_FAILURE:
        case CANCEL_LIVE_AD_FAILURE:
        case CANCEL_LIVE_AD_SUCCESS:
        case INSERT_LIVE_AD_COMPLETE:
            return merge(state, {
                [newsroom]: {
                    [assetId]: {
                        ...defaultEntityState,
                    },
                },
            });

        default:
            return state;
    }
}
