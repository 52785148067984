import { TimelineTooltip } from './TimelineTooltip';
import { useSubscribeTimelineState } from './hooks/useTimelineState';
import { getOffsetWithPadding } from './helpers';
import css from './TimelineMarker.module.scss';

export function TimelineMarker() {
    const offset = useSubscribeTimelineState((state) => state.offset);
    const pixelsPerSecond = useSubscribeTimelineState((state) => state.pixelsPerSecond);
    const scroll = useSubscribeTimelineState((state) => state.scroll);

    if (!offset) {
        return null;
    }

    const time = (offset + scroll) / pixelsPerSecond;
    return (
        <div className={css.marker} style={{ transform: `translateX(${getOffsetWithPadding(offset)}px)` }}>
            <TimelineTooltip marker time={time} offset={0} />
        </div>
    );
}
