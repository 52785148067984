import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';

const bem = new BEM('video-navigation');

function NavigationTabProgress({ variant, progress }) {
    return <div className={bem.element('tab-progress', { [variant]: true })} style={{ width: `${progress}%` }} />;
}

NavigationTabProgress.propTypes = {
    progress: PropTypes.number,
    variant: PropTypes.string,
};

NavigationTabProgress.defaultProps = {
    progress: 0,
    variant: 'default',
};

export default NavigationTabProgress;
