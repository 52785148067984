import { put, takeEvery } from '@redux-saga/core/effects';
import { matchMqttTopic } from 'store/mqtt/listeners/utils';
import { uploadCategoryPreviewSuccess } from 'store/uploads/previews/slice';

type CategoryMqttSagaType = { type: string; data: { provider: string; categoryId: number; promoUrl: string } };

function* categoryMqttSaga({ data }: CategoryMqttSagaType) {
    const { provider, categoryId, url } = data;
    yield put(uploadCategoryPreviewSuccess({ provider, categoryId, url }));
}

export default [takeEvery((action) => matchMqttTopic(action, 'category', 2), categoryMqttSaga)];
