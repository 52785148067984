import { useState } from 'react';
import { getNewsroomOptions } from 'store/newsroom/selectors';
import {
    CollapsibleContent,
    LabeledContainer,
    Select,
    Divider,
    Button,
    Input,
    Connector,
} from '@schibsted-svp/react-ui';
import { useSelector, useDispatch } from 'react-redux';
import type { UseFormGetValues } from 'react-hook-form';

import { staticVideosSearch, playlistsSearch } from 'store/playlists/staticItems';
import { FaSearch } from 'react-icons/fa';
import { LABELS } from 'screens/NewsroomScreen/PlaylistsScreen/utils';
import { FormValues } from './types';

import css from './StaticItemsForm.module.scss';

interface StaticItemsFormProps {
    provider: string;
    isRegularPlaylist: boolean;
    isFetching: boolean;
    getValues: UseFormGetValues<FormValues>;
}

type Newsroom = {
    value: string;
    label: string;
};
export const selectNewsrooms = (
    newsrooms: Newsroom[],
    currentNewsroom: Newsroom[],
    selectedNewsroom: Newsroom[],
    isRegularPlaylist?: boolean
) => {
    const valuesCleared = !newsrooms?.length;
    const allSelected = newsrooms?.[newsrooms.length - 1]?.value === 'all';
    const currentNewsroomCleared =
        valuesCleared && selectedNewsroom?.length === 1 && currentNewsroom[0].value === selectedNewsroom[0].value;

    if (isRegularPlaylist && (allSelected || currentNewsroomCleared)) {
        return [{ value: 'all', label: 'All newsrooms' }];
    }

    if (valuesCleared) {
        return currentNewsroom;
    }

    return newsrooms.filter(({ value }) => value !== 'all');
};

const joinProviders = (providers: Newsroom[], linkedProviders: string[], currentProvider: string) => {
    if (providers[0].value === 'all') {
        return [...linkedProviders, currentProvider].join(',');
    }
    return providers.map(({ value }) => value).join(',');
};

const getDefaultFiltersPerType = (type: string, isPodcastPlaylist: boolean) => {
    if (type === LABELS.AUDIO) {
        if (isPodcastPlaylist) {
            return {
                'additional.metadata.isPodcast': true,
                assetType: ['audio'],
            };
        }
        return { assetType: ['audio'] };
    }

    return { assetType: ['video'] };
};

export default function StaticItemsForm({ provider, isRegularPlaylist, isFetching, getValues }: StaticItemsFormProps) {
    const dispatch = useDispatch();
    const { currentNewsroomOption, allNewsroomsOptions, linkedProviders } = useSelector(getNewsroomOptions);

    const [selectedNewsroom, setSelectedNewsroom] = useState(currentNewsroomOption);
    const [searchTerm, setSearchTerm] = useState('');
    const { type, isPodcastPlaylist } = getValues();
    const handleOnChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const parameters = {
        limit: 5,
        filters: { status: ['active'], ...getDefaultFiltersPerType(type, isPodcastPlaylist) },
    };

    const handleOnSubmit = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (isRegularPlaylist) {
            dispatch(
                staticVideosSearch({
                    searchTerm,
                    provider,
                    searchQueryProviders: joinProviders(selectedNewsroom, linkedProviders, provider),
                    parameters,
                })
            );
        } else {
            dispatch(
                playlistsSearch({
                    searchTerm,
                    providers: joinProviders(selectedNewsroom, linkedProviders, provider).split(','),
                    originProvider: provider,
                })
            );
        }
    };

    const handleOnNewsroomChange = (newValue) => {
        const changedNewsrooms = selectNewsrooms(newValue, currentNewsroomOption, selectedNewsroom, isRegularPlaylist);

        if (
            changedNewsrooms.length === 1 &&
            selectedNewsroom.length === 1 &&
            changedNewsrooms[0].value === selectedNewsroom[0].value
        ) {
            return;
        }
        setSelectedNewsroom(changedNewsrooms);

        if (isRegularPlaylist) {
            dispatch(
                staticVideosSearch({
                    searchTerm,
                    provider,
                    searchQueryProviders: joinProviders(changedNewsrooms, linkedProviders, provider),
                    parameters,
                })
            );
        } else {
            dispatch(
                playlistsSearch({
                    searchTerm,
                    providers: joinProviders(changedNewsrooms, linkedProviders, provider).split(','),
                    originProvider: provider,
                })
            );
        }
    };

    return (
        <form onSubmit={handleOnSubmit}>
            <Connector className={css.findVideo}>
                <Input size="small" value={searchTerm} onChange={handleOnChange} placeholder="Search" />
                <Button
                    data-testid="search"
                    iconOnly
                    size="small"
                    type="submit"
                    variant="standard"
                    className={css.searchButton}
                    disabled={isFetching}
                >
                    <FaSearch color="white" />
                </Button>
            </Connector>
            <CollapsibleContent label="Filters" className={css.filtersContainer}>
                <LabeledContainer
                    className={css.filters}
                    label="Newsroom:"
                    variant="horizontal"
                    horizontalLabelSpace="small"
                    strict
                >
                    <Select
                        isMulti
                        size="small"
                        options={allNewsroomsOptions}
                        className={css.newsroomSelect}
                        value={selectedNewsroom}
                        onChange={handleOnNewsroomChange}
                    />
                </LabeledContainer>
            </CollapsibleContent>
            <Divider margin="0" />
        </form>
    );
}
