import Evaporate from 'evaporate';
import config from 'config';
import token from 'models/authToken';

const customAuthMethod = async (signParams, signHeaders, stringToSign) => {
    const url = new URL('/api/v1/aws/sign', config.api.host);
    url.searchParams.set('to_sign', decodeURIComponent(stringToSign));
    url.searchParams.set('token', token.get().raw);

    const response = await fetch(url);

    if (response.status === 200) {
        return response.text();
    }

    throw new Error(response.statusText);
};

export default class EvaporateFileUploader {
    static EVENT_PROGRESS = Symbol('progress');

    static EVENT_COMPLETE = Symbol('complete');

    static EVENT_ERROR = Symbol('error');

    /**
     * @param {Evaporate} evaporate
     * @param {Map} eventHandlers
     */
    constructor(evaporate, eventHandlers) {
        this.eventHandlers = eventHandlers;
        this.evaporate = evaporate;
    }

    /**
     * @returns {Promise<EvaporateFileUploader>}
     */
    static async create() {
        const evaporate = await Evaporate.create({
            ...config.evaporate,
            customAuthMethod,
            aws_key: config.aws.key,
            aws_url: `https://${config.aws.bucket}.s3.vgnett.no`,
            bucket: config.aws.bucket,
        });

        return new EvaporateFileUploader(evaporate, new Map());
    }

    /**
     * @param {Symbol} event
     * @param {Function} handler
     * @returns void
     */
    registerHandler(event, handler) {
        this.eventHandlers.set(event, handler);
    }

    /**
     * @param {Number} id
     * @param {File} file
     * @param {String} provider
     * @param {Object} rest
     * @returns {Promise<string>}
     */
    uploadFile({ id, file, provider, ...rest }) {
        const timestamp = new Date().valueOf();
        let completed = false;
        const filename = `${provider}${rest.categoryPreview ? '/category' : ''}/${id}/${timestamp}-${file.type.replace(
            '/',
            '-'
        )}`;
        return this.evaporate.add({
            progress: (progress, stats) => {
                if (completed) {
                    return;
                }

                const { secondsLeft } = stats;

                const eventHandler = this.eventHandlers.get(EvaporateFileUploader.EVENT_PROGRESS);
                eventHandler({
                    ...rest,
                    id,
                    file,
                    provider,
                    filename: `${config.aws.bucket}/${filename}`,
                    progress: Math.floor(progress * 100),
                    secondsLeft,
                });
            },
            complete: (xhr, awsObjectKey, stats) => {
                completed = true;
                const eventHandler = this.eventHandlers.get(EvaporateFileUploader.EVENT_COMPLETE);
                eventHandler({ ...rest, id, file, provider, object: awsObjectKey, stats });
            },
            error: (message) => {
                const eventHandler = this.eventHandlers.get(EvaporateFileUploader.EVENT_ERROR);
                eventHandler({ ...rest, id, file, provider, message });
            },
            name: filename,
            file,
        });
    }

    /**
     * @param {String} filename
     * @returns {Promise<undefined[]>}
     */
    cancelUploadFile(filename) {
        return this.evaporate.cancel(filename);
    }

    /**
     * @param {File} file
     * @param {String} name
     * @returns {Promise<string>}
     */
    uploadFileWithoutProgress({ file, name }) {
        return this.evaporate.add({
            name,
            file,
        });
    }
}
