import { Children } from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import BEM from 'lib/bem';
import { Spinner } from '@schibsted-svp/react-ui';
import './AvailableVideoList.scss';

const bem = new BEM('AvailableVideoList');

function AvailableVideoList({ children, className, loading, ...props }) {
    return (
        <div {...props} className={bem.block(undefined, className)}>
            {loading && <Spinner containerClassName={bem.element('spinner')} />}

            {!loading &&
                (Children.count(children) > 0 ? (
                    <ul className={bem.element('list')} data-testid="availableNextVideoList">
                        {Children.map(children, (child) => (
                            <li key={`available-videos:${child.key}`}>{child}</li>
                        ))}
                    </ul>
                ) : (
                    <p className={bem.element('empty')}>
                        <FaSearch size={30} />
                        No results to be shown
                    </p>
                ))}
        </div>
    );
}

AvailableVideoList.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    loading: PropTypes.bool,
};

AvailableVideoList.defaultProps = {
    children: null,
    className: undefined,
    loading: false,
};

export default AvailableVideoList;
