import { ReactNode, ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Radio } from '@schibsted-svp/react-ui';
import css from './RadioOption.module.scss';

interface RadioOptionProps {
    className: string;
    label: string;
    name: string;
    value: string;
    disabled?: boolean;
    children?: ReactNode;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function RadioOptionRHF({ className, children, label, name, value, disabled, onChange }: RadioOptionProps) {
    const { register } = useFormContext();
    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
        <label className={`${css.radio} ${className}`}>
            <Radio {...register(name, { disabled, onChange })} value={value} containerClassName={css.input} />
            <span className={css.label}>{label}</span>
            {children}
        </label>
    );
}

export function RadioOption({
    className,
    children,
    label,
    name,
    value,
    disabled,
    onChange,
    checked,
}: RadioOptionProps & { checked: boolean }) {
    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
        <label className={`${css.radio} ${className}`}>
            <Radio
                value={value}
                onChange={onChange}
                name={name}
                disabled={disabled}
                containerClassName={css.input}
                checked={checked}
            />
            <span className={css.label}>{label}</span>
            {children}
        </label>
    );
}
