import { Component } from 'react';
import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';
import { Spinner } from '@schibsted-svp/react-ui';

import config from 'config';
import Sprite from 'components/core/Sprite';

import './SnapshotsSource.scss';

const snapshotsConfig = config.images.snapshots;

const bem = {
    preview: new BEM('snapshots-source'),
};

class SnapshotsPreview extends Component {
    static propTypes = {
        // snapshots canvas
        snapshots: PropTypes.string.isRequired,
        // container element in which preview is rendered
        element: PropTypes.object.isRequired,
        hovered: PropTypes.bool.isRequired,
        duration: PropTypes.number.isRequired,
        position: PropTypes.number,
    };

    static defaultProps = {
        position: null,
    };

    constructor(props) {
        super(props);

        const { snapshots, position } = props;

        this.state = {
            loading: true,
        };

        // prepare the preview sprite to be loaded, including appropriate handlers
        this.spriteImg = new Image();
        this.spriteImg.addEventListener('load', this.onSpriteLoaded);

        // When position is defined then preload snapshots image immediately
        if (position !== -1) {
            this.state.loading = true;
            this.state.singleFrame = true;

            // fetch the sprite
            this.spriteImg.src = `${snapshots}?t[]=${snapshotsConfig.transformation}`;
        }
    }

    componentDidUpdate() {
        const { hovered, snapshots } = this.props;

        if (hovered === true && this.spriteImg.src === '') {
            this.spriteImg.src = `${snapshots}?t[]=${snapshotsConfig.transformation}`;
        }
    }

    componentWillUnmount() {
        if (this.spriteImg) {
            this.spriteImg.removeEventListener('load', this.onSpriteLoaded);
        }
    }

    onSpriteLoaded = () => {
        this.setState({ loading: false });
    };

    render() {
        const { spriteImg } = this;
        const { element, hovered, position, duration } = this.props;
        const { loading, singleFrame } = this.state;

        const sprite = loading ? (
            <Spinner size="compact" />
        ) : (
            <Sprite
                className={bem.preview.element('sprite')}
                image={spriteImg}
                dimensions={{ width: snapshotsConfig.width, height: snapshotsConfig.height }}
                rect={element && element.getBoundingClientRect()}
                expected={Math.min(parseInt(duration / snapshotsConfig.duration, 10) + 1, snapshotsConfig.max)}
                position={position}
            />
        );

        if (hovered || singleFrame === true) {
            return (
                <div className={bem.preview.block()}>
                    <div className={bem.preview.element('overlay')}>
                        <div className={bem.preview.element('preview', { loading })}>{sprite}</div>
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default SnapshotsPreview;
