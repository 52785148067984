/* eslint-disable no-nested-ternary */
import config from 'config';
import { adminBffRtkApi } from '../admin-bff-rtk-api';
import * as graphql from './graphql/origin.graphql';

interface ChangeAssetRestrictionsMutationArgs {
    provider: string;
    assetId: number;
    protections?: {
        geoRestriction?: boolean;
        accessLevel?: string;
    };
}

type SwedishNewsroom = (typeof config.swedishNewsrooms)[number];

const isProviderSwedishNewsroom = (provider: string): provider is SwedishNewsroom => {
    return config.swedishNewsrooms.includes(provider as SwedishNewsroom);
};

export const originApi = adminBffRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        changeAssetRestrictions: builder.mutation<boolean, ChangeAssetRestrictionsMutationArgs>({
            query: ({ provider, assetId, protections }) => ({
                document: graphql.changeAssetRestrictions,
                variables: {
                    provider,
                    assetId,
                    geoRestriction: protections?.geoRestriction
                        ? isProviderSwedishNewsroom(provider)
                            ? 'SWEDEN'
                            : 'NORWAY'
                        : null,
                    accessLevel: protections?.accessLevel?.toUpperCase() || null,
                },
            }),
            transformResponse: (response: { changeAssetRestrictions: boolean }) => response?.changeAssetRestrictions,
        }),
    }),
});

export const { useChangeAssetRestrictionsMutation } = originApi;
