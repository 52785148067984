import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';
import { Checkbox } from '@schibsted-svp/react-ui';
import { getLiveEncoderOptions } from 'store/live/selectors';
import { EncoderInputSelector } from '../EncoderInputSelector';
import { NetworkInput } from '../NetworkInput';
import * as validatorHelpers from '../../validators';
import { useEncoderInputFormContext } from '../hooks';

import css from './EncoderInputSource.module.scss';

export function EncoderInputSource() {
    const { formApi } = useEncoderInputFormContext();
    const { register, control } = formApi;

    const encoderOptions = useSelector(getLiveEncoderOptions);

    const [source, backup, useBackupEncoder] = useWatch({ name: ['source', 'backup', 'useBackupEncoder'], control });

    const { encoderId: selectedEncoderId, inputId: selectedInputId } = source || {};
    const { encoderId: selectedBackupEncoderId, inputId: selectedBackupInputId } = backup || {};

    const showNetworkInput = source && source.value.endsWith(':network');
    const showBackupNetworkInput = Boolean(
        useBackupEncoder && selectedBackupEncoderId && backup.value.endsWith(':network')
    );

    const sourceValidator = useBackupEncoder ? { required: validatorHelpers.required } : undefined;
    const backupValidator = {
        required: validatorHelpers.required,
        backupEncoderInput: validatorHelpers.backupEncoderInputFactory(source, useBackupEncoder),
    };

    return (
        <>
            <div className={css.inputSelector}>
                <EncoderInputSelector
                    name="source"
                    label="Choose input"
                    options={encoderOptions}
                    encoderId={selectedEncoderId}
                    inputId={selectedInputId}
                    validate={sourceValidator}
                >
                    <Checkbox
                        {...register('useBackupEncoder')}
                        label="Additional backup encoder"
                        containerClassName={css.backupEncoderCheckbox}
                    />
                </EncoderInputSelector>

                {useBackupEncoder && (
                    <EncoderInputSelector
                        name="backup"
                        label="Choose backup input"
                        options={encoderOptions}
                        encoderId={selectedBackupEncoderId}
                        inputId={selectedBackupInputId}
                        validate={backupValidator}
                    />
                )}
            </div>

            {showNetworkInput && <NetworkInput name="networkUrl" label="Source type" encoderId={selectedEncoderId} />}

            {showBackupNetworkInput && (
                <NetworkInput name="backupNetworkUrl" label="Backup source type" encoderId={selectedBackupEncoderId} />
            )}
        </>
    );
}
