import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { Button, Dialog, LabeledContainer } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import config from 'config';
import { makeStreamTypeLabel } from 'models/asset';
import { TagsSelect } from 'components/core/hookForm/TagsSelect';
import { TagsSuggestions } from 'components/core/TagsSuggestions';
import { useTagsEditingForm, useTagsEditingFormSubmit } from './hooks';

import css from './TagsEditingDialog.module.scss';

type TagsEditingDialogProps = {
    asset: Asset;
    isOpen: boolean;
    onSuccess: () => void;
    onClose: () => void;
};

export function TagsEditingDialog({ asset, isOpen, onSuccess, onClose }: TagsEditingDialogProps) {
    const { formApi } = useTagsEditingForm({ asset });

    const {
        control,
        setValue,
        formState: { isSubmitting },
    } = formApi;

    const handleSubmit = useTagsEditingFormSubmit({ formApi, asset, onSuccess });
    const streamTypeLabel = makeStreamTypeLabel(asset, config.asset.labels);

    const tags = useWatch({ name: 'tags', control });

    const addTag = useCallback(
        (tag) => setValue('tags', [...tags, tag], { shouldDirty: true, shouldTouch: true }),
        [tags, setValue]
    );

    return (
        <Dialog heading="Edit tags" isOpen={isOpen} onClose={onClose}>
            <form onSubmit={handleSubmit}>
                <Dialog.Section className={css.dialogSection}>
                    <LabeledContainer className={css.assetMetadata} label={`${streamTypeLabel} - ${asset.id}`}>
                        <div className={css.title}>{asset.title}</div>
                    </LabeledContainer>

                    <div className={css.content}>
                        <LabeledContainer className={css.label} label="TAGS: try to add three">
                            <TagsSelect
                                name="tags"
                                control={control}
                                provider={asset.provider}
                                maxMenuHeight={180}
                                normalizeValues
                            />
                            <TagsSuggestions
                                provider={asset.provider}
                                onAddTag={addTag}
                                formData={{
                                    title: asset.title,
                                    description: asset.description,
                                    subtitles: asset.additional.subtitles,
                                    tags,
                                }}
                            />
                        </LabeledContainer>
                    </div>
                </Dialog.Section>
                <Dialog.Section mode="flexRight" variant="darker">
                    <Button type="button" variant="standard" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" loading={isSubmitting}>
                        Confirm
                    </Button>
                </Dialog.Section>
            </form>
        </Dialog>
    );
}
