import { combineReducers } from '@reduxjs/toolkit';
import adsReducer from './adsReducer';
import encodersReducer from './encodersReducer';
import channelsReducer from './channelsReducer';
import runtimeReducer from './runtimeReducer';
import liveAssetsReducer from './liveAssetsReducer';
import liveNotificationsReducer from './liveNotificationsReducer';
import wasLiveAssetsReducer from './wasLiveAssetsReducer';

export default combineReducers({
    ads: adsReducer,
    encoders: encodersReducer,
    channels: channelsReducer,
    runtime: runtimeReducer,
    liveAssets: liveAssetsReducer,
    notifications: liveNotificationsReducer,
    wasLiveAssets: wasLiveAssetsReducer,
});
