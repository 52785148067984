import { RefObject } from 'react';
import { minmax } from './utils';
import { getEndTimeOffset, getStartTimeOffset } from './helpers';
import { useSubscribeTimelineState } from './hooks/useTimelineState';
import { TimelineTooltip } from './TimelineTooltip';

type TimelineTooltipsProps = {
    containerRef: RefObject<HTMLDivElement>;
};

export function TimelineTooltips({ containerRef }: TimelineTooltipsProps) {
    const selection = useSubscribeTimelineState((state) => state.selection);
    const pixelsPerSecond = useSubscribeTimelineState((state) => state.pixelsPerSecond);
    const scroll = useSubscribeTimelineState((state) => state.scroll);

    if (!selection || !containerRef.current) {
        return null;
    }

    const { clientWidth } = containerRef.current;
    const startTimeOffset = getStartTimeOffset(selection.startTime, pixelsPerSecond, scroll);
    const endTimeOffset = getEndTimeOffset(selection.endTime, pixelsPerSecond, scroll);

    return (
        <>
            <TimelineTooltip time={selection.startTime} offset={minmax(startTimeOffset, clientWidth, 0)} />
            <TimelineTooltip time={selection.endTime} offset={minmax(endTimeOffset, clientWidth, 0)} />
        </>
    );
}
