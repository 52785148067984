import moment from 'moment';

export interface Option {
    label: string;
    value: number;
}

export const parseTextAsDuration = (text = ''): Record<string, number> => {
    const durationMatches = text.match(/\b\d+\s*?(?:h|hours?|m|minutes?|s|seconds?)\b/gi);
    if (!durationMatches) {
        return undefined;
    }
    return durationMatches.reduce((durationObject, value) => {
        const [, time, unit] = value.match(/(\d+)\s*?(\w+)/i);
        if (!time || !unit) {
            return durationObject;
        }
        return {
            ...durationObject,
            [unit.toLowerCase()]: Number(time),
        };
    }, {});
};

export const pluralize = (word: string, quantity: number): string => (quantity > 1 ? `${word}s` : word);

export const getDefaultOptions = (defaultLabel: string): Option[] => {
    return [
        { label: defaultLabel, value: 0 },
        { label: '30 minutes', value: 1800 },
        { label: '1 hour', value: 3600 },
        { label: '3 hours', value: 10800 },
        { label: '6 hours', value: 21600 },
        { label: '12 hours', value: 43200 },
        { label: '22 hours', value: 79200 },
    ];
};

export const appendOption = (options: Option[], newOption: Option): Option[] =>
    [...options, newOption].sort((a, b) => a.value - b.value);

export const createCustomOption = (value: number): Option => {
    const duration = moment.duration(value, 'seconds');
    const labelParts = [];

    const hours = Math.floor(duration.asHours());
    if (hours > 0) {
        labelParts.push(`${Math.floor(hours)} ${pluralize('hour', hours)}`);
    }
    if (duration.minutes() > 0) {
        labelParts.push(`${duration.minutes()} ${pluralize('minute', duration.minutes())}`);
    }
    if (duration.seconds() > 0) {
        labelParts.push(`${duration.seconds()} ${pluralize('second', duration.seconds())}`);
    }

    return {
        label: labelParts.join(', '),
        value,
    };
};

export const parseCustomOption = (value: string): Option => {
    const durationObject = parseTextAsDuration(value);
    if (!durationObject) {
        return null;
    }

    return {
        label: value,
        value: moment.duration(durationObject).asSeconds(),
    };
};

export const validate = (value?: number): string | undefined => {
    if (!value || (typeof value === 'number' && Number.isFinite(value) && value >= 0)) {
        return undefined;
    }
    return 'Should be greater or equal 0';
};
