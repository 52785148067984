import * as PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import css from './VideoCardProgress.module.scss';

const cln = classnames.bind(css);

function VideoCardProgress({ variant, progress }) {
    return <div className={cln('progress', `progress--${variant}`)} style={{ width: `${progress}%` }} />;
}

VideoCardProgress.propTypes = {
    progress: PropTypes.number.isRequired,
    variant: PropTypes.string,
};

VideoCardProgress.defaultProps = {
    variant: 'default',
};

export default VideoCardProgress;
