/**
 * @param {Array<File>} files
 * @returns {{ isValid: Boolean, files?: Array<File>, message?: String }}
 */
export default function validator(files) {
    const rx = /^(video|audio)\//;
    const validFiles = files.filter(({ type }) => rx.test(type));
    const invalidFileNames = files
        .filter(({ type }) => !rx.test(type))
        .map(({ name }) => name)
        .join(', ');
    const message = invalidFileNames.length > 0 && `Wrong file(s): ${invalidFileNames}`;

    if (!validFiles.length) {
        return {
            isValid: false,
            message,
        };
    }

    return {
        isValid: true,
        files: validFiles,
        message,
    };
}
