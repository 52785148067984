export type SubtitlesUploadedData = {
    language: string;
    url: string;
    default: boolean;
};

export type SubtitlesUploadingData = {
    language: string;
    filename: string;
    error?: string;
};

export type SubtitlesData = SubtitlesUploadedData | SubtitlesUploadingData;

export const isSubtitlesUploadingData = (subtitles: SubtitlesData): subtitles is SubtitlesUploadingData =>
    'filename' in subtitles;
