export const defaultAsset = {
    isFetching: false,
    isPristine: true,
    error: null,
    asset: null, // TODO: rename this to `item` ?
};

// { [assetId]: defaultAsset }
export const defaultItems = {};

export const defaultMeta = {
    tags: {
        isFetching: false,
        items: [],
    },
    stories: {
        isFetching: false,
        items: [],
    },
};
