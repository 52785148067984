import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export const playlistFormSchema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    header: z.union([z.literal(''), z.null(), z.string()]).optional(),
    labels: z.array(z.string()).optional(),
    type: z.enum(['video', 'audio', 'playlists', 'playlist-of-playlists']),
    isPodcastPlaylist: z.union([z.string(), z.boolean()]).optional(),
    query: z.union([z.literal(''), z.null(), z.string()]).optional(),
    limit: z.number({ invalid_type_error: 'Must be number' }).min(1, 'Min value is 1').max(100, 'Max value is 100'),
    searchQuery: z.string(),
    items: z
        .array(
            z.union([
                z.object({
                    position: z.number().optional(),
                    isStatic: z.boolean().optional(),
                    asset: z.object({
                        id: z.number(),
                        provider: z.string(),
                        duration: z.number().optional(),
                        category: z.string(),
                    }),
                }),
                z.object({
                    position: z.number().optional(),
                    isStatic: z.boolean().optional(),
                    playlist: z.object({
                        id: z.string(),
                        provider: z.string(),
                        duration: z.number().optional(),
                    }),
                }),
            ])
        )
        .optional(),
});

export const resolver = zodResolver(playlistFormSchema);
