import config from 'config';

let request = null;

const load = () => {
    if (!request) {
        request = new Promise((resolve) => {
            window.onSvpPlayerReady = () => {
                resolve(window.SVP.Player);
            };

            // eslint-disable-next-line func-names
            (function (d, s, id) {
                const fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }

                const js = d.createElement(s);
                js.id = id;
                js.src = config.player.src;
                js.async = true;
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'svp-player-sdk');
        });
    }

    return request;
};

export default load;
