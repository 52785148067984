export const FETCH_CLIPS = 'FETCH_CLIPS';
export const FETCH_CLIPS_ERROR = 'FETCH_CLIPS_ERROR';
export const FETCH_CLIPS_SUCCESS = 'FETCH_CLIPS_SUCCESS';
export const CREATE_CLIP = 'CREATE_CLIP';
export const CREATE_CLIP_ERROR = 'CREATE_CLIP_ERROR';
export const CREATE_CLIP_SUCCESS = 'CREATE_CLIP_SUCCESS';
export const CREATE_CLIP_START = 'CREATE_CLIP_START';
export const TRIM_VIDEO = 'TRIM_VIDEO';
export const TRIM_VIDEO_ERROR = 'TRIM_VIDEO_ERROR';
export const TRIM_VIDEO_SUCCESS = 'TRIM_VIDEO_SUCCESS';
export const TRIM_VIDEO_START = 'TRIM_VIDEO_START';
export const RESET_TRIM = 'RESET_TRIM';
export const RESET_TRIM_SUCCESS = 'RESET_TRIM_SUCCESS';
export const RESET_TRIM_FAILURE = 'RESET_TRIM_FAILURE';

/**
 * @typedef {Object} Playback
 * @property {Number} begin - Start time
 * @property {Number} end - End time
 */

/**
 * @param {Number} assetId
 * @param {String} provider
 * @param {String} [nextUrl='']
 */
export const fetchClips = (assetId, provider, nextUrl = '') => ({
    type: FETCH_CLIPS,
    assetId,
    provider,
    nextUrl,
});

/**
 * @param {Number} assetId
 * @param {String} newsroom
 * @param {Error} error
 */
export const fetchClipsError = (assetId, provider, error) => ({
    type: FETCH_CLIPS_ERROR,
    assetId,
    provider,
    error,
});

/**
 * @param {Number} assetId
 * @param {String} provider
 * @param {String} nextUrl
 * @param {Array.<Object>} assets
 */
export const fetchClipsSuccess = (assetId, provider, nextUrl, assets) => ({
    type: FETCH_CLIPS_SUCCESS,
    assetId,
    provider,
    nextUrl,
    assets,
});

/**
 * @param {Number} assetId
 * @param {String} provider
 * @param {Playback} playback
 */
export const createClip = (assetId, provider, playback) => ({
    type: CREATE_CLIP,
    assetId,
    provider,
    playback,
});

/**
 * @param {Number} assetId
 * @param {String} provider
 * @param {Error} error
 */
export const createClipError = (assetId, provider, error) => ({
    type: CREATE_CLIP_ERROR,
    assetId,
    provider,
    error,
});

/**
 * @param {Number} assetId
 * @param {Number} newAssetId
 * @param {String} provider
 */
export const createClipSuccess = (assetId, newAssetId, provider) => ({
    type: CREATE_CLIP_SUCCESS,
    assetId,
    newAssetId,
    provider,
});

/**
 * @param {Number} assetId
 * @param {Number} newAssetId
 * @param {String} provider
 */
export const createClipStart = (assetId, newAssetId, provider) => ({
    type: CREATE_CLIP_START,
    assetId,
    newAssetId,
    provider,
});

/**
 * @param {Number} assetId
 * @param {String} provider
 * @param {Playback} playback
 */
export const trimVideo = (assetId, provider, playback) => ({
    type: TRIM_VIDEO,
    assetId,
    provider,
    playback,
});

/**
 * @param {Number} assetId
 * @param {String} provider
 * @param {Error} error
 */
export const trimVideoError = (assetId, provider, error) => ({
    type: TRIM_VIDEO_ERROR,
    assetId,
    provider,
    error,
});

/**
 * @param {Number} assetId
 * @param {String} provider
 */
export const trimVideoSuccess = (assetId, provider) => ({
    type: TRIM_VIDEO_SUCCESS,
    assetId,
    provider,
});

/**
 * @param {Number} assetId
 * @param {String} provider
 */
export const trimVideoStart = (assetId, provider) => ({
    type: TRIM_VIDEO_START,
    assetId,
    provider,
});

export const resetTrim = ({ assetId, provider, previousData }) => ({
    type: RESET_TRIM,
    assetId,
    provider,
    previousData,
});

export const resetTrimSuccess = ({ assetId, provider }) => ({
    type: RESET_TRIM_SUCCESS,
    assetId,
    provider,
});

export const resetTrimFailure = ({ assetId, provider, error }) => ({
    type: RESET_TRIM_FAILURE,
    assetId,
    provider,
    error,
});
