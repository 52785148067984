import { useDrag } from 'react-dnd';

import Playlist from 'components/Playlists';

import { itemTypePlaylist } from 'components/video/NextVideo/constants';

const style = {
    transform: 'translate3d(0, 0,0)',
};

export function MovablePlaylistCard({ provider, id, assignPlaylist, assetId, className }) {
    const [, drag] = useDrag({
        type: itemTypePlaylist,
        item: { id, type: itemTypePlaylist },
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                assignPlaylist({ provider, playlistId: item.id, id: assetId });
            }
        },
        collect: (monitor) => ({
            dragged: !!monitor.isDragging(),
        }),
    });

    return (
        <div ref={drag} style={{ ...style }}>
            <Playlist
                provider={provider}
                id={id}
                hideDeleteButton
                className={className}
                size="compact"
                linkOnlyOnName
            />
        </div>
    );
}
