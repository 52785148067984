import { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { FaAngleDown } from 'react-icons/fa';
import { Button, Dropdown, Checkbox } from '@schibsted-svp/react-ui';
import { PreferencesPropTypes } from 'store/user/validation';
import config from 'config';
import { bemgen } from 'lib/bem';

import './OptionsButton.scss';

const buttonRenderer = (onClick, isExpanded) => (
    <Button
        className={bemgen('OptionsButton', { expanded: isExpanded })}
        type="button"
        variant="standard"
        onClick={onClick}
    >
        Options
        <span className={bemgen('OptionsButton__arrow-icon')}>
            <FaAngleDown />
        </span>
    </Button>
);

class OptionsButton extends PureComponent {
    static propTypes = {
        preferences: PreferencesPropTypes.isRequired,
        toggle: PropTypes.func.isRequired,
    };

    onOptionClick = (key) => {
        const { toggle } = this.props;
        toggle(key);
    };

    render() {
        const { preferences } = this.props;
        const { columnOptions } = config.assets;

        const optionItems = Object.entries(columnOptions).map(([key, label]) => (
            <Dropdown.Item hideOnOutsideClick key={key} itemKey={key} onClick={this.onOptionClick}>
                <Checkbox readOnly checked={preferences.assetProperties.includes(key)} />
                Show {label}
            </Dropdown.Item>
        ));

        return (
            <Dropdown
                className={bemgen('OptionsButtonContainer')}
                hideOnOptionClick={false}
                mainButtonRenderer={buttonRenderer}
                variant="standard"
                menuGap={8}
                onClick={this.handleArrowClick}
            >
                {optionItems}
            </Dropdown>
        );
    }
}

export default OptionsButton;
