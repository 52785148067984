import classnames from 'classnames/bind';
import { memo, useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LabeledContainer } from '@schibsted-svp/react-ui';
import { cancelLiveAd, completeLiveAdInserting, insertLiveAd } from 'store/live/actions';
import { getAdsPlacementStatus } from 'store/live/selectors';
import ConfirmationButton from 'components/core/ConfirmationButton/ConfirmationButton';
import ElementalAdProgress from './ElementalAdProgress';
import ElementalAdSelector from './ElementalAdSelector';
import { durationOptions } from './utils';
import css from './ElementalAdControls.module.scss';

const cln = classnames.bind(css);

const defaultDurationOption = durationOptions[0];

function ElementalAdControls({ assetId, newsroom }) {
    const dispatch = useDispatch();
    const [selectedDuration, setSelectedDuration] = useState(defaultDurationOption);
    const { emittedAt, processing, progress } = useSelector((state) =>
        getAdsPlacementStatus(state, { assetId, newsroom })
    );

    const handleInsertAdClick = useCallback(() => {
        dispatch(insertLiveAd(newsroom, assetId, selectedDuration.value));
    }, [dispatch, newsroom, assetId, selectedDuration]);

    const handleCancelAdClick = useCallback(() => {
        dispatch(cancelLiveAd(newsroom, assetId));
        setSelectedDuration(defaultDurationOption);
    }, [assetId, dispatch, newsroom]);

    const handleEmittingAdComplete = useCallback(() => {
        dispatch(completeLiveAdInserting(newsroom, assetId));
        setSelectedDuration(defaultDurationOption);
    }, [dispatch, newsroom, assetId]);

    return (
        <div className={css.container} data-testid="AdsPlacement">
            <h1>Place ad break in Live video</h1>
            <div className={cln(css.containerInner, { 'containerInner--progress': progress })}>
                {progress ? (
                    <>
                        <LabeledContainer label="Ads status">
                            <ElementalAdProgress
                                emittedAt={emittedAt}
                                duration={selectedDuration.value}
                                onEmittingAdComplete={handleEmittingAdComplete}
                            />
                        </LabeledContainer>
                        <LabeledContainer className={css.abortButtonContainer}>
                            <ConfirmationButton loading={processing} variant="danger" onConfirm={handleCancelAdClick}>
                                Abort ads
                            </ConfirmationButton>
                        </LabeledContainer>
                    </>
                ) : (
                    <ElementalAdSelector
                        duration={selectedDuration}
                        loading={processing}
                        onDurationChange={setSelectedDuration}
                        onInsertAdClick={handleInsertAdClick}
                    />
                )}
            </div>
        </div>
    );
}

ElementalAdControls.propTypes = {
    assetId: PropTypes.number.isRequired,
    newsroom: PropTypes.string.isRequired,
};

export default memo(ElementalAdControls);
