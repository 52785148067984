import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isElementalEncoder, isEmptyEncoder, isExternalOrRtmpEncoder, isMediaLiveEncoder } from 'models/asset';
import { getAsset } from 'store/assets/selectors';
import { isLiveRuntimeStarting, isLiveRuntimeStopping } from 'store/live/selectors';
import { LiveCreationDialog } from '../LiveCreationDialog';
import MediaLiveEncoderControls from './MediaLiveEncoderControls';
import ElementalEncoderControls from './ElementalEncoderControls';
import ExternalOrRtmpControls from './ExternalOrRtmpControls';
import LiveControlsTemplate from './LiveControlsTemplate';
import css from './LiveControls.module.scss';

function LiveControls({ assetId, provider, children }) {
    const asset = useSelector((state) => getAsset(state, { id: assetId, provider }));
    const isStarting = useSelector((state) => isLiveRuntimeStarting(state, { assetId, provider }));
    const isStopping = useSelector((state) => isLiveRuntimeStopping(state, { assetId, provider }));

    return (
        <section data-testid="LiveControls" className={css.container}>
            {isMediaLiveEncoder(asset) && (
                <MediaLiveEncoderControls asset={asset} isStarting={isStarting} isStopping={isStopping} />
            )}
            {isElementalEncoder(asset) && (
                <ElementalEncoderControls asset={asset} isStarting={isStarting} isStopping={isStopping} />
            )}
            {isExternalOrRtmpEncoder(asset) && <ExternalOrRtmpControls asset={asset} isStopping={isStopping} />}
            {isEmptyEncoder(asset) && (
                <LiveControlsTemplate
                    newsroom={provider}
                    assetId={assetId}
                    startTime={asset.flightTimes.start}
                    sourceButton={<LiveCreationDialog form="live" newsroom={asset.provider} assetId={asset.id} />}
                />
            )}
            <div className={css.submitButton}>{children}</div>
        </section>
    );
}

LiveControls.propTypes = {
    assetId: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default LiveControls;
