import { useState, memo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { Notification } from '@schibsted-svp/react-ui';
import { bemgen } from 'lib/bem';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import VideoNavigation from 'components/video/navigation/VideoNavigation';
import ScreenContainer from 'components/ui/ScreenContainer';
import { Dropzone } from 'components/video/Dropzone/Dropzone';

import AssetsList from 'components/AssetsList';
import validator from './AssetListScreen.validator';
import './AssetListScreen.scss';

function AssetsListScreen({ newsroom, createVideo }) {
    const { listType = 'main' } = useParams();
    const history = useHistory();
    const [processing, setProcessing] = useState(false);
    const userPermissions = useUserPermissions();

    const onUpload = useCallback(
        (files) => {
            const result = validator(files);

            if (result.message) {
                Notification.notify.error(result.message);
            }

            if (!result.isValid) {
                return;
            }

            setProcessing(true);

            Promise.all(result.files.map((file) => createVideo(newsroom, file)))
                .then((ids) => {
                    setProcessing(false);

                    if (ids.length === 1) {
                        // FIXME: Find needed because services/api-client/assets.js is fucked up
                        const first = ids.find(Number.isInteger);
                        history.push(`/${newsroom}/assets/${first}`);
                    }
                })
                .catch(() => {
                    setProcessing(false);

                    Notification.notify.error('An unexpected error occurred during uploading one of the videos');
                });
        },
        [setProcessing, newsroom, createVideo, history]
    );

    return (
        <ScreenContainer className={bemgen('AssetListScreen')}>
            <VideoNavigation newsroom={newsroom} />
            <AssetsList newsroom={newsroom} type={listType} isUserProvider={userPermissions?.isUser} />
            {userPermissions?.isUser && (
                <Dropzone loading={processing} onUpload={onUpload} disabled={listType !== 'main'} fixed multiple />
            )}
        </ScreenContainer>
    );
}

AssetsListScreen.propTypes = {
    newsroom: PropTypes.string.isRequired,
    createVideo: PropTypes.func.isRequired,
};

export default memo(AssetsListScreen);
