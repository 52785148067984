import { createContext, useContext } from 'react';
import type { Optional } from 'types';

export const CustomPreviewDialogContext = createContext<
    Optional<{
        preventDialogClose: boolean;
        disableCreateButton: boolean;
        setPreventDialogClose: (preventDialogClose: boolean) => void;
        setDisableCreateButton: (disableCreateButton: boolean) => void;
    }>
>(undefined);

export function useCustomPreviewDialogContext() {
    const context = useContext(CustomPreviewDialogContext);
    if (!context) {
        throw new Error('CustomPreviewDialogContext not defined');
    }
    return context;
}
