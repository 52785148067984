import * as PropTypes from 'prop-types';
import { LabeledContainer, Select } from '@schibsted-svp/react-ui';
import useToggle from 'hooks/useToggle';
import ConfirmationButton from 'components/core/ConfirmationButton/ConfirmationButton';
import { durationOptions } from './utils';

function ElementalAdSelector({ duration, loading, onDurationChange, onInsertAdClick }) {
    const [isDurationDisabled, toggleIsDurationDisabled] = useToggle();

    const handleInsertAdClick = () => {
        toggleIsDurationDisabled();
        onInsertAdClick();
    };

    return (
        <>
            <LabeledContainer label="Maximum duration" width={160}>
                <Select
                    placeholder="Duration"
                    options={durationOptions}
                    onChange={onDurationChange}
                    isDisabled={isDurationDisabled || loading}
                    value={duration}
                />
            </LabeledContainer>
            <LabeledContainer>
                <ConfirmationButton
                    loading={loading}
                    variant="standard"
                    onClick={toggleIsDurationDisabled}
                    onCancel={toggleIsDurationDisabled}
                    onConfirm={handleInsertAdClick}
                >
                    Place ad break
                </ConfirmationButton>
            </LabeledContainer>
        </>
    );
}

ElementalAdSelector.propTypes = {
    duration: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    onDurationChange: PropTypes.func.isRequired,
    onInsertAdClick: PropTypes.func.isRequired,
};

export default ElementalAdSelector;
