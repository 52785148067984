import { DEFERRED } from 'store/deferred';

export const UPLOAD_SUBTITLES = 'Subtitles/UPLOAD';
export const UPLOAD_SUBTITLES_COMPLETE = 'Subtitles/UPLOAD_COMPLETE';
export const UPLOAD_SUBTITLES_FAILURE = 'Subtitles/UPLOAD_FAILURE';

/**
 * @param {String} provider
 * @param {Number} assetId
 * @param {String} language
 * @param {File} file
 */
export const uploadSubtitles = (provider, assetId, language, file) => ({
    type: UPLOAD_SUBTITLES,
    provider,
    assetId,
    language,
    file,
    [DEFERRED]: true,
});

/**
 * @param {String} provider
 * @param {Number} assetId
 * @param {String} language
 * @param {String} url
 */
export const completeSubtitlesUpload = (provider, assetId, language, url) => ({
    type: UPLOAD_SUBTITLES_COMPLETE,
    provider,
    assetId,
    language,
    url,
});

/**
 * @param {String} provider
 * @param {Number} assetId
 * @param {String} language
 * @param {Error} error
 */
export const failSubtitlesUpload = (provider, assetId, language, error) => ({
    type: UPLOAD_SUBTITLES_FAILURE,
    provider,
    assetId,
    language,
    error,
});
