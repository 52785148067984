import { combineReducers, createReducer, isAnyOf } from '@reduxjs/toolkit';
import type { WrappedState } from 'types';
import { relatedReducer, searchReducer } from './reducers-rest';
import { openCustomPreviewUploadDialog, closeCustomPreviewUploadDialog } from './actions';

type CustomPreviewUploadDialogState = {
    isOpen: boolean;
};

type CustomPreviewState = WrappedState<CustomPreviewUploadDialogState>;

export const customPreviewUploadDialogReducer = createReducer<CustomPreviewState>({}, (builder) => {
    builder.addMatcher(
        isAnyOf(openCustomPreviewUploadDialog, closeCustomPreviewUploadDialog),
        (state, { type, payload: { provider, assetId } }) => {
            state[provider] = {
                ...state?.[provider],
                [assetId]: {
                    ...state?.[provider]?.[assetId],
                    isOpen: type === openCustomPreviewUploadDialog.type,
                },
            };
        }
    );
});

export default combineReducers({
    customPreviewUploadDialog: customPreviewUploadDialogReducer,
    related: relatedReducer,
    search: searchReducer,
});
