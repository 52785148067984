import { useState } from 'react';
import { Spinner, Notification } from '@schibsted-svp/react-ui';
import config from 'config';
import css from './ProviderSelector.module.scss';
import { SubmitEvent } from './types';

export function ProviderSelector() {
    const [isSubmitting, setIsSumbitting] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState<string | null>(null);

    async function handleSelectProvider(event: SubmitEvent, sso: string) {
        event.preventDefault();

        setIsSumbitting(true);
        setSelectedProvider(sso);

        try {
            const response = await fetch(`/sso/saml/login?idp=${sso}`, {
                method: 'GET',
                redirect: 'manual',
            });

            const redirectUrl = await response.text();
            window.location.href = redirectUrl;
        } catch {
            Notification.notify.error("Couldn't log in");
        }
    }

    return (
        <span>
            <p className={css.instruction}>Your e-mail is not assigned to any SSO provider. Select one to log in. </p>
            <ul className={css.providers}>
                {config.auth.providers.map((provider) => (
                    <li key={provider.id} className={css.element}>
                        <form onSubmit={(e: SubmitEvent) => handleSelectProvider(e, provider.id)}>
                            <button className={css.button} type="submit">
                                {isSubmitting && selectedProvider === provider.id ? (
                                    <Spinner />
                                ) : (
                                    <img
                                        style={{
                                            maxWidth: `${provider.width}px`,
                                        }}
                                        src={provider.logo}
                                        alt={provider.title}
                                    />
                                )}
                            </button>
                        </form>
                    </li>
                ))}
            </ul>
        </span>
    );
}
