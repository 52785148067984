import { createSlice, type Draft, type PayloadAction } from '@reduxjs/toolkit';

const DEFAULT_KEY = '_default';

export type CutRange = {
    begin: number;
    end: number;
};

export type AssetPayload = {
    provider: string;
    assetId: number;
};

export type CategoryPayload = {
    provider: string;
    categoryId: number;
};

export type PayloadWithPrefix<P> = P & {
    prefix?: string;
};

export function getPreviewKey(payload: PayloadWithPrefix<AssetPayload> | PayloadWithPrefix<CategoryPayload>) {
    if ('categoryId' in payload) {
        return `${payload.provider}:category:${payload.categoryId}:${payload.prefix || DEFAULT_KEY}`;
    }
    return `${payload.provider}:asset:${payload.assetId}:${payload.prefix || DEFAULT_KEY}`;
}

const initialState: Record<ReturnType<typeof getPreviewKey>, number> = {};

function increasePreviewPending(
    state: Draft<typeof initialState>,
    payload: PayloadWithPrefix<AssetPayload> | PayloadWithPrefix<CategoryPayload>
) {
    const key = getPreviewKey(payload);
    if (!(key in state)) {
        state[key] = 0;
    }
    state[key] += 1;
}

function decreasePreviewPending(
    state: Draft<typeof initialState>,
    payload: PayloadWithPrefix<AssetPayload> | PayloadWithPrefix<CategoryPayload>
) {
    const key = getPreviewKey(payload);
    if (!(key in state)) {
        return;
    }
    if (state[key] > 0) {
        state[key] -= 1;
        return;
    }
    delete state[key];
}

const previewsSlice = createSlice({
    name: 'previews',
    initialState,
    reducers: {
        createAssetPreview(state, action: PayloadAction<AssetPayload & { cutRange: CutRange; artifacts?: string[] }>) {
            increasePreviewPending(state, action.payload);
        },
        uploadAssetPreview(
            state,
            action: PayloadAction<PayloadWithPrefix<AssetPayload & { file: File; artifacts?: string[] }>>
        ) {
            increasePreviewPending(state, action.payload);
        },
        createAssetPreviewFailure(state, action: PayloadAction<PayloadWithPrefix<AssetPayload & { message: string }>>) {
            decreasePreviewPending(state, action.payload);
        },
        createAssetPreviewSuccess(state, action: PayloadAction<PayloadWithPrefix<AssetPayload>>) {
            decreasePreviewPending(state, action.payload);
        },
        uploadCategoryPreview(state, action: PayloadAction<CategoryPayload & { file: File; artifacts?: string[] }>) {
            increasePreviewPending(state, action.payload);
        },
        uploadCategoryPreviewFailure(state, action: PayloadAction<CategoryPayload & { message: string }>) {
            decreasePreviewPending(state, action.payload);
        },
        uploadCategoryPreviewSuccess(state, action: PayloadAction<CategoryPayload & { url: string }>) {
            decreasePreviewPending(state, action.payload);
        },
    },
});

export const {
    createAssetPreview,
    uploadAssetPreview,
    createAssetPreviewFailure,
    createAssetPreviewSuccess,
    uploadCategoryPreview,
    uploadCategoryPreviewFailure,
    uploadCategoryPreviewSuccess,
} = previewsSlice.actions;

export default previewsSlice;
