import { ForwardedRef, RefObject, useCallback, useImperativeHandle } from 'react';
import { useTimelineState } from './useTimelineState';
import { getCenteredOffsetForItem } from '../helpers';

export type TimelineRef = {
    isDragging: () => boolean;
    scrollToIndex: (index: number) => void;
};

export function useTimelineRef(ref: ForwardedRef<TimelineRef>, containerRef: RefObject<HTMLDivElement>) {
    const { getState } = useTimelineState();

    const isDragging = useCallback<TimelineRef['isDragging']>(() => {
        return getState().method !== undefined;
    }, [getState]);

    const scrollToIndex = useCallback<TimelineRef['scrollToIndex']>(
        (index) => {
            const { items, pixelsPerSecond } = getState();
            const item = items[index];
            if (!containerRef.current || !item) {
                return;
            }

            const { clientWidth } = containerRef.current;
            const itemOffset = item.startTime * pixelsPerSecond;
            const itemWidth = (item.endTime - item.startTime) * pixelsPerSecond;
            const left = getCenteredOffsetForItem(itemOffset, itemWidth, clientWidth);

            containerRef.current?.scrollTo({ left, behavior: 'smooth' });
        },
        [containerRef, getState]
    );

    useImperativeHandle(
        ref,
        () => ({
            isDragging,
            scrollToIndex,
        }),
        [isDragging, scrollToIndex]
    );
}
