import classnames from 'classnames/bind';
import { Control, useWatch } from 'react-hook-form';
import Tooltip from 'components/ui/Tooltip';
import type { SubtitlesFormValues } from './types';
import css from './SubtitleCueStats.module.scss';

const cln = classnames.bind(css);

const MAX_NUMBER_OF_LINES = 2;
const MAX_CHARACTERS_PER_LINE = 42;
const MAX_CHARACTERS_PER_SECOND = 30;

function countCharacters(text: string): number {
    return text.length;
}

function getNumberOfLinesRatio(texts: string[]): number {
    return texts.length;
}

function getCharactersPerLineRatio(texts: string[]): number {
    return Math.max(...texts.map((text) => countCharacters(text)));
}

function getCharactersPerSecondRatio(texts: string[], duration: number): number {
    if (duration <= 0) {
        return 0;
    }
    return Math.ceil(texts.map((text) => countCharacters(text) / duration).reduce((sum, num) => sum + num, 0));
}

type SubtitleCueStatsProps = {
    className?: string;
    control: Control<SubtitlesFormValues>;
    index: number;
};

export function SubtitleCueStats({ className, control, index }: SubtitleCueStatsProps) {
    const { startTime, endTime, text } = useWatch({
        control,
        name: `cues.${index}`,
    });

    const lines = text.split('\n');
    const nol = getNumberOfLinesRatio(lines);
    const cpl = getCharactersPerLineRatio(lines);
    const cps = getCharactersPerSecondRatio(lines, endTime - startTime);

    return (
        <div className={cln(css.wrapper, className)}>
            <dt className={cln(css.title, { error: cpl > MAX_CHARACTERS_PER_LINE })}>
                <Tooltip theme="light" offset={[10, 10]} content="Characters Per Line" placement="top">
                    <span>CPL</span>
                </Tooltip>
            </dt>
            <dd>{cpl}</dd>

            <dt className={cln(css.title, { error: cps > MAX_CHARACTERS_PER_SECOND })}>
                <Tooltip theme="light" offset={[10, 10]} content="Characters Per Second" placement="top">
                    <span>CPS</span>
                </Tooltip>
            </dt>
            <dd>{cps}</dd>

            <dt className={cln(css.title, { error: nol > MAX_NUMBER_OF_LINES })}>
                <Tooltip theme="light" offset={[10, 10]} content="Number Of Lines" placement="top">
                    <span>NOL</span>
                </Tooltip>
            </dt>
            <dd>{nol}</dd>
        </div>
    );
}
