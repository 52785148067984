import * as PropTypes from 'prop-types';
import { Button, Connector, Dropdown } from '@schibsted-svp/react-ui';
import { allEventsHaveError, isMultiEvent, atLeastOneEventIsRunning, allEventsArePending } from 'models/multiLiveEvent';
import { getEncoderName, getInputName, hasError } from 'models/liveEvent';
import classnames from 'classnames/bind';
import { LiveCreationDialog } from '../LiveCreationDialog';
import NetworkInputInfoDialog from '../NetworkInputInfoDialog';
import css from './ElementalSourceButton.module.scss';

const cln = classnames.bind(css);

const getSourceButtonVariant = (event) => (event.status === 'error' ? 'danger' : 'standard');

function ElementalSourceButton({ assetId, provider, events, loading }) {
    const triggerProps = { buttonLoading: loading };
    const size = isMultiEvent(events) ? 'compact' : 'big';

    if (loading || !events.length || allEventsHaveError(events)) {
        return (
            <LiveCreationDialog
                form="live"
                assetId={assetId}
                newsroom={provider}
                triggerProps={triggerProps}
                from="sourceButton"
            />
        );
    }

    const leftButtonRenderer = (event, index) => {
        if (event.networkInputUri) {
            return (
                <NetworkInputInfoDialog
                    key={`network-info:${event.id}`}
                    uri={event.networkInputUri}
                    encoderId={event.encoder?.id}
                    dialogTrigger={
                        <Button
                            type="button"
                            size={size}
                            variant={getSourceButtonVariant(event)}
                            className={css.networkInfoDialogTrigger}
                        >
                            {`Network Input - ${getEncoderName(event)}`}
                        </Button>
                    }
                />
            );
        }

        return (
            <Button
                className={cln({ backupEncoder: isMultiEvent(events) && index === 1 })}
                disabled
                type="button"
                variant={getSourceButtonVariant(event)}
                size={size}
            >
                {`${getInputName(event)} - ${getEncoderName(event)}`}
            </Button>
        );
    };

    const sourceButton = (event, index) => {
        if (hasError(event)) {
            return (
                <Button
                    key={`button:${event.id}`}
                    type="button"
                    size={size}
                    variant={getSourceButtonVariant(event)}
                    disabled
                >
                    {`Error on ${getEncoderName(event)}`}
                </Button>
            );
        }

        if (atLeastOneEventIsRunning(events)) {
            if (event.networkInputUri) {
                return (
                    <NetworkInputInfoDialog
                        key={`network-info:${event.id}`}
                        uri={event.networkInputUri}
                        encoderId={event.encoder?.id}
                        dialogTrigger={
                            <Button
                                type="button"
                                size={size}
                                variant={getSourceButtonVariant(event)}
                                className={css.networkInfoDialogTrigger}
                            >
                                {`Network Input - ${getEncoderName(event)}`}
                            </Button>
                        }
                    />
                );
            }
            return (
                <Button
                    className={cln({
                        backupEncoder: isMultiEvent(events) && allEventsArePending(events) && index === 1,
                    })}
                    disabled
                    type="button"
                    variant={getSourceButtonVariant(event)}
                    size={size}
                >
                    {`${getInputName(event)} - ${getEncoderName(event)}`}
                </Button>
            );
        }

        return (
            <Dropdown
                variant={getSourceButtonVariant(event)}
                buttonRendererClassName={cln({ dropdownArrow: isMultiEvent(events) && index === 1 })}
                hideOnOutsideClick
                connectGap={0}
                disabled={loading}
                leftButtonRenderer={() => leftButtonRenderer(event, index)}
            >
                <LiveCreationDialog
                    assetId={assetId}
                    newsroom={provider}
                    triggerProps={triggerProps}
                    form="live"
                    triggerType="dropdown"
                    events={events}
                />
            </Dropdown>
        );
    };

    return (
        <Connector gap={0} flow="column">
            {events.map((event, index) => sourceButton(event, index))}
        </Connector>
    );
}

ElementalSourceButton.propTypes = {
    assetId: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default ElementalSourceButton;
