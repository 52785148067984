export const CUTTING_START = 'Cutting/CUTTING_START';
export const CUTTING_PROGRESS = 'Cutting/CUTTING_PROGRESS';
export const CUTTING_COMPLETE = 'Cutting/CUTTING_COMPLETE';
export const CUTTING_FAILURE = 'Cutting/CUTTING_FAILURE';

export const cuttingStart = (provider, id) => ({
    type: CUTTING_START,
    provider,
    id,
});

export const cuttingProgress = (provider, id, progress) => ({
    type: CUTTING_PROGRESS,
    provider,
    id,
    progress,
});

export const cuttingComplete = (provider, id, originAssetId) => ({
    type: CUTTING_COMPLETE,
    provider,
    id,
    originAssetId,
});

export const cuttingFailure = (provider, id, error) => ({
    type: CUTTING_FAILURE,
    provider,
    id,
    error,
});
