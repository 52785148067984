import { useWatch } from 'react-hook-form';
import { isFromFuture } from 'lib/time';
import LiveCreationDialogFooter from 'components/video/live/LiveCreationDialog/LiveCreationDialogFooter';
import { useEncoderInputFormContext, useEncoderInputFormFormSubmit } from './hooks';
import { EncoderInputFormControl } from './types';

interface EncoderInputFormFooterProps {
    isEditing: boolean;
    hasEncoder: boolean;
    hasEvents: boolean;
    formControl: EncoderInputFormControl;
}

export function EncoderInputFormFooter({ isEditing, hasEncoder, hasEvents, formControl }: EncoderInputFormFooterProps) {
    const { formApi } = useEncoderInputFormContext();

    const {
        control,
        formState: { isSubmitting, isValid },
    } = formApi;

    const [source, startTime] = useWatch({ name: ['source', 'startTime'], control });

    const hasSource = source != null;
    const submitButtonText =
        (hasSource && !hasEvents && (isFromFuture(startTime) ? 'Schedule' : 'Create')) ||
        (isEditing && hasEncoder && 'Change') ||
        (isEditing ? 'Set source' : 'Create');

    const handleSubmit = useEncoderInputFormFormSubmit(formControl);

    return (
        <LiveCreationDialogFooter
            onSubmit={handleSubmit}
            onCancel={formControl.onCancel}
            disabled={isSubmitting || !isValid}
            submitButtonText={submitButtonText}
        />
    );
}
