import deepDiff from 'deep-diff';
import { get } from 'lodash';
import { getFullUsernameFromEmail } from 'lib/email';
import { GetAssetHistory } from './graphql/assets-history.graphql';

const availableChanges = [
    { path: ['id'], name: 'ID' },
    { path: ['title'], name: 'Title' },
    { path: ['titleFront'], name: 'Alternative title' },
    { path: ['description'], name: 'Description' },
    { path: ['descriptionFront'], name: 'Alternative description' },
    { path: ['showDisplays'], name: 'Show displays' },
    { path: ['flightTimes'], name: 'Flight times' },
    { path: ['duration'], name: 'Duration' },
    { path: ['assetType'], name: 'Asset type' },
    { path: ['streamType'], name: 'Stream type' },
    { path: ['status'], name: 'Status' },
    { path: ['playback'], name: 'Playback' },
    { path: ['series'], name: 'Series' },
    { path: ['articleUrl'], name: 'Article URL' },
    { path: ['category', 'id'], name: 'Category' },
    { path: ['images'], name: 'Images' },
    { path: ['streamUrls'], name: 'Stream URLs' },
    { path: ['streamConfiguration'], name: 'Stream configuration' },
    { path: ['provider'], name: 'vgtv' },
    { path: ['additional', 'settings', 'ageLimit'], name: 'Age limit' },
    { path: ['additional', 'settings', 'escenicId'], name: 'Escenic ID' },
    { path: ['additional', 'settings', 'loopGeneratorEnabled'], name: 'Loop generator settings' },
    { path: ['additional', 'settings', 'notes'], name: 'Notes' },
    { path: ['additional', 'settings', 'playhead'], name: 'Playhead' },
    { path: ['additional', 'settings', 'showAds'], name: 'Ads settings' },
    { path: ['additional', 'settings', 'showComments'], name: 'Comments settings' },
    { path: ['additional', 'settings', 'showEmbed'], name: 'Embed settings' },
    { path: ['additional', 'settings', 'showInNewest'], name: 'Show in newest settings' },
    { path: ['additional', 'settings', 'source'], name: 'Source' },
    { path: ['additional', 'settings', 'startIndexPoint'], name: 'Start index point' },
    { path: ['additional', 'settings', 'tonoRegistered'], name: 'Tono registered' },
    { path: ['additional', 'chapters'], name: 'Chapters' },
    { path: ['additional', 'featuredChapters'], name: 'Featured chapters' },
    { path: ['additional', 'cuePoints'], name: 'Cue points' },
    { path: ['additional', 'nextAsset'], name: 'Next asset' },
    { path: ['additional', 'subtitles'], name: 'Subtitles' },
    { path: ['additional', 'tags'], name: 'Tags' },
    { path: ['additional', 'stories'], name: 'Stories' },
    { path: ['additional', 'access'], name: 'Access' },
    { path: ['additional', 'metadata'], name: 'Metadata' },
];

const changesActionsMap = {
    N: 'added',
    D: 'removed',
    E: 'modified',
    A: 'modified',
};

const getActionName = ({ isCreated, isPublished }) => {
    if (isCreated) return 'Created';
    if (isPublished) return 'Published';
    return 'Modified';
};

export function prepareAssetHistoryData(responseData) {
    return responseData
        .sort((a, b) => b.updated - a.updated)
        .map((assetData, index, list) => {
            const { updated, created, status } = assetData;

            const previousAsset = list[index + 1]; // previous asset is the next one on the list
            const previousStatus = get(previousAsset, 'status');
            const isCreated = updated === created;
            const isPublished = status === 'active' && previousStatus && previousStatus !== status;
            const action = getActionName({ isCreated, isPublished });

            const userEmail = get(assetData, ['additional', 'settings', isCreated ? 'createdBy' : 'lastEditedBy']);
            const user = userEmail && getFullUsernameFromEmail(userEmail);

            const diffResults = deepDiff.diff(previousAsset, assetData);
            const changes =
                diffResults &&
                diffResults.reduce((all, diff) => {
                    if (!diff.path) return all;

                    const change = availableChanges.find((item) =>
                        item.path.every((pathPart, pathIndex) => diff.path[pathIndex] === pathPart)
                    );

                    if (!change) return all;
                    if (all.find((item) => item.includes(change.name))) return all;

                    const changeAction = changesActionsMap[diff.kind];
                    if (!changeAction) return all;

                    return [...all, `${change.name} ${changeAction}`];
                }, []);

            return {
                date: updated,
                action,
                user,
                changes,
                assetData,
            };
        });
}

export async function fetchAssetHistory(provider, id) {
    const response = await this.query(GetAssetHistory, {
        newsroom: provider,
        assetId: id,
    });

    return prepareAssetHistoryData(response.assetHistory);
}
