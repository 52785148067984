import type { ChangeEventHandler, UIEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import css from './ScaleSlider.module.scss';

const MAX_SCALE_VALUE = 4;

interface RangeSliderProps {
    onChange: (scale: number) => void;
}

export function ScaleSlider({ onChange }: RangeSliderProps) {
    const [value, setValue] = useState(MAX_SCALE_VALUE);
    const isMaxValue = value >= MAX_SCALE_VALUE;
    const isMinValue = value <= 0;

    useEffect(() => {
        onChange(2 ** (MAX_SCALE_VALUE - value));
    }, [onChange, value]);

    const handleZoomIn: UIEventHandler<HTMLButtonElement> = () => {
        if (!isMaxValue) {
            setValue((v) => v + 1);
        }
    };

    const handleZoomOut: UIEventHandler<HTMLButtonElement> = () => {
        if (!isMinValue) {
            setValue((v) => v - 1);
        }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(() => Number(event.target.value));
    };

    return (
        <div className={css.container}>
            <button
                type="button"
                aria-label="zoom out"
                className={css.button}
                onClick={handleZoomOut}
                disabled={isMinValue}
            >
                <FiZoomOut />
            </button>

            <div className={css.slider}>
                <div className={css.steps}>
                    {Array.from({ length: MAX_SCALE_VALUE }, (_, index) => (
                        <span key={`scale-slider:step:${index}`} />
                    ))}
                </div>
                <input className={css.input} type="range" max={MAX_SCALE_VALUE} value={value} onChange={handleChange} />
            </div>

            <button
                type="button"
                aria-label="zoom in"
                className={css.button}
                onClick={handleZoomIn}
                disabled={isMaxValue}
            >
                <FiZoomIn />
            </button>
        </div>
    );
}
