import { Select, DateTimeInput, LabeledContainer, Label, Divider, Input, Checkbox } from '@schibsted-svp/react-ui';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { getCategory } from 'store/categories/selectors';
import type { Control, UseFormRegister, UseFormUnregister } from 'react-hook-form';
import type { FiltersDialogData } from 'components/core/FiltersDialog/types';
import { useFetchCategory } from 'components/categories/Form/hooks';
import { usePrevious } from 'hooks/usePrevious';
import { normalizeDate } from '../FiltersDialog';
import { getCustomMetadataFields } from './helpers';
import css from './DynamicFilters.module.scss';

interface DynamicFilterProps {
    register: UseFormRegister<FiltersDialogData>;
    control: Control<FiltersDialogData>;
    content: {
        type: string;
        options: { label: string; value: string }[];
        key: string;
    };
}

function DynamicFilter({ register, control, content: { type, options, key } }: DynamicFilterProps) {
    const dynamicFieldName = `customFields.${key}` as const;

    if (type === 'text') {
        return <Input {...register(dynamicFieldName)} size="small" />;
    }

    if (type === 'select') {
        return (
            <Controller
                name={dynamicFieldName}
                control={control}
                render={({ field }) => (
                    <Select {...field} size="small" options={options} splitButton={false} isClearable />
                )}
            />
        );
    }
    if (type === 'date') {
        return (
            <Controller
                name={dynamicFieldName}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <div className={css.date}>
                        <DateTimeInput
                            {...field}
                            onChange={(value) => field.onChange(normalizeDate(value))}
                            error={error?.message}
                            size="small"
                            isClearable
                            datePickerProps={{
                                selectsEnd: true,
                                dateFormat: 'dd.MM.yyyy',
                            }}
                        />
                    </div>
                )}
            />
        );
    }

    if (type === 'checkbox') {
        return <Checkbox {...register(dynamicFieldName)} containerClassName={css.checkbox} />;
    }
    return null;
}

interface DynamicFiltersContainerProps {
    provider: string;
    categoryId: number;
    register: UseFormRegister<FiltersDialogData>;
    control: Control<FiltersDialogData>;
    unregister: UseFormUnregister<FiltersDialogData>;
}

export function DynamicFiltersContainer({
    provider,
    categoryId,
    register,
    control,
    unregister,
}: DynamicFiltersContainerProps) {
    const category = useSelector((state) => getCategory(state, { provider, id: categoryId }));
    const categoryCustomFields = getCustomMetadataFields(category);

    const prevCategoryId = usePrevious(categoryId);

    const prevCategory = useSelector((state) => getCategory(state, { provider, id: prevCategoryId }));

    if (prevCategory && prevCategoryId !== categoryId) {
        const prevCategoryCustomFields = getCustomMetadataFields(prevCategory);
        prevCategoryCustomFields.map((field) => unregister(`customFields.${field[0]}`));
    }

    useFetchCategory({ provider, categoryId });

    if (!categoryCustomFields.length) {
        return null;
    }

    return (
        <>
            <Divider />
            <div className={css.customFiltersContainer}>
                <Label className={css.customFiltersLabel}>Category specific filters</Label>

                <div className={css.customFilters}>
                    {categoryCustomFields
                        .filter((field) => !field[1].hideInFilters)
                        .map((field) => {
                            const [key, content] = field;

                            content.key = key;
                            const { label } = content;
                            return (
                                <LabeledContainer
                                    label={`${label}:`}
                                    horizontalLabelSpace="small"
                                    labelWidth={100}
                                    variant="horizontal"
                                    key={label}
                                >
                                    <DynamicFilter register={register} control={control} content={content} />
                                </LabeledContainer>
                            );
                        })}
                </div>
            </div>
        </>
    );
}
