import { useWatch } from 'react-hook-form';
import type { Control, UseFormSetValue } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { TagsSuggestions as TagsSuggestionsUI } from 'components/core/TagsSuggestions';
import { useCallback } from 'react';
import type { FormValues } from '../types';

type TagsSuggestionsProps = {
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    asset: Asset;
};

export function TagsSuggestions({ control, setValue, asset }: TagsSuggestionsProps) {
    const [title, description, tags, subtitles] = useWatch({
        name: ['title', 'description', 'additional.tags', 'additional.subtitles'],
        control,
    });

    const addTag = useCallback(
        (tag) => setValue('additional.tags', [...tags, tag], { shouldDirty: true, shouldTouch: true }),
        [tags, setValue]
    );

    return (
        <TagsSuggestionsUI
            provider={asset.provider}
            onAddTag={addTag}
            formData={{ title, description, tags, subtitles }}
        />
    );
}
