import { call, put, takeLatest, select, takeEvery, apply, all, spawn } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import FileUploader from 'services/file-uploader';
import { apiClient } from 'services';
import config, { getS3Uri } from 'config';
import { get } from 'lodash';
import { watchForMqttEvents } from 'store/mqtt/client';
import { generateS3bucketPath, prepareImages } from './sagas-helpers';
import {
    categoriesFetchSuccess,
    categoriesFetchError,
    categoriesFetched,
    categorySubmissionSuccess,
    categoryVisibilityToggleSuccess,
    categorySubmissionError,
    fetchCategorySuccess,
} from './actions';
import { getCategoriesItems, getCategory } from './selectors';

import * as ActionType from './actionTypes';

export function* fetchCategories({ provider }) {
    try {
        const categories = yield select(getCategoriesItems, { provider });

        if (categories.items && categories.items.length) {
            yield put(categoriesFetched({ provider }));
            return;
        }

        const result = yield call(apiClient.fetchCategories, { provider });

        yield spawn(watchForMqttEvents, { topic: `${provider}/category/+/updated` });

        const defaultCategory = get(config, `asset.hiddenCategory.${provider}`);
        yield put(
            categoriesFetchSuccess({
                provider,
                categories: result.filter((category) => category.id !== defaultCategory),
            })
        );
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch categories',
            extras: {
                error,
            },
        });
        yield put(categoriesFetchError({ provider }));
    }
}

export function* fetchCategory({ payload: { provider, categoryId }, meta }) {
    try {
        const category = yield call(apiClient.fetchCategory, { provider, categoryId });

        yield put(fetchCategorySuccess(provider, category, meta));
        yield spawn(watchForMqttEvents, { topic: `${provider}/category/+/updated` });
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch category',
            extras: {
                error,
            },
        });
    }
}

export function* setSeriesFlag({ payload: { provider, categoryId, isSeries }, meta }) {
    const { additional, ...newCategory } = yield select(getCategory, { provider, id: categoryId });

    newCategory.isSeries = isSeries;
    try {
        const response = yield call(apiClient.saveCategory, { provider, payload: newCategory });
        yield put(categorySubmissionSuccess(provider, response, meta));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to set series flag',
            extras: {
                error,
            },
        });
        yield put(categorySubmissionError(meta));
    }
}

export function* submitCategory({ payload: { provider, data }, meta }) {
    try {
        const { imagesToUpload, mainImage, additionalImagesToSave } = prepareImages(getS3Uri, generateS3bucketPath)(
            provider,
            get(data, 'additional.images', []),
            get(data, 'additional.image')
        );

        if (imagesToUpload.length) {
            const fileUploader = yield call(FileUploader.create);

            yield all(
                imagesToUpload.map(({ file, name }) =>
                    apply(fileUploader, fileUploader.uploadFileWithoutProgress, [{ file, name }])
                )
            );
        }

        const payload = {
            ...data,
            additional: {
                ...data.additional,
                image: mainImage,
                images: additionalImagesToSave,
            },
        };
        const response = yield call(apiClient.saveCategory, { provider, payload });

        yield put(categorySubmissionSuccess(provider, response, meta));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to submit category',
            extras: {
                error,
            },
        });
        yield put(categorySubmissionError(meta));
    }
}

export function* toggleVisibility({ payload: { provider, categoryId }, meta }) {
    try {
        const { additional, ...newCategory } = yield select(getCategory, { provider, id: categoryId });

        newCategory.showCategory = !newCategory.showCategory;
        const response = yield call(apiClient.saveCategory, { provider, payload: newCategory });
        yield put(categoryVisibilityToggleSuccess(provider, response, meta));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to toggle category visibility',
            extras: {
                error,
            },
        });
    }
}

export default [
    takeLatest(ActionType.CATEGORIES_FETCH, fetchCategories),
    takeEvery(ActionType.CATEGORY_FETCH, fetchCategory),
    takeEvery(ActionType.CATEGORY_SUBMISSION, submitCategory),
    takeEvery(ActionType.CATEGORY_VISIBILITY_TOGGLE, toggleVisibility),
    takeEvery(ActionType.CATEGORY_SET_SERIES_FLAG, setSeriesFlag),
];
