import { hasPlayback, hasTextToSpeech } from 'models/asset';

/**
 * @param {Asset} asset
 * @returns {Object|undefined}
 */
export function getAssetChangesBeforeReplace(asset) {
    let changes;

    if (hasPlayback(asset)) {
        changes = {
            playback: null,
        };
    }

    if (hasTextToSpeech(asset)) {
        changes = {
            ...changes,
            additional: {
                metadata: {
                    ...asset.additional.metadata,
                    tts_disabled: 'true',
                },
            },
        };
    }

    return changes;
}
