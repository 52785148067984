import { createSelector } from '@reduxjs/toolkit';
import { at } from 'lodash';

export const getFilters = (state) => state.assetList?.filters;

export const getMainAssetsListItems = createSelector(
    (state) => state.items,
    (state) => state.itemIds,
    (items = {}, itemIds = []) => {
        return at(items, itemIds)
            .filter((item) => !!item)
            .map(({ asset }) => asset);
    }
);

export const getLinkedAssetsListItems = createSelector(
    (state) => state.items,
    (state) => state.assetList,
    (items = {}, assetList = {}) => {
        const { providers = [], items: ids = [] } = assetList;
        const linkedItems = providers
            .reduce((acc, provider) => [...acc, ...Object.values(items[provider] || {})], [])
            .map((item) => item?.asset)
            .filter((item) => Boolean(item));
        return [...new Set(ids)].reduce((acc, id) => [...acc, ...linkedItems.filter((asset) => asset.id === id)], []);
    }
);
