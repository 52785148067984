import { connect } from 'react-redux';
import { getAccess } from 'models/asset';
import { getAsset } from 'store/assets/selectors';
import { getNewsroomLivePaywallAccess } from 'config';
import EmbedDialog from './EmbedDialog';

const enhance = connect(({ assets }, { id, provider }) => {
    const asset = getAsset({ assets }, { provider, id });
    const defaultPaywallAccess = getNewsroomLivePaywallAccess(provider);
    return {
        asset,
        provider,
        paywallAccess: defaultPaywallAccess && getAccess(asset).some((item) => item === defaultPaywallAccess),
    };
});

export default enhance(EmbedDialog);
