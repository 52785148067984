import { Button } from '@schibsted-svp/react-ui';
import { MouseEventHandler, useState } from 'react';
import useToggle from 'hooks/useToggle';
import css from './SubtitlesCacheNotification.module.scss';

type SubtitlesCacheNotificationProps = {
    lastSavedAt?: number;
    loadSavedFormValues: () => void;
};

export function SubtitlesCacheNotification({ lastSavedAt, loadSavedFormValues }: SubtitlesCacheNotificationProps) {
    const [initialLastSavedAt] = useState(lastSavedAt);
    const [loadedSavedFormValues, toggleLoadedSavedFormValues] = useToggle();

    const handleSavedFormValuesLoad: MouseEventHandler<HTMLButtonElement> = () => {
        loadSavedFormValues();
        toggleLoadedSavedFormValues();
    };

    if (loadedSavedFormValues || !initialLastSavedAt || !lastSavedAt) {
        return null;
    }

    return (
        <div className={css.notification}>
            <span>Found local copy of recently edited subtitles from {new Date(lastSavedAt).toLocaleString()}</span>

            <Button type="button" variant="warning" size="compact" onClick={handleSavedFormValuesLoad}>
                Bring back latest copy
            </Button>
        </div>
    );
}
