import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification } from '@schibsted-svp/react-ui';
import { saveAsset } from 'store/assets/actions';
import { getAsset } from 'store/assets/selectors';
import { getMetadata, isRtmpEncoder } from 'models/asset';
import ConfirmationButton from 'components/core/ConfirmationButton/ConfirmationButton';
import css from './RtmpMigrateButton.module.scss';

export function RtmpMigrateButton({ assetId, provider }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const asset = useSelector((state) => getAsset(state, { id: assetId, provider }));
    const { migrationPlaybackUrl, ...restMetadata } = getMetadata(asset);

    const handleConfirm = async () => {
        const changes = {
            streamUrls: { hls: migrationPlaybackUrl },
            additional: { metadata: restMetadata },
        };

        setLoading(true);

        try {
            await new Promise((resolve, reject) => {
                dispatch(saveAsset({ id: assetId, provider, changes, resolve, reject }));
            });
            Notification.notify.success('Live stream migrated successfully');
        } catch (error) {
            Notification.notify.error('Failed to migrate the live stream');
        } finally {
            setLoading(false);
        }
    };

    if (!asset || !migrationPlaybackUrl || !isRtmpEncoder(asset)) {
        return null;
    }

    return (
        <div className={css.container}>
            <ConfirmationButton
                variant="primary"
                loading={loading}
                onConfirm={handleConfirm}
                className={css.migrateButton}
                connected
            >
                Migrate
            </ConfirmationButton>
        </div>
    );
}
