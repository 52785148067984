import classnames from 'classnames/bind';
import { MouseEventHandler, useCallback, useLayoutEffect, useState } from 'react';

import { formatRemainingExactTime } from 'lib/time';
import type { CutRange } from 'store/uploads/previews/slice';
import type { Thumbnail } from 'services/player-api-client/thumbnails';
import { usePrevious } from 'hooks/usePrevious';
import ThumbnailCard from 'components/video/VideoPoster/SuggestedThumbnails/Thumbnail';

import { useCustomPreviewDialogContext } from '../../CustomPreviewDialogContext';
import { useVideoPreviewEditorContext } from '../VideoPreviewEditorContext';
import { Timeline } from './Timeline';
import type { CutRangeSelectionEditMode, TimeMarker } from './types';
import { isCutRangeSelectorStateOnMouseDown } from './types';
import { minmax, toCSSPercent, toCSSPixel } from './helpers';
import { useCutRangeSelectorState, useRequestAnimation } from './hooks';
import css from './CutRangeSelector.module.scss';

const cln = classnames.bind(css);

interface CutRangeSelectorProps {
    cutRange?: CutRange;
    onCutRangeChange: (cutRange?: CutRange) => void;
    thumbnails: Thumbnail[];
}

export function CutRangeSelector({ cutRange, onCutRangeChange, thumbnails }: CutRangeSelectorProps) {
    const requestAnimation = useRequestAnimation();
    const { setPreventDialogClose } = useCustomPreviewDialogContext();
    const { duration, previewPlayer } = useVideoPreviewEditorContext();
    const {
        centerSelection,
        elementRef,
        getComputedAnimatedElements,
        getState,
        isMarkerHovered,
        mouseDown,
        mouseMove,
        mouseUp,
        scrollContainerBy,
        storeScrollLeftPosition,
    } = useCutRangeSelectorState();

    const [editMode, setEditMode] = useState<CutRangeSelectionEditMode | undefined>(undefined);
    const [isActive, setIsActive] = useState(false);
    const [isHoveringMarker, setIsHoveringMarker] = useState(false);
    const [timeMarker, setTimeMarker] = useState<TimeMarker | undefined>(undefined);
    const [cutRangeTooltips, setCutRangeTooltips] = useState<CutRange | undefined>(undefined);

    const thumbnailsLength = thumbnails.length;
    const prevThumbnailsLength = usePrevious(thumbnailsLength);

    const beginRatio = cutRange ? minmax(cutRange.begin / duration) : undefined;
    const endRatio = cutRange ? minmax(cutRange.end / duration) : undefined;
    const selectionRatio = cutRange ? minmax((cutRange.end - cutRange.begin) / duration) : undefined;
    const isTimeMarkerVisible = isActive && !editMode && !isHoveringMarker;

    const calculatePositions = useCallback(
        (currentCutRange?: CutRange) => {
            const animatedElements = getComputedAnimatedElements(duration, currentCutRange);
            if (!animatedElements) {
                return;
            }

            requestAnimation(() => {
                if (animatedElements.nextCutRange !== undefined) {
                    onCutRangeChange(animatedElements.nextCutRange);
                }
                if (animatedElements.nextCutRangeTooltips) {
                    setCutRangeTooltips(animatedElements.nextCutRangeTooltips);
                }
                if (animatedElements.scrollLeftDelta) {
                    scrollContainerBy(animatedElements.scrollLeftDelta);
                }
                setTimeMarker(animatedElements.nextTimeMarker);
            });
        },
        [getComputedAnimatedElements, duration, requestAnimation, onCutRangeChange, scrollContainerBy]
    );

    const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            event.stopPropagation();
            const mode = mouseDown(event, cutRange);
            if (!mode) {
                return;
            }

            previewPlayer?.videoElement?.pause();
            setEditMode(mode);
            setPreventDialogClose(true);
        },
        [cutRange, mouseDown, previewPlayer?.videoElement, setPreventDialogClose]
    );

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            const { target } = event;
            mouseMove(event);
            setIsHoveringMarker(() => isMarkerHovered(target));
            calculatePositions(cutRange);
        },
        [calculatePositions, cutRange, isMarkerHovered, mouseMove]
    );

    const handleMouseUp = useCallback(
        (event: MouseEvent) => {
            const state = getState();
            if (!isCutRangeSelectorStateOnMouseDown(state)) {
                return;
            }
            event.stopPropagation();
            const currentTime = mouseUp(event, duration);
            if (!cutRange && currentTime && previewPlayer?.videoElement) {
                previewPlayer.videoElement.currentTime = currentTime;
            }
            if (cutRange) {
                previewPlayer?.videoElement?.play();
            }
            setEditMode(undefined);
            setTimeout(() => setPreventDialogClose(false), 100);
        },
        [getState, cutRange, duration, mouseUp, setPreventDialogClose, previewPlayer?.videoElement]
    );

    const handleMouseEnter: MouseEventHandler<HTMLDivElement> = () => {
        setIsActive(true);
    };

    const handleMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
        setIsActive(false);
    };

    const handleScroll = useCallback<MouseEventHandler<HTMLDivElement>>(
        (event) => {
            storeScrollLeftPosition(event.currentTarget.scrollLeft);
            calculatePositions(cutRange);
        },
        [calculatePositions, cutRange, storeScrollLeftPosition]
    );

    const handleWheel = useCallback(
        (event: WheelEvent) => {
            event.preventDefault();

            const delta = Math.abs(event.deltaY) < Math.abs(event.deltaX) ? event.deltaX : event.deltaY;
            scrollContainerBy(delta);
        },
        [scrollContainerBy]
    );

    useLayoutEffect(() => {
        const { container } = getState();

        // replace vertical scroll with horizontal one when hovering the range selector
        container?.addEventListener('wheel', handleWheel, { passive: false, capture: true });

        // avoid closing the dialog by a mistake when a user moved cursor outside the dialog during selection
        document.addEventListener('mouseup', handleMouseUp, { capture: true });

        return () => {
            container?.removeEventListener('wheel', handleWheel, { capture: true });
            document.removeEventListener('mouseup', handleMouseUp, { capture: true });
        };
    }, [getState, handleMouseUp, handleWheel]);

    useLayoutEffect(() => {
        if (prevThumbnailsLength !== thumbnailsLength) {
            // center current selection when switching scale
            centerSelection(cutRange);

            // recalculate time tooltip positions when switching scale
            calculatePositions(cutRange);
        }
    }, [calculatePositions, cutRange, centerSelection, prevThumbnailsLength, thumbnailsLength]);

    return (
        <div
            className={css.wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            onMouseDown={handleMouseDown}
        >
            <div
                ref={elementRef('container')}
                className={cln(css.container, { active: isActive, [`${editMode}`]: true })}
                onScroll={handleScroll}
            >
                <Timeline duration={duration} thumbnails={thumbnails} />

                <div className={css.thumbnailList}>
                    {thumbnails.map(({ startTime, url }) => (
                        <ThumbnailCard
                            key={`thumb:${startTime}`}
                            className={css.thumbnail}
                            startTime={startTime}
                            url={url}
                        />
                    ))}
                </div>

                <div
                    ref={elementRef('slider')}
                    className={cln(css.slider, { selected: cutRange !== undefined })}
                    style={{ gridTemplateColumns: `${toCSSPercent(beginRatio)} ${toCSSPercent(selectionRatio)} auto` }}
                >
                    <div className={css.leftTrimShape} />
                    <div ref={elementRef('selector')} className={css.selector}>
                        <div ref={elementRef('markerLeft')} className={css.leftMarker} />
                        <div ref={elementRef('markerRight')} className={css.rightMarker} />
                    </div>
                    <div className={css.rightTrimShape} />
                </div>
            </div>

            <div
                className={cln(css.timeTooltip, { visible: beginRatio !== undefined })}
                style={{ left: toCSSPixel(cutRangeTooltips?.begin) }}
            >
                {formatRemainingExactTime(beginRatio ? beginRatio * duration : 0)}
            </div>
            <div
                className={cln(css.timeTooltip, { visible: endRatio !== undefined })}
                style={{ left: toCSSPixel(cutRangeTooltips?.end) }}
            >
                {formatRemainingExactTime(endRatio ? endRatio * duration : 0)}
            </div>

            <div
                className={cln(css.timeMarker, { visible: isTimeMarkerVisible })}
                style={{ left: toCSSPixel(timeMarker?.left) }}
            >
                <div className={cln(css.timeTooltip, 'visible', 'current')}>
                    {formatRemainingExactTime(timeMarker?.value ?? 0)}
                </div>
            </div>
        </div>
    );
}
