import { useSelector } from 'react-redux';
import { useWatch, type Control } from 'react-hook-form';
import { getCategory } from 'store/categories/selectors';
import { usePrevious } from 'hooks/usePrevious';
import type { FormValues } from '../types';

export function useCategoryWatch({ provider, control }: { provider: string; control: Control<FormValues> }) {
    const categoryId = useWatch({ control, name: 'category.id' });
    const prevCategoryId = usePrevious(categoryId);
    const category = useSelector((state) => getCategory(state, { provider, id: categoryId }));
    return { prevCategoryId, categoryId, category };
}
