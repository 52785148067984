import type { AnyAction } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { merge } from 'lib/object';
import { UPLOAD_SUBTITLES, UPLOAD_SUBTITLES_COMPLETE, UPLOAD_SUBTITLES_FAILURE } from './actions';

type SubtitlesUploadsState = {
    [provider: string]: {
        [assetId: number]: {
            [language: string]: {
                filename: string;
                error?: string;
            };
        };
    };
};

export default (state: SubtitlesUploadsState = {}, { type, provider, assetId, language, ...data }: AnyAction) => {
    switch (type) {
        case UPLOAD_SUBTITLES: {
            return merge(state, {
                [provider]: {
                    [assetId]: {
                        [language]: {
                            filename: data.file.name,
                            error: undefined,
                        },
                    },
                },
            });
        }
        case UPLOAD_SUBTITLES_COMPLETE: {
            return omit(state, `${provider}.${assetId}.${language}`);
        }
        case UPLOAD_SUBTITLES_FAILURE: {
            return merge(state, {
                [provider]: {
                    [assetId]: {
                        [language]: {
                            error: data.error,
                        },
                    },
                },
            });
        }
        default:
            return state;
    }
};
