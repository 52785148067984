import { get, uniqBy, maxBy } from 'lodash';
import { defaultEntityState as defaultLiveAdsState } from 'store/live/reducers/adsReducer';
import { getAsset } from 'store/assets/selectors';
import {
    isStarted,
    isExternalOrRtmpEncoder,
    isEmptyEncoder,
    isLive,
    isMediaLiveEncoder,
    isElementalEncoder,
    isScheduled,
    getScheduledLiveDuration,
} from 'models/asset';
import { isChannelIdle, isChannelRunning } from 'models/channel';
import { isRunning, isPending } from 'models/liveEvent';
import { atLeastOneEventIsRunning, isMultiLiveEventIdle } from 'models/multiLiveEvent';
import { relative } from 'lib/date';
import config from 'config';

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.provider
 * @return {Object<String, Object>|undefined}
 */
export const getMediaLiveChannels = (state, { provider }) => state.live?.channels[provider]?.items;

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @return {Object|undefined}
 */
export const getMediaLiveChannel = (state, { assetId, provider }) => {
    const channels = getMediaLiveChannels(state, { provider }) || {};
    return Object.values(channels).find(
        (channel) => channel.asset.id === assetId && channel.asset.provider === provider
    );
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @return {Boolean}
 */
export const getIsMediaLiveChannelStarting = (state, { provider, assetId }) => {
    const channel = getMediaLiveChannel(state, { provider, assetId });
    return ['creating', 'starting', 'idle'].includes(channel?.state);
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.provider
 * @return {Boolean}
 */
export function areMediaLiveChannelsReady(state, { provider }) {
    return Boolean(getMediaLiveChannels(state, { provider }));
}

/**
 * @param {Object} state
 * @return {Array<Object>|undefined}
 */
export const getLiveEncoders = (state) => get(state, `live.encoders.${state.newsroom}.items`);

/**
 * @param {Object} state
 * @returns {Boolean}
 */
export function areLiveEncodersReady(state) {
    return getLiveEncoders(state) !== undefined;
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.encoderId
 * @returns {Object|undefined}
 */
export function getLiveEncoder(state, { encoderId }) {
    return get(state, `live.encoders.${state.newsroom}.items.${encoderId}`);
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.newsroom
 * @return {Boolean}
 */
export const areLiveEncodersLoading = (state, { newsroom }) => get(state, `live.encoders.${newsroom}.loading`, false);

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.newsroom
 * @return {Boolean}
 */
export const areLiveEncodersSupported = (state, { newsroom }) =>
    Object.keys(get(state, `live.encoders.${newsroom}.items`, {})).length > 0;

/**
 * @param {Object} encoders
 * @returns {Object[]}
 */
const flattenEncoderEvents = (encoders) =>
    Object.values(encoders).reduce((events, encoder) => [...events, ...Object.values(encoder.events || {})], []);

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @return {Array<Object>}
 */
export function findLiveEncoderEvents(state, { assetId, provider }) {
    const encoders = getLiveEncoders(state) || {};

    return flattenEncoderEvents(encoders)
        .filter(({ asset }) => asset != null && assetId === asset.id && provider === asset.provider)
        .sort((a, b) => a.id - b.id);
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @return {Array<String>}
 */
export function findLiveEncoderEventsUrl(state, { assetId, provider }) {
    const events = findLiveEncoderEvents(state, { assetId, provider });
    return events.map(
        (event) => `${getLiveEncoder(state, { provider, encoderId: event?.encoder.id })?.url}/live_events/${event.id}`
    );
}

/**
 * @param {String} name
 * @param {LiveEvent} [event=]
 * @return {String}
 */
const getLiveEncoderOptionLabel = (name, event) => {
    if (!event) {
        return name;
    }

    const labelOpts = [];
    const withAsset = (prefix, asset) => {
        if (!asset) {
            return prefix;
        }
        return `${prefix}: ${asset.id}`;
    };

    if (isPending(event)) {
        labelOpts.push(withAsset('scheduled', event.asset));
    }
    if (isRunning(event)) {
        labelOpts.push(withAsset('occupied', event.asset));
    }
    if (event.endTime) {
        const date = relative(event.endTime * 1000);
        labelOpts.push(`ends: ${date.toLowerCase()}`);
    }

    if (!labelOpts.length) {
        return name;
    }

    return `${name} (${labelOpts.join(', ')})`;
};

/**
 * @param {Object} state
 * @return {Array<Object>|undefined}
 */
export const getLiveEncoderOptions = (state) => {
    const encoders = getLiveEncoders(state);

    if (!encoders) {
        return undefined;
    }

    // filter out encoders without defined names
    return Object.values(encoders).map((encoder) => ({
        label: encoder.name,
        options: [
            ...Object.values(encoder.inputs)
                .filter(({ name }) => name)
                .map(({ id: inputId, name, event }) => ({
                    label: getLiveEncoderOptionLabel(name, event),
                    encoderId: encoder.id,
                    inputId,
                    value: `${encoder.id}:${inputId}`,
                    disabled: Boolean(event),
                    pending: isPending(event),
                    running: isRunning(event),
                })),
            {
                label: 'Network',
                encoderId: encoder.id,
                inputId: undefined,
                value: `${encoder.id}:network`,
            },
        ],
    }));
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @returns {Boolean}
 */
export const isLiveRuntimeStarting = (state, { assetId, provider }) => {
    return state.live?.runtime[provider]?.[assetId]?.isStarting || false;
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @returns {Boolean}
 */
export const isLiveRuntimeStopping = (state, { assetId, provider }) => {
    return state.live?.runtime[provider]?.[assetId]?.isStopping || false;
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @returns {Boolean}
 */
export const isLiveRuntimeEditing = (state, { assetId, provider }) => {
    return state.live?.runtime[provider]?.[assetId]?.isEditing || false;
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @returns {Boolean}
 */
export const isLiveRuntimeUnpublishing = (state, { assetId, provider }) => {
    return state.live?.runtime[provider]?.[assetId]?.isUnpublishing || false;
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.newsroom
 * @return {Object}
 */
export const getAdsPlacementStatus = (state, { assetId, newsroom }) =>
    get(state, `live.ads.${newsroom}.${assetId}`, {
        ...defaultLiveAdsState,
    });

/**
 * @param {Object} state
 * @return {{liveAssets: Asset[], readyToPublishAssets: Asset[]}}
 */
export const getSplittedAssetsByStatus = (state) => {
    const {
        live: {
            liveAssets: { list },
        },
        newsroom,
    } = state;

    const liveAssets = [];
    const readyToPublishAssets = [];
    list.forEach((assetId) => {
        const asset = getAsset(state, { id: assetId, provider: newsroom });
        if (!asset || asset.streamType !== 'live') {
            return;
        }
        if (asset.status === 'active') {
            liveAssets.push(asset);
        } else if (asset.status === 'readyToPublish') {
            readyToPublishAssets.push(asset);
        }
    });

    return { liveAssets, readyToPublishAssets };
};

export const getCurrentAndUpcomingLiveAssets = (state) => {
    const { liveAssets } = getSplittedAssetsByStatus(state);
    const currentLiveAssets = [];
    const upcomingLiveAssets = [];

    liveAssets.forEach((liveAsset) => {
        if (isStarted(liveAsset) && !isEmptyEncoder(liveAsset)) {
            currentLiveAssets.push(liveAsset);
        } else {
            upcomingLiveAssets.push(liveAsset);
        }
    });

    upcomingLiveAssets.sort((liveOne, liveTwo) => {
        return liveOne.flightTimes.start - liveTwo.flightTimes.start;
    });

    return { currentLiveAssets, upcomingLiveAssets };
};

/**
 * @param {Object} state
 * @param {String} userEmail
 * @return {Asset[]}
 */
export const getUserRunningLiveAssets = (state, userEmail) => {
    const { liveAssets, readyToPublishAssets } = getSplittedAssetsByStatus(state);

    return [...liveAssets, ...readyToPublishAssets].filter((asset) => {
        if (asset.additional.settings?.createdBy !== userEmail) {
            return false;
        }
        if (isMediaLiveEncoder(asset)) {
            return Boolean(getMediaLiveChannel(state, { assetId: asset.id, provider: asset.provider }));
        }
        if (isElementalEncoder(asset)) {
            return findLiveEncoderEvents(state, { assetId: asset.id, provider: asset.provider }).length > 0;
        }
        return isExternalOrRtmpEncoder(asset);
    });
};

/**
 * @param {Number} startTime
 * @return {Boolean}
 */
const isStartTimeAboveLiveThreshold = (startTime) => Date.now() / 1000 - startTime > config.longRunningLiveThreshold;

/**
 * @param {Object} state
 * @param {String} userEmail
 * @return {Asset[]}
 */
export const getUserTooLongRunningLiveAssets = (state, userEmail) => {
    const userRunningLiveAssets = getUserRunningLiveAssets(state, userEmail);
    return userRunningLiveAssets.filter((asset) => {
        const { id: assetId, provider } = asset;
        const startTime = asset.flightTimes?.start || asset.created;

        if (isMediaLiveEncoder(asset)) {
            const channel = getMediaLiveChannel(state, { assetId, provider });
            return isChannelRunning(channel) && isStartTimeAboveLiveThreshold(startTime);
        }
        if (isElementalEncoder(asset)) {
            const events = findLiveEncoderEvents(state, { assetId, provider });
            return atLeastOneEventIsRunning(events) && isStartTimeAboveLiveThreshold(startTime);
        }
        return isStartTimeAboveLiveThreshold(startTime);
    });
};

/**
 * @typedef {Object} BaseEncoderState
 * @property {Number} [duration]
 * @property {Object} [schedule]
 * @property {Number} [schedule.startTime]
 * @property {Number} [schedule.endTime]
 * @property {Boolean} [isRunning]
 * @property {Boolean} [isIdle]
 * @property {Boolean} [isScheduled]
 */
/**
 * @param {Asset} asset
 * @returns {BaseEncoderState}
 */
function baseEncoderMapper(asset) {
    return {
        duration: getScheduledLiveDuration(asset),
        schedule: undefined,
        isIdle: false,
        isRunning: true, // true by default, used for external and rtmp encoder
        isScheduled: isScheduled(asset),
    };
}

/**
 * @typedef {BaseEncoderState & {
 *     isStarting: Boolean,
 * }} MediaLiveEncoderState
 */
/**
 * @param {Asset} asset
 * @param {MediaLiveChannel} channel
 * @returns {MediaLiveEncoderState}
 */
function mediaLiveEncoderMapper(asset, channel) {
    return {
        ...baseEncoderMapper(asset),
        schedule: channel?.schedule,
        isIdle: ['creating', 'idle'].includes(channel?.state),
        isRunning: ['starting', 'running', 'stopping'].includes(channel?.state),
        isStarting: ['starting'].includes(channel?.state),
    };
}

/**
 * @typedef {BaseEncoderState & {
 *     hasError: Boolean,
 *     hasFailed: Boolean
 * }} ElementalEncoderState
 */
/**
 * @param {Asset} asset
 * @param {MultiLiveEvent} events
 * @returns {ElementalEncoderState}
 */
function elementalEncoderMapper(asset, events) {
    return {
        ...baseEncoderMapper(asset),
        schedule: events?.[0]?.schedule,
        isIdle:
            events.length > 0 &&
            events.some(({ status }) => status === 'pending') &&
            events.some(({ status }) => status !== 'running'),
        isRunning: events.length > 0 && events.some(({ status }) => status === 'running'),
        hasError: events.length > 0 && events.some(({ status }) => status === 'error'),
        hasFailed: events.length > 0 && events.every(({ status }) => status === 'error'),
    };
}

/**
 * @param {Object} state
 * @param {Asset} asset
 * @returns {MediaLiveEncoderState|ElementalEncoderState|BaseEncoderState|undefined}
 */
export function getEncoderStateForAsset(state, asset) {
    if (!isLive(asset)) {
        return undefined;
    }
    if (isMediaLiveEncoder(asset)) {
        const channel = getMediaLiveChannel(state, { assetId: asset.id, provider: asset.provider });
        return mediaLiveEncoderMapper(asset, channel);
    }
    if (isElementalEncoder(asset)) {
        const events = findLiveEncoderEvents(state, { assetId: asset.id, provider: asset.provider });
        return elementalEncoderMapper(asset, events);
    }
    return baseEncoderMapper(asset);
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @returns {MediaLiveEncoderState|ElementalEncoderState|BaseEncoderState|undefined}
 */
export function getEncoderState(state, { assetId, provider }) {
    const asset = getAsset(state, { id: assetId, provider });
    return getEncoderStateForAsset(state, asset);
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @return {Boolean}
 */
export function isIdleLive(state, { assetId, provider }) {
    const asset = getAsset(state, { id: assetId, provider });
    if (isLive(asset) && isMediaLiveEncoder(asset)) {
        const channel = getMediaLiveChannel(state, { assetId, provider });
        return isChannelIdle(channel);
    }
    if (isLive(asset) && isElementalEncoder(asset)) {
        const events = findLiveEncoderEvents(state, { assetId, provider });
        return isMultiLiveEventIdle(events);
    }
    return false;
}

/**
 * @param {Object} state
 * @param {String} userEmail
 * @return {Asset[]}
 */
export const getUserIdleLiveAssets = (state, userEmail) => {
    const userRunningLiveAssets = getUserRunningLiveAssets(state, userEmail);
    return userRunningLiveAssets.filter((asset) => {
        const { id: assetId, provider } = asset;
        const startTime = asset.flightTimes?.start || asset.created;
        const isIdle = isIdleLive(state, { assetId, provider });
        return isIdle && isStartTimeAboveLiveThreshold(startTime);
    });
};

export const getWasLiveAssetsList = (state) => {
    const {
        live: {
            wasLiveAssets: { list },
        },
        newsroom,
    } = state;

    const wasLiveAssets = [];

    list.forEach((assetId) => {
        const asset = getAsset(state, { id: assetId, provider: newsroom });
        if (!asset || asset.streamType !== 'wasLive') {
            return;
        }
        wasLiveAssets.push(asset);
    });

    return wasLiveAssets;
};

export const getIsFetchingFlag = (state) => {
    const {
        live: { liveAssets, wasLiveAssets },
    } = state;

    return get(liveAssets, 'isFetching') || get(wasLiveAssets, 'isFetching');
};

export const getErrors = (state) => {
    const {
        live: { liveAssets, wasLiveAssets },
    } = state;

    return { liveErrorMessage: get(liveAssets, 'error'), wasLiveErrorMessage: get(wasLiveAssets, 'error') };
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.newsroom
 * @param {Number} [props.assetId]
 * @returns {Object[]}
 */
export function getLiveNotifications(state, { newsroom, assetId }) {
    return (
        state.live?.notifications?.[newsroom]?.items?.filter((notification) =>
            assetId ? notification.assetId === assetId : true
        ) || []
    );
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.newsroom
 * @param {Number} [props.assetId]
 * @returns {Object[]}
 */
export function getSortedAndLimitedLiveNotifications(state, { newsroom, assetId, limit }) {
    return getLiveNotifications(state, { newsroom, assetId })
        .sort((errorOne, errorTwo) => errorTwo.timestamp - errorOne.timestamp)
        .slice(0, limit);
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.newsroom
 * @param {Number} props.assetId
 * @returns {Boolean}
 */
export function hasLiveAssetFailed(state, { newsroom, assetId }) {
    return getLiveNotifications(state, { newsroom, assetId }).some(({ level }) => level === 'error');
}

/**
 *
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.newsroom
 * @param {Number} [props.assetId]
 * @returns {Object[]}
 */
export function getLiveErrorNotifications(state, { newsroom, assetId }) {
    return getLiveNotifications(state, { newsroom, assetId }).filter(({ level }) => level === 'error');
}

/**
 *
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.newsroom
 * @param {Number} props.assetId
 * @returns {Boolean}
 */
export function isErrorCleared(state, { newsroom, assetId }) {
    const errorNotifications = getLiveErrorNotifications(state, { newsroom, assetId });

    if (!errorNotifications.length) {
        return false;
    }

    const lastError = maxBy(errorNotifications, 'timestamp');

    const allNotifications = getLiveNotifications(state, { newsroom, assetId });

    return allNotifications.some(({ level, timestamp }) => level === 'info' && timestamp >= lastError.timestamp);
}

/**
 * @param {Object} state
 * @param {String} newsroom
 * @param {String} userEmail
 * @returns {Array<Object>}
 */
export function getFailedLiveAssetsCreatedBySpecificUser(state, newsroom, userEmail, limit) {
    const errorNotificationsSortedByNewest = getSortedAndLimitedLiveNotifications(state, { newsroom, limit }).filter(
        ({ level }) => level === 'error'
    );
    const uniqueFailedLiveAssets = uniqBy(errorNotificationsSortedByNewest, 'assetId');

    const acceptedAssetStatuses = ['active', 'readyToPublish'];

    const failedVideosCreatedBySpecificUser = uniqueFailedLiveAssets.reduce((failedLiveAssets, currentLiveAsset) => {
        const asset = getAsset(state, { id: currentLiveAsset.assetId, provider: newsroom });

        const shouldAssetBeOmitted =
            !asset ||
            !isLive(asset) ||
            isErrorCleared(state, { newsroom, assetId: currentLiveAsset.assetId }) ||
            !acceptedAssetStatuses.includes(asset.status);

        if (shouldAssetBeOmitted) {
            return failedLiveAssets;
        }

        if (asset?.additional?.settings?.createdBy === userEmail) {
            failedLiveAssets.push(asset);
        }
        return failedLiveAssets;
    }, []);

    return failedVideosCreatedBySpecificUser;
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.assetId
 * @param {String} props.provider
 * @return {Boolean}
 */
export function isEncoderReady(state, { assetId, provider }) {
    const asset = getAsset(state, { id: assetId, provider });
    if (!asset) {
        return false;
    }
    if (isElementalEncoder(asset)) {
        return areLiveEncodersReady(state);
    }
    if (isMediaLiveEncoder(asset)) {
        return areMediaLiveChannelsReady(state, { provider });
    }
    return true;
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.id
 * @param {String} props.newsroom
 * @return {Boolean}
 */
export const isLiveStreamDuplicating = (state) => state?.live?.liveAssets?.isDuplicating || false;

/**
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.id
 * @param {String} props.newsroom
 * @return {Number}
 */
export const getDuplicatedAssetId = (state) => state?.live?.liveAssets?.duplicatedAssetId || undefined;
