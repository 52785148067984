/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.id
 * @returns {Promise<{assets: *}>}
 */

import config from 'config';

export default async function getRelatedAssets({ provider, id }) {
    const query = {
        additional: config.asset.additional.join('|'),
    };
    return this.get(`/${provider}/${id}`, { query });
}
