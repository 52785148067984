import { get, keyBy, omit, set } from 'lodash';
import { merge } from 'lib/object';

import { PLAYLISTS_FETCH_LIST_SUCCESS } from 'store/playlists/shared';
import { STATIC_PLAYLISTS_FETCH_SUCCESS } from 'store/playlists/staticItems';

export const PLAYLISTS_FETCH = 'Playlists/FETCH';

export const PLAYLISTS_FETCH_SUCCESS = 'Playlists/FETCH_SUCCESS';
export const PLAYLISTS_FETCH_ERROR = 'Playlists/FETCH_ERROR';

export const PLAYLISTS_CREATE = 'Playlists/CREATE';
export const PLAYLISTS_CREATE_SUCCESS = 'Playlists/CREATE_SUCCESS';
export const PLAYLISTS_CREATE_ERROR = 'Playlists/CREATE_ERROR';

export const PLAYLISTS_UPDATE = 'Playlists/UPDATE';
export const PLAYLISTS_UPDATE_SUCCESS = 'Playlists/UPDATE_SUCCESS';
export const PLAYLISTS_UPDATE_ERROR = 'Playlists/UPDATE_ERROR';

export const PLAYLISTS_ASSETS_FETCH = 'Playlists/ASSETS_FETCH';
export const PLAYLISTS_ASSETS_FETCH_SUCCESS = 'Playlists/ASSETS_FETCH_SUCCESS';

export const PLAYLISTS_DELETE = 'Playlists/PLAYLISTS_DELETE';
export const PLAYLISTS_DELETE_SUCCESS = 'Playlists/PLAYLISTS_DELETE_SUCCESS';
export const PLAYLISTS_DELETE_ERROR = 'Playlists/PLAYLISTS_DELETE_ERROR';

export const PLAYLISTS_DYNAMIC_ASSETS_CLEAR = 'Playlists/PLAYLISTS_DYNAMIC_ASSETS_CLEAR';

export const PLAYLISTS_GENERATOR_ASSETS_FETCH = 'Playlists/GENERATOR_ASSETS_FETCH';
export const PLAYLISTS_GENERATOR_ASSETS_FETCH_SUCCESS = 'Playlists/GENERATOR_ASSETS_FETCH_SUCCESS';
export const PLAYLISTS_GENERATOR_ASSETS_FETCH_ERROR = 'Playlists/PLAYLISTS_GENERATOR_ASSETS_FETCH_ERROR';

export const PLAYLISTS_GENERATOR_FETCH_ERROR = 'Playlists/PLAYLISTS_GENERATOR_FETCH_ERROR';

export const PLAYLIST_OF_PLAYLISTS_ITEMS_FETCH = 'Playlists/PLAYLIST_OF_PLAYLISTS_ITEMS_FETCH';
export const PLAYLIST_OF_PLAYLISTS_ITEMS_FETCH_SUCCESS = 'Playlists/PLAYLIST_OF_PLAYLISTS_ITEMS_FETCH_SUCCESS';

export const playlistsDynamicAssetsClear = () => ({
    type: PLAYLISTS_DYNAMIC_ASSETS_CLEAR,
    payload: {},
});

export const playlistsGeneratorAssetsFetch = ({ provider, playlistId, filtersOutsideRedux }) => ({
    type: PLAYLISTS_GENERATOR_ASSETS_FETCH,
    payload: {
        provider,
        playlistId,
        filtersOutsideRedux,
    },
});

export const playlistsGeneratorAssetsFetchSuccess = ({ assets, provider, playlistId }) => ({
    type: PLAYLISTS_GENERATOR_ASSETS_FETCH_SUCCESS,
    payload: {
        assets,
        provider,
        playlistId,
    },
});

export const playlistsGeneratorAssetsFetchError = ({ provider, playlistId }) => ({
    type: PLAYLISTS_GENERATOR_ASSETS_FETCH_ERROR,
    payload: {
        provider,
        playlistId,
    },
});

export const playlistsGeneratorFetchError = ({ provider, playlists }) => ({
    type: PLAYLISTS_GENERATOR_FETCH_ERROR,
    payload: {
        provider,
        playlists,
    },
});

export const playlistsFetch = ({
    provider,
    id,
    name = '',
    parentPlaylistId = undefined,
    parentPlaylistProvider = undefined,
}) => ({
    type: PLAYLISTS_FETCH,
    payload: {
        provider,
        name,
        id,
        parentPlaylistId,
        parentPlaylistProvider,
    },
});

export const playlistsFetchSuccess = ({ provider, playlists, id }) => ({
    type: PLAYLISTS_FETCH_SUCCESS,
    payload: {
        provider,
        playlists,
        id,
    },
});

export const playlistsFetchError = ({ provider, id }) => ({
    type: PLAYLISTS_FETCH_ERROR,
    payload: {
        provider,
        id,
    },
    error: true,
});

export const playlistsCreate = ({ provider, name, header, labels, searchQuery, items, assets }) => ({
    type: PLAYLISTS_CREATE,
    payload: {
        provider,
        name,
        header,
        labels,
        searchQuery,
        items,
        assets,
    },
    meta: {
        thunk: true,
    },
});

export const playlistsCreateSuccess = ({ provider, playlist, meta }) => ({
    type: PLAYLISTS_CREATE_SUCCESS,
    payload: {
        provider,
        playlist,
    },
    meta,
});

export const playlistsCreateError = ({ meta }) => ({
    type: PLAYLISTS_CREATE_ERROR,
    error: true,
    meta,
});

export const playlistsUpdate = ({ id, provider, name, header, labels, searchQuery, items, assets }) => ({
    type: PLAYLISTS_UPDATE,
    payload: {
        id,
        provider,
        name,
        header,
        labels,
        searchQuery,
        items,
        assets,
    },
    meta: {
        thunk: true,
    },
});

export const playlistsUpdateSuccess = ({ provider, playlist, meta }) => ({
    type: PLAYLISTS_UPDATE_SUCCESS,
    payload: {
        provider,
        playlist,
    },
    meta,
});

export const playlistsUpdateError = ({ meta }) => ({
    type: PLAYLISTS_UPDATE_ERROR,
    error: true,
    meta,
});

export const playlistsAssetsFetch = ({ provider, playlists }) => ({
    type: PLAYLISTS_ASSETS_FETCH,
    payload: {
        provider,
        playlists,
    },
});

export const playlistsAssetsFetchSuccess = ({ assets, provider, playlistId, staticAssets, originProvider }) => ({
    type: PLAYLISTS_ASSETS_FETCH_SUCCESS,
    payload: {
        assets,
        provider,
        playlistId,
        staticAssets,
        originProvider,
    },
});

export const playlistsDelete = ({ id, provider }) => ({
    type: PLAYLISTS_DELETE,
    payload: { id, provider },
});

export const playlistsDeleteSuccess = ({ id, provider }) => ({
    type: PLAYLISTS_DELETE_SUCCESS,
    payload: { id, provider },
});

export const playlistsDeleteError = ({ id, provider }) => ({
    type: PLAYLISTS_DELETE_ERROR,
    payload: { id, provider },
});

export const playlistOfPlaylistsItemsFetch = ({ playlists, provider }) => ({
    type: PLAYLIST_OF_PLAYLISTS_ITEMS_FETCH,
    payload: {
        playlists,
        provider,
    },
});

export const playlistOfPlaylistsItemsFetchSuccess = ({ provider, id }) => ({
    type: PLAYLIST_OF_PLAYLISTS_ITEMS_FETCH_SUCCESS,
    payload: {
        provider,
        id,
    },
});

export const overwriteMerge = (_, sourceArray) => sourceArray;

export default function reducer(state = {}, action) {
    const provider = get(action, 'payload.provider');
    switch (action.type) {
        case PLAYLIST_OF_PLAYLISTS_ITEMS_FETCH: {
            const { playlists } = action.payload;

            const fetchingPlaylists = keyBy(
                playlists.map((playlist) => set(playlist, 'isFetching', true)),
                'id'
            );

            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    ...fetchingPlaylists,
                },
            });
        }

        case PLAYLISTS_FETCH: {
            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    [action.payload.id]: {
                        ...get(state, [provider, action.payload.id]),
                        isFetching: true,
                        error: false,
                    },
                },
            });
        }

        case PLAYLIST_OF_PLAYLISTS_ITEMS_FETCH_SUCCESS: {
            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    [action.payload.id]: {
                        ...get(state, [provider, action.payload.id]),
                        isFetching: false,
                        error: false,
                    },
                },
            });
        }

        case PLAYLISTS_FETCH_SUCCESS:
        case PLAYLISTS_FETCH_LIST_SUCCESS:
        case STATIC_PLAYLISTS_FETCH_SUCCESS: {
            const { playlists, id } = action.payload;

            if (!playlists.length && id) {
                return merge(state, {
                    [provider]: {
                        ...get(state, [provider], {}),
                        [id]: { items: null, isFetching: false, error: false },
                    },
                });
            }

            const fetchedPlaylists = playlists.map((playlist) => set(playlist, 'isFetching', false));

            return merge(state, {
                [provider]: keyBy(fetchedPlaylists, 'id'),
            });
        }

        case PLAYLISTS_FETCH_ERROR: {
            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    [action.payload.id]: {
                        ...get(state, [provider, action.payload.id]),
                        isFetching: false,
                        error: true,
                    },
                },
            });
        }

        case PLAYLISTS_CREATE_SUCCESS: {
            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    [action.payload.playlist.id]: action.payload.playlist,
                },
            });
        }

        case PLAYLISTS_UPDATE_SUCCESS: {
            return merge(
                state,
                {
                    [provider]: {
                        ...get(state, [provider], {}),
                        [action.payload.playlist.id]: action.payload.playlist,
                    },
                },
                { arrayMerge: overwriteMerge }
            );
        }

        case PLAYLISTS_ASSETS_FETCH: {
            const playlists = action.payload.playlists.reduce((acc, playlist) => {
                const shouldBeResolved = Boolean(playlist.searchQuery || playlist.items.length);

                acc[playlist.id] = {
                    isFetching: shouldBeResolved,
                };
                return acc;
            }, {});

            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    ...playlists,
                },
            });
        }

        case PLAYLISTS_ASSETS_FETCH_SUCCESS: {
            const playlistWithResolvedAssets = {
                assets: action.payload.assets.map((asset, index) => {
                    const isStaticId = action.payload.staticAssets.some(({ id }) => id === asset.id);
                    const isStaticAsset = Boolean(
                        action.payload.staticAssets.find(({ position }) => position === index)
                    );

                    return {
                        position: index,
                        isStatic: isStaticId && isStaticAsset,
                        asset: {
                            id: asset.id,
                            provider: asset.provider,
                            duration: asset.duration,
                            category: asset.category.title,
                        },
                    };
                }),
                isFetching: false,
                error: false,
            };

            return merge(
                state,
                {
                    [provider]: {
                        ...get(state, [provider], {}),
                        [action.payload.playlistId]: playlistWithResolvedAssets,
                    },
                },
                { arrayMerge: overwriteMerge }
            );
        }

        case PLAYLISTS_GENERATOR_ASSETS_FETCH: {
            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    [action.payload.playlistId]: {
                        ...get(state, [provider, action.payload.playlistId]),
                        isFetching: true,
                        error: false,
                    },
                },
            });
        }

        case PLAYLISTS_GENERATOR_ASSETS_FETCH_SUCCESS: {
            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    [action.payload.playlistId]: {
                        ...get(state, [provider, action.payload.playlistId]),
                        isFetching: false,
                        error: false,
                    },
                },
            });
        }

        case PLAYLISTS_GENERATOR_ASSETS_FETCH_ERROR: {
            return merge(state, {
                [provider]: {
                    ...get(state, [provider], {}),
                    [action.payload.playlistId]: {
                        ...get(state, [provider, action.payload.playlistId]),
                        isFetching: false,
                        error: true,
                    },
                },
            });
        }

        case PLAYLISTS_GENERATOR_FETCH_ERROR: {
            const playlists = action.payload.playlists.map((playlist) => set(playlist, 'isFetching', false));

            return merge(state, {
                [provider]: keyBy(playlists, 'id'),
            });
        }

        case PLAYLISTS_DELETE_SUCCESS: {
            const currentPlaylists = get(state, [provider], {});
            const remainingPlaylists = omit(currentPlaylists, action.payload.id);

            return {
                ...state,
                [provider]: {
                    ...remainingPlaylists,
                },
            };
        }

        default:
            return state;
    }
}
