import { get } from 'lodash';
import config from 'config';
import { getTimeInSeconds } from 'lib/time';

/**
 * @param {Object} params
 * @param {String} params.provider
 * @return {Promise<Array<Asset>>}
 */
export async function fetchLiveAssets({ provider }) {
    const query = {
        filter: 'streamType::live|status::active,readyToPublish,draft|assetType::any',
        limit: 100,
        sort: '-flightTimes.start',
        additional: config.asset.additional.join('|'),
        showAll: true,
        cb: Math.random(),
    };
    const result = await this.get('v1/svp/{PROVIDER}/search', { provider, query });
    return get(result, '_embedded.assets');
}

export async function fetchWasLiveAssets({ provider }) {
    const last24hours = getTimeInSeconds() - 24 * 3600;

    const query = {
        filter: 'streamType::wasLive|status::active|assetType::any',
        sort: 'created',
        after: last24hours,
        additional: config.asset.additional.join('|'),
        showAll: true,
        cb: Math.random(),
    };
    const result = await this.get('v1/svp/{PROVIDER}/assets', { provider, query });
    return get(result, '_embedded.assets');
}
