import { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { SECOND } from 'lib/time';
import useInterval from 'hooks/useInterval';
import { calculateProgressWidth, calculateSecondsLeft } from './utils';
import css from './ElementalAdProgress.module.scss';

function ElementalAdProgress({ duration, emittedAt, onEmittingAdComplete }) {
    const emittingEndTime = emittedAt + duration;
    const [secondsLeft, setSecondsLeft] = useState(calculateSecondsLeft(emittingEndTime));
    const width = calculateProgressWidth(duration, secondsLeft);

    useEffect(() => {
        if (secondsLeft <= 0) {
            onEmittingAdComplete();
        }
    }, [secondsLeft, onEmittingAdComplete]);

    useInterval(
        () => {
            setSecondsLeft(calculateSecondsLeft(emittingEndTime));
        },
        secondsLeft >= 0 ? SECOND : null
    );

    return (
        <>
            <div className={css.progressBar}>
                <div className={css.progressBarInner} style={{ width: `${width}%` }} />
            </div>
            <span className={css.progressLabel}>Ads playing: {secondsLeft} seconds remaining</span>
        </>
    );
}

ElementalAdProgress.propTypes = {
    duration: PropTypes.number.isRequired,
    emittedAt: PropTypes.number.isRequired,
    onEmittingAdComplete: PropTypes.func.isRequired,
};

export default ElementalAdProgress;
