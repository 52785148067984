import { get } from 'lodash';
import config from 'config';

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.provider
 * @param {Number} props.id
 * @return {Array<Thumbnail> | Null}
 */
export const getThumbnailsItems = (state, { provider, id }) => get(state, `thumbnails.${provider}-${id}.items`);

/**
 * @param {Object} state
 * @param {String} newsroom
 * @param {Number} id
 * @return {String}
 */
export const getThumbnailsStatus = (state, { provider, id }) => get(state, `thumbnails.${provider}-${id}.status`);

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} provider
 * @param {Number} id
 * @return {Array<Thumbnail>}
 */
export const getSuggestedThumbnails = (state, { provider, id }) => {
    const thumbnails = getThumbnailsItems(state, { provider, id });

    if (!thumbnails) {
        return [];
    }

    const { totalThumbnailsSuggestion: total } = config.images;
    const everyNth = thumbnails.length / total;
    const exactEveryNth = Math.floor(everyNth >= 1 ? everyNth : 1);

    return thumbnails.filter((snapshot, index) => index % exactEveryNth === 0).slice(0, total);
};

/**
 * @param {Object} state
 * @param {Object} props
 * @param {String} provider
 * @param {Number} id
 * @return {Boolean}
 */
export const getAreThumbnailsLoading = (state, { provider, id }) => {
    return getThumbnailsStatus(state, { provider, id }) === 'loading';
};
