import * as Actions from './actionTypes';

export const relatedFetch = ({ provider, id }) => ({
    type: Actions.RELATED_FETCH,
    provider,
    id,
});

export const relatedFetchSuccess = ({ provider, id, assets }) => ({
    type: Actions.RELATED_FETCH_SUCCESS,
    provider,
    id,
    assets,
});

export const relatedFetchError = ({ provider, id, error }) => ({
    type: Actions.RELATED_FETCH_ERROR,
    provider,
    id,
    error,
});

export const nextSearch = ({ provider, id, query, selectedFilters, sortType }) => ({
    type: Actions.NEXT_SEARCH,
    provider,
    id,
    query,
    selectedFilters,
    sortType,
});

export const nextSearchSuccess = ({ provider, id, assets }) => ({
    type: Actions.NEXT_SEARCH_SUCCESS,
    provider,
    id,
    assets,
});

export const nextSearchError = ({ provider, id, query, error }) => ({
    type: Actions.NEXT_SEARCH_ERROR,
    provider,
    id,
    query,
    error,
});
