import { Component } from 'react';
import * as PropTypes from 'prop-types';
import config from 'config';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import { bemgen } from 'lib/bem';
import './Moodoji.scss';

init({ data });

const { iconSet, values: moods } = config.asset.mood;

/**
 * A component for displaying a mood emoji based on a given value.
 * It uses the [emoji-mart](https://github.com/missive/emoji-mart) component.
 */
export default class Moodoji extends Component {
    static propTypes = {
        /** Callback, with a type as a parameter, is invoked when the icon was clicked.  */
        onClick: PropTypes.func,
        /** Size of the icon. Default is 32. Possible values: `16`, `20`, `32`, `64` */
        size: PropTypes.number,
        /** Flag which hides a tooltip on hover when is set to `false`. Default is `true`. */
        tooltip: PropTypes.bool,
        /** Mood value to display. Possible values: `neutral`, `funny`, `love`, `wow`, `sad`, `angry` */
        type: PropTypes.oneOf(Object.keys(moods)).isRequired,
    };

    static defaultProps = {
        onClick: () => {},
        size: 32,
        tooltip: true,
    };

    handleClick = () => {
        const { onClick, type } = this.props;

        onClick(type);
    };

    render() {
        const { onClick, size, tooltip, type, ...props } = this.props;
        const { label, icon } = moods[type];

        return (
            <span {...props} className={bemgen('Moodoji', { tooltip }, props.className)} data-tooltip={label}>
                <em-emoji onClick={this.handleClick} id={icon} set={iconSet} size={size} />
            </span>
        );
    }
}
