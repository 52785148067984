/**
 * @typedef MediaLiveChannel
 * @property {String} state
 * @property {Object|null} schedule
 * @property {String|null} schedule.startTime
 * @property {String|null} schedule.endTime
 */

/**
 * @param {MediaLiveChannel} [channel]
 * @returns {Number | null}
 */
export function getChannelStartTime(channel) {
    return channel?.schedule?.startTime || null;
}

/**
 * @param {MediaLiveChannel} [channel]
 * @returns {Number | null}
 */
export function getChannelEndTime(channel) {
    return channel?.schedule?.endTime || null;
}

/**
 * @param {MediaLiveChannel} [channel]
 * @returns {Boolean}
 */
export function isChannelRunning(channel) {
    return ['starting', 'running', 'stopping'].includes(channel?.state);
}

/**
 * @param {MediaLiveChannel} [channel]
 * @returns {Boolean}
 */
export function isChannelIdle(channel) {
    const startTime = getChannelStartTime(channel);
    // AWS doesn't populate idle state, so it's required to check newly created channel if it's in idle state
    return ['creating', 'idle'].includes(channel?.state) && (!startTime || startTime < Date.now() / 1000);
}

export function isChannelScheduled(channel) {
    const startTime = getChannelStartTime(channel);
    // AWS doesn't populate idle state, so it's required to check newly created channel if it's in idle state
    return ['creating', 'idle'].includes(channel?.state) && Boolean(startTime) && startTime > Date.now() / 1000;
}
