import { CollapsibleContent, Button } from '@schibsted-svp/react-ui';
import { relative as getRelativeDate } from 'lib/date';
import { getFullUsernameFromEmail, isEmail } from 'lib/email';
import { capitalize } from 'lib/string';
import { getChangeFieldName, type HistoryData } from './helpers';
import css from './HistoryListItem.module.scss';

type HistoryListItemProps = {
    data: HistoryData;
    openPreview: (data: HistoryData) => void;
};

export function HistoryListItem({ data, openPreview }: HistoryListItemProps) {
    const { id, date, user, action, changes } = data;

    return (
        <li className={css.listItem}>
            <CollapsibleContent
                headerClassName={css.header}
                arrowIconClassName={css.headerArrow}
                customHeader={
                    <>
                        <div className={css.headerDate}>{getRelativeDate(date * 1000)}</div>
                        <span>{capitalize(action)}</span>
                        <span className={css.headerUser}>{isEmail(user) ? getFullUsernameFromEmail(user) : user}</span>
                    </>
                }
            >
                <div className={css.content}>
                    <div className={css.actionButtons}>
                        <Button type="button" onClick={() => openPreview(data)} size="small" variant="primary">
                            Preview
                        </Button>
                    </div>
                    <ul className={css.changesList}>
                        {changes.map((change) => (
                            <li key={`history:change:${id}:${change.path}`}>
                                {getChangeFieldName(change.path)} {change.action}
                            </li>
                        ))}
                    </ul>
                </div>
            </CollapsibleContent>
        </li>
    );
}
