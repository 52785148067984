import { ChangeEvent } from 'react';
import { Input } from '@schibsted-svp/react-ui';
import { slug } from 'lib/string';
import { useFormContext } from 'react-hook-form';

interface Props {
    fieldName: string;
    fieldToSlug: string;
}

export function SlugfyingInput({ fieldName, fieldToSlug }: Props) {
    const formApi = useFormContext();

    const { register, setValue, getFieldState, formState } = formApi;
    const { errors } = formState;

    const { isTouched: isShortPathTouched } = getFieldState(fieldToSlug, formState);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!isShortPathTouched) {
            setValue(fieldToSlug, slug(e.target.value), { shouldDirty: true, shouldTouch: true });
        }
    };
    return (
        <Input
            {...register(fieldName)}
            autoComplete="off"
            error={errors[fieldName]?.message as string}
            onChange={onChange}
        />
    );
}
