import type { Player } from '@schibsted-svp/web-player';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { Maybe, ObjectValues } from 'types';

const VIDEO_JUMP_TIME = 1 / 25;
const AUDIO_JUMP_TIME = 1;

function handleKeyPress(videoElement: HTMLVideoElement, isAudio: boolean, duration: number) {
    let playPromise: Maybe<PromiseLike<void>> = null;
    return function handle(event: KeyboardEvent) {
        if (!document.activeElement?.className.includes('jwplayer')) {
            return;
        }

        const jumpTime = isAudio ? AUDIO_JUMP_TIME : VIDEO_JUMP_TIME;

        const normalizeTime = (time: number) => {
            if (time < 0) {
                return 0;
            }

            if (time > duration) {
                return duration;
            }

            return time;
        };

        const { currentTime } = videoElement;

        switch (event.key) {
            case 'j':
                event.preventDefault();
                // eslint-disable-next-line no-param-reassign
                videoElement.currentTime = normalizeTime(currentTime - jumpTime);
                videoElement.pause();
                break;

            case 'k':
                event.preventDefault();
                if (playPromise) return;

                if (videoElement.paused) {
                    playPromise = videoElement.play();
                    playPromise.then(() => {
                        playPromise = null;
                    });
                } else {
                    videoElement.pause();
                }
                break;

            case 'l':
                event.preventDefault();
                // eslint-disable-next-line no-param-reassign
                videoElement.currentTime = normalizeTime(currentTime + jumpTime);
                videoElement.pause();
                break;

            default:
                break;
        }
    };
}

export const PLAYER_STATE = {
    LOAD_ERROR: -1,
    UNLOADED: 0,
    LOADING: 1,
    LOADED: 2,
    PLAYING: 3,
    PAUSED: 4,
    BUFFERING: 5,
} as const;

export type PlayerState = ObjectValues<typeof PLAYER_STATE>;

export type QualityLevel = {
    bitrate: number;
    height: number;
    width: number;
    label: string;
};

export const createPlayer = (svpPlayer: Player) => {
    let initialPlay = false;
    let currentTime: Maybe<number> = null;
    let playerState: PlayerState = PLAYER_STATE.UNLOADED;
    let keysControlHandler: Maybe<ReturnType<typeof handleKeyPress>> = null;
    let timeoutId: Maybe<NodeJS.Timeout> = null;

    const setPlayerState = (newState: PlayerState) => {
        playerState = newState;
    };

    svpPlayer.on('initialPlay', () => {
        initialPlay = true;
    });

    svpPlayer.on('error', () => {
        svpPlayer.remove();
        setPlayerState(PLAYER_STATE.LOAD_ERROR);
        if (!timeoutId) {
            return;
        }
        clearTimeout(timeoutId);
        timeoutId = null;
    });

    svpPlayer.on('ready', () => setPlayerState(PLAYER_STATE.LOADED));

    svpPlayer.on('time', (time) => {
        currentTime = time;
    });

    const api = {
        get svpPlayer() {
            return svpPlayer;
        },

        get jwplayer() {
            return svpPlayer.getJWPlayer();
        },

        get videoElement() {
            const container = svpPlayer.getContainer();
            return container.querySelector<HTMLVideoElement>('video.jw-video');
        },

        get paused() {
            return this.videoElement?.paused || false;
        },

        get wasPlayed() {
            return initialPlay;
        },

        get currentTime() {
            return (this.videoElement && this.videoElement.currentTime) || 0;
        },

        get duration() {
            return this.videoElement?.duration;
        },

        get isLoaded() {
            return playerState >= PLAYER_STATE.LOADED;
        },

        get isAudio() {
            return api.svpPlayer.getRawAsset().assetType === 'audio';
        },

        async load(asset: Asset) {
            if (svpPlayer.getState() !== 'playing') {
                svpPlayer.once('playNext', () => svpPlayer.once('time', () => svpPlayer.pause()));
            }
            return svpPlayer.playNext(asset, { disableAutoplay: true });
        },

        seek(time: number, play = false) {
            // limit amount of seeks to prevent buffering overload
            if (
                playerState !== PLAYER_STATE.BUFFERING &&
                (currentTime === null || Math.abs(currentTime - time) >= 0.8)
            ) {
                setPlayerState(PLAYER_STATE.BUFFERING);

                // store current time after seek has been performed
                svpPlayer.once('time', () => {
                    if (!play) {
                        return svpPlayer.pause();
                    }
                    return setPlayerState(PLAYER_STATE.PLAYING);
                });
            } else {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(() => {
                    svpPlayer.pause();
                }, 1200);
            }
            // after seek player should stop
            svpPlayer.seek(time);
        },

        seekLive(play = false) {
            // seek to live edge only when player is not currently playing on when it's not buffering the latest chunk
            if (([PLAYER_STATE.PLAYING, PLAYER_STATE.BUFFERING] as number[]).indexOf(playerState) === -1 && play) {
                setPlayerState(PLAYER_STATE.BUFFERING);

                svpPlayer.once('time', () => {
                    if (!play) {
                        return svpPlayer.pause();
                    }
                    return setPlayerState(PLAYER_STATE.PLAYING);
                });

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.jwplayer.on('bufferFull', () => play && svpPlayer.play());

                svpPlayer.seek(-1);
            }
        },

        pause() {
            svpPlayer.once('time', () => {
                setPlayerState(PLAYER_STATE.PAUSED);
            });

            svpPlayer.pause();
        },

        /**
         * Invokes play on the current video
         * Providing the force parameter to true invokes play on the player model which skips flight times checking
         * @param {Boolean} [force=false]
         */
        play(force = false) {
            svpPlayer.once('time', () => {
                setPlayerState(PLAYER_STATE.PLAYING);
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (force ? svpPlayer.model : svpPlayer).play();
        },

        remove() {
            if (keysControlHandler) {
                document.removeEventListener('keydown', keysControlHandler, false);
            }
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }
            if (svpPlayer && svpPlayer.remove) {
                svpPlayer.remove();
            }
        },

        setMaximumQuality() {
            const jwPlayer = this.jwplayer;

            const currentQuality = jwPlayer.getCurrentQuality();

            const maximumQualityIndex = 1;

            return new Promise((resolve) => {
                if (currentQuality !== maximumQualityIndex) {
                    jwPlayer.setCurrentQuality(maximumQualityIndex);

                    jwPlayer.once('levelsChanged', resolve);
                } else {
                    resolve(jwPlayer.getCurrentQuality());
                }
            });
        },

        getStreamDimensions() {
            const levels = this.jwplayer.getQualityLevels() as QualityLevel[];
            const currentQuality = this.jwplayer.getCurrentQuality();
            const { width = 0, height = 0 } = (currentQuality >= 0 && levels[currentQuality]) || {};

            return { width, height };
        },

        async generateImage({ mime = 'image/jpeg', quality = 1 }: { mime?: string; quality?: number } = {}): Promise<
            Maybe<Blob>
        > {
            if (!this.wasPlayed) {
                await new Promise((resolve) => {
                    this.svpPlayer.on('seeked', resolve);
                    // handle the case when a user hasn't run the player yet
                    this.seek(this.currentTime);
                });
            }
            this.pause();

            const { videoElement } = this;
            const { width = 0, height = 0 } = this.getStreamDimensions();
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (!width || !height || !context || !videoElement) {
                return Promise.resolve(null);
            }
            canvas.width = width;
            canvas.height = height;

            return new Promise((resolve) => {
                setTimeout(() => {
                    context.drawImage(videoElement, 0, 0, width, height);
                    canvas.toBlob(
                        (blob) => {
                            resolve(blob);
                            canvas.remove();
                        },
                        mime,
                        quality
                    );
                }, 500);
            });
        },
    };

    svpPlayer.on('ready', () => {
        if (!api.videoElement) {
            return;
        }
        keysControlHandler = handleKeyPress(api.videoElement, api.isAudio, api.duration ?? 0);
        document.addEventListener('keydown', keysControlHandler);
        api.jwplayer.on('levels', () => api.setMaximumQuality());
    });

    return api;
};

export type PlayerApi = ReturnType<typeof createPlayer>;
