import config from 'config';
import { getPreferredAssetListType } from 'store/asset-list/utils';

const { status } = config.asset.labels;
const { assetType } = config.asset.labels;

export const defaultList = {
    // eslint-disable-line
    isClearing: false,
    isFetching: false,
    highlights: {},
    items: [],
    providers: [],
    nextUrl: '',
    lastResultsCount: 0,
};

export const defaultFilters = (defaultAssetType = getPreferredAssetListType()) => {
    return {
        statuses: [{ value: 'active', label: status.active }],
        assetTypes: [
            {
                value: defaultAssetType,
                label: assetType[defaultAssetType],
            },
        ],
    };
};
