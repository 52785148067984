import { useController } from 'react-hook-form';
import type { Control, FieldValues, FieldPath, ControllerRenderProps } from 'react-hook-form';
import { Select } from '@schibsted-svp/react-ui';
import type { ExtendedProps as SelectProps } from '@schibsted-svp/react-ui/lib/es/Select';
import { findSingleOption, getValueFromOption } from 'components/ui/Form/Select';

export const sources = {
    1: 'Gammel videoplayer',
    2: 'AP',
    4: 'Scanpix',
    5: 'Reuters',
    6: 'Egenproduksjon',
    7: 'Privat',
    8: 'TVNorge',
    9: 'Leser',
    10: 'Glefs',
    11: 'Tellus Works',
    12: 'VG papir',
    13: 'TV3',
    14: 'Stortinget',
    15: 'VG papir fotoavdelingen',
    16: 'Eksterne leverandører',
    17: 'Cikmedia',
    18: 'Bulls',
    19: 'All Over Press',
    20: 'KickOff',
    21: 'Lovefilm',
    22: 'Aftonbladet',
    24: 'Rubicon',
    25: 'KameraOne',
    26: 'NRK',
    27: 'VideoforWeb',
    28: 'Departementenes servicesenter',
    29: 'Viasat',
    30: 'C More',
    31: 'Eurosport',
    32: 'TV2',
    33: 'AFP',
    34: 'CNN',
    35: 'Jukin',
    36: 'Viralhog',
    50: 'TT',
    100: 'Wochit',
    110: 'NENT',
};

export const options = Object.entries(sources).map(([value, label]) => ({
    value: Number(value),
    label,
}));

type SourceSelectOption = { label: string; value: number };

type SourceSelectProps<TFieldValues extends FieldValues, IsMulti extends boolean> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    normalizeValue?: boolean;
} & SelectProps<SourceSelectOption, IsMulti>;

export function SourceSelect<TFieldValues extends FieldValues, IsMulti extends boolean>({
    name,
    control,
    normalizeValue = true,
    ...rest
}: SourceSelectProps<TFieldValues, IsMulti>) {
    const { field } = useController({ name, control });

    const normalizeValueHandlers: Pick<ControllerRenderProps, 'value' | 'onChange'> = {
        value: findSingleOption(options)(field.value),
        onChange: (value) => field.onChange(getValueFromOption(value) || null),
    };

    return (
        <Select
            {...field}
            {...(normalizeValue && normalizeValueHandlers)}
            options={options}
            isClearable
            placeholder="Internal"
            splitButton={false}
            size="small"
            {...rest}
        />
    );
}
