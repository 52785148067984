import LiveCreationDialogFooter from 'components/video/live/LiveCreationDialog/LiveCreationDialogFooter';
import { useMediaLiveInputFormContext, useMediaLiveInputFormFormSubmit } from './hooks';
import { MediaLiveInputFormControl } from './types';

interface MediaLiveInputFormFooterProps {
    isEditing: boolean;
    hasEncoder: boolean;
    formControl: MediaLiveInputFormControl;
}

export function MediaLiveInputFormFooter({ isEditing, hasEncoder, formControl }: MediaLiveInputFormFooterProps) {
    const { formApi } = useMediaLiveInputFormContext();

    const {
        formState: { isSubmitting, isValid },
    } = formApi;

    const submitButtonText = (isEditing && hasEncoder && 'Change') || (isEditing ? 'Set source' : 'Create');

    const handleSubmit = useMediaLiveInputFormFormSubmit(formControl);

    return (
        <LiveCreationDialogFooter
            onSubmit={handleSubmit}
            onCancel={formControl.onCancel}
            disabled={isSubmitting || !isValid}
            submitButtonText={submitButtonText}
        />
    );
}
