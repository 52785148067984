import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, Dropdown, Notification } from '@schibsted-svp/react-ui';
import { Tab, Tabs } from 'components/ui/Tabs';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import { createLiveStream, prepareLiveStream } from 'store/live/actions';
import type { LiveEvent } from 'models/liveEvent';
import { EncoderInputForm } from '../form/EncoderInputForm';
import { MediaLiveInputForm } from '../form/MediaLiveInputForm';
import { ExternalStreamForm } from '../form/ExternalStreamForm';
import { RtmpForm } from '../form/RtmpForm';
import { normalizeLiveStreamValues } from './utils';
import { useLiveCreationData } from './hooks';
import css from './LiveCreationDialog.module.scss';

const getIsEditing = (assetId: number): boolean => {
    return assetId != null;
};

const getHasEncoder = (events: LiveEvent[], channelId: number | null): boolean => {
    return Boolean(events.length || channelId);
};

type TabNames = 'cloud' | 'encoder' | 'rtmp' | 'external';
const getDefaultTab = (enableEncoderInput: boolean, channelId: number | null, isEditing: boolean): TabNames => {
    if (channelId) {
        return 'cloud';
    }
    if (enableEncoderInput) {
        return 'encoder';
    }
    if (!isEditing) {
        return 'rtmp';
    }
    return 'cloud';
};

const getLabel = (assetId: number, hasEncoder: boolean): string => {
    if (assetId != null && hasEncoder) {
        return 'Edit source';
    }
    if (assetId != null) {
        return 'Set source';
    }
    return 'Create live video';
};

type LiveCreationDialogProps = {
    assetId?: number;
    newsroom: string;
    channelId?: number | null;
    form: string;
    triggerType?: 'button' | 'dropdown';
    triggerProps?: {
        disabled: boolean;
        buttonSize: 'big' | 'small' | 'compact';
        buttonLoading: boolean;
    };
    events?: LiveEvent[];
};
export function LiveCreationDialog({
    assetId,
    newsroom,
    channelId = null,
    form,
    triggerType = 'button',
    triggerProps: { disabled, buttonSize, buttonLoading } = {
        disabled: false,
        buttonSize: undefined,
        buttonLoading: false,
    },
    events = [],
}: LiveCreationDialogProps) {
    const hasEncoder = getHasEncoder(events, channelId);
    const isEditing = getIsEditing(assetId);
    const userPermissions = useUserPermissions();

    const [opened, setOpened] = useState(false);
    const { initialValues, enableEncoderInput, hasLiveFailed } = useLiveCreationData({
        isEditing,
        opened,
        newsroom,
        assetId,
    });

    const defaultTab = getDefaultTab(enableEncoderInput, channelId, getIsEditing(assetId));
    const [tab, setTab] = useState(defaultTab);

    const dispatch = useDispatch();
    const history = useHistory();

    const submitLiveStreamValues = (rawValues) => {
        const values = normalizeLiveStreamValues(newsroom, rawValues);
        if (assetId) {
            return dispatch(
                prepareLiveStream(newsroom, assetId, {
                    ...values,
                    hasEvent: Boolean(events?.length),
                    channelId,
                })
            );
        }

        return dispatch(createLiveStream(newsroom, values));
    };

    const toggle = () => {
        if (!opened) {
            setTab(defaultTab);
        }
        setOpened(!opened);
    };

    const handleSubmit = async (values) => {
        try {
            const result = await submitLiveStreamValues(values);

            const isEdited = events.length || channelId;
            if (!hasLiveFailed && !isEdited) {
                Notification.notify.success('A new live video has been created successfully.');
            }

            setOpened(false);

            const { id, provider } = result?.asset || {};

            if (!getIsEditing(assetId) && id && provider) {
                history.push(`/${provider}/assets/${id}`);
            }
        } catch (error) {
            Notification.notify.error('An unexpected error occurred during a new live video creation.');
        }
    };

    const handleTabChange = (newTab: TabNames) => {
        setTab(newTab);
    };

    const formProps = {
        form,
        onSubmit: handleSubmit,
        onCancel: toggle,
    };
    const triggerLabel = getLabel(assetId, hasEncoder);
    const triggerDisabled = !userPermissions?.isUser || disabled;
    const DialogTrigger =
        triggerType === 'dropdown' ? (
            <Dropdown.Item
                hideOnOutsideClick
                itemKey="live"
                onClick={disabled ? undefined : toggle}
                disabled={triggerDisabled}
            >
                {triggerLabel}
            </Dropdown.Item>
        ) : (
            <Button
                type="button"
                variant="standard"
                onClick={toggle}
                disabled={triggerDisabled}
                size={buttonSize}
                loading={buttonLoading}
            >
                {triggerLabel}
            </Button>
        );

    return (
        <Dialog className={css.dialog} dialogTrigger={DialogTrigger} isOpen={opened} onClose={toggle}>
            <Dialog.Section variant="darker" className={css.header}>
                {triggerLabel}
            </Dialog.Section>
            <Dialog.Section className={css.tabsContainer}>
                <Tabs>
                    {enableEncoderInput && (
                        <Tab
                            label="Encoder input"
                            onClick={() => handleTabChange('encoder')}
                            isActive={tab === 'encoder'}
                        />
                    )}
                    {!hasEncoder ? (
                        <Tab label="Web camera" onClick={() => handleTabChange('rtmp')} isActive={tab === 'rtmp'} />
                    ) : null}
                    {!hasEncoder ? (
                        <Tab
                            label="External stream"
                            onClick={() => handleTabChange('external')}
                            isActive={tab === 'external'}
                        />
                    ) : null}
                    <Tab label="Cloud encoder" onClick={() => handleTabChange('cloud')} isActive={tab === 'cloud'} />
                </Tabs>
            </Dialog.Section>

            <>
                {tab === 'encoder' && (
                    <EncoderInputForm
                        initialValues={initialValues}
                        isEditing={isEditing}
                        events={events}
                        hasEncoder={hasEncoder}
                        formControl={formProps}
                    />
                )}
                {tab === 'rtmp' && !hasEncoder && <RtmpForm formControl={formProps} isEditing={isEditing} />}
                {tab === 'external' && !hasEncoder && <ExternalStreamForm formControl={formProps} />}
                {tab === 'cloud' && (
                    <MediaLiveInputForm
                        initialValues={initialValues}
                        isEditing={isEditing}
                        hasEncoder={hasEncoder}
                        assetId={assetId}
                        formControl={formProps}
                    />
                )}
            </>
        </Dialog>
    );
}
