import { createContext, ReactNode, useContext, useState, useMemo, useCallback } from 'react';
import { SECOND } from 'lib/time';
import type { Optional } from 'types';
import type { PlayerApi } from 'components/core/Player/playerApi';
import { useVideoDuration } from '../hooks';

interface VideoPreviewEditorContextValue {
    /** Video duration in seconds */
    duration: number;
    previewPlayer?: PlayerApi;
    onPreviewPlayerReady: (player: PlayerApi) => void;
}

export const VideoPreviewEditorContext = createContext<Optional<VideoPreviewEditorContextValue>>(undefined);

export function useVideoPreviewEditorContext() {
    const context = useContext(VideoPreviewEditorContext);
    if (!context) {
        throw new Error('VideoPreviewEditorContext not defined');
    }
    return context;
}

export function VideoPreviewEditorProvider({
    assetDuration,
    children,
}: {
    assetDuration: number;
    children?: ReactNode;
}) {
    const [previewPlayer, setPreviewPlayer] = useState<Optional<PlayerApi>>(undefined);
    const duration = useVideoDuration(previewPlayer?.videoElement, assetDuration / SECOND);

    const onPreviewPlayerReady = useCallback(
        (player: PlayerApi) => {
            setPreviewPlayer(player);
            player.seek(0);
        },
        [setPreviewPlayer]
    );

    const contextValue = useMemo(
        () => ({
            duration,
            previewPlayer,
            onPreviewPlayerReady,
        }),
        [duration, previewPlayer, onPreviewPlayerReady]
    );
    return <VideoPreviewEditorContext.Provider value={contextValue}>{children}</VideoPreviewEditorContext.Provider>;
}
