import type { ChangeEvent } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import { Input, Select, Checkbox } from '@schibsted-svp/react-ui';
import { DateTimeMerged } from 'components/video/edit/Form/DateTimeMerged';
import { findSingleOption, getValueFromOption } from 'components/ui/Form/Select';
import { combine } from 'lib/function';
import type { DynamicFieldGrouped } from '../../helpers/DynamicForm';
import type { FormValues } from '../../types';
import css from './DynamicFieldGenerator.module.scss';

type DynamicFieldGeneratorProps = {
    dynamicField: DynamicFieldGrouped;
    controllerField?: ControllerRenderProps<FormValues, `additional.metadata.simpleMetadata.${number}.value`>;
    onChange?: (value?: string) => void;
};

export function DynamicFieldGenerator({ dynamicField, controllerField, onChange }: DynamicFieldGeneratorProps) {
    const { type, label, options, minDate = 0 } = dynamicField;

    const combinedOnChange = combine(controllerField?.onChange, onChange);

    if (type === 'text') {
        return (
            <Input
                {...controllerField}
                onChange={(event) => combinedOnChange(event.currentTarget.value)}
                size="small"
            />
        );
    }

    if (type === 'date') {
        return (
            <DateTimeMerged
                input={{
                    ...controllerField,
                    value: Number(controllerField?.value) || null,
                    onChange: (value) => combinedOnChange(value ? String(value) : value),
                }}
                meta={{ error: '' }}
                minDate={minDate === 'today' ? new Date() : new Date(minDate * 1000)}
                clearToNull
            />
        );
    }

    if (type === 'select') {
        return (
            <Select
                {...controllerField}
                value={findSingleOption(options)(controllerField?.value)}
                onChange={(value) => combinedOnChange(getValueFromOption(value))}
                options={options}
                isClearable
                splitButton={false}
                size="small"
            />
        );
    }

    if (type === 'checkbox') {
        return (
            <Checkbox
                {...controllerField}
                checked={Boolean(controllerField?.value)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => combinedOnChange(event.currentTarget.checked)}
                label={label}
                containerClassName={css.checkboxContainer}
            />
        );
    }

    return null;
}
