import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bemgen } from 'lib/bem';
import { Dropdown } from '@schibsted-svp/react-ui';
import SortIcon from 'components/icons/SortIcon';
import config from 'config';
import './OrderDropdown.scss';

const { sortTypes } = config;

const renderButton = (onClick, isExpanded, { sortIcon }) => (
    <button type="button" className={bemgen('OrderDropdown__button', { expanded: isExpanded })} onClick={onClick}>
        {sortIcon}
    </button>
);

class OrderDropdown extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        selectedSort: PropTypes.object.isRequired,
        className: PropTypes.string,
        size: PropTypes.oneOf(['small']),
    };

    static defaultProps = {
        className: '',
        size: undefined,
    };

    handleChange = (sortType) => {
        const { onChange } = this.props;
        onChange(sortTypes.find(({ value }) => value === sortType));
    };

    render() {
        const { selectedSort, className, size, ...rest } = this.props;
        const sortIcon = <SortIcon className={bemgen('OrderDropdown__icon')} size={size} />;

        return (
            <Dropdown
                hideOnOutsideClick
                size="small"
                className={bemgen('OrderDropdown', undefined, className)}
                type="button"
                showMenuArrow
                sortIcon={sortIcon}
                mainButtonRenderer={renderButton}
                {...rest}
            >
                <Dropdown.Item className={bemgen('OrderDropdown__item', false)} itemKey="heading">
                    SORT BY:
                </Dropdown.Item>
                {sortTypes.map(({ value, label }) => (
                    <Dropdown.Item
                        className={bemgen('OrderDropdown__item', { selected: selectedSort.value === value })}
                        itemKey={value}
                        key={value}
                        onClick={this.handleChange}
                    >
                        {label}
                    </Dropdown.Item>
                ))}
            </Dropdown>
        );
    }
}

export default OrderDropdown;
