import { memo, useCallback } from 'react';
import { Control } from 'react-hook-form';

import { PurePlayer } from 'components/core/Player';
import type { PlayerApi } from 'components/core/Player/playerApi';
import type { SubtitlesFormValues } from './types';
import { useGetAsset } from './hooks/useAsset';
import { CueChangeHandler, useSubtitlesCues } from './hooks/useSubtitlesCues';
import css from './SubtitlesPlayer.module.scss';

type SubtitlesPlayerProps = {
    assetId: number;
    provider: string;
    className: string;
    control: Control<SubtitlesFormValues>;
    onCueChange: CueChangeHandler;
    onDurationChange: (duration: number) => void;
    onPlayerReady: (player: PlayerApi) => void;
};

export const SubtitlesPlayer = memo<SubtitlesPlayerProps>(function SubtitlesPlayer({
    assetId,
    provider,
    className,
    control,
    onDurationChange,
    onPlayerReady,
    onCueChange,
}) {
    const { createCuesTrack, flushCues } = useSubtitlesCues({ control, onCueChange });
    const asset = useGetAsset({ assetId, provider });

    const handlePlayerReady = useCallback(
        (player: PlayerApi) => {
            if (!player.videoElement) {
                return;
            }

            createCuesTrack(player.videoElement);
            player.svpPlayer.getJWPlayer().once('meta', () => onDurationChange(player.svpPlayer.getDuration()));
            player.svpPlayer.once('assetPlay', flushCues);
            player.seek(0);
            onPlayerReady(player);
        },
        [createCuesTrack, flushCues, onDurationChange, onPlayerReady]
    );

    if (!asset) {
        return null;
    }

    return (
        <PurePlayer
            asset={asset}
            mute
            noPoster
            onReady={handlePlayerReady}
            className={[css.player, className].join(' ')}
        />
    );
});
