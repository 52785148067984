import { Component } from 'react';
import * as PropTypes from 'prop-types';
import config from 'config';
import BEM from 'lib/bem';
import Moodoji from 'components/ui/Moodoji/Moodoji';
import './MoodSelect.scss';

const { values: moods } = config.asset.mood;

const bem = new BEM('MoodSelect');

/**
 * A component for displaying a mood selector.
 * It uses the [emoji-mart](https://github.com/missive/emoji-mart) component.
 */
export default class MoodSelect extends Component {
    static propTypes = {
        /**
         * Callback invoked when the value was changed.
         * Possible values: `neutral`, `funny`, `love`, `wow`, `sad`, `angry`
         */
        onChange: PropTypes.func,
        /** Size of the icons. Default is 32. Possible values: `16`, `20`, `32`, `64` */
        size: PropTypes.number,
        /** Value or array of a selected mood */
        selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        /** Whether or not the component should be highlighted */
        highlighted: PropTypes.bool,
    };

    static defaultProps = {
        onChange: () => {},
        size: 32,
        selected: null,
        highlighted: false,
    };

    optionRenderer = (value) => {
        const { onChange, selected, size } = this.props;
        let inactive;
        if (Array.isArray(selected)) {
            inactive = selected.length > 0 && !selected.includes(value);
        } else {
            inactive = selected && selected !== value;
        }

        return (
            <li key={`mood:${value}`} className={bem.element('option')}>
                <Moodoji onClick={onChange} type={value} size={size} className={bem.element('emoji', { inactive })} />
            </li>
        );
    };

    render() {
        const { highlighted } = this.props;
        return <ul className={bem.block({ highlighted })}>{Object.keys(moods).map(this.optionRenderer)}</ul>;
    }
}
