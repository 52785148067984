import type { ReactNode } from 'react';
import { CollapsibleContent } from '@schibsted-svp/react-ui';
import type { CollapsibleContentProps } from '@schibsted-svp/react-ui/lib/es/CollapsibleContent/CollapsibleContent';
import css from './InlineCollapsible.module.scss';

type InlineCollapsible = {
    children: ReactNode;
} & CollapsibleContentProps;

export function InlineCollapsible({ children, ...props }: InlineCollapsible) {
    return (
        <CollapsibleContent
            className={css.inlineCollapsible}
            expandedClassName={css['inlineCollapsible--expanded']}
            variant="cover"
            {...props}
        >
            {children}
        </CollapsibleContent>
    );
}
