import type { Asset } from '@schibsted-svp/svp-api-types';
import { getDateString, DATE_TIME_FORMAT } from 'lib/time';
import { getFullUsernameFromEmail, isEmail } from 'lib/email';
import type { HistoryDataChange } from '../helpers';

export function getStatusText(status: Asset['status']): string {
    if (status === 'active') {
        return `Published`;
    }
    if (status === 'inactive') {
        return 'Unpublished';
    }
    if (status === 'draft') {
        return 'Incoming';
    }
    return 'Ready to be published';
}

export function getFieldsChangesText(changes: HistoryDataChange[]): string {
    return `${changes.length > 0 ? changes.length : 'no'} ${changes.length === 1 ? 'field' : 'fields'} changed`;
}

export function getMetadataChanges(changes: HistoryDataChange[]): HistoryDataChange[] {
    return changes.filter((change) => change.path.startsWith('additional.metadata'));
}

export function createIsFieldChanged(changes: HistoryDataChange[]) {
    return function isFieldChanged(path: string | string[]): boolean {
        const paths = Array.isArray(path) ? path : [path];
        return paths.some((singlePath) => changes.some((change) => change.path === singlePath));
    };
}

export function formatEditedDate(updated: number): string {
    return updated && getDateString(updated, DATE_TIME_FORMAT);
}

export function formatPublishedDate(published: number): string {
    return published && getDateString(published, DATE_TIME_FORMAT);
}

export function formatLastEditedBy(lastEditedBy: string): string {
    return isEmail(lastEditedBy) ? getFullUsernameFromEmail(lastEditedBy) : lastEditedBy;
}

export function formatAgeLimit(ageLimit: number | null): string {
    if (ageLimit || ageLimit === 0) {
        return ageLimit > 0 ? `${ageLimit} years` : 'All';
    }
    return undefined;
}
