import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    acl: state.acl,
    newsroom: state.newsroom,
});

export const NewsroomProps = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withNewsroom = (WrappedComponent) => {
    function WithNewsroom({ acl, newsroom, dispatch, ...props }) {
        return <WrappedComponent newsroom={newsroom} {...props} />;
    }

    WithNewsroom.propTypes = {
        acl: PropTypes.object.isRequired,
        newsroom: PropTypes.string.isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    WithNewsroom.displayName = `withNewsroom(${getDisplayName(WrappedComponent)})`;
    return connect(mapStateToProps)(WithNewsroom);
};
