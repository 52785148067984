import { useController } from 'react-hook-form';
import type { Control, FieldPath, FieldValues, ControllerRenderProps } from 'react-hook-form';
import MoodSelect from 'components/ui/MoodSelect';

type MoodProps<TFieldValues extends FieldValues> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    isMulti?: boolean;
};

export function Mood<TFieldValues extends FieldValues>({
    name,
    control,
    isMulti = false,
    ...props
}: MoodProps<TFieldValues>) {
    const { field } = useController({ name, control });

    const onChange: ControllerRenderProps['onChange'] = (value) => {
        if (isMulti) {
            let selectedMoods = Array.isArray(field.value) ? [...field.value] : [];

            if (selectedMoods.includes(value)) {
                selectedMoods = selectedMoods.filter((mood) => mood !== value);
            } else {
                selectedMoods.push(value);
            }

            field.onChange(selectedMoods);
        } else {
            field.onChange(field.value === value ? '' : value);
        }
    };

    return <MoodSelect {...props} selected={field.value} onChange={onChange} />;
}
