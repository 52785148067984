import { reportMessageToSentry } from 'lib/error';

/**
 * LocalStorage token
 *
 * @type {string}
 */

const STORAGE_KEY = 'svpAdminJwt';

/**
 * Load token from storage
 *
 * @returns {*}
 */
const get = () => {
    let token = null;

    try {
        token = JSON.parse(localStorage.getItem(STORAGE_KEY));
    } catch (error) {
        token = null;
    }

    return token;
};

const storeRefreshTokenStart = (token) => {
    const { iat } = token || {};
    const refreshStart = localStorage.getItem('refreshStart');

    if (!refreshStart) {
        localStorage.setItem('refreshStart', iat);
    }
};

/**
 * Save token in persistent storage
 *
 * @param token
 */
const store = (token) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(token));
    storeRefreshTokenStart(token);
};

/**
 * Parse JWT from string to JSON
 *
 * @param token - JWT token string representation
 * @returns {null}
 */
const parse = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');

        return JSON.parse(window.atob(base64));
    } catch (error) {
        reportMessageToSentry({ message: `JWT token parse failed. ${error.message}`, extras: { error } });
        return null;
    }
};

/**
 * Remote token from storage
 */
const clear = () => {
    try {
        localStorage.removeItem(STORAGE_KEY);
    } catch (error) {
        reportMessageToSentry({ message: `Could not remove JWT token. ${error.message}`, extras: { error } });
    }
};

const timeSkew = 0.8;

/**
 * @return {number} milliseconds until token expiration (0 or nagative if expired)
 */
function expiresIn() {
    try {
        const { exp } = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
        return Math.floor((exp * 1000 - Date.now()) * timeSkew) || 0;
    } catch (error) {
        return 0;
    }
}

/**
 * Check if token is valid
 * Not existing token is the same as expired one
 *
 * @returns {boolean}
 */
function isExpired() {
    const token = get();
    if (!token || Object.keys(token).length === 0) {
        return true;
    }

    const ttl = (token.exp - token.iat) * 1000;
    const margin = Math.abs(ttl * timeSkew - ttl);

    return expiresIn() <= margin;
}

export default {
    parse,
    clear,
    get,
    store,
    isExpired,
    expiresIn,
};
