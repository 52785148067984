import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import type { Control, FieldValues, FieldPath, ControllerRenderProps } from 'react-hook-form';
import { Select } from '@schibsted-svp/react-ui';
import type { ExtendedProps as SelectProps, DefaultOptionType } from '@schibsted-svp/react-ui/lib/es/Select';
import { getCategoriesItems } from 'store/categories/selectors';
import { generateSelectOptionsWithDepth } from 'lib/categories';
import { findSingleOption, getValueFromOption } from 'components/ui/Form/Select';

type CategorySelectProps<TFieldValues extends FieldValues, IsMulti extends boolean> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    provider: string;
    filterIds?: number[];
    normalizeValue?: boolean;
} & SelectProps<DefaultOptionType, IsMulti>;

export function CategorySelect<TFieldValues extends FieldValues, IsMulti extends boolean>({
    name,
    control,
    provider,
    filterIds = [],
    normalizeValue = true,
    ...rest
}: CategorySelectProps<TFieldValues, IsMulti>) {
    const { field, fieldState } = useController({ name, control });

    const categories = useSelector((state) => getCategoriesItems(state, { provider }));
    const options = generateSelectOptionsWithDepth(categories).filter(({ value }) => !filterIds.includes(value));

    const customStyles: SelectProps<DefaultOptionType, IsMulti>['styles'] = {
        option: (base, { data }) => ({
            ...base,
            paddingLeft: `${data.depth * 10}px !important`,
            textDecoration: !data.showCategory && 'line-through',
        }),
    };

    const normalizeValueHandlers: Pick<ControllerRenderProps, 'value' | 'onChange'> = {
        value: findSingleOption(options)(field.value),
        onChange: (value) => field.onChange(getValueFromOption(value)),
    };

    return (
        <Select
            {...field}
            {...(normalizeValue && normalizeValueHandlers)}
            options={options}
            styles={customStyles}
            isLoading={options.length === 0}
            backspaceRemovesValue={false}
            splitButton={false}
            size="small"
            error={fieldState.error?.message}
            {...rest}
        />
    );
}
