import { get } from 'lodash';
import { isVideoStreamDownloadable } from 'models/asset';
import { getAssetData } from 'store/assets/selectors';
import { createSelector } from '@reduxjs/toolkit';

export const getConversion = (state, { provider, id }) => get(state, `video.conversion.${provider}.${id}.video`, {});

export const getConversionState = createSelector(getConversion, (conversion) => conversion.state || '');

export const getConversionURL = createSelector(getConversion, (conversion) => conversion.url);

export const isConversionInProgress = createSelector(getConversionState, (state) => state === 'convert');

export const isConversionFinished = createSelector(getConversionState, (state) => state === 'done');

export const isConversionFailed = createSelector(getConversionState, (state) => state === 'error');

export const isVideoReadyForDownloading = (state, { id, provider }) => {
    const { asset } = getAssetData(state, { id, provider });
    return isVideoStreamDownloadable(asset) && isConversionFinished(state, asset);
};

export const canVideoBePreparedForDownloading = (state, { id, provider }) => {
    const { asset } = getAssetData(state, { id, provider });
    return isVideoStreamDownloadable(asset) && !isConversionFinished(state, asset) && !isConversionFailed(state, asset);
};
