import { bemgen } from 'lib/bem';
import Logo from './logo/logo';
import Menu from './Menu';
import AssetCreationButton from './AssetCreationButton';
import { Search } from './search';
import Avatar from './Avatar';
import './Header.scss';

function Header() {
    return (
        <header className={bemgen('header')}>
            <Logo />
            <Menu />
            <Search />
            <AssetCreationButton />
            <Avatar />
        </header>
    );
}

export default Header;
