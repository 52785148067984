import { useEffect, useState } from 'react';
import type { Maybe } from 'types';

export function useVideoDuration(videoElement?: Maybe<HTMLVideoElement>, initialDurationInSec?: number) {
    const [durationInSec, setDurationInSec] = useState(initialDurationInSec);

    useEffect(() => {
        const video = videoElement;
        const handleLoadedMetadata = (event: Event) => {
            if (event.currentTarget instanceof HTMLVideoElement) {
                setDurationInSec(event.currentTarget.duration);
            }
        };

        if (video?.readyState > 0) {
            setDurationInSec(video.duration);
        } else if (video && video?.readyState <= 0) {
            video.addEventListener('loadedmetadata', handleLoadedMetadata, { once: true });
        }

        return () => {
            video?.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, [videoElement]);

    return durationInSec;
}
