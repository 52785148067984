import { memo } from 'react';
import { useSelector } from 'react-redux';
import type { UseFormReturn } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { CollapsibleContent, Label } from '@schibsted-svp/react-ui';
import config from 'config';
import { makeStreamTypeLabel } from 'models/asset';
import { getDateString, getFormattedTime, DATE_TIME_FORMAT } from 'lib/time';
import { getFullUsernameFromEmail } from 'lib/email';
import { findLiveEncoderEventsUrl } from 'store/live/selectors';
import { StreamUrl } from '../fields/StreamUrl';
import type { FormValues } from '../types';

import css from './AssetDetails.module.scss';

type AssetDetailsProps = {
    asset: Asset;
    formApi: UseFormReturn<FormValues>;
};

export const AssetDetails = memo(function AssetDetails({ asset, formApi }: AssetDetailsProps) {
    const liveEncoderEventsUrl = useSelector((state) =>
        findLiveEncoderEventsUrl(state, { assetId: asset.id, provider: asset.provider })
    );

    const lastEditedBy = getFullUsernameFromEmail(asset.additional.settings?.lastEditedBy) || '-';
    const createdBy = getFullUsernameFromEmail(asset.additional.settings?.createdBy) || '-';

    const editedDate = asset.updated ? getDateString(asset.updated, DATE_TIME_FORMAT) : '-';
    const publishedDate = asset.published ? getDateString(asset.published, DATE_TIME_FORMAT) : '-';
    const createdDate = getDateString(asset.created, DATE_TIME_FORMAT);

    return (
        <CollapsibleContent label="details">
            <section className={css.container}>
                <div className={css.header}>
                    <div className={css.item}>
                        <Label className={css.label}>Stream type</Label>
                        <span>{makeStreamTypeLabel(asset, config.asset.labels) || 'not available'}</span>
                    </div>
                    <div className={css.item}>
                        <Label className={css.label}>External ID</Label>
                        <span>{asset.additional.externalId || 'not available'}</span>
                    </div>
                    <div className={css.item}>
                        <Label className={css.label}>ID</Label>
                        <span>{asset.id}</span>
                    </div>
                    <div className={css.item}>
                        <Label className={css.label}>Views</Label>
                        <span>{asset.displays || 'not available'}</span>
                    </div>
                    <div className={css.item}>
                        <Label className={css.label}>Duration</Label>
                        <span>{asset.duration > 0 ? getFormattedTime(asset.duration) : '--:--:--'}</span>
                    </div>
                </div>
                <div className={css.history}>
                    <div className={css.row}>
                        <Label className={css.label}>History</Label>
                        <Label className={css.label}>Date</Label>
                        <Label className={css.label}>User</Label>
                    </div>
                    <div className={css.row}>
                        <span>Modified</span>
                        <span>{editedDate}</span>
                        <span>{lastEditedBy}</span>
                    </div>
                    <div className={css.row}>
                        <span>Published</span>
                        <span>{publishedDate}</span>
                        <span>{createdBy}</span>
                    </div>
                    <div className={css.row}>
                        <span>Uploaded</span>
                        <span>{createdDate}</span>
                        <span>{createdBy}</span>
                    </div>
                </div>
                <div className={css.streamUrl}>
                    <Label className={css.label}>STREAM URL</Label>
                    <StreamUrl className={css.url} formApi={formApi} />
                </div>
                {liveEncoderEventsUrl.length > 0 && (
                    <div className={css.liveEventUrl}>
                        <Label className={css.label}>LIVE EVENT URL</Label>
                        <ul className={css.urls}>
                            {liveEncoderEventsUrl.map((url) => (
                                <li key={url}>
                                    <a target="_blank" rel="noreferrer" href={url}>
                                        {url}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </section>
        </CollapsibleContent>
    );
});
