import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Button } from '@schibsted-svp/react-ui';
import Playlist from 'components/Playlists';

import css from './SelectedPlaylist.module.scss';

const cln = classnames.bind(css);

export default function SelectedPlaylist({ provider, playlistId, onUnassignPlaylist, loading }) {
    return (
        <div className={cln('container', { 'container--loading': loading })}>
            <Playlist provider={provider} id={playlistId} className={css.playlist} hideDeleteButton size="compact" />
            <div className={css.overlay}>
                <Button type="button" size="small" onClick={onUnassignPlaylist}>
                    Remove
                </Button>
            </div>
        </div>
    );
}

SelectedPlaylist.propTypes = {
    provider: PropTypes.string.isRequired,
    playlistId: PropTypes.string.isRequired,
    onUnassignPlaylist: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
