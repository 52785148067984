import { type Control, type FieldPath, type FieldValues, useWatch } from 'react-hook-form';
import Tooltip from 'components/ui/Tooltip';
import style from './LogoPreview.module.scss';

type LogoPreviewProps<TFieldValues extends FieldValues> = {
    className?: string;
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
};

export function LogoPreview({ className, logoUrl }: { className: string; logoUrl: string }) {
    return (
        <div className={className}>
            {logoUrl && (
                <Tooltip
                    theme="dark"
                    placement="top"
                    content={<img alt="Encoder input preview tooltip" src={logoUrl} className={style.tooltip} />}
                >
                    <img alt="Overlay logo preview" src={logoUrl} className={style.logo} />
                </Tooltip>
            )}
        </div>
    );
}

export function LogoPreviewRHF<TFieldValues extends FieldValues>({
    className,
    control,
    name,
}: LogoPreviewProps<TFieldValues>) {
    const value = useWatch({ control, name });

    return <LogoPreview logoUrl={value} className={className} />;
}
