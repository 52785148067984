import type { MouseEvent } from 'react';

const CONTAINER_MARGIN_PX = 8;
const SCROLL_TO_MARGIN_PX = 20;
const SLIDER_EDGE_SIZE_PX = 9;

export function getOffset({ clientX, currentTarget }: MouseEvent<HTMLDivElement>): number {
    return clientX - currentTarget.offsetLeft - CONTAINER_MARGIN_PX;
}

export function getScrollDelta({ deltaX, deltaY }: WheelEvent): number {
    return Math.abs(deltaY) < Math.abs(deltaX) ? deltaX : -deltaY;
}

export function getSelectorEdgeSize(pixelsPerSecond: number): number {
    return SLIDER_EDGE_SIZE_PX / pixelsPerSecond;
}

export function getOffsetWithPadding(offset: number): number {
    return offset + CONTAINER_MARGIN_PX;
}

function getTimeOffset(time: number, pixelsPerSecond: number, scroll: number): number {
    return getOffsetWithPadding(time * pixelsPerSecond - scroll);
}

export function getStartTimeOffset(startTime: number, pixelsPerSecond: number, scroll: number): number {
    return getTimeOffset(startTime, pixelsPerSecond, scroll) + SLIDER_EDGE_SIZE_PX / 2;
}

export function getEndTimeOffset(endTime: number, pixelsPerSecond: number, scroll: number): number {
    return getTimeOffset(endTime, pixelsPerSecond, scroll) - SLIDER_EDGE_SIZE_PX / 2;
}

export function getCenteredOffsetForItem(itemOffset: number, itemWidth: number, containerWidth: number): number {
    const padding =
        itemWidth < containerWidth - SCROLL_TO_MARGIN_PX * 2 ? (containerWidth - itemWidth) / 2 : SCROLL_TO_MARGIN_PX;

    return itemOffset - padding;
}
