import { SECOND } from 'lib/time';

export const shortcuts = (shiftKey: boolean, precise: boolean) => {
    const defaultStep = precise && !shiftKey ? 0.1 : 1;

    const valueModifier = (step: number) => {
        return (value: number) => (precise ? (value * SECOND + step * SECOND) / SECOND : value + step);
    };

    return {
        ArrowUp: valueModifier(defaultStep),
        ArrowDown: valueModifier(-defaultStep),
        k: valueModifier(-10),
        l: valueModifier(10),
    } as const;
};
