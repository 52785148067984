import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Dialog, LabeledContainer, Input } from '@schibsted-svp/react-ui';
import useToggle from 'hooks/useToggle';
import { getLiveEncoder } from 'store/live/selectors';
import {
    getExternalPushUrl,
    INTERNAL_PUSH_URL,
} from 'components/video/live/form/EncoderInputForm/NetworkInput/NetworkInput';

import css from './NetworkInputInfoDialog.module.scss';

export default function NetworkInputInfoDialog({ dialogTrigger, uri, encoderId }) {
    const [isOpened, toggleIsOpened] = useToggle();

    const encoder = useSelector((state) => getLiveEncoder(state, { encoderId }));

    const isNetworkInternalUri = uri.startsWith(INTERNAL_PUSH_URL);

    const handleInputSelection = (event) => event.target.select();
    return (
        <Dialog
            heading="Network input info"
            isOpen={isOpened}
            dialogTrigger={cloneElement(dialogTrigger, {
                ...dialogTrigger.props,
                onClick: toggleIsOpened,
            })}
            onClose={toggleIsOpened}
        >
            <Dialog.Section>
                {isNetworkInternalUri ? (
                    <div className={css.container}>
                        <LabeledContainer label="Network Input URL">
                            <Input
                                onFocus={handleInputSelection}
                                value={getExternalPushUrl(encoder?.url || '')}
                                readOnly
                            />
                        </LabeledContainer>
                        <LabeledContainer label="Video key">
                            <Input onFocus={handleInputSelection} value={uri.split(INTERNAL_PUSH_URL)?.[1]} readOnly />
                        </LabeledContainer>
                    </div>
                ) : (
                    <LabeledContainer label="Network Input URL">
                        <Input onFocus={handleInputSelection} value={uri} readOnly />
                    </LabeledContainer>
                )}
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={toggleIsOpened}>
                    Close
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}

NetworkInputInfoDialog.propTypes = {
    uri: PropTypes.string.isRequired,
    encoderId: PropTypes.string.isRequired,
    dialogTrigger: PropTypes.node.isRequired,
};
