import BEM from 'lib/bem';

const bem = new BEM('video-navigation');

function ListIcon() {
    return (
        <svg className={bem.element('tab-icon')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" version="1">
            <path d="M5 12l1-1 1 1-1 1-1-1zm0 4a1 1 0 1 1 2 0 1 1 0 1 1-2 0zm4 0l1-1h8l1 1-1 1h-8l-1-1zm0-4l1-1h8l1 1-1 1h-8l-1-1zM5 8a1 1 0 1 1 2 0 1 1 0 1 1-2 0zm4 0l1-1h8l1 1-1 1h-8L9 8zM3 22zm18 0zm0 0V2H3v20h18zM1 2l2-2h18l2 2v20l-2 2H3l-2-2V2z" />
        </svg>
    );
}

export default ListIcon;
