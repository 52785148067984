export const createAssetFormName = (id) => `asset-${id}`;

/**
 * parses response's errors according to methods generating initial value and parsing each error
 *
 * @param {Object} response
 * @param {Function} initial
 * @param {Function} each
 * @returns {Promise<*>}
 */
function parseErrors({ response, initial, each }) {
    if (!response) {
        return false;
    }

    const { errors = {} } = response;
    return Object.keys(errors).reduce(
        (acc, key) =>
            each({
                errors,
                acc,
                key,
            }),
        initial(response)
    );
}

/**
 * @param {Object} error
 * @return {Promise<Array>}
 */
const assetsParsePatchErrors = async (error) =>
    parseErrors({
        response: await error.response.json(),
        initial: (payload) => ({ _error: payload.message }),
        each: ({ errors, acc, key }) => {
            const errorItem = errors[key];

            if (typeof errorItem === 'string') {
                acc[key] = errorItem;
            } else {
                // any nested errors should be handled separately, since a situation where a node is of the type:
                //   { [error_type] : error_string }
                // is indistinguishable from:
                //   { [field_name] : error_string } ;- the other version of this node being: { [field_name]: node }
                // without any definition of the field structure, which is basically what this switch does
                switch (key) {
                    case 'category':
                        acc[key] = Object.values(Object.values(errorItem)).join('\n');
                        break;
                    case 'playback':
                        acc[key] = Object.values(errorItem)
                            .reduce((arr, item) => arr.concat(Object.values(item)), [])
                            .join('\n');
                        break;
                    default:
                        acc[key] = Object.values(errorItem).join('\n');
                }
            }
            return acc;
        },
    });

export default assetsParsePatchErrors;
