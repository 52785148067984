export const ASSET_CREATE = 'Asset/CREATE';
export const ASSET_CREATE_SUCCESS = 'Asset/CREATE_SUCCESS';

export const ASSET_FETCH = 'Asset/FETCH';
export const ASSET_FETCH_SUCCESS = 'Asset/FETCH_SUCCESS';
export const ASSET_FETCH_ERROR = 'Asset/FETCH_ERROR';

export const ASSET_SAVE = 'Asset/SAVE';
export const ASSET_SAVE_SUCCESS = 'Asset/SAVE_SUCCESS';
export const ASSET_SAVE_ERROR = 'Asset/SAVE_ERROR';

export const ASSET_CHANGE = 'Asset/CHANGE';

export const ASSET_ASSIGN_PLAYLIST = 'Asset/ASSIGN_PLAYLIST';
export const ASSET_ASSIGN_PLAYLIST_SUCCESS = 'Asset/ASSIGN_PLAYLIST_SUCCESS';
export const ASSET_ASSIGN_PLAYLIST_ERROR = 'Asset/ASSIGN_PLAYLIST_ERROR';

export const ASSET_UNASSIGN_PLAYLIST = 'Asset/UNASSIGN_PLAYLIST';
export const ASSET_UNASSIGN_PLAYLIST_SUCCESS = 'Asset/UNASSIGN_PLAYLIST_SUCCESS';
export const ASSET_UNASSIGN_PLAYLIST_ERROR = 'Asset/UNASSIGN_PLAYLIST_ERROR';
