import { call, race, select, take, takeEvery } from '@redux-saga/core/effects';
import * as ReactGA from 'react-ga';

import { openCustomPreviewUploadDialog } from 'store/ui/assetForm/actions';
import { createAssetPreview, uploadAssetPreview, uploadCategoryPreview } from 'store/uploads/previews/slice';
import { selectNewsroom } from 'store/newsroom/selectors';
import { UPLOAD_SUBTITLES, UPLOAD_SUBTITLES_COMPLETE, UPLOAD_SUBTITLES_FAILURE } from 'store/uploads/subtitles/actions';
import SELECT_NEWSROOM from 'store/newsroom/actionTypes';
import {
    LIVE_STREAM_CREATION_REQUEST,
    START_LIVE_STREAM_REQUEST,
    STOP_LIVE_STREAM_REQUEST,
    CANCEL_LIVE_AD_REQUEST,
    INSERT_LIVE_AD_REQUEST,
} from 'store/live/actions';

export function* trackSubtitlesUpload() {
    const { success, failure } = yield race({
        success: take(UPLOAD_SUBTITLES_COMPLETE),
        failure: take(UPLOAD_SUBTITLES_FAILURE),
    });

    if (failure) {
        yield call(ReactGA.exception, {
            description: 'An error occurred during subtitles upload',
        });
        return; // early return
    }

    yield call(ReactGA.event, {
        category: 'Subtitles',
        action: 'Upload',
        label: success.language,
    });
}

export function* trackNewsroomSelect({ newsroom: selectedNewsroom }) {
    const currentNewsroom = yield select(selectNewsroom);

    if (selectedNewsroom === currentNewsroom) {
        return;
    }

    yield call(ReactGA.set, {
        dimension1: selectedNewsroom,
    });
}

export function* trackCreateLive() {
    yield call(ReactGA.event, {
        category: 'Live',
        action: 'Create',
    });
}

export function* trackStartLive() {
    yield call(ReactGA.event, {
        category: 'Live',
        action: 'Start',
    });
}

export function* trackStopLive() {
    yield call(ReactGA.event, {
        category: 'Live',
        action: 'Stop',
    });
}

export function* trackPlaceAd() {
    yield call(ReactGA.event, {
        category: 'Ads Control',
        action: 'Place Ad',
    });
}

export function* trackCancelAd() {
    yield call(ReactGA.event, {
        category: 'Ads Control',
        action: 'Cancel Ad',
    });
}

export function* trackCustomPreviewDialog() {
    yield call(ReactGA.modalview, 'upload-custom-preview');
}

export function* trackAssetPreviewUpload() {
    yield call(ReactGA.event, {
        category: 'Video',
        action: 'Upload asset preview',
    });
}

export function* trackCategoryPreviewUpload() {
    yield call(ReactGA.event, {
        category: 'Video',
        action: 'Upload category preview',
    });
}

export function* trackAssetPreviewCreation() {
    yield call(ReactGA.event, {
        category: 'Video',
        action: 'Create asset preview',
    });
}

export default [
    takeEvery(UPLOAD_SUBTITLES, trackSubtitlesUpload),
    takeEvery(SELECT_NEWSROOM, trackNewsroomSelect),
    takeEvery(LIVE_STREAM_CREATION_REQUEST, trackCreateLive),
    takeEvery(START_LIVE_STREAM_REQUEST, trackStartLive),
    takeEvery(STOP_LIVE_STREAM_REQUEST, trackStopLive),
    takeEvery(INSERT_LIVE_AD_REQUEST, trackPlaceAd),
    takeEvery(CANCEL_LIVE_AD_REQUEST, trackCancelAd),
    takeEvery(openCustomPreviewUploadDialog.type, trackCustomPreviewDialog),
    takeEvery(createAssetPreview.type, trackAssetPreviewCreation),
    takeEvery(uploadAssetPreview.type, trackAssetPreviewUpload),
    takeEvery(uploadCategoryPreview.type, trackCategoryPreviewUpload),
];
