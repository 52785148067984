import { useState, useEffect, useMemo, memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useUserRoles } from 'hooks/usersManagement/useUserRoles';
import { ScreenSeparator } from 'components/ui/ScreenContainer';
import { UserForm } from './UserForm';
import { UserSearchInput } from './UserSearchInput';
import { generateNewsroomsList, getEmptyUser } from './helpers';
import { useUserForm } from './hooks';
import type { UserData, FormMode } from './types';

type UserAccessManagerProps = {
    userNewsrooms: string[];
};

export const UserAccessManager = memo(({ userNewsrooms }: UserAccessManagerProps) => {
    const roles = useUserRoles();
    const { formApi } = useUserForm();

    const [displayForm, setDisplayForm] = useState(false);
    const [formMode, setFormMode] = useState<FormMode>();
    const [selectedUser, setSelectedUser] = useState<UserData>();

    const newsroomsOptions = useMemo(() => {
        const options = generateNewsroomsList(roles, selectedUser?.newsrooms || []);
        return options.map((option) => ({
            ...option,
            isFixed: !userNewsrooms.includes(option.value),
        }));
    }, [roles, selectedUser, userNewsrooms]);

    useEffect(() => {
        formApi.reset(selectedUser || getEmptyUser());
    }, [selectedUser, newsroomsOptions, formApi]);

    const showForm = (mode: FormMode, data?: UserData) => {
        setFormMode(mode);
        setSelectedUser(data);
        setDisplayForm(true);
    };

    const hideForm = () => {
        setFormMode(undefined);
        setDisplayForm(false);
    };

    return (
        <FormProvider {...formApi}>
            <h1>User access manager</h1>
            <UserSearchInput showForm={showForm} />
            {displayForm && (
                <>
                    <ScreenSeparator />
                    <UserForm formMode={formMode} newsroomsOptions={newsroomsOptions} hideForm={hideForm} />
                </>
            )}
        </FormProvider>
    );
});

UserAccessManager.displayName = 'UserAccessManager';
