import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Spinner, Input, Connector, Button } from '@schibsted-svp/react-ui';
import { bemgen } from 'lib/bem';
import TravoltaGif from 'gifs/lost-travolta.gif';
import './NewsroomsList.scss';
import { getNewsroomConfig } from 'config';
import { FaSearch } from 'react-icons/fa';

export default class NewsroomsList extends Component {
    static propTypes = {
        acl: PropTypes.object.isRequired,
        searchTerm: PropTypes.string,
        selectNewsroom: PropTypes.func.isRequired,
        onSelectNewsroom: PropTypes.func,
    };

    static defaultProps = {
        searchTerm: undefined,
        onSelectNewsroom: () => {},
    };

    state = {
        searchedNewsroom: '',
    };

    handleOnSearchChange = (event) => {
        this.setState({ searchedNewsroom: event.target.value });
    };

    filterListByName = ({ name }) => {
        if (!this.state.searchedNewsroom) {
            return true;
        }

        return name.toLowerCase().includes(this.state.searchedNewsroom);
    };

    location(newsroom) {
        const { searchTerm } = this.props;

        const location = {
            pathname: `/${newsroom}/assets`,
        };

        const search = new URLSearchParams();

        if (searchTerm) {
            search.append('query', searchTerm);
        }

        location.search = `?${search.toString()}`;

        return location;
    }

    render() {
        const { acl, selectNewsroom, onSelectNewsroom } = this.props;
        const { newsrooms } = acl;
        if (!newsrooms) return <Spinner containerClassName={bemgen('NewsroomsListSpinnerInternal')} />;
        if (!Object.keys(newsrooms).length) {
            return (
                <div className={bemgen('NoAccess')}>
                    <img alt="confused" src={TravoltaGif} className={bemgen('NoAccess__travolta')} />
                    <br />
                    Sorry, you don&apos;t have access to any newsroom in Stream. Please request access from a colleague
                    in your newsroom who is already using Stream.
                </div>
            );
        }

        return (
            <>
                {newsrooms.length > 5 ? (
                    <Connector className={bemgen('NewsroomsList__search')}>
                        <Input
                            value={this.state.searchedNewsroom}
                            onChange={this.handleOnSearchChange}
                            placeholder="Search for newsroom"
                        />
                        <Button type="button" data-testid="search" iconOnly variant="standard">
                            <FaSearch color="#421a91" />
                        </Button>
                    </Connector>
                ) : null}
                <ul className={bemgen('NewsroomsList')}>
                    {newsrooms.filter(this.filterListByName).map(({ id, name }) => {
                        const newsroomConfig = getNewsroomConfig(id);

                        return (
                            <li key={id} className={bemgen('NewsroomsList__element')}>
                                <Link
                                    to={this.location(id)}
                                    className={bemgen('NewsroomsList__link')}
                                    onClick={() => {
                                        selectNewsroom(id);
                                        onSelectNewsroom();
                                    }}
                                >
                                    <img
                                        alt={`${id} logo`}
                                        src={`../../..${newsroomConfig.logo.url}`}
                                        className={bemgen('NewsroomsList__logo')}
                                    />
                                    {name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    }
}
