import { useController } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import { LabeledContainer, Select } from '@schibsted-svp/react-ui';
import { findMultipleOptions, getValuesFromOptionsArray } from 'components/ui/Form/Select';
import podcastCategories from 'podcastCategories';
import type { CategoryData } from '../types';

import css from './PodcastCategoriesSelect.module.scss';

export function PodcastMainCategory({ control }: { control: Control<CategoryData> }) {
    const { field: mainCategoryField } = useController({ name: 'additional.metadata.podcast_mainCategory', control });

    const mainCategories = Object.entries(podcastCategories).map(([value, data]) => {
        const { label } = data;
        return { value, label };
    });

    return (
        <LabeledContainer label="Category" className={css.mainCategory}>
            <p> (first used for ads targeting)</p>

            <Select
                {...mainCategoryField}
                value={findMultipleOptions(mainCategories)(mainCategoryField.value)}
                onChange={(values) => mainCategoryField.onChange(getValuesFromOptionsArray(values))}
                options={mainCategories}
                isClearable={false}
                splitButton={false}
                size="small"
                isMulti
                className={css.categories}
            />
        </LabeledContainer>
    );
}
