import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Notification } from '@schibsted-svp/react-ui';
import { SECOND } from 'lib/time';
import useInterval from 'hooks/useInterval';
import * as assetModel from 'models/asset';
import { isChannelIdle, isChannelRunning } from 'models/channel';
import { atLeastOneEventIsPending, atLeastOneEventIsRunning, isMultiLiveEventIdle } from 'models/multiLiveEvent';
import { duplicateLiveStream } from 'store/live/actions';
import { isLiveRuntimeStopping } from 'store/live/selectors';
import * as liveHelpers from 'components/video/live/helpers';
import { calculateDuration } from 'components/core/VideoCard/dataMapper';
import LiveStartButton from 'components/video/live/LiveControls/LiveRuntimeButtons/LiveStartButton';
import LiveStopButton from 'components/video/live/LiveControls/LiveRuntimeButtons/LiveStopButton';
import { RtmpInfoDialog } from 'components/video/live/RtmpInfoDialog';
import { LiveCreationDialog } from 'components/video/live/LiveCreationDialog';
import PublishVideoConfirmationDialog from 'components/video/PublishVideoConfirmationDialog';
import css from './VideoCardControls.module.scss';

/**
 * @param {Object} asset
 * @param {Number|undefined} [liveDuration]
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
function isStopButtonVisible(asset, liveDuration, events = [], channel) {
    const showWhenRunning =
        liveDuration >= 0 &&
        (assetModel.isExternalOrRtmpEncoder(asset) ||
            (assetModel.isMediaLiveEncoder(asset) && isChannelRunning(channel)) ||
            (assetModel.isElementalEncoder(asset) && atLeastOneEventIsRunning(events)));

    return showWhenRunning;
}

/**
 * @param {Object} asset
 * @param {Number|undefined} [liveDuration]
 * @param {MultiLiveEvent} [events=[]]
 * @param {MediaLiveChannel} [channel]
 * @returns {Boolean}
 */
function isStartButtonVisible(asset, liveDuration, events = [], channel) {
    const showWhenIdle =
        (assetModel.isElementalEncoder(asset) && isMultiLiveEventIdle(events)) ||
        (assetModel.isMediaLiveEncoder(asset) && isChannelIdle(channel));
    const showWhenScheduled =
        liveDuration < 0 &&
        assetModel.isScheduled(asset) &&
        (assetModel.isMediaLiveEncoder(asset) ||
            (assetModel.isElementalEncoder(asset) && atLeastOneEventIsPending(events)));

    return showWhenIdle || showWhenScheduled;
}

function VideoCardControls({
    asset,
    events,
    channel,
    ready,
    isStarting,
    isStopping,
    fetchLiveEncoders,
    saveAsset,
    requireConfirmation,
}) {
    const [liveDuration, setLiveDuration] = useState(calculateDuration(asset));
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [isPublishingProcessRunning, setIsPublishingProcessRunning] = useState(false);
    const isLiveStopping = useSelector(
        (state) =>
            assetModel.isLive(asset) &&
            isLiveRuntimeStopping(state, {
                assetId: asset.id,
                provider: asset.provider,
            })
    );

    const dispatch = useDispatch();

    useEffect(() => {
        setLiveDuration(() => calculateDuration(asset));
    }, [asset]);

    const toggleConfirmationDialog = () => {
        setShowConfirmationDialog((showConfirmation) => !showConfirmation);
    };

    const confirm = async () => {
        const changes = { status: 'active' };

        if (requireConfirmation) {
            toggleConfirmationDialog();
        }
        setIsPublishingProcessRunning(true);

        try {
            await new Promise((resolve, reject) => {
                saveAsset({ id: asset.id, provider: asset.provider, changes, resolve, reject });
            });
        } catch (error) {
            setIsPublishingProcessRunning(false);
            Notification.notify.error('Failed to save the asset');
        }
    };

    const handlePublishClick = () => {
        if (requireConfirmation) {
            toggleConfirmationDialog();
        } else {
            confirm();
        }
    };

    const duplicateLive = () => {
        dispatch(duplicateLiveStream(asset.provider, asset.id));
    };

    useInterval(
        () => {
            setLiveDuration((prevTime) => prevTime + SECOND);
            /**
             duration is calculated in milliseconds and we don't receive round number like 2000 but sth like 2345,
             so we treat the value of duration less than 1 sec as a start of a video
             @see https://github.schibsted.io/svp/admin-front/pull/863#discussion_r869008
             */
            if (assetModel.isElementalEncoder(asset) && liveDuration > -SECOND) {
                fetchLiveEncoders(asset.provider);
            }
        },
        assetModel.isLive(asset) && liveDuration < 0 ? SECOND : null
    );

    const disabled = isStarting || isStopping || !ready;
    const triggerProps = { disabled, buttonSize: 'compact', buttonClassName: css.button };
    const stopButtonDisabled = disabled || liveHelpers.isStopButtonDisabled(asset, liveDuration, events);

    return (
        <div className={css['video-card-buttons']}>
            <Button variant="standard" size="compact" type="button" onClick={duplicateLive}>
                Duplicate
            </Button>
            {liveHelpers.isPublishButtonVisible(asset) && (
                <>
                    <Button
                        variant="standard"
                        size="compact"
                        type="button"
                        loading={isPublishingProcessRunning}
                        onClick={handlePublishClick}
                        disabled={isLiveStopping}
                    >
                        Publish video
                    </Button>
                    <PublishVideoConfirmationDialog
                        assetId={asset.id}
                        provider={asset.provider}
                        shouldBeShown={showConfirmationDialog}
                        toggle={toggleConfirmationDialog}
                        confirm={confirm}
                        isRequired={requireConfirmation}
                        assetType={asset.assetType}
                    />
                </>
            )}
            {liveHelpers.isSetSourceButtonVisible(asset, events) && (
                <LiveCreationDialog
                    form="live"
                    newsroom={asset.provider}
                    assetId={asset.id}
                    triggerProps={triggerProps}
                />
            )}
            {liveHelpers.isRtmpButtonVisible(asset) && (
                <RtmpInfoDialog
                    asset={asset}
                    assetId={asset.id}
                    provider={asset.provider}
                    triggerProps={triggerProps}
                />
            )}

            {isStopButtonVisible(asset, liveDuration, events, channel) && (
                <LiveStopButton
                    provider={asset.provider}
                    assetId={asset.id}
                    loading={isStopping}
                    disabled={stopButtonDisabled}
                    size="compact"
                >
                    Stop video
                </LiveStopButton>
            )}
            {isStartButtonVisible(asset, liveDuration, events, channel) && (
                <LiveStartButton
                    provider={asset.provider}
                    assetId={asset.id}
                    loading={isStarting}
                    disabled={disabled}
                    size="compact"
                >
                    Start video
                </LiveStartButton>
            )}
        </div>
    );
}

VideoCardControls.propTypes = {
    asset: PropTypes.object.isRequired,
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
    channel: PropTypes.object,
    fetchLiveEncoders: PropTypes.func,
    ready: PropTypes.bool.isRequired,
    isStarting: PropTypes.bool.isRequired,
    isStopping: PropTypes.bool.isRequired,
    saveAsset: PropTypes.func.isRequired,
    requireConfirmation: PropTypes.bool,
};

VideoCardControls.defaultProps = {
    fetchLiveEncoders: undefined,
    requireConfirmation: true,
    channel: undefined,
};

export default VideoCardControls;
