import { ACL_FETCH, ACL_FETCH_SUCCESS } from './actionTypes';
import { defaultState } from './state';

function acl(state = defaultState, action) {
    switch (action.type) {
        case ACL_FETCH: {
            return {
                ...state,
                isFetching: true,
            };
        }

        case ACL_FETCH_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                newsrooms: action.payload.newsrooms,
                core: action.payload.core,
            };
        }

        default:
            return state;
    }
}

export default acl;
