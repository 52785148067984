import { ReactNode, useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { Button, Dialog, Dropdown } from '@schibsted-svp/react-ui';
import useToggle from 'hooks/useToggle';
import { usePrevious } from 'hooks/usePrevious';
import { LiveSchedule } from 'components/video/live/form/LiveSchedule';
import type { LiveScheduleFormData } from 'components/video/live/form/LiveSchedule';
import { useLiveScheduleForm, useInitialValues, useLiveScheduleFormSubmit } from './hooks';
import css from './LiveScheduleDialog.module.scss';

interface LiveScheduleDialogProps {
    children: ReactNode;
    assetId: number;
    newsroom: string;
    isRunning: boolean;
    startTime?: number;
    encoderStartTime?: number;
    encoderDuration?: number;
    disabled?: boolean;
}

export function LiveScheduleDialog({
    children,
    assetId,
    newsroom,
    isRunning,
    startTime,
    encoderStartTime,
    encoderDuration,
    disabled = false,
}: LiveScheduleDialogProps) {
    const initialValues = useInitialValues({ newsroom, startTime, encoderStartTime, encoderDuration });
    const { formApi } = useLiveScheduleForm(initialValues);

    const { reset, formState } = formApi;
    const { isSubmitting, isValid } = formState;

    /* RHF has problems with using generic form components so we need to use type assetion here */
    /* @see: https://github.com/react-hook-form/react-hook-form/issues/6726 */
    const liveScheduleFormApi = formApi as unknown as UseFormReturn<LiveScheduleFormData>;

    const [isDialogOpen, toggleDialogOpen] = useToggle();
    const prevIsDialogOpen = usePrevious(isDialogOpen);

    useEffect(() => {
        if (isDialogOpen && prevIsDialogOpen !== isDialogOpen) {
            reset(initialValues);
        }
    }, [prevIsDialogOpen, isDialogOpen, initialValues, reset]);

    const handleSubmit = useLiveScheduleFormSubmit({
        formApi,
        newsroom,
        assetId,
        onSuccess: toggleDialogOpen,
        isRunning,
    });

    return (
        <Dialog
            isOpen={isDialogOpen}
            heading="Schedule live video"
            className={css.dialog}
            onClose={toggleDialogOpen}
            dialogTrigger={
                <Dropdown disabled={disabled} connectGap={0} variant="standard" leftButtonRenderer={() => children}>
                    <Dropdown.Item itemKey="reschedule" onClick={toggleDialogOpen}>
                        Reschedule live video
                    </Dropdown.Item>
                </Dropdown>
            }
        >
            <form onSubmit={handleSubmit}>
                <Dialog.Section className={css.container}>
                    <LiveSchedule newsroom={newsroom} formApi={liveScheduleFormApi} isRunning={isRunning} />
                </Dialog.Section>
                <Dialog.Section mode="flexRight" variant="darker">
                    <Button type="button" variant="standard" onClick={toggleDialogOpen} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={disabled || isSubmitting || !isValid} loading={isSubmitting}>
                        Schedule
                    </Button>
                </Dialog.Section>
            </form>
        </Dialog>
    );
}
