import { reportMessageToSentry } from 'lib/error';
import { difference } from 'lodash';

export const shouldShowLongLiveVideosNotification = (currentAssets, storedAssets) => {
    return !!difference(currentAssets, storedAssets).length;
};

export const generateLongLiveVideoIdsKey = (email, newsroom) => `${email}:${newsroom}:liveAssets`;

export const getStoredLongLiveVideoIds = (email, newsroom) => {
    try {
        const storageKey = generateLongLiveVideoIdsKey(email, newsroom);
        const items = window.localStorage.getItem(storageKey);
        return items ? JSON.parse(items) : [];
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to get stored long live videos',
            extras: {
                error,
            },
        });
        return [];
    }
};

export const setStoredLongLiveVideoIds = (email, newsroom, items) => {
    try {
        const storageKey = generateLongLiveVideoIdsKey(email, newsroom);
        window.localStorage.setItem(storageKey, JSON.stringify(items));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to set stored long live videos',
            extras: {
                error,
            },
        });
    }
};
