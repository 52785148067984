import { memo } from 'react';
import config from 'config';
import classnames from 'classnames/bind';
import { Checkbox, Button, Spinner, InputError, Connector } from '@schibsted-svp/react-ui';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Tooltip from 'components/ui/Tooltip/Tooltip';
import { SubtitlesEditorDialog } from 'components/video/SubtitlesEditor';
import { isSubtitlesUploadingData } from './types';
import type { SubtitlesData, SubtitlesUploadedData, SubtitlesUploadingData } from './types';

import css from './SubtitlesList.module.scss';

const cln = classnames.bind(css);

const { languages } = config;

type SubtitlesItemUploadingProps = {
    subtitles: SubtitlesUploadingData;
};

function SubtitlesItemUploading({ subtitles }: SubtitlesItemUploadingProps) {
    const { filename, language, error } = subtitles;
    return (
        <>
            <span>
                {filename} ({languages[language]})
            </span>
            {subtitles.error ? (
                <Tooltip content={error} offset={[10, 10]} arrow={false}>
                    <div>
                        <InputError>Subtitles upload failed. Please try again.</InputError>
                    </div>
                </Tooltip>
            ) : (
                <Spinner size="compact" containerClassName={css.spinner} />
            )}
        </>
    );
}

type SubtitlesItemUploadedProps = {
    disabled?: boolean;
    subtitles: SubtitlesUploadedData;
    index: number;
    onDefaultChange: (index: number) => void;
};

function SubtitlesItemUploaded({ disabled = false, subtitles, index, onDefaultChange }: SubtitlesItemUploadedProps) {
    const { url, language, default: isDefault } = subtitles;
    return (
        <>
            <a href={url} target="_blank" rel="noopener noreferrer">
                {url.split('/').pop()} ({languages[language]}) <FaExternalLinkAlt />
            </a>
            <Checkbox
                disabled={disabled}
                checked={isDefault}
                onChange={() => onDefaultChange(index)}
                label="Turn on by default"
            />
        </>
    );
}

type SubtitlesListProps = {
    provider: string;
    assetId: number;
    disabled?: boolean;
    subtitles: SubtitlesData[];
    onDelete: (index: number) => void;
    onDefaultChange: (index: number) => void;
};

export const SubtitlesList = memo(function SubtitlesList({
    provider,
    assetId,
    disabled = false,
    subtitles,
    onDelete,
    onDefaultChange,
}: SubtitlesListProps) {
    return (
        <ul className={css.container}>
            {subtitles.map((item, index) => {
                const isUploading = isSubtitlesUploadingData(item);

                return (
                    <li key={item.language} className={cln('item', { loading: isUploading })}>
                        {isSubtitlesUploadingData(item) ? (
                            <SubtitlesItemUploading subtitles={item} />
                        ) : (
                            <SubtitlesItemUploaded
                                disabled={disabled}
                                subtitles={item}
                                index={index}
                                onDefaultChange={onDefaultChange}
                            />
                        )}

                        <Connector gap={8} className={css.buttons}>
                            <SubtitlesEditorDialog
                                assetId={assetId}
                                provider={provider}
                                language={item.language}
                                disabled={isUploading || disabled}
                            />
                            <Button
                                size="compact"
                                variant="standard"
                                type="button"
                                onClick={() => onDelete(index)}
                                disabled={isUploading || disabled}
                            >
                                Delete
                            </Button>
                        </Connector>
                    </li>
                );
            })}
        </ul>
    );
});
