import { get } from 'lodash';
import config, { getNewsroomConfig, getNewsroomLivePaywallAccess } from 'config';
import { getTime, formatRemainingTime } from 'lib/time';

/**
 * @typedef {import('@schibsted-svp/svp-api-types').Asset} Asset
 */

/**
 * @typedef {import('@schibsted-svp/svp-api-types').AssetSubtitles} AssetSubtitles
 */

/**
 * creates a minimal, empty asset
 *
 * @export
 * @param {Object} asset - the minimal definition of an asset
 * @param {String} asset.provider - the newsroom under which the temporary tab should be added
 * @param {Number} asset.id - the temporary, negative id (should be taken from the store)
 * @param {String} asset.title
 * @param {Number} asset.category - category object

 * @returns {Object} the empty asset entity
 */
export function createEmpty({ id, title, category, provider }) {
    return {
        id,
        title,
        category,
        provider,
        additional: {
            tags: [],
            stories: [],
        },
    };
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isScheduled(asset) {
    return asset.flightTimes.start * 1000 > Date.now();
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isStarted(asset) {
    return !isScheduled(asset);
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isLive(asset) {
    return asset.streamType === 'live';
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function wasLive(asset) {
    return asset.streamType === 'wasLive';
}

/**
 * @param {Object} asset
 * @param {String} asset.streamType
 * @returns {Boolean}
 */
export function isVod({ streamType }) {
    return streamType === 'vod';
}

/**
 * @param {Asset} asset
 * @param {String} asset.streamType
 * @returns {Boolean}
 */
export function isAudio({ assetType }) {
    return assetType === 'audio';
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isActive(asset) {
    return asset.status === 'active';
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isDraft(asset) {
    return asset.status === 'draft';
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isReadyToPublish(asset) {
    return asset.status === 'readyToPublish';
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isInactive(asset) {
    return asset.status === 'inactive';
}

/**
 * @param {Asset} asset
 * @returns {Object}
 */
export function getMetadata(asset) {
    return get(asset, 'additional.metadata', {});
}

/**
 * @param {Asset} asset
 * @returns {String}
 */
export function getPlaylistId(asset) {
    return asset?.additional?.metadata?.playlistId || '';
}

/**
 * Get asset's duration in a proper unit which is 'seconds' by default.
 *
 * @param {Asset} asset
 * @param {String} [unit=seconds]
 * @param {Boolean} [precise=false]
 * @returns {Number}
 */
export function getDuration(asset, unit = 'seconds', precise = false) {
    return getTime(asset.duration, unit, precise);
}

/**
 * Get midroll from asset. Only live assets supports midrolls for now
 *
 * @param {Asset} asset
 * @returns {*}
 */
export function getMidroll(asset) {
    const { _embedded } = asset;

    if (_embedded) {
        return _embedded.midroll || null;
    }

    return null;
}

export function prepareChanges(changes) {
    const result = { ...changes };

    if (changes.category) {
        // category requires only an object with the id property
        result.category = { id: changes.category.id };
    }

    return result;
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isRtmpEncoder(asset) {
    // leaving "software" for backwards compatibility
    return ['rtmp', 'software'].includes(get(asset, 'additional.metadata.source'));
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isExternalEncoder(asset) {
    return get(asset, 'additional.metadata.source') === 'external';
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isExternalOrRtmpEncoder(asset) {
    return isExternalEncoder(asset) || isRtmpEncoder(asset);
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isEmptyEncoder(asset) {
    return get(asset, 'additional.metadata.source') === undefined;
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isElementalEncoder(asset) {
    return ['sdi', 'network'].includes(get(asset, 'additional.metadata.source'));
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isSdiEncoder(asset) {
    return get(asset, 'additional.metadata.source') === 'sdi';
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isNetworkEncoder(asset) {
    return get(asset, 'additional.metadata.source') === 'network';
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isMediaLiveEncoder(asset) {
    return asset?.additional.metadata.source === 'medialive';
}

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export function isHLSProtocol(asset) {
    return get(asset, 'additional.metadata.protocol') === 'hls';
}

/**
 * @param {Asset} [asset]
 * @returns {String[]}
 */
export const getStreamConfiguration = (asset) => get(asset, 'streamConfiguration.properties', []);

/**
 * @param {Asset} [asset]
 * @returns {String[]}
 */
export const getAccess = (asset) => Object.keys(get(asset, 'additional.access', {}));

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export const isGeoblocked = (asset) => getStreamConfiguration(asset).includes('geoblocked');

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export const isStreamConfigurationEmpty = (asset) => getStreamConfiguration(asset).length === 0;

/**
 * @param {Asset} [asset]
 * @returns {Boolean}
 */
export const isBehindPaywall = (asset) => {
    const defaultPaywallAccess = getNewsroomLivePaywallAccess(asset.provider);
    return defaultPaywallAccess && getAccess(asset).some((item) => item === defaultPaywallAccess);
};

export const isEncrypted = (asset) => {
    const streamConfigurationProperties = getStreamConfiguration(asset);
    return ['tokenSecured', 'encrypted'].some((property) => streamConfigurationProperties.includes(property));
};

export const isVodDownloadable = (asset) => isVod(asset) && Boolean(asset.streamUrls.mp4) && !isEncrypted(asset);

export const isVideoStreamDownloadable = (asset) => wasLive(asset) || (isVod(asset) && isEncrypted(asset));

export const hasPlayback = (asset) => Boolean(asset?.playback);

export const makeStreamTypeLabel = (asset, labels) => {
    const { streamType, status } = asset;
    const streamTypeLabel = labels.streamType[streamType].toUpperCase();

    if (streamTypeLabel === 'LIVE' && status === 'active') {
        if (isStarted(asset) && !isEmptyEncoder(asset)) {
            return `${streamTypeLabel} NOW`;
        }
        return `UPCOMING ${streamTypeLabel}`;
    }
    return streamTypeLabel;
};

export const STREAM_TYPE = {
    LIVE: 1,
    WAS_LIVE: 2,
    VOD: 3,
    LIVE_HLS: 4,
};

export const LIVE_TYPES = ['LIVE', 'LIVE NOW', 'UPCOMING LIVE'];

export function getIngestState({ upload = {}, trimming = {}, conversion = {} }) {
    if (get(upload, 'state', 'done') !== 'done') {
        return { ...upload, type: 'upload' };
    }

    if (get(trimming, 'state', 'done') !== 'done') {
        return { ...trimming, type: 'trimming' };
    }

    if (get(conversion, 'state', 'done') !== 'done') {
        return { ...conversion, type: 'conversion' };
    }

    return {};
}

export function getProgressLabel({ type, state, progress, secondsLeft }) {
    if (type === 'upload' && state === 'upload') {
        const remaining = `\u2014 ${formatRemainingTime(secondsLeft)} remaining`;
        return progress > 0 ? `UPLOADING ${progress}% ${secondsLeft ? remaining : ''}` : 'UPLOADING SCHEDULED';
    }

    if (type === 'upload' && state === 'transcode') {
        return progress > 0 ? `ENCODING ${progress}%` : 'ENCODING SCHEDULED';
    }

    if (type === 'trimming' && state === 'transcode') {
        return progress > 0 ? `TRIMMING ${progress}%` : 'TRIMMING SCHEDULED';
    }

    if (type === 'conversion' && state === 'convert') {
        return progress > 0 ? `PREPARING DOWNLOAD ${progress}%` : 'PREPARING DOWNLOAD SCHEDULED';
    }
    return '';
}

/**
 * @param {Asset} asset
 * @param {number} minutes
 * @returns {Boolean}
 */
export function isStartedAfterSpecifiedTime(asset, time) {
    return asset.flightTimes.start * 1000 + time < Date.now();
}

/**
 * @param {Asset} asset
 * @return {Number|undefined}
 */
export function getScheduledLiveDuration(asset) {
    const { liveDuration } = getMetadata(asset);
    return liveDuration ? Number(liveDuration) : undefined;
}

/**
 * @param {Asset} asset
 * @returns {String}
 */
export function getPodcastProvider({ provider, category }) {
    const E24_CATEGORY_ID = config.podcasts?.categoryId?.e24;
    return provider === 'vgtv' && category.parentId === E24_CATEGORY_ID ? 'e24' : provider;
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isPodcastPushEnabled(asset) {
    const provider = getPodcastProvider(asset);
    return (config.podcasts?.pushEnabledFor || []).includes(provider);
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isPushNotificationSent(asset) {
    return Boolean(asset.additional.metadata?.notificationSent);
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isLivingThumbnailAvailable(asset) {
    return isLive(asset) || wasLive(asset);
}

/**
 * @param {Asset} asset
 * @returns {String|undefined}
 */
export function getPreviewUrl(asset) {
    const { assetMetadataKey } = getNewsroomConfig(asset?.provider).previews;
    const metadata = getMetadata(asset);
    return metadata[assetMetadataKey];
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isEngagementChartAvailable(asset) {
    if (isLive(asset) || wasLive(asset)) {
        return false;
    }
    const { features } = getNewsroomConfig(asset?.provider);
    return Boolean(features.engagementChart?.enabled);
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function hasTextToSpeech(asset) {
    return Boolean(asset?.additional.metadata.tts_articleId);
}

/**
 * @param {Asset} asset
 * @param {AssetSubtitles[]} subtitles
 * @returns {Boolean}
 */
export function hasSubtitlesChanged(asset, subtitles = []) {
    return (
        asset.additional?.subtitles?.length !== subtitles.length ||
        subtitles.some(({ url }, index) => asset.additional.subtitles[index]?.url !== url)
    );
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function hasFreeAccess(asset) {
    const accessKey = getAccess(asset)[0];
    return (
        ['tokenSecured', 'encrypted'].every((property) => asset.streamConfiguration.properties.includes(property)) &&
        !accessKey
    );
}
