import { dynamicFieldSchema } from 'components/AssetForm/helpers/DynamicForm';
import z, { ZodError } from 'zod';

export const urlValidator = z.object({
    value: z.union([z.string().url({ message: 'Invalid url' }), z.null(), z.literal('')]),
});

export const categoryFormSchema = z
    .object({
        title: z.string().min(2, { message: 'Title must be at least 2 characters long' }),
        additional: z
            .object({
                description: z.string().optional().nullable(),
                images: z
                    .array(
                        z
                            .object({
                                key: z
                                    .string()
                                    .trim()
                                    .min(1, { message: 'Image name is required' })
                                    .regex(/^[a-zA-Z0-9\-_]{1,255}$/, { message: 'Invalid title' }),
                            })
                            .passthrough()
                    )
                    .optional(),
                metadata: z
                    .object({
                        podcast_author: z.object({ value: z.string().optional() }),
                        podcast_language: z.object({ value: z.string().optional() }),
                        podcast_link: urlValidator,
                        podcast_ownerEmail: z.object({
                            value: z.union([z.string().email({ message: 'Invalid e-mail' }), z.null(), z.literal('')]),
                        }),
                        podcast_acast_prependUrl: urlValidator,
                        podcast_richDescription: z.object({
                            value: z
                                .union([
                                    z.literal(''),
                                    z
                                        .string()
                                        .min(2, { message: 'Rich description must be at least 2 characters long' })
                                        .max(2048, {
                                            message: 'Rich description must be at most 2048 characters long',
                                        }),
                                ])
                                .optional()
                                .nullable(),
                        }),
                    })
                    .passthrough(),
            })
            .passthrough(),
        simpleMetadata: z
            .array(
                z.object({
                    key: z.string().min(1, { message: 'Metadata key is required' }),
                    value: z.string().min(1, { message: 'Metadata value is required' }),
                })
            )
            .optional(),
        dynamicFieldsMetadata: z
            .array(
                z.object({
                    key: z.string().min(1, { message: 'Key is required' }),
                    value: z
                        .string()
                        .min(1, { message: 'Dynamic field config is required' })
                        .superRefine((value, ctx) => {
                            try {
                                dynamicFieldSchema.parse(JSON.parse(value));
                            } catch (error) {
                                if (error instanceof ZodError) {
                                    const issue = error.issues[0];
                                    if (issue) {
                                        ctx.addIssue({
                                            code: z.ZodIssueCode.custom,
                                            message: `Config schema error${
                                                issue.path?.length ? ` (path: '${issue.path.join('.')}')` : ''
                                            }: ${issue.message.toLowerCase()}`,
                                        });
                                    } else {
                                        ctx.addIssue({
                                            code: z.ZodIssueCode.custom,
                                            message: 'Unknown config schema error',
                                        });
                                    }
                                } else {
                                    ctx.addIssue({
                                        code: z.ZodIssueCode.custom,
                                        message: 'Config is not valid JSON',
                                    });
                                }
                            }
                        }),
                })
            )
            .optional(),
        podmeMetadata: z
            .object({
                sendToPodme: z.boolean().optional(),
                sendRssToPodme: z.boolean().optional(),
                podmeCategories: z.array(z.number()).optional(),
            })
            .superRefine(({ sendToPodme, podmeCategories }, ctx) => {
                if (sendToPodme && !podmeCategories?.length) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ['podmeCategories'],
                        message: 'Please choose category',
                    });
                }
            })
            .optional(),
    })
    .passthrough()
    .superRefine(({ additional, podmeMetadata }, ctx) => {
        const { description, image, metadata } = additional;
        const { sendToPodme } = podmeMetadata;
        if (!sendToPodme) {
            return;
        }
        if (!description) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['additional.description'],
                message: 'Description is required for Podme integration',
            });
        }
        if (!image) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['additional.image'],
                message: 'Image is required for Podme integration',
            });
        }
        if (!metadata.podcast_author?.value) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['additional.metadata.podcast_author.value'],
                message: 'Author is required for Podme integration',
            });
        }
        if (!metadata.podcast_language?.value) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['additional.metadata.podcast_language.value'],
                message: 'Language is required for Podme integration',
            });
        }
    });
