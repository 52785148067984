import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import { Button, Dropdown } from '@schibsted-svp/react-ui';
import { LiveCreationDialog } from 'components/video/live/LiveCreationDialog';
import { UploadDialog } from '../UploadDialog';
import css from './AssetCreationButton.module.scss';

const cln = classnames.bind(css);

export default function AssetCreationButton() {
    const { newsroom } = useParams();

    const mainButtonRenderer = (onClick) => (
        <Button className={css.AssetCreationButton} type="button" variant="standard" onClick={onClick}>
            New
        </Button>
    );

    return (
        <>
            <div className={cln('AssetCreationButton', { 'AssetCreationButton--buttons': true })}>
                <LiveCreationDialog form="live" newsroom={newsroom} />
                <UploadDialog />
            </div>

            <Dropdown
                className={cln('AssetCreationButton', { 'AssetCreationButton--dropdown': true })}
                mainButtonRenderer={mainButtonRenderer}
                variant="standard"
            >
                <UploadDialog triggerType="dropdown" />
                <LiveCreationDialog form="live" triggerType="dropdown" label="dropdown" newsroom={newsroom} />
            </Dropdown>
        </>
    );
}
