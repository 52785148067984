import type { CutRange } from 'store/uploads/previews/slice';
import type {
    AnimatedElements,
    CutRangeSelectorStateOnMouseDownAndMove,
    CutRangeSelectorStateOnMouseDownAndMoveWithSelection,
    CutRangeSelectorStateOnMouseMove,
    CutRangeSelectorStateReady,
    TimeMarker,
} from './types';
import { isCutRangeSelectorStateOnMouseDownAndMove } from './types';

const RATIO_MAX = 1;
const SCROLL_SIZE = 20;

export function minmax(current: number, max = RATIO_MAX, min = 0) {
    return Math.max(min, Math.min(max, current));
}

export function toCSSPixel(value?: number) {
    return `${value ?? 0}px`;
}

export function toCSSPercent(ratio?: number) {
    return `${ratio ? ratio * 100 : 0}%`;
}

export function getSelectionCenteredScrollLeft({ container, selector }: CutRangeSelectorStateReady) {
    const scrollLeft =
        selector.clientWidth > container.clientWidth
            ? selector.offsetLeft - SCROLL_SIZE
            : selector.offsetLeft - (container.clientWidth - selector.clientWidth) / 2;

    return minmax(scrollLeft, container.scrollWidth);
}

function getSliderLeftPadding({ container, slider }: CutRangeSelectorStateReady) {
    return Math.max(slider.offsetLeft - container.scrollLeft, 0);
}

function getSliderRightPadding({ container, slider }: CutRangeSelectorStateReady) {
    if (slider.clientWidth < container.clientWidth) {
        return 0;
    }
    return Math.max(container.clientWidth - (slider.offsetLeft - container.scrollLeft + slider.clientWidth), 0);
}

function getScrollLeftDelta(
    { container, slider, mouseMove: { positionX } }: CutRangeSelectorStateOnMouseMove,
    paddingLeft: number,
    paddingRight: number
) {
    // mouse position near container's left edge
    if (paddingLeft < slider.offsetLeft && positionX < SCROLL_SIZE) {
        const scrollSpeedRatio = minmax((SCROLL_SIZE - positionX) / SCROLL_SIZE);
        return -Math.round(SCROLL_SIZE * scrollSpeedRatio);
    }

    // mouse position near container's right edge
    if (paddingRight < slider.offsetLeft && positionX > container.clientWidth - SCROLL_SIZE) {
        const scrollSpeedRatio = minmax((SCROLL_SIZE - (container.clientWidth - positionX)) / SCROLL_SIZE);
        return Math.round(SCROLL_SIZE * scrollSpeedRatio);
    }

    return 0;
}

function getTimeMarkerPosition(
    { container, slider, mouseMove: { positionX } }: CutRangeSelectorStateOnMouseMove,
    duration: number,
    paddingLeft: number,
    paddingRight: number
): TimeMarker {
    const absoluteX = positionX + container.scrollLeft;
    const maxValue = Math.min(slider.clientWidth + paddingLeft, container.clientWidth - paddingRight);
    return {
        left: minmax(positionX, maxValue, paddingLeft),
        value: (minmax(absoluteX - slider.offsetLeft, slider.clientWidth) / slider.clientWidth) * duration,
    };
}

function getTooltipPosition({ container, slider }: CutRangeSelectorStateReady, ratio: number, translateX = 0) {
    return minmax(
        ratio * slider.clientWidth - container.scrollLeft + slider.offsetLeft + translateX,
        container.clientWidth
    );
}

export function getAnimatedElements(
    state: CutRangeSelectorStateOnMouseMove | CutRangeSelectorStateOnMouseDownAndMove,
    duration: number,
    nextCutRange?: CutRange
): AnimatedElements | undefined {
    const paddingLeft = getSliderLeftPadding(state);
    const paddingRight = getSliderRightPadding(state);
    const nextTimeMarker = getTimeMarkerPosition(state, duration, paddingLeft, paddingRight);

    if (!nextCutRange) {
        return { nextTimeMarker };
    }

    const nextCutRangeTooltips = {
        begin: getTooltipPosition(state, nextCutRange.begin / duration, state.mouseMove.markerLeftTranslateX),
        end: getTooltipPosition(state, nextCutRange.end / duration, state.mouseMove.markerRightTranslateX),
    };

    if (!isCutRangeSelectorStateOnMouseDownAndMove(state)) {
        return { nextCutRange, nextCutRangeTooltips, nextTimeMarker };
    }

    return {
        nextCutRange,
        nextCutRangeTooltips,
        nextTimeMarker,
        scrollLeftDelta: getScrollLeftDelta(state, paddingLeft, paddingRight),
    };
}

export function getAnimatedElementsByMovingBeginMarker(
    state: CutRangeSelectorStateOnMouseDownAndMoveWithSelection,
    duration: number
) {
    const { container, slider, mouseDown, mouseMove } = state;
    const absolutePositionX = mouseMove.positionX + container.scrollLeft;
    const mouseMoveRatio = (absolutePositionX + mouseMove.markerLeftTranslateX) / slider.clientWidth;
    const currEndRatio = mouseDown.cutRange.end / duration;
    const maxBeginRatio = currEndRatio - mouseDown.minRatioValue;
    const nextBeginRatio = minmax(mouseMoveRatio, maxBeginRatio);

    return getAnimatedElements(state, duration, {
        begin: nextBeginRatio * duration,
        end: mouseDown.cutRange.end,
    });
}

export function getAnimatedElementsByMovingEndMarker(
    state: CutRangeSelectorStateOnMouseDownAndMoveWithSelection,
    duration: number
) {
    const { container, slider, mouseDown, mouseMove } = state;
    const absolutePositionX = mouseMove.positionX + container.scrollLeft;
    const mouseMoveRatio =
        (absolutePositionX - mouseMove.markerRightTranslateX - slider.offsetLeft) / slider.clientWidth;
    const currBeginRatio = mouseDown.cutRange.begin / duration;
    const minEndRatio = currBeginRatio + mouseDown.minRatioValue;
    const nextEndRatio = minmax(mouseMoveRatio, RATIO_MAX, minEndRatio);

    return getAnimatedElements(state, duration, {
        begin: mouseDown.cutRange.begin,
        end: nextEndRatio * duration,
    });
}

export function getAnimatedElementsByCreatingSelection(
    state: CutRangeSelectorStateOnMouseDownAndMove,
    duration: number
) {
    const { container, slider, mouseDown, mouseMove } = state;
    const absolutePositionX = mouseMove.positionX + container.scrollLeft;
    const absoluteInitialPositionX = mouseDown.positionX + mouseDown.scrollLeft;
    const isBeginGreater = absolutePositionX >= absoluteInitialPositionX;
    const markerValue = isBeginGreater
        ? mouseMove.markerRightTranslateX + slider.offsetLeft
        : mouseMove.markerLeftTranslateX;
    const mouseDownRatio = absoluteInitialPositionX / slider.clientWidth;
    const mouseMoveRatio = (absolutePositionX - markerValue) / slider.clientWidth;
    const nextBeginRatio = minmax(
        isBeginGreater ? mouseDownRatio : mouseMoveRatio,
        RATIO_MAX - mouseDown.minRatioValue
    );
    const nextEndRatio = minmax(isBeginGreater ? mouseMoveRatio : mouseDownRatio, RATIO_MAX, mouseDown.minRatioValue);

    return getAnimatedElements(state, duration, {
        begin: nextBeginRatio * duration,
        end: nextEndRatio * duration,
    });
}

export function getAnimatedElementsByMovingSelection(
    state: CutRangeSelectorStateOnMouseDownAndMoveWithSelection,
    duration: number
) {
    const { container, slider, mouseDown, mouseMove } = state;
    const diffRatio =
        (mouseMove.positionX - mouseDown.positionX + (container.scrollLeft - mouseDown.scrollLeft)) /
        slider.clientWidth;
    const currBeginRatio = mouseDown.cutRange.begin / duration;
    const currEndRatio = mouseDown.cutRange.end / duration;
    const currRangeRatio = currEndRatio - currBeginRatio;
    const nextBeginRatio = minmax(currBeginRatio + diffRatio, RATIO_MAX - currRangeRatio);
    const nextEndRatio = minmax(currEndRatio + diffRatio, RATIO_MAX, currRangeRatio);

    return getAnimatedElements(state, duration, {
        begin: nextBeginRatio * duration,
        end: nextEndRatio * duration,
    });
}
