import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import * as ReactGA from 'react-ga';
import { api as adminBffSdk } from 'services/admin-bff-sdk';
import { useGetAsset } from 'components/video/SubtitlesEditor/hooks/useAsset';
import { areLiveEncodersSupported, hasLiveAssetFailed } from 'store/live/selectors';
import { formatLiveStreamValues } from './utils';

export type InitialValues = {
    dvrLength?: number;
    streamQuality?: 'standard' | 'normal';
    livePreview?: boolean;
    startTime?: number;
    enableAssetSchedule: boolean;
    encoderDuration?: number;
    access: {
        geoblock: boolean;
        paywall: string | undefined;
    };
};
type UseLiveCreationData = (parameters: { isEditing: boolean; opened: boolean; newsroom: string; assetId: number }) => {
    initialValues: InitialValues;
    enableEncoderInput: boolean;
    hasLiveFailed: boolean;
};

export const useLiveCreationData: UseLiveCreationData = ({ isEditing, opened, newsroom, assetId }) => {
    const [getLiveQuery, liveDetails] = adminBffSdk.useLazyGetLiveQuery();
    const liveDetailsEvents =
        // eslint-disable-next-line no-underscore-dangle
        liveDetails.data?.live.__typename === 'ElementalLive' ? liveDetails.data?.live.events : [];

    const asset = useGetAsset({ assetId, provider: newsroom });
    const initialValues = formatLiveStreamValues(asset, liveDetailsEvents);
    const enableEncoderInput = useAppSelector((state) => areLiveEncodersSupported(state, { newsroom }));
    const hasLiveFailed = useAppSelector((state) => hasLiveAssetFailed(state, { newsroom, assetId }));

    useEffect(() => {
        if (opened) {
            if (isEditing) {
                getLiveQuery({ newsroom, assetId });
            }
            ReactGA.modalview(isEditing ? 'update-live' : 'create-live');
        }
    }, [opened, newsroom, assetId, getLiveQuery, isEditing]);

    return {
        initialValues,
        enableEncoderInput,
        hasLiveFailed,
    };
};
