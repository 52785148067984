import { isAudioOrVideoType } from 'lib/file';

/**
 * @param {Array<File>} files
 * @returns {{ isValid: Boolean, file?: File, message?: String }}
 */
export default function validator(files) {
    if (files.length > 1) {
        return {
            isValid: false,
            message: 'Too many files selected. Try again with the only one.',
        };
    }

    const file = files.find(isAudioOrVideoType);
    if (!file) {
        return {
            isValid: false,
            message: `Selected file ${files[0].name} isn't a valid video`,
        };
    }

    return {
        isValid: true,
        file,
    };
}
