import { put, call, takeEvery, select } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';

import { svpApiClient } from 'services';
import SELECT_NEWSROOM from 'store/newsroom/actionTypes';
import { selectNewsroom } from 'store/newsroom/selectors';

import { linkedProvidersFetchSuccess, linkedProvidersFetchError } from './actions';

/**
 *
 * @returns {Generator<*, void, ?>}
 */
export function* fetchLinkedProviders() {
    const currentNewsroom = yield select(selectNewsroom);
    try {
        const linked = yield call(svpApiClient.fetchLinkedProviders, { provider: currentNewsroom });

        yield put(linkedProvidersFetchSuccess({ linked }));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch linked providers',
            extras: {
                error,
            },
        });
        yield put(linkedProvidersFetchError());
    }
}

export default [takeEvery(SELECT_NEWSROOM, fetchLinkedProviders)];
