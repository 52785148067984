import { useCallback, useMemo, useRef } from 'react';

export type UseContextStateReturn<T extends Record<string, unknown>> = {
    getState: () => T;
    setState: (state: Partial<T>) => void;
    subscribe: (callback: () => void) => () => void;
};

export function useContextState<T extends Record<string, unknown>>(initialState: T): UseContextStateReturn<T> {
    const contextState = useRef<T>(initialState);
    const subscribers = useRef(new Set<() => void>());

    const getState = useCallback(() => contextState.current, []);

    const setState = useCallback((state: Partial<T>) => {
        contextState.current = { ...contextState.current, ...state };
        subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
        subscribers.current.add(callback);
        return () => subscribers.current.delete(callback);
    }, []);

    return useMemo(() => ({ getState, setState, subscribe }), [getState, setState, subscribe]);
}
