import { LabeledContainer } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { useEffect, type ReactNode } from 'react';
import * as assetModel from 'models/asset';
import * as multiLiveEventModel from 'models/multiLiveEvent';
import { useLazyGetLiveQuery } from 'services/admin-bff-sdk/generated';
import css from './StreamDetails.module.scss';

function dvrLengthToHumanReadable(seconds: number) {
    if (!Number.isFinite(seconds)) {
        return '';
    }
    if (seconds >= 3600) {
        const hours = Math.floor(seconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    if (seconds >= 60) {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    if (seconds >= 0) {
        return 'No DVR';
    }
    if (seconds === -1) {
        return 'Unlimited';
    }
    return '';
}

interface StreamDetailsProps {
    asset: Asset;
    divider: ReactNode;
}

export function StreamDetails({ asset, divider }: StreamDetailsProps) {
    const [getLive, { data, isUninitialized }] = useLazyGetLiveQuery();
    const { provider: newsroom, id: assetId } = asset;
    const isLive = assetModel.isLive(asset);
    const isHlsProtocol = assetModel.isHLSProtocol(asset);
    const dvrLength =
        // eslint-disable-next-line no-underscore-dangle
        data?.live.__typename === 'ElementalLive' ? multiLiveEventModel.getDvrLength(data.live.events) : undefined;

    useEffect(() => {
        if (isLive && isHlsProtocol) {
            getLive({ newsroom, assetId });
        }
    }, [assetId, newsroom, isLive, isHlsProtocol, getLive]);

    if (isUninitialized || !dvrLength) {
        return null;
    }

    return (
        <>
            <LabeledContainer strict label="DVR Length">
                <span className={css.StreamDetails}>{dvrLengthToHumanReadable(dvrLength)}</span>
            </LabeledContainer>
            {divider}
        </>
    );
}
