import { memo, useEffect } from 'react';
import { minmax, round } from './utils';
import type { EditMethod, ItemChangeHandler, Selection } from './types';
import { useTimelineState, useSubscribeTimelineState } from './hooks/useTimelineState';
import css from './TimelineSelector.module.scss';

const ITEM_MINIMUM_DURATION = 0.5; // second

function calculateSelection(method: EditMethod, distanceTime: number, initialSelection: Selection) {
    switch (method) {
        case 'move': {
            const { startTime, endTime, max, min } = initialSelection;
            const itemDuration = endTime - startTime;
            return {
                ...initialSelection,
                startTime: round(minmax(startTime + distanceTime, max - itemDuration, min)),
                endTime: round(minmax(endTime + distanceTime, max, min + itemDuration)),
            };
        }

        case 'resizeBegin': {
            const { startTime, endTime, min } = initialSelection;
            const max = endTime - ITEM_MINIMUM_DURATION;
            return {
                ...initialSelection,
                startTime: round(minmax(startTime + distanceTime, max, min)),
            };
        }

        case 'resizeEnd': {
            const { startTime, endTime, max } = initialSelection;
            const min = startTime + ITEM_MINIMUM_DURATION;
            return {
                ...initialSelection,
                endTime: round(minmax(endTime + distanceTime, max, min)),
            };
        }

        default: {
            throw new Error('Unsupported edit method in Timeline');
        }
    }
}

export type TimelineSelectorProps = {
    height?: number;
    onChange: ItemChangeHandler;
};

export const TimelineSelector = memo<TimelineSelectorProps>(function TimelineSelector({ height, onChange }) {
    const { getState, setState, subscribe } = useTimelineState();
    const method = useSubscribeTimelineState((state) => state.method);
    const selection = useSubscribeTimelineState((state) => state.selection);
    const pixelsPerSecond = useSubscribeTimelineState((state) => state.pixelsPerSecond);

    useEffect(() => {
        const { offset: initialOffset, scroll: initialScroll, selection: initialSelection } = getState();
        if (!method || !initialOffset || !initialSelection) {
            return undefined;
        }

        const initialAbsoluteOffset = initialOffset + initialScroll;
        let lastAbsoluteOffset: number;

        return subscribe(() => {
            const state = getState();
            if (!state.offset) {
                return;
            }

            const absoluteOffset = state.offset + state.scroll;
            if (absoluteOffset === lastAbsoluteOffset) {
                return;
            }
            lastAbsoluteOffset = absoluteOffset;

            const distanceTime = (absoluteOffset - initialAbsoluteOffset) / state.pixelsPerSecond;
            const nextSelection = calculateSelection(method, distanceTime, initialSelection);
            setState({ selection: nextSelection });
            onChange({ startTime: nextSelection.startTime, endTime: nextSelection.endTime }, nextSelection.index);
        });
    }, [method, onChange, getState, setState, subscribe]);

    if (!selection) {
        return null;
    }

    return (
        <div className={css.slider}>
            <div
                className={css.selector}
                style={{
                    transform: `translateX(${selection.startTime * pixelsPerSecond}px)`,
                    width: (selection.endTime - selection.startTime) * pixelsPerSecond,
                    height,
                }}
            />
        </div>
    );
});
