import { keyBy } from 'lodash';
import { merge } from 'lib/object';
import {
    LIVE_ENCODERS_FAILURE,
    LIVE_ENCODERS_REQUEST,
    LIVE_ENCODERS_SUCCESS,
    MEDIALIVE_CHANNEL_STATE_CHANGE,
} from '../actions';

/**
 * @param {Object} channel
 * @param {String} channel.state
 * @param {Object} [channel.schedule]
 * @param {Number} [channel.schedule.startTime]
 * @param {Number} [channel.schedule.endTime]
 * @return {Object}
 */
const channelMapper = (channel) => ({
    ...channel,
    state: channel.state.toLowerCase(),
});

/**
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {String} action.newsroom
 * @param {Object} action.rest...
 * @returns {Object}
 */
export default function channelsReducer(state = {}, { type, newsroom, ...rest }) {
    const newsroomState = state[newsroom] || {};

    switch (type) {
        case LIVE_ENCODERS_REQUEST: {
            return merge(state, {
                [newsroom]: {
                    ...newsroomState,
                    loading: true,
                    items: newsroomState.items,
                },
            });
        }

        case LIVE_ENCODERS_FAILURE:
        case LIVE_ENCODERS_SUCCESS: {
            const { channels = [] } = rest;
            return {
                ...state,
                [newsroom]: {
                    ...newsroomState,
                    loading: false,
                    items: keyBy(channels.map(channelMapper), 'id'),
                },
            };
        }

        case MEDIALIVE_CHANNEL_STATE_CHANGE: {
            const { channelId } = rest.data;
            if (!newsroomState?.items[channelId]) return state;
            return {
                ...state,
                [newsroom]: {
                    ...newsroomState,
                    items: {
                        ...newsroomState.items,
                        [channelId]: {
                            ...newsroomState.items[channelId],
                            state: rest.data.state.toLowerCase(),
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
}
