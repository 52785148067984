import { call, put, takeLatest, select } from '@redux-saga/core/effects';
import { adminBffClient } from 'services';
import { getConversionState } from 'store/video/stream-conversion/selectors';
import {
    FETCH_STREAM_CONVERSION,
    fetchStreamConversionFailure,
    fetchConversionURL,
    streamConversionReset,
} from 'store/video/stream-conversion/actions';

export function* startStreamConversion({ provider, id }) {
    try {
        const { url } = yield call(adminBffClient.prepareConversionURL, provider, id);
        yield put(fetchConversionURL(provider, id, url));
    } catch (error) {
        yield put(fetchStreamConversionFailure(provider, id, error.message));
    }
}

export function* resetStreamConversion({ provider, id }) {
    const conversionState = yield select(getConversionState, { provider, id });

    if (conversionState) {
        yield put(streamConversionReset(provider, id));
    }
}

export default [takeLatest(FETCH_STREAM_CONVERSION, startStreamConversion)];
