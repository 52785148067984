import { getTranscodingState } from 'store/video/upload/selectors';
import { getCuttingState } from 'store/video/cutting/selectors';
import { CUTTING_STATE } from 'store/video/cutting/reducers';
import { UPLOAD_STATE } from 'store/video/upload/reducers';

/**
 *
 * @param {Object} state
 * @param {Object} props
 * @param {Number} props.id
 * @param {String} props.provider
 * @returns {{renditions: *, poster: (asset.images.main|string), thumbnails: (asset.images.snapshots|string)}}
 */
export const getVideoArtifacts = (state, { id, provider }) => {
    const asset = state?.assets?.items?.[provider]?.[id]?.asset || {
        streamUrls: {},
        images: {},
    };

    const { hls } = asset.streamUrls;
    const { main, snapshots } = asset.images;

    const parts = hls.split('/');
    const items = parts[parts.length - 2].split(',');
    const renditions = items
        .slice(1, items.length - 1)
        .map((rendition) => rendition.split('_'))
        .reduce(
            (acc, [width, height, bitrate]) =>
                acc.concat({
                    width: Number(width),
                    height: Number(height),
                    bitrate: Number(bitrate),
                }),
            []
        );

    return {
        renditions,
        poster: main || '',
        thumbnails: snapshots || '',
    };
};

export const isReady = (state, { id, provider }) => {
    const streamUrl = state?.assets?.items?.[provider]?.[id]?.asset?.streamUrls?.hls || '';
    const transcodingState = getTranscodingState(state, { provider, id });

    return !streamUrl.includes('placeholder.m3u8') && transcodingState === UPLOAD_STATE.DONE;
};

export const getOriginalStreamUrls = (state, { id, provider }) => {
    return state.assets.items?.[provider]?.[id]?.asset.additional?.metadata?.originalStreamUrls;
};

export const areTranscodingsDone = (state, { provider, id }) => {
    const transcodingState = getTranscodingState(state, { provider, id });
    const cuttingState = getCuttingState(state, { provider, id });
    return transcodingState === UPLOAD_STATE.DONE && cuttingState === CUTTING_STATE.DONE;
};
