import {
    isElementalEncoder,
    isEmptyEncoder,
    isExternalOrRtmpEncoder,
    isReadyToPublish,
    isRtmpEncoder,
} from 'models/asset';
import {
    allEventsAreRunning,
    allEventsHaveError,
    getEndTime,
    atLeastOneEvent,
    isMultiEvent,
    noEvents,
} from 'models/multiLiveEvent';

/** @type {Number} - to avoid crushing the second event during start by cancelling events at the time */
export const DISABLE_STOP_BUTTON_BREAKPOINT = -120000;

/**
 * @param {Asset} asset
 * @param {Number} [liveDuration]
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isDurationTimeVisible(asset, liveDuration, events = []) {
    return (
        Number.isFinite(liveDuration) &&
        (isExternalOrRtmpEncoder(asset) ||
            (atLeastOneEvent(events) && isElementalEncoder(asset) && !allEventsHaveError(events)))
    );
}

/**
 * @param {Asset} asset
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isEndTimeVisible(asset, events = []) {
    return Boolean(isElementalEncoder(asset) && !allEventsHaveError(events) && getEndTime(events));
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isPublishButtonVisible(asset) {
    return isReadyToPublish(asset);
}

/**
 * @param {Asset} asset
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isElementalEncoderButtonVisible(asset, events = []) {
    return isElementalEncoder(asset) && atLeastOneEvent(events) && !allEventsHaveError(events);
}

/**
 * @param {Asset} asset
 * @returns {Boolean}
 */
export function isRtmpButtonVisible(asset) {
    return isRtmpEncoder(asset);
}

/**
 * @param {Asset} asset
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isSetSourceButtonVisible(asset, events = []) {
    return (
        ((isElementalEncoder(asset) || isEmptyEncoder(asset)) && noEvents(events)) ||
        (isElementalEncoder(asset) && allEventsHaveError(events))
    );
}

/**
 * To avoid crushing events on cancellation/stopping, the stop button is blocked for some time:
 * a few minutes before the events are starting and up to the moment when all events are running.
 * The issue happens when stopping more than one event at the same time.
 *
 * @param {Asset} asset
 * @param {Number} [liveDuration]
 * @param {MultiLiveEvent} [events=[]]
 * @returns {Boolean}
 */
export function isStopButtonDisabled(asset, liveDuration, events = []) {
    const blockOnMultiLiveEventStarting = () =>
        isMultiEvent(events) &&
        ((liveDuration >= 0 && !allEventsAreRunning(events)) ||
            (liveDuration >= DISABLE_STOP_BUTTON_BREAKPOINT && liveDuration < 0));

    return (
        isElementalEncoder(asset) &&
        (noEvents(events) ||
            (!allEventsHaveError(events) && !Number.isFinite(liveDuration)) ||
            (!allEventsHaveError(events) && blockOnMultiLiveEventStarting()))
    );
}
