export const FETCH_CONVERSION_URL = 'StreamConversion/FETCH_CONVERSION_URL';
export const FETCH_STREAM_CONVERSION = 'StreamConversion/FETCH_STREAM_CONVERSION';
export const FETCH_STREAM_CONVERSION_PROGRESS = 'StreamConversion/FETCH_STREAM_CONVERSION_PROGRESS';
export const FETCH_STREAM_CONVERSION_SUCCESS = 'StreamConversion/FETCH_STREAM_CONVERSION_SUCCESS';
export const FETCH_STREAM_CONVERSION_FAILURE = 'StreamConversion/FETCH_STREAM_CONVERSION_FAILURE';
export const STREAM_CONVERSION_RESET = 'StreamConversion/STREAM_CONVERSION_RESET';

export const fetchConversionURL = (provider, id, url) => ({
    type: FETCH_CONVERSION_URL,
    provider,
    id,
    url,
});

export const fetchStreamConversion = (provider, id) => ({
    type: FETCH_STREAM_CONVERSION,
    provider,
    id,
});

export const fetchStreamConversionProgress = (provider, id, progress, url) => ({
    type: FETCH_STREAM_CONVERSION_PROGRESS,
    provider,
    id,
    progress,
    url,
});

export const fetchStreamConversionSuccess = (provider, id, url) => ({
    type: FETCH_STREAM_CONVERSION_SUCCESS,
    provider,
    id,
    url,
});

export const fetchStreamConversionFailure = (provider, id, error) => ({
    type: FETCH_STREAM_CONVERSION_FAILURE,
    provider,
    id,
    error,
});

export const streamConversionReset = (provider, id) => ({
    type: STREAM_CONVERSION_RESET,
    provider,
    id,
});
