import { LabeledContainer, Divider } from '@schibsted-svp/react-ui';
import { DateTimeMergedRHF } from 'components/video/edit/Form/DateTimeMerged';
import QuestionMark from 'components/ui/QuestionMark';
import type { Control } from 'react-hook-form';
import type { FormValues } from '../types';

import css from './LiveVideoTime.module.scss';

type LiveVideoTimeProps = {
    control: Control<FormValues>;
};
export function LiveVideoTime({ control }: LiveVideoTimeProps) {
    return (
        <>
            <Divider />
            <LabeledContainer label="Live video time">
                <div className={css.studioTimes}>
                    <LabeledContainer
                        label={
                            <>
                                Studio start{' '}
                                <QuestionMark content="Date and time for studio transmission, e.g. commentators talk, before the actual event starts." />
                            </>
                        }
                    >
                        <DateTimeMergedRHF
                            name="additional.metadata.studioStartTime"
                            control={control}
                            normalizeToString
                        />
                    </LabeledContainer>

                    <LabeledContainer
                        label={
                            <>
                                Event start{' '}
                                <QuestionMark content="Date and time for the main transmitted event, e.g. the match kick-off time." />
                            </>
                        }
                    >
                        <DateTimeMergedRHF
                            name="additional.metadata.eventStartTime"
                            control={control}
                            normalizeToString
                        />
                    </LabeledContainer>
                </div>
            </LabeledContainer>
        </>
    );
}
