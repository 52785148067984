import { connect } from 'react-redux';
import { getConversionURL } from 'store/video/stream-conversion/selectors';
import { getAssetData } from 'store/assets/selectors';
import { isVod } from 'models/asset';

import DownloadControls from './DownloadControls';

export function mapStateToProps(state, { id, provider }) {
    const { asset } = getAssetData(state, { id, provider });

    const conversionURL = getConversionURL(state, { id, provider });
    const url = conversionURL || (isVod(asset) && asset.streamUrls.mp4);

    return { url };
}

export default connect(mapStateToProps)(DownloadControls);
