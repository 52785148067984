/**
 * Create standard redux action
 *
 * @deprecated
 * @param type
 * @param payload
 * @param error
 */
const createAction = (type, payload, error) => ({ type, payload, error });

export { createAction };
