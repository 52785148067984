import type { Asset } from '@schibsted-svp/svp-api-types';
import type { Control } from 'react-hook-form';
import { getNewsroomConfig } from 'config';
import type { FormValues } from 'components/AssetForm/types';
import { CustomPreview } from './CustomPreview';
import { MultiPreviewList } from './MultiPreviewList';
import { MultiPreviewListRHF } from './MultiPreviewListRHF';
import css from './Preview.module.scss';

type PreviewRHFProps = {
    asset: Asset;
    control: Control<FormValues>;
};

export function PreviewRHF({ asset, control }: PreviewRHFProps) {
    const { artifactsMap } = getNewsroomConfig(asset.provider).previews;

    return (
        <div className={css.container}>
            <CustomPreview asset={asset} />
            {artifactsMap ? (
                <MultiPreviewListRHF assetId={asset.id} control={control} provider={asset.provider} />
            ) : null}
        </div>
    );
}

type PreviewProps = {
    asset: Asset;
};

export function Preview({ asset }: PreviewProps) {
    const { artifactsMap } = getNewsroomConfig(asset.provider).previews;

    return (
        <div className={css.container}>
            <CustomPreview asset={asset} />
            {artifactsMap ? <MultiPreviewList asset={asset} /> : null}
        </div>
    );
}
