import { call, put, race, select, take, takeEvery, delay } from '@redux-saga/core/effects';
import * as ReactGA from 'react-ga';
import config from 'config';
import { get, omit } from 'lodash';

import { waitForAssetFetch } from 'store/assets/sagas';
import { getMetadata } from 'models/asset';
import { getAsset } from 'store/assets/selectors';
import { getNextList } from './selectors';
import { ASSET_SAVE_ERROR, ASSET_SAVE_SUCCESS } from '../../assets/actionTypes';
import { fetchAsset, saveAsset } from '../../assets/actions';
import {
    CHANGE_NEXT_VIDEOS,
    CHANGE_NEXT_VIDEOS_ERROR,
    CHANGE_NEXT_VIDEOS_SUCCESS,
    GENERATE_NEXT_VIDEOS,
    SET_NEXT_VIDEO,
    SET_NEXT_VIDEO_ERROR,
    SET_NEXT_VIDEO_SUCCESS,
    changeNextVideosError,
    changeNextVideosSuccess,
    generateNextVideosSuccess,
    generateNextVideosError,
    setNextVideo,
    setNextVideoError,
    setNextVideoSuccess,
    LOAD_MORE_NEXT_VIDEOS,
} from './actions';

export function* takeAsset(id, provider) {
    const props = { id, provider };
    let asset = yield select(getAsset, props);

    if (asset == null) {
        yield put(fetchAsset(props));
        asset = yield call(waitForAssetFetch, id);
    }

    return asset;
}

export function* generateNextVideosSaga({ id, provider }) {
    const nextState = yield select(getNextList, { id, provider });
    const limit = config.asset.nextVideo.pagination * nextState.page;
    const items = [];

    try {
        let asset = yield takeAsset(id, provider);
        let loopsTo = null;

        while (asset != null && loopsTo == null && items.length < limit) {
            const nextId = get(asset, 'additional.nextAsset.id');

            if (nextId == null) {
                asset = null;
            } else if (items.includes(nextId)) {
                loopsTo = nextId;
            } else {
                asset = yield takeAsset(nextId, provider);
                if (asset != null && !items.includes(asset.id)) {
                    items.push(asset.id);
                }
            }
        }

        const nextId = get(asset, 'additional.nextAsset.id');
        if (nextId && items.includes(nextId)) {
            loopsTo = nextId;
        }

        yield put(generateNextVideosSuccess(id, provider, items, loopsTo));
    } catch (error) {
        yield put(generateNextVideosError(id, provider, error.message));
    }
}

export function* setNextVideoSaga({ id, provider, nextId, isCurrentAsset }) {
    const changes = {
        additional: {
            nextAsset: nextId != null ? { id: nextId } : null,
        },
    };
    if (isCurrentAsset) {
        const asset = yield select(getAsset, { provider, id });
        const metadata = getMetadata(asset);
        changes.additional.metadata = omit(metadata, 'playlistId');
    }

    yield put(saveAsset({ id, provider, changes }));

    yield call(ReactGA.event, {
        category: 'Video',
        action: 'Next video',
        label: 'Asset',
    });

    const saveAssetResultActionTypes = [ASSET_SAVE_ERROR, ASSET_SAVE_SUCCESS];
    const { result } = yield race({
        timeout: delay(5000),
        result: take((action) => saveAssetResultActionTypes.includes(action.type) && action.id === id),
    });

    if (result && !result.error) {
        yield put(setNextVideoSuccess(id, provider, result.asset));
    } else {
        const message = result.error ? String(result.error) : 'Timeout error';
        yield put(setNextVideoError(id, provider, message));
    }
}

export function* changeNextVideosSaga({ id, provider, changes }) {
    const assetIds = Object.keys(changes).map(Number);
    const setNextVideoResultActionTypes = [SET_NEXT_VIDEO_ERROR, SET_NEXT_VIDEO_SUCCESS];
    const results = [];

    for (let i = 0; i < assetIds.length; i += 1) {
        const assetId = assetIds[i];
        const nextId = changes[`${assetId}`];
        const isCurrentAsset = assetId === Number(id);

        yield put(setNextVideo(assetId, provider, nextId, isCurrentAsset));
        results[i] = yield take(
            (action) => setNextVideoResultActionTypes.includes(action.type) && action.id === assetId
        );
    }

    const errorOccurred = results.filter((action) => action && action.type !== SET_NEXT_VIDEO_SUCCESS).length > 0;
    if (errorOccurred) {
        yield put(changeNextVideosError(id, provider, `An error occurred during saving the next asset id`));
    } else {
        yield put(changeNextVideosSuccess(id, provider));
    }
}

export default [
    takeEvery(CHANGE_NEXT_VIDEOS, changeNextVideosSaga),
    takeEvery(SET_NEXT_VIDEO, setNextVideoSaga),
    takeEvery(
        [GENERATE_NEXT_VIDEOS, LOAD_MORE_NEXT_VIDEOS, CHANGE_NEXT_VIDEOS_ERROR, CHANGE_NEXT_VIDEOS_SUCCESS],
        generateNextVideosSaga
    ),
];
