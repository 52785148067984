import { Controller, Control } from 'react-hook-form';
import { Checkbox } from '@schibsted-svp/react-ui';
import type { FormValues } from '../types';

type ShowInNewestCheckboxProps = {
    control: Control<FormValues>;
};
export function ShowInNewestCheckbox({ control }: ShowInNewestCheckboxProps) {
    return (
        <Controller
            name="additional.settings.showInNewest"
            control={control}
            render={({ field: { value, ...rest } }) => (
                <Checkbox
                    {...rest}
                    checked={!value}
                    onChange={(event) => rest.onChange(!event.currentTarget.checked)}
                    label="Hide from list"
                />
            )}
        />
    );
}
