import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGetAccessDefinitionQuery } from 'services/svp-api-client/access-definitions';
import { selectNewsroom } from 'store/newsroom/selectors';
import { selectPlaylist, getPlaylistFetchingState, isPlaylistLoaded } from 'store/playlists/selectors';
import { playlistsFiltersSetController } from 'store/playlists/filters';
import { playlistsFetch } from 'store/playlists/items';
import { PlaylistsScreenGenerator } from 'screens/NewsroomScreen/PlaylistsScreen/PlaylistsGenerator';

export function PlaylistsScreenEdit() {
    const dispatch = useDispatch();
    const newsroom = useSelector(selectNewsroom);
    const { playlistId } = useParams();

    useGetAccessDefinitionQuery({ provider: newsroom });
    const playlist = useSelector((state) => selectPlaylist(state, newsroom, playlistId));

    const playlistLoaded = useSelector((state) => isPlaylistLoaded(state, newsroom, playlistId));

    const isPlaylistFetching = useSelector((state) => getPlaylistFetchingState(state, { newsroom, playlistId }));

    useEffect(() => {
        if (!playlistLoaded && !isPlaylistFetching) {
            dispatch(playlistsFetch({ provider: newsroom, id: playlistId }));
        }
    }, [dispatch, playlistId, newsroom, playlistLoaded, isPlaylistFetching]);

    useEffect(() => {
        dispatch(playlistsFiltersSetController({ provider: newsroom, playlistId }));
    }, [dispatch, playlistId, newsroom]);

    const searchQuery = new URLSearchParams(playlist?.searchQuery || '');

    return (
        <PlaylistsScreenGenerator
            playlist={playlist}
            mode="edit"
            provider={newsroom}
            order={searchQuery.get('sort')}
            pristine
        />
    );
}
