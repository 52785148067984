import { Children, cloneElement } from 'react';
import { generatePath, matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import BEM from 'lib/bem';
import { isTargetExternal } from 'lib/url';
import AssetTab from './AssetTab';
import './AssetTabs.scss';

const bem = new BEM('AssetTabs');

const AssetTabProp = (props, propName, componentName) => {
    let invalidChild = false;

    Children.forEach(props[propName], (child) => {
        if (child.type.name !== AssetTab.name) {
            invalidChild = true;
        }
    });

    return invalidChild && new Error(`\`${componentName}\` requires nodes of type ${AssetTab.name}`);
};

function AssetTabs({ children }) {
    const location = useLocation();
    const match = useRouteMatch();

    const isActiveTab = (target, index) => {
        const path = match.path.replace(':tab?', target);

        // Omit external links
        if (isTargetExternal(target)) {
            return false;
        }

        // Set the first tab as active when the tab param isn't provided
        if (!match.params.tab && index === 0) {
            return true;
        }

        return matchPath(location.pathname, { path, exact: true, strict: false }) != null;
    };

    const generateTargetPath = (tab) => {
        return generatePath(match.path, { ...match.params, tab });
    };

    const tabRenderer = (child, index) => {
        if (!child?.props) {
            return null;
        }

        const { label, target } = child.props;
        const active = isActiveTab(target, index);

        return (
            <li key={label} className={bem.element('tab', { active })}>
                {cloneElement(child, {
                    ...child.props,
                    target: isTargetExternal(target) ? target : generateTargetPath(target),
                })}
            </li>
        );
    };

    const contentRenderer = (child, index) => {
        if (!child?.props) {
            return null;
        }

        const { children: contentChildren, target } = child.props;
        if (!isActiveTab(target, index)) {
            return null;
        }

        return contentChildren;
    };

    if (!children) {
        return null;
    }

    return (
        <section className={bem.block()} data-testid="assetFormTabsContainer">
            <ul className={bem.element('list')}>{Children.map(children, tabRenderer)}</ul>
            {Children.map(children, contentRenderer)}
        </section>
    );
}

AssetTabs.propTypes = {
    children: AssetTabProp,
};

AssetTabs.defaultProps = {
    children: undefined,
};

export default AssetTabs;
