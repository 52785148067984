import PlaybackRaw from 'components/video/edit/Form/Playback/Playback';
import { Controller, type Control } from 'react-hook-form';
import { usePlayer } from 'hooks/usePlayer';
import type { FormValues } from '../types';

type PlaybackProps = {
    className: string;
    disabled: boolean;
    duration: number;
    control: Control<FormValues>;
    isSubmitting: boolean;
};

export function Playback({ className, disabled, duration, control, isSubmitting }: PlaybackProps) {
    const player = usePlayer();
    return (
        <Controller
            name="playback"
            control={control}
            render={({ field }) => (
                <PlaybackRaw
                    className={className}
                    disabled={disabled || isSubmitting}
                    duration={duration}
                    onChange={(value) => {
                        field.onChange({
                            begin: Math.floor(value.begin),
                            end: Math.floor(value.end),
                        });
                    }}
                    value={field.value}
                    player={player}
                />
            )}
        />
    );
}
