import config from 'config';
import Client from './client';

import getRelatedAssets from './related-api-client/assets';

const apiClient = new Client({ baseUrl: config.relatedApi.url });

Object.entries({
    getRelatedAssets,
}).forEach(([k, v]) => {
    if (typeof v === 'function') {
        apiClient[k] = v.bind(apiClient);
    }
});

export default apiClient;
