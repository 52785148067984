export const auth = {
    url: window.location.origin,
    providers: [
        {
            id: 'okta',
            title: 'Schibsted',
            logo: '/static/assets/schibsted-full-name-logo.png',
            width: 200,
        },
        {
            id: 'azure',
            title: 'Polaris Media',
            logo: '/static/assets/polaris-logo.png',
            width: 220,
        },
        {
            id: 'azuretvh',
            title: 'TV Haugaland',
            logo: '/static/assets/tvh.jpg',
            width: 250,
        },
    ],
};
