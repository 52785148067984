import { Select } from '@schibsted-svp/react-ui';
import { useMemo } from 'react';

import { findSingleOption } from 'components/ui/Form/Select';
import type { ExtendedProps as SelectProps } from '@schibsted-svp/react-ui/lib/es/Select';
import style from './LogoOverlaySelector.module.scss';
import { getLogoOverlayOptions, type Option } from './helpers';
import { LogoPreview } from './LogoPreview';

type LogoOverlaySelectProps = {
    newsroom: string;
} & SelectProps<Option>;

export function LogoOverlaySelect({ newsroom, ...props }: LogoOverlaySelectProps) {
    const options = useMemo(() => getLogoOverlayOptions(newsroom), [newsroom]);
    const logoUrl = props?.overlay;

    return (
        <div className={style.wrapper}>
            <LogoPreview className={style.logo} logoUrl={logoUrl} />
            <Select
                value={findSingleOption(options)(logoUrl)}
                onChange={props?.onChange}
                options={options}
                {...props}
            />
        </div>
    );
}
