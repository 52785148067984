import { combineReducers } from '@reduxjs/toolkit';
import filtersReducer from 'store/playlists/filters';
import filtersSagas from 'store/playlists/filters/sagas';
import listReducer from 'store/playlists/list';
import listSagas from 'store/playlists/list/sagas';
import staticItemsSearchReducer from 'store/playlists/staticItems';
import staticItemsSagas from 'store/playlists/staticItems/sagas';
import itemsReducer from 'store/playlists/items';
import itemsSagas from 'store/playlists/items/sagas';

export default {
    reducers: combineReducers({
        list: listReducer,
        items: itemsReducer,
        staticItemsSearch: staticItemsSearchReducer,
        filters: filtersReducer,
    }),
    sagas: [...filtersSagas, ...listSagas, ...staticItemsSagas, ...itemsSagas],
};
