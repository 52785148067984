import SELECT_NEWSROOM from './actionTypes';

const selectNewsroom = (newsroom) => ({
    type: SELECT_NEWSROOM,
    newsroom,
});

/**
 * @param {String} newsroom
 */
export default selectNewsroom;
