import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { HTML5Player } from 'components/core/Player/HTML5Player';
import { Dropzone } from 'components/video/Dropzone/Dropzone';

import { reportMessageToSentry } from 'lib/error';
import { uploadCategoryPreview } from 'store/uploads/previews/slice';
import { Notification } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';
import config from 'config';
import { isVideoType } from 'lib/file';
import z, { ZodError } from 'zod';
import css from './PreviewCategory.module.scss';
import { useIsPreviewLoading } from './hooks';

const { allowedVideoTypes } = config;

const filesValidator = z
    .instanceof(File)
    .refine((file) => isVideoType(file), 'Must video file type')
    .array()
    .length(1, 'Must contain one video file exactly');

const cln = classnames.bind(css);

interface PreviewCategoryType {
    promoUrl: string;
    provider: string;
    categoryId: number;
}

export function PreviewCategory({ promoUrl, provider, categoryId }: PreviewCategoryType) {
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(false);

    const isPreviewLoading = useIsPreviewLoading({ provider, categoryId });

    const handleUpload = async (files: unknown) => {
        try {
            const [file] = filesValidator.parse(files);
            dispatch(uploadCategoryPreview({ provider, categoryId, file }));
        } catch (error) {
            if (error instanceof ZodError) {
                const formatted = error.format();
                // eslint-disable-next-line no-underscore-dangle
                const message = formatted._errors?.[0] ?? formatted[0]?._errors;
                Notification.notify.error(`An error occurred during upload: ${message}.`);
                return;
            }

            reportMessageToSentry({
                message: 'Category preview upload failed',
                extras: {
                    error,
                },
            });

            Notification.notify.error('An unexpected error occurred during file upload.');
        }
    };

    function handleMouseEnter() {
        setHovered(true);
    }

    function handleMouseLeave() {
        setHovered(false);
    }

    return (
        <div
            className={cln('container', { hovered: promoUrl && hovered })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Dropzone
                accept={allowedVideoTypes}
                onUpload={handleUpload}
                className={cln('dropzone')}
                loading={isPreviewLoading}
            >
                {() => {
                    if (!promoUrl) {
                        return null;
                    }
                    return <HTML5Player src={promoUrl} className={css.player} loading={isPreviewLoading} width={250} />;
                }}
            </Dropzone>
        </div>
    );
}
