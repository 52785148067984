import classnames from 'classnames/bind';
import type { IngestState } from './VideoSummary';
import css from './ProgressBar.module.scss';

const cln = classnames.bind(css);

type ProgressBarProps = {
    state: IngestState['state'];
    progress: IngestState['progress'];
};

export function ProgressBar({ state, progress }: ProgressBarProps) {
    return (
        <div
            className={cln('progressBar', { [`progressBar--${state}`]: state !== 'done' })}
            style={{ width: `${progress}%` }}
        />
    );
}
