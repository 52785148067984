import { Button, Dialog } from '@schibsted-svp/react-ui';
import { type Control, type UseFormSetValue, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { useAssetSchedulePublishTime } from 'store/schedules/hooks';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { isAudio } from 'models/asset';
import { ScheduleDialogForm } from './ScheduleDialogForm';
import type { FormValues } from '../types';
import css from './ScheduleDialog.module.scss';

export const getSubmitButtonLabel = ({
    isAudioAsset,
    schedulePublishTimeInit,
    schedulePublishTime,
    distributeAfterInit,
    distributeAfter,
}: {
    isAudioAsset: boolean;
    schedulePublishTimeInit: number;
    schedulePublishTime: number;
    distributeAfterInit: string;
    distributeAfter: string;
}) => {
    if (isAudioAsset) {
        const isPublishScheduleRemoved = Boolean(
            schedulePublishTimeInit && !schedulePublishTime && (distributeAfterInit || !distributeAfter)
        );
        const isDistributeAfterRemoved = Boolean(
            distributeAfterInit && !distributeAfter && (schedulePublishTimeInit || !schedulePublishTime)
        );
        return isPublishScheduleRemoved || isDistributeAfterRemoved ? 'Remove schedule' : 'Schedule';
    }
    return schedulePublishTime ? 'Schedule' : 'Publish';
};

type ScheduleDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: ({ changeStatusToActive }: { changeStatusToActive: boolean }) => void;
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    asset: Asset;
};

export function ScheduleDialog({ isOpen, onClose, onSubmit, control, setValue, asset }: ScheduleDialogProps) {
    const [schedulePublishTime, flightTimes, distributeAfter] = useWatch({
        control,
        name: ['schedulePublishTime', 'flightTimes', 'additional.metadata.distributeAfter'],
    });

    const schedulePublishTimeStore = useAssetSchedulePublishTime({ id: asset.id, provider: asset.provider });
    const initialValues = useMemo(
        () => ({
            schedulePublishTimeStore,
            flightTimes,
            distributeAfter,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isOpen, schedulePublishTimeStore]
    );

    const submitButtonLabel = getSubmitButtonLabel({
        isAudioAsset: isAudio(asset),
        schedulePublishTimeInit: initialValues.schedulePublishTimeStore,
        schedulePublishTime,
        distributeAfterInit: initialValues.distributeAfter,
        distributeAfter,
    });

    const onCloseHandler = () => {
        setValue('schedulePublishTime', initialValues.schedulePublishTimeStore);
        setValue('flightTimes', initialValues.flightTimes);
        setValue('additional.metadata.distributeAfter', initialValues.distributeAfter);
        onClose();
    };
    return (
        <Dialog isOpen={isOpen} heading="Schedule publication" className={css.container} onClose={onCloseHandler}>
            <ScheduleDialogForm control={control} isAudio={isAudio(asset)} setValue={setValue} />
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={onCloseHandler}>
                    Cancel
                </Button>
                <Button
                    type="button"
                    onClick={() => onSubmit({ changeStatusToActive: submitButtonLabel === 'Publish' })}
                >
                    {submitButtonLabel}
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}
