import { createFilterString, defaultFilters } from 'models/filter';
import { get } from 'lodash';
import config from 'config';

/**
 * @typedef {Object} Asset
 * @see http://svp.vg.no/svp/api/v1/docs/#!/assets/fetch
 * @property {Number} id
 * @property {String} title
 * @property {String} titleFront
 * @property {String} description,
 * @property {String} descriptionFront
 * @property {Number} displays
 * @property {Boolean} showDisplays
 * @property {Number} created
 * @property {Number} updated
 * @property {Number} published
 * @property {Object} flightTimes
 * @property {Number} duration
 * @property {String} assetType
 * @property {String} streamType
 * @property {String} status
 * @property {Object} playback
 * @property {Object} series
 * @property {String} articleUrl
 * @property {Object} category
 * @property {Object} images
 * @property {Object} streamUrls
 * @property {Object} streamConfiguration
 * @property {Object} additional
 * @property {String} provider
 */

export function prepareQueryString({ query, searchQueryProviders, before, after, filter, order, limit }) {
    const additional = config.asset.additional.join('|');
    const qs = {
        additional,
        limit: String(config.assets.pagination),
    };

    if (order !== 'relevance') {
        qs.sort = order;
    }

    if (filter) {
        qs.filter = filter;
    }

    if (after) {
        qs.after = after;
        qs.sort = 'published';
    }

    if (before) {
        qs.before = before;
        qs.sort = 'published';
    }

    if (query) {
        qs.query = query;
    }

    if (limit) {
        qs.limit = limit;
    }

    if (searchQueryProviders) {
        qs.searchQueryProviders = searchQueryProviders;
    }
    return qs;
}
/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {String} params.id
 * @return {Promise<Asset>}
 */
export async function fetchAsset({ provider, id }) {
    const query = {
        additional: config.asset.additional.join('|'),
    };
    return this.get(`v1/svp/{PROVIDER}/assets/${id}`, { provider, query });
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Object} params.query
 * @param {Number} params.before
 * @param {Number} params.after
 * @param {Object} params.filters
 * @return {Promise<Object>}
 */
export async function fetchFilteredAssets({
    provider,
    query,
    searchQueryProviders,
    before,
    after,
    filters = {},
    order = 'created',
    limit,
}) {
    const filter = createFilterString(filters);
    const qs = prepareQueryString({ query, searchQueryProviders, before, after, filter, order, limit });

    const response = await this.get(`v1/svp/{PROVIDER}/search`, {
        provider: searchQueryProviders || provider,
        query: qs,
    });

    return {
        assets: get(response, '_embedded.assets', []),
        nextUrl: get(response, '_links.next.href'),
    };
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {String} params.id
 * @param {Object} params.changes
 * @return {Promise}
 */
export function saveAsset({ provider, id, changes }) {
    if (id > 0) {
        return this.patch(`v1/svp/{PROVIDER}/assets/${id}`, { provider, json: changes });
    }

    return this.post(`v1/svp/{PROVIDER}/assets`, { provider, json: changes });
}

/**
 * @param {String} nextUrl
 * @returns {Promise<Object>}
 */
export async function fetchMoreAssets(nextUrl) {
    const response = await this.get(nextUrl);
    return {
        assets: get(response, '_embedded.assets', []),
        page: get(response, 'page', 1),
        nextUrl: get(response, '_links.next.href'),
    };
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.originAssetId
 * @param {String} [params.order=created]
 * @return {Promise<Object>}
 */
export async function fetchClips({ provider, originAssetId, order = 'created' }) {
    const query = {
        showAll: true,
        additional: config.asset.additional.join('|'),
        filter: createFilterString({ ...defaultFilters, originAssetId }),
        limit: config.assets.pagination,
        order,
    };
    const response = await this.get(`v1/svp/{PROVIDER}/assets`, { provider, query });

    return {
        assets: get(response, '_embedded.assets', []),
        nextUrl: get(response, '_links.next.href'),
    };
}
