import classnames from 'classnames/bind';
import { Button, Connector, Input } from '@schibsted-svp/react-ui';
import React, { FocusEventHandler, MouseEventHandler, useCallback } from 'react';
import { MdAdd, MdKeyboardTab, MdStart } from 'react-icons/md';
import { Control, useController, UseFieldArrayInsert, UseFieldArrayRemove } from 'react-hook-form';

import { SECOND } from 'lib/time';
import type { SubtitlesFormValues } from './types';
import { createCue } from './helpers';
import { SubtitleCueStats } from './SubtitleCueStats';
import { SubtitleCueTimeInput } from './SubtitleCueTimeInput';
import css from './SubtitleCueField.module.scss';

const DEFAULT_CUE_LENGTH_MS = 500;

const cln = classnames.bind(css);

type SubtitleCueFieldProps = {
    control: Control<SubtitlesFormValues>;
    index: number;
    highlighted: boolean;
    insert: UseFieldArrayInsert<SubtitlesFormValues, 'cues'>;
    remove: UseFieldArrayRemove;
    seekPlayer: (time: number) => void;
};

export function SubtitleCueField({ control, index, highlighted, insert, remove, seekPlayer }: SubtitleCueFieldProps) {
    const { field } = useController({ control, name: `cues.${index}` });
    const { register } = control;

    const handleInsertAboveClick = useCallback<MouseEventHandler<HTMLElement>>(() => {
        const { startTime } = field.value;
        const endTime = startTime ?? 0.1;
        const value = createCue({
            startTime: Math.max(0, (endTime * SECOND - DEFAULT_CUE_LENGTH_MS) / SECOND),
            endTime,
        });

        insert(index, value);
        seekPlayer(startTime);
    }, [field.value, index, insert, seekPlayer]);

    const handleInsertBelowClick = useCallback<MouseEventHandler<HTMLElement>>(() => {
        const { startTime } = field.value;
        const value = createCue({
            startTime,
            endTime: (startTime * SECOND + DEFAULT_CUE_LENGTH_MS) / SECOND,
        });

        insert(index + 1, value);
        seekPlayer(value.startTime);
    }, [field.value, index, insert, seekPlayer]);

    const handleRemoveClick = useCallback<MouseEventHandler<HTMLElement>>(() => {
        remove(index);
    }, [index, remove]);

    const handleFieldFocus = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
        const { startTime } = field.value;
        seekPlayer(startTime);
    }, [field.value, seekPlayer]);

    return (
        <div className={cln(css.field, { [css['field-highlighted']]: highlighted })}>
            <div className={css.startTime}>
                <SubtitleCueTimeInput
                    className={css.startTimeInput}
                    control={control}
                    icon={<MdStart />}
                    name={`cues.${index}.startTime`}
                    onFocus={handleFieldFocus}
                />
            </div>

            <div className={css.endTime}>
                <SubtitleCueTimeInput
                    className={css.endTimeInput}
                    control={control}
                    icon={<MdKeyboardTab />}
                    name={`cues.${index}.endTime`}
                    onFocus={handleFieldFocus}
                />
            </div>

            <div className={css.text}>
                <Input
                    type="textarea"
                    size="compact"
                    {...register(`cues.${index}.text`)}
                    className={css.textInput}
                    onFocus={handleFieldFocus}
                />
            </div>

            <SubtitleCueStats className={css.stats} control={control} index={index} />

            <Connector className={css.buttons}>
                <Button
                    type="button"
                    size="compact"
                    className={css.insertButton}
                    iconOnly
                    onClick={handleInsertAboveClick}
                >
                    <MdAdd size={20} />
                </Button>

                <Button
                    type="button"
                    size="compact"
                    className={cln(css.insertButton, { last: true })}
                    iconOnly
                    onClick={handleInsertBelowClick}
                >
                    <MdAdd size={20} />
                </Button>

                <Button
                    type="button"
                    size="compact"
                    variant="standard"
                    className={css.deleteButton}
                    onClick={handleRemoveClick}
                >
                    Delete
                </Button>
            </Connector>
        </div>
    );
}
