import { MouseEvent as ReactMouseEvent, useRef } from 'react';

import type { Maybe, Optional } from 'types';
import type { CutRange } from 'store/uploads/previews/slice';
import type {
    CutRangeSelectorMouseDown,
    CutRangeSelectionEditMode,
    CutRangeSelectorMouseMove,
    CutRangeSelectorState,
    AnimatedElements,
} from './types';
import {
    isCutRangeSelectorStateOnMouseDownAndMove,
    isCutRangeSelectorStateOnMouseDownAndMoveWithSelection,
    isCutRangeSelectorStateOnMouseMove,
    isCutRangeSelectorStateReady,
} from './types';
import {
    getAnimatedElements,
    getAnimatedElementsByCreatingSelection,
    getAnimatedElementsByMovingBeginMarker,
    getAnimatedElementsByMovingEndMarker,
    getAnimatedElementsByMovingSelection,
    getSelectionCenteredScrollLeft,
} from './helpers';

export function useCutRangeSelectorState() {
    const containerRef = useRef<Maybe<HTMLDivElement>>(null);
    const markerLeftRef = useRef<Maybe<HTMLDivElement>>(null);
    const markerRightRef = useRef<Maybe<HTMLDivElement>>(null);
    const sliderRef = useRef<Maybe<HTMLDivElement>>(null);
    const selectorRef = useRef<Maybe<HTMLDivElement>>(null);

    const scrollLeftRef = useRef(0);
    const mouseDownRef = useRef<Optional<CutRangeSelectorMouseDown>>(undefined);
    const mouseMoveRef = useRef<Optional<CutRangeSelectorMouseMove>>(undefined);

    const elementRef = (type: 'container' | 'markerLeft' | 'markerRight' | 'selector' | 'slider') => {
        return (element: HTMLDivElement) => {
            switch (type) {
                case 'container':
                    containerRef.current = element;
                    break;
                case 'markerLeft':
                    markerLeftRef.current = element;
                    break;
                case 'markerRight':
                    markerRightRef.current = element;
                    break;
                case 'selector':
                    selectorRef.current = element;
                    break;
                case 'slider':
                    sliderRef.current = element;
                    break;
                default:
            }
        };
    };

    const getState = (): CutRangeSelectorState => ({
        container: containerRef.current,
        selector: selectorRef.current,
        slider: sliderRef.current,
        mouseDown: mouseDownRef.current,
        mouseMove: mouseMoveRef.current,
    });

    const isResizingSelectionBeginEdge = (target: HTMLDivElement, cutRange?: CutRange): cutRange is CutRange => {
        return cutRange !== undefined && target === markerLeftRef.current;
    };

    const isResizingSelectionEndEdge = (target: HTMLDivElement, cutRange?: CutRange): cutRange is CutRange => {
        return cutRange !== undefined && target === markerRightRef.current;
    };

    const isResizingSelection = (target: HTMLDivElement, cutRange?: CutRange): cutRange is CutRange => {
        return isResizingSelectionBeginEdge(target, cutRange) || isResizingSelectionEndEdge(target, cutRange);
    };

    const isCreatingSelection = (target: HTMLDivElement) => {
        return target === sliderRef.current;
    };

    const isMovingSelection = (target: HTMLDivElement, cutRange?: CutRange): cutRange is CutRange => {
        return cutRange !== undefined && target === selectorRef.current;
    };

    const setMouseDown = (
        clientX: number,
        currentTarget: HTMLDivElement,
        target: HTMLDivElement,
        mode: CutRangeSelectionEditMode,
        cutRange?: CutRange
    ) => {
        if (!containerRef.current || !markerLeftRef.current || !markerRightRef.current || !sliderRef.current) {
            return undefined;
        }

        mouseDownRef.current = {
            cutRange,
            minRatioValue: 1 / sliderRef.current.clientWidth,
            mode,
            positionX: clientX - currentTarget.offsetLeft,
            scrollLeft: containerRef.current.scrollLeft,
            target,
        };

        return mode;
    };

    const mouseDown = (
        { clientX, currentTarget, target }: ReactMouseEvent<HTMLDivElement>,
        cutRange?: CutRange
    ): CutRangeSelectionEditMode | undefined => {
        if (!(target instanceof HTMLDivElement)) {
            return undefined;
        }

        if (isResizingSelection(target, cutRange)) {
            return setMouseDown(clientX, currentTarget, target, 'resize', cutRange);
        }

        if (!cutRange && isCreatingSelection(target)) {
            return setMouseDown(clientX, currentTarget, target, 'create');
        }

        if (isMovingSelection(target, cutRange)) {
            return setMouseDown(clientX, currentTarget, target, 'move', cutRange);
        }

        return undefined;
    };

    const mouseMove = ({ clientX, currentTarget }: ReactMouseEvent<HTMLDivElement>) => {
        if (!markerLeftRef.current || !markerRightRef.current) {
            return;
        }

        mouseMoveRef.current = {
            positionX: clientX - currentTarget.offsetLeft,
            markerLeftTranslateX: -(markerLeftRef.current.clientWidth / 2),
            markerRightTranslateX: markerRightRef.current.clientWidth / 2,
        };
    };

    const isMarkerHovered = (target: EventTarget) =>
        target === markerLeftRef.current || target === markerRightRef.current;

    const mouseUp = (event: MouseEvent, duration: number) => {
        const state = getState();
        let currentTime;

        if (
            event.target instanceof HTMLDivElement &&
            event.target === sliderRef.current &&
            isCutRangeSelectorStateOnMouseDownAndMove(state) &&
            state.mouseDown.positionX === state.mouseMove.positionX
        ) {
            const { nextTimeMarker } = getAnimatedElements(state, duration);
            currentTime = nextTimeMarker.value;
        }

        mouseDownRef.current = undefined;
        return currentTime;
    };

    const scrollContainerBy = (scrollLeftDelta: number) => {
        const state = getState();
        if (isCutRangeSelectorStateReady(state)) {
            state.container.scrollLeft += scrollLeftDelta;
        }
    };

    const storeScrollLeftPosition = (currentScrollLeft: number) => {
        const state = getState();
        if (isCutRangeSelectorStateReady(state)) {
            scrollLeftRef.current = (currentScrollLeft + state.container.clientWidth / 2) / state.slider.clientWidth;
        }
    };

    const centerSelection = (cutRange?: CutRange) => {
        const state = getState();
        if (!isCutRangeSelectorStateReady(state)) {
            return;
        }

        if (cutRange) {
            state.container.scrollLeft = getSelectionCenteredScrollLeft(state);
            return;
        }
        state.container.scrollLeft = Math.round(
            state.slider.clientWidth * scrollLeftRef.current - state.container.clientWidth / 2
        );
    };

    const getComputedAnimatedElements = (duration: number, cutRange?: CutRange): AnimatedElements | undefined => {
        const state = getState();
        if (!isCutRangeSelectorStateOnMouseMove(state)) {
            return undefined;
        }

        if (!isCutRangeSelectorStateOnMouseDownAndMove(state)) {
            return getAnimatedElements(state, duration, cutRange);
        }

        if (isCreatingSelection(state.mouseDown.target)) {
            return getAnimatedElementsByCreatingSelection(state, duration);
        }

        if (!isCutRangeSelectorStateOnMouseDownAndMoveWithSelection(state)) {
            return undefined;
        }

        if (isResizingSelectionBeginEdge(state.mouseDown.target, state.mouseDown.cutRange)) {
            return getAnimatedElementsByMovingBeginMarker(state, duration);
        }
        if (isResizingSelectionEndEdge(state.mouseDown.target, state.mouseDown.cutRange)) {
            return getAnimatedElementsByMovingEndMarker(state, duration);
        }
        if (isMovingSelection(state.mouseDown.target, state.mouseDown.cutRange)) {
            return getAnimatedElementsByMovingSelection(state, duration);
        }

        return undefined;
    };

    return {
        centerSelection,
        elementRef,
        getComputedAnimatedElements,
        getState,
        isMarkerHovered,
        mouseDown,
        mouseMove,
        mouseUp,
        scrollContainerBy,
        storeScrollLeftPosition,
    };
}

export function useRequestAnimation() {
    const animationRef = useRef(false);

    return (callback: FrameRequestCallback) => {
        if (animationRef.current) {
            return;
        }

        animationRef.current = true;
        window.requestAnimationFrame((time) => {
            animationRef.current = false;
            callback(time);
        });
    };
}
