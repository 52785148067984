import { put, takeEvery } from '@redux-saga/core/effects';

import { matchMqttTopic } from 'store/mqtt/listeners/utils';
import { addLiveNotification, changeLiveEventState, changeMediaLiveState } from 'store/live/actions';

function* liveMqttSaga({ type, data }) {
    const [, , encoderType, provider, assetId, action] = type.split('/');
    if (action === 'notification') {
        yield put(addLiveNotification(provider, Number(assetId), data));
    }
    if (encoderType === 'medialive' && action === 'state') {
        yield put(changeMediaLiveState(provider, Number(assetId), data));
    }
    if (encoderType === 'elemental' && action === 'state') {
        yield put(changeLiveEventState(provider, Number(assetId), data));
    }
}

export default [takeEvery((action) => matchMqttTopic(action, 'live', 1), liveMqttSaga)];
