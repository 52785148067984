import { useEffect, useState } from 'react';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { useLazyGetAssetHistoryQuery } from 'services/admin-bff-sdk/generated';
import { prepareHistoryData, type HistoryData } from './helpers';

export function useGetAssetHistoryData(asset: Asset, isExpanded: boolean) {
    const [getAssetHistory, { data, isLoading, isError }] = useLazyGetAssetHistoryQuery();

    useEffect(() => {
        if (isExpanded) {
            getAssetHistory({ newsroom: asset.provider, assetId: asset.id });
        }
    }, [isExpanded, asset.provider, asset.id, asset.updated, getAssetHistory]);

    const historyData = prepareHistoryData(data?.assetHistory);

    return { historyData, isLoading, isError };
}

export function useHistoryPreview() {
    const [previewData, setPreviewData] = useState<HistoryData>();

    return {
        previewData,
        isPreviewOpen: Boolean(previewData),
        openPreview: (data: HistoryData) => setPreviewData(data),
        closePreview: () => setPreviewData(undefined),
    };
}
