import { LabeledContainer, CollapsibleContent, Divider, DateTimeInput, Checkbox } from '@schibsted-svp/react-ui';

import { Controller, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CategoryData } from './types';
import { getSeriesFlag } from '../../../store/categories/selectors';

import { getNewsroomConfig } from '../../../config';
import { AgeLimitSelect } from '../../core/hookForm/AgeLimitSelect';
import { formatDate, normalizeDate } from '../../core/FiltersDialog/FiltersDialog';
import css from './SeriesProperties.module.scss';
import { CastMembersSelect } from '../../core/hookForm/CastMembersSelect';
import { DirectorSelect } from '../../core/hookForm/DirectorSelect';

type SeriesPropertiesProps = {
    formApi: UseFormReturn<CategoryData>;
    provider: string;
    categoryId: number;
};

export function SeriesProperties({ formApi, provider, categoryId }: SeriesPropertiesProps) {
    const isSeries = useSelector((state) => getSeriesFlag(state, { provider, id: categoryId }));
    const { features } = getNewsroomConfig(provider);
    const { control, register } = formApi;

    if (!(isSeries && features.seriesProperties?.enabled)) {
        return null;
    }

    return (
        <>
            <CollapsibleContent label="Series Properties">
                <section className={css.content}>
                    <LabeledContainer strict label="Cast members">
                        <CastMembersSelect
                            name="additional.metadata.series_cast"
                            control={control}
                            provider={provider}
                        />
                    </LabeledContainer>
                    <LabeledContainer strict label="Director">
                        <DirectorSelect
                            name="additional.metadata.series_director.value"
                            control={control}
                            provider={provider}
                        />
                    </LabeledContainer>
                    <div className={css.doubleCol}>
                        <LabeledContainer strict label="Age limit">
                            <AgeLimitSelect name="ageLimit" control={control} />
                        </LabeledContainer>
                        <LabeledContainer strict label="Release year">
                            <Controller
                                name="additional.metadata.series_releasedAt.value"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DateTimeInput
                                        {...field}
                                        value={formatDate(Number(field?.value))}
                                        onChange={(value) => field.onChange(normalizeDate(value))}
                                        error={error?.message}
                                        size="small"
                                        isClearable
                                        datePickerProps={{
                                            dateFormat: 'yyyy',
                                            showYearPicker: true,
                                        }}
                                    />
                                )}
                            />
                        </LabeledContainer>
                    </div>
                    <LabeledContainer strict label="Properties">
                        <div>
                            <Checkbox
                                {...register('additional.metadata.series_isSponsored.value')}
                                containerClassName={css.checkbox}
                                label="Sponsored TV series"
                            />
                        </div>
                    </LabeledContainer>
                </section>
            </CollapsibleContent>
            <Divider margin="0" />
        </>
    );
}
