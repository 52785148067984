import { SET_MAIN_NOTIFICATION_VISIBILITY } from './actions';

export default function notificationReducer(state = {}, action) {
    switch (action.type) {
        case SET_MAIN_NOTIFICATION_VISIBILITY: {
            return {
                main: {
                    visible: action.visible,
                },
            };
        }

        default:
            return state;
    }
}
