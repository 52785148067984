import type { CategoryData } from 'components/categories/Form/types';
import type { Control } from 'react-hook-form';
import { LabeledContainer, Select } from '@schibsted-svp/react-ui';
import { findMultipleOptions, getValuesFromOptionsArray } from 'components/ui/Form/Select';
import { useController } from 'react-hook-form';
import type { Option } from 'components/ui/Form/Select/SelectUtils';
import css from './PodcastCategoriesSelect.module.scss';

export function PodcastSubCategory({
    control,
    mainCategory,
    subcategories,
}: {
    control: Control<CategoryData>;
    mainCategory: string;
    subcategories: Option[];
}) {
    const { field } = useController({
        name: `additional.metadata.podcast_category.${mainCategory}`,
        control,
    });

    return (
        <LabeledContainer label={mainCategory} className={css.subcategories}>
            <Select
                {...field}
                value={findMultipleOptions(subcategories)(field.value)}
                onChange={(values) => field.onChange(getValuesFromOptionsArray(values))}
                options={subcategories}
                isClearable={false}
                size="compact"
                isMulti
                className={css.categories}
            />
        </LabeledContainer>
    );
}
