import { useDispatch } from 'react-redux';
import { Controller, useWatch } from 'react-hook-form';
import type { UseFormReturn, FieldErrors } from 'react-hook-form';
import { CollapsibleContent, Select, Input, Label, Button } from '@schibsted-svp/react-ui';
import type { Optional } from 'types';
import { combine } from 'lib/function';
import { seriesAdd } from 'store/series/actions';
import { findSingleOption, getValueFromOption } from 'components/ui/Form/Select';
import type { FormValues } from '../../types';
import { useFetchSeriesData, useUpdateFormData, useFillNext } from './hooks';
import css from './Series.module.scss';

type SeriesProps = {
    provider: string;
    assetCategoryId: Optional<number>;
    formApi: UseFormReturn<FormValues>;
    formErrors: FieldErrors<FormValues>;
};

export function Series({ provider, assetCategoryId, formApi, formErrors }: SeriesProps) {
    const { control, register, setValue, trigger } = formApi;

    const [categoryId, seasonNumber, episodeNumber] = useWatch({
        name: ['category.id', 'series.seasonNumber', 'series.episodeNumber'],
        control,
    });

    const dispatch = useDispatch();

    const { isSeries, series, seriesIsLoading } = useFetchSeriesData({ provider, categoryId });
    const { fillNext, isNextLoading } = useFillNext({ provider, categoryId, seasonNumber, formApi });

    useUpdateFormData({ categoryId, assetCategoryId, formApi });

    if (!isSeries) {
        return null;
    }

    const onCreateOption = (seasonTitle: string) => {
        const lastSeason = series?.at(-1)?.value ?? 0;
        const newSeasonNumber = lastSeason + 1;
        dispatch(seriesAdd(provider, categoryId, newSeasonNumber, seasonTitle));
        setValue('series.seasonNumber', newSeasonNumber, { shouldDirty: true, shouldTouch: true });
    };

    const isValidNewOption = (inputValue: string, _, selectOptions: { label: string }[]) => {
        return inputValue?.length > 2 && selectOptions.every(({ label }) => label !== inputValue);
    };

    const triggerValidation = () => {
        trigger(['series.seasonNumber', 'series.episodeNumber']);
    };

    return (
        <CollapsibleContent label="Season and episode" className={css.container}>
            <div className={css.content}>
                <Label className={css.seasonLabel}>Season number</Label>
                <div className={css.seasonField}>
                    <Controller
                        name="series.seasonNumber"
                        control={control}
                        render={({ field, fieldState }) => (
                            <Select
                                {...field}
                                value={findSingleOption(series)(field.value)}
                                onChange={(value) => field.onChange(getValueFromOption(value))}
                                onBlur={combine(field.onBlur, triggerValidation)}
                                options={series}
                                isClearable
                                isLoading={seriesIsLoading}
                                placeholder="Select season..."
                                splitButton={false}
                                size="small"
                                error={fieldState.error?.message}
                                onCreateOption={onCreateOption}
                                isValidNewOption={isValidNewOption}
                                createOptionPosition="first"
                                creatable
                            />
                        )}
                    />
                </div>
                <Label className={css.episodeLabel}>Episode number</Label>
                <div className={css.episodeField}>
                    <Input
                        {...register('series.episodeNumber', {
                            setValueAs: (value: string) => (value ? parseInt(value, 10) : null),
                            onBlur: triggerValidation,
                        })}
                        size="small"
                        error={formErrors.series?.episodeNumber?.message}
                    />
                </div>
                <Button
                    type="button"
                    size="small"
                    loading={isNextLoading}
                    disabled={!series || Boolean(seasonNumber && episodeNumber)}
                    className={css.fillButton}
                    onClick={fillNext}
                >
                    Fill next
                </Button>
            </div>
        </CollapsibleContent>
    );
}
