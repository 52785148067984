import type { Asset } from '@schibsted-svp/svp-api-types';
import { useCallback, useEffect } from 'react';

import { usePrevious } from 'hooks/usePrevious';
import { PurePlayer } from 'components/core/Player';

import css from './PreviewPlayer.module.scss';
import { useVideoPreviewEditorContext } from './VideoPreviewEditorContext';

interface PreviewPlayerProps {
    asset: Asset;
    startTime?: number;
    endTime?: number;
    loop?: boolean;
}

export function PreviewPlayer({ asset, loop = false, startTime, endTime }: PreviewPlayerProps) {
    const { previewPlayer, onPreviewPlayerReady } = useVideoPreviewEditorContext();
    const prevStartTime = usePrevious(startTime);
    const prevEndTime = usePrevious(endTime);
    const prevLoop = usePrevious(loop);

    const handleTimeUpdate = useCallback(
        (time: number) => {
            if (
                previewPlayer &&
                startTime != null &&
                endTime != null &&
                (time >= endTime || time < startTime) &&
                previewPlayer.videoElement
            ) {
                previewPlayer.videoElement.currentTime = startTime;
            }
        },
        [endTime, previewPlayer, startTime]
    );

    useEffect(() => {
        if (previewPlayer && loop) {
            previewPlayer.svpPlayer.on('time', handleTimeUpdate);
        }
        return () => {
            if (previewPlayer && loop) {
                previewPlayer.svpPlayer.off('time', handleTimeUpdate);
            }
        };
    }, [handleTimeUpdate, previewPlayer, loop]);

    useEffect(() => {
        if (
            !previewPlayer ||
            !previewPlayer.videoElement ||
            endTime === undefined ||
            startTime === undefined ||
            !loop
        ) {
            return;
        }
        if (prevEndTime !== endTime) {
            previewPlayer.videoElement.currentTime = endTime;
            return;
        }
        if (prevStartTime !== startTime) {
            previewPlayer.videoElement.currentTime = startTime;
        }
    }, [endTime, loop, prevEndTime, prevStartTime, previewPlayer, startTime]);

    useEffect(() => {
        if (!previewPlayer || loop === prevLoop) {
            return;
        }

        if (!loop && !previewPlayer.paused) {
            previewPlayer.pause();
            return;
        }
        if (loop && previewPlayer.paused && startTime && endTime) {
            previewPlayer.play();
        }
    }, [endTime, loop, prevLoop, previewPlayer, startTime]);

    return (
        <div className={css.container}>
            <PurePlayer asset={asset} mute onReady={onPreviewPlayerReady} options={{ repeat: true }} />
        </div>
    );
}
