import { SendPushNotification } from './graphql/push-notifications.graphql';

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {Number} params.assetId
 * @param {String} params.notificationBody
 * @returns {Promise<Object>}
 */
export async function sendPushNotification({ provider, assetId, notificationBody }) {
    const response = await this.query(SendPushNotification, {
        provider,
        assetId,
        notificationBody,
    });
    return response?.sendPushNotification;
}
