import type { Control, UseFieldArrayReturn } from 'react-hook-form';
import { CollapsibleContent } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { FormValues } from '../../types';
import { useDynamicFields } from '../../hooks/useDynamicFields';
import { DynamicFieldContainer } from './DynamicFieldContainer';
import css from './DynamicForm.module.scss';

type DynamicFormProps = {
    asset: Asset;
    control: Control<FormValues>;
    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;
};

export function DynamicForm({ asset, control, simpleMetadataControls }: DynamicFormProps) {
    const { dynamicFieldsGrouped } = useDynamicFields({ asset, control });

    return (
        <>
            {dynamicFieldsGrouped.map(({ group, fields }) => (
                <CollapsibleContent label={group} key={group} className={css.groupContainer}>
                    <div className={css.groupContent}>
                        {fields.map((field) => (
                            <DynamicFieldContainer
                                key={field.key}
                                field={field}
                                control={control}
                                simpleMetadataControls={simpleMetadataControls}
                            />
                        ))}
                    </div>
                </CollapsibleContent>
            ))}
        </>
    );
}
