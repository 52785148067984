import slugify from '@sindresorhus/slugify';

// trims a specified substring from the begining and end of a string
export function trim(string: string, affix = '\\s') {
    const regexp = new RegExp(`(^(${affix})+)|((${affix})+$)`, 'g');
    return string.replace(regexp, '');
}

// replaces a substring made of multiple instances of a pattern into a single occurence
export function collapse(string: string, pattern = ' ') {
    const regexp = new RegExp(`(${pattern})+`, 'g');
    return string.replace(regexp, pattern);
}

export function clear(string: string, pattern: string) {
    return trim(collapse(string, pattern), pattern);
}

export function removeDuplicatedNewLines(string: string) {
    return string.replace(/[\r\n]{3,}/g, `\n\n`);
}

export function slug(string: string) {
    return slugify(string, {
        decamelize: false,
        lowercase: true,
        preserveLeadingUnderscore: true,
        customReplacements: [
            ['$', 'dollar'],
            ['|', 'or'],
        ],
    });
}

export function capitalize(string: string, keepRestCase?: boolean) {
    return string.charAt(0).toUpperCase() + (keepRestCase ? string.slice(1) : string.slice(1).toLowerCase());
}

export function camelCaseToSentence(string: string, titleCase = false) {
    return string
        .split(/(?=[A-Z])/)
        .map((word, index) => (index === 0 || titleCase ? capitalize(word) : word.toLowerCase()))
        .join(' ');
}

export function tryJSON<T>(string: string) {
    try {
        return JSON.parse(string) as T;
    } catch {
        return undefined;
    }
}
