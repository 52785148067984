import { arrayLengthValidator, wordCountValidator, valueCheckValidator } from 'services/asset-quality/validators';

const rules = {
    title: {
        name: 'title',
        field: 'title',
        validator: wordCountValidator(3),
    },
    titleFront: {
        name: 'alternative title',
        field: 'titleFront',
        validator: valueCheckValidator(),
    },
    description: {
        name: 'description',
        field: 'description',
        validator: wordCountValidator(4),
    },
    tags: {
        name: 'tag',
        field: 'additional.tags',
        validator: arrayLengthValidator(2),
    },
    contentType: {
        name: 'content type',
        field: 'additional.metadata.contentType',
        validator: valueCheckValidator(),
    },
    category: {
        name: 'category',
        field: 'category.id',
        validator: valueCheckValidator(),
    },
    nextAsset: {
        name: 'next video',
        field: 'additional.nextAsset.id',
        validator: valueCheckValidator(),
    },
    cuePoints: {
        name: 'midroll',
        field: 'additional.cuePoints',
        validator: arrayLengthValidator(1),
    },
    seriesSeason: {
        name: 'season',
        field: 'series.seasonNumber',
        validator: valueCheckValidator(),
    },
    seriesEpisode: {
        name: 'episode',
        field: 'series.episodeNumber',
        validator: valueCheckValidator(),
    },
    seoTitle: {
        name: 'google title',
        field: 'additional.metadata.seoTitle',
        validator: valueCheckValidator(),
    },
    seoDescription: {
        name: 'google description',
        field: 'additional.metadata.seoDescription',
        validator: valueCheckValidator(),
    },
} as const;

const placements = {
    publishingLiveAsset: 'publishingLiveAsset', // confirmation dialog during publishing live video
    publishingAsset: 'publishingAsset', // confirmation dialog during publishing
    assetList: 'assetList', // add an icon on the asset list with a tooltip
} as const;

export { rules, placements };
