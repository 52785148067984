import { ChangeEvent } from 'react';
import { Checkbox, LabeledContainer } from '@schibsted-svp/react-ui';
import { hasLogoOverlayOptions, LogoOverlaySelect } from 'components/video/live/form/LogoOverlaySelect';
import css from './TranscodingOptions.module.scss';

interface TranscodingOptionsProps {
    provider: string;
    overlayProps: {
        overlay: string;
        setOverlay: (overlay: string) => void;
    };
    previewProps: {
        preservePreview: boolean;
        setPreservePreview: (preserve: boolean) => void;
    };
}

export function TranscodingOptions({
    provider,
    overlayProps: { overlay, setOverlay },
    previewProps: { preservePreview, setPreservePreview },
}: TranscodingOptionsProps) {
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPreservePreview(event.target.checked);
    };

    return (
        <>
            {hasLogoOverlayOptions(provider) ? (
                <LabeledContainer label="Burn-in logo" className={css.burnInLogo}>
                    <LogoOverlaySelect
                        newsroom={provider}
                        size="compact"
                        overlay={overlay}
                        onChange={(option) => setOverlay(option.value)}
                    />
                </LabeledContainer>
            ) : null}

            <LabeledContainer label="Preview">
                <p className={css.leavePreview}>
                    <Checkbox
                        checked={preservePreview}
                        onClick={handleCheckboxChange}
                        label="Leave current previews unchanged"
                    />
                </p>
            </LabeledContainer>
        </>
    );
}
