import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFieldArray } from 'react-hook-form';
import { LabeledContainer, Input, Divider } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { combine } from 'lib/function';
import config from 'config';

import { getLiveNotifications } from 'store/live/selectors';

import { PlayerUrlCheckerProvider } from 'components/core/Player/UrlCheckerContext';
import { FormPlayerProvider } from 'components/video/edit/Form/Player';
import { StreamDetails } from 'components/video/StreamDetails';

import { CategorySelect } from 'components/core/hookForm/CategorySelect';
import { SourceSelect } from 'components/core/hookForm/SourceSelect';
import { TagsSelect } from 'components/core/hookForm/TagsSelect';
import { StoriesSelect } from 'components/core/hookForm/StoriesSelect';
import { Mood } from 'components/core/hookForm/Mood';
import { BylinesSelect } from 'components/core/hookForm/BylinesSelect';
import { TitleShortPath } from 'components/AssetForm/fields/TitileShortPath';
import LiveNotifications from 'components/LiveNotifications';

import { isLive } from 'models/asset';
import { slug } from 'lib/string';
import { ContentTypeSelect } from './fields/ContentTypeSelect';
import { TagsSuggestions } from './fields/TagsSuggestions';
import { Series } from './fields/Series';
import { AssetChecklistField } from './fields/AssetChecklist';
import { NewsLifetime } from './fields/NewsRating/NewsLifetime';
import { NewsValue } from './fields/NewsRating/NewsValue';
import { ViewerAccess } from './fields/ViewerAccess';
import { PodcastProperties } from './fields/PodcastProperties';
import { Description } from './fields/Description';
import { AdditionalProperties } from './containers/AdditionalProperties';
import { AssetDetails } from './containers/AssetDetails';
import { FormTabs } from './containers/FormTabs';
import { FormMainControls } from './containers/FormMainControls';
import { InlineCollapsible } from './misc/InlineCollapsible';
import { useAssetForm } from './hooks/useAssetForm';
import { useFormDataFetch } from './hooks/useFormDataFetch';
import { FormErrors } from './misc/FormErrors';
import { HidableLabeledContainer } from './misc/HidableLabeledContainer';
import { History } from './fields/History';
import { DynamicForm } from './fields/DynamicForm';
import { MovieProperties } from './containers/MovieProperties';
import StreamControls from './controls/StreamControls';
import FormPlayer from './controls/FormPlayer';

import css from './AssetForm.module.scss';

type AssetFormProps = {
    asset: Asset;
};

export const AssetForm = memo(function AssetForm({ asset }: AssetFormProps) {
    const { formApi, formErrors, onSubmit, defaultValues, getFieldState } = useAssetForm(asset);
    const { control, register, setValue, getValues } = formApi;
    const titleField = register('title');

    const simpleMetadataControls = useFieldArray({
        control,
        name: 'additional.metadata.simpleMetadata',
    });

    useFormDataFetch({ asset, control });

    const handleTitleChange = useCallback(
        (event) => {
            const tvSiteAssetUrlPrefix = config.newsroomsConfig[asset.provider]?.tvSiteAssetUrlPrefix;
            if (!tvSiteAssetUrlPrefix) return;
            if (defaultValues?.additional?.metadata?.titleShortPath) return;
            if (getFieldState('additional.metadata.titleShortPath').isDirty) return;
            setValue('additional.metadata.titleShortPath', slug(event.target.value));
        },
        [asset.provider, defaultValues?.additional?.metadata?.titleShortPath, getFieldState, setValue]
    );

    const shouldDisplayLiveNotifications = useSelector(
        (state) => getLiveNotifications(state, { newsroom: asset.provider, assetId: asset.id }).length > 0
    );

    return (
        <FormPlayerProvider>
            <PlayerUrlCheckerProvider id={asset.id} provider={asset.provider}>
                <form onSubmit={onSubmit()} className={css.form}>
                    <FormErrors
                        provider={asset.provider}
                        assetId={asset.id}
                        formApi={formApi}
                        formErrors={formErrors}
                    />
                    {shouldDisplayLiveNotifications && (
                        <LiveNotifications assetId={asset.id} newsroom={asset.provider} />
                    )}
                    <FormMainControls
                        asset={asset}
                        formApi={formApi}
                        onSubmit={onSubmit}
                        simpleMetadataControls={simpleMetadataControls}
                    />
                    <div className={css.fieldsContainer}>
                        <div className={css.content}>
                            <div className={css.formGroup}>
                                <LabeledContainer strict label="Title">
                                    <Input
                                        {...titleField}
                                        error={formErrors.title?.message}
                                        onChange={combine(titleField.onChange, handleTitleChange)}
                                    />
                                </LabeledContainer>
                                <TitleShortPath
                                    id={asset.id}
                                    provider={asset.provider}
                                    register={register}
                                    setValue={setValue}
                                    getValues={getValues}
                                />

                                <HidableLabeledContainer
                                    label="alternative title"
                                    fieldName="titleFront"
                                    value={asset.titleFront}
                                >
                                    <Input {...register('titleFront')} />
                                </HidableLabeledContainer>

                                <HidableLabeledContainer
                                    label="google title"
                                    fieldName="seoTitle"
                                    value={asset.additional.metadata.seoTitle}
                                >
                                    <Input {...register('additional.metadata.seoTitle')} />
                                </HidableLabeledContainer>
                            </div>
                            <div className={css.formGroup}>
                                <LabeledContainer strict label="Description">
                                    <Description control={control} />
                                </LabeledContainer>
                                <HidableLabeledContainer
                                    label="alternative description"
                                    fieldName="descriptionFront"
                                    value={asset.descriptionFront}
                                >
                                    <Input
                                        type="textarea"
                                        {...register('descriptionFront')}
                                        error={formErrors.descriptionFront?.message}
                                        rows={4}
                                    />
                                </HidableLabeledContainer>

                                <HidableLabeledContainer
                                    label="google description"
                                    fieldName="seoDescription"
                                    value={asset.additional?.metadata?.seoDescription}
                                >
                                    <Input
                                        {...register('additional.metadata.seoDescription')}
                                        type="textarea"
                                        rows={4}
                                    />
                                </HidableLabeledContainer>

                                <InlineCollapsible
                                    label="+add comment"
                                    startExpanded={Boolean(asset.additional.settings.notes)}
                                >
                                    <LabeledContainer strict label="Comment">
                                        <Input type="textarea" {...register('additional.settings.notes')} rows={4} />
                                    </LabeledContainer>
                                </InlineCollapsible>
                            </div>
                            <LabeledContainer strict label="Tags">
                                <TagsSelect
                                    name="additional.tags"
                                    control={control}
                                    provider={asset.provider}
                                    normalizeValues
                                />
                                <TagsSuggestions control={control} setValue={setValue} asset={asset} />
                            </LabeledContainer>
                            <LabeledContainer strict label="Stories">
                                <StoriesSelect
                                    name="additional.stories"
                                    control={control}
                                    provider={asset.provider}
                                    normalizeValues
                                />
                            </LabeledContainer>
                            <LabeledContainer strict label="Bylines">
                                <BylinesSelect
                                    name="additional.metadata.authors"
                                    control={control}
                                    provider={asset.provider}
                                    normalizeValues
                                />
                            </LabeledContainer>
                        </div>
                        <div className={css.additional}>
                            <LabeledContainer strict label="Select category">
                                <CategorySelect
                                    name="category.id"
                                    control={control}
                                    provider={asset.provider}
                                    error={formErrors.category?.id?.message}
                                />
                            </LabeledContainer>
                            <LabeledContainer strict label="Source/contains material from">
                                <SourceSelect name="additional.settings.source" control={control} />
                            </LabeledContainer>
                            <StreamDetails asset={asset} divider={<Divider margin="0px" />} />
                            <LabeledContainer strict label="Mood">
                                <Mood name="additional.metadata.mood" control={control} />
                            </LabeledContainer>
                            <Divider margin="-10px 0 10px" />

                            <ViewerAccess asset={asset} formApi={formApi} />
                            <LabeledContainer strict label="Content type">
                                <ContentTypeSelect provider={asset.provider} control={control} />
                            </LabeledContainer>
                            <AssetChecklistField asset={asset} control={control} />
                            <NewsLifetime control={control} />
                            <NewsValue control={control} />
                        </div>
                        <div className={css.other}>
                            <DynamicForm
                                asset={asset}
                                control={control}
                                simpleMetadataControls={simpleMetadataControls}
                            />
                            <PodcastProperties control={control} register={register} />
                            <Series
                                provider={asset.provider}
                                assetCategoryId={asset.category?.id}
                                formApi={formApi}
                                formErrors={formErrors}
                            />
                            <MovieProperties control={control} asset={asset} />
                            <AdditionalProperties
                                asset={asset}
                                formApi={formApi}
                                formErrors={formErrors}
                                simpleMetadataControls={simpleMetadataControls}
                                isLive={isLive(asset)}
                            />
                            <AssetDetails asset={asset} formApi={formApi} />
                            <History asset={asset} />
                            <StreamControls id={asset.id} provider={asset.provider} control={control} />
                        </div>
                        <div className={css.preview}>
                            <FormPlayer asset={asset} highlighted={false} />
                            <FormTabs
                                asset={asset}
                                isSubmitting={formApi.formState.isSubmitting}
                                control={control}
                                register={register}
                            />
                        </div>
                    </div>
                </form>
            </PlayerUrlCheckerProvider>
        </FormPlayerProvider>
    );
});
