import { get } from 'lodash';
import { ConvertVideo } from './graphql/stream-converter.graphql';

/**
 * @param {String} provider
 * @param {Number} assetId
 * @returns {Promise<Object>}
 */
export async function prepareConversionURL(provider, assetId) {
    const response = await this.query(ConvertVideo, {
        provider,
        assetId,
    });
    return get(response, 'convertVideo', {});
}
