import { useContext } from 'react';
import * as PropTypes from 'prop-types';
import config from 'config';
import { Button, LabeledContainer } from '@schibsted-svp/react-ui';
import { fromBlob } from 'lib/file';
import { useUrlChecker } from 'components/core/Player/UrlCheckerContext';
import FormPlayerContext from 'components/video/edit/Form/Player/PlayerContext';
import css from './VideoFrameForm.module.scss';

function VideoFrameForm({ disabled, isLive, onVideoPosterUpload }) {
    const { player } = useContext(FormPlayerContext);
    const { available } = useUrlChecker();

    const handleCreateThumbnailClick = async () => {
        await player.pause();
        const blob = await player.generateImage(config.images.poster.generate);
        return blob && onVideoPosterUpload(fromBlob(blob, 'thumbnail.jpg'));
    };

    return (
        <div className={css.wrapper}>
            <LabeledContainer width={80} label="Select image from video">
                <Button
                    type="button"
                    size="small"
                    disabled={disabled || !player || (isLive && !available)}
                    onClick={handleCreateThumbnailClick}
                >
                    Create
                </Button>
            </LabeledContainer>
        </div>
    );
}

VideoFrameForm.propTypes = {
    disabled: PropTypes.bool,
    isLive: PropTypes.bool.isRequired,
    onVideoPosterUpload: PropTypes.func.isRequired,
};

VideoFrameForm.defaultProps = {
    disabled: false,
};

export default VideoFrameForm;
