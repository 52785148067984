import { RefObject } from 'react';
import { Button, Dialog, Spinner } from '@schibsted-svp/react-ui';
import type { SubtitlesUploadedData } from 'components/AssetForm/fields/Subtitles/types';

import { useSubtitleCuesFetch } from './hooks/useSubtitleCuesFetch';
import { SubtitlesForm, SubtitlesFormRef } from './SubtitlesForm';
import css from './SubtitlesEditorProvider.module.scss';

interface SubtitlesEditorProps {
    provider: string;
    assetId: number;
    language: string;
    subtitles: SubtitlesUploadedData[];
    closeDialog: () => void;
    formRef: RefObject<SubtitlesFormRef>;
}

export function SubtitlesEditorProvider({
    provider,
    assetId,
    language,
    subtitles,
    closeDialog,
    formRef,
}: SubtitlesEditorProps) {
    const selectedSubtitles = subtitles.find((sub) => sub.language === language);
    const { subtitleCues, isLoading } = useSubtitleCuesFetch(selectedSubtitles);

    if (!subtitleCues) {
        return (
            <>
                <Dialog.Section className={css.empty}>
                    <div>
                        {isLoading ? (
                            <Spinner animation="spinning" />
                        ) : (
                            `No captions available for selected language: ${language}`
                        )}
                    </div>
                </Dialog.Section>

                <Dialog.Section mode="flexRight" variant="darker">
                    <Button type="button" variant="standard" onClick={closeDialog}>
                        Cancel
                    </Button>
                </Dialog.Section>
            </>
        );
    }

    const generateSubtitles = (uploadedSubtitles: SubtitlesUploadedData) => {
        return subtitles.map((sub) => (sub.language === uploadedSubtitles.language ? uploadedSubtitles : sub));
    };

    return (
        <SubtitlesForm
            ref={formRef}
            provider={provider}
            assetId={assetId}
            language={language}
            cues={isLoading ? undefined : subtitleCues}
            closeDialog={closeDialog}
            generateSubtitles={generateSubtitles}
        />
    );
}
