import classnames from 'classnames/bind';
import type { ReactEventHandler, UIEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import { BiPause as PauseIcon, BiPlay as PlayIcon, BiLoaderAlt as LoaderIcon } from 'react-icons/bi';
import { AiOutlineStop as OutlineStopIcon } from 'react-icons/ai';

import type { Maybe } from 'types';
import { usePrevious } from 'hooks/usePrevious';
import css from './HTML5Player.module.scss';

const cln = classnames.bind(css);

interface HTML5PlayerProps {
    className?: string;
    loading?: boolean;
    src?: string;
    onDurationChange?: (duration: number) => void;
    width?: number;
}

export function HTML5Player({ className, loading = false, src, onDurationChange, width }: HTML5PlayerProps) {
    const videoRef = useRef<Maybe<HTMLVideoElement>>(null);
    const playPromiseRef = useRef<Maybe<Promise<void>>>(null);
    const prevSrc = usePrevious(src);
    const [paused, setPaused] = useState(true);

    useEffect(() => {
        if (!videoRef.current || !src || prevSrc === src) {
            return;
        }

        videoRef.current.src = src;
        videoRef.current.load();
        if (!paused) {
            videoRef.current.play();
        }
    }, [loading, paused, src, prevSrc]);

    const handleClick: UIEventHandler<HTMLVideoElement> = async ({ currentTarget }) => {
        if (playPromiseRef.current) {
            return;
        }

        if (currentTarget.paused) {
            playPromiseRef.current = currentTarget.play();
            if (playPromiseRef.current !== null) {
                try {
                    await playPromiseRef.current;
                    setPaused(false);
                    playPromiseRef.current = null;
                } catch (error) {
                    playPromiseRef.current = null;
                }
            }
        } else {
            currentTarget.pause();
            setPaused(true);
            playPromiseRef.current = null;
        }
    };

    const handleDurationChange: ReactEventHandler<HTMLVideoElement> = (event) => {
        onDurationChange?.(event.currentTarget.duration);
    };

    return (
        <div
            className={cln(css.player, className, {
                'player--loading': loading,
                'player--paused': paused,
                'player--empty': !src,
            })}
        >
            <LoaderIcon className={css.loaderIcon} />
            <PauseIcon className={css.pauseIcon} />
            <PlayIcon className={css.playIcon} />
            <OutlineStopIcon className={css.outlineStopIcon} />

            {src ? (
                <video
                    width={width}
                    className={css.player}
                    onClick={handleClick}
                    muted
                    loop
                    ref={videoRef}
                    onDurationChange={handleDurationChange}
                >
                    <source src={src} type="video/mp4" />
                    <track kind="captions" />
                </video>
            ) : (
                <div className={css.emptyVideo} />
            )}
        </div>
    );
}

HTML5Player.displayName = 'HTML5Player';
