import { createSlice } from '@reduxjs/toolkit';

const pushNotificationsSlice = createSlice({
    name: 'pushNotifications',
    initialState: {},
    reducers: {
        sendPushNotification: () => {},
    },
});

export const { sendPushNotification } = pushNotificationsSlice.actions;

export default pushNotificationsSlice;
