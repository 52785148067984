import { adminBffClient } from 'services';
import { takeEvery, call } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import { sendPushNotification } from './slice';

export function* handleSendPushNotification({ payload: { provider, assetId, notificationBody } }) {
    try {
        yield call(adminBffClient.sendPushNotification, { provider, assetId, notificationBody });
    } catch (error) {
        const message = 'Failed sending push notification';
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        Notification.notify.error(message);
    }
}

export default [takeEvery(sendPushNotification, handleSendPushNotification)];
