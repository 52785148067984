import { useState, useMemo } from 'react';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { ChangeEvent } from 'react';
import config from 'config';
import { isVod } from 'models/asset';
import { Button, Select, Notification } from '@schibsted-svp/react-ui';
import type { DefaultOptionType } from '@schibsted-svp/react-ui/lib/es/Select';
import { useGenerateSubtitlesForAssetMutation } from 'services/admin-bff-sdk/generated';
import { useDeferredDispatch } from 'hooks/useDeferredDispatch';
import { uploadSubtitles } from 'store/uploads/subtitles/actions';
import { findSingleOption } from 'components/ui/Form/Select';
import type { SubtitlesUploadedData } from './types';
import css from './UploadSubtitles.module.scss';

type UploadSubtitlesProps = {
    provider: string;
    assetId: number;
    streamType: Asset['streamType'];
    disabled: boolean;
    addSubtitlesToForm: (subtitles: SubtitlesUploadedData) => void;
};

export function UploadSubtitles({
    provider,
    assetId,
    streamType,
    disabled = false,
    addSubtitlesToForm,
}: UploadSubtitlesProps) {
    const deferredDispatch = useDeferredDispatch();
    const [generateSubtitlesForAsset, { isLoading }] = useGenerateSubtitlesForAssetMutation();

    const [isAdding, setIsAdding] = useState(false);
    const [language, setLanguage] = useState<string>(null);
    const [file, setFile] = useState<File>(undefined);

    const options = useMemo(
        () =>
            Object.keys(config.languages).map((option) => ({
                value: option,
                label: config.languages[option],
            })),
        []
    );

    const addSubtitles = async () => {
        try {
            setIsAdding(true);
            const uploadedSubtitles = await deferredDispatch<SubtitlesUploadedData>(
                uploadSubtitles(provider, assetId, language, file)
            );
            addSubtitlesToForm(uploadedSubtitles);
            setLanguage(null);
            setFile(undefined);
        } catch (error) {
            Notification.notify.error('Failed to upload subtitles');
        } finally {
            setIsAdding(false);
        }
    };

    const selectLanguage = (option: DefaultOptionType) => setLanguage(option?.value || null);

    const chooseFile = (event: ChangeEvent<HTMLInputElement>) => setFile(event.target.files[0]);

    const generateSubtitles = async () => {
        const result = await generateSubtitlesForAsset({ id: assetId, provider });
        if ('data' in result) {
            const { success, error } = result.data.generateSubtitlesForAsset;
            if (success) {
                Notification.notify.success('Subtitles are being generated - player will update when finished');
            } else if (error) {
                Notification.notify.error(`Failed to generate subtitles - ${error}`);
            }
        } else {
            Notification.notify.error('Failed to generate subtitles');
        }
    };
    return (
        <div className={css.container}>
            <Button type="label" variant="standard" size="small" disabled={!options.length}>
                <input
                    type="file"
                    onChange={chooseFile}
                    disabled={disabled || !options.length}
                    data-testid="subtitlesUploadInput"
                    hidden
                />
                {file?.name || 'Choose file'}
            </Button>
            <Select
                size="small"
                placeholder="Language"
                value={findSingleOption(options)(language)}
                onChange={selectLanguage}
                options={options}
                splitButton={false}
                isDisabled={disabled || !options.length}
                isSearchable={false}
                isClearable
                required
            />
            <Button
                type="button"
                size="small"
                onClick={addSubtitles}
                disabled={disabled || isAdding || !language || !file}
            >
                Add subtitles
            </Button>
            {isVod({ streamType }) && (
                <Button type="button" size="small" loading={isLoading} onClick={generateSubtitles}>
                    Generate subtitles
                </Button>
            )}
        </div>
    );
}
