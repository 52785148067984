import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { authEmailSelector } from 'store/auth/selectors';
import { makeUserNewsroomSelector } from 'services/admin-bff-sdk/selectors';
import { selectUserLinkedProviders } from 'services/svp-api-rtk/providers';

export function useUserPermissions(): { isUser: boolean; isAssociate: boolean; userNewsrooms: string[] } | undefined {
    const { newsroom } = useParams();
    const email = useSelector(authEmailSelector);
    const selectUserNewsrooms = useMemo(() => makeUserNewsroomSelector(email), [email]);
    const userNewsrooms = useSelector(selectUserNewsrooms);
    const userLinkedProviders = useSelector((state) => selectUserLinkedProviders(state, userNewsrooms));

    if (!userNewsrooms || !userLinkedProviders) {
        return undefined;
    }

    return {
        isUser: userNewsrooms.includes(newsroom),
        isAssociate: userLinkedProviders.includes(newsroom),
        userNewsrooms,
    };
}
