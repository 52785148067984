// fetch user acl action
export const FETCH_LIST = 'AssetList/fetchList';
export const FETCH_ERROR = 'AssetList/fetchError';
export const FETCH_MORE = 'AssetList/fetchMore';

export const SEARCH = 'AssetList/SEARCH';

export const SET_ASSETS = 'AssetList/setAssets';
export const ADD_ASSETS = 'AssetList/addAssets';
export const SET_HIGHLIGHT = 'AssetList/setHighlight';

export const FILTERS_SET = 'Filters/SET';
export const FILTERS_REMOVE = 'Filters/REMOVE';
export const FILTERS_CLEAR = 'Filters/CLEAR';

export const SORT_TYPE_SET = 'SORT_TYPE/SET';
