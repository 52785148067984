import * as Actions from './actionTypes';

export const fetchList = (payload) => ({
    type: Actions.FETCH_LIST,
    ...payload,
});

export const fetchMore = (payload) => ({
    type: Actions.FETCH_MORE,
    ...payload,
});

export const setAssets = (payload) => ({
    type: Actions.SET_ASSETS,
    ...payload,
});

export const addAssets = (payload) => ({
    type: Actions.ADD_ASSETS,
    ...payload,
});

export const fetchError = (payload) => ({
    type: Actions.FETCH_ERROR,
    ...payload,
});

export const filtersSet = ({ list, filters }) => ({
    type: Actions.FILTERS_SET,
    list,
    filters,
});

export const filtersRemove = ({ list, filter, value }) => ({
    type: Actions.FILTERS_REMOVE,
    list,
    filter,
    value,
});

/**
 *
 * @param {Object} params
 * @param {String} params.list [main|linked]
 * @param {String} params.assetsListType [audio|video]
 */
export const filtersClear = ({ list, assetsListType, isPodcastOnly, isChangingAssetListType = false }) => ({
    type: Actions.FILTERS_CLEAR,
    list,
    assetsListType,
    isPodcastOnly,
    isChangingAssetListType,
});

export const sortTypeSet = ({ list, sortType }) => ({
    type: Actions.SORT_TYPE_SET,
    list,
    sortType,
});

export const search = () => ({
    type: Actions.SEARCH,
});

export const setHighlight = ({ assetId, value }) => ({
    type: Actions.SET_HIGHLIGHT,
    assetId,
    value,
});
