import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { getPrefixMetadataKey } from 'store/uploads/previews/sagas-utils';
import type { FormValues } from 'components/AssetForm/types';
import { getMultiPreviewPrefixes } from './utils';
import { MultiPreview } from './MultiPreview';

type MultiPreviewListRHFProps = {
    assetId: number;
    control: Control<FormValues>;
    provider: string;
};

export function MultiPreviewListRHF({ assetId, control, provider }: MultiPreviewListRHFProps) {
    const prefixes = useMemo(() => getMultiPreviewPrefixes(provider), [provider]);
    const { field } = useController({
        name: `additional.metadata.multiPreviewsMetadata`,
        control,
    });

    const makeOnChangeHandler = (prefix: string) => {
        return (value: string) => {
            field.onChange({
                ...field.value,
                [getPrefixMetadataKey(prefix)]: value,
            });
        };
    };

    return (
        <>
            {prefixes.map((prefix) => (
                <MultiPreview
                    provider={provider}
                    assetId={assetId}
                    key={`multi-preview:${prefix}`}
                    prefix={prefix}
                    value={field.value[getPrefixMetadataKey(prefix)]}
                    onChange={makeOnChangeHandler(prefix)}
                />
            ))}
        </>
    );
}
