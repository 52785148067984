import config from 'config';
import { get, pick, isEmpty } from 'lodash';
import { getValues } from 'lib/array';
import { sources as selectSources } from 'components/core/hookForm/SourceSelect';

/**
 *
 * @param {Object} selectedFilters
 * @param {String} newsroom
 * @param {Object} sortType
 * @returns {Object}
 */
export default function generateSearchParameters(selectedFilters, sortType) {
    const {
        moods,
        before,
        after,
        categories,
        excludedCategory,
        excludedTags,
        isPodcast,
        createdBy,
        min,
        max,
        isGeoblocked,
        accessLevels,
        showHidden,
        ...rest
    } = selectedFilters;

    Object.keys(rest)
        .filter((key) => key !== 'customFields')
        .forEach((key) => {
            rest[key] = rest[key] && getValues(rest[key]);
        });
    const filterValues = { ...rest };
    // extract query params from filters
    const { statuses, types, tags, stories, aspects, sources, assetTypes } = filterValues;
    // make sure that if no status is selected, all filters are set
    // since otherwise only 'active' assets are fetched

    const { customFields = {} } = filterValues;

    const customFilters = Object.entries(customFields).reduce((acc, [key, value]) => {
        const isObject = value && typeof value === 'object';

        return {
            ...acc,
            [`additional.metadata.${key}`]: isObject ? value.value : value,
        };
    }, {});

    const filters = {
        status: Array.isArray(statuses) && statuses.length ? statuses : Object.keys(config.asset.labels.status),
        categoryId: categories && categories.value,
        excludedCategory: excludedCategory?.map((category) => category.value),
        excludedTags: excludedTags?.map((tag) => tag.value),
        streamType: types,
        createdBy,
        'additional.tags.id': tags,
        'additional.stories': stories,
        'additional.metadata.mood': moods,
        'additional.ratio': aspects,
        'additional.settings.source': sources,
        'additional.metadata.isPodcast': isPodcast,
        assetType: assetTypes,
        min: min && min * 1000,
        max: max && max * 1000,
        ...(showHidden !== undefined && { 'additional.settings.showInNewest': !showHidden }),
        ...customFilters,
    };

    filters['streamConfiguration.properties'] = isGeoblocked ? ['geoblocked'] : [];
    if (accessLevels?.length) {
        filters['streamConfiguration.properties'].push('tokenSecured');
        filters['streamConfiguration.properties'].push('encrypted');
        filters['additional.access'] = accessLevels.map(({ value }) => value).join(',');
    }

    return {
        before,
        after,
        filters,
        order: sortType.value,
    };
}

const aspectRatios = get(config, 'aspectRatios');

const { streamType, assetType, status } = get(config, 'asset.labels', {});

function mapFilters(filters, labels) {
    if (!Array.isArray(filters)) {
        return undefined;
    }

    return filters.map((filter) => ({
        value: filter,
        label: labels[filter],
    }));
}

function mapAspectFilters(filters, labels) {
    if (!Array.isArray(filters)) {
        return undefined;
    }

    return filters.map((filter) => ({
        value: filter,
        label: labels.find(({ value }) => value === filter).label,
    }));
}

function mapTagFilters(filters, labels = {}) {
    if (!Array.isArray(filters)) {
        return undefined;
    }
    return filters.map((filter) => ({
        value: filter,
        label: get(labels, [filter, 'tag', 'tag']),
    }));
}

function mapStoryFilters(provider, filters, labels = {}) {
    if (!Array.isArray(filters) || isEmpty(labels)) {
        return undefined;
    }
    return filters.map((filter) => ({
        value: filter,
        label: labels?.[provider]?.[filter]?.story?.title,
    }));
}

export function mapCategoryFilters(categories, excludedCategory) {
    const { items } = categories;
    if (!items || isEmpty(excludedCategory)) return undefined;

    const selectedTitles = pick(items, excludedCategory);

    return excludedCategory.map((categoryId) => ({
        value: categoryId,
        label: selectedTitles?.[categoryId]?.title,
    }));
}

export function generateSearchParametersReverse(
    provider,
    newsrooms,
    selectedFilters,
    tags,
    stories,
    categories = {},
    accessDefinitionOptions
) {
    const { categoryId, excludedCategory, excludedTags, createdBy = '' } = selectedFilters;
    const shouldShowHidden =
        get(selectedFilters, 'additional.settings.showInNewest') === undefined
            ? true
            : !get(selectedFilters, 'additional.settings.showInNewest');

    const filters = {
        statuses: mapFilters(selectedFilters.status, status),
        categories: categoryId && {
            value: categoryId,
            label: get(categories, ['items', categoryId, 'title']),
        },
        excludedCategory: mapCategoryFilters(categories, excludedCategory),
        types: mapFilters(selectedFilters.streamType, streamType),
        createdBy: createdBy.toString(),
        tags: mapTagFilters(selectedFilters['additional.tags.id'], tags),
        excludedTags: mapTagFilters(excludedTags, tags),
        stories: mapStoryFilters(provider, selectedFilters['additional.stories'], stories),
        moods: get(selectedFilters, 'additional.metadata.mood', ''),
        aspects: mapAspectFilters(get(selectedFilters, 'additional.ratio'), aspectRatios),
        sources: mapFilters(selectedFilters['additional.settings.source'], selectSources),
        isPodcast: Boolean(get(selectedFilters, 'additional.metadata.isPodcast', [])[0]),
        assetTypes: mapFilters(selectedFilters.assetType, assetType),
        min: Math.round(selectedFilters.min / 1000),
        max: Math.round(selectedFilters.max / 1000),
        newsrooms: (newsrooms && newsrooms.split(',')) || [provider],
        isGeoblocked: selectedFilters?.['streamConfiguration.properties']?.includes('geoblocked'),
        showHidden: shouldShowHidden,
    };

    const access = selectedFilters?.['additional.access'];
    if (access) {
        filters.accessLevels = accessDefinitionOptions.filter(({ value }) => access.includes(value));
    }

    return Object.entries(filters).reduce(
        (prev, [key, value]) => ({
            ...prev,
            ...((key === 'showHidden' || value) && { [key]: value }),
        }),
        {}
    );
}
