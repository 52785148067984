import { useState, useEffect, useRef, FocusEvent, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import classnames from 'classnames/bind';
import { MdClear as ClearIcon } from 'react-icons/md';
import { CgUndo as UndoIcon } from 'react-icons/cg';
import { LabeledContainer, Input, Radio } from '@schibsted-svp/react-ui';
import { generateUniqId } from 'lib/uniq';
import { getLiveEncoder } from 'store/live/selectors';
import * as validatorHelpers from '../../validators';
import { useEncoderInputFormContext } from '../hooks';
import css from './NetworkInput.module.scss';

const cln = classnames.bind(css);

const SOURCE_TYPE = { PUSH: 'PUSH', PULL: 'PULL' } as const;

type SourceType = (typeof SOURCE_TYPE)[keyof typeof SOURCE_TYPE];

export const INTERNAL_PUSH_URL = 'rtmp://localhost/live/';

export const getInternalPushUrl = (id: string) => `${INTERNAL_PUSH_URL}${id}`;
export const getExternalPushUrl = (url: string) => `${url}/live/`.replace(/^https?:/, 'rtmp:');

const validatePushUrl = (url: string) => (url === INTERNAL_PUSH_URL ? 'Video Key is required' : undefined);

interface NetworkInputProps {
    name: 'networkUrl' | 'backupNetworkUrl';
    encoderId: string;
    label?: string;
}

export function NetworkInput({ name, encoderId, label }: NetworkInputProps) {
    const { formApi } = useEncoderInputFormContext();
    const { control, resetField } = formApi;

    const uniqId = useRef(generateUniqId());
    const pushKey = useRef(uniqId.current);

    const [sourceType, setSourceType] = useState<SourceType>(SOURCE_TYPE.PUSH);

    const { field, fieldState } = useController({
        name,
        control,
        rules: { validate: sourceType === SOURCE_TYPE.PUSH ? validatePushUrl : validatorHelpers.url },
        shouldUnregister: true,
    });

    const { url: encoderUrl } = useSelector((state) => getLiveEncoder(state, { encoderId })) || {};

    const resetFieldDefaultValue = (value = '') => {
        resetField(name, { defaultValue: value });
    };

    const handleInputSelection = (event: FocusEvent<HTMLInputElement>) => {
        event.target.select();
    };

    const handlePushKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
        pushKey.current = event.target.value.trim();
        field.onChange(getInternalPushUrl(pushKey.current));
    };

    const handleVideoKeyResetClick = () => {
        pushKey.current = pushKey.current ? '' : uniqId.current;
        field.onChange(getInternalPushUrl(pushKey.current));
    };

    const handlePullUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
        field.onChange(event.target.value.trim());
    };

    const handlePushOptionClick = () => {
        pushKey.current = uniqId.current;
        resetFieldDefaultValue(getInternalPushUrl(pushKey.current));
        setSourceType(SOURCE_TYPE.PUSH);
    };

    const handlePullOptionClick = () => {
        resetFieldDefaultValue();
        setSourceType(SOURCE_TYPE.PULL);
    };

    useEffect(() => {
        if (sourceType === SOURCE_TYPE.PUSH) {
            resetFieldDefaultValue(getInternalPushUrl(uniqId.current));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={cln('container', {
                [`${css['container-one-column']}`]: sourceType === SOURCE_TYPE.PULL,
            })}
        >
            <LabeledContainer label={label} className={css.sourceTypeSelector}>
                <Radio
                    checked={sourceType === SOURCE_TYPE.PUSH}
                    onChange={handlePushOptionClick}
                    label="Generate key (push live)"
                    data-testid="network-push-option"
                />
                <Radio
                    checked={sourceType === SOURCE_TYPE.PULL}
                    onChange={handlePullOptionClick}
                    label="Use external URL (pull live)"
                    data-testid="network-pull-option"
                />
            </LabeledContainer>

            {sourceType === SOURCE_TYPE.PUSH ? (
                <>
                    <LabeledContainer label="Network Input URL">
                        <Input
                            value={getExternalPushUrl(encoderUrl)}
                            onFocus={handleInputSelection}
                            readOnly
                            data-testid="network-push-url"
                        />
                    </LabeledContainer>
                    <LabeledContainer label="Video Key">
                        <Input
                            {...field}
                            value={pushKey.current}
                            onChange={handlePushKeyChange}
                            onFocus={handleInputSelection}
                            error={fieldState.error?.message}
                            icon={
                                <span className={css.clearIcon} onClick={handleVideoKeyResetClick}>
                                    {pushKey.current ? <ClearIcon /> : <UndoIcon />}
                                </span>
                            }
                            iconPosition="right"
                            data-testid="network-push-key"
                        />
                    </LabeledContainer>
                </>
            ) : (
                <LabeledContainer label="Network Input URL">
                    <Input
                        {...field}
                        onChange={handlePullUrlChange}
                        error={fieldState.error?.message}
                        data-testid="network-pull-url"
                    />
                </LabeledContainer>
            )}
        </div>
    );
}
