import { LabeledContainer, CollapsibleContent, DateTimeInput } from '@schibsted-svp/react-ui';

import { Control, Controller, useWatch } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { getNewsroomConfig } from 'config';
import { CastMembersSelect } from '../../core/hookForm/CastMembersSelect';
import type { FormValues } from '../types';
import { DirectorSelect } from '../../core/hookForm/DirectorSelect';

import css from './MovieProperties.module.scss';
import { formatDate, normalizeDate } from '../../core/FiltersDialog/FiltersDialog';

type MoviePropertiesProps = {
    control: Control<FormValues>;
    asset: Asset;
};

export function MovieProperties({ control, asset }: MoviePropertiesProps) {
    const [contentType] = useWatch({ name: ['additional.metadata.contentType'], control });
    const { features } = getNewsroomConfig(asset.provider);

    if (!(contentType === 'documentary' && features.movieProperties?.enabled)) {
        return null;
    }

    return (
        <CollapsibleContent label="Movie properties">
            <section className={css.content}>
                <LabeledContainer strict label="Cast members">
                    <CastMembersSelect
                        name="additional.metadata.movie_cast"
                        control={control}
                        provider={asset.provider}
                    />
                </LabeledContainer>
                <LabeledContainer strict label="Director">
                    <DirectorSelect
                        name="additional.metadata.movie_director"
                        control={control}
                        provider={asset.provider}
                    />
                </LabeledContainer>
                <div className={css.doubleCol}>
                    <LabeledContainer strict label="Release year">
                        <Controller
                            name="additional.metadata.movie_releasedAt"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DateTimeInput
                                    {...field}
                                    value={formatDate(Number(field?.value))}
                                    onChange={(value) => field.onChange(normalizeDate(value))}
                                    error={error?.message}
                                    size="small"
                                    isClearable
                                    datePickerProps={{
                                        dateFormat: 'yyyy',
                                        showYearPicker: true,
                                    }}
                                />
                            )}
                        />
                    </LabeledContainer>
                </div>
            </section>
        </CollapsibleContent>
    );
}
