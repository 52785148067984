import { memo } from 'react';
import type { UseFormReturn, FieldErrors, UseFieldArrayReturn } from 'react-hook-form';
import { LabeledContainer, Checkbox, CollapsibleContent, Divider } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { ArticleUrlInput } from '../fields/ArticleUrlInput';
import { Subtitles } from '../fields/Subtitles';
import { ShowAdsCheckbox } from '../fields/ShowAdsCheckbox';
import { ShowInNewestCheckbox } from '../fields/ShowInNewestCheckbox';
import { AgeLimitSelect } from '../../core/hookForm/AgeLimitSelect';
import { AssetMetadataInputs } from '../fields/AssetMetadataInputs';
import { LiveVideoTime } from '../fields/LiveVideoTime';
import type { FormValues } from '../types';
import css from './AdditionalProperties.module.scss';

type AdditionalPropertiesProps = {
    asset: Asset;
    formApi: UseFormReturn<FormValues>;
    formErrors: FieldErrors<FormValues>;
    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;
    isLive: boolean;
};

export const AdditionalProperties = memo(function AdditionalProperties({
    asset,
    formApi,
    formErrors,
    simpleMetadataControls,
    isLive,
}: AdditionalPropertiesProps) {
    const { control, register } = formApi;

    return (
        <CollapsibleContent label="Additional properties" className={css.container}>
            <section className={css.content}>
                <LabeledContainer strict label="Article url">
                    <ArticleUrlInput control={control} error={formErrors.articleUrl?.message} />
                </LabeledContainer>
                <Divider />
                <Subtitles
                    provider={asset.provider}
                    assetId={asset.id}
                    streamType={asset.streamType}
                    formApi={formApi}
                />
                <Divider />
                <LabeledContainer label="Properties">
                    <div className={css.properties}>
                        <Checkbox {...register('additional.metadata.silentplay')} label="This is a silent video" />
                        <ShowAdsCheckbox control={control} />
                        <ShowInNewestCheckbox control={control} />
                    </div>
                </LabeledContainer>
                <Divider />
                <div className={css.doubleCol}>
                    <LabeledContainer strict label="Age limit">
                        <AgeLimitSelect name="additional.settings.ageLimit" control={control} />
                    </LabeledContainer>
                </div>
                <Divider />
                <AssetMetadataInputs
                    asset={asset}
                    control={control}
                    register={register}
                    simpleMetadataControls={simpleMetadataControls}
                />
                {isLive ? <LiveVideoTime control={control} /> : null}
            </section>
        </CollapsibleContent>
    );
});
