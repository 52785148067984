export const authEmailSelector = (store) => store.auth?.token?.sub || '';

export const hasToken = (store) => {
    const token = store.auth?.token?.raw;

    if (!token) {
        return false;
    }
    const indexHtml = '<!DOCTYPE html>';
    const isValidToken = !token.startsWith(indexHtml);

    return isValidToken;
};
export const isVerifying = (store) => store.auth.verifying;
