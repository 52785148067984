import { Button, Dialog, Spinner } from '@schibsted-svp/react-ui';
import { UseFormGetValues, UseFieldArrayInsert } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import VideoCard from 'components/core/VideoCard';
import InfiniteScroll from 'react-infinite-scroller';
import Playlist from 'components/Playlists';
import { throttle } from 'lodash';

import config from 'config';

import { FaSearch } from 'react-icons/fa';

import { staticItemsClear, staticVideosSearchMore, playlistsSearch } from 'store/playlists/staticItems';
import { getStaticItemsData } from 'store/playlists/selectors';
import type { FormValues, PlaylistItemAsset, PlaylistItemPlaylist } from './types';
import StaticItemsForm from './StaticItemsForm';
import css from './StaticItemsSearchDialog.module.scss';

interface StaticItemsSearchDialogProps {
    provider: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    staticItemPosition: number;
    isRegularPlaylist: boolean;
    getValues: UseFormGetValues<FormValues>;
    insertItem: UseFieldArrayInsert<FormValues, 'items'>;
}

export default function StaticItemsSearchDialog({
    provider,
    isOpen,
    setIsOpen,
    staticItemPosition,
    isRegularPlaylist,
    getValues,
    insertItem,
}: StaticItemsSearchDialogProps) {
    const heading = isRegularPlaylist ? 'Find' : 'Find playlist';

    const dispatch = useDispatch();

    const {
        isFetching: isFetchingStaticItems,
        items: staticItems,
        query: staticItemsQuery,
        nextUrl,
        providers,
        hasNext,
    } = useSelector(getStaticItemsData);

    const closeAddStaticItemsModal = () => {
        setIsOpen(false);
        dispatch(staticItemsClear());
    };

    const addStaticItem = (newStaticItem: PlaylistItemAsset['asset'] | PlaylistItemPlaylist['playlist']) => {
        insertItem(staticItemPosition, {
            position: staticItemPosition,
            isStatic: true,
            ...(isRegularPlaylist
                ? {
                      asset: newStaticItem as PlaylistItemAsset['asset'],
                  }
                : {
                      playlist: newStaticItem as PlaylistItemPlaylist['playlist'],
                  }),
        });
        closeAddStaticItemsModal();
    };

    const renderStaticItemsContent = () => {
        if (isFetchingStaticItems && !staticItems.length) {
            return <Spinner containerClassName={css.spinner} />;
        }
        if (!staticItems.length && staticItemsQuery) {
            return (
                <div className={css.noResults}>
                    <FaSearch size={40} opacity={0.15} />
                    <br />
                    No results matching search criteria
                </div>
            );
        }

        const additionalInfo = (newsroom: string, category: number) => (
            <span>
                <span className={css.newsroom}>
                    &nbsp;-&nbsp;&nbsp;
                    {config.newsrooms[newsroom]}
                </span>
                <span className={css.category}>{category}</span>
            </span>
        );

        return staticItems.map((staticItem) => {
            if (isRegularPlaylist) {
                return (
                    <VideoCard
                        key={staticItem.id}
                        id={staticItem.id}
                        provider={staticItem.provider}
                        visibleIcons={['duration', 'date']}
                        variant="simple"
                        readOnly
                        asLink={false}
                        noFollow
                        className={css.videoCard}
                        additionalInfo={additionalInfo(staticItem.provider, staticItem.category)}
                    >
                        <Button
                            size="small"
                            type="button"
                            onClick={() => {
                                addStaticItem(staticItem);
                            }}
                        >
                            Add
                        </Button>
                    </VideoCard>
                );
            }

            return (
                <div className={css.container} key={staticItem.id}>
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            addStaticItem(staticItem);
                        }}
                        className={css.button}
                    >
                        Add
                    </Button>
                    <Playlist
                        provider={staticItem.provider}
                        id={staticItem.id}
                        hideDeleteButton
                        className={css.playlist}
                        size="compact"
                        noFollow
                    />
                </div>
            );
        });
    };

    const loadMore = throttle(() => {
        if (isRegularPlaylist) {
            dispatch(staticVideosSearchMore());
        } else {
            dispatch(
                playlistsSearch({
                    searchTerm: staticItemsQuery,
                    providers,
                    originProvider: provider,
                })
            );
        }
    }, 100);

    return (
        <Dialog isOpen={isOpen} heading={heading} onClose={closeAddStaticItemsModal} className={css.dialog}>
            <Dialog.Section className={css.search}>
                <StaticItemsForm
                    provider={provider}
                    isRegularPlaylist={isRegularPlaylist}
                    isFetching={isFetchingStaticItems}
                    getValues={getValues}
                />
            </Dialog.Section>
            <Dialog.Section>
                <div className={css.staticItems}>
                    <InfiniteScroll
                        threshold={0.05}
                        loadMore={loadMore}
                        hasMore={Boolean(nextUrl) || hasNext}
                        initialLoad={false}
                        useWindow={false}
                    >
                        {renderStaticItemsContent()}
                        {isFetchingStaticItems && staticItems.length !== 0 && (
                            <Spinner containerClassName={css.smallSpinner} label="Loading..." size="small" />
                        )}
                    </InfiniteScroll>
                </div>
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button data-test="CancelButton" type="button" variant="standard" onClick={closeAddStaticItemsModal}>
                    Cancel
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}
