import { useEffect, useState } from 'react';

import { useController } from 'react-hook-form';
import type { Control, FieldValues, FieldPath } from 'react-hook-form';
import { Select, Spinner } from '@schibsted-svp/react-ui';
import type { ExtendedProps as BylinesProps, DefaultOptionType } from '@schibsted-svp/react-ui/lib/es/Select';
import { useAuthorsSearch } from '../../AssetForm/hooks/useAuthorsSearch';
import { useAuthorsGet } from '../../AssetForm/hooks/useAuthorsGet';

import css from './BylinesSelect.module.scss';

function LoadingMessage() {
    return <Spinner containerClassName={css.optionsSpinner} size="compact" label="Loading bylines" />;
}
function LoadingIndicator() {
    return null;
}

type BylinesSelectProps<TFieldValues extends FieldValues> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    provider: string;
} & BylinesProps<DefaultOptionType, true>;

export function BylinesSelect<TFieldValues extends FieldValues>({
    name,
    control,
    provider,
}: BylinesSelectProps<TFieldValues>) {
    const { field, fieldState } = useController({ name, control });

    const [selectedOptions, setSelectedOptions] = useState(null);

    const {
        isFetching: areAuthorsFetching,
        isLoading: areAuthorsLoading,
        onAuthorsGet,
        data,
    } = useAuthorsGet({
        provider,
        delay: 200,
    });

    const { isFetching, isLoading, onAuthorsSearch, searchResults } = useAuthorsSearch({ provider, delay: 200 });

    useEffect(() => {
        onAuthorsGet(field.value ? JSON.parse(field.value) : []);
    }, []);

    useEffect(() => {
        setSelectedOptions(data?.getAuthors?.map((d) => ({ value: d.id, label: d.title })));
    }, [data]);

    const options = searchResults?.searchAuthors.map((author) => ({ value: author.id, label: author.title })) ?? [];

    return (
        <Select
            value={selectedOptions}
            onChange={(val) => {
                setSelectedOptions(val);
                field.onChange(val.map((v) => v.value));
            }}
            options={options}
            error={fieldState.error?.message}
            placeholder={selectedOptions ? 'Search for byline (try adding two or more)' : <LoadingMessage />}
            isLoading={isFetching || isLoading || areAuthorsFetching || areAuthorsLoading}
            onInputChange={onAuthorsSearch}
            components={{ LoadingMessage, LoadingIndicator }}
            backspaceRemovesValue={false}
            splitButton={false}
            isMulti
            isClearable
        />
    );
}
