import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { reportMessageToSentry } from 'lib/error';
import { CategorySelect } from 'components/core/hookForm/CategorySelect';
import { toggleCategoryVisibility } from 'store/categories/actions';
import { LabeledContainer, Button, Divider, Notification, Input } from '@schibsted-svp/react-ui';
import { FormProvider } from 'react-hook-form';
import { RichDescription } from 'components/core/hookForm/RichDescription';
import { SlugfyingInput } from 'components/core/SlugfyingInput/SlugfyingInput';
import Tooltip from 'components/ui/Tooltip';
import { MdInfo } from 'react-icons/md';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import { deleteCategoryPreview } from 'store/categories/slice';
import { PreviewCategory } from './PreviewCategory';

import { MetadataInputs } from './MetadataInputs';
import { DynamicFields } from './DynamicFields';
import PodcastRssUrl from './PodcastRssUrl';
import { SeasonsForm } from './SeasonsForm/SeasonsForm';
import { Images } from './Images';
import { PodcastProperties } from './PodcastProperties';
import { PreviewImage } from './PreviewImage';
import { useFetchCategory, useGetCategoryPromoUrl } from './hooks';
import { useCategoryForm, useCategoryFormSubmit } from './hooks/useCategoryForm';
import { useInitialValues } from './hooks/useInitialValues';
import type { CategoryRouteParams } from './types';

import css from './Form.module.scss';
import { SeriesProperties } from './SeriesProperties';

export function CategoryForm() {
    const [isTogglingVisibility, setIsTogglingVisibility] = useState(false);
    const userPermissions = useUserPermissions();

    const dispatch = useDispatch();
    const { category, mode, newsroom: provider } = useParams<CategoryRouteParams>();
    const isCreationMode = mode === 'new';
    const initialValues = useInitialValues({ provider, categoryId: Number(category), isCreationMode });
    const categoryId = initialValues.id;
    const promoUrl = useGetCategoryPromoUrl({ categoryId, provider });
    const { showCategory } = initialValues;
    const { formApi } = useCategoryForm(initialValues);
    const { register, handleSubmit, formState, control } = formApi;

    const onSubmit = useCategoryFormSubmit({ handleSubmit, initialValues, categoryId, provider, mode, isCreationMode });

    const { isSubmitting } = formState;
    const isArchived = showCategory === false;

    const { isFetchingCategory, isCategoryFetchError } = useFetchCategory({ provider, categoryId });

    const inheritedDynamicFields = (initialValues?.dynamicFieldsMetadata || []).filter(({ inherited }) => inherited);

    const onArchiveToggleClick = async () => {
        if (!categoryId) {
            return;
        }
        try {
            setIsTogglingVisibility(true);
            await dispatch(toggleCategoryVisibility(provider, categoryId));
            setIsTogglingVisibility(false);
            Notification.notify.success(
                `Category ${categoryId} has been ${isArchived ? 'un' : ''}archived successfully`
            );
        } catch (error) {
            const ERROR_MESSAGE = `Failed to ${isArchived ? 'un' : ''}archive category`;
            reportMessageToSentry({
                message: ERROR_MESSAGE,
                extras: {
                    error,
                },
            });
            setIsTogglingVisibility(false);
            Notification.notify.error(ERROR_MESSAGE);
        }
    };

    const onDeletePreview = () => {
        dispatch(deleteCategoryPreview({ provider, categoryId }));
    };

    return (
        <FormProvider {...formApi}>
            <form onSubmit={onSubmit} className={css.container}>
                <div className={css.images}>
                    <LabeledContainer label="POSTER IMAGE" data-testid="posterContainer">
                        <PreviewImage name="additional.image" control={control} />
                    </LabeledContainer>
                    <Images />
                    <Divider margin="0" />
                    <LabeledContainer
                        className={css.preview}
                        label={
                            <span>
                                Custom preview{' '}
                                <Tooltip
                                    theme="light"
                                    content="Video must be in 1080p resolution"
                                    placement="top"
                                    offset={[0, 10]}
                                >
                                    <span className={css.infoIcon}>
                                        <MdInfo />
                                    </span>
                                </Tooltip>
                            </span>
                        }
                        data-testid="previewContainer"
                    >
                        <PreviewCategory promoUrl={promoUrl} provider={provider} categoryId={categoryId} />
                        {promoUrl ? (
                            <Button
                                type="button"
                                onClick={onDeletePreview}
                                variant="standard"
                                size="small"
                                className={css.deletePreview}
                            >
                                Delete
                            </Button>
                        ) : null}
                    </LabeledContainer>
                </div>
                <div className={css.fields}>
                    <LabeledContainer label="CATEGORY TITLE" strict>
                        <SlugfyingInput fieldName="title" fieldToSlug="additional.shortPath" />
                    </LabeledContainer>
                    <LabeledContainer label="SHORT PATH" strict>
                        <Input {...register('additional.shortPath')} autoComplete="off" />
                    </LabeledContainer>
                    <LabeledContainer label="DESCRIPTION" strict>
                        <RichDescription
                            name="additional.description"
                            richName="additional.metadata.podcast_richDescription.value"
                            control={control}
                        />
                    </LabeledContainer>
                    {categoryId && (
                        <LabeledContainer label="PARENT CATEGORY" strict>
                            <CategorySelect
                                name="parentId"
                                control={control}
                                provider={provider}
                                filterIds={[categoryId]}
                                filterArchived
                                isClearable
                            />
                        </LabeledContainer>
                    )}
                </div>
                <div className={css.others}>
                    {categoryId && (
                        <>
                            <SeasonsForm categoryId={categoryId} provider={provider} />
                            <Divider margin="0" />
                        </>
                    )}
                    <MetadataInputs
                        provider={provider}
                        categoryId={categoryId}
                        metadataFromAPI={initialValues?.simpleMetadata || []}
                        isFetching={isFetchingCategory}
                        error={isCategoryFetchError}
                        formApi={formApi}
                    />
                    <Divider margin="0" />
                    {categoryId && <SeriesProperties provider={provider} categoryId={categoryId} formApi={formApi} />}
                    <DynamicFields
                        inheritedDynamicFields={inheritedDynamicFields}
                        isFetching={isFetchingCategory}
                        error={isCategoryFetchError}
                        formApi={formApi}
                    />
                    <Divider margin="0" />
                    <PodcastProperties provider={provider} categoryId={categoryId} isCreationMode={isCreationMode} />
                    <Divider margin="0" />
                    {categoryId && (
                        <>
                            <PodcastRssUrl categoryId={categoryId} provider={provider} />
                            <Divider margin="0" />
                        </>
                    )}
                    {!isCreationMode && (
                        <Button
                            className={css.archiveButton}
                            onClick={onArchiveToggleClick}
                            variant="standard"
                            disabled={!userPermissions?.isUser}
                            type="button"
                            loading={isTogglingVisibility}
                            size="small"
                        >
                            {isArchived ? 'Unarchive' : 'Archive'} Category
                        </Button>
                    )}
                    <Button
                        loading={isSubmitting}
                        className={css.saveButton}
                        disabled={
                            !userPermissions?.isUser ||
                            isCategoryFetchError ||
                            isFetchingCategory ||
                            isTogglingVisibility
                        }
                        type="submit"
                    >
                        {isCreationMode ? 'Create' : 'Update'}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
}
