import { getNewsroomConfig } from 'config';

export type Option = {
    label: string;
    value: string;
};

// tmp helper type until config is typed properly
type NewsroomConfig = { liveLogoOverlays?: Option[] } | undefined;

export function getLogoOverlayOptions(provider: string): Option[] {
    const liveLogoOverlays = (getNewsroomConfig(provider) as NewsroomConfig)?.liveLogoOverlays || [];
    return [{ label: 'No logo', value: '' }, ...liveLogoOverlays];
}

export function hasLogoOverlayOptions(provider: string) {
    return (getNewsroomConfig(provider) as NewsroomConfig)?.liveLogoOverlays?.length > 0;
}
