import classnames from 'classnames/bind';
import { memo, useEffect, useRef } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { ViewportList, ViewportListRef } from 'react-viewport-list';

import type { Maybe } from 'types';
import type { SubtitlesFormValues } from './types';
import { SubtitleCueField } from './SubtitleCueField';
import css from './SubtitleCuesField.module.scss';

// a half of the field height in px to calculate the offset (not real one, but good enough to present the element in
// the center of the viewport)
const FIELD_HALF_HEIGHT = 45;

const cln = classnames.bind(css);

type SubtitleCuesFieldProps = {
    className?: string;
    displayedIndex?: number;
    formApi: UseFormReturn<SubtitlesFormValues>;
    seekPlayer: (time: number) => void;
};

export const SubtitleCuesField = memo<SubtitleCuesFieldProps>(function SubtitleCuesField({
    className,
    displayedIndex,
    formApi,
    seekPlayer,
}) {
    const cuesListRef = useRef<Maybe<ViewportListRef>>(null);
    const viewportRef = useRef<Maybe<HTMLDivElement>>(null);
    const { control } = formApi;
    const { fields, insert, remove } = useFieldArray({
        control,
        name: 'cues',
        keyName: 'key',
    });

    useEffect(() => {
        const { current: cuesList } = cuesListRef;
        const { current: viewport } = viewportRef;
        if (!viewport || !cuesList || displayedIndex == null) {
            return;
        }

        cuesList.scrollToIndex({
            index: displayedIndex,
            offset: -(viewport.clientHeight / 2 - FIELD_HALF_HEIGHT),
        });
    }, [displayedIndex]);

    return (
        <div className={cln(css.list, className)} ref={viewportRef}>
            <ViewportList ref={cuesListRef} viewportRef={viewportRef} items={fields}>
                {(field, index) => (
                    <SubtitleCueField
                        key={field.key}
                        control={control}
                        index={index}
                        highlighted={index === displayedIndex}
                        insert={insert}
                        remove={remove}
                        seekPlayer={seekPlayer}
                    />
                )}
            </ViewportList>
        </div>
    );
});
