import * as ReactGA from 'react-ga';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from 'config';
import { Button, Dialog, Notification } from '@schibsted-svp/react-ui';
import { Dropzone } from 'components/video/Dropzone/Dropzone';
import { hasPlayback } from 'models/asset';
import { getAsset } from 'store/assets/selectors';
import { uploadVideo } from 'store/video/upload/actions';
import { saveAsset } from 'store/assets/actions';
import useToggle from 'hooks/useToggle';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import { getAssetChangesBeforeReplace } from './helper';
import { TranscodingOptions } from '../TranscodingOptions/TranscodingOptions';

import validator from './ChangeStreamUrlsDialog.validator';
import css from './ChangeStreamUrlsDialog.module.scss';

const { allowedVideoTypes } = config;

type ChangeStreamUrlsDialogProps = {
    assetId: number;
    provider: string;
    disabled?: boolean;
};

const PRESERVE_PREVIEW_DEFAULT_STATE = true;
const OVERLAY_DEFAULT_STATE = '';

export function ChangeStreamUrlsDialog({ assetId, provider, disabled = false }: ChangeStreamUrlsDialogProps) {
    const [opened, toggleOpened] = useToggle();
    const [preservePreview, setPreservePreview] = useState(PRESERVE_PREVIEW_DEFAULT_STATE);
    const asset = useSelector((state) => getAsset(state, { id: assetId, provider }));
    const userPermissions = useUserPermissions();
    const dispatch = useDispatch();

    const [overlay, setOverlay] = useState();

    const onUpload = async (files: File[]) => {
        const result = validator(files);

        if (!result.isValid) {
            Notification.notify.error(result.message);
            return;
        }

        toggleOpened();
        const changes = getAssetChangesBeforeReplace(asset);

        if (changes) {
            await new Promise((resolve, reject) => {
                saveAsset({
                    id: assetId,
                    provider,
                    changes,
                    resolve,
                    reject,
                });
            });
        }
        dispatch(
            uploadVideo(provider, assetId, result.file, {
                preservePoster: true,
                preservePreview,
                overlayUrl: overlay,
            })
        );
        setPreservePreview(PRESERVE_PREVIEW_DEFAULT_STATE);
        setOverlay(OVERLAY_DEFAULT_STATE);

        ReactGA.modalview('replace-video');
    };

    const DialogTrigger = (
        <Button
            onClick={toggleOpened}
            variant="standard"
            size="small"
            type="button"
            disabled={!userPermissions?.isUser || disabled}
        >
            Replace
        </Button>
    );

    return (
        <Dialog onClose={toggleOpened} heading="Replace" isOpen={opened} dialogTrigger={DialogTrigger}>
            <Dialog.Section className={css.content}>
                <Dropzone accept={allowedVideoTypes} onUpload={onUpload} className={css.dropzone} />
                {hasPlayback(asset) && '*by uploading file you will reset the playback'}
            </Dialog.Section>
            <Dialog.Section className={css.options}>
                <TranscodingOptions
                    provider={provider}
                    overlayProps={{ overlay, setOverlay }}
                    previewProps={{ preservePreview, setPreservePreview }}
                />
            </Dialog.Section>

            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={toggleOpened}>
                    Cancel
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}
