/**
 *
 * @returns {boolean|void}
 */
function removeDocumentSelections() {
    const selection = document.getSelection();
    return selection.rangeCount && selection.empty();
}

export default removeDocumentSelections;
