import type { ChangeEvent } from 'react';
import { useController, type Control, type FieldValues, type FieldPath } from 'react-hook-form';
import { Input, Checkbox } from '@schibsted-svp/react-ui';
import { MdInfo } from 'react-icons/md';
import Tooltip from 'components/ui/Tooltip';
import css from './RichDescription.module.scss';

type RichDescriptionProps<TFieldValues extends FieldValues> = {
    name: FieldPath<TFieldValues>;
    richName: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    allowRich?: boolean;
};

export function RichDescription<TFieldValues extends FieldValues>({
    name,
    richName,
    control,
    allowRich = true,
}: RichDescriptionProps<TFieldValues>) {
    const { field: descriptionField, fieldState: descriptionFieldState } = useController({ name, control });

    const { field: richDescriptionField, fieldState: richDescriptionFieldState } = useController({
        name: richName,
        control,
    });

    const isRich = Boolean(richDescriptionField.value) || richDescriptionField.value === '';
    const errorMessage = (isRich ? richDescriptionFieldState : descriptionFieldState).error?.message;

    const onRichCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        richDescriptionField.onChange(event.target.checked ? descriptionField.value || '' : null);
        richDescriptionField.onBlur();
    };

    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        descriptionField.onChange(event);
        if (isRich) {
            richDescriptionField.onChange(event);
        }
    };

    const onDescriptionBlur = () => {
        descriptionField.onBlur();
        if (isRich) {
            richDescriptionField.onBlur();
        }
    };

    return (
        <>
            <Input
                type="textarea"
                {...descriptionField}
                value={richDescriptionField.value || descriptionField.value || ''}
                onChange={onDescriptionChange}
                onBlur={onDescriptionBlur}
                error={errorMessage}
                rows={4}
            />
            {allowRich && (
                <div className={css.checkboxContainer}>
                    <Checkbox
                        label="Allow rich markup"
                        checked={isRich}
                        onChange={onRichCheckboxChange}
                        containerClassName={css.checkbox}
                    />
                    <Tooltip
                        theme="dark"
                        content="Rich markup will be available in podcast RSS"
                        placement="right"
                        offset={[0, 12]}
                    >
                        <span className={css.infoIcon}>
                            <MdInfo />
                        </span>
                    </Tooltip>
                </div>
            )}
        </>
    );
}
