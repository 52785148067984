import { Dialog } from '@schibsted-svp/react-ui';
import type { Control, UseFormSetValue } from 'react-hook-form';
import type { FormValues } from '../types';
import { ScheduleDialogFormVideo } from '../fields/ScheduleDialogFormVideo';
import { ScheduleDialogFormAudio } from '../fields/ScheduleDialogFormAudio';

import css from './ScheduleDialogForm.module.scss';

type ScheduleDialogFormProps = {
    control: Control<FormValues>;
    isAudio: boolean;
    setValue: UseFormSetValue<FormValues>;
};

export function ScheduleDialogForm({ control, isAudio, setValue }: ScheduleDialogFormProps) {
    return (
        <Dialog.Section className={css.container}>
            {isAudio ? (
                <ScheduleDialogFormAudio control={control} setValue={setValue} />
            ) : (
                <ScheduleDialogFormVideo control={control} />
            )}
        </Dialog.Section>
    );
}
