import { useState } from 'react';
import cln from 'classnames';
import css from './Rating.module.scss';
import Item from './RatingItem';

export function mapValue(chosenValue: number, options = []): number {
    const option = options?.find(({ value }) => value === chosenValue);

    return option?.label || chosenValue;
}

type onChangeType = (index: number) => void;
type Option = {
    label: string;
    value: number;
};
interface Props {
    onChange: onChangeType;
    value: number;
    options: Option[] | number[];
    containerClassName: string;
    itemClassName?: string;
}

function Rating({ onChange, value, options, containerClassName, itemClassName }: Props) {
    const [hoveredRate, setHoveredRate] = useState<number>(0);

    const onClick = (idx: number): void => onChange(idx);

    const hoverIn = (idx: number): void => setHoveredRate(idx);
    const hoverOut = (): void => setHoveredRate(0);

    return (
        <div className={css.container} onMouseOut={hoverOut} onBlur={hoverOut}>
            <ul className={cln(css.levels, containerClassName)}>
                {options.map((option) => (
                    <Item
                        key={option.value || option}
                        value={option.value || option}
                        clickedRate={value}
                        hoveredRate={hoveredRate}
                        onHover={hoverIn}
                        onClick={onClick}
                        itemClassName={itemClassName}
                    />
                ))}
            </ul>
            <span className={css.value}>{mapValue(value, options)}</span>
        </div>
    );
}

export default Rating;
