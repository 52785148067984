import { connect } from 'react-redux';
import { fetchLiveEncoders } from 'store/live/actions';
import {
    findLiveEncoderEvents,
    getMediaLiveChannel,
    isEncoderReady,
    isLiveRuntimeStarting,
    isLiveRuntimeStopping,
} from 'store/live/selectors';
import { saveAsset } from 'store/assets/actions';
import { canRenderAssetQualityForNewsroom } from 'services/asset-quality';
import { getAssetScore } from 'store/assets/selectors';
import { isElementalEncoder, isLive, isMediaLiveEncoder } from 'models/asset';

import config from 'config';
import VideoCardControls from './VideoCardControls';

const { publishingAsset: publishingAssetPlacement, publishingLiveAsset: publishingLiveAssetPlacement } =
    config.assetQuality.placements;

const mapStateToProps = (state, { asset }) => {
    const videoQualityPlacement = isLive(asset) ? publishingLiveAssetPlacement : publishingAssetPlacement;
    const events = isElementalEncoder(asset)
        ? findLiveEncoderEvents(state, { assetId: asset.id, provider: asset.provider })
        : [];
    const channel = isMediaLiveEncoder(asset)
        ? getMediaLiveChannel(state, { assetId: asset.id, provider: asset.provider })
        : undefined;

    return {
        events,
        channel,
        ready: isEncoderReady(state, { assetId: asset.id, provider: asset.provider }),
        isStarting: isLiveRuntimeStarting(state, { assetId: asset.id, provider: asset.provider }),
        isStopping: isLiveRuntimeStopping(state, { assetId: asset.id, provider: asset.provider }),
        requireConfirmation:
            getAssetScore(state, asset.id, asset.provider) < 1 &&
            canRenderAssetQualityForNewsroom(asset.provider, videoQualityPlacement, asset.assetType),
    };
};

export default connect(mapStateToProps, {
    fetchLiveEncoders,
    saveAsset,
})(VideoCardControls);
