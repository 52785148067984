import { useSelector } from 'react-redux';
import { useWatch, type UseFormReturn } from 'react-hook-form';
import { getLiveEncoders } from 'store/live/selectors';
import type { LiveEvent } from 'services/admin-bff-sdk/generated';
import type { EncoderInputFormData } from './types';

export const useScheduleError = ({
    formApi: { control },
}: {
    formApi: UseFormReturn<EncoderInputFormData>;
}): { message: string; assetId: number }[] => {
    const [enableAssetSchedule, encoderStartTime, encoderDuration, source] = useWatch({
        name: ['enableAssetSchedule', 'encoderStartTime', 'encoderDuration', 'source'],
        control,
    });

    const liveEncoders = useSelector((state) => getLiveEncoders(state));

    if (!source?.encoderId || !source?.inputId) return [];
    if (enableAssetSchedule && encoderStartTime) {
        const encoderEvents: LiveEvent[] = liveEncoders[source.encoderId]?.events || {};
        const inputEvents = Object.values(encoderEvents).filter((event) => event?.input?.id === source.inputId);

        return inputEvents
            .filter((event) => {
                const { startTime, endTime } = event.schedule;
                const assetLiveDurationMetadata = Number(event.asset.additional.metadata.liveDuration);

                if (event.status.toLowerCase() === 'running') {
                    return endTime > encoderStartTime;
                }

                // Math.max and Math.min are used to check if two ranges overlap
                // https://stackoverflow.com/a/12888920
                return (
                    Math.max(startTime, encoderStartTime) <=
                    Math.min(startTime + assetLiveDurationMetadata, encoderStartTime + encoderDuration)
                );
            })
            .map((event) => ({
                message: `Encoder ${source.label.split('(scheduled')?.[0]} is already scheduled for this time range on asset`,
                assetId: event.asset.id,
            }));
    }
    return [];
};
