import { useState } from 'react';
import classnames from 'classnames/bind';
import { InputError } from '@schibsted-svp/react-ui';
import { Dropzone } from 'components/video/Dropzone/Dropzone';

import { useController } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import type { CategoryData, ImageSource } from './types';
import css from './PreviewImage.module.scss';

const cln = classnames.bind(css);

type PreviewImageProps = {
    name: 'additional.image' | `additional.images.${number}.url`;
    control: Control<CategoryData>;
};

function getSrc(src: ImageSource) {
    return src instanceof File || src instanceof Blob ? URL.createObjectURL(src) : src;
}

function revokeImageURL({ currentTarget }: { currentTarget: HTMLImageElement }) {
    if (currentTarget.src.startsWith('blob')) {
        URL.revokeObjectURL(currentTarget.src);
    }
}

export function PreviewImage({ name, control }: PreviewImageProps) {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({ name, control });

    const [hovered, setHovered] = useState(false);

    const handleOnChange = (files: File[]) => {
        onChange(files[0]);
    };

    function handleMouseEnter() {
        setHovered(true);
    }

    function handleMouseLeave() {
        setHovered(false);
    }

    return (
        <div>
            <div
                className={cln('container', { hovered: value && hovered })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {value && <img className={css.image} src={getSrc(value)} alt="" onLoad={revokeImageURL} />}
                <Dropzone className={cln('dropzone', { hidden: value })} onUpload={handleOnChange} />
            </div>
            <InputError>{error?.message}</InputError>
        </div>
    );
}
