import { useDispatch, useSelector } from 'react-redux';
import { useController, type Control, type UseFormRegister } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';

import { uploadVideoPoster, revokeVideoPosterPreview } from 'store/video/upload/actions';
import { isReady } from 'store/video/selectors';
import { isVideoPosterUploading, getPosterPreviewUrl } from 'store/video/upload/selectors';
import { isAudio, isLive, wasLive, isLivingThumbnailAvailable } from 'models/asset';
import useToggle from 'hooks/useToggle';

import CropImageDialog from 'components/video/CropImageDialog';
import PosterPreview from 'components/video/VideoPoster/PosterPreview';
import VideoFrameForm from 'components/video/VideoPoster/VideoFrameForm';
import SuggestedThumbnails from 'components/video/VideoPoster/SuggestedThumbnails';
import PosterUploader from 'components/video/VideoPoster/PosterUploader';
import { LivingThumbnail } from './LivingThumbnail';

import { useFetchThumbnails, useUploadedPosterChange, useCategoryChange } from './hooks/poster';
import type { FormValues } from '../types';

import css from './Poster.module.scss';

type PosterProps = {
    asset: Asset;
    control: Control<FormValues>;
    register: UseFormRegister<FormValues>;
};

export function Poster({ asset, control, register }: PosterProps) {
    const { provider, id } = asset;

    const dispatch = useDispatch();

    const uploading = useSelector((state) => isVideoPosterUploading(state, { provider, id }));
    const ready = useSelector((state) => isReady(state, { id, provider }));

    const [openedCropDialog, toggleOpenedCropDialog] = useToggle();

    const {
        field: { value, onChange },
        fieldState: { isDirty },
        formState: {
            defaultValues: {
                images: { main: defaultValue },
            },
        },
    } = useController({ name: 'images.main', control });

    const { thumbnails, areThumbnailsLoading } = useFetchThumbnails({ asset });

    useUploadedPosterChange({ provider, id, onChange });
    useCategoryChange({ asset, control, onChange });

    const poster = useSelector((state) => getPosterPreviewUrl(state, { provider, id })) || value;

    const onVideoPosterUpload = (file: File) => {
        dispatch(uploadVideoPoster(provider, id, file));
    };

    const handleDeleteUploadedImage = () => {
        onChange(defaultValue);
        dispatch(revokeVideoPosterPreview(provider, id));
    };

    return (
        <section className={css.wrapper} data-testid="imagePoster">
            {!isAudio(asset) && (
                <div className={css.greybox}>
                    <div className={css.frameSelector}>
                        <VideoFrameForm
                            disabled={uploading || !ready}
                            isLive={isLive(asset)}
                            onVideoPosterUpload={onVideoPosterUpload}
                        />
                    </div>
                    <div className={css.suggestions}>
                        {isLivingThumbnailAvailable(asset) ? (
                            <LivingThumbnail wasLive={wasLive(asset)} register={register} />
                        ) : (
                            <SuggestedThumbnails id={id} thumbnails={thumbnails} loading={areThumbnailsLoading} />
                        )}
                    </div>
                </div>
            )}
            <div className={css.poster}>
                <PosterPreview
                    loading={uploading}
                    src={poster}
                    toggleCropImageDialog={toggleOpenedCropDialog}
                    clearUploadedImage={isDirty ? handleDeleteUploadedImage : undefined}
                />
            </div>
            <div className={css.uploader}>
                <PosterUploader disabled={uploading || !ready} onVideoPosterUpload={onVideoPosterUpload} />
            </div>
            <CropImageDialog
                opened={openedCropDialog}
                src={poster}
                toggle={toggleOpenedCropDialog}
                onVideoPosterUpload={onVideoPosterUpload}
            />
        </section>
    );
}
