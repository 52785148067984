import { memo, useEffect } from 'react';
import { useHistory, useParams, useLocation, matchPath } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import BEM from 'lib/bem';
import { navigationTabExists, getNavigationTabs, getSearchTerm } from 'store/video/navigation-tabs/selectors';
import VideoTab from 'components/video/navigation/VideoTabs/VideoTab';

import { addNavigationTab, removeNavigationTab } from 'store/video/navigation-tabs/actions';

const bem = new BEM('video-navigation');

export const VideoTabs = memo(function VideoTabs() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { newsroom } = useParams();

    const searchTerm = useAppSelector(getSearchTerm);
    const opened = useAppSelector(getNavigationTabs);

    useEffect(() => {
        const { params = {} } = matchPath(location.pathname, { path: '/:newsroom/assets/:id([0-9]+)' }) || {};
        const { id } = params;

        if (id && newsroom && !navigationTabExists({ id, provider: newsroom }, opened)) {
            dispatch(addNavigationTab(Number(id), newsroom));
        }
    }, [location, newsroom, dispatch, opened]);

    const getNewLocation = () => {
        const newLocation = {
            pathname: `/${newsroom}/assets`,
            search: '',
        };

        const searchParams = new URLSearchParams();

        if (searchTerm) {
            searchParams.append('query', searchTerm);
        }

        newLocation.search = `?${searchParams.toString()}`;

        return newLocation;
    };

    const handleTabClose = ({ id, provider, active }) => {
        if (active) {
            const newLocation = getNewLocation();
            history.push(newLocation);
        }

        dispatch(removeNavigationTab(Number(id), provider));
    };

    return (
        <div className={bem.block({ scrollable: true })}>
            {opened.map(({ id, provider, title, type }) => (
                <VideoTab
                    key={`${provider}-${id}`}
                    id={id}
                    provider={provider}
                    type={type}
                    title={title}
                    onClose={handleTabClose}
                />
            ))}
        </div>
    );
});
