import { useSelector } from 'react-redux';
import DownloadControls from 'components/video/edit/Form/DownloadControls';
import LiveControls from 'components/video/live/LiveControls';
import { areTranscodingsDone } from 'store/video/selectors';
import { isVideoReadyForDownloading } from 'store/video/stream-conversion/selectors';
import { isLive, isAudio, hasTextToSpeech } from 'models/asset';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { UseFormReturn, UseFieldArrayReturn } from 'react-hook-form';
import { Submit } from './Submit';
import { PodcastControls } from './PodcastControls';
import { TTSControls } from './TTSControls';
import { useAssetForm } from '../hooks/useAssetForm';
import type { FormValues } from '../types';

import css from './FormMainControls.module.scss';

type FormMainControlsProps = {
    asset: Asset;
    formApi: UseFormReturn<FormValues>;
    onSubmit: ReturnType<typeof useAssetForm>['onSubmit'];
    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;
};

export function FormMainControls({ asset, formApi, onSubmit, simpleMetadataControls }: FormMainControlsProps) {
    const isReadyForDownloading = useSelector((state) => isVideoReadyForDownloading(state, asset));
    const isVideoDone = useSelector((state) => areTranscodingsDone(state, asset));

    const submitProps = {
        asset,
        onSubmit,
        formApi,
    };

    const isDownloadAvailable = isReadyForDownloading && isVideoDone;
    if (isDownloadAvailable) {
        return (
            <>
                <DownloadControls id={asset.id} provider={asset.provider} />
                <div className={css.header}>
                    <Submit {...submitProps} variant="primary" />
                </div>
            </>
        );
    }

    if (isLive(asset)) {
        return (
            <LiveControls assetId={asset.id} provider={asset.provider}>
                <Submit {...submitProps} variant="standard" />
            </LiveControls>
        );
    }

    if (isAudio(asset) && !hasTextToSpeech(asset)) {
        return (
            <PodcastControls asset={asset} formApi={formApi} simpleMetadataControls={simpleMetadataControls}>
                <Submit {...submitProps} variant="standard" />
            </PodcastControls>
        );
    }

    if (isAudio(asset) && hasTextToSpeech(asset)) {
        return (
            <TTSControls register={formApi.register}>
                <Submit {...submitProps} variant="standard" />
            </TTSControls>
        );
    }

    return (
        <div className={css.header}>
            <Submit {...submitProps} variant="primary" />
        </div>
    );
}
