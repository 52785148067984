export function getEncoderOption(encoderName, inputId, encoderOptions = []) {
    if (!encoderName || !inputId) {
        return null;
    }

    const [encoder] = encoderOptions.filter((option) => option.label === encoderName);
    const source = encoder?.options?.find((op) => op.inputId === inputId) || null;
    return source;
}

export function getInitialSources(events, encoderOptions) {
    const { encoder, input } = events[0] || {};
    const { encoder: backupEncoder, input: backupInput } = events[1] || {};
    const initialSource = getEncoderOption(encoder?.name, input?.id, encoderOptions);
    const initialBackup = getEncoderOption(backupEncoder?.name, backupInput?.id, encoderOptions);

    if (initialBackup) {
        return { initialSource, initialBackup };
    }
    return { initialSource };
}
