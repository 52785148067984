import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { playlistsCreate, playlistsUpdate } from 'store/playlists/items';
import { reportMessageToSentry } from 'lib/error';
import { Notification } from '@schibsted-svp/react-ui';
import {
    getPreferredPlaylistType,
    getPlaylistType,
    getPlaylistLabels,
    isPodcastOnlyPlaylist,
    mergePlaylistLabels,
    mapToBackendPlaylistItemsNotation,
    adjustItemsIndexes,
    isPlaylistOfPlaylists,
} from './utils';
import { resolver } from './validator';
import { Playlist, FormValues } from './types';

export const useFormValues = ({ playlist }: { playlist: Playlist }): FormValues =>
    useMemo(() => {
        if (!playlist) {
            return {
                name: '',
                header: null,
                query: '',
                limit: 10,
                items: [],
                labels: [],
                type: getPreferredPlaylistType(),
                searchQuery: '',
            };
        }

        const searchQuery = new URLSearchParams(playlist.searchQuery || '');
        return {
            name: playlist.name,
            header: playlist.header,
            query: searchQuery.get('query'),
            limit: Number(searchQuery.get('limit')) || 10,
            items: isPlaylistOfPlaylists(playlist) ? playlist.playlists : playlist.assets,
            searchQuery: playlist.searchQuery,
            type: getPlaylistType(playlist),
            labels: getPlaylistLabels(playlist),
            isPodcastPlaylist: isPodcastOnlyPlaylist(playlist),
        };
    }, [playlist]);

export const usePlaylistForm = ({ playlist }: { playlist: Playlist }) => {
    const formValues = useFormValues({ playlist });
    const formApi = useForm<FormValues>({
        defaultValues: formValues,
        values: formValues,
        resolver,
        mode: 'onChange',
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const { handleSubmit } = formApi;

    const onSubmit = (mode: 'new' | 'edit', provider: string, isRegularPlaylist: boolean) => async (values) => {
        try {
            const valuesToSend = {
                id: playlist?.id,
                provider,
                name: values.name,
                header: values.header,
                labels: mergePlaylistLabels(values.labels, values.type, values.isPodcastPlaylist),
                searchQuery: values.searchQuery,
                items: mapToBackendPlaylistItemsNotation(values.items),
                ...(isRegularPlaylist && { assets: adjustItemsIndexes(values.items) }),
            };

            if (mode === 'new') {
                const { playlist: newPlaylist } = await dispatch(playlistsCreate(valuesToSend));
                Notification.notify.success('Successfully saved new playlist');
                history.push(`/${provider}/playlists/edit/${newPlaylist.id}`);
                return;
            }
            await dispatch(playlistsUpdate(valuesToSend));
            Notification.notify.success('Playlist updated successfully');
        } catch (error) {
            const ERROR_MESSAGE = 'There was an error during playlist submission';
            reportMessageToSentry({
                message: ERROR_MESSAGE,
                extras: { error },
            });
            Notification.notify.error(ERROR_MESSAGE);
        }
    };

    return {
        formApi,
        onSubmit: (mode: 'new' | 'edit', provider: string, isRegularPlaylist: boolean) =>
            handleSubmit(onSubmit(mode, provider, isRegularPlaylist)),
    };
};
