import { useState } from 'react';
import PropTypes from 'prop-types';

import { Notification } from '@schibsted-svp/react-ui';
import InlineInput from 'components/ui/Form/InlineInput';

function TitleCell({ provider, title, id, saveAsset }) {
    const [assetTitle, setAssetTitle] = useState(title);

    const handleSaveError = (assetId, oldTitle) => () => {
        setAssetTitle(oldTitle);
        Notification.notify.error(`There was an error while updating the title for asset ${assetId}`);
    };
    const isValidTitle = (value) => value.trim().length > 1;

    const handleSave = () => {
        const assetTitleTrimmed = assetTitle.trim();
        if (title === assetTitleTrimmed) {
            return;
        }

        if (!isValidTitle(assetTitleTrimmed)) {
            Notification.notify.error(`Title must have at least 2 characters`);
            setAssetTitle(title);
            return;
        }

        const changes = { title: assetTitleTrimmed };

        saveAsset({
            id,
            provider,
            changes,
            reject: handleSaveError(id, title),
        });
    };

    const resetField = () => {
        setAssetTitle(title);
    };

    return (
        <InlineInput
            value={assetTitle}
            validate={isValidTitle}
            onReset={resetField}
            onEdit={setAssetTitle}
            onSave={handleSave}
        />
    );
}
TitleCell.propTypes = {
    provider: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    saveAsset: PropTypes.func.isRequired,
};

export default TitleCell;
