import { FETCH_THUMBNAILS, FETCH_THUMBNAILS_SUCCESS, FETCH_THUMBNAILS_ERROR } from './actions';

const thumbnails = (state = {}, action) => {
    switch (action.type) {
        case FETCH_THUMBNAILS: {
            const { provider, id } = action;
            return {
                ...state,
                [`${provider}-${id}`]: {
                    status: 'loading',
                },
            };
        }

        case FETCH_THUMBNAILS_SUCCESS: {
            const { provider, id, thumbnails: items } = action;
            return {
                ...state,
                [`${provider}-${id}`]: {
                    status: 'success',
                    items,
                },
            };
        }

        case FETCH_THUMBNAILS_ERROR: {
            const { provider, id, error } = action;
            return {
                ...state,
                [`${provider}-${id}`]: {
                    ...state[`${provider}-${id}`],
                    status: 'error',
                    error,
                },
            };
        }

        default:
            return state;
    }
};

export default thumbnails;
