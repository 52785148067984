import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Connector, Input } from '@schibsted-svp/react-ui';
import { FaSearch } from 'react-icons/fa';
import './Search.scss';

export default class SearchForm extends Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    handleOnChange = (event) => {
        this.props.onChange(event.target.value);
    };

    handleOnSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit();
    };

    render() {
        return (
            <form onSubmit={this.handleOnSubmit} className="af_SearchForm" data-testid="topSearchForm">
                <Connector className="af_SearchForm__connector">
                    <Input
                        className="af_SearchForm__input"
                        placeholder="Search"
                        onChange={this.handleOnChange}
                        value={this.props.value}
                    />
                    <Button
                        iconOnly
                        className="af_SearchForm__button"
                        type="submit"
                        variant="standard"
                        data-testid="topSearchFormButton"
                    >
                        <FaSearch className="af_SearchForm__icon" />
                    </Button>
                </Connector>
            </form>
        );
    }
}
