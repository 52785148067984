import { useEffect, useState, createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DAY } from 'lib/time';

import * as PropTypes from 'prop-types';
import {
    getMetadata,
    isLive,
    isScheduled,
    isStartedAfterSpecifiedTime,
    isExternalEncoder,
    isElementalEncoder,
    isMediaLiveEncoder,
} from 'models/asset';
import { isVideoDone } from 'store/video/upload/selectors';
import { getEncoderState } from 'store/live/selectors';
import adminBff from 'services/admin-bff-client';

import { getAsset } from 'store/assets/selectors';

const PlayerUrlCheckerContext = createContext({ available: false, idleLive: false, shouldDisplay: false });

export const useUrlChecker = () => useContext(PlayerUrlCheckerContext);

export function PlayerUrlCheckerProvider({ id, provider, children }) {
    const asset = useSelector((state) => getAsset(state, { id, provider }));
    const encoder = useSelector((state) => getEncoderState(state, { assetId: id, provider }));
    const isLiveAsset = isLive(asset);
    const idleLive = isLiveAsset && encoder.isIdle;
    const isStartingLiveEventFailed = isLiveAsset && isElementalEncoder(asset) && encoder.hasFailed;
    const isMediaLiveChannelStarting = isLiveAsset && isMediaLiveEncoder(asset) && encoder.isStarting;

    const [available, setAvailable] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isInitialCheckState, setIsInitialCheckState] = useState(true);

    const { source } = getMetadata(asset);

    const isExternal = isExternalEncoder(asset);
    const isScheduledAsset = isScheduled(asset);
    const isStartedAsset = isStartedAfterSpecifiedTime(asset, DAY * 1000);

    const { hls: url } = asset.streamUrls;

    const isVideoReady = useSelector((state) => isVideoDone(state, { provider, id }));

    const isOldUnavailableStream = isStartedAsset && !available && counter > 0;

    const isEmptyOrExternalLiveAsset = isLiveAsset && (!source || isExternal);
    const shouldOmitLiveAsset = isEmptyOrExternalLiveAsset || isStartingLiveEventFailed || idleLive;

    const shouldWaitForChecking = !isVideoReady;
    const availabilityAlreadyChecked = available || isOldUnavailableStream;

    const avoidAssetStreamUrlChecking =
        availabilityAlreadyChecked || shouldWaitForChecking || shouldOmitLiveAsset || isMediaLiveChannelStarting;

    const shouldDisplayCheckingResults = isVideoReady && !isScheduledAsset && !isExternal && !isInitialCheckState;

    useEffect(() => {
        setAvailable(false);
    }, [url]);

    useEffect(() => {
        if (avoidAssetStreamUrlChecking) {
            return undefined;
        }
        let timeoutID;
        (async () => {
            try {
                const streamDuration = await adminBff.getStreamDuration({ provider, assetId: id });
                if (streamDuration === null) {
                    throw new Error('Stream duration not available yet');
                }

                setAvailable(true);
                if (isInitialCheckState) {
                    setIsInitialCheckState(false);
                }
            } catch {
                if (isLiveAsset) {
                    timeoutID = setTimeout(() => {
                        setCounter((c) => c + 1);
                    }, 2000);
                }
                if (isInitialCheckState) {
                    setIsInitialCheckState(false);
                }
            }
        })();

        return () => {
            clearTimeout(timeoutID);
        };
    }, [available, avoidAssetStreamUrlChecking, counter, id, isInitialCheckState, isLiveAsset, provider, source, url]);

    const value = useMemo(
        () => ({
            available,
            idleLive,
            shouldDisplay: shouldDisplayCheckingResults,
        }),
        [available, idleLive, shouldDisplayCheckingResults]
    );
    return <PlayerUrlCheckerContext.Provider value={value}>{children}</PlayerUrlCheckerContext.Provider>;
}

PlayerUrlCheckerProvider.propTypes = {
    id: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    children: PropTypes.node,
};

PlayerUrlCheckerProvider.defaultProps = {
    children: undefined,
};
