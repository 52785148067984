import classnames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AiFillInfoCircle } from 'react-icons/ai';
import { BsCircleFill } from 'react-icons/bs';
import { getTimeInSeconds, SECOND } from 'lib/time';
import { isLive, isScheduled, isStarted, isExternalOrRtmpEncoder, isAudio } from 'models/asset';
import useInterval from 'hooks/useInterval';
import { PurePlayer } from 'components/core/Player';
import { useUrlChecker } from 'components/core/Player/UrlCheckerContext';
import { FormPlayer } from 'components/video/edit/Form/Player';
import FormPlayerContext from 'components/video/edit/Form/Player/PlayerContext';
import { VideoSummary } from 'components/video/VideoSummary';
import { PlayerShortcuts } from 'components/core/Player/PlayerShortcuts';
import css from './AssetFormPlayer.module.scss';

const cln = classnames.bind(css);

/**
 * @param {Asset} asset
 * @param {Boolean} encoderStreamAvailability
 * @returns {Boolean}
 */
function hasLiveStreamStarted(asset, encoderStreamAvailability) {
    return encoderStreamAvailability && isLive(asset) && isStarted(asset);
}

function useStreamPreview(asset) {
    const { start: assetStartTime } = asset.flightTimes;
    const isLiveAsset = isLive(asset);
    const { available } = useUrlChecker();
    const isEncoderPreviewAvailable = isLiveAsset && available;
    const [isLivePreviewAvailable, setLivePreviewAvailable] = useState(hasLiveStreamStarted(asset, available));

    // check stream availability less frequently when stream starts in more than an hour
    const delay = getTimeInSeconds() - assetStartTime < -3600 ? SECOND * 5 : SECOND;

    useInterval(
        () => {
            if (getTimeInSeconds() >= assetStartTime && !isLivePreviewAvailable) {
                setLivePreviewAvailable(hasLiveStreamStarted(asset, available));
            }
        },
        isLive(asset) && !isLivePreviewAvailable ? delay : null
    );

    return {
        isLiveAsset,
        isEncoderPreviewAvailable,
        isLivePreviewAvailable,
    };
}

export default function AssetFormPlayer({ asset, highlighted }) {
    const { player } = useContext(FormPlayerContext);
    const [showLivePreview, setShowLivePreview] = useState(false);
    const { isLiveAsset, isEncoderPreviewAvailable, isLivePreviewAvailable } = useStreamPreview(asset);
    const externalOrRtmpEncoder = isExternalOrRtmpEncoder(asset);
    const showLivePreviewControls =
        isLiveAsset &&
        !externalOrRtmpEncoder &&
        (!isEncoderPreviewAvailable || (isScheduled(asset) && isEncoderPreviewAvailable));

    useEffect(() => {
        if (isEncoderPreviewAvailable) {
            player?.play(true);
        }
    }, [isEncoderPreviewAvailable, player]);

    const handleLivePreviewClick = () => {
        setShowLivePreview(true);
    };

    const handleEncoderPreviewClick = () => {
        setShowLivePreview(false);
    };

    return (
        <>
            <VideoSummary asset={asset} />

            <div
                className={cln(css.playerContainer, {
                    'playerContainer--livePreview': showLivePreview,
                    'playerContainer--controls': showLivePreviewControls,
                })}
            >
                {showLivePreviewControls && (
                    <div className={css.playerState}>
                        Now you are viewing <strong>{showLivePreview ? 'live preview' : 'encoder preview'}</strong>{' '}
                        <BsCircleFill
                            className={cln(css.sourceIndicator, {
                                'sourceIndicator--active': showLivePreview
                                    ? isLivePreviewAvailable
                                    : isEncoderPreviewAvailable,
                            })}
                        />
                    </div>
                )}

                <div className={css.playerPreview}>
                    {showLivePreviewControls && (
                        <div className={css.livePreview}>
                            <PurePlayer asset={asset} mute autoplay={isLivePreviewAvailable} play />

                            <div className={css.previewCover} onClick={handleLivePreviewClick} />
                            <div className={css.previewCaption}>
                                <h4 onClick={handleLivePreviewClick}>
                                    Live preview{' '}
                                    <BsCircleFill
                                        className={cln(css.sourceIndicator, {
                                            'sourceIndicator--active': isLivePreviewAvailable,
                                        })}
                                    />
                                </h4>
                            </div>
                            <div className={css.previewInfo}>
                                <AiFillInfoCircle className={css.previewInfoIcon} />
                                <p>Note that the video with DVR includes everything since encoder start</p>
                            </div>
                        </div>
                    )}

                    <div className={css.encoderPreview}>
                        <FormPlayer asset={asset} data-testid="assetFormPlayer" highlighted={highlighted} />

                        {showLivePreviewControls && (
                            <>
                                <div className={css.previewCover} onClick={handleEncoderPreviewClick} />
                                <div className={css.previewCaption}>
                                    <h4 onClick={handleEncoderPreviewClick}>
                                        Encoder preview{' '}
                                        <BsCircleFill
                                            className={cln(css.sourceIndicator, {
                                                'sourceIndicator--active': isEncoderPreviewAvailable,
                                            })}
                                        />
                                    </h4>
                                </div>
                                <div className={css.previewInfo}>
                                    <AiFillInfoCircle className={css.previewInfoIcon} />
                                    <p>Note that the video with DVR includes everything since encoder start</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className={css.playerShortcuts}>
                <PlayerShortcuts isAudio={isAudio(asset)} />
            </div>
        </>
    );
}

AssetFormPlayer.propTypes = {
    asset: PropTypes.object.isRequired,
    highlighted: PropTypes.bool.isRequired,
};
