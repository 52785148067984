import deepDiff from 'deep-diff';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { camelCaseToSentence } from 'lib/string';

const MAX_DEPTH_PATHS = [
    'category',
    'images',
    'flightTimes',
    'playback',
    'streamUrls',
    'streamConfiguration',
    'tags',
    'additional.chapters',
    'additional.featuredChapters',
    'additional.cuePoints',
    'additional.imageCaption',
    'additional.nextAsset',
    'additional.metadata',
    'additional.subtitles',
    'additional.sourceFile',
    'additional.tags',
    'additional.stories',
    'additional.access',
];

const CHANGES_ACTIONS_MAP = {
    N: 'added',
    D: 'removed',
    E: 'modified',
    A: 'modified',
} as const;

const FIELD_NAME_MAP = {
    created: 'created time',
    updated: 'updated time',
    published: 'published time',
    displays: 'views',
    'additional.chapters': 'highlights',
    'additional.cuePoints': 'ad breaks',
    'additional.settings.showInNewest': 'hide from list',
    'additional.settings.showAds': 'disable ads',
} as const;

type ChangeAction = (typeof CHANGES_ACTIONS_MAP)[keyof typeof CHANGES_ACTIONS_MAP];

// eslint-disable-next-line no-shadow
enum HistoryAction {
    CREATED = 'created',
    PUBLISHED = 'published',
    MODIFIED = 'modified',
}

export type HistoryDataChange = {
    path: string;
    action: ChangeAction;
};

export type HistoryData = {
    id: number;
    date: number;
    user: string;
    action: HistoryAction;
    allChanges: HistoryDataChange[];
    changes: HistoryDataChange[];
    assetData: Asset;
};

function getHistoryAction(asset: Asset, previousAsset: Asset): HistoryAction {
    if (asset.updated === asset.created) {
        return HistoryAction.CREATED;
    }
    if (asset.status === 'active' && asset.status !== previousAsset.status) {
        return HistoryAction.PUBLISHED;
    }
    return HistoryAction.MODIFIED;
}

function getHistoryChanges(asset: Asset, previousAsset: Asset): HistoryDataChange[] {
    const diffList = deepDiff.diff(previousAsset, asset);

    if (!diffList) {
        return [];
    }

    return diffList.reduce<HistoryDataChange[]>((all, diff) => {
        if (!diff.path) {
            return all;
        }

        const path = diff.path.join('.');
        const action = CHANGES_ACTIONS_MAP[diff.kind];

        return [...all, { path, action }];
    }, []);
}

function formatHistoryChanges(allChanges: HistoryDataChange[]): HistoryDataChange[] {
    return allChanges.reduce<HistoryDataChange[]>((all, change) => {
        const maxDepthPath = MAX_DEPTH_PATHS.find((_path) => change.path.includes(_path));
        const path = maxDepthPath || change.path;

        if (all.find((_change) => _change.path === path)) {
            return all;
        }

        return [...all, { ...change, path }];
    }, []);
}

export function prepareHistoryData(rawHistoryData: Asset[] | undefined): HistoryData[] {
    if (!rawHistoryData) {
        return [];
    }

    return rawHistoryData
        .slice()
        .sort((a, b) => b.updated - a.updated)
        .map((assetData, index, list) => {
            // previous asset is the next one on the list
            const previousAssetData = list[index + 1] || assetData;
            const { createdBy, lastEditedBy } = assetData.additional.settings || {};

            const action = getHistoryAction(assetData, previousAssetData);
            const allChanges = getHistoryChanges(assetData, previousAssetData);
            const changes = formatHistoryChanges(allChanges);

            return {
                id: assetData.updated,
                date: assetData.updated,
                user: action === HistoryAction.CREATED ? createdBy : lastEditedBy,
                action,
                allChanges,
                changes,
                assetData,
            };
        });
}

export function getChangeFieldName(path: string): string {
    const field = FIELD_NAME_MAP[path] || path.split('.').pop();
    return camelCaseToSentence(field);
}
