import { connect } from 'react-redux';
import selectNewsroom from 'store/newsroom/actions';
import NewsroomList from './NewsroomsList';

const mapStateToProps = (state) => ({
    acl: state.acl,
    searchTerm: state.ui.search.query,
});

const enhance = connect(mapStateToProps, {
    selectNewsroom,
});

export default enhance(NewsroomList);
