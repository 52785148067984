import classnames from 'classnames/bind';
import css from './Rating.module.scss';

const cln = classnames.bind(css);

type onHoverType = (index: number) => void;
type onClickType = (index: number) => void;

interface Props {
    value: number;
    onHover: onHoverType;
    onClick: onClickType;
    clickedRate: number;
    hoveredRate: number;
    itemClassName?: string;
}

function RatingItem({ value, onHover, onClick, clickedRate, hoveredRate, itemClassName }: Props) {
    const handleOnClick = () => onClick(value);

    const handleOnHover = () => onHover(value);
    const isHalf = Boolean(value % 1);

    return (
        <li className={cln(css.item, { 'item--rightHalf': isHalf })}>
            <button
                type="button"
                className={cln(css.button, itemClassName, {
                    'button--active': hoveredRate ? value <= hoveredRate : value <= clickedRate,
                    'button--half': isHalf,
                })}
                onMouseOver={handleOnHover}
                onFocus={handleOnHover}
                onClick={handleOnClick}
                aria-label="Rating item"
            />
        </li>
    );
}

export default RatingItem;
