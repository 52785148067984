import * as PropTypes from 'prop-types';

function PoorMetadataCircleIcon({ className }) {
    return (
        <svg className={className} width="32" height="32" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="nonzero">
                <circle fill="#E8C931" cx="16" cy="16" r="16" />
                <g fill="#FFF">
                    <path d="M22 10.326l-12 3.7082v2.0026l12 3.6154v-9.3261zM18 20.536V23c0 .618-.5549 1.088-1.1644.9864l-6-1A1 1 0 0 1 10 22v-3.8744l-1.3-.3917c-.4-.2163-.7-.649-.7-1.0815v-3.2282c0-.4326.3-.8653.7-1.0816l14-4.3262c.7-.1081 1.3.3245 1.3 1.0816v11.7724c0 .757-.7 1.2978-1.3 1.0815L18 20.536zm-2-.6026l-4-1.2051v2.4247l4 .6666v-1.8862z" />
                    <path d="M22 10.326l-12 3.7082v2.0026l12 3.6154v-9.3261zM18 20.536V23c0 .618-.5549 1.088-1.1644.9864l-6-1A1 1 0 0 1 10 22v-3.8744l-1.3-.3917c-.4-.2163-.7-.649-.7-1.0815v-3.2282c0-.4326.3-.8653.7-1.0816l14-4.3262c.7-.1081 1.3.3245 1.3 1.0816v11.7724c0 .757-.7 1.2978-1.3 1.0815L18 20.536zm-2-.6026l-4-1.2051v2.4247l4 .6666v-1.8862z" />
                </g>
            </g>
        </svg>
    );
}

PoorMetadataCircleIcon.propTypes = {
    className: PropTypes.string,
};

PoorMetadataCircleIcon.defaultProps = {
    className: undefined,
};

export default PoorMetadataCircleIcon;
