import { useState } from 'react';
import classnames from 'classnames/bind';
import { FaAngleDown } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { Option, Spinner } from '@schibsted-svp/react-ui';
import { type FormData, useTagsSuggestions } from 'components/AssetForm/hooks/useTagsSuggestions';
import { TagIcon } from '../TagIcon';
import css from './TagsSuggestions.module.scss';

const cln = classnames.bind(css);

type TagsSuggestionsProps = {
    provider: string;
    onAddTag: (tag: string) => void;
    formData: FormData;
};

export function TagsSuggestions({ provider, onAddTag, formData }: TagsSuggestionsProps) {
    const { isFetching, isLoading, suggestions } = useTagsSuggestions({ provider, formData });
    const [isHidden, setIsHidden] = useState(false);

    const onToggleSuggestionsClick = () => setIsHidden((prevState) => !prevState);
    const addTag = (tag: string) => () => onAddTag(tag);

    const loader = isFetching ? <Spinner className={css.loader} size="compact" /> : null;

    return (
        <div className={css.container}>
            <div className={css.header}>
                {isLoading ? (
                    <>
                        <span className={css.label}>Loading tags suggestions</span>
                        {loader}
                    </>
                ) : (
                    <>
                        {suggestions.length > 0 && (
                            <>
                                <button type="button" className={css.toggler} onClick={onToggleSuggestionsClick}>
                                    <FaAngleDown className={cln('togglerIcon', { 'togglerIcon--hidden': isHidden })} />

                                    <span className={css.label}>
                                        <span className={css.togglerLabel}>{isHidden ? 'show' : 'hide'}</span> suggested
                                        tags
                                    </span>
                                </button>
                                {loader}
                            </>
                        )}

                        {suggestions.length === 0 && (
                            <>
                                <span className={css.label}>No tag suggestion</span>
                                {loader}
                            </>
                        )}
                    </>
                )}
            </div>
            {suggestions.length > 0 && (
                <div className={cln('list', { 'list--hidden': isHidden })}>
                    {suggestions.map((suggestion) => (
                        <Option key={suggestion.value} onClick={addTag(suggestion.value)}>
                            <Option.Icon padding={0}>
                                <MdAdd size={20} />
                            </Option.Icon>
                            <Option.Icon padding={2}>
                                <TagIcon tagType={suggestion.type} inheritColor className={css.tagIcon} />
                            </Option.Icon>
                            <Option.Label>{suggestion.label}</Option.Label>
                        </Option>
                    ))}
                </div>
            )}
        </div>
    );
}
