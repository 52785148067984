import { Button, LabeledContainer, Input, Select } from '@schibsted-svp/react-ui';
import { Controller } from 'react-hook-form';
import type { StylesConfig } from 'react-select';
import { findMultipleOptions, getValuesFromOptionsArray } from 'components/ui/Form/Select';
import { useUserFormContext, useUserFormSubmit } from '../hooks';
import type { FormMode, NewsroomOption } from '../types';
import css from './UserForm.module.scss';

const selectStyles: StylesConfig<NewsroomOption, true> = {
    multiValueRemove(base, state) {
        if (!state.data.isFixed) {
            return base;
        }
        return { ...base, opacity: 0, width: 0 };
    },
};

interface Props {
    formMode: FormMode;
    newsroomsOptions: NewsroomOption[];
    hideForm: () => void;
}

export function UserForm({ formMode, newsroomsOptions, hideForm }: Props) {
    const { formApi } = useUserFormContext();
    const handleSubmit = useUserFormSubmit({ mode: formMode, onSuccess: () => hideForm() });
    const isEditMode = formMode === 'edit';

    const {
        register,
        control,
        formState: { errors },
    } = formApi;

    return (
        <form className={css.form} onSubmit={handleSubmit}>
            <LabeledContainer label="First name">
                <Input {...register('firstname')} />
            </LabeledContainer>
            <LabeledContainer label="Last name">
                <Input {...register('lastname')} />
            </LabeledContainer>
            <LabeledContainer label="E-mail">
                <Input {...register('email')} disabled={isEditMode} error={errors.email?.message} />
            </LabeledContainer>
            <LabeledContainer label="Select newsroom">
                <Controller
                    name="newsrooms"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            styles={selectStyles}
                            value={findMultipleOptions(newsroomsOptions)(field.value)}
                            onChange={(values, action) => {
                                if (action.action === 'clear') {
                                    const newValues = findMultipleOptions(newsroomsOptions)(field.value).filter((v) =>
                                        v.isFixed !== undefined ? v.isFixed : true
                                    );
                                    field.onChange(getValuesFromOptionsArray(newValues));
                                    return;
                                }

                                if (
                                    (action.action === 'remove-value' || action.action === 'pop-value') &&
                                    action.removedValue.isFixed
                                ) {
                                    return;
                                }

                                field.onChange(getValuesFromOptionsArray(values));
                            }}
                            options={newsroomsOptions}
                            isMulti
                        />
                    )}
                />
            </LabeledContainer>
            <Button type="submit" className={css.button}>
                {isEditMode ? 'Update' : 'Give'} access
            </Button>
        </form>
    );
}
