import { withScope, captureMessage, type SeverityLevel } from '@sentry/browser';

type Primitive = number | string | boolean | bigint | symbol | null | undefined;
type Extras = Record<string, unknown>;

export function reportMessageToSentry({
    message,
    tags = {},
    extras = {},
    level = 'error',
}: {
    message: string;
    tags?: { [key: string]: Primitive };
    extras?: Extras;
    level?: SeverityLevel;
}): void {
    return withScope((scope) => {
        scope.setTags(tags);
        scope.setExtras(extras);
        scope.setLevel(level);
        captureMessage(message);
    });
}
