import * as PropTypes from 'prop-types';
import config from 'config';
import { reportMessageToSentry } from 'lib/error';
import { Button, Notification } from '@schibsted-svp/react-ui';
import validate from './PosterUploader.validator';
import css from './PosterUploader.module.scss';

const [minWidth, minHeight] = config.images.poster.upload.minimumSize;

function PosterUploader({ disabled, onVideoPosterUpload }) {
    const handleChange = async (event) => {
        try {
            const result = await validate(Array.from(event.target.files), { minWidth, minHeight });
            if (result.isValid) {
                onVideoPosterUpload(result.file);
            } else {
                Notification.notify.error(result.message);
            }
        } catch (error) {
            const message =
                'Something went wrong during upload. Please contact us on Slack or on mail:svp@schibsted.com';

            reportMessageToSentry({
                message,
                extras: { error },
            });
            Notification.notify.error(message);
        }
    };

    const handleClick = (event) => {
        // eslint-disable-next-line no-param-reassign
        event.target.value = null;
    };

    return (
        <div className={css.wrapper}>
            <h5>Upload image</h5>
            <div className={css.content}>{`Minimum image resolution ${minWidth}x${minHeight}`}</div>
            <Button className={css.button} type="label" size="small" disabled={disabled}>
                Upload image
                <input
                    id="uploadInput"
                    data-testid="imageUploadInput"
                    type="file"
                    onClick={handleClick}
                    onChange={handleChange}
                    disabled={disabled}
                />
            </Button>
        </div>
    );
}

PosterUploader.propTypes = {
    disabled: PropTypes.bool,
    onVideoPosterUpload: PropTypes.func.isRequired,
};

PosterUploader.defaultProps = {
    disabled: false,
};

export default PosterUploader;
