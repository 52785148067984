import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import type { Control, FieldValues, FieldPath, ControllerRenderProps } from 'react-hook-form';
import { Select, Spinner } from '@schibsted-svp/react-ui';
import type { ExtendedProps as SelectProps, DefaultOptionType } from '@schibsted-svp/react-ui/lib/es/Select';
import { useListTagsQuery } from 'services/admin-bff-sdk';
import { useTagsSearch } from 'components/AssetForm/hooks/useTagsSearch';
import { findMultipleOptions, getValuesFromOptionsArray } from 'components/ui/Form/Select';
import { TagIcon } from '../TagIcon';
import css from './TagsSelect.module.scss';

function LoadingMessage() {
    return <Spinner containerClassName={css.optionsSpinner} size="compact" label="Loading tags" />;
}
function LoadingIndicator() {
    return null;
}

type TagOptionType = DefaultOptionType & {
    type: string;
};

type TagsSelectProps<TFieldValues extends FieldValues> = {
    name: FieldPath<TFieldValues>;
    control: Control<TFieldValues>;
    provider: string;
    normalizeValues?: boolean;
} & SelectProps<TagOptionType, true>;

export function TagsSelect<TFieldValues extends FieldValues>({
    name,
    control,
    provider,
    normalizeValues = false,
    excludedTags = [],
    ...rest
}: TagsSelectProps<TFieldValues>) {
    const { field, fieldState } = useController({ name, control });
    const normalizedValue = normalizeValues ? field.value : getValuesFromOptionsArray(field.value);
    const {
        data,
        isFetching: isListTagsFetching,
        isLoading: isListTagsLoading,
    } = useListTagsQuery({ provider, ids: normalizedValue }, { skip: !normalizedValue?.length });
    const { isFetching, isLoading, onTagsSearch, searchResults } = useTagsSearch({ provider, delay: 200 });

    const options = searchResults?.map(({ id, title, type }) => ({ value: id, label: title, type })) ?? [];

    const excludedTagsValues = excludedTags.map(({ value }) => value);
    const restOptions = options.filter((tag) => !excludedTagsValues.includes(tag.value));

    const tagsOptions = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return data?.listTags.map((tag) => ({ value: tag.id, label: tag?.title, type: tag?.type })) || [];
    }, [data]);

    const formatOptionLabel = ({ label, type }: TagOptionType) => {
        if (isListTagsLoading || !label) {
            return <Spinner containerClassName={css.selectSpinner} />;
        }
        if (!type) {
            return label;
        }
        return (
            <>
                <TagIcon inheritColor tagType={type} className={css.tagIcon} />
                {label}
            </>
        );
    };

    const normalizeValuesHandlers: Pick<ControllerRenderProps, 'value' | 'onChange'> = {
        value: findMultipleOptions(tagsOptions)(field.value),
        onChange: (value) => field.onChange(getValuesFromOptionsArray(value)),
    };

    return (
        <Select
            {...field}
            {...(normalizeValues && normalizeValuesHandlers)}
            error={fieldState.error?.message}
            placeholder="Search for tags (try adding three or more)"
            isLoading={isListTagsFetching || isListTagsLoading || isFetching || isLoading}
            onInputChange={onTagsSearch}
            options={restOptions}
            formatOptionLabel={formatOptionLabel}
            components={{ LoadingMessage, LoadingIndicator }}
            backspaceRemovesValue={false}
            splitButton={false}
            isMulti
            isClearable
            {...rest}
        />
    );
}
