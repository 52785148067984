import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { DocumentNode } from 'graphql';
import { GraphQLClient, ClientError } from 'graphql-request';
import config from 'config';
import authToken from 'models/authToken';
import { reportMessageToSentry } from 'lib/error';

function prepareHeaders() {
    const token = authToken.get();
    return (
        token?.raw && {
            Authorization: `Bearer ${token.raw}`,
        }
    );
}

export const graphqlRequestBaseQuery = (): BaseQueryFn<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { document: string | DocumentNode; variables?: any },
    unknown,
    Pick<ClientError, 'name' | 'message' | 'stack'>,
    Partial<Pick<ClientError, 'request' | 'response'>>
> => {
    const client = new GraphQLClient(config.adminBff.host);

    return async ({ document, variables }, { endpoint, signal }) => {
        try {
            return {
                data: await client.request({
                    document,
                    variables,
                    signal,
                    requestHeaders: new Headers(prepareHeaders()),
                }),
                meta: {},
            };
        } catch (error) {
            reportMessageToSentry({
                message: `Error in operation: ${endpoint} for variables: ${JSON.stringify(variables)}`,
                extras: {
                    error,
                },
            });
            if (error instanceof ClientError) {
                const { name, message, stack, request, response } = error;
                return { error: { name, message, stack }, meta: { request, response } };
            }
            throw error;
        }
    };
};
