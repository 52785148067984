import { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form';
import InlineInput from 'components/ui/Form/InlineInput';
import { InputError } from '@schibsted-svp/react-ui';
import { streamUrlSchema } from '../validator';
import type { FormValues } from '../types';

type StreamUrlProps = {
    className?: string;
    formApi: UseFormReturn<FormValues>;
};

export function StreamUrl({ className, formApi }: StreamUrlProps) {
    const { control, trigger } = formApi;

    const { field, fieldState } = useController({ name: 'streamUrls.hls', control });
    const { value, onChange } = field;
    const { error } = fieldState;

    const [editingValue, setEditingValue] = useState(value);

    useEffect(() => {
        setEditingValue(value);
    }, [value]);

    const validate = (val: string) => streamUrlSchema.safeParse(val)?.success;
    const onReset = () => setEditingValue(value);

    const onSave = () => {
        onChange(editingValue);
        trigger('streamUrls.hls');
    };

    return (
        <div className={className}>
            <InlineInput
                value={editingValue || ''}
                onSave={onSave}
                validate={validate}
                onEdit={setEditingValue}
                onReset={onReset}
            />
            {error?.message && <InputError>{error?.message}</InputError>}
        </div>
    );
}
