import { useState } from 'react';
import { Autosuggest } from 'components/ui/Form';
import { LabeledContainer } from '@schibsted-svp/react-ui';
import { useUsersSuggestions } from 'hooks/usersManagement/useUsersSuggestions';
import { FormMode, UserData } from '../types';
import css from './UserSearchInput.module.scss';

interface Props {
    showForm: (mode: FormMode, data?: UserData) => void;
}

export function UserSearchInput({ showForm }: Props) {
    const [value, setValue] = useState('');
    const { getSuggestions, suggestions } = useUsersSuggestions();

    const handleChange = (_, { newValue }: { newValue: string }) => setValue(newValue);

    const getSuggestionValue = ({ email }: { email: string }) => email;

    const clearSearchInput = () => setValue('');

    const handleAddAccessClick = () => {
        clearSearchInput();
        showForm('creation');
    };

    const handleShowUserEditForm = (_, { suggestion }: { suggestion: UserData }) => {
        clearSearchInput();
        showForm('edit', suggestion);
    };

    const handleBlur = (_, { highlightedSuggestion }: { highlightedSuggestion: UserData }) => {
        if (highlightedSuggestion) {
            clearSearchInput();
            showForm('edit', highlightedSuggestion);
        }
    };

    const handleSuggestionsFetch = (data: { value: string }) => {
        getSuggestions(data.value);
    };

    return (
        <div className={css.container}>
            <LabeledContainer label="Search by okta email">
                <Autosuggest
                    suggestions={suggestions}
                    focusInputOnSuggestionClick={false}
                    onSuggestionsFetchRequested={handleSuggestionsFetch}
                    onSuggestionsClearRequested={() => {}}
                    getSuggestionLabel={getSuggestionValue}
                    getSuggestionValue={getSuggestionValue}
                    onSuggestionSelected={handleShowUserEditForm}
                    inputProps={{
                        'data-test': 'AutosuggestInput',
                        onBlur: handleBlur,
                        placeholder: 'Search by e-mail',
                        value,
                        onChange: handleChange,
                    }}
                />
            </LabeledContainer>
            <button type="button" onClick={handleAddAccessClick} className={css.createButton}>
                Can&apos;t find user? Create new here
            </button>
        </div>
    );
}
