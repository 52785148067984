import type { FocusEvent, MouseEvent } from 'react';
import type { UseFormRegister, UseFormSetValue, UseFormGetValues } from 'react-hook-form';
import { MdSync } from 'react-icons/md';
import { LabeledContainer, Input } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import config from 'config';
import { slug } from 'lib/string';
import type { FormValues } from '../types';

import css from './TitleShortPath.module.scss';

function getTvSiteAssetUrlPrefix(provider: Asset['provider'], assetId: Asset['id']): string | null {
    return config.newsroomsConfig[provider]?.tvSiteAssetUrlPrefix?.replace(':assetId', assetId) || null;
}

type TitleShortPathProps = {
    provider: Asset['provider'];
    id: Asset['id'];
    register: UseFormRegister<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    getValues: UseFormGetValues<FormValues>;
};

export function TitleShortPath({ provider, id, register, setValue, getValues }: TitleShortPathProps) {
    const prefix = getTvSiteAssetUrlPrefix(provider, id);

    if (!prefix) {
        return null;
    }

    const handleShortPathBlur = (event: FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const normalizedValue = slug(value);

        if (value !== normalizedValue) {
            event.preventDefault();
            setValue('additional.metadata.titleShortPath', normalizedValue, { shouldDirty: true, shouldTouch: true });
        }
    };

    const handleRegenerateClick = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const value = slug(getValues('title'));
        setValue('additional.metadata.titleShortPath', value, { shouldDirty: true, shouldTouch: true });
    };

    return (
        <LabeledContainer strict label="short path" className={css.container}>
            <div className={css.inner}>
                <a href={prefix} target="_blank" rel="noopener noreferrer" className={css.link}>
                    {prefix}
                </a>
                <Input
                    {...register('additional.metadata.titleShortPath')}
                    onBlur={handleShortPathBlur}
                    className={css.input}
                    icon={
                        <span className={css.regenerateIcon} onClick={handleRegenerateClick}>
                            <MdSync />
                        </span>
                    }
                    iconPosition="right"
                />
            </div>
        </LabeledContainer>
    );
}
