import { useSelector } from 'react-redux';
import { getAsset } from 'store/assets/selectors';
import type { PlaylistItem } from './types';

export function usePlaylistDuration({
    items,
    isRegularPlaylist,
}: {
    items: PlaylistItem[];
    isRegularPlaylist: boolean;
}) {
    const playlistAssets = useSelector((state) =>
        items.map((item) => 'asset' in item && getAsset(state, { id: item.asset.id, provider: item.asset.provider }))
    );

    if (!isRegularPlaylist) {
        return 0;
    }

    return playlistAssets.reduce((accumulator, currentAsset) => accumulator + Number(currentAsset?.duration) || 0, 0);
}
