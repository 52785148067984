import type { Asset } from '@schibsted-svp/svp-api-types';
import { getMetadata } from 'models/asset';
import { z } from 'zod';
import { getNewsroomConfig } from 'config';
import { getPrefixMetadataKey } from 'store/uploads/previews/sagas-utils';

const multiPreviewSchema = z.object({
    aspectRatio: z.string(),
    width: z.number(),
    height: z.number(),
    url: z.string(),
});

const multiPreviewVariantSchema = z.array(multiPreviewSchema);

const metadataValue = z.array(multiPreviewVariantSchema);

export function parseMetadataValue(value: string | undefined) {
    try {
        if (!value) {
            return [];
        }
        return metadataValue.parse(JSON.parse(value));
    } catch {
        return [];
    }
}

export function getMultiPreviewPrefixes(provider: string) {
    return Object.keys(getNewsroomConfig(provider).previews.artifactsMap ?? {});
}

export function getMultiPreviewsMetadata(asset: Asset): { [key: string]: string } {
    const metadataKeys = getMultiPreviewPrefixes(asset.provider).map(getPrefixMetadataKey);
    return Object.entries(getMetadata(asset)).reduce((acc, [key, value]) => {
        if (!metadataKeys.includes(key)) {
            return acc;
        }
        return { ...acc, [key]: value };
    }, {});
}
