import { ASSET_CHANGE } from 'store/assets/actionTypes';
import {
    FETCH_LIVE_ASSETS,
    FETCH_LIVE_ASSETS_SUCCESS,
    FETCH_LIVE_ASSETS_ERROR,
    STOP_LIVE_STREAM_SUCCESS,
    DUPLICATE_LIVE_STREAM_REQUEST,
    DUPLICATE_LIVE_STREAM_SUCCESS,
    DUPLICATE_LIVE_STREAM_FAILURE,
    REMOVE_DUPLICATE_LIVE_STREAM_INFO,
} from '../actions';

const defaultState = {
    isFetching: false,
    list: [],
    error: '',
};

export default function liveAssetsReducer(state = defaultState, action) {
    switch (action.type) {
        case FETCH_LIVE_ASSETS: {
            return {
                ...state,
                isFetching: true,
                error: '',
            };
        }
        case FETCH_LIVE_ASSETS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                list: action.assetsIds,
                error: '',
            };
        }

        case FETCH_LIVE_ASSETS_ERROR: {
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        }

        case ASSET_CHANGE: {
            const { asset } = action;
            if (asset.streamType === 'live') {
                // TODO think through removing inactive assets ids from the list
                return { ...state, list: [...new Set([...state.list, asset.id])] };
            }
            if (asset.streamType === 'wasLive') {
                return {
                    ...state,
                    list: state.list.filter((assetId) => assetId !== asset.id),
                };
            }
            return state;
        }

        case STOP_LIVE_STREAM_SUCCESS: {
            const { assetId } = action;
            return {
                ...state,
                list: state.list.filter((id) => assetId !== id),
            };
        }

        case DUPLICATE_LIVE_STREAM_REQUEST: {
            return {
                ...state,
                isDuplicating: true,
            };
        }

        case DUPLICATE_LIVE_STREAM_FAILURE: {
            if (state?.duplicatedAssetId) {
                const { duplicatedAssetId, ...newState } = state;
                return {
                    ...newState,
                    isDuplicating: false,
                };
            }

            return {
                ...state,
                isDuplicating: false,
            };
        }

        case DUPLICATE_LIVE_STREAM_SUCCESS: {
            const { newAssetId } = action;

            return {
                ...state,
                isDuplicating: false,
                duplicatedAssetId: newAssetId,
            };
        }

        case REMOVE_DUPLICATE_LIVE_STREAM_INFO: {
            const { duplicatedAssetId, ...newState } = state;

            return newState;
        }

        default:
            return state;
    }
}
