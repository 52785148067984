import classnames from 'classnames/bind';
import { type CSSProperties, type ReactNode, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import type { Item, ItemChangeHandler } from './types';
import { TimelineItem, type TimelineItemProps } from './TimelineItem';
import { TimelineSelector, type TimelineSelectorProps } from './TimelineSelector';
import { useTimelineState, useSubscribeTimelineState } from './hooks/useTimelineState';
import { useDragHandlers } from './hooks/useDragHandlers';
import css from './TimelineItems.module.scss';

const cln = classnames.bind(css);

export function getTotalDuration(items: Item[], duration: number) {
    const itemsTime = items.reduceRight((time, { startTime, endTime }) => {
        return Math.max(time, startTime, endTime);
    }, 0);

    return Math.max(itemsTime, duration);
}

export type TimelineItemRenderer = (id: string, props: TimelineItemProps) => ReactNode;

export type TimelineSelectorRenderer = (props: TimelineSelectorProps) => ReactNode;

type TimelineItems = {
    className?: string;
    height: number;
    items: Item[];
    onChange: ItemChangeHandler;
    renderItem?: TimelineItemRenderer;
    renderSelector?: TimelineSelectorRenderer;
};

export function TimelineItems({ className, height, items, onChange, renderItem, renderSelector }: TimelineItems) {
    const { setState } = useTimelineState();
    const duration = useSubscribeTimelineState((state) => state.duration);
    const pixelsPerSecond = useSubscribeTimelineState((state) => state.pixelsPerSecond);
    const selectedItemIndex = useSubscribeTimelineState((state) => state.selection?.index);
    const totalDuration = useSubscribeTimelineState((state) => state.totalDuration ?? state.duration);
    const viewport = useSubscribeTimelineState((state) => state.viewport);
    const { startDragging, finishDragging } = useDragHandlers();

    const handleMouseDown = useCallback(() => {
        startDragging();
    }, [startDragging]);

    useEffect(() => {
        const handleDocumentMouseUp = () => {
            finishDragging();
        };

        document.addEventListener('mouseup', handleDocumentMouseUp);
        return () => document.removeEventListener('mouseup', handleDocumentMouseUp);
    }, [finishDragging]);

    useLayoutEffect(() => {
        if (!totalDuration && duration) {
            setState({ items, totalDuration: getTotalDuration(items, duration) });
            return;
        }
        setState({ items });
    }, [duration, items, setState, totalDuration]);

    const itemsInViewport = useMemo(() => {
        if (!viewport) {
            return [];
        }
        return items
            .map((item, index) => [index, item] as const)
            .filter(([, item]) => item.startTime <= viewport.endTime && item.endTime >= viewport.startTime);
    }, [items, viewport]);

    const style = useMemo<CSSProperties>(
        () => ({
            backgroundSize: `${duration * pixelsPerSecond}px auto, auto`,
            height,
        }),
        [duration, height, pixelsPerSecond]
    );

    return (
        <>
            <div className={cln(css.items, className)} onMouseDown={handleMouseDown} style={style}>
                {itemsInViewport.map(([index, item]) => {
                    const itemProps: TimelineItemProps = {
                        startTime: item.startTime,
                        endTime: item.endTime,
                        active: selectedItemIndex === index,
                        height,
                        index,
                        max: items[index + 1]?.startTime || totalDuration,
                        min: items[index - 1]?.endTime || 0,
                    };

                    return renderItem?.(item.id, itemProps) || <TimelineItem {...itemProps} key={item.id} />;
                })}
            </div>

            {renderSelector?.({ height, onChange }) || <TimelineSelector onChange={onChange} height={height} />}
        </>
    );
}
