import { merge } from 'lib/object';
import { toHashMap } from 'lib/array';
import { LIVE_ENCODERS_FAILURE, LIVE_ENCODERS_REQUEST, LIVE_ENCODERS_SUCCESS } from '../actions';

/**
 * @param {Object} event
 * @param {String} [event.status]
 * @param {Object} [event.schedules]
 * @param {Number} [event.schedules.startTime]
 * @param {Number} [event.schedules.endTime]
 * @return {Object}
 */
const eventMapper = ({ schedules, ...event }) => {
    if (!event.status) {
        return event;
    }

    const { startTime, endTime } = schedules || {};
    return {
        ...event,
        startTime,
        endTime,
        status: event.status.toLowerCase(),
    };
};

/**
 * @param {Object} input
 * @param {String} [input.event]
 * @return {Object}
 */
const inputMapper = (input) => {
    if (!input.event) {
        return input;
    }

    return {
        ...input,
        event: eventMapper(input.event),
    };
};

/**
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {String} action.newsroom
 * @param {Object} action.rest...
 * @returns {Object}
 */
export default function encodersReducer(state = {}, { type, newsroom, ...rest }) {
    const newsroomState = state[newsroom] || {};

    switch (type) {
        case LIVE_ENCODERS_REQUEST: {
            return merge(state, {
                [newsroom]: {
                    ...newsroomState,
                    loading: true,
                    items: newsroomState.items,
                },
            });
        }

        case LIVE_ENCODERS_FAILURE:
        case LIVE_ENCODERS_SUCCESS: {
            const { encoders = [] } = rest;
            return {
                ...state,
                [newsroom]: {
                    ...newsroomState,
                    loading: false,
                    items: toHashMap(
                        encoders.map(({ inputs, events, ...encoder }) => ({
                            ...encoder,
                            inputs: toHashMap(inputs.map(inputMapper)),
                            events: toHashMap(events.map(eventMapper)),
                        }))
                    ),
                },
            };
        }

        default:
            return state;
    }
}
