import { Button, Dialog } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { useEffect, useState } from 'react';
import * as ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';

import { createAssetPreview, type CutRange } from 'store/uploads/previews/slice';
import { VideoPreviewEditorProvider } from './VideoPreviewEditorContext';
import { VideoPreviewTimeline } from './VideoPreviewTimeline';
import { useCustomPreviewDialogContext } from '../CustomPreviewDialogContext';

interface VideoPreviewEditorProps {
    asset: Asset;
    closeDialog: () => void;
}

export function VideoPreviewEditor({ asset, closeDialog }: VideoPreviewEditorProps) {
    const dispatch = useDispatch();
    const { disableCreateButton } = useCustomPreviewDialogContext();
    const [cutRange, setCutRange] = useState<CutRange | undefined>(undefined);

    useEffect(() => {
        ReactGA.modalview('video-preview-editor');
    }, []);

    const handleCreatePreview = () => {
        if (!cutRange) {
            return;
        }
        dispatch(createAssetPreview({ provider: asset.provider, assetId: asset.id, cutRange }));
        closeDialog();
    };

    return (
        <>
            <Dialog.Section>
                <VideoPreviewEditorProvider assetDuration={asset.duration}>
                    <VideoPreviewTimeline asset={asset} cutRange={cutRange} onCutRangeChange={setCutRange} />
                </VideoPreviewEditorProvider>
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={closeDialog}>
                    Close
                </Button>
                <Button type="button" onClick={handleCreatePreview} disabled={disableCreateButton || !cutRange}>
                    Create
                </Button>
            </Dialog.Section>
        </>
    );
}
