import classnames from 'classnames/bind';
import Tooltip from 'components/ui/Tooltip';
import css from './Tab.module.scss';

const cln = classnames.bind(css);

interface TabWrapperProps {
    showTooltip: boolean;
    tooltipContent?: React.ReactNode;
    children: React.ReactNode;
}

function TabWrapper({ showTooltip, tooltipContent, children }: TabWrapperProps) {
    if (showTooltip) {
        return (
            <Tooltip theme="light" className={css.tooltip} content={tooltipContent} placement="top">
                <span>{children}</span>
            </Tooltip>
        );
    }
    return <span>{children}</span>;
}

interface TabProps {
    isActive: boolean;
    onClick: () => void;
    label: string;
    disabled?: boolean;
    showTooltip?: boolean;
    tooltipContent?: React.ReactNode;
}

export function Tab({ isActive, onClick, label, disabled = false, showTooltip = false, tooltipContent }: TabProps) {
    return (
        <TabWrapper showTooltip={showTooltip} tooltipContent={tooltipContent}>
            <div className={cln('tab', { 'tab--active': isActive, 'tab--disabled': disabled })} onClick={onClick}>
                {label}
            </div>
        </TabWrapper>
    );
}
