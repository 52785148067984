import { useCallback, BaseSyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form';
import { getCategory } from 'store/categories/selectors';
import { getCustomFieldsPerType } from './DynamicFilters/helpers';
import type { FiltersDialogData } from './types';

export const useFiltersDialogForm = (initialValues: FiltersDialogData) => {
    const formApi = useForm<FiltersDialogData>({
        defaultValues: initialValues,
    });
    return { formApi };
};

export const useFiltersDialogFormSubmit = ({
    formApi,
    stopFormPropagation,
    onFilter,
    onSuccess = () => {},
}: {
    formApi: UseFormReturn<FiltersDialogData>;
    stopFormPropagation: boolean;
    onFilter: (data: FiltersDialogData) => void;
    onSuccess?: () => void;
}) => {
    const { handleSubmit } = formApi;

    return useCallback(
        (event: BaseSyntheticEvent) => {
            if (stopFormPropagation) {
                event.stopPropagation();
            }
            return handleSubmit(async (data) => {
                onFilter(data);
                onSuccess();
            })(event);
        },
        [handleSubmit, stopFormPropagation, onFilter, onSuccess]
    );
};

export const useCategoryCustomFieldsPerType = ({ provider, type, filters }) => {
    const categoryId = filters?.categories?.value;
    const { customFields } = filters;

    const category = useSelector((state) => getCategory(state, { provider, id: categoryId }));
    const fieldsPerType = getCustomFieldsPerType({ category, type });

    if (!categoryId || !customFields) {
        return [];
    }

    return fieldsPerType.filter((field) => Object.keys(customFields).includes(field.key));
};
