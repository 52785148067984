import { isFromFuture } from 'lib/time';
import { isAudio, isLive } from 'models/asset';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { type Control, useWatch } from 'react-hook-form';
import type { FormValues } from '../types';

export function useScheduleState({ asset, control }: { asset: Asset; control: Control<FormValues> }): {
    shouldRenderScheduleInterface: boolean;
} {
    const schedulePublishTime = useWatch({ name: 'schedulePublishTime', control });

    const isScheduledForFutureDate = isAudio(asset)
        ? isFromFuture(schedulePublishTime)
        : isFromFuture(asset.flightTimes.start) || isFromFuture(schedulePublishTime);

    return {
        shouldRenderScheduleInterface: isScheduledForFutureDate && !isLive(asset),
    };
}
