import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import config from 'config';
import authToken from 'models/authToken';
import { ClientError, GraphQLClient } from 'graphql-request';

interface BaseQueryOptions {
    url: string;
    requestHeaders: () => Record<string, string>;
}

export const graphqlRequestBaseQuery = (options: BaseQueryOptions): BaseQueryFn => {
    const client = new GraphQLClient(options.url);
    return async ({ document, variables }) => {
        client.setHeaders(options.requestHeaders());
        try {
            return { data: await client.request(document, variables), meta: {} };
        } catch (error) {
            if (error instanceof ClientError) {
                const { name, message, stack, request, response } = error;
                return { error: { name, message, stack }, meta: { request, response } };
            }
            throw error;
        }
    };
};

export const adminBffRtkApi = createApi({
    reducerPath: 'adminBffRtkApi',
    baseQuery: graphqlRequestBaseQuery({
        url: config.adminBff.host,
        requestHeaders: () => {
            const token = authToken.get();
            return (
                token?.raw && {
                    Authorization: `Bearer ${token.raw}`,
                }
            );
        },
    }),
    endpoints: () => ({}),
});
